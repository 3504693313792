import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../contexts/StateContext";
import { getClientsData } from "../../../../../../api/ClientApi";
import OnboardEmployee from "../../../../hr/dashboard/applicants/OnboardEmployee";

//import EmploymentStatus from "./EmploymentStatus";
//import OnboardEmployee from "./OnboardEmployee";


const FullStackData = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = [
    "Search",
    'PdfExport'
  ];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState([]);
  const [FullStackData, setFullStackData] = useState({
    showLoading: false,
     });
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let applicantsList = [];
  useEffect(() => {
    document.title = "Applicants";
    loadData();
  }, []);
  const loadData = () => {
    getClientsData(searchText,"interview-fullstack")
      .then((res) => {
        let responseData = res.data.content;
        for (let i = 0; i < responseData.length; i++) {
          const applicants = {
            name: "",
            experience: "",
            designation: "",
            hireStatus: "",
            preferred: "",       
        };
          applicants.name = responseData[i].name;
          applicants.experience = responseData[i].employment.profileDetails?responseData[i].employment.profileDetails.experienceInMonths:0;
          applicants.designation = responseData[i].employment.designation;
          applicants.hireStatus = responseData[i].hireStatus;
          applicants.preferred = responseData[i].employment.profileDetails?responseData[i].employment.profileDetails.preferredLocation:'NA';
          applicantsList.push(applicants);
          
        }
        setData(applicantsList);
        setFullStackData({ ...FullStackData, showLoading: false });
      })
      .catch((error) => {
        if (error.message) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        setFullStackData({ ...FullStackData, showLoading: false });
        console.log(error);
      });
  };
  
  const dialogTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);   
    };
    return (
     <EmploymentStatus name={userName} email={userEmail}/>   
    )
  })
  const onboardTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);   
    };
    return (  
     <OnboardEmployee name={userName} email={userEmail}/>    
    )
  })
 

  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl">FullStack Page</h2>
      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={true}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      > 
        <ColumnsDirective>
        <ColumnDirective field='action' headerText='Action' textAlign="Center" isPrimaryKey={true}  width='100' template={onboardTemplate} />
          {/*<ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />*/}
          <ColumnDirective field='name' headerText='Name' width='100'  textAlign="Center" />
          <ColumnDirective field='experience' headerText='Experience' width='150'  textAlign="Center" />
          <ColumnDirective field='designation' headerText='Designation' width='100'  textAlign="Center" />
          <ColumnDirective field='hireStatus' headerText='HireStatus' width='120'  textAlign="Center" />
          <ColumnDirective field='preferred' headerText='Preferred' width='120'  textAlign="Center" />
          <ColumnDirective field='appliedOn' headerText='Update' width='120'  textAlign="Center" />

          
         {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Group, PdfExport]} />
      </GridComponent>
    </div>
  );
}

export default FullStackData;