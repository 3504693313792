/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <div className="flex overflow-hidden flex-col pb-12 bg-white">
      <main>{children}</main>
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
