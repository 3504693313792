import React, { useState, useEffect } from "react";
import {
  AppBar,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AlertComponent from "../../../../../AlertComponent";
import { retrieveProfileData } from "../../../../../api/Api";
import { getPageTrackAssessments } from "../../../../../api/AssessmentApi";
import { NavLink } from "react-router-dom";
import { Category } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { Page } from "@syncfusion/ej2-react-grids";
import { Link } from "react-router-dom";

const DsPrograming = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          Ds-Programming
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const BackEnd = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          BackEnd
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const FrontEnd = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          FrontEnd
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const FullStack = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          FullStack
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const Devops = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          Devops
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const Testing = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          Testing
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const FlexPLM = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          FlexPLM
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const SoftSkills = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          SoftSkills
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const Others = ({ assessment }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5" color="primary">
          Others
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Validate</TableCell>
                <TableCell>QA</TableCell>
                <TableCell>skill</TableCell>
                <TableCell>Total Score</TableCell>
                <TableCell>Min Pass</TableCell>
                <TableCell>No of Attendees</TableCell>
              </TableRow>
            </TableHead>
            {assessment ? (
              <TableBody>
                {assessment.map((data) => (
                  <TableRow key={data.assessmentName}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/validateAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/viewAnswers",
                        }}
                        state={{
                          assessment: data,
                        }}
                        exact
                      >
                        <button type="button" className="btn btn-light">
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell>{data.assessmentName}</TableCell>
                    <TableCell>{data.assessmentTotalScore}</TableCell>
                    <TableCell>{data.assessmentMinimumScoreToPass}</TableCell>
                    <TableCell>
                      {data.students ? data.students.length : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const TrackAssessment = ({ showAlert }) => {
  const [value, setValue] = React.useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showModalUserInfo, setShowModalUserInfo] = useState(false);
  const [assessment, setAssessment] = useState([]);
  const [role, setRole] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [page, setPage] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([
    "ds-programming",
    "backEnd",
    "frontEnd",
    "fullStack",
    "devops",
    "testing",
    "flex-plm",
    "soft-skills",
    "others",
  ]);

  const [category, setCategory] = useState("ds-programming");
  const [newContent, setNewContent] = useState([]);
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggle = () => {
    setShowModalUserInfo(!showModalUserInfo);
  };

  const setStatePromise = (newState) => {
    return new Promise((resolve) => {
      setContent(
        (prevContent) => ({ ...prevContent, ...newState }),
        () => {
          resolve();
        }
      );
    });
  };
  const changeCurrentPage = (newCurrentPage) => {
    setLoading(true);
    setCurrentPage(newCurrentPage);
  };
  const loadData = async (newPage) => {
    setLoading(true);
    try {
      const res = await getPageTrackAssessments(newPage, category);
      const filteredAssessments = res.data.content.filter(
        (assessment) =>
          assessment.students &&
          assessment.students.some((student) => student.status !== "validated")
      );
      //  setNewContent(res.data.content);
      setNewContent(filteredAssessments);
      //  alert("new content :"+JSON.stringify(filteredAssessments));
      setPage(res.data);
      setCurrentPage(res.data.pageNumber);
      setTotalNumberOfElements(res.data.totalNumberOfElements);
      setPageSize(res.data.pageSize);
      setLoading(false);
      // setAssessment(newContent);
      setAssessment(filteredAssessments);
      setSearchText(null);
      // await setStatePromise({ content: newContent });
      await setStatePromise({ content: filteredAssessments });
    } catch (error) {
      handleLoadDataError(error);
    }
  };

  useEffect(() => {
    document.title = "TrackAssessments";
    loadData(0);
  }, [category]);

  const handleLoadDataError = (error) => {
    if (error.message && error.success === false) {
      showAlert(error.message, "error");
    } else {
    }
    setLoading(false);
    console.error(error);
  };

  const updateCategory = (event) => {
    setCategory(event.target.value);
  };c
  const categoryComponents = {
    "ds-programming": DsPrograming,
    backend: BackEnd,
    frontend: FrontEnd,
    fullstack: FullStack,
    devops: Devops,
    testing: Testing,
    "flex-plm": FlexPLM,
    "soft-skills": SoftSkills,
    others: Others,
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <h4 className="text-muted">Loading. Please Wait...</h4>
        <i className="material-icons animate-spin text-3xl ml-2">refresh</i>
      </div>
    );
  }
  const CategoryComponent = categoryComponents[category];

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "block", width: "100%" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {Object.keys(categoryComponents).map((cat, index) => (
              <Tab key={index} label={cat} onClick={() => setCategory(cat)} />
            ))}
            {/*    <Tab label="BackEnd" />
          <Tab label="FrontEnd" />ss
          <Tab label="FullStack" />
          <Tab label="Devops" />
          <Tab label="Testing" />
          <Tab label="FlexPLM" />
          <Tab label="SoftSkills" />
          <Tab label="Others" />*/}
          </Tabs>
        </AppBar>

        <CategoryComponent assessment={newContent} />
        {/* <TabPanel value={value} index={0}>
        <DsPrograming assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BackEnd assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FrontEnd assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FullStack assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Devops assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Testing assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <FlexPLM assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <SoftSkills assessment={newContent} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Others assessment={newContent} />
      </TabPanel>*/}
      </Box>
      <AlertComponent
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertMessage("")}
      />
    </>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default TrackAssessment;
