import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { Link } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { getClients } from "../../../../../api/AdminApi";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function ClientData() {

  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = ["Search"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState([]);
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let applicantsList = [];
  useEffect(() => {
    document.title = "Clients";
    loadData();
  }, []);
  const loadData = () => {
    getClients(searchText)
      .then((res) => {
        const userList = res.data.data;
        const constructClientDataList = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const clientData = {
            name: "",
            userName: "",
            contactNo: "",
            status: "",
            noOfSubscriptions: "",
            subscribedFrom: "",
          };
          clientData.name = userList[i].name;
          clientData.userName = userList[i].email;
          clientData.contactNo = userList[i].mobile;
          clientData.status = userList[i].status;
          clientData.noOfSubscriptions = userList[i].subscription
            ? userList[i].subscription.length
            : 0;
          clientData.subscribedFrom = splitByT(userList[i].createdAt);
          constructClientDataList.push(clientData);
        }
        setData(constructClientDataList);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        setLoading(false);
        console.log(error);
      });
  };
  const handleClickBack = () => {
    navigate('/');
  };

  const dialogTemplate = (props) => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
      showDetail();
    }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);
    };
    return {
      /*
                <OnboardEmployee name={userName} email={userEmail}/>    
            */
    };
  };
  const onboardTemplate = (props) => {
    console.log(props.userName)
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
      showDetail();
      //alert("show"+JSON.stringify(userEmail));
    }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.userName);
    };

    return (
      <Link
        to={{
          pathname: "/viewClient",
        }}
        state={{
          clientUserName: userEmail,
        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span style={{ color: currentColor }}>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };

  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      }
    >
      <div className="p-2 m-2">
        <h2 className="p-1 md:text-2xl float-left">Clients Page</h2>
<button
                  
                  className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                     
                  style={{ backgroundColor: currentColor }}
                  onClick={() => handleClickBack()}
                >
                  <span>back</span>
                </button>
      </div>
     

      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
          {/*<ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />*/}
          <ColumnDirective
            field="name"
            headerText="Name"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="userName"
            headerText="UserName"
            width="150"
            textAlign="Center"
          />
          <ColumnDirective
            field="contactNo"
            headerText="ContactNo"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="status"
            headerText="Status"
            width="120"
            textAlign="Center"
          />
          <ColumnDirective
            field="noOfSubscriptions"
            headerText="No of Subscriptions"
            width="120"
            textAlign="Center"
          />
          <ColumnDirective
            field="viewClient"
            headerText="View Client"
            textAlign="Center"
            isPrimaryKey={true}
            width="100"
            template={onboardTemplate}
          />
          <ColumnDirective
            field="subscribedFrom"
            headerText="Subscribed From"
            width="120"
            textAlign="Center"
          />

          {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
}

export default ClientData;
