import React, { useState, useEffect } from "react";
import { Fade } from 'react-reveal';
import { policyHubEn } from '../../../../../../data/content/En/PolicyHubEn';
import { StateContext } from '../../../../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import {ORGDATALIST, PUBLICHOLIDAYLIST } from "../../../../../../config/Config";
import {
  Select,
  MenuItem,
} from "@material-ui/core";
import { retrieveOrgData } from "../../../../../../api/Api";
const LeavePolicy = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() + index
  );
  const [publicHolidayYear, setPublicHolidayYear] = useState(new Date().getFullYear());
  const [holidays, setHolidays] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    retrieveOrgDataList();
  }, []);
  

  const yearChange = (event) => {
    const selectedYear = event.target.value;
    setPublicHolidayYear(selectedYear);
    const selectedYearHolidays = JSON.parse(localStorage.getItem(PUBLICHOLIDAYLIST))[selectedYear] || [];
    selectedYearHolidays.sort((a, b) => {
      return new Date(a.holidayDate) - new Date(b.holidayDate);
    });
    setHolidays(selectedYearHolidays);
  };

  const retrieveOrgDataList = () => {
    retrieveOrgData()
      .then((res) => {
        //localStorage.setItem(ORGDATALIST, JSON.stringify(res.orgDetailList));
        localStorage.setItem(PUBLICHOLIDAYLIST, JSON.stringify(res.holidayMap));
        const selectedYearHolidays = res.holidayMap[publicHolidayYear] || [];
        setHolidays(selectedYearHolidays);
        
      })
      .catch((error) => {
        console.error("Error retrieving holiday list:", error);
      });
  };
  

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn.leavePolicy}</strong></h1>
            <br />
            <h2 className="pages-content-text text-l ml-4 md:ml-10" ><strong>{policyHubEn.effectOn}</strong> </h2>
            <br />
            <h4 className="pages-content-text text-l ml-4 md:ml-10" ><strong>{policyHubEn.holidays}</strong> </h4>
            <br />
            <div className="contact-header ml-4 md:ml-10 form-group">
              <label htmlFor="year"> Public Holiday Year:</label>
              <Select
                      label="Year"
                      value={publicHolidayYear}
                      onChange={(event) => yearChange(event)}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
              <br></br>
            </div>
            <br></br>
            
            <div className="contact-header ml-4 md:ml-10">
              <div className="overflow-x-auto">
                <table className="table-auto border-collapse border border-black-200">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 bg-grey-100 border border-black text-left text-lg font-medium"><strong>Date</strong></th>
                      <th className="px-4 py-2 bg-grey-100 border border-black text-left text-lg font-medium"><strong>Holiday</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(holidays === undefined || holidays === null || holidays.length === 0) ? (
                      <tr>
                        <td colSpan="2">
                          <div className="alert alert-danger" role="alert">
                            <strong>Oops !</strong> Contact HR for public holidays
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {holidays.map((data, index) => (
                          <tr key={index}>
                            <td className="pages-content-text text-lg px-4 py-2 border border-black">{data.holidayDate}</td>
                            <td className="pages-content-text text-lg px-4 py-2 border border-black">{data.holidayName}</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn. sickLeave}</strong></h1>
            <br></br>
            <ul className="list-disc ml-20" >
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.paidSickLeave}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.interveningHolidaysWeekends}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. normalSalaryDuring}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.conditionCertificateissued}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.certificateIssuedSickLeave}</li>
            </ul>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn.vacationLeave }</strong></h1>
            <br></br>
            <ul className="list-disc ml-20">
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.provideAnOpportunity}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. eligibleWorkingDays}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.interveningHolidays }</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.fullyPaidCompany}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.plannedAndReportedPriorly}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.employeeAccount}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.unusedLeaves}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.carryforwardLeave}</li>
            </ul>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn. maternityLeaves}</strong></h1>
            <br></br>
            <ul className="list-disc ml-20">
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.provideLeavesPregnancy}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.paternityLeavePolicy}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.availabilityPaternityLeave }</li>
            </ul>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn.paternityLeaves }</strong></h1>
            <br></br>
            <ul className="list-disc ml-20">
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.provideMaleEmployees }</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.maleEmployeeLeave }</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.unfortunateEvents }</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.companyPaidLeave }</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.interveningHolidays }</li>
            </ul>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn.leaveWithoutPay }</strong></h1>
            <br></br>
            <ul className="list-disc ml-20">
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.policyCompanyEncouragePractice}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.extraLeavesTakenBeyondLimits}</li>
            </ul>
            <br></br>
            <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn. disclaimer }</strong></h1>
            <br></br>
            <ul className="list-disc ml-20" >
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.companyReservesTheRightAlter}</li>
              <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.yaazhtechTerminateYourEmploymentContract}</li>
            </ul>
          </div>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
            crossOrigin="anonymous"
          />
        </div>
      </div>
    </Fade>
  );
};

export default LeavePolicy;
