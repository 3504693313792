import React, { useEffect, useState } from "react";
import { probationActionRequest } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";

const TimeSheet = ({ name, email, timeSheet }) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    document.title = "Time Sheet";
  }, []);
  return (
    <div>
      {/* 
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
      */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  TimeSheet Action
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + " { " + email + " }"}
                  </h3>
                </div>
                <ul className="list-none p-1">
                  {timeSheet.map((data) => (
                    <li className="flex">
                      <p className="p-1">
                        hoursWorked:{" "}
                        <span className=" text-black font-bold p-1">{data.hoursWorked}</span>
                      </p>
                      <p className="p-1">
                        CreatedAt:{" "}
                        <span className="text-black font-bold p-1">{data.createdAt}</span>
                      </p>
                      <p className="p-1">
                        Date:{" "} <span className="text-black font-bold p-1">{data.date}</span>
                      </p>
                      <hr />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default TimeSheet;
