const UiDeveloperEn={

    header:"Direct Intern Opportunities : UI DEVELOPER ",
   w1:"We are providing Direct Intern for the candidates who are interested in learning Frontend Development. The candidates can take their own time to complete the three levels successfully. Once the three levels are completed successfully, They will be considered the intern with 5K stipend per month. The levels are mentioned below",
   eligibility:"Eligibility",
   o1:"BE/BTech or BCA/MCA/Msc",
   stipendOffered:"Stipend Offered",
    s1:"5000 Per Month",
    learn:"Learn More",
    infoLevel:"1.Info Level",
    p1:"Info level contains basic environment setup and contains 14 React JS Questions,",
    environmentSetup:"Environment Setup",
    r1:"Install Visual Studio Code ",
    r2:"Install NodeJS",
    r3:"Install GitHub Desktop Download ",
    r4:"Install Maven",
    r5:"Install DynamoDB zip File",
    r6:"Install Workbench",
    install:"Link for installation",
    linkForReact:"Link for React Js 14 Questions:",
    z1:"https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction" ,
level:"2.Primary level:",
primaryLevel :"Primary level contains ", 
g1:"Profile summary with one sample project explanation, React JS contains 30 questions and FlexPLM has 20 questions",
reactJs:"ReactJS 30 Questions:",
h1:"https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction",
flexPlm:"FlexPLM 20 Questions:",
d1:"https://www.yaazhtech.com/flexPLM-flexPLM-FlexPLM-Self-Intro%202",
dark:"3. Dark Level:",
l1:"This level contains Profile summary, ReactJS 30 questions and Problem solving with real time coding.",


  button:"Apply",
  
  };
  export {UiDeveloperEn};