import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { StaffAugSoftwareDevelopementEn } from '../../../data/content/En/StaffAugSoftwareDevelopementEn';
import { StaffAugSoftwareDevelopementDe } from '../../../data/content/De/StaffAugSoftwareDevelopementDe';
import { Link } from 'react-router-dom';
import { Language } from '../../../config/Config';

const StaffAugSoftwareDevelopement = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      <div className="container">
      <div className="main contact-margin-top" id="contact">
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
          <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? StaffAugSoftwareDevelopementEn.button:StaffAugSoftwareDevelopementDe.button}</span></Link>
          </button>
            <br></br>
            <br></br>
            <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? StaffAugSoftwareDevelopementEn.title:StaffAugSoftwareDevelopementDe.title}</h1>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugSoftwareDevelopementEn.headingContent:StaffAugSoftwareDevelopementDe.headingContent}</p><br></br>
             
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugSoftwareDevelopementEn.unLeashing:StaffAugSoftwareDevelopementDe.unLeashing}</b>


             <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.frontEndDevelopers : StaffAugSoftwareDevelopementDe.frontEndDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.f1 : StaffAugSoftwareDevelopementDe.f1}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.fullStackDevelopers : StaffAugSoftwareDevelopementDe.fullStackDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.full1 : StaffAugSoftwareDevelopementDe.full1}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.javaBackendDevelopers : StaffAugSoftwareDevelopementDe.javaBackendDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.back : StaffAugSoftwareDevelopementDe.back}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.camundaDevelopers : StaffAugSoftwareDevelopementDe.camundaDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.cam : StaffAugSoftwareDevelopementDe.cam}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.mobileAppDevelopers : StaffAugSoftwareDevelopementDe.mobileAppDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.mob1 : StaffAugSoftwareDevelopementDe.mob1}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.devOpsEngineers : StaffAugSoftwareDevelopementDe.devOpsEngineers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.dev1 : StaffAugSoftwareDevelopementDe.dev1}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.digitalMarketingExperts : StaffAugSoftwareDevelopementDe.digitalMarketingExperts}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.digital : StaffAugSoftwareDevelopementDe.digital}</li>
           </p>
            <br></br>


            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.pythonDevelopers : StaffAugSoftwareDevelopementDe.pythonDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.python : StaffAugSoftwareDevelopementDe.python}</li>
           </p>
            <br></br>

            <p className="pages-content-text text-lg ml-4 md:ml-10">
            <b>{Language === 'en' ? StaffAugSoftwareDevelopementEn.goLangDevelopers : StaffAugSoftwareDevelopementDe.goLangDevelopers}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? StaffAugSoftwareDevelopementEn.golang : StaffAugSoftwareDevelopementDe.golang}</li>
           </p>
            <br></br>
             {' '}
             </p>

             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugSoftwareDevelopementEn.conclusion:StaffAugSoftwareDevelopementDe.conclusion}</p><br></br>


             <hr className="my-5" />
               <div className="d-flex justify-content-between"> 
               
                <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }}><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? StaffAugSoftwareDevelopementEn.button:StaffAugSoftwareDevelopementDe.button}</span></Link>
                </button>
              {/* TODO */}
              <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" style={{ backgroundColor: currentColor }} ><Link to ="/staffAugmentation">
                  <span><div className="text-center"/>{Language==='en'? StaffAugSoftwareDevelopementEn.cancelButton:StaffAugSoftwareDevelopementDe.cancelButton}</span></Link>
              </button>
              </div>
              </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default StaffAugSoftwareDevelopement