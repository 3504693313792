// /* eslint-disable import/no-unresolved */
// /* eslint-disable react/no-access-state-in-setstate */
// /* eslint-disable jsx-a11y/scope */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
//import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
} from '../../../../../../config/Config';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
  content,
} from "@syncfusion/ej2-react-grids";
import { retrieveProfileData, updatePassword } from '../../../../../../api/Api';
import { retrieveProfileImage } from '../../../../../../api/EmployeeApi';
import avatar from '../../../../../../assets/images/logos/logoFinal30x30.png';
import { StateContext } from '../../../../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import toast, { Toaster } from 'react-hot-toast';
import { dataBound } from '@syncfusion/ej2-react-schedule';
function MyProfile() {
  const [data, setData] = useState({})
  const [state, setState] = useState({
    user: {},
    employment: '',
    performanceEvaluation: '',
    personalInfo: '',
    employeeLeaveData: '',
    salaryDetails: '',
    accountDetails: '',
    timeSheetList: '',
    educationInfo: '',
    assessmentCard: '',
    timeSheetList: '',
  })
  const handleClick = useContextSelector(
    StateContext,
    (state) => state.handleClick
  );
  const [showLoading, setShowLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const retrieveProfilePic = () => {
    setShowLoading(true);
    retrieveProfileImage()
      .then((res) => {
        // alert("profile"+JSON.stringify(res.data));
        //console.log("data" + JSON.stringify(res));
        setImagePreviewUrl(res.data);
        //showAlert(
        // "Profile image retrieved successfully ",
        // "success"
        // );
        toast.message('Profile image retrieved successfully');
        setShowLoading(true);
      })
      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image does't exist !", "error");
          toast.message('Your profile image doest exist !');
        } else {
          //showAlert(error.message, "error");
          toast(error.message);
        }
        setShowLoading(true);
      });
  };
  useEffect(() => {
    document.title = 'My Profile Data';
    retrieveProfile();
    retrieveProfilePic();
    retrieveProfileData();
  }, []);

  // updateOldPassword(event) {
  //   const req = this.state.updatePasswordRequest;
  //   req.oldPassword = event.target.value;
  //   this.setState({ updatePasswordRequest: req });
  // }

  // updateNewPassword(event) {
  //   const req = this.state.updatePasswordRequest;
  //   req.newPassword = event.target.value;
  //   this.setState({ updatePasswordRequest: req });

  //   const { passwordPattern } = this.state;

  //   const lowerCase = /[a-z]/g;
  //   if (req.newPassword.match(lowerCase)) {
  //     document.getElementById('lowerCase').classList.remove('text-danger');
  //     document.getElementById('lowerCase').classList.add('text-success');
  //     passwordPattern.lowerCase = true;
  //   } else {
  //     document.getElementById('lowerCase').classList.remove('text-success');
  //     document.getElementById('lowerCase').classList.add('text-danger');
  //     passwordPattern.lowerCase = false;
  //   }

  //   const upperCase = /[A-Z]/g;
  //   if (req.newPassword.match(upperCase)) {
  //     document.getElementById('upperCase').classList.remove('text-danger');
  //     document.getElementById('upperCase').classList.add('text-success');
  //     passwordPattern.upperCase = true;
  //   } else {
  //     document.getElementById('upperCase').classList.remove('text-success');
  //     document.getElementById('upperCase').classList.add('text-danger');
  //     passwordPattern.lowerCase = false;
  //   }

  //   const number = /[0-9]/g;
  //   if (req.newPassword.match(number)) {
  //     document.getElementById('number').classList.remove('text-danger');
  //     document.getElementById('number').classList.add('text-success');
  //     passwordPattern.number = true;
  //   } else {
  //     document.getElementById('number').classList.remove('text-success');
  //     document.getElementById('number').classList.add('text-danger');
  //     passwordPattern.number = false;
  //   }

  //   if (
  //     req.newPassword.length > PASSWORD_MIN_LENGTH
  //     && req.newPassword.length < PASSWORD_MAX_LENGTH
  //   ) {
  //     document.getElementById('chars').classList.remove('text-danger');
  //     document.getElementById('chars').classList.add('text-success');
  //     passwordPattern.charsLength = true;
  //   } else {
  //     document.getElementById('chars').classList.remove('text-success');
  //     document.getElementById('chars').classList.add('text-danger');
  //     passwordPattern.charsLength = false;
  //   }
  //   this.setState({ passwordPattern });

  //   if (req.newPassword === req.confirmNewPassword) {
  //     document.getElementById('inputNewPassword').classList.add('is-valid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.add('is-valid');
  //     document
  //       .getElementById('inputNewPassword')
  //       .classList.remove('is-invalid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.remove('is-invalid');
  //   } else {
  //     document.getElementById('inputNewPassword').classList.add('is-invalid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.add('is-invalid');
  //     document.getElementById('inputNewPassword').classList.remove('is-valid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.remove('is-valid');
  //   }
  // }

  // updateConfirmNewPassword(event) {
  //   const req = this.state.updatePasswordRequest;
  //   req.confirmNewPassword = event.target.value;
  //   this.setState({ updatePasswordRequest: req });

  //   const { passwordPattern } = this.state;

  //   const lowerCase = /[a-z]/g;
  //   if (req.confirmNewPassword.match(lowerCase)) {
  //     document.getElementById('lowerCase').classList.remove('text-danger');
  //     document.getElementById('lowerCase').classList.add('text-success');
  //     passwordPattern.lowerCase = true;
  //   } else {
  //     document.getElementById('lowerCase').classList.remove('text-success');
  //     document.getElementById('lowerCase').classList.add('text-danger');
  //     passwordPattern.lowerCase = false;
  //   }

  //   const upperCase = /[A-Z]/g;
  //   if (req.confirmNewPassword.match(upperCase)) {
  //     document.getElementById('upperCase').classList.remove('text-danger');
  //     document.getElementById('upperCase').classList.add('text-success');
  //     passwordPattern.upperCase = true;
  //   } else {
  //     document.getElementById('upperCase').classList.remove('text-success');
  //     document.getElementById('upperCase').classList.add('text-danger');
  //     passwordPattern.lowerCase = false;
  //   }

  //   const number = /[0-9]/g;
  //   if (req.confirmNewPassword.match(number)) {
  //     document.getElementById('number').classList.remove('text-danger');
  //     document.getElementById('number').classList.add('text-success');
  //     passwordPattern.number = true;
  //   } else {
  //     document.getElementById('number').classList.remove('text-success');
  //     document.getElementById('number').classList.add('text-danger');
  //     passwordPattern.number = false;
  //   }

  //   if (
  //     req.confirmNewPassword.length > PASSWORD_MIN_LENGTH
  //     && req.confirmNewPassword.length < PASSWORD_MAX_LENGTH
  //   ) {
  //     document.getElementById('chars').classList.remove('text-danger');
  //     document.getElementById('chars').classList.add('text-success');
  //     passwordPattern.charsLength = true;
  //   } else {
  //     document.getElementById('chars').classList.remove('text-success');
  //     document.getElementById('chars').classList.add('text-danger');
  //     passwordPattern.charsLength = false;
  //   }
  //   this.setState({ passwordPattern });

  //   if (req.newPassword === req.confirmNewPassword) {
  //     document.getElementById('inputNewPassword').classList.add('is-valid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.add('is-valid');
  //     document
  //       .getElementById('inputNewPassword')
  //       .classList.remove('is-invalid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.remove('is-invalid');
  //   } else {
  //     document.getElementById('inputNewPassword').classList.add('is-invalid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.add('is-invalid');
  //     document.getElementById('inputNewPassword').classList.remove('is-valid');
  //     document
  //       .getElementById('inputConfirmNewPassword')
  //       .classList.remove('is-valid');
  //   }
  // }

  // sendUpdatePasswordRequest(event) {
  //   event.preventDefault();

  //   this.setState({ showLoading: true });
  //   const req = this.state.updatePasswordRequest;

  //   if (
  //     req.confirmNewPassword.length < USERNAME_MIN_LENGTH
  //     || req.confirmNewPassword.length > USERNAME_MAX_LENGTH
  //   ) {
  //     this.props.showAlert(
  //       `Your username must contain between ${
  //         USERNAME_MIN_LENGTH
  //       } and ${
  //         USERNAME_MAX_LENGTH
  //       } characters !`,
  //       'error',
  //     );
  //     return null;
  //   }

  //   if (
  //     req.confirmNewPassword.length < PASSWORD_MIN_LENGTH
  //     || req.confirmNewPassword.length > PASSWORD_MAX_LENGTH
  //   ) {
  //     this.props.showAlert(
  //       `Your password must contain between ${
  //         PASSWORD_MIN_LENGTH
  //       } and ${
  //         PASSWORD_MAX_LENGTH
  //       } characters !`,
  //       'error',
  //     );
  //     return null;
  //   }

  //   if (req.newPassword === req.confirmNewPassword) {
  //     const { passwordPattern } = this.state;
  //     if (
  //       passwordPattern.upperCase
  //       && passwordPattern.lowerCase
  //       && passwordPattern.charsLength
  //       && passwordPattern.number
  //     ) {
  //       updatePassword(this.state.updatePasswordRequest)
  //         .then((res) => {
  //           this.props.showAlert('Your password has been changed !', 'success');
  //           this.props.history.push('/me/settings/account');
  //           this.setState({ showLoading: false });
  //         })
  //         .catch((error) => {
  //           if (error.message) {
  //             this.props.showAlert(error.message, 'error');
  //           } else {
  //             this.props.showAlert(
  //               'Sorry! Something went wrong. Please try again!',
  //               'error',
  //             );
  //           }
  //           this.setState({ showLoading: false });
  //           console.log(error);
  //         });
  //     } else {
  //       this.props.showAlert(
  //         'Invalid password : please take care of password constraints !',
  //         'error',
  //       );
  //     }
  //   } else {
  //     this.props.showAlert('Passwords does not match !', 'error');
  //   }
  // }

  // nullCheck(object) {
  //   if (object !== undefined && object !== null) {
  //     return object;
  //   }
  //   return 'NA';
  // }

  const nullCheck = (object) => {
    if (object !== undefined && object !== null) {
      return object;
    }
    return 'NA';
  };

  // const retrieveProfile=()=> {
  //   setState({ showLoading: true });
  //   retrieveProfileData()
  //     .then((res) => {
  //       setState({ user: res });
  //       setState({ employment: res.employment });
  //       setState({
  //         performanceEvaluation: res.employment.performanceEvaluation,
  //       });
  //       setState({
  //         personalInfo: res.employment.profileDetails.personalInfo,
  //       });
  //       setState({
  //         employeeLeaveData: res.employment.employeeLeaveData,
  //       });

  //       showAlert('Profile data retrieved successfully ', 'success');
  //       setState({ showLoading: false });
  //     })
  //     .catch((error) => {
  //       if (error.status === 401) {
  //         showAlert("Your profile image does't exist !", 'error');
  //       } else {
  //         showAlert(error.message, 'error');
  //       }
  //       setState({ showLoading: false });
  //     });
  // }
  const retrieveProfile = () => {
    setShowLoading(true);
    retrieveProfileData()
      .then((res) => {
        //alert('user'+JSON.stringify(res));
        setData(res);
        setState({ ...state, user: res })
        // setState({user: res});
        const data = {
          probationStartDate: "",
          probationEndDate: "",
          employeeOfficialEmailId: "",
          remainingProbationDays: "",
          employeeOfficialEmailId: "",
          employeeId: "",
          panNo: "",
          gender: "",
          dateOfBirth: "",
          emergencyContactNo: "",
          phoneNo: "",
          permanentAddress: "",
          presentAddress: "",
          gender: "",
          sickLeavesAvailable: "",
          availedSickLeave: "",
          vacationLeavesAvailable: "",
          availedVacationLeave: "",
          assessmentScore: "",
          assessmentName: "",
          assessmentTakenOn: "",
          result: "",
          date: '',
          hoursWorked: '',
        }
        setState({ ...state, employment: res.employment });
        setState({ ...state, assessmentCard: res.assessmentCard });
        //alert("employment"+JSON.stringify(res));
        setState({ ...state, performanceEvaluation: res.employment.performanceEvaluation, });
        toast(performanceEvaluation.message);
        setState({ personalInfo: res.employment.profileDetails.personalInfo });
        setState({ employeeLeaveData: res.employment.employeeLeaveData });
        setState({ salaryDetails: res.employment.salaryDetails });
        setState({ timeSheetList: res.employment.timeSheetList });
        setState({ onlineAssessmentList: res.assessmentCard.onlineAssessmentList });
        content.push(data);
        //showAlert('Profile data retrieved successfully ', 'success');
        setShowLoading(false);
        setData(content);
      })

      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image doesn't exist!", 'error');
        } else {
          //showAlert(error.message, 'error');
        }
        setShowLoading(false);
      });
  };

  return (

    <div className="container mx-auto p-4 sm:p-8 text-center">
      <div
        className="flex items-center gap-2 cursor-pointer p-1 "
        onClick={() => handleClick("userProfile")}
      >

        {imagePreviewUrl ? (
          <img
            src={`data:image/jpeg;base64,${imagePreviewUrl}`}
            alt="avatar"
            className="rounded-full h-10 w-10"
          />
        ) : (
          <img
            src={avatar}
            alt="avatar"
            className="rounded-full h-20 w-20"
          />
        )}


      </div>
      <p>
        {' '}

        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-4xl font-bold uppercase ">
          {data.name}
          {' '}
          <span className="text-muted pages-content-text" style={{ fontSize: 11 }}>
            {data.designation}
          </span>
        </h1>
        <span className="pages-content-text" style={{ fontSize: 11 }}>
          Performance Level :

          {data.performanceLevel || 'NA'}
          {data.performancePercent || 'NA'}


        </span>
      </p>
      <div >
        {state.showLoading ? (
          <div className="align-content-center text-center">
            <h3>Profile loading inprogress.....</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              Please wait
            </i>
          </div>
        ) : (
          <div className="grid md:grid-cols  ">
            <div className=''>
              <div >

                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl  lg:text-4xl font-bold uppercase">
                      <span className="text-muted " style={{ fontSize: 17 }}>
                        {data.category || "NA"}
                        {' '}
                        {' '}
                        Data
                      </span>
                    </h1>
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th > Start Date</th>
                      <th className="text-muted ml-5 ">
                        {' '}
                        {data.probationStartDate ||"NA"}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th> Next evaluation </th>
                      <th>
                        {' '}
                        {data.nextEvaluationDate ||"NA"}
                      </th>
                      {' '}
                    </tr>

                    <tr scope="row">
                      <th> End Date</th>
                      <th>
                        {' '}
                        {data.probationEndDate ||"NA" }
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th> Remaining Days </th>
                      <th>
                        {' '}
                        {
                        data.remainingProbationDays ||"NA"
                        }
                      </th>
                      {' '}
                    </tr>
                   
                  <tr scope="row">
                      <th> Official Email</th>
                      <th>
                      {' '}
                      {data.employeeOfficialEmailId || 'NA'}
                    </th>
                      {' '}
                    </tr>

                    <tr scope="row">
                      <th>Employee Id</th>
                      <th>
                        {' '}
                        {data.employeeId || 'NA'}
                      </th>
                    </tr> 
                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="probationStartDate" headerText='Start Date' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="probationEndDate"
                        headerText="End Date"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="remainingProbationDays"
                        headerText="Remaining Days"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="employeeOfficialEmailId"
                        headerText="Official Email"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="employeeId"
                        headerText="Employee Id"
                        width="100"
                        textAlign="Center"

                      />

                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>

              <div>
                {' '}
                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl   lg:text-4xl font-bold uppercase">
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Personal Info
                      </span>
                    </h1>
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th>PanCard</th>
                      <th>
                      {' '}
                      {data.panNo || 'NA'}
                    </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th>Emergency Contact No</th>
                      <th>
                      {' '}
                      {data.emergencyContactNo || 'NA'}
                    </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Mobile No</th>
                      <th>
                        {' '}
                        {data.phoneNo ||  'NA'}
                      </th>
                    </tr>
                    <tr scope="row">
                      <th>Date Of Birth</th>
                      <th>
                        {' '}
                        {data.dateOfBirth || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Permanent Address </th>
                      <th>
                        {' '}
                        {data.permanentAddress || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Present Address</th>
                      <th>
                        {' '}
                        {data.presentAddress || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Gender</th>
                      <th>
                        {' '}
                        {data.gender || 'NA'}
                      </th>
                      {' '}
                    </tr>
                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="panNo" headerText='PanCard' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="emergencyContactNo"
                        headerText="Emergency Contact No"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="phoneNo"
                        headerText="Mobile No"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="dateOfBirth"
                        headerText="Date Of Birth"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="permanentAddress"
                        headerText="Permanent Address "
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="presentAddress"
                        headerText="Present Address "
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="gender"
                        headerText="Gender "
                        width="100"
                        textAlign="Center"

                      />

                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>
              <div>
                {' '}
                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl h lg:text-4xl font-bold uppercase">
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Leave Data
                      </span>
                    </h1>
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th>Remaining Sick Leaves</th>
                      <th>
                        {' '}
                        {data.sickLeavesAvailable ||'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th>Availed Sick Leaves</th>
                      <th>
                        {' '}
                        {data.availedSickLeave || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Remaining Vacation Leaves</th>
                      <th>
                        {' '}
                        {data.vacationLeavesAvailable || 'NA'}
                      </th>
                    </tr>
                    <tr scope="row">
                      <th>Availed Vacation Leaves</th>
                      <th>
                        {' '}
                        {data.availedVacationLeave || 'NA'}
                      </th>
                      {' '}
                    </tr>

                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="sickLeavesAvailable" headerText='Remaining Sick Leaves' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="availedSickLeave"
                        headerText="Availed Sick Leaves"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="vacationLeavesAvailable"
                        headerText="Remaining Vacation Leaves"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="availedVacationLeave"
                        headerText="Availed Vacation Leaves"
                        width="100"
                        textAlign="Center"

                      />


                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>
              <div>
                {' '}
                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl  ml-28 lg:text-4xl font-bold uppercase">
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Employee Salary Data
                      </span>
                    </h1>
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th> Basic Pay of Year: </th>
                      <th>
                        {' '}
                        {data.offeredCTC || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th>Monthly pay: </th>
                      <th>
                        {' '}
                        {data. salaryData|| 'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                   
                    <tr scope="row">
                      <th>Deduction:</th>
                      <th>
                        {' '}
                        {data.lopLeavesTaken || 'NA'}
                      </th>
                    </tr>
                   
                    <tr scope="row">
                      <th>Bonus :</th>
                      <th>
                        {' '}
                        {data.annualIncentive || 'NA'}
                      </th>
                    </tr>
                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="offeredCTC" headerText='Basic Pay of Year' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="salaryData"
                        headerText="Monthly pay"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="lopLeavesTaken"
                        headerText="Total Leaves"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="annualIncentive"
                        headerText="Bonus "
                        width="100"
                        textAlign="Center"

                      />


                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>
              <div>
                {' '}
                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl ml-28 lg:text-4xl font-bold uppercase">
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Result for Assessment
                      </span>
                    </h1>
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th> Maximum marks: </th>
                      <th>
                        {' '}
                        {data.sickLeavesAvailable || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th>Minimum marks: </th>
                      <th>
                        {' '}
                        {data.availedSickLeave || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th>No.of Attempt: </th>
                      <th>
                        {' '}
                        {data.availedSickLeave|| 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th>Result:</th>
                      <th>
                        {' '}
                        {data.vacationLeavesAvailable || 'NA'}
                      </th>
                    </tr>
                    
                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="assessmentScore" headerText='Assessment Score' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="assessmentName"
                        headerText="Assessment Name"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="assessmentTakenOn"
                        headerText="No.of Attempt"
                        width="100"
                        textAlign="Center"

                      />
                      <ColumnDirective
                        field="result"
                        headerText="Result "
                        width="100"
                        textAlign="Center"

                      />


                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>
              <div>
                {' '}
                <table className="table table-hover">
                  <thead>

                    <h1 className="text-3xl sm:text-4xl md:text-5xl  ml-28 lg:text-4xl font-bold uppercase">
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Time Sheet
                      </span>
                    </h1>
                    {/* <tr >
          <th  >
            Date
          </th>
          
          <th >
            Time In 
          </th>

          <th >
           Time Out
          </th>
          
          <th >
           Total Hours
          </th>
        </tr> */}
                  </thead>
                  {/* <tbody>
                    <tr scope="row">
                      <th>  </th>
                      <th>
                        {' '}
                        {data.sickLeavesAvailable ||'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th></th>
                      <th>
                        {' '}
                        {data.availedSickLeave || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    {' '}
                    <tr scope="row">
                      <th></th>
                      <th>
                        {' '}
                        {data.availedSickLeave || 'NA'}
                      </th>
                      {' '}
                    </tr>
                    <tr scope="row">
                      <th></th>
                      <th>
                        {' '}
                        {data.vacationLeavesAvailable || 'NA'}
                      </th>
                    </tr>
                    
                  </tbody> */}
                  <GridComponent
                    dataSource={data}
                    enableHover={true}
                    allowPaging
                    allowFiltering={false}
                    allowGrouping={true}

                  >
                    <ColumnsDirective>
                      <ColumnDirective field="date" headerText=' Date' width='100' textAlign="Center" />
                      <ColumnDirective
                        field="hoursWorked"
                        headerText=" Worked Hours "
                        width="100"
                        textAlign="Center"

                      />


                      {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}






                      {/* <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />
       
       
      
        <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

                      {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Selection,


                        Sort,
                        Filter,
                        Group,

                      ]}
                    />
                  </GridComponent>
                </table>
              </div>
            </div>
          </div>
        )}

      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: '#FF4136',
              color: '#fff',
            },
          },

          success: {
            duration: 6000,
            style: {
              background: '#008000',
              color: '#fff',
            },
          },
        }}
      />
    </div>
  );
}


export default MyProfile;
