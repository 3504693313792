import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
// Import requestChangeRole from "../../../../api/AdminApi";

const AssessmentsGridLine = (props) => {
  const [showModalUserInfo, setShowModalUserInfo] = useState(false);
  const [assessment, setAssessment] = useState(props.assessment);
  const [role, setRole] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const toggle = () => {
    setShowModalUserInfo(!showModalUserInfo);
  };

  const updateRole = (event) => {
    setRole(event.target.value);
  };

  const changeRole = (event) => {
    event.preventDefault();

    setShowLoading(true);
    // Uncomment the line below when the requestChangeRole function is imported
    // requestChangeRole(assessment.user.email, role)
    //   .then((res) => {
    //     props.showAlert(
    //       "Your request has been accepted! Role got changed for the user with email of " +
    //         assessment.user.email,
    //       "success"
    //     );
    //     setShowLoading(false);
    //     toggle();
    //     props.history.push("/admin/dashboard/index");
    //     props.history.push("/admin/dashboard/users");
    //   })
    //   .catch((error) => {
    //     if (error.status === 401) {
    //       props.showAlert("Your username or email does not exist!", "error");
    //     } else {
    //       props.showAlert(error.message, "error");
    //     }
    //     setShowLoading(false);
    //   });
  };

  return (
    <tr>
    {assessment ? (
      <th scope="row">
        <NavLink
          to={{
            pathname: "/me/assessment/onlineAssessment",
            assessmentProps: {
              assessment: assessment,
            },
          }}
          exact
        >
          <button type="button" className="btn btn-light">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        </NavLink>
      </th>
    ) : (
      <th>NA</th>
    )}
    <th>{assessment.skill}</th>
    <th scope="row">
      {assessment.duration}
      <small className="text-muted"> mins</small>
    </th>
    <th>{assessment.totalScore}</th>
    <th>{assessment.minPass}</th>
    <th>{assessment.status}</th>
    <th>{assessment.result ? assessment.result : "pending"}</th>
    <th>{assessment.scored ? assessment.scored : "pending"}</th>

    <Modal isOpen={showModalUserInfo} toggle={toggle} className={props.className}>
      <ModalHeader toggle={toggle}>
        <div className="align-content-center align-items-center align-self-center text-center">
          {" " + assessment.skill + " "}
        </div>
      </ModalHeader>
      <ModalBody>
        <p>
          <b>Skill:</b> {assessment.skill}
        </p>
        <p>
          <b>Account creation date:</b> {assessment.totalScore}
        </p>
        <p>To change role:</p>
        <form onSubmit={(e) => changeRole(e)}>
          <select
            className="w3-select p-2 border rounded-md"
            name="option"
            value={role}
            onChange={(event) => updateRole(event)}
          >
            <option value="" disabled>
              Choose your option
            </option>
            <option value="HR">HR</option>
            <option value="ADMIN">ADMIN</option>
            <option value="USER">USER</option>
            <option value="CLIENT">CLIENT</option>
            <option value="EMPLOYEE">EMPLOYEE</option>
            <option value="EMPTY">EMPTY</option>
          </select>
          <br></br>
          <button type="submit" className="btn btn-success btn-lg mt-4">
            Change Role
          </button>
        </form>
      </ModalBody>
    </Modal>
  </tr>
);
};

export default AssessmentsGridLine;