
import React, { useEffect, useState } from "react";
import { contentData } from "../../../../../api/commonApi";
import { CustomInput, FormGroup } from 'reactstrap';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../src/contexts/StateContext';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

const Content = (props) => {

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const navigate = useNavigate;
  const [state, setState] = useState({

    primaryHeader: "",
    category: "java",
    
    subCategory: "",
    childCategory: "",
    contentInfoData: [
      {
        contentHeader: "",
        contentBody: "",
        codeSnippet: "",
        contentImageBody: null,
        contentImageName: "",
        youTubeLink:"",
        invalidContentFile: false,
      },
    ],
    showLoading: false,
  })

  useEffect(() => {
    document.write = "My content data settings";

  }, [])

  const primaryHeader = (event) => {
    setState({ ...state, primaryHeader: event.target.value });
  }
  const handleYoutubeLinkAddClick = () => {
    setState({
      ...state,
      youtubeLinkList: [
        ...state.youtubeLinkList,
        {
         videoLink:"",
        },
      ],
    });
  };
  
  const handleYoutubeLinkRemoveClick = (index) => {
    const list = [...state.youtubeLinkList];
    list.splice(index, 1);
    setState({ ...state, youtubeLinkList: list });
  };
  const handleYouTubeLink = (e, index) => {
    const { name, value } = e.target;
    const list = [...state.youtubeLinkList];
    list[index][name] = value;
    setState({ ...state, youtubeLinkList: list });
  };

  const category = (event) => {
    setState({ ...state, category: event.target.value });
  }

  const subCategory = (event) => {
    setState({ ...state, subCategory: event.target.value });
  }

  const childCategory = (event) => {
    setState({ ...state, childCategory: event.target.value });
  }


  const handleInfoAddClick = () => {
    // Get the last content object
    const lastContent = state.contentInfoData[state.contentInfoData.length - 1];
  
    // Create a new content object with default values
    const newContent = {
      contentHeader: "",
      contentBody: "",
      codeSnippet: "",
      contentImageBody: null,
      contentImageName: "",
      invalidContentFile: false,
      youTubeLink: "",  // Include default value for YouTube link
    };
  
    // Add the new content object to the contentInfoData array
    setState(prevState => ({
      ...prevState,
      contentInfoData: [...prevState.contentInfoData, newContent],
    }));
  };
  

  const toBase64 = (fileData) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const fileDataConverter = async (fileData) => {
    try {
      const result = await toBase64(fileData);
      return result;
    } catch (error) {
      console.error(error);
      return;
    }

  };
  const handleContentLink = (e, index) => {
    const { name, value } = e.target;

    if (name === "contentImageBody") {


    } else if (name === "youtubeLink") {
      const list = [...state.contentInfoData];
      list[index][name] = value;
      setState({ ...state, contentInfoData: list });
    } else {
      const list = [...state.contentInfoData];
      list[index][name] = value;
      setState({ ...state, contentInfoData: list });
    }
  };

  // handle click event of the onchange method
  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    if (name === "contentImageBody") {
      const files = e.target.files;
      if (files && files.length) {
        const [{ size, name }] = files;
        const maxSize = 100000;
        if (size < maxSize) {
          const list = [...state.contentInfoData];
          list[index].contentImageName = name;
          list[index].invalidContentFile = false;

          //alert("files[0["+files[0])

          fileDataConverter(files[0]).then((response) => {
            list[index].contentImageBody = response;

            //alert("response"+response)

            setState({ ...state, contentInfoData: list });
          });

        } else {
          const list = [...state.contentInfoData];
          list[index].contentImageName = "";
          list[index].invalidContentFile = true;
          setState({ ...state, contentInfoData: list });
          toast("image size should not exceed 100kb", "error");
          setState({ ...state, showLoading: false });
          //props.history.push("/publish/content");
        }
      }
    }
    const list = [...state.contentInfoData];
    list[index][name] = value;
    setState({ ...state, contentInfoData: list });
  };

  // handle click event of the Remove button

  const handleInfoRemoveClick = (index) => {
    const list = [...state.contentInfoData];
    list.splice(index, 1);
    setState({ ...state, contentInfoData: list });
  };

  const publishContentUpdate = (event) => {
    event.preventDefault();
    setState({ ...state, showLoading: true });
    const content = state;

    //alert("content details" + JSON.stringify(content));


    contentData(content)
      .then((response) => {
        const data = response.data;
       // toast(data.message);
        toast.success("Content is added successfully",data.message);
        setState({ ...state, showLoading: false });

        setState({
          primaryHeader: "",
          category: "",
          subCategory: "",
          childCategory: "",
          publishedBy: "",
          contentInfoData: [
            {
              contentHeader: "",
              contentBody: "",
              codeSnippet: "",
              contentImageBody: null,
              contentImageName: "",
              invalidContentFile: false,
            },
          ],
          showLoading: false,
        });
        navigate("/")
        // props.history.push("/");

      })

      .catch((error) => {

        //props.showAlert(error, "error");
        setState({ ...state, showLoading: false });
      });


  };

  const { contentInfoData } = state;

  return (
    <div className="w-full md:10 mx-auto p-3">
      <h2 className="md:text-xl sm:text-lg p-3 font-semibold text-center uppercase mb-3">PUBLISH CONTENT </h2>
      <br></br>
      <div>
        {state.showLoading ? (
          <div className="align-content-center text-center">
            <h3>Updating your content data</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              refresh
            </i>
          </div>
        ) : (
          <form onSubmit={publishContentUpdate}>
            <button className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="submit"
              data-ripple-light="true"
              style={{ color: currentColor }}>
              Submit
            </button>
            <div className="grid md:grid-cols-3">
              <div className="grid md:grid-row">
                <div className="mt-2 mx-3">
                  <label htmlFor="primaryHeader">Primary Header</label>
                  <br />
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="primaryHeader"
                    name="primaryHeader"
                    placeholder="primary header"
                    required
                    autoComplete="on"
                    onChange={primaryHeader}
                  />
                </div>



                <div className="mt-2 mx-3">
                  <label htmlFor="category"> Category</label>
                  <select
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"

                    //focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"

                    // className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="option"
                    value={state.category}
                    onChange={(event) => category(event)}
                  >
                    <option value="java">java</option>
                    <option value="golang">golang</option>
                    <option value="environmental-setup">
                      environment-setup
                    </option>
                    <option value="javascript">
                      javascript
                    </option>
                    <option value="flexPLM">
                      flexPLM
                    </option>
                    <option value="aws">
                      aws
                    </option>
                    <option value="identity-access-management">
                      identity-access-management
                    </option>
                    <option value="reactJs">reactJs</option>
                    <option value="camunda">camunda</option>
                    <option value="testing">testing</option>
                    <option value="mainframe-modernization">mainframe-modernization</option>
                    <option value="soft-skills">soft-skills</option>
                    <option value="data-structure-algorithms">data-structure-algorithms</option>
                    <option value="springboot">springboot</option>
                    <option value="database">database</option>
                    <option value="evaluation">evaluation</option>
                    <option value="others">others</option>

                  </select>
                </div>
                <div className="mt-2 mx-3">
                  <label htmlFor="subCategory">Subcategory</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="subCategory"
                    name="subCategory"
                    placeholder="subcategory"
                    required
                    autoComplete="on"
                    onChange={subCategory}
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label htmlFor="childCategory">Child Category</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="childCategory"
                    name="childCategory"
                    placeholder="Child category"
                    required
                    autoComplete="on"
                    onChange={childCategory}
                  />
                </div>
              </div>
              <div className="grid md:grid-row">
                <div className="mt-2 mx-3">

                  {contentInfoData.map((x, i) => {
                    return (
                      <div>
                        <label htmlFor="contentHeader" className="">
                          Content Header
                        </label>
                        <input
                          type="text"
                          className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                          id="contentHeader"
                          name="contentHeader"
                          placeholder="content header"
                          required
                          autoComplete="on"
                          value={x.contentHeader}
                          onChange={(e) => handleContentInfo(e, i)}
                        />
                        <label htmlFor="contentBody" className="">
                          Content Body
                        </label>
                        <textarea
                          type="text"
                          className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                          id="contentBody"
                          name="contentBody"
                          required
                          autoComplete="on"
                          placeholder="content body"
                          value={x.contentBody}
                          onChange={(e) => handleContentInfo(e, i)}
                        />
                                                <label htmlFor="codeSnippet" className="">
                          Code Snippet
                        </label>
                        <textarea
                          type="text"
                          className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                          id="codeSnippet"
                          name="codeSnippet"
                          autoComplete="on"
                          placeholder="code snippet"
                          value={x.codeSnippet}
                          onChange={(e) => handleContentInfo(e, i)}
                        />
                                                <label htmlFor="youTubeLink" className="">
                        youTubeLink
                        </label>
                        <input
                          type="text"
                          className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                          id="youTubeLink"
                          name="youTubeLink"
                          autoComplete="on"
                          placeholder="youTubeLink"
                          value={x.youTubeLink}
                          onChange={(e) => handleContentInfo(e, i)}
                        />


                        <label htmlFor="profilePic">Content Image Body</label>

                        <FormGroup>
                          <CustomInput
                            type="file"
                            id="exampleCustomFileBrowser"
                            name="contentImageBody"
                            className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            label={
                              x.contentImageName ||
                              "choose an image file size less than 100kb"
                            }
                            onChange={(e) => handleContentInfo(e, i)}
                            invalid={x.invalidContentFile}
                          />
                        </FormGroup>

                        {contentInfoData.length !== 1 && (
                          <button
                            className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            onClick={handleInfoRemoveClick}
                            style={{ color: currentColor }}
                          >
                            Remove
                          </button>
                        )}
                        {contentInfoData.length - 1 === i && (
                          <button
                            className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            onClick={handleInfoAddClick}
                            style={{ color: currentColor }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

          </form>
        )}
        
      </div>
      <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />
      
    </div>
  );
}


export default Content;
