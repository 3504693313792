import React from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { DirectInternEn } from '../../data/content/En/DirectInternEn';
import { DirectInternDe } from '../../data/content/De/DirectInternDe';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../../components/StaticButton/Button';


const DirectInternMain = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const language = useContextSelector(StateContext, state => state.language);

  
  const content = language === 'EN' ? DirectInternEn : DirectInternDe;

  return (
    <div className="container mx-auto px-4 md:px-8">
      <Helmet>
        <title>Launch Your Tech Career: Yaazhtech Direct Intern Program (Become an Intern by Learning In-Demand Skills)</title>
        <meta name="description" content="Aspiring web developer, software engineer, or IT professional? Yaazhtech's Direct Intern Program offers self-paced learning & hands-on projects to land a paid internship!" />
      </Helmet>

      <div className="visually-hidden" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: '0' }}>
        <h1>Launch Your Tech Career: Yaazhtech Direct Intern Program (Become an Intern by Learning In-Demand Skills)</h1>
      </div>

      <div className="py-10">
        <div className="text-center md:text-left">
          <h2 className="text-3xl font-bold uppercase" style={{ color: currentColor }}>
            {content.title}
          </h2>
          <p className="mt-4 text-lg">
            {content.titleExplain}
          </p>
        </div>

        <div className="mt-8 text-center md:text-left">
          <Button text={content.applyButton} navigation="/directIntern"/>
          
        </div>

        <div className="mt-8">
          <p className="text-lg font-bold">{content.heading}</p>
          <p className="mt-4 text-lg">{content.headingExplain}</p>

          <ul className="mt-4 space-y-2">
            {['l1', 'l2', 'l3', 'l4', 'l5', 'l6', 'l7', 'l8'].map((item, index) => (
              <li key={index} className="text-lg">
                <span className="font-bold">{content[`label${index + 1}`]}: </span>
                {content[item]}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-8">
          <p className="text-lg font-bold">{content.beyond}</p>
          <p className="mt-4 text-lg">{content.beyondExplain}</p>

          <ul className="mt-4 space-y-2">
            {['l9', 'l10', 'l11', 'l12', 'l13'].map((item, index) => (
              <li key={index} className="text-lg">
                <span className="font-bold">{content[`label${index + 9}`]}: </span>
                {content[item]}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-8">
          <p className="text-lg">
            {content.ready}
            <Link to="/" className="text-blue-500 font-bold">Visit our Careers Page</Link>{' '}
            to explore available internships and{' '}
            <Link to="/contact" className="text-blue-500 font-bold">Contact Us</Link>{' '}
            if you have any questions.
          </p>
        </div>

        <p className="mt-8 text-lg">{content.passion}</p>
      </div>

      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
        crossOrigin="anonymous"
      />
    </div>
  );
};

export default DirectInternMain;
