import React, { useEffect, useState } from "react";
import { StateContext } from "./contexts/StateContext";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useContextSelector } from "use-context-selector";
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  ScrollRestoration,
  Outlet,
} from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { Footer, Navbar, Sidebar, ThemeSettings } from "./components";
import Navigation from "./components/Navigation";

const Layout = () => {
    const activeMenu = useContextSelector(
        StateContext,
        (state) => state.activeMenu
      );
    
      const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
      );
      const themeSettings = useContextSelector(
        StateContext,
        (state) => state.themeSettings
      );
      const setThemeSettings = useContextSelector(
        StateContext,
        (state) => state.setThemeSettings
      );
      const currentMode = useContextSelector(
        StateContext,
        (state) => state.currentMode
      );
      const contentEndpoints = useContextSelector(
        StateContext,
        (state) => state.contentEndpoints
      );
      const isLoggedIn = useContextSelector(
        StateContext,
        (state) => state.isLoggedIn
      );
      const setIsLoggedIn = useContextSelector(
        StateContext,
        (state) => state.setIsLoggedIn
      );
      const role = useContextSelector(StateContext, (state) => state.role);
      const setRole = useContextSelector(StateContext, (state) => state.setRole);
      const handleClick = () => {
        const currentUrl = window.location.href;
    
        // Check if the current page is the career page
        if (
          currentUrl.includes("/trainingAndHiring") ||
          currentUrl.includes("/directHire") ||
          currentUrl.includes("/education") ||
          currentUrl.includes("/directIntern")
        ) {
          // Redirect to a different WhatsApp number for career pages
          window.location.href = "https://wa.me/919543490067";
        } else {
          // Redirect to the default WhatsApp number for other pages
          window.location.href = "https://wa.me/916382289060";
        }
      };
  return (
    <div
    className={
      currentMode === "Dark"
        ? "flex relative bg-dark-gradient-r"
        : "flex relative bg-light-gradient-r"
    }
  >
    <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
      <TooltipComponent content="whatsup" position="Top">
        <button
          type="button"
          onClick={handleClick}
          style={{ background: "green", borderRadius: "50%" }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <FaWhatsapp />
        </button>
      </TooltipComponent>
      <br></br>
      {/* <TooltipComponent content="Settings" position="Top">
        <button
          type="button"
          onClick={() => setThemeSettings(true)}
          style={{ background: currentColor, borderRadius: "50%" }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <FiSettings />
        </button>
      </TooltipComponent> */}
    </div>
    {/* {activeMenu ? (
      <div className="w-72 fixed sidebar">
        <Sidebar />
      </div>
    ) : (
      <div className="w-0">
        <Sidebar />
      </div>
    )} */}
    <div
      className={
        currentMode === "Dark"
          ? activeMenu
            ? "bg-white min-h-screen  w-full"
            : "bg-white w-full min-h-screen flex-2"
          : activeMenu
          ? "bg-white min-h-screen  w-full"
          : "bg-white w-full min-h-screen flex-2"
      }
    >
      <div className="sticky top-0 navbar w-full ">
        <Navbar />
      </div>
      <div>
        {themeSettings && <ThemeSettings />}
        <ScrollRestoration />
        <Outlet />
      </div>
      <Footer />
    </div>
  </div>
  )
}

export default Layout;