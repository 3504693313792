const EducationEn={
    welcome:"Empower Your Academic Journey with YaazhTech's Arrear Coaching Program ",
    summary:"At YaazhTech, we understand the challenges that students may face in their engineering education journey. If you find yourself with arrears in engineering subjects, our specialized Arrear Coaching Program is tailored to provide targeted support and guidance. Let YaazhTech be your partner in overcoming academic obstacles and excelling in your engineering studies.",

    whyChoose:"Why Choose YaazhTech's Arrear Coaching?",

    subjectMatterExperts:"1. Subject-Matter Experts:",
    subjectSummary:"Our coaching program is led by seasoned educators and industry professionals who are experts in the respective engineering subjects. Benefit from their wealth of knowledge and experience as they guide you through the intricacies of the coursework.",

    personalizedLearningPlans:"2. Personalized Learning Plans:",
    personalSummary:"Recognizing that each student has unique strengths and challenges, we design personalized learning plans to address your specific needs. Our goal is to help you grasp difficult concepts and build a strong foundation for success.",

    comprehensiveCourseCoverage:"3. Comprehensive Course Coverage:",
    comprehensiveSummary:"YaazhTech's Arrear Coaching covers a wide array of engineering subjects. Whether it's mathematics, electronics, computer science, or any other discipline, we have dedicated modules to support your learning and ensure a holistic understanding of the subject matter.",

    interactiveAndEngagingSessions:"4. Interactive and Engaging Sessions:",
    interactiveSummary:"Our coaching sessions are not just lectures; they are interactive and engaging. We use a variety of teaching methods, including real-world examples and practical applications, to make the learning experience enjoyable and effective.",

    flexibleScheduling:"5. Flexible Scheduling:",
    flexibleSummary:"We understand the importance of flexibility in your busy academic schedule. Our coaching sessions are designed to accommodate your timetable, ensuring that you can access support when you need it the most.",

    benefits:"Benefits of YaazhTech's Arrear Coaching Program:",

    confidenceBuilding:"Gain the confidence to tackle challenging engineering concepts head-on. Our program is structured to boost your confidence and empower you to excel in your arrear subjects.",
    focusedLearning:"Experience focused and targeted learning that addresses the specific areas where you need improvement. We prioritize the topics that are crucial for your success in clearing arrear exams.",
    practicalApplication:"Bridge the gap between theory and application. Our coaching emphasizes practical problem-solving skills, preparing you not just for exams but for real-world engineering challenges.",
    continuousSupport:"Receive ongoing support throughout your arrear coaching journey. Our team is committed to your success and is ready to assist you with any questions or challenges you may encounter.",

    conclusion:"Embark on a journey of academic excellence with YaazhTech's Arrear Coaching Program. Let us guide you towards success in your engineering studies. Contact us today to enroll and take the first step towards mastering your arrear subjects.",
    button:"Apply"
}
export {EducationEn};