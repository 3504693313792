import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import NavLinks from "./NavLinks";
import LanguageSelector from "./LanguageSelector";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  EMPLOYEE_TOKEN,
  CLIENT_TOKEN,
  FINANCIER_TOKEN,
  ORGDATALIST,
  PUBLICHOLIDAYLIST,
  DEVELOPERLIST,
} from "../config/Config";
import { yaazhtechLogo } from "../assets/images";

const Navigation = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleActionClick = () => {
    setOpen(false);
  };
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  function handleLogout() {
    //alert('inside logout line 1');
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    localStorage.removeItem(HR_TOKEN);
    localStorage.removeItem(EMPLOYEE_TOKEN);
    localStorage.removeItem(CLIENT_TOKEN);
    localStorage.removeItem(FINANCIER_TOKEN);
    localStorage.removeItem(ORGDATALIST);
    localStorage.removeItem(PUBLICHOLIDAYLIST);
    localStorage.removeItem(DEVELOPERLIST);
    //setRole("desg");
    //alert('inside logout');
    setIsLoggedIn(false);
    navigate("/");

    // this.showAlert("Your are no longer logged in !", "success");
    // this.props.history.push("/");
  }
  const formatDate = (date) => {
    return date.toLocaleDateString();
  };
  const formatTime = (time) => {
    return time.toLocaleTimeString();
  };
  return (
    <nav className="p-1">
      <div className="flex items-center font-medium">
        <div className="z-50  md:w-auto w-full flex">
          <div className="flex md:mr-40 items-center">
            {/* Logo with color-changing effect and background color #f5f5f5 */}
            <div className=" -mt-4 bg-[#f5f5f5] flex items-center justify-center">
              <img
                src={yaazhtechLogo}
                alt="logo"
                className="object-contain  animate-rainbow"
              />
            </div>
            <h6 className=" flex-col   mt-2 md:mt-0 text-xs ">
              <div>
                G :
                {formatDate(currentTime)}
              </div>
              <div>
                T :
                {formatTime(currentTime)}
              </div>
            </h6>
            {/* <h1 className="columns-1 items-center mt-4">
    <span className="md:text-2xl text-xl md:mt-0 font-semibold">Yaazhtech</span>
    <div className="p-1 text-transparent  bg-clip-text bg-gradient-to-r from-blue-400 via-pink-500 to-purple-500 text-center"
    style={{fontSize:11}}>
      Teamwork Makes Dream Work
  </div>
  </h1> */}

            {/* Tagline */}

            {/* Company name */}
          </div>

          <div className={`${open ? "hidden" : "md:block"} md:hidden mt-2 `}>
            <LanguageSelector />
          </div>

          <div
            className="text-3xl md:hidden flex items-center"
            onClick={() => setOpen(!open)}
          >
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden  items-center   gap-3">
          <NavLinks actionClick={handleActionClick} />

          <li>
            <Link
              to="/"
              className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600"
            >
              Pricing
            </Link>
          </li>
          <li>
            <Link
              to={isLoggedIn ? "#" : "/login"}
              onClick={isLoggedIn ? handleLogout : null}
              className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600"
            >
              {isLoggedIn ? "Logout" : "Login"}
            </Link>
          </li>
          <LanguageSelector />
        </ul>

        <div className="md:block hidden">{/* <Button /> */}</div>
        {/* Mobile nav */}
        <ul
          className={`
      md:hidde bg-[#f5f5f5] fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
      duration-500 ${open ? "left-0" : "left-[-100%]"}
      `}
        >
          <NavLinks actionClick={handleActionClick} />

          <li>
            <Link
              to="/"
              className="py-7 px-3 inline-block text-lg text-dark"
              onClick={handleActionClick}
            >
              Pricing
            </Link>
          </li>
          <li>
            {/* <Link to="/login" className="py-7 px-3 inline-block" onClick={handleActionClick}>
            Login
          </Link> */}
            <Link
              to={isLoggedIn ? "#" : "/login"}
              onClick={isLoggedIn ? handleLogout : handleActionClick}
              className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600"
            >
              {isLoggedIn ? "Logout" : "Login"}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Navigation;
