import React, { useState, useEffect } from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../../src/contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { skillData, skillDetails } from "../../../../../../api/commonApi";

const SkillsDataSettings = (props) => {
  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);
  const [skills, setSkills] = useState(
    {
      skillData: [{
        primarySkill: "",
        experienceSkillMonth: "",
      }],
    }
  );
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "My account skills data settings";
  }, []);

  const handleSkillAddClick = () => {
    setSkills({
      ...skills,
      skillData: [
        ...skills.skillData,
        {
          primarySkill: "",
          experienceSkillMonth: "",
        },
      ],
    });
  };

  const handleSkillList = (e, index) => {
    const { name, value } = e.target;
    const list = [...skills.skillData];
    list[index][name] = value;
    setSkills({ ...skills, skillData: list });
  };

  const handleSkillRemoveClick = (index) => {
    const list = [...skills.skillData];
    list.splice(index, 1);
    setSkills({ ...skills, skillData: list });
  };

  const fullSkillList = (event) => {
    event.preventDefault();
    setShowLoading(true);

    
    const skill = skills;


    skillDetails(skill)
      .then(response => {
        const data = response.data;
        toast.success(data.message);
        setShowLoading(false);

       setSkills({
        skillData: [{
          primarySkill: "",
          experienceSkillMonth: "",
        }],
      }
    )
      })
      .catch(error => {
        toast.error(error.message);
        toast.error("error");
        setShowLoading(false);
      });
  };
const{skillData}=skills;

  return (
    <div className="text-center">
      <h2 className='text-2xl ml-10 font-semibold mt-5 flex items-center justify-center'>Add Your TechStack</h2>
      <br></br>
      <div>
        {showLoading ? (
          <div className="align-content-center text-center">
            <h3>Updating your Skills data</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
          </div>
        ) : (
          <form onSubmit={(e) => fullSkillList(e)}>
            <div className="w-full md:w-3/6 mx-auto p-3">
              <div >
                {skillData.map((skill, i) => (
                  <div key={i} >
                    <div className="grid md:grid-cols-2">
                      <label htmlFor="primarySkill" className='text-xl text-gray-500'>Primary Skills:</label>

                      <input
                        type="text"
                        className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500 "
                        id={"primarySkill-${i}"}
                        name="primarySkill"
                        placeholder="Enter  primary skills"
                        required
                        autoComplete="on"
                        value={skill.primarySkill}
                        onChange={(e) => handleSkillList(e, i)}
                      />
                      <br />
                    </div>
                    <div className="grid md:grid-cols-2">
                      <label htmlFor="experienceSkillMonth" className='text-xl text-gray-500'>Experience Skills:</label>

                      <input
                        type="number"
                        className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                        id={`experienceSkillMonth-${i}`}
                        name='experienceSkillMonth'
                        required
                        autoComplete="on"
                        placeholder='Enter  experience skills in month'
                        value={skill.experienceSkillMonth}
                        onChange={(e) => handleSkillList(e, i)}
                      />
                    </div>
                    <br></br>
                    {skillData.length !== 1 && (
                      <button

                        className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"

                        style={{ color: currentColor }}
                        onClick={() => handleSkillRemoveClick(i)}
                      >
                        <span>Remove</span>
                      </button>
                    )}
                    {skillData.length - 1 === i && (
                      <button
                        className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"

                        style={{ color: currentColor }}
                        onClick={handleSkillAddClick}
                      >
                        <span>Add</span>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <br></br>
            <button
              className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"

              style={{ color: currentColor }}
              type="submit"
            >
              <span>Submit</span>
            </button>
          </form>
        )}
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default SkillsDataSettings;