import React, { useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
  Group,
  PdfExport,
  Search,
  Inject,
} from "@syncfusion/ej2-react-grids";

import {
  AppBar,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { retrieveProfileData } from "../../../../../../api/Api";
import { resumeGenerationApiCall } from "../../../../../../api/HrApi";
import { retrieveProfileImage } from "../../../../../../api/EmployeeApi";
import avatar from "../../../../../../assets/images/logos/logoFinal30x30.png";
import AlertComponent from "../../../../../../AlertComponent";
import PerformanceTab from "../../../../hr/dashboard/PerformanceTab";
import CompactTrainingPerformance from "../../../../hr/dashboard/CompactTrainingPerformance";
const toolbarOptions = ["Search"];
const ProfileTab = ({
  imagePreviewUrl,
  personalData,
  accountDetails,
  jobApplication,
  employment,
}) => {
 
   
    const handleDownloadResume = () => {
      resumeGenerationApiCall()
        .then((res) => {
          let binaryString = window.atob(res.data);
          let binaryLen = binaryString.length;
          let bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          let blob = new Blob([bytes], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download ="resume.pdf";
          link.click();
          
        })
        .catch((error) => {
          console.error('Error generating resume:', error);
        });
    };
    
  
  

  return (
    <Container>
      <Card
        style={{
          marginTop: "16px",
          cursor: "pointer",
          backgroundColor: "#e6f7ff",
        }}
      >
          {/* Download Resume Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadResume}
            style={{ marginTop: "16px" }}
          >
            Download CV
          </Button>
        <CardContent>
         
          <Typography variant="h5" color="primary">
            Profile Information
          </Typography>

          {imagePreviewUrl ? (
            <img
              src={`data:image/jpeg;base64,${imagePreviewUrl}`}
              alt="Profile"
              style={{
                width: "100%",
                maxWidth: "100px",
                borderRadius: "50%",
                marginTop: "8px",
              }}
            />
          ) : (
            <img
              src={avatar}
              alt="Profile"
              style={{
                width: "100%",
                maxWidth: "100px",
                borderRadius: "50%",
                marginTop: "8px",
              }}
            />
          )}
          {personalData ? (
            <>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>Name:</span>
                {personalData.name}
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>Role:</span>{" "}
                {personalData.role}
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>Email:</span>
                {personalData.emailId}
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>PAN No:</span>
                {personalData.panNo}
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>Aadhar No:</span>{" "}
                {personalData.aadharNo}
              </Typography>
              <Typography>
                {" "}
                <span style={{ color: "#6b7280" }}>Mobile:</span>{" "}
                {personalData.phoneNo}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Emergency Contact No:</span>{" "}
                {personalData.emergencyContactNo}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Date Of Birth:</span>{" "}
                {personalData.dateOfBirth}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Gender:</span>
                {personalData.gender}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Present Address:</span>{" "}
                {personalData.presentAddress}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Permanent Address:</span>{" "}
                {personalData.permanentAddress}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Father Name:</span>{" "}
                {personalData.fatherName}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Mother Name:</span>
                {personalData.motherName}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Father DOB:</span>
                {personalData.fatherDob}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Mother DOB:</span>{" "}
                {personalData.motherDob}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Blood Group:</span>{" "}
                {personalData.bloodGroup}
              </Typography>
            </>
          ) : null}
          {accountDetails ? (
            <>
              <Typography variant="h5" color="primary">
                Account Information
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Bank Name:</span>
                {accountDetails.bankName}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Account No:</span>{" "}
                {accountDetails.accountNo}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>IFSC Code:</span>{" "}
                {accountDetails.ifscCode}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>UPI Id:</span>
                {accountDetails.upiId}
              </Typography>
            </>
          ) : null}
          {jobApplication ? (
            <>
              <Typography variant="h5" color="primary">
                Job Application
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>ID:</span> {jobApplication.id}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Applied For:</span>{" "}
                {jobApplication.appliedFor}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Created At:</span>
                {jobApplication.createdAt}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>History:</span>{" "}
                {jobApplication.history}
              </Typography>
            </>
          ) : null}
          {employment ? (
            <>
              <Typography variant="h5" color="primary">
                Employee Details
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Employee ID:</span>{" "}
                {employment.employeeId}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Employee Joining Date:</span>{" "}
                {employment.employeeJoiningdate}
              </Typography>
              <Typography>
                <span style={{ color: "#6b7280" }}>Employee PF No:</span>
                {employment.pfNo}
              </Typography>
            </>
          ) : null}

       
        </CardContent>
      </Card>
    </Container>
  );
};


  
const MonthlySalaryTab = ({ salaryDataDetails, category }) => (
  <Container>
    <Card
      style={{
        marginTop: "16px",
        cursor: "pointer",
        backgroundColor: "#e6f7ff",
      }}
    >
      <CardContent>
        <Typography variant="h5" color="primary">
          Salary Data
        </Typography>

        <Typography>
          <strong>Category:</strong> {category}
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>NoOfDaysWorked</TableCell>
                <TableCell>PayableAmount</TableCell>
                <TableCell>NoOfExtraDaysWorked</TableCell>
                <TableCell>NoOfWorkingDays</TableCell>
              </TableRow>
            </TableHead>
            {salaryDataDetails && salaryDataDetails.salaryDataList ? (
              <TableBody>
                {salaryDataDetails.salaryDataList.map((data) => (
                  <TableRow key={data.date}>
                    <TableCell>{data.date}</TableCell>
                    <TableCell>{data.noOfDaysWorked}</TableCell>
                    <TableCell>{data.payableAmount}</TableCell>
                    <TableCell>{data.noOfExtraDaysWorked}</TableCell>
                    <TableCell>{data.noOfWorkingDays}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const TimesheetListTab = ({ timeSheetList }) => (
  <Container>
    <Card
      style={{
        marginTop: "16px",
        cursor: "pointer",
        backgroundColor: "#e6f7ff",
      }}
    >
      <CardContent>
        <Typography variant="h5" color="primary">
          Timesheet List
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Hours Worked</TableCell>
                <TableCell>Clocked on</TableCell>
              </TableRow>
            </TableHead>
            {timeSheetList ? (
              <TableBody>
                {timeSheetList.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.date}</TableCell>
                    <TableCell>{data.hoursWorked}</TableCell>
                    <TableCell>{data.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const LeaveListTab = ({ leaveList }) => (
  <Container>
    <Card
      style={{
        marginTop: "16px",
        cursor: "pointer",
        backgroundColor: "#e6f7ff",
      }}
    >
      <CardContent>
        <Typography variant="h5" color="primary">
          Leave List
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date From</TableCell>
                <TableCell>Date To</TableCell>
                <TableCell>No of Days</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Clocked on</TableCell>
              </TableRow>
            </TableHead>
            {leaveList && leaveList.length > 0 ? (
              <TableBody>
                {leaveList.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.dateFrom}</TableCell>
                    <TableCell>{data.dateTo}</TableCell>
                    <TableCell>{data.noOfDays}</TableCell>
                    <TableCell>{data.reason}</TableCell>
                    <TableCell>{data.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const AssessmentTab = ({ assessmentList }) => (
  <Container>
    <Card
      style={{
        marginTop: "16px",
        cursor: "pointer",
        backgroundColor: "#e6f7ff",
      }}
    >
      <CardContent>
        <Typography variant="h5" color="primary">
          Assessment Card
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <GridComponent
            dataSource={assessmentList}
            toolbar={toolbarOptions}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            allowGrouping={true}
            allowPdfExport={true}
            pageSettings={{ pageSize: 10 }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="assessmentName"
                headerText="Assessment Name"
                width="150"
              />
              <ColumnDirective
                field="testType"
                headerText="Test Type"
                width="150"
              />
              <ColumnDirective
                field="assessmentScore"
                headerText="Assessment Score"
                width="150"
              />
              <ColumnDirective field="result" headerText="Result" width="150" />
              <ColumnDirective
                field="createdAt"
                headerText="Created At"
                width="150"
                valueAccessor={(field, data) => {
                  const date = data.createdAt.split("T")[0];
                  const [year, month, day] = date.split("-");

                  return `${day}/${month}/${year}`;
                }}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Selection,
                Toolbar,
                Edit,
                Sort,
                Filter,
                Group,
                PdfExport,
                Search,
              ]}
            />
          </GridComponent>
        </div>
      </CardContent>
    </Card>
  </Container>
);
const SubscriptionTab = ({
  subscriptionList,
  paymentList,
  transactionList,
}) => (
  <Container>
    <Card
      style={{
        marginTop: "16px",
        cursor: "pointer",
        backgroundColor: "#e6f7ff",
      }}
    >
      <CardContent>
        <Typography variant="h5" color="primary">
          Subscription Data
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subscription For</TableCell>
                <TableCell>Subscription Id</TableCell>
                <TableCell>Subscription Status</TableCell>
                <TableCell>Subscription Cost</TableCell>
                <TableCell>Subscription Type</TableCell>
              </TableRow>
            </TableHead>
            {subscriptionList ? (
              <TableBody>
                {subscriptionList.map((data) => (
                  <TableRow key={data.subscriptionFor}>
                    <TableCell>{data.subscriptionFor}</TableCell>
                    <TableCell>{data.subscriptionId}</TableCell>
                    <TableCell>{data.subscriptionStatus}</TableCell>
                    <TableCell>{data.subscriptionCost}</TableCell>
                    <TableCell>{data.subscriptionType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <Typography variant="h5" color="primary">
          Payment List
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment Status</TableCell>
                <TableCell>Billing From</TableCell>
                <TableCell>Billing To</TableCell>
                <TableCell>Paid So Far</TableCell>
                <TableCell>Payment Id</TableCell>
              </TableRow>
            </TableHead>
            {paymentList && paymentList.length > 0 ? (
              <TableBody>
                {paymentList.map((data) => (
                  <TableRow key={data.paymentStatus}>
                    <TableCell>{data.paymentStatus}</TableCell>
                    <TableCell>{data.paymentBillingFrom}</TableCell>
                    <TableCell>{data.paymentBillingTo}</TableCell>
                    <TableCell>{data.paidSoFar}</TableCell>
                    <TableCell>{data.paymentId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <Typography variant="h5" color="primary">
          Transaction Details List
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Status</TableCell>
                <TableCell>Transaction Amount</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Mode Of Transaction</TableCell>
              </TableRow>
            </TableHead>
            {transactionList && transactionList.length > 0 ? (
              <TableBody>
                {transactionList.map((data) => (
                  <TableRow key={data.transactionStatus}>
                    <TableCell>{data.transactionStatus}</TableCell>
                    <TableCell>{data.transactionAmount}</TableCell>
                    <TableCell>{data.transactionId}</TableCell>
                    <TableCell>{data.transactionDate}</TableCell>
                    <TableCell>{data.modeOfTransaction}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);

const ProfileTabs = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showLoading, setShowLoading] = useState(false);
  const [category, setCategory] = useState(false);

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [personalData, setPersonalData] = useState({});
  const [accountDetails, setAccountDetails] = useState({});
  const [jobApplication, setJobApplication] = useState({});
  const [salaryDataDetails, setSalaryDataDetails] = useState({});
  const [timeSheetList, setTimeSheetList] = useState({});
  const [leaveList, setLeaveList] = useState({});
  const [assessmentList, setAssessmentList] = useState({});
  const [subscriptionList, setSubscriptionList] = useState({});
  const [paymentList, setPaymentList] = useState({});
  const [transactionList, setTransactionList] = useState({});
  const [yearEndPerformanceList, setYearEndPerformanceList] = useState([]);
  const [trainingPerformanceEvaluation, setTrainingPerformanceEvaluation] =
    useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    document.title = "My Profile Data";

    retrieveProfilePic();
    retrieveProfile();
  }, []);

  useEffect(() => {
    if (alertMessage && alertSeverity === "success") {
      toast.success(alertMessage);
    }
  }, [alertMessage, alertSeverity]);

  const retrieveProfilePic = () => {
    setShowLoading(true);
    retrieveProfileImage()
      .then((res) => {
        setImagePreviewUrl(res.data);

        setShowLoading(true);
      })
      .catch((error) => {
        if (error.status === 401) {
          setAlertMessage("Your profile image doest exist !");
          setAlertSeverity("error");
        } else {
          setAlertMessage(error.message);
          setAlertSeverity("error");
        }
        setShowLoading(true);
      });
  };

  const retrieveProfile = () => {
    setShowLoading(true);
    retrieveProfileData()
      .then((res) => {
        setCategory(res.category);
        const employment = res.employment;
        if (employment) {
          const personalInfo = employment.profileDetails
            ? employment.profileDetails.personalInfo
            : null;
          if (personalInfo) {
            const personalData = {
              name: personalInfo.name,
              panNo: personalInfo.panNo,
              emailId: res.email,
              phoneNo: personalInfo.phoneNo,
              aadharNo: personalInfo.aadharNo,
              dateOfBirth: personalInfo.dateOfBirth,
              presentAddress: personalInfo.presentAddress,
              permanentAddress: personalInfo.permanentAddress,
              fatherName: personalInfo.fatherName,
              motherName: personalInfo.motherName,
              emergencyContactNo: personalInfo.emergencyContactNo,
              fatherDob: personalInfo.fatherDob,
              motherDob: personalInfo.motherDob,
              gender: personalInfo.gender,
              bloodGroup: personalInfo.bloodGroup,
              role: res.role,
            };
            setPersonalData(personalData);
          }

          const accountDetails = employment.accountDetails;
          setAccountDetails(accountDetails);
          const jobApplication = res.jobApplication;
          setJobApplication(jobApplication);

          const salaryDetails = employment.salaryDetails
            ? employment.salaryDetails
            : null;
          const salaryDataList = employment.salaryDetails
            ? employment.salaryDetails.salaryData
            : null;
          if (salaryDetails && salaryDataList) {
            const salaryDataDetails = {
              offeredCTC: salaryDetails.offeredCTC,
              annualIncentive: salaryDetails.annualIncentive,
              salaryRevisionDate: salaryDetails.salaryRevisionDate,
              previousOfferedCTC: salaryDetails.previousOfferedCTC,
              salaryDataList: salaryDataList,
            };
            setSalaryDataDetails(salaryDataDetails);
          }
          const timeSheetList = employment.timeSheetList;
          if (timeSheetList) {
            timeSheetList.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTimeSheetList(timeSheetList);
          }
          const leaveDetails = employment.employeeLeaveData;
          const leaveList = leaveDetails ? leaveDetails.leaveDataList : null;
          if (leaveList) {
            leaveList.sort(
              (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom)
            );
            setLeaveList(leaveList);
          }
          const performanceEvaluation = employment.performanceEvaluation
            ? employment.performanceEvaluation
            : null;

          if (performanceEvaluation) {
            const trainingPerformance = {
              probationStartDate: performanceEvaluation.probationStartDate,
              probationEndDate: performanceEvaluation.probationEndDate,
              remainingProbationDays:
                performanceEvaluation.remainingProbationDays,
              performanceRating: performanceEvaluation.performanceRating,
              nextEvaluationDate: performanceEvaluation.nextEvaluationDate,
              performanceComments: performanceEvaluation.performanceComments,
              performanceLevel: performanceEvaluation.performanceLevel,
              performancePercent: performanceEvaluation.performancePercent,
            };
            setTrainingPerformanceEvaluation(trainingPerformance);

            const yearEndPerformanceList =
              performanceEvaluation.yearEndPerformanceList;

            if (yearEndPerformanceList) {
              yearEndPerformanceList.sort(
                (a, b) =>
                  new Date(b.evaluationYear) - new Date(a.evaluationYear)
              );
              setYearEndPerformanceList(yearEndPerformanceList);
            }
          }
        }
        const assessmentCard = res.assessmentCard ? res.assessmentCard : null;
        const assessmentDetails = assessmentCard
          ? assessmentCard.onlineAssessmentList
          : null;
        setAssessmentList(assessmentDetails);

        const subscriptionDataList = res.subscription ? res.subscription : null;
        setSubscriptionList(subscriptionDataList);

        const paymentList = [];
        for (let i = 0; i < subscriptionDataList.length; i++) {
          paymentList.push(...subscriptionDataList[i].paymentList);
        }
        setPaymentList(paymentList);

        const transactionList = [];
        for (let i = 0; i < paymentList.length; i++) {
          if (paymentList[i].transactionDetailsList) {
            transactionList.push(...paymentList[i].transactionDetailsList);
          }
        }
        setPaymentList(paymentList);
        setTransactionList(transactionList);
        toast.success("Profile data retrieved successfully");
        //setAlertMessage("Profile Data retrieved successfully");
        setAlertSeverity("success");
      })

      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image doesn't exist!", 'error');
        } else {
          //showAlert(error.message, 'error');
        }
        setShowLoading(false);
      });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "block", width: "100%" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Profile" />
            <Tab label="Monthly Salary" />
            <Tab label="Timesheet List" />
            <Tab label="Leave List" />
            <Tab label="Assessment Card" />
            <Tab label="Subscription Card" />
            <Tab label=" My Performance" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <ProfileTab
            imagePreviewUrl={imagePreviewUrl}
            personalData={personalData}
            accountDetails={accountDetails}
            jobApplication={jobApplication}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MonthlySalaryTab
            salaryDataDetails={salaryDataDetails}
            category={category}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TimesheetListTab timeSheetList={timeSheetList} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <LeaveListTab leaveList={leaveList} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AssessmentTab assessmentList={assessmentList} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SubscriptionTab
            subscriptionList={subscriptionList}
            paymentList={paymentList}
            transactionList={transactionList}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <PerformanceTab
            yearEndPerformanceList={yearEndPerformanceList}
            trainingPerformanceEvaluation={trainingPerformanceEvaluation}
          />
        </TabPanel>
      </Box>
      <AlertComponent
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertMessage("")}
      />
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default ProfileTabs;
