const staffAugmentationEn = {
    title: "Yaazh Tech: Your Trusted Partner for Top-Tier IT Staff Augmentation ",
    heading: "Empower your projects with skilled IT professionals, exactly when you need them",
    headingContent:
      "Yaazh Tech's IT Staff Augmentation Services bridge the gap between your current workforce and the specialized skills needed to drive innovation and success. We act as an extension of your team, providing access to a global pool of pre-vetted, experienced IT professionals across various specialties.",
    
    whatSets: "What sets YaazhTech apart?",
    agileScalable: "Agile & Scalable: Seamlessly integrate talent into your existing team, scaling up or down as project needs evolve.",
  
    costEffective: "Cost-Effective: Reduce recruitment and training overhead while accessing top talent at competitive rates.",

    fasterTimeToMarket: "Faster Time-to-Market: Quickly fill skill gaps and expedite project completion timelines.",

    reduceRisk: "Reduced Risk: Benefit from comprehensive vetting and project management expertise.",

    globalTalentPool: "Global Talent Pool: Access a diverse pool of qualified IT professionals worldwide.",
  
    explore: "Explore our diverse range of IT Staff Augmentation Services:",

    softwareDevelopemet: (
      <span>
        <a href="/staffAugSoftwareDevelopement" style={{ color: 'blue', fontWeight: 'bold'}}>Software Developemet: </a>
        Gain access to expert developers adept in various programming languages and frameworks.
      </span>
    ),

    qATesting: (
      <span>
        <a href="/staffAugQaAndTesting" style={{ color: 'blue', fontWeight: 'bold'}}> QA & Testing: </a>
        Enhance software quality with experienced testers for all project phases.
      </span>
    ),

    talentAcquisitionSpecialist: (
      <span>
        <a href="/staffAugTalentAcquisitionSpecialist" style={{ color: 'blue', fontWeight: 'bold'}}> Talent Acquisition Specialist: </a>
        They source, attract, interview, and onboard top talent to ensure our organization has the right people to achieve its goals.
      </span>
    ),

    databasengineers: (
      <span>
        <a href="/staffAugDatabaseEngineer" style={{ color: 'blue', fontWeight: 'bold'}}> Database Engineers: </a>
        Master the art of SQL query writing, crafting intricate queries to extract, transform, and analyze data for business insights, reporting, and application integration.
      </span>
    ),
  
    //qATesting: "QA & Testing: Enhance software quality with experienced testers for all project phases.",

    //talentAcquisitionSpecialist: "Talent Acquisition Specialist: They source, attract, interview, and onboard top talent to ensure our organization has the right people to achieve its goals.",

    //databasengineers: "Database Engineers: Master the art of SQL query writing, crafting intricate queries to extract, transform, and analyze data for business insights, reporting, and application integration.",

    
    journey: "Yaazh Tech's Staff Augmentation Journey:",
    needsAssessment: "Needs Assessment: We collaborate with you to understand your specific requirements and project goals.",
  
    talentSourcing: "Talent Sourcing: Leverage our extensive network to identify and vet suitable candidates.",

    evaluationSelection: "Evaluation & Selection: Conduct in-depth interviews and skills assessments to ensure the ideal fit.",

    onboardingIntegration: "Onboarding & Integration: Facilitate a smooth onboarding process and seamless integration into your team.",
    
    projectManagementSupport: "Project Management & Support: Provide ongoing project management and support throughout the engagement.",

    billing: "Billing & Project Management:",
    flexible:"Flexible billing options to cater to project requirements.",
    dedicated:"Dedicated project managers ensure seamless communication and efficient execution.",
    regular:"Regular progress reports and transparent communication channels.",

    quality: "Billing & Project Management:",
    stringentRecruitment:"Stringent recruitment process ensures only the best talent joins our network.",
    ongoingTraining:"Ongoing training and development for our staff to maintain industry expertise.",
    clientSatisfaction :"Client satisfaction remains at the core of our service delivery.",

    whyChoose: "Why Choose YaazhTech for IT Staff Augmentation?",
    whyChooseExplain:"We are committed to providing you with the best IT staff augmentation services by:",
    prioritizingQuality:"Prioritizing quality: We meticulously vet our talent pool for technical expertise and cultural fit.",
    focusingOnFlexibility:"Focusing on flexibility: We adapt to your unique needs and offer scalable solutions.",
    emphasizingCostEffectiveness:"Emphasizing cost-effectiveness: We deliver value by optimizing your resource allocation.",

    expertInTheTech:"We have Experts in following Technology and Services:",

    contact: "Ready to unlock the true potential of your projects? Contact YaazhTech today!",

    cta:"Call to Action:",
    freeConsultation:"Request a free consultation to explore how YaazhTech can booster your team.",
    downloadWhitePaper:"Download our white paper on The Power of IT Staff Augmentation.",
    
    button:"Hire a Coder",

  };

  export {staffAugmentationEn};