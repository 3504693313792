import React, { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { leaveUpdateRequest, performanceUpdate, requestUpdateCategory } from "../../../../../api/HrApi";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const CategoryUpdate = ({ name, email, role, totalLeaves }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [category, setCategory] =useState("");
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const updateCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleClickBack = () => {
    navigate('/');
  };
  
  const changeCategory = (event) => {
    event.preventDefault();
    setShowLoading(true);
    requestUpdateCategory(email, category)
      .then((res) => {
        toast.success("Your request has been accepted ! Category got changed for the user with email of " + email);
        setShowLoading(false);
        setShowModal(false);
        navigate('/');
        navigate('/interns');
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
        } else {
          toast.error(error.message);
        }
        setShowModal(false);
        setShowLoading(false);
      });
      setShowLoading(false);
       
  };

  return (
    <div>
      
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Category Update
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + "Role as { " + role + " }"}
                  </h3>
                    <div>
                    <p>
              <b>Email address:</b> {email}
            </p>
            <p>
              <b>Total leaves taken for current year:</b>
              {totalLeaves ? (
                <span className="badge bg-deep-orange-800 p-1 text-white rounded-lg">Irregular</span>
              ) : (
                <span className="badge bg-green-700 p-1  text-white rounded-lg">Regular</span>
              )}
            </p>
            <form onSubmit={changeCategory}>
                      <div className="form-group">
                        <label htmlFor="inputPerformanceLevel">
                          Change category
                        </label>
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                            name="option"
                          value={category}
                          onChange={(event) => updateCategory(event)}
                        >
                          <option  value="" disabled>
                  Choose your option
                </option>
                <option value="Employment Status">Employment Status</option>
                <option value="trainee">Trainee</option>
                    <option value="employee">Employee</option>
                    <option value="intern">INTERN</option>
                    <option value="applicant">applicant</option>
                    <option value="terminated">terminated</option>
                    <option value="rejected">REJECTED</option>
            </select>
                      </div>
                      
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Change Category
                        </button>
                      </div>
                      </form>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default CategoryUpdate;
