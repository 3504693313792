import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { staffAugmentationEn } from '../../../data/content/En/StaffAugmentationEn';
import { staffAugmentationDe } from '../../../data/content/De/StaffAugmentationDe';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Language } from '../../../config/Config';
import Button from '../../../components/StaticButton/Button';

const StaffAugmentation = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      
      <div className="container">
      <div className="main contact-margin-top" id="contact">

        <div>
            <Helmet>
                <title>Yaazh Tech: Top-Tier Staff Augmentation Services, India</title>
                <meta name="description" content="Yaazh Tech provides exceptional staff augmentation services in India. We help businesses find the skilled professionals they need to succeed. Contact us today for a free consultation!" />
                {/* Other meta tags as needed */}
            </Helmet>

            <div className="inspect-visible visually-hidden" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: '0' }}>
                   <h1>Yaazh Tech: Top-Tier Staff Augmentation Services, India</h1>
            </div>

            {/* Your page content goes here */}
        
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 mt-4 md:ml-10">
            <Button text={Language==='en'? staffAugmentationEn.button:staffAugmentationDe.button} navigation="/hireCoderRegister"/>
         
            <br></br>
            <br></br>
            <h2 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? staffAugmentationEn.title:staffAugmentationDe.title}</h2>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.heading:staffAugmentationDe.heading}</b>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.headingContent:staffAugmentationDe.headingContent}</p><br></br></p>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.whatSets:staffAugmentationDe.whatSets}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.agileScalable:staffAugmentationDe.agileScalable}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.costEffective:staffAugmentationDe.costEffective}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.fasterTimeToMarket:staffAugmentationDe.fasterTimeToMarket}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.reduceRisk:staffAugmentationDe.reduceRisk}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.globalTalentPool:staffAugmentationDe.globalTalentPool}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.explore:staffAugmentationDe.explore}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.softwareDevelopemet:staffAugmentationDe.softwareDevelopemet}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.qATesting:staffAugmentationDe.qATesting}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.talentAcquisitionSpecialist:staffAugmentationDe.talentAcquisitionSpecialist}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.databasengineers:staffAugmentationDe.databasengineers}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.journey:staffAugmentationDe.journey}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.needsAssessment:staffAugmentationDe.needsAssessment}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.talentSourcing:staffAugmentationDe.talentSourcing}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.evaluationSelection:staffAugmentationDe.evaluationSelection}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.onboardingIntegration:staffAugmentationDe.onboardingIntegration}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.projectManagementSupport:staffAugmentationDe.projectManagementSupport}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.billing:staffAugmentationDe.billing}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.flexible:staffAugmentationDe.flexible}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.dedicated:staffAugmentationDe.dedicated}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.regular:staffAugmentationDe.regular}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.quality:staffAugmentationDe.quality}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.stringentRecruitment:staffAugmentationDe.stringentRecruitment}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.ongoingTraining:staffAugmentationDe.ongoingTraining}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.clientSatisfaction:staffAugmentationDe.clientSatisfaction}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.whyChoose:staffAugmentationDe.whyChoose}</b>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.whyChooseExplain:staffAugmentationDe.whyChooseExplain}</p>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.prioritizingQuality:staffAugmentationDe.prioritizingQuality}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.focusingOnFlexibility:staffAugmentationDe.focusingOnFlexibility}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.emphasizingCostEffectiveness:staffAugmentationDe.emphasizingCostEffectiveness}</li>
             {' '}
             </p><br></br>
              
             <a href="/contact" style={{ color: 'blue' }}>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.contact:staffAugmentationDe.contact}</b>
             </p>
             </a>
            
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.cta:staffAugmentationDe.cta}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.freeConsultation:staffAugmentationDe.freeConsultation}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? staffAugmentationEn.downloadWhitePaper:staffAugmentationDe.downloadWhitePaper}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? staffAugmentationEn.expertInTheTech:staffAugmentationDe.expertInTheTech}</b>
              <ul
                  className="pages-content-text text-lg  ml-4 md:ml-10"
                  style={{ fontSize: 16, listStyle: 'none', fontWeight: 200 }}
                >
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    FlexPLM PTC
                    products.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Java-Springboot micro services.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Javascript-ReactJs SPA reactive pages.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Mainframe to
                    Spring-boot micro services modernization.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    IAM, SSO
                    (SailPoint, Ping federate, FIM, KeyCloak, OpenID).
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Camunda BPMN
                    with micro service orchestration.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    AWS solution
                    architects.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    API test
                    engineers.
                  </li>
                </ul>
                {' '}
             </p><br></br>
             <Button text={Language==='en'? staffAugmentationEn.button:staffAugmentationDe.button} navigation="/hireCoderRegister"/>
         
              </div>
          </div>
        </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default StaffAugmentation