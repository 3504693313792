import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { getEndpoints } from "../../../../../api/Api";
import toast, { Toaster } from "react-hot-toast";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Group,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { Button, Typography } from "@mui/material";

const ApplicantsAnswer = () => {
  const location = useLocation();
  const { assessment } = location.state;
  const navigate = useNavigate();
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  //const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = ["Search", "SortAscending", "PdfExport"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    document.title = "ApplicantsAnswers management";
    a;
    //alert("data : ",assessment);

    loadData();
  }, [location.state]);

  const loadData = () => {
    // alert("data 1 : "+JSON.stringify(content))
    setLoading(true);

    const students = assessment.students;
    let data = [];

    for (let i = 0; i < students.length; i++) {
      let student = students[i];

      student.testId = assessment.testId;
      const filteredData = students
        .filter((student) => student.status !== "validated")
        .map((student) => ({
          studentName: students[i].studentName,
          studentMailId: students[i].studentMailId,
          studentPhoneNo: students[i].studentPhoneNo,
          createdAt: students[i].createdAt,
          assessmentShortAnswers: students[i].assessmentShortAnswers,
          testId: students[i].testId,
          status: students[i].status,
        }));
      setContent(filteredData);
    }

    setLoading(false);
  };

  const handleViewAnswers = (student) => {
    const state = {
      assessmentShortAnswers: student.assessmentShortAnswers,
      student: {
        studentMailId: student.studentMailId,
        studentName: student.studentName,
        studentPhoneNo: student.studentPhoneNo,
        createdAt: student.createdAt,
        testId: student.testId,
      },
    };
    navigate("/studentAnswer", { state });
    console.log("DATA 3:", student.assessmentShortAnswers);
  };
  const backToHome = () => {
    navigate("/trackAssessment");
  };
  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <div className="flex items-center justify-between mb-4">
        <Typography variant="h6">Validate Answer</Typography>

        <Button variant="outlined" onClick={backToHome}>
          Back
        </Button>
      </div>

      <GridComponent
        dataSource={content}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
          <ColumnDirective
            headerText="View Answers"
            width="60"
            template={(props) => (
              <span>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ cursor: "pointer", marginRight: "5px" }}
                  onClick={() => handleViewAnswers(props)}
                />
              </span>
            )}
          />

          <ColumnDirective
            field="studentName"
            headerText="Name"
            width="60"
            textAlign="Center"
          />
          <ColumnDirective
            field="studentMailId"
            headerText="Email Id"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="createdAt"
            headerText="Test Taken On"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="studentPhoneNo"
            headerText="Mobile No"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="status"
            headerText="Validation Status"
            width="80"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default ApplicantsAnswer;
