import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { careerBanner1, careerBanner2, careerBanner3, careerBanner4 } from '../../../../assets/images';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
  };

  const slides = [
    {
      image: careerBanner1,
      title: 'Direct Hire',
      description: 'Join us as a direct hire and grow your career.',
      linkText: 'Explore Direct Hire',
      linkHref: '/directHiringMain',
    },
    {
      image: careerBanner2,
      title: 'Direct Intern',
      description: 'Kickstart your career with our internship program.',
      linkText: 'Explore Internships',
      linkHref: '/directInternMain',
    },
    {
      image: careerBanner3,
      title: 'Training',
      description: 'Gain the skills you need to succeed.',
      linkText: 'Explore Training',
      linkHref: '/trainingAndHiring',
    },
    {
      image: careerBanner4,
      title: 'Training for Experienced Candidates',
      description: 'Advance your career with our expert training.',
      linkText: 'Explore Advanced Training',
      linkHref: '/trainingForExperiencedMain',
    },
  ];

  return (
    <section className="relative text-white text-center w-full h-auto">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <div
              className="w-full h-auto md:h-screen bg-cover bg-center"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="mx-auto h-full flex flex-col justify-center items-center bg-opacity-50 p-4 md:p-8">
                <h1 className="text-3xl md:text-5xl font-bold">{slide.title}</h1>
                <p className="mt-4 text-lg md:text-xl">{slide.description}</p>
                <button
                  onClick={navigate(slide.linkHref)}
                  className="mt-8 inline-block bg-white text-blue-600 px-4 py-2 md:px-6 md:py-3 rounded-lg font-semibold"
                >
                  {slide.linkText}
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Hero;
