const cancelPolicyEn={
    cancellationPolicy:"Yaazhtech Cancellation Policy:",
    allDevelopment:"All Web Design / Web Development / Mobile Application Development / Software Development / Search Engine Optimization / Digital Marketing/Hosting Services/ Maintenance of a website are carried out and accepted after total analysis and creation of a complete scope document, in order to ensure that our teams have full understanding of the work needed to be done, eliminating possibilities of any project cancellation / reversal / dispute.",
   clientUnderstand:"Since Yaazhtech and the client understand that there is work involved to achieve every milestone of the project and completion of every module, a refund would not be possible for the work once assigned to us.",
    paymentDesign:"Payments for custom design projects / above services are made to us in increments by client is non-refundable.",
    cancelled: "If a project is cancelled or postponed, all charges paid are retained by Yaazhtech and if applicable, a fee for all work completed beyond what was already paid for shall be paid by the client.",
    receiveCharge:"If we receive a charge back or payment dispute from a credit card company or bank, your service and/or project will be suspended without notice.",
    chargeBackFee:" A 750-/- charge back fee (issued to recover fees passed on to us by the credit company), plus any outstanding balances accrued as a result of the charge back(s) must be paid in full before service is restored, files delivered, or any further work is done.",
    billingIssue: "Instead of issuing a charge back, contact us to address any billing issues. Requesting a charge back or opening a Payment dispute for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund. Please read and make sure you fully understand our refund policy prior to making a payment.",
    noRefundable:"We are willing to work with you and after getting confirmation from your side, we immediately take action on it and send your work in progress. Therefore, if you take your steps back after confirmation, so, the amount you have already paid is non-refundable.",
    interact:"We interact with our clients and do our best to solve all their queries and start work after their approval, therefore, there is no provision for any kind of refund. We clearly mentioned that the paid amount is non-refundable in any circumstance.",
   understandPolicy:"Please read and make sure you fully understand our refund and cancellation policy prior to making a payment. Should you have other questions about our policies, feel free to call us.",
  };
  export {cancelPolicyEn};