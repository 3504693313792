// ProfileTabs.js
import React, { useState, useEffect } from 'react';
import { AppBar, Card, CardContent, Tab, Tabs, Typography, Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { retrieveProfileDataByEmail } from '../../../../api/Api';
import { retrieveProfileImageByEmail } from '../../../../api/EmployeeApi';
import avatar from '../../../../assets/images/logos/logoFinal30x30.png';
import AlertComponent from '../../../../AlertComponent';
import { useLocation } from 'react-router-dom';
import PerformanceTab from './PerformanceTab';
import { ADMIN_TOKEN } from '../../../../config/Config';
import { StateContext } from "../../../../contexts/StateContext";
import { useContextSelector } from "use-context-selector";

const ProfileTab = ({ imagePreviewUrl, personalData, accountDetails, jobApplication }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Profile Information</Typography>
        {/* Profile Picture */}

        {imagePreviewUrl ? (
          <img
            src={`data:image/jpeg;base64,${imagePreviewUrl}`}
            alt="Profile"
            style={{ width: '100%', maxWidth: '100px', borderRadius: '4px', marginTop: '8px' }}
          />
        ) : (
          <img
            src={avatar}
            alt="Profile"
            style={{ width: '100%', maxWidth: '100px', borderRadius: '4px', marginTop: '8px' }}
          />
        )}
        {/* Profile Data */}
        {personalData ? (<><Typography>Name:{personalData.name}</Typography><Typography>Role: {personalData.role}</Typography><Typography>Email:{personalData.emailId}</Typography><Typography>Pan No: {personalData.panNo}</Typography><Typography>Aadhar No: {personalData.aadharNo}</Typography><Typography>Mobile: {personalData.phoneNo}</Typography><Typography>Emergency ContactNo: {personalData.emergencyContactNo}</Typography><Typography>DOB: {personalData.dateOfBirth}</Typography><Typography>Gender: {personalData.gender}</Typography><Typography>Current Address: {personalData.presentAddress}</Typography><Typography>Permanent Address: {personalData.permanentAddress}</Typography><Typography>Father Name: {personalData.fatherName}</Typography><Typography>Mother Name:{personalData.motherName}</Typography><Typography>Father DOB: {personalData.fatherDob}</Typography><Typography>Mother DOB: {personalData.motherDob}</Typography><Typography>Blood Group: {personalData.bloodGroup}</Typography></>) : null}

        {/* Add more profile information as needed */}
        {accountDetails ? (<><Typography variant="h5">Account Information</Typography><Typography>BankName: {accountDetails.bankName}</Typography><Typography>accountNo: {accountDetails.accountNo}</Typography><Typography>IfscCode: {accountDetails.ifscCode}</Typography><Typography>UpiId: {accountDetails.upiId}</Typography></>) : null}
        {jobApplication ? (<><Typography variant="h5">Job Application</Typography><Typography>id: {jobApplication.id}</Typography><Typography>Applied For: {jobApplication.appliedFor}</Typography><Typography>Created At: {jobApplication.createdAt}</Typography><Typography>History: {jobApplication.history}</Typography></>) : null}


      </CardContent>


    </Card>
  </Container>
);

const MonthlySalaryTab = ({ salaryDataDetails, category }) => (
  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Salary Data</Typography>

        <Typography>Category: {category}</Typography>

        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>NoOfDaysWorked</TableCell>
                <TableCell>PayableAmount</TableCell>
                <TableCell>NoOfExtraDaysWorked</TableCell>
                <TableCell>NoOfWorkingDays</TableCell>
              </TableRow>
            </TableHead>
            {salaryDataDetails && salaryDataDetails.salaryDataList ? (<TableBody>
              {salaryDataDetails.salaryDataList.map((data) => (
                <TableRow key={data.date}>
                  <TableCell>{data.date}</TableCell>
                  <TableCell>{data.noOfDaysWorked}</TableCell>
                  <TableCell>{data.payableAmount}</TableCell>
                  <TableCell>{data.noOfExtraDaysWorked}</TableCell>
                  <TableCell>{data.noOfWorkingDays}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);

const TimesheetListTab = ({ timeSheetList }) => (


  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Timesheet List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Hours Worked</TableCell>
                <TableCell>Clocked on</TableCell>
              </TableRow>
            </TableHead>
            {timeSheetList ? (<TableBody>
              {timeSheetList.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.date}</TableCell>
                  <TableCell>{data.hoursWorked}</TableCell>
                  <TableCell>{data.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const LeaveListTab = ({ leaveList }) => (


  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Leave List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date From</TableCell>
                <TableCell>Date To</TableCell>
                <TableCell>No of Days</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Clocked on</TableCell>
              </TableRow>
            </TableHead>
            {leaveList ? (<TableBody>
              {leaveList.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.dateFrom}</TableCell>
                  <TableCell>{data.dateTo}</TableCell>
                  <TableCell>{data.noOfDays}</TableCell>
                  <TableCell>{data.reason}</TableCell>
                  <TableCell>{data.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const AssessmentTab = ({ assessmentList }) => (


  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Assessment Card</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Assessment Name</TableCell>
                <TableCell>Test Type</TableCell>
                <TableCell>Assessment Score</TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            {assessmentList ? (<TableBody>
              {assessmentList.map((data) => (
                <TableRow key={data.assessmentName}>
                  <TableCell>{data.assessmentName}</TableCell>
                  <TableCell>{data.testType}</TableCell>
                  <TableCell>{data.assessmentScore}</TableCell>
                  <TableCell>{data.result}</TableCell>
                  <TableCell>{data.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const SubscriptionTab = ({ subscriptionList, paymentList, transactionList }) => (


  <Container>
    <Card>
      <CardContent>
        <Typography variant="h5">Subscription Data</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subscription For</TableCell>
                <TableCell>Subscription Id</TableCell>
                <TableCell>Subscription Status</TableCell>
                <TableCell>Subscription Cost</TableCell>
                <TableCell>Subscription Type</TableCell>
              </TableRow>
            </TableHead>
            {subscriptionList ? (<TableBody>
              {subscriptionList.map((data) => (
                <TableRow key={data.subscriptionFor}>
                  <TableCell>{data.subscriptionFor}</TableCell>
                  <TableCell>{data.subscriptionId}</TableCell>
                  <TableCell>{data.subscriptionStatus}</TableCell>
                  <TableCell>{data.subscriptionCost}</TableCell>
                  <TableCell>{data.subscriptionType}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <Typography variant="h5">Payment List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment Status</TableCell>
                <TableCell>Billing From</TableCell>
                <TableCell>Billing To</TableCell>
                <TableCell>Paid So Far</TableCell>
                <TableCell>Payment Id</TableCell>
              </TableRow>
            </TableHead>
            {paymentList && paymentList.length > 0 ? (<TableBody>
              {paymentList.map((data) => (
                <TableRow key={data.paymentStatus}>
                  <TableCell>{data.paymentStatus}</TableCell>
                  <TableCell>{data.paymentBillingFrom}</TableCell>
                  <TableCell>{data.paymentBillingTo}</TableCell>
                  <TableCell>{data.paidSoFar}</TableCell>
                  <TableCell>{data.paymentId}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <Typography variant="h5">Transaction Details List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Status</TableCell>
                <TableCell>Transaction Amount</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Mode Of Transaction</TableCell>
              </TableRow>
            </TableHead>
            {transactionList && transactionList.length > 0 ? (<TableBody>
              {transactionList.map((data) => (
                <TableRow key={data.transactionStatus}>
                  <TableCell>{data.transactionStatus}</TableCell>
                  <TableCell>{data.transactionAmount}</TableCell>
                  <TableCell>{data.transactionId}</TableCell>
                  <TableCell>{data.transactionDate}</TableCell>
                  <TableCell>{data.modeOfTransaction}</TableCell>
                </TableRow>
              ))}
            </TableBody>) : null}

          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);

const ViewEmployeeProfiles = (props) => {
  const location = useLocation();
  
  const role = useContextSelector(StateContext, (state) => state.role);
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  console.log('Location Object:', location);
  // Check if state is available before using it
  if (!location.state) {
    // Handle the case where state is not available
    return <div>No state found</div>;
  }
  const { email } = location.state;


  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {

    setValue(newValue);
  };
  const [showLoading, setShowLoading] = useState(false);
  const [category, setCategory] = useState(false);

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [personalData, setPersonalData] = useState({
  });
  const [accountDetails, setAccountDetails] = useState({
  });
  const [jobApplication, setJobApplication] = useState({
  });
  const [salaryDataDetails, setSalaryDataDetails] = useState({
  });
  const [timeSheetList, setTimeSheetList] = useState({
  });
  const [leaveList, setLeaveList] = useState({
  });
  const [assessmentList, setAssessmentList] = useState({
  });
  const [subscriptionList, setSubscriptionList] = useState({
  });
  const [yearEndPerformanceList, setYearEndPerformanceList] = useState({
  });
  const [trainingPerformanceEvaluation, setTrainingPerformanceEvaluation] = useState({
  });
  const [paymentList, setPaymentList] = useState({});
  const [transactionList, setTransactionList] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  useEffect(() => {
    document.title = 'My Profile Data';
    //alert("email"+email);
    retrieveProfilePicByEmail(email);
    retrieveProfileByEmail(email);
  }, []);
  const retrieveProfilePicByEmail = (email) => {
    setShowLoading(true);
    retrieveProfileImageByEmail(email)
      .then((res) => {
        setImagePreviewUrl(res.data);

        setShowLoading(true);
        //setAlertMessage('Profile image retrieved');
        //setAlertSeverity('success');

      })
      .catch((error) => {
        if (error.status === 401) {

          setAlertMessage('Your profile image doest exist !');
          setAlertSeverity('error');
        } else {

          setAlertMessage(error.message);
          setAlertSeverity('error');
        }
        setShowLoading(true);
      });
  };

  const retrieveProfileByEmail = (email) => {
    setShowLoading(true);
    retrieveProfileDataByEmail(email)
      .then((res) => {
        setCategory(res.category);
        const employment = res.employment;
        if (employment) {
          const personalInfo = employment.profileDetails ? employment.profileDetails.personalInfo : null;
          if (personalInfo) {
            const personalData = {
              name: personalInfo.name,
              panNo: personalInfo.panNo,
              emailId: res.email,
              phoneNo: personalInfo.phoneNo,
              aadharNo: personalInfo.aadharNo,
              dateOfBirth: personalInfo.dateOfBirth,
              presentAddress: personalInfo.presentAddress,
              permanentAddress: personalInfo.permanentAddress,
              fatherName: personalInfo.fatherName,
              motherName: personalInfo.motherName,
              emergencyContactNo: personalInfo.emergencyContactNo,
              fatherDob: personalInfo.fatherDob,
              motherDob: personalInfo.motherDob,
              gender: personalInfo.gender,
              bloodGroup: personalInfo.bloodGroup,
              role: res.role,
            }
            setPersonalData(personalData);
          }


          const accountDetails = employment.accountDetails;
          setAccountDetails(accountDetails);
          const jobApplication = res.jobApplication;
          setJobApplication(jobApplication);

          const salaryDetails = employment.salaryDetails ? employment.salaryDetails : null;
          const salaryDataList = employment.salaryDetails ? employment.salaryDetails.salaryData : null;
          if (salaryDetails && salaryDataList) {
            const salaryDataDetails = {
              "offeredCTC": salaryDetails.offeredCTC,
              "annualIncentive": salaryDetails.annualIncentive,
              "salaryRevisionDate": salaryDetails.salaryRevisionDate,
              "previousOfferedCTC": salaryDetails.previousOfferedCTC,
              salaryDataList: salaryDataList,
            };
            setSalaryDataDetails(salaryDataDetails);
          }
          const timeSheetList = employment.timeSheetList;
          if (timeSheetList) {
            timeSheetList.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTimeSheetList(timeSheetList);
          }
          const leaveDetails = employment.employeeLeaveData;
          const leaveList = leaveDetails ? leaveDetails.leaveDataList : null;
          if (leaveList) {
            leaveList.sort((a, b) => new Date(b.dateFrom) - new Date(a.dateFrom));
            setLeaveList(leaveList);
          }
          const performanceEvaluation = employment.performanceEvaluation ? employment.performanceEvaluation : null;

          if (performanceEvaluation) {

            const trainingPerformance = {
              probationStartDate: performanceEvaluation.probationStartDate,
              probationEndDate: performanceEvaluation.probationEndDate,
              remainingProbationDays: performanceEvaluation.remainingProbationDays,
              performanceRating: performanceEvaluation.performanceRating,
              nextEvaluationDate: performanceEvaluation.nextEvaluationDate,
              performanceComments: performanceEvaluation.performanceComments,
              performanceLevel: performanceEvaluation.performanceLevel,
              performancePercent: performanceEvaluation.performancePercent,
            }
            setTrainingPerformanceEvaluation(trainingPerformance);

            const yearEndPerformanceList = performanceEvaluation.yearEndPerformanceList;
            if (yearEndPerformanceList) {

              yearEndPerformanceList.sort((a, b) => new Date(b.evaluationYear) - new Date(a.evaluationYear));
              setYearEndPerformanceList(yearEndPerformanceList);

            }
          }

        }

        const assessmentCard = res.assessmentCard ? res.assessmentCard : null;
        const assessmentDetails = assessmentCard ? assessmentCard.onlineAssessmentList : null;
        setAssessmentList(assessmentDetails);
        const subscriptionDataList = res.subscription ? res.subscription : null;
        setSubscriptionList(subscriptionDataList);

        const paymentList = [];
        for (let i = 0; i < subscriptionDataList.length; i++) {

          paymentList.push(...subscriptionDataList[i].paymentList);
        }

        setPaymentList(paymentList);

        const transactionList = [];
        for (let i = 0; i < paymentList.length; i++) {
          if (paymentList[i].transactionDetailsList) {
            transactionList.push(...paymentList[i].transactionDetailsList);
          }
        }

        setTransactionList(transactionList);

        setAlertMessage("Profile Data retrieved successfully");
        setAlertSeverity('success');


      })

      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image doesn't exist!", 'error');
        } else {
          //showAlert(error.message, 'error');
        }
        setShowLoading(false);
      });
  };
  return (
    <><Box sx={{ flexGrow: 1, display: 'block', width: '100%' }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} centered
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Profile" />
          <Tab label="Timesheet List" />
          <Tab label="Leave List" />
          <Tab label="Assessment Card" />
          <Tab label="Subscription Card" />
         
          <Tab label="My Performance" />
          <Tab label="Monthly Salary" />
        </Tabs>
      </AppBar>
     
      <TabPanel value={value} index={0}>
        <ProfileTab imagePreviewUrl={imagePreviewUrl} personalData={personalData} accountDetails={accountDetails} jobApplication={jobApplication} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TimesheetListTab timeSheetList={timeSheetList} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LeaveListTab leaveList={leaveList} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AssessmentTab assessmentList={assessmentList} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SubscriptionTab subscriptionList={subscriptionList} paymentList={paymentList} transactionList={transactionList} />
      </TabPanel>
     
      {role === "ADMIN"?
      <><TabPanel value={value} index={5}>
          <PerformanceTab yearEndPerformanceList={yearEndPerformanceList} trainingPerformanceEvaluation={trainingPerformanceEvaluation} />
        </TabPanel><TabPanel value={value} index={6}>
            <MonthlySalaryTab salaryDataDetails={salaryDataDetails} category={category} />
          </TabPanel></>:null}
    </Box><AlertComponent
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertMessage('')} /></>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default ViewEmployeeProfiles;
