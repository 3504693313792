import React, { useState, useEffect } from 'react';
import { check2FAUsage, disable2FA, enable2FAStepOne, enable2FAStepTwo } from '../../../../../../api/Api';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import TwoFA from '../../../../../../assets/images/careers/twoFA.png';
import { PASSWORD_MIN_LENGTH } from '../../../../../../config/Config';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../contexts/StateContext';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';

//const SecuritySettings = ({showAlert}) => {
  const SecuritySettings = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const navigate=useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isUsingTwoFA, setIsUsingTwoFA] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showLearnMoreAbout2FA, setShowLearnMoreAbout2FA] = useState(false);
  const [enableTwoFAStepOne, setEnableTwoFAStepOne] = useState({

    status: false,
    qrCodeByteData: [],

  });

  const [enableTwoFARequest, setEnableTwoFARequest] = useState({

    password: '',
    twoFACode: 0,

  });

  const [showTwoFAStepOneModal, setShowTwoFAStepOneModal] = useState(false);
  const [showStepTwo, setShowStepTwo] = useState(false);

  useEffect(() => {

    document.title = 'My security settings';
    doCheck2FAStatus();
  }, []);



  const doCheck2FAStatus = () => {
    setShowLoading(true);
    check2FAUsage()
      .then((res) => {
        setIsUsingTwoFA(res.usingTwoFA);
        setShowLoading(false);

      })

      .catch((error) => {
        handleApiError(error);
      });
  };
  const toggleLearnMoreAbout2FA = () => {
    setShowLearnMoreAbout2FA(!showLearnMoreAbout2FA);

  };

  const toggleTwoFAStepOneModal = () => {
    setShowTwoFAStepOneModal(!showTwoFAStepOneModal);

  };

  const updateEnableTwoFARequestPassword = (event) => {
    setEnableTwoFARequest({ ...enableTwoFARequest, password: event.target.value });

  };

  const enable2FAStepOneApiCall = (event) => {

    if (enableTwoFARequest.password && enableTwoFARequest.password.length >= PASSWORD_MIN_LENGTH) {

      setShowLoading(true);
      enable2FAStepOne()
        .then((res) => {
          setEnableTwoFAStepOne(res);
          setShowLoading(false);
          setShowStepTwo(true);
          setShowModal(false);

        })
        .catch((error) => {
          handleApiError(error);

        });
    } else {
      toast.error("Please enter a valid password!")
    }

  };
  const updateEnableTwoFARequestCode = (event) => {
    setEnableTwoFARequest({ ...enableTwoFARequest, twoFACode: parseInt(event.target.value, 10) });
  };

  const cancelEnable2FA = () => {
  disable2FA();
  toast.success("Two Factor Authentication Disabled Successfully for your account")
  setIsUsingTwoFA(false)
  setShowStepTwo(false)
  navigate('/2fa');
  resetState();
  };

  const enable2FAStepTwoApiCall = (event) => {
    enable2FAStepTwo(enableTwoFARequest)
      .then((res) => {
      toast.success(res.message, "success");
      toast.success("Two Factor Authentication Enabled Successfully for your account")
        setIsUsingTwoFA(true);
        resetState();
      })
      .catch((error) => {
        handleApiError(error);
        cancelEnable2FA();

      });

  };

  const resetState = () => {
    setEnableTwoFAStepOne({ status: false, qrCodeByteData: [] });
    setEnableTwoFARequest({ password: '', twoFACode: 0 });
    setShowTwoFAStepOneModal(false);
    setShowStepTwo(false);
    setShowLoading(false);

  };

  const handleApiError = (error) => {
    if (error.message && error.success === false) {
      toast.error(error.message)
    } else if (error.message === 'Your password does not match ! Please try again.') {
      toast.error(error.message)
      cancelEnable2FA();
    } else {
      toast.error("Sorry! Something went wrong. Please try again!")
      cancelEnable2FA();
    }
    setShowLoading(false);
    console.error(error);

  };

  return (
    <div className="text-center">
      <h2 class="text-2xl ml-10 font-semibold mt-5 flex items-center justify-center">Two Factor Authentication</h2>
      <br></br>
      {showLoading ? (
        <div className="m-auto">
          <h3 className="text-muted text-center">Loading. Please wait...</h3>
          <i className="material-icons w3-xxxlarge w3-spin d-flex justify-content-center">refresh</i>
        </div>

      ) : (

        <div>

          {/* TODO if showStepTwo state */}
          {isUsingTwoFA === true ? (
            <div>
              <div className="jumbotron text-center">
              <FontAwesomeIcon icon={faLock} className="w3-xxxlarge m-auto" />
             <p className="text-2xl ml-10 font-bold mt-5 flex items-center justify-center">
                  Two Factor Authentication is Enabled
             </p>
              <br></br>
              <p className="text-xl ml-10 font-semibold mt-5 flex items-center justify-center">
                  You account is already secured by 2FA, congratulations !
              </p>
                <hr className="my-5" />
                <div className="d-flex justify-content-between"> 
                
                <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" 
                type="submit" 
                style={{ backgroundColor: currentColor }} 
                onClick={cancelEnable2FA}>
                Disable Two-Factor authentication
                </button>

               {/* <button
                className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  //className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="submit"
                  //data-ripple-light="true"
                  style={{ color: currentColor }}
                  onClick={cancelEnable2FA}
                > Disable Two-Factor authentication
        </button>*/}
        </div>
              </div>
            </div>

          ) : showStepTwo ? (

            <div className="text-center align-content-center">

              <p>Please install google authenticator app in your mobile then scan this barcode.</p>
              <p className="text-muted">Scan the image below with the two-factor google authentication app on your phone.</p>
              
              <div style={{ textAlign: 'center' }}>
              <img src={`data:image/png;base64,${enableTwoFAStepOne.qrCodeByteData}`} alt="qrCode 2FA" style={{ display: 'block', margin: '0 auto' }}/>
             
              <hr />
              <p>Enter the six-digit code from the application</p>
              <p className="text-muted" >
                After scanning the barcode image, the app will display a six-digit code that you can enter below.
              </p>
              <br></br>
              <form onSubmit={(e) => enable2FAStepTwoApiCall(e)}>
                <input
                  onChange={updateEnableTwoFARequestCode}
                  className="form-control"
                  id="two-factor-code"
                  type="number"
                  name="otp"
                  placeholder="123456"
                  autoComplete="off"
                  required

                />
               <hr className="my-5" />
               <div className="d-flex justify-content-between"> 
               
                <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }} onClick={() => setShowModal(true)}>
                  Enable 2FA
                </button>
              {/* TODO */}
              <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" style={{ backgroundColor: currentColor }} onClick={cancelEnable2FA}>
                Cancel
              </button>
              </div>
               </form>
            </div>
             </div>

          ) : (

            <div>
              <div className="jumbotron text-center">
                <FontAwesomeIcon icon={faLockOpen} className="w3-xxxlarge m-auto" />
                <h3>Two factor authentication is not enabled yet.</h3>
                <p className="lead">
                  Two-factor authentication adds an additional layer of security to your account by requiring more than
                  just a password to log in.
                </p>

                <p className="alert-link page-link" onClick={toggleLearnMoreAbout2FA}>
                  Learn more
                </p>

                <hr className="my-5" />
                <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }} onClick={() => setShowModal(true)}>
                  Enable Two-factor authentication
                </button>
              </div>
              {showModal ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-2xl">
                      <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                        <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                          <button
                            className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                          >
                          </button>

                        </div>
                        <h2 className="card-title text-center text-lg font-semibold">
                          About two-factor authentication
                        </h2>
                        <div className="relative p-0 px-3 flex-auto">
                          <h3 className=" font-bold text-md">
                            To enable 2FA please enter your password
                          </h3>
                          <form onSubmit={(e) => enable2FAStepOneApiCall(e)}>
                            <div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  autoComplete="on"
                                  className="block w-full mt-2
                      bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                                  id="enable2FAPasswordConfirm"
                                  required
                                  placeholder="Enter your password"
                                  onChange={updateEnableTwoFARequestPassword}

                                />
                              </div>
                              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                  style={{ backgroundColor: currentColor }}
                                  className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="submit"
                                >
                                  Continue
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                     </div>
                    </div>
                  </div>

                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : (
                null
              )}
              <Modal isOpen={showLearnMoreAbout2FA} toggle={toggleLearnMoreAbout2FA}>

                <ModalHeader toggle={toggleLearnMoreAbout2FA}>About two-factor authentication</ModalHeader>
                <ModalBody>

                  <p>
                    Two-factor authentication, or 2FA, is an extra layer of security used when logging into websites or
                    apps. With 2FA, you have to log in with your username and password and provide another form of
                    authentication that only you know or have access to.
                  </p>
                  <p>
                    For us, the second form of authentication is a code that's generated by an application on your
                    mobile device. After you enable 2FA, the system generates an authentication code any time someone
                    attempts to sign into your account. The only way someone can sign into your account is if they know
                   both your password and have access to the authentication code on your phone.
                  </p>
                  <img src={TwoFA} alt="2FA schema" className="img-fluid" />
                </ModalBody>
               <ModalFooter>
                  <button className="btn btn-success btn-lg" onClick={toggleTwoFAStepOneModal}>
                    Enable Two-factor authentication
                  </button>
                 <Button color="secondary" onClick={toggleLearnMoreAbout2FA}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>



              {/*             <Modal isOpen={showTwoFAStepOneModal} toggle={toggleTwoFAStepOneModal}>

                <ModalHeader toggle={toggleTwoFAStepOneModal}>About two-factor authentication</ModalHeader>

                <ModalBody>

                  <p>To enable 2FA please enter your password.</p>

                  <form onSubmit={(e) => enable2FAStepOne(e)}>

                    <input

                      type="password"

                      autoComplete="on"

                      className="form-control"

                      id="enable2FAPasswordConfirm"

                      required

                      placeholder="Enter your password"

                      onChange={updateEnableTwoFARequestPassword}

                    />

                    <button type="submit" className="btn btn-success btn-lg mt-4">

                      Continue

                    </button>

                  </form>

                </ModalBody>

                <ModalFooter>

                  <Button color="danger" onClick={toggleTwoFAStepOneModal}>

                    Cancel

                  </Button>

                </ModalFooter>

              </Modal>

      */}

            </div>
        )}
       </div>
    )}
 </div>
 );
};



export default SecuritySettings;
