import React, { useState, useEffect } from "react";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../contexts/StateContext';
import { documentGeneration } from "../../../../api/ClientApi";
import toast, { Toaster } from "react-hot-toast";

const Request = () => {

  const [request, setRequest] = useState({
    header: "",
    subHeader: "",
    documentBody: "",
  });
  const [showLoading, setShowLoading] = useState(false);

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  useEffect(() => {
    document.title = "Document Generation";
  }, []);

  const updateHeader = (event) => {
    setRequest({ ...request, header: event.target.value });
  };

  const updateSubHeader = (event) => {
    setRequest({ ...request, subHeader: event.target.value });
  };

  const updateDocumentBody = (event) => {
    setRequest({ ...request, documentBody: event.target.value });
  };
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const handleDocumentGeneration = (event) => {
    event.preventDefault();
    //alert("data" + JSON.stringify(letterRequest));
    setShowLoading(true);
    documentGeneration(request)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = request.header + ".pdf";

        link.click(); /*

        alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        alert("url"+url);
        const link = document.createElement('a');
        alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
*/
        toast.success("Letter generated successfully !");
        setShowLoading(false);
        setRequest("");
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <>
      <br></br>

      <div className="w-full md:w-1/2 mx-auto">

        <div >
          <h1 className="text-4xl uppercase mb-3">Create request</h1>
          <hr />
        </div>

        <div>
          {showLoading ? (
            <div className="align-content-center text-center">
              <h3>Document generation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={handleDocumentGeneration}>

              <div>

                <div className="mt-3">
                  <label className="text-gray-500" for="inputHeader">Subject</label>
                  <input
                    type="text"
                    id="inputHeader"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Subject"
                    required
                    value={request.header}
                    onChange={updateHeader}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label className="text-gray-500" for="inputSubHeader">Request Info</label>
                  <input
                    type="text"
                    id="inputSubHeader"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Request info"
                    required
                    value={request.subHeader}
                    onChange={updateSubHeader}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label className="text-gray-500" for="inputDocumentBody">Additional Info</label>
                  <textarea
                    type="text"
                    id="inputDocumentBody"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Additional Info"
                    required
                    value={request.documentBody}
                    onChange={updateDocumentBody}
                    autoComplete="on"
                  />
                </div>

                <br />
                <button
                  className="w-full px-6 py-4 bg-blue-500 text-white text-center uppercase"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          )}
        </div>
        <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        error: {
          duration: 6000,
          style: {
            background: '#FF4136',
            color: '#fff',
          },
        },

        success: {
          duration: 6000,
          style: {
            background: '#008000',
            color: '#fff',
          },
        },
      }}
    />
      </div>
    </>
  );
};

export default Request;