import React, { Component } from "react";
import { Fade } from "react-reveal";
import { StateContext } from "../../../../src/contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import { cancelPolicyEn } from "../../../data/content/En/CancellationPolicyEn";
import { cancelPolicyDe } from "../../../data/content/De/CancellationPolicyDe";
import { Language } from "../../../config/Config";
const CancellationPolicy = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  return (
    <div className="container">
      <br />
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
            <br></br>
            <p classname="pages-content-text text-3xl">
              Web app services cancellation policy
            </p>
            <br></br>
            <h1
              className="section-heading text-3xl font-bold uppercase"
              style={{ color: currentColor }}
            >
              {Language === "en"
                ? cancelPolicyEn.cancellationPolicy
                : cancelPolicyDe.cancellationPolicy}
            </h1>
            <br></br>
            <p className="pages-content-text text-lg ml-4 md:ml-10">
              {Language === "en"
                ? cancelPolicyEn.allDevelopment
                : cancelPolicyDe.allDevelopment}{" "}
            </p>
            <br></br>
            <p className="pages-content-text text-lg ml-4 md:ml-10">
                   <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4  ">

              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.clientUnderstand
                  : cancelPolicyDe.clientUnderstand}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.paymentDesign
                  : cancelPolicyDe.paymentDesign}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.cancelled
                  : cancelPolicyDe.cancelled}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.receiveCharge
                  : cancelPolicyDe.receiveCharge}{" "}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.chargeBackFee
                  : cancelPolicyDe.chargeBackFee}{" "}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.billingIssue
                  : cancelPolicyDe.billingIssue}{" "}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.noRefundable
                  : cancelPolicyDe.noRefundable}{" "}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.interact
                  : cancelPolicyDe.interact}{" "}
              </li>
              <li className="pages-content-text text-lg ml-4 md:ml-10">
                {Language === "en"
                  ? cancelPolicyEn.understandPolicy
                  : cancelPolicyDe.understandPolicy}{" "}
              </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
        crossOrigin="anonymous"
      />
    </div>
  );
};

export default CancellationPolicy;



// import React, { Component } from "react";
// import { Fade } from "react-reveal";
// import { StateContext } from "../../../../src/contexts/StateContext";
// import { useContextSelector } from "use-context-selector";
// import { cancelPolicyEn } from "../../../data/content/En/CancellationPolicyEn";
// import { cancelPolicyDe } from "../../../data/content/De/CancellationPolicyDe";
// import { Language } from "../../../config/Config";
// const CancellationPolicy = () => {
//   const currentColor = useContextSelector(
//     StateContext,
//     (state) => state.currentColor
//   );
//   return (
//     <div className="container">
//       <br />
//       <div className="main contact-margin-top" id="contact">
//         <div className="contact-div-main">
//           <div className="contact-header ml-4 md:ml-10">
//             <br></br>
//             <p className="pages-content-text text-3xl">
//               Web app services cancellation policy
//             </p>
//             <br></br>
//             <h1
//               className="section-heading text-3xl font-bold uppercase"
//               style={{ color: currentColor }}
//             >
//               {Language === "en"
//                 ? cancelPolicyEn.cancellationPolicy
//                 : cancelPolicyDe.cancellationPolicy}
//             </h1>
//             <br></br>
//             <p className="pages-content-text text-lg ml-4 md:ml-10">
//               {Language === "en"
//                 ? cancelPolicyEn.allDevelopment
//                 : cancelPolicyDe.allDevelopment}{" "}
//             </p>
//             <br></br>
//             {/* <p className=" text-lg ml-4 md:ml-10"> */}
//               <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4 border border-gray-400 rounded-md shadow-sm ">
             
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">
//                 {Language === "en"
//                   ? cancelPolicyEn.clientUnderstand
//                   : cancelPolicyDe.clientUnderstand}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.paymentDesign
//                   : cancelPolicyDe.paymentDesign}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.cancelled
//                   : cancelPolicyDe.cancelled}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.receiveCharge
//                   : cancelPolicyDe.receiveCharge}{" "}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.chargeBackFee
//                   : cancelPolicyDe.chargeBackFee}{" "}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.billingIssue
//                   : cancelPolicyDe.billingIssue}{" "}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.noRefundable
//                   : cancelPolicyDe.noRefundable}{" "}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.interact
//                   : cancelPolicyDe.interact}{" "}
//               </li>
//               {/* <li className="pages-content-text text-lg ml-4 md:ml-10"> */}
//               <li  className="flex items-start">                {Language === "en"
//                   ? cancelPolicyEn.understandPolicy
//                   : cancelPolicyDe.understandPolicy}{" "}
//               </li>
//               </ul>
//             {/* </p> */}
//           </div>
//         </div>
//       </div>
//       <script
//         async
//         src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
//         crossOrigin="anonymous"
//       />
//     </div>
//   );
// };

// export default CancellationPolicy;
