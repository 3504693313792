const ContractEn={
    header:"Swift Hiring with Flexible Contracts",
    bulidDream:"Build Your Dream Team with YaazhTech",
    w1:"At YaazhTech, we offer the fastest and most flexible hiring services, allowing you to build your dream team with immediate joiners starting from $15.90/hour and also specialize in delivering comprehensive product development services tailored to your needs. (CTA - Start Hiring Now)",
    chooseYaazhtech:"Why Choose YaazhTech for Your Hiring Needs?",
    s1:"Speedy Onboarding: Get your team up and running swiftly with immediate joiners ready to dive into projects.",
    s2:"Flexible Contracts: Choose hourly rates starting from $15.90, providing cost-effective solutions aligned with your budget.",
    s3:"Quality Talent: Access top-notch professionals skilled in diverse domains and technologies to craft your dream team",
    s4:"Expertise & Experience: Benefit from our team's extensive experience and expertise in delivering successful product solutions and staffing support",
    s5:"Client-Centric Approach: We prioritize understanding your business needs to deliver tailored solutions that exceed expectations",
    s6:"Reliability & Quality: Count on us for reliable services and a commitment to delivering high-quality results on time",
    whatWe:"What We Offer Product Development Services",
    a1:"End-to-End Solutions: From ideation to deployment, we craft innovative and scalable products that solve your business challenges",
    a2:"Agile Methodologies: Our adaptive approach ensures rapid development cycles, allowing for swift adjustments and superior results",
    a3:"Customized Solutions: Tailored product development to address your unique requirements and industry demands",
    staffAugmentation:"Staff Augmentation",
    r1:"Skilled Professionals: Access our pool of skilled experts to augment your team's capabilities.",
    r2:"Flexible Staffing Solutions: Scale your workforce based on project demands without long-term commitments.",
    r3:"Domain Expertise: Choose from a diverse talent pool equipped with expertise across various domains and technologies",
    immediateJoiners:"Immediate Joiners",
   t1:"Ready to Work: Our pool of immediate joiners is prepared to contribute right away, minimizing project delays",
    t2:"Versatile Skills: Choose from a range of skill sets and expertise to match your project requirements",
    flexibleContractOptions:"Flexible Contract Options",
    e1:"Hourly Rates: Opt for hourly rates as low as $15.90, ensuring cost efficiency without compromising quality",
    e2:"Adaptable Engagements: Tailor contracts to suit your project's duration and scale",
    projectDuration:"Tailor contracts to suit your project's duration and scale",

    y1:"Browse Talent: Explore our pool of immediate joiners with diverse skills and experience",
    y2:"Select Your Team: Handpick individuals who align perfectly with your project requirements",
    y3:"Start Building: Begin your project with a dedicated team ready to deliver exceptional results",
    readyBuild:"Ready to Build Your Dream Team?",
    u1:"Take the leap toward success! Hire exceptional talent from YaazhTech at flexible contracts, starting from $15.90/hour. Let's create extraordinary outcomes together",
    buildSuccess:"Let's Build Success Together",
    z1:"Whether you're envisioning a groundbreaking product or seeking skilled professionals to bolster your team, YaazhTech is your dedicated partner. Let's collaborate to achieve your goals and elevate your business.",
    button:"Direct Hire"
};

export {ContractEn};