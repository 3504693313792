import React from 'react'
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { MobileFriendlyEn } from '../../../data/content/En/MobileFriendlyEn';
import { MobileFriendlyDe } from '../../../data/content/De/MobileFriendlyDe';
import { Language } from '../../../config/Config';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Button from '../../../components/StaticButton/Button';

const MobileFriendly = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
      <div className="main contact-margin-top" id="contact">

        <div>
            <Helmet>
                <title>Free Mobile-Friendly Web App! Boost Your Mobile Presence with Yaazhtech (Get Your Website Mobile-Ready Today)</title>
                <meta name="description" content="Claim your FREE mobile-friendly web app from Yaazhtech! Get a website that looks great on all devices (mobile, tablet, desktop)"/>
                {/* Other meta tags as needed */}
            </Helmet>

            <div className="inspect-visible visually-hidden" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: '0' }}>
                   <h1>Free Mobile-Friendly Web App! Boost Your Mobile Presence with Yaazhtech (Get Your Website Mobile-Ready Today)</h1>
            </div>
            {/* Your page content goes here */}
        
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
            <h2 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? MobileFriendlyEn.title:MobileFriendlyDe.title}</h2>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10 " style={{ textAlign: 'left' }} >{Language==='en'? MobileFriendlyEn.titleExplain:MobileFriendlyDe.titleExplain}</p>

             <div className="flex justify-center">
             <Button text={Language==='en'? MobileFriendlyEn.button:MobileFriendlyDe.button} navigation="/devSubscriptionPricing"/>
              </div>
              <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10 "><b>{Language==='en'? MobileFriendlyEn.whyChoose:MobileFriendlyDe.whyChoose}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Seamless Mobile Experience: </span>{" "}{Language==='en'? MobileFriendlyEn.seamless:MobileFriendlyDe.seamless}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Enhanced User Engagement: </span>{" "}{Language==='en'? MobileFriendlyEn.enhanced:MobileFriendlyDe.enhanced}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Boost Search Engine Rankings: </span>{" "}{Language==='en'? MobileFriendlyEn.boost:MobileFriendlyDe.boost}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Free and Easy to Use: </span>{" "}{Language==='en'? MobileFriendlyEn.free:MobileFriendlyDe.free}</li>
             {' '}
             </p>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? MobileFriendlyEn.who:MobileFriendlyDe.who}</b>

             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Small Businesses & Startups: </span>{" "}{Language==='en'? MobileFriendlyEn.small:MobileFriendlyDe.small}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Entrepreneurs & Freelancers: </span>{" "}{Language==='en'? MobileFriendlyEn.entrepreneur:MobileFriendlyDe.entrepreneur}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10"><span style={{ fontWeight: "bold" }}>Non-Profits & Organizations: </span>{" "}{Language==='en'? MobileFriendlyEn.nonProfit:MobileFriendlyDe.nonProfit}</li>
             
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? MobileFriendlyEn.ready:MobileFriendlyDe.ready}</b></p>
             <p className="pages-content-text text-lg ml-4 md:ml-10 " style={{ textAlign: 'left' }} >{Language==='en'? MobileFriendlyEn.dontMiss:MobileFriendlyDe.dontMiss}</p><br></br>
             
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? MobileFriendlyEn.question:MobileFriendlyDe.question}</b></p>
             {/*<p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? MobileFriendlyEn.quesExplain:MobileFriendlyDe.quesExplain} </p>*/}
             
             <div>
             <p className="pages-content-text text-lg ml-4 md:ml-10 ">
              <Link to="/contact" className="text-blue-500 font-bold">
              Contact our team for a free consultation.
             </Link>
             {Language==='en'? MobileFriendlyEn.quesExplain:MobileFriendlyDe.quesExplain}
             </p><br></br>
             </div>   

            <p className="pages-content-text text-lg ml-4 md:ml-10 text-center"><b>{Language==='en'? MobileFriendlyEn.go:MobileFriendlyDe.go}</b> </p> 
          </div>
          </div>
        </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
  )
}

export default MobileFriendly