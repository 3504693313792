/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor. scss file
import splashAnimation from '../../assets/lottie/splashAnimation.json'; // Rename to your file name for custom animation
// import camundaImage from ". /assets/images/CAMUNDA_PLATFORM_MANIFEST32x32. png"
// Splash Screen
const splashScreenContent = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1300, // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true, // Set to false to use static SVG
};

const greeting = {
  username: 'Product of Yaazhtech',
  title: 'Yaazhtech is an software development lab from India.  We specialize in fullstack app development and business process management automation. ',
  subTitle:
    'Yaazhtech specializes in providing high quality development resources to the IT industry.  We specialize in the development and staff augmentation of react native mobile apps, Java backends, Javascript reactJs frontends, BPMN Camunda developers, AWS development operations engineers, and other IT skills.  ',
  subTitle1: 'The aim of Yaazhtech is to translate your brainstorming ideas to a product or mobile app (Android/IOs), as well as to improve business flow quality through BPM process automation. ',
  displayGreeting: true, // Set false to hide this section, defaults to true
};
const clientContent = {
  clientHeading: 'Get started for free!!',
  clientSubheading: 'Start your development odc for free with us or Hire developers to build your dream team. ',
  developCardHeader: 'Mobile friendly web apps for free!!',
  developAtYaazhtech: 'Start your free web application for any kind of business with secured user subscription management dashboard. ',
  developAtYaazhtechODC: 'We undertake any additional features as in house development when you grow in your business. ',
  developAtYaazhtechApps: 'We also develop android, ios mobile apps and BPMN process automation micro services. ',
  developAtYaazhtechPara: 'We have secured odc to solve your business problems. ',
  findDeveloper: 'Are you looking for techies at flexible contract?',
  findDeveloperFull: 'Skilled immediate joiners are available in flexible contract. ',
  findDeveloperPara: "Customize your dream team from our cherry picked developers pool. ",
  findDeveloperCost:"*0 cost If you are not satisfied with our coder's performance. ",
  findDeveloperCostReturn:"*Return to us or swap coders with in 30 days for free. ",
  contactUs:"For quick start contact our sales team on business@yaazhtech.com. ",
  
  displayGreeting: true, // Set false to hide this section, defaults to true
};

const hirePricing = {
  header: "Subscribe for free to access the developers profiles.  If you are not satisfied with our developer's performance you can return and swap in paid plans. ",
  standard: 'Standard',
  free: '0',
  freeProfileAccess: 'Limited profile dashboard access. ',
  freeHireReturn: 'Return coder is NOT possible. ',
  freeHireSwap: 'Swap coder is NOT possible. ',
  noCards: '*No credit cards required. ',
  premium: 'Premium',
  premiumCost: '2500',
  premiumProfileAccess: 'Unlimited profile dashboard access. ',
  premiumHireReturn: 'Return 2 coders in 20 days of hire. ',
  premiumHireSwap: 'Swap 2 coders in 20 days of hire. ',
  superPremium: 'Super Premium',
  superPremiumCost: '5000',
  superPremiumProfileAccess: 'Unlimited profile dashboard access. ',
  superPremiumHireReturn: 'Return 5 coders in 30 days of hire. ',
  superPremiumHireSwap: 'Swap 5 coders in 30 days of hire. ',
  contactUs: '* If any clarifications about the plan.  Please contact us on business@yaazhtech.com. ',
  excludeGST: '* The price quoted for the plan is exclude GST.  Final invoice may vary based on GST. ',
  displayGreeting: true, // Set false to hide this section, defaults to true
  premium: 'Premium',
  annumPremiumCost: '4000',
  premiumProfileAccess: 'Unlimited profile dashboard access. ',
  premiumHireReturn: 'Return 2 coders in 20 days of hire. ',
  premiumHireSwap: 'Swap 2 coders in 20 days of hire. ',
};
// Social Media Links
const devPricing = {
  header: 'Subscribe with us to launch your life time free mobile friendly web apps with in 15 days of subscription. ',
  standard: 'OneTime',
  free: '6000',
  freeDevAccess: 'SEO configuration for 3 pages. ',
  freeDevUsers: 'Upto 10 users & 1 admin dashboard is free.',
  freeDevUsersAdditional: 'Additional users Rs 5/- per month.',
  freeDevPages: 'Common meta tags include the meta title, meta description, and meta keywords. ',
  freeDevSecureOptions: 'SEO Additional improvements not applicable ',
  freeDevUsersDelete: 'User delete option is NOT available. ',
  freeDevSeo: 'SEO configuration is NOT applicable. ',
  freeDevSEOAdditional:' A sitemap is an XML file that lists the URLs for a site. It helps search engines index your website pages more efficiently. ',
  freeDevSecureEmail: 'Bulk email available with extra cost 0.25P per mail. ',
  freeDevAdditional: 'Additional SEO pages improvement are charged as Rs 875/- +GST for 8 hrs. ',
  noCards: '*No credit cards required. ',
  taskEstimation: '*(Small Task (8 hrs), Medium Task (24 hrs), Big Task (40 hrs)). ',
  premium: 'Monthly',
  premiumCost: '1500',
  premiumDevAccess: 'SEO configuration for 10 pages',
  premiumDevUsers: 'A sitemap is an XML file that lists the URLs for a site. It helps search engines index your website pages more efficiently.',
  premiumDevUsersAdditional: 'Additional users Rs 5/- per month.',
  premiumDevUsersDelete: 'User delete option is available. ',
  premiumDevPages: 'Common meta tags include the meta title, meta description, and meta keywords.. ',
  premiumDevSEO: 'Initial SEO configuration is free. ',
  premiumDevSEOAdditional: 'Additional SEO pages improvement are charged as Rs 875/- +GST for 8 hrs. ',
  premiumDevSecureOptions: 'Monthly SEO improvements upto 3 pages ',
  premiumDevSecureEmail: 'Bulk email available with extra cost 0.25P per mail',
  premiumDevAdditional: 'Additional development changes are free upto 48 hrs per year as per task complexity.',
  superPremium: 'Yearly',
  superPremiumCost: '6000',
  superPremiumDevAccess: 'SEO configuration for 4 pages ',
  superPremiumDevUsers: 'Upto 300 users &  1 admin dashboard is free.',
  superPremiumDevUsersAdditional: 'A sitemap is an XML file that lists the URLs for a site. It helps search engines index your website pages more efficiently.',
  superPremiumDevPages: 'Common meta tags include the meta title, meta description, and meta keywords.. ',
  superPremiumDevSEO: 'Initial SEO configuration is free. ',
  superPremiumDevUsersDelete: 'User delete option is available. ',
  superPremiumDevSEOAdditional: 'Additional SEO pages improvement are charged as Rs 875/- +GST for 8 hrs. ',
  superPremiumDevSecureOptions: 'Yearly SEO improvements upto 4 pages ',
  superPremiumDevSecureEmail: 'Upto 1000 Bulk email for marketing is free. ',
  superPremiumDevAdditional: 'Additional development changes are free upto 128 hours per year as per task complexity. ',
  contactUs: '* If you already own your dedicated domain without any swift you can build your application and domain cost will be reduced from the plan.  Please contact us on business@yaazhtech. com. ',
  excludeGST: '* The price quoted for the plan is exclude GST.  Final invoice may vary based on GST. ',
  displayGreeting: true, // Set false to hide this section, defaults to true
  annumPremium: 'New Annual Plan',
  annumPremiumCost: '10000',
  annumPremiumDevAccess: 'Dedicated Domain. ',
  annumPremiumDevUsers: 'Upto 10 users & 1 admin dashboard is free. ',
  annumPremiumDevPages: 'Upto 5 business pages. ',
  annumPremiumDevUsersAdditional: 'Additional users Rs 5/- per month.',
  annumPremiumDevUsersDelete: 'User delete option is NOT available. ',
  annumPremiumDevSecureOptions: 'https, email OTP based Login. ',
  annumPremiumDevSEO: 'Initial SEO configuration is additionally paid Rs 6000/- +GST one time ',
  annumPremiumDevSecureEmail: 'Bulk email option available to your subscribers with extra charges. ',
  annumPremiumDevAdditional: 'Additional development charged as Rs 875/-+GST for 8 hours.',
};
const seoPricingOneTime = {
  header: 'Subscribe with us to launch your life time free mobile friendly web apps with in 15 days of subscription. ',
  standard: 'Lifetime Free',
  free: '0',
  freeDevAccess: 'Sub domain of yaazhtech. ',
  freeDevUsers: 'Upto 10 users & 1 admin dashboard is free.',
  freeDevUsersAdditional: 'Additional users Rs 5/- per month.',
  freeDevPages: 'Upto 3 business pages. ',
  freeDevSecureOptions: 'https, email OTP based Login. ',
  freeDevUsersDelete: 'User delete option is NOT available. ',
  freeDevSeo: 'SEO configuration is NOT applicable. ',
  freeDevSEOAdditional:' Additional SEO configuration is NOT applicable. ',
  freeDevSecureEmail: 'Bulk email available with extra cost 0.25P per mail. ',
  freeDevAdditional: 'Additional development charged as Rs 875/- +GST for 8 hours see the task complexity ',
  noCards: '*No credit cards required. ',
  taskEstimation: '*(Small Task (8 hrs), Medium Task (24 hrs), Big Task (40 hrs)). ',
  premium: 'When you grow',
  premiumCost: '2500',
  premiumDevAccess: 'Dedicated Domain. ',
  premiumDevUsers: 'Upto 200 users & 1 admin dashboard is free.',
  premiumDevUsersAdditional: 'Additional users Rs 5/- per month.',
  premiumDevUsersDelete: 'User delete option is available. ',
  premiumDevPages: 'Upto 5 business pages. ',
  premiumDevSEO: 'Initial SEO configuration is free. ',
  premiumDevSEOAdditional: 'Additional SEO pages improvement are charged as Rs 875/- +GST for 8 hrs. ',
  premiumDevSecureOptions: 'https, email OTP based Login. ',
  premiumDevSecureEmail: 'Bulk email available with extra cost 0.25P per mail',
  premiumDevAdditional: 'Additional development changes are free upto 48 hrs per year as per task complexity.',
  superPremium: 'When you expend',
  superPremiumCost: '5000',
  superPremiumDevAccess: 'Dedicated Domain. ',
  superPremiumDevUsers: 'Upto 300 users &  1 admin dashboard is free.',
  superPremiumDevUsersAdditional: 'Additional users Rs 5/- per month.',
  superPremiumDevPages: 'Upto 10 business pages. ',
  superPremiumDevSEO: 'Initial SEO configuration is free. ',
  superPremiumDevUsersDelete: 'User delete option is available. ',
  superPremiumDevSEOAdditional: 'Additional SEO pages improvement are charged as Rs 875/- +GST for 8 hrs. ',
  superPremiumDevSecureOptions: 'https email OTP based Login. ',
  superPremiumDevSecureEmail: 'Upto 1000 Bulk email for marketing is free. ',
  superPremiumDevAdditional: 'Additional development changes are free upto 128 hours per year as per task complexity. ',
  contactUs: '* If you already own your dedicated domain without any swift you can build your application and domain cost will be reduced from the plan.  Please contact us on business@yaazhtech. com. ',
  excludeGST: '* The price quoted for the plan is exclude GST.  Final invoice may vary based on GST. ',
  displayGreeting: true, // Set false to hide this section, defaults to true
  annumPremium: 'New Annual Plan',
  annumPremiumCost: '10000',
  annumPremiumDevAccess: 'Dedicated Domain. ',
  annumPremiumDevUsers: 'Upto 10 users & 1 admin dashboard is free. ',
  annumPremiumDevPages: 'Upto 5 business pages. ',
  annumPremiumDevUsersAdditional: 'Additional users Rs 5/- per month.',
  annumPremiumDevUsersDelete: 'User delete option is NOT available. ',
  annumPremiumDevSecureOptions: 'https, email OTP based Login. ',
  annumPremiumDevSEO: 'Initial SEO configuration is additionally paid Rs 6000/- +GST one time ',
  annumPremiumDevSecureEmail: 'Bulk email option available to your subscribers with extra charges. ',
  annumPremiumDevAdditional: 'Additional development charged as Rs 875/-+GST for 8 hours.',
};
const socialMediaLinks = {
  linkedin: 'https://www. linkedin. com/company/yaazhtech/',
  gmail: 'business@yaazhtech. com',
  facebook: 'https://www. facebook. com/yaazhtech',
  display: true,
};
const jobContent = {
  camunda: 'Camunda Developer',
  content0: 'You will be responsible to write error free Java code for our clients.  First 6 months you will be on probation period and there is no salary on probation period.',

  fullStack: 'FS Developer - Java and ReactJS',
  // description:"Job Description: ( 0 to 5 yrs experience )",
  content: 'You will be responsible to write error free springboot backend with reactJs code for our clients.  First 6 months you will be on probation period and there is no salary on probation period. ',
  eligibility: 'Eligibility:BE/BTech or BCA/MCA/Msc. Probation period: 6 months.',
  salary: 'Salary Offered: 1.5 Lakhs to 10 Lakhs.',
  location: 'Location: Work from home or Hybrid.  ',

  backend: 'BE - Java Developer',
  content1: 'You will be responsible to write error free java springboot code with junit test cases code for our clients.  First 6 months you will be on probation period and there is no salary on probation period. ',

  mobile: 'Mobile App Developer ',
  content2: 'You develop error free react native & java code for mobile apps android/IOs device code for our clients.  First 6 months you will be on probation period and there is no salary on probation period. ',

  uiDeveloper: 'UI - ReactJS Developer',
  content3: 'You will be responsible to write error free javascript code with reactjs framework code for our clients UX.  First 6 months you will be on probation period and there is no salary on probation period. ',

  devops: ' DevOps Engineer',
  content4: 'You will be responsible to configure AWS with cicd pipeline in docker repo code for our clients.  First 6 months you will be on probation period and there is no salary on probation period. ',

  hrManager: 'HR Manager',
  content5: 'Responsible for telecalling, hiring, onboarding, pay, leave, enforcing the company policies and practices.  First 6 months you will be on probation period and there is no salary on probation period. ',
  eligibility1: 'Eligibility: MBA or Any Degree.  Probation period: 6 months. ',

  apiTesting: 'API Test Engineer',
  content6: 'You will be responsible to write test suite for rest API in postman & test UI/UX by devtools code for our client.  First 6 months you will be on probation period and there is no salary on probation period. ',

  sqlDeveloper: 'SQL Developer',
  content7: 'You will be responsible to write complex SQL quries and SP functions code for our client applications.  First 6 months you will be on probation period and there is no salary on probation period. ',

  digitalMarketing: 'Digital Marketing SEO ',
  content8: 'Responsible for digital marketing, finance, client co-ordination for the projects undertaken.  First 6 months you will be on probation period and there is no salary on probation period. ',

  pythonDeveloper: 'Python Developer',
  golangDeveloper:'GoLang Developer',

  displayGreeting: true, // Set false to hide this section, defaults to true
};
const jobRequirements = {
  header01: 'Camunda Developer Job Description',
  header02: 'Camunda Developer Job Responsibilities',
  header03: 'Camunda Developer Job Skills',
  description0: 'Design an end-to-end Camunda application with Spring Boot.Ability to understand Business Problem and Business KPIs and provide BPM centric solution through digitized process, automations, User Interventions.',
  Responsibilities0: 'Leading the feature analysis on critical initiatives which require significant strategic change to the solution.Partnering with Technology Portfolio Analysts to generate epics from upcoming new business demand.',
  skils0: 'Good knowledge of Camunda and other BPMS (Workflow systems).Knowledge of integrating Camunda with other applications via REST APIs.',

  header11: 'Full Stack Developer Job Description',
  header12: 'Full Stack Developer Job Responsibilities',
  header13: 'Full Stack Developer Job Skills',
  description: 'A Full-Stack developer is a professional responsible for working on both front-end and back-end development processes.  They design, develop, and maintain fully-fledged and functioning platforms with databases or servers. ',
  Responsibilities: 'Work with development teams and product managers to ideate software solutions.  Design client-side and server-side architecture. Build the front-end of applications through appealing visual design.  ',
  skills: 'Proven experience as a Full Stack Developer or similar role. Experience developing desktop and mobile applications. Knowledge of multiple front-end languages and libraries. ',

  header21: 'Backend Developer Job Description',
  header22: 'Backend Developer Job Responsibilities',
  header23: 'Backend Developer Job Skills',
  description1: 'This Back-end developer job  can help you attract candidates with development skills to meet your position’s requirements. If you have excellent programming skills and a passion for developing applications or improving existing ones, we would like to meet you. ',
  Responsibilities1: 'Participate in the entire application lifecycle, focusing on coding and debugging. Write clean code to develop functional web applications. Manage cutting-edge technologies to improve legacy applications. Gather and address technical and design requirements. ',
  skils1: 'Proven work experience as a Back-end developer. In-depth understanding of the entire web development process (design, development and deployment). Teamwork skills with a problem-solving attitude. ',

  header31: 'Mobile Developer Job Description',
  header32: 'Mobile Developer Job Responsibilities',
  header33: 'Mobile Developer Job Skills',
  description2: 'If you’re passionate about mobile platforms and translating code into user-friendly apps, we would like to meet you.  As a Mobile developer, you’ll collaborate with internal teams to develop functional mobile applications, while working in a fast-paced environment. ',
  Responsibilities2: 'Support the entire application lifecycle (concept, design, test, release and support). Produce fully functional mobile applications writing clean code. Gather specific requirements and suggest solutions. Troubleshoot and debug to optimize performance. Design interfaces to improve user experience. ',
  skils2: 'Proven work experience as a Mobile developer. Demonstrable portfolio of released applications on the App store or the Android market. In-depth knowledge of at least one programming language like Swift and Java. Excellent analytical skills with a good problem-solving attitude.',

  header41: 'UI Developer Job Description',
  header42: 'UI Developer Job Responsibilities',
  header43: 'UI Developer Job Skills',
  description3: 'We are looking for a creative, skilled UI Developer who can combine the art of design with the art of programming.  Also known as Front-End Web Developers, as a UI Developer, you will work on the aesthetic of a website to make it appealing to the user while maintaining the core functionality of the website. ',
  Responsibilities3: 'Work with your team to design and build software applications to function across multiple platforms. Maintain and improve the website by developing new user-facing and mobile-based features. Build reusable code and libraries for future use. Collaborate with other team members and stakeholders. ',
  skils3: 'Strong understanding of front-end coding and development technologies. Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery. Good understanding of SEO principles. In-depth understanding of the entire web development process (design, development, and deployment). Excellent analytical and multitasking skills. ',

  header51: 'AWS Devops Engineer Job Description',
  header52: 'AWS Devops Engineer Job Responsibilities',
  header53: 'AWS Devops Engineer Job Skills',
  description4: 'The role of an AWS DevOps Engineer involves working with a team who are responsible for automating design, coding, and deployment of infrastructure within an AWS Environment.  Developing CI/CD pipelines, instrumenting code to report relevant metrics, and building safe and efficient build processes are all a part of the role of an AWS Devops Engineer. ',
  Responsibilities4: 'Design and deploy scalable, highly available, secure, and fault tolerant systems on AWS for the development and test lifecycle of our cloud security product. Passion for solving challenging issues. Examine data to grasp issues, draw conclusions, and solve problems.  ',
  skils4: 'Deep understanding of the Linux operating system and networking concepts. Experience building scalable infrastructure in AWS. Expertise in leveraging Automation / DevOps principles, experience with operational tools, and able to apply best practices for infrastructure and software deployment.  ',

  header61: 'HR Manager Job Description',
  header62: 'HR Manager Job Responsibilities',
  header63: 'HR Manager Job Skills',
  description5: 'Human Resource Managers are professionals tasked with strategic planning for staffing, conducting interviews, and hiring personnel to foster a productive work atmosphere.  They consult executives on decisions impacting all management levels, fostering sustainable transformation within their business or company. ',
  Responsibilities5: 'Bridge management and employee relations by addressing demands, grievances or other issues. Manage the recruitment and selection process. Support current and future business needs through the development,motivation and preservation of human capital. Develop and monitor overall HR strategies, systems, tactics and procedures across the organization. ',
  skils5: 'Demonstrable experience with Human Resources metrics. Knowledge of HR systems and databases. Excellent active listening, negotiation and presentation skills. Competence to build and effectively manage interpersonal relationships at all levels of the company. ',

  header71: 'API Test Engineer Job Description',
  header72: 'API Test Engineer Job Responsibilities',
  header73: 'API Test Engineer Job Skills',
  description6: 'API testing is a type of software testing that involves testing application programming interfaces (APIs) directly and as part of integration testing to determine if they meet expectations for functionality, reliability, performance, and security. ',
  Responsibilities6: 'Support to client during UAT and Development.  Coordinating with Client team for Integration testing between application and board. Test cases writing, Bug reporting.  Work closely with client and get all the details of the workflows defined and the APIS present and build the AUTOMATED scripts accordingly. ',
  skils6: 'Solid understanding of RESTful web services and microservice architecture.  Strong hands-on experience with automation of APIs using SOAPUI/Rest Assured or other API automation frameworks. Solid understanding of test process: requirement analysis, test scenario design, test case creation, test scripting and status reporting to stakeholders for testing services. ',

  header81: 'SQL Developer Job Description',
  header82: 'SQL Developer Job Responsibilities',
  header83: 'SQL Developer Job Skills',
  description7: "We are seeking to hire an SQL developer to join our team and create high-performing, reliable & stable databases Additionally, you'll collaborate with other developers to define best practices and optimize any in-application SQL statements that need it. ",
  Responsibilities7: 'Reviewing and evaluating of current company needs and creating relevant database solutions. Making use of T-SQL to create, implement, and improve functions and processes. Creating effective and relevant reporting for deliverables. Analyzing current SQL queries and recommending new ones to boost performance. Making data migration scripts and procedures, and providing regularly scheduled reporting. Investigating any exceptions that may apply to asset fluctuations. ',
  skils7: 'You should have a strong understanding of widely used programming languages including Python, JavaScript, HTML, CSS, and PHP. You must have a solid working knowledge of Microsoft SQL server. Practical knowledge of NoSQL and NewSQL databases through application. You must possess the ability to configure well-known database engines and manage clusters as needed.  Planning resource requirements from high-level specifications. Ability to prioritize workload in order to balance deliverables and meet necessary deadlines. You are a good team player, who can communicate & collaborate effectively with different stakeholders. ',

  header91: 'Digital Marketing Job Description',
  header92: 'Digital Marketing Job Responsibilities',
  header93: 'Digital Marketing Job Skills',
  description8: "The candidate will develop, implement, and manage marketing campaigns promoting the organization's products and services.  The candidate will majorly enhance brand awareness, drive web traffic, and acquire leads/customers. ",
  Responsibilities8: "Develop marketing strategies that align with the organization's business goals.  Manage and execute campaigns across various digital channels, including email, social media, search engines, and display advertising. Collaborate with cross-functional teams, including creative, content, and web development, to produce engaging content and optimize user experience. ",
  skils8: 'Strong understanding of digital marketing channels, including SEO, PPC, social media, email marketing, and display advertising. Proficient in marketing automation tools. Excellent analytical skills and experience with web analytics tools (e. g. , Google Analytics). Strong project management skills. Excellent written and verbal communication skills. ',

  displayGreeting: true, // Set false to hide this section, defaults to true
};

const training = {
  title1: 'Get trained !! Get hired!!',
  title2: ' **Yaazhtech is celebrating its 8th anniversary with a special offer! Get 50% off on all training courses. This offer is valid until JUNE 2024. ',

  trainingGiven:"Our Process From Training to Securing Full-Time Employment",

  levels:"Engage in intensive coding practice and progress through Pass Info, Primary, and Dark levels to qualify as an intern.",

  intern:"Receive a monthly stipend of 5000 during the internship period upon successful completion of the designated levels.",

  client:"Successfully pass the screening round in the client interview to secure a position as a Client Intern, with an increased stipend of 10000 per month.",

  fullTime:"Excel in the client interview to transition into a full-time role, earning a competitive salary based on your experience and performance, ranging from 1.5 LPA to 30 LPA.",

  certificate:"Regardless of the outcome at each level, participants will receive a course completion certificate. This certificate is a testament to your dedication and skills development throughout the entire process, ensuring recognition of your efforts in the training program.",

  freeTraining:"We offer an initial 15-day trial period. If, within this timeframe, you find that our course isn't the right fit for you, you can leave without any cancellation fees. We believe in providing flexibility and ensuring that our participants are satisfied with their learning experience.",

  content0: 'In this Camunda online classroom training, trainees will learn Camunda BPM installation, Camunda Wildly server, Camunda BPM Architecture,Camunda Modeler, and ProcessEngine.',

  content: "Become a fullstack web developer  by taking this ONE course, even if you've never coded before. Build real-world projects by mastering React,JavaScript, etc. Learn the essential Full Stack development concepts from scratch.",
  eligibility: 'Eligibility:BE/BTech or BCA/MCA/Msc. Course Duration: 6 months. Salary Offered: 1.5 Lakhs to 10 Lakhs.',
  course: 'Job and Training: Online as work from home. (Laptop mandatory)',
  coursePlan: 'Course plan:',
  step1: '* Step 1: Coding practice.',
  step2: '* Step 2: Theoretical and soft skill improvement.',
  step3: '* Step 3: Mock interview and Off-campus to get new job.',
  step4: '* Step 4: Job at yaazhtech according to your performance rating.',

  content1: 'Launch your career as a back-end developer. Build real work-like projects to gain work experience in Java, RestAPI, Spring Boot, and more to land a top career as a backend developer. ',

  content2: 'Start here for a high-level overview of how to build apps using the latest Modern Android Development practices. Build a series of apps using react native & java code,for creating beautiful user interfaces on Android.',

  content3: 'Some skills always stay in demand, and front-end development is one of them.  Learning this skill can be quite challenging, and that’s why we have launched our front end development online course.  ',

  content4: 'If you’re a beginner looking for a clear starting point to help you build a career or learn how to implement a solution in DevOps, we recommend you start with an AWS Learning Plan. ',

  content5: 'From recruitment to retention, learn the strategies to cultivate a high-performing team.  Elevate your HR expertise with cutting-edge courses and make an impact in the workplace. ',
  eligibility1: 'Eligibility: MBA or Any Degree. Duration 6 months. Salary Offered: 1.5 Lakhs to 10 Lakhs.',

  content6: 'API Testing path is for manual testers, automation beginners, and anyone who wants to explore the API testing. You will learn about how to create API requests and incorporate them into automated test cases for both REST and SOAP API testing.  ',

  content7: 'Let the SQL training you need to become a database professional.  These courses are also great for advanced engineers who need to brush up on their skill.  And SQL courses aren’t just for engineers; it’s also for business analysts who need to gain crucial insight on new trends and marketplaces. ',

  content8: 'This online digital marketing course gives you an in-depth understanding and advanced knowledge of the eight most important digital marketing domains and includes real-world projects for gaining domain experience to help you become industry-ready. ',

  displayGreeting: true, // Set false to hide this section, defaults to true

};
const experienced = {
  content0: 'We are looking for an experienced Camunda Developer.As a Camunda Developer, the person would be required to participate in architecture, design and development of Camunda-based applications using Java programming language.',
  content: 'We are looking for an experienced Full Stack Developer to produce scalable software solutions. As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, development frameworks and third-party libraries. ',
  eligibility: 'Eligibility: BE/BTech or BCA/MCA/Msc. Salary Offered: 1.5 Lakhs to 10 Lakhs. ',
  
  //process:"Process to be follow",
  assessments:"Candidates participate in an online coding assessment to showcase their proficiency in the specified development.",
  technicalEvaluation:"Successful candidates undergo a comprehensive technical evaluation focused on fundamental specified development skills.",
  deepDive:"An in-depth assessment is conducted to evaluate candidates' advanced technical skills and expertise in the specified development.",
  hrRound:"Shortlisted candidates engage in a round with the HR manager to assess cultural fit, communication skills, and alignment with company values.",
  fee:"An interview application fee of 500 INR + GST is applicable. This fee covers the administrative costs associated with the assessment process.",
  note:"The interview application fee is non-refundable. In the event that a candidate does not pass the interview process, the fee will not be refunded.",
  document:"Upon successful completion of the rounds, the document and offer process is initiated to formalize the employment relationship, providing transparency and clarity for both parties.",
  

  location: 'Location: Work from home or Hybrid. ',
  process: 'Interview Process: ',
  round1: '* Round 1: Online coding challenges.',
  round2: '* Round 2: Technical on fundamentals.',
  round3: '* Round 3: Deep dive in technical skills. ',
  round4: '* Round 4: Hr Managerial round.',
  round5: '* Round 5: Document verification and Offer process.',

  price: 'Online coding challenge is paid (₹499 Rs).',

  content1: 'We are looking for an experienced Back-end developer to join our IT team.  You will be responsible for the server side of our web applications. As a Back-end developer, you’ll work closely with our engineers to ensure system consistency and improve user experience. ',

  content2: 'We are looking for a qualified Mobile developer to join our Engineering team.  You will be working with our engineers to develop and maintain high quality mobile applications. As a Mobile developer, you’ll collaborate with internal teams to develop functional mobile applications. ',

  content3: 'We are looking for a creative, skilled UI developer to design websites with functionality and aesthetics in mind.  The UI developer will work with back-end developers and web developers to ensure that the website is optimized for several devices and presented in an attractive way. ',

  content4: 'We are looking for an experienced DevOps engineer that will help our team establish DevOps practice. You will help us build scalable, efficient cloud infrastructure and CI pipeline. You’ll implement monitoring for automated system health check. ',

  content5: 'We are looking for an HR manager to lead our HR team.  You will take ownership of all HR matters across the company from recruitment to performance reviews, wage reviews, disciplinary action, and learning and development.  ',
  eligibility1: 'Eligibility: MBA or Any Degree. Salary Offered: 1.5 Lakhs to 10 Lakhs. ',

  content6: 'We are looking for an experienced API Testing Lead Engineer to join our IT team. You will be responsible to Create and verify Web services API requests, SOAP Protocols and To analyze test results and reporting defects. ',

  content7: 'We are looking for a SQL developer to develop MS-SQL queries and procedures, create custom reports, and modify ERP user forms to enhance organizational productivity. You will be responsible for designing databases and ensuring their stability, reliability and performance. ',

  content8: 'We are looking for a Digital Marketing Manager to develop, implement, track and optimize our digital marketing campaigns across all digital channels. ',

  displayGreeting: true, // Set false to hide this section, defaults to true
};
const education = {
  title1: 'Get trained !! Get hired!!',
  title2: ' **joining Before December 2023 50% OFFER FOR ALL ARREAR PAPERS!! ** ',
  header1: 'Engineering Mathematics',
  header2: 'Computer Science Engineering',
  header3: 'Automobile Engineering',
  header4: 'Mechanical Engineering',

  content1: 'Yaazhtech helps every student learn engineering smarter and grow faster. Our Mathematics engineering tuition professors are highly rated and flexible. We offer tuition classes at affordable costs and leverage students’ talent. We will provide efficient training for engineering mathematics 1,Engineering mathematics 2,Transform partial and differentila equations,Probability theory,Numerical analysis. If you are having arrears in these kind of papers under any semester Mathematics paper we will give training for those respective papers. After the course completion we will conduct/practice mock tests and final test for those respective papers so that you can achieve in your board exams.',
  content2: 'Yaazhtech tutor helps to develop design and analytical skills in our engineering tuitions.We educate the method to write an algorithm apart from theoretical classes.We will provide efficient training for Software engineering,Computer networks,Data structures,Computer architecture and Operating system .If you are having arrears in these kind of papers under any semester Computer Science Engineering paper we will give training for those respective papers.After the course completion we will conduct/practice mock tests and final test for those respective papers so that you can achieve in your board exams.',
  content3: 'Yaazhtech helps every student learn engineering smarter and grow faster. We offer tuition classes at affordable costs and leverage students’ talent.A wide range of subjects are taught in Automobile engineering which include mobility design and aesthetics, Automotive materials, Automotive electrical and electronics, computer-aided vehicle design.If you are having arrears in these kind of papers under any semester Automobile Engineering paper we will give training for those respective papers.After the course completion we will conduct/practice mock tests and final test for those respective papers so that you can achieve in your board exams.',
  content4: 'Yaazhtech helps every student learn engineering smarter and grow faster. Our Mechanical engineering tuition professors are highly rated and flexible. We offer tuition classes at affordable costs and leverage students’ talent. We will provide efficient training for Fluid mechanics,Materials science, Mechanics of Materials. If you are having arrears in these kind of papers under any semester Mechanical Engineering paper we will give training for those respective papers .After the course completion we will conduct/practice mock tests and final test for those respective papers so that you can achieve in your board exams.',

  displayGreeting: true,
};

// Skills Section

const skillsSection = {
  title: 'What we do',
  subTitle: 'Technology research startup & specialized in process automation. ',
  skills: [

    ' ✔️ Our research team is developing highly interactive event based process automation framework with open source tech stack like camunda, kafka and power BI. ',
    '✔️ We do tech consulting and resource deployment for the various tech stack like Java, Camunda, Teableau, ReactJs and much more. ',
    '✔️ We do under take project with our secured odc for the camunda process automation, BI reporting and much more verticals. ',
    '✔️ We provide technical training and placement guideline for the freshers and experienced. ',
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome. com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: 'java',
      fontAwesomeClassname: 'fab fa-java',
    },
    {
      skillName: 'camunda',
      fontAwesomeClassname: 'fas fa-star-of-life',
    },
    {
      skillName: 'No SQL',
      fontAwesomeClassname: 'fas fa-eye',
    },
    {
      skillName: 'reactjs',
      fontAwesomeClassname: 'fab fa-react',
    },
    {
      skillName: 'npm',
      fontAwesomeClassname: 'fab fa-npm',
    },
    {
      skillName: 'sql-database',
      fontAwesomeClassname: 'fas fa-database',
    },
    {
      skillName: 'aws',
      fontAwesomeClassname: 'fab fa-aws',
    },
    {
      skillName: 'tableau',
      fontAwesomeClassname: 'fas fa-chart-pie',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, // Set it to true to show Proficiency Section
  experience: [
    {
      Stack: 'Frontend/Design', // Insert stack or technology you have experience in
      progressPercentage: '90%', // Insert relative proficiency in percentage
    },
    {
      Stack: 'Backend',
      progressPercentage: '70%',
    },
    {
      Stack: 'Programming',
      progressPercentage: '60%',
    },
  ],
  displayCodersrank: false,
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme. md */

const openSource = {
  showGithubProfile: 'true', // Set true or false to show Contact profile using Github, defaults to true
  display: true, // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const ourServices = {
  title: 'We are at your services. ',
  subtitle: 'Our highly skilled coding experts are available at your services. ',
  projects: [
    {
      // image: require(". /assets/images/saayaHealthLogo. webp"),
      projectName: 'Staff augmentation',
      projectDesc: 'We are providing highly skilled techies for short/long term contracts at your convenience. ',
      technology: 'Profiles: BPMN Camunda developers, React Native developers, Java Spring-boot developers,Full stack or UI developers, Test Engineer, SQL Engineer, AWS Dev ops experts, HRs and more. ',
      footerLink: [
        {
          name: 'Contact Us',
          url: 'http://www. yaazhtech. com/contact',
        },
        //  you can add extra buttons here.
      ],
    },
    {
      // image: require(". /assets/images/nextuLogo. webp"),
      projectName: 'Technology consultation',
      projectDesc: 'If your team needs technical consultation for the various tech stack.  We are here to solve your technical problems with low cost daily based invoice. ',
      technology: 'Techstack: ReactNative, ReactJs, Spring Boot-Java, SQL, Camunda',
      footerLink: [
        {
          name: 'Contact Us',
          url: 'http://www. yaazhtech. com/contact',
        },

      ],
    }, {
      // image: require(". /assets/images/saayaHealthLogo. webp"),
      projectName: 'Development at secured ODC/Remote',
      projectDesc: 'We undertake projects at our workplace and own the responsibility to deliver at your expectations. ',
      technology: 'Projects: Web based development and support, Mobile app development, Middleware services implementation. ',
      footerLink: [
        {
          name: 'Contact Us',
          url: 'http://www. yaazhtech. com/contact',
        },
        //  you can add extra buttons here.
      ],
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

const blogSection = {
  title: 'Jobs and Training',
  subtitle:
    'We are hiring and also we provide technical training for the freshers & experienced. ',
  displayMediumBlogs: 'false', // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: 'http://localhost:3000/app',
      title: 'Submit your profile to get chance to work at yaazhtech. ',
      description:
        'Our HR will contact you for the further process if your profile shortlisted',
    },
    {
      url: 'http://localhost:3000/app',
      title: 'Tell us about you to find suitable training to scale up your skills. ',
      description:
        'Our technical expert will evaluate your profile to find suitable training to up-skill. ',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: 'Login',
  subtitle:
    'To login, Please keyin your credentials here. ',

  talks: [
    {
      title: 'Build Actions For Google Assistant',
      subtitle: 'Codelab at GDG DevFest Karachi 2019',
      slides_url: 'https://bit. ly/saadpasta-slides',
      event_url: 'https://www. facebook. com/events/2339906106275053/',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};
/* const themeDark={
  darkBoolean:false
}; */

// Podcast Section

const contactInfo = {
  title: 'Contact US',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Our team provides high-tech resources to build your strong team in developing web, mobile apps, and more.  Schedule a meeting to learn more. ',
  email_address: 'business@yaazhtech. com',
};

const peppyHireInfo = {
  title: 'Welcome to PeppyHire',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Peppyhire product is in progress, will reach market soon. ',
  email_address: 'business@yaazhtech. com',
};

const peppyPayInfo = {
  title: 'Welcome to PeppyPay',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Peppypay product is development in progress, will be available once developed. ',
  email_address: 'business@yaazhtech. com',
};

const peppyDoctorInfo = {
  title: 'Welcome to PeppyDoctor',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Peppydoctor product is work in progress, will reach after development. ',
  email_address: 'business@yaazhtech. com',
};

const peppyOrderInfo = {
  title: 'Welcome to PeppyOrder',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Peppyorder product is work in progress, will reach later. ',
  email_address: 'business@yaazhtech. com',
};

const cookieInfo = {
  main: 'Last update: 27 May 2023',
  title: "Yaazhtech's cookie policy",
  title1: 'Why and who?',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  "Yaazhtech  ('Yaazhtech','we', 'us', 'our') is using cookies and other tracking technologies ('Cookies') in orderto improve your user experience. ",

  content1: "This Cookie Policy ('the Policy') describes Yaazhtech'susage of Cookies by explaining what Cookies are, why we use them and how you can control the usage of Cookies when you use our service - matching talent with opportunity - and/or our Webpage yaazhtech. com (commonly referred to as 'Webpage'). The Policy does not inform about the usage of Cookies that are essential in order to provide the Webpage and its services to you (so-called strictly necessary cookies ). ",

  content2: 'For more information about how we process your personal data in relation to Cookies, see our Privacy Policy (in the footer of the main page). ',

  content3: "This Policy provides information on how we handle Personal Data when you communicate with us, use the Services or visit our website yaazhtech. com (together the 'Functions'). ",

  content4: 'The intended recipient of the information provided in this Policy is:',
  content5: 'Users of the Services',
  content6: 'Potential customers',
  content7: 'Visitors of our website',
  email_address: 'business@yaazhtech. com',
};

const termsInfo = {
  main: 'Last update: 27 May 2023',
  title: 'Terms of Service',
  title1: 'Why and who?',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'These Terms of Service reflect the way that Yaazhtech’s business works, the laws that apply to our company, and certain things that we’ve always believed to be true.  As a result, these Terms of Service help define Yaazhtech’s relationship with you as you interact with our services.  For example, these terms include the following topic headings:',

  content1: 'What you can expect from us, which describes how we provide and develop our services',

  content2: 'What we expect from you, which establishes certain rules for using our services',

  content3: 'Content in Yaazhtech services, which describes the intellectual property rights to the content that you find in our services – whether that content belongs to you, Yaazhtech or others',

  content4: 'In case of problems or disagreements, which describes other legal rights that you have, and what to expect in case someone violates these terms. ',
  content5: 'Understanding these terms is important because, by using our services, you’re agreeing to these terms. ',
  content6: 'Besides these terms, we also publish a Privacy Policy.  Although it’s not part of these terms, we encourage you to read it to better understand how you can update, manage, export and delete your information. ',
  content7: 'Visitors of our website',

  email_address: 'business@yaazhtech. com',

};

const privacyInfo = {
  main: 'Last update: 27 May 2023',
  title: "Yaazhtech's privacy policy",
  title1: 'Why and who?',
  subtitle:
  // "Set up a meeting with our team to avail our products and services.  we provide high-tech resources to build your strong team in developing web, mobile app and much more.  ",
  'Yaazhtech cares about privacy and protecting the Personal Data handled by us.  This means that we care about your personal integrity and actively work to protect it. ',

  content1: 'In this Policy, we overall describe how and the purposes for which we use your Personal Data as well as what lawful basis we use and what measures we take to protect Personal Data.  We also provide information on how you exercise the rights you have linked to our Processing of Personal data. ',

  content2: "Yaazhtech ('Yaazhtech', 'we', 'us', 'our') is the Controller of all Personal Data listed in this Privacy Policy (the 'Policy'). ",

  content3: "This Policy provides information on how we handle Personal Data when you communicate with us, use the Services or visit our website yaazhtech. com (together the 'Functions'). ",

  content4: 'The intended recipient of the information provided in this Policy is:',
  content5: 'Users of the Services',
  content6: 'Potential customers',
  content7: 'Visitors of our website',
  title2: 'Definitions: ',
  content8: ' Applicable Law refers to the legislation applicable to the Processing of Personal Data, including the GDPR, supplementary national legislation, as well as practices, guidelines and recommendations issued by a national or EU supervisory authority.  Controller is the company/organisation that decides for what purposes and in what way personal data is to be processed and is responsible for the Processing of Personal Data in accordance with Applicable Law. Data Subject is the living, natural person whose Personal Data is being processed. Personal Data is all information relating, directly or indirectly, to an identifiable natural person. Processing means any operation or set of operations which is performed on Personal data, e. g.  storage, modification, reading, handover and similar. Processor is the company/organisation that processes Personal Data on behalf of the Controller and can therefore only process the Personal Data according to the instructions of the Controller and the Applicable Law. The Services are to provide companies with highly skilled tech talents as well as providing opportunities for talents in search of a remote job. The definitions above shall apply in  the Policy regardless if they are capitalised or not. ',
  title3: "YaazhTech's role as a Controller",
  definition1: 'The information in this Policy covers Personal Data Processing for which yaazhTech is the Controller.  As a Controller we are responsible for the Processing for which we decide the purpose   and the means for the Processing (what methods, what Personal Data and for how long it is stored.  The Policy does not describe how we Process Personal Data in the role of a Processor - i. e.  when we process Personal Data on behalf of our customers. ',
  definition2: 'We are providing a platform where we match tech talent with companies in need of adding people to their tech teams.  We need to process information in order to be able to reach out to people actively showing interest in our services (to hire talent or to apply to become a member of our network), create user accounts, match clients with talents, handle time reporting, invoicing etc.  In some cases, we use personal data for marketing purposes by sending out information or offers. ',
  title4: "YaazhTech's Processing of Personal Data",
  content9: 'We have a responsibility to describe and demonstrate how we fulfill the requirements that are imposed on us when we Process your Personal Data.  This section aims to give you an understanding of what type of Personal Data we Process and on what reasons. ',
  definition3: 'For how long do we store your Personal Data?',
  content10: 'We will keep your Personal Data as long as it is necessary for the purpose for which it was collected.  Depending on the lawful basis on which we support the Processing, this may a) be regulated in a contract, b) be dependent on valid consent, c) be stated in legislation or d) follow by an internal assessment based on a legitimate interest assessment (LIA).  In the list below, we indicate, where possible, the period during which the Personal Data will be stored and the criteria used to determine the storage period. ',
  content11: 'We never store your Personal Data longer than neccessary and delete Personal Data regularly.  YaazhTech also takes reasonable actions to keep the Personal Data being Processed updated and to delete outdated and otherwise incorrect or redundant Personal Data. ',
  definition4: 'Processing',
  content12: 'The main purpose of the data processing undertaken by us is to provide, carry out and improve our services to you.  There are several different reasons why we may need to collect, manage and save your data. ',
  content13: 'We mainly Process the following types of Personal Data:',
  content14: 'Contact details to be able to confirm your identity, to verify your personal and contact details and to be able to communicate with you',
  content15: 'Information on your usage of the service or product to improve our services provided to you',
  content16: 'IP-adress to perform customer analysis and for content on our site to be presented effectively to you and the device you use',
  definition5: 'How do we get access to your Personal Data?',
  content17: 'We collect your Personal Data in a number of different ways.  We mainly get access to your Personal Data:',
  content18: 'By you providing your Personal Data to us',
  content19: 'Through third-party analysis technology ex.  cookies',
  definition6: 'Lawful basis',
  content20: 'In order for us to be able to process your Personal Data, it is required that we have so-called legal basis for each process.  In our business, we process your personal data mainly on the following grounds:',
  content21: 'Performance of a contract - The Processing is necessary for the performance of a contract entered between us and the Data Subject, or to prepare for entering into an agreement with the Data Subject. ',
  content22: 'Legitimate interest - YaazhTech may process Personal Data if we have assessed that a legitimate interest overrides the interest of fundamental rights and freedoms of the Data Subject, and if the processing is necessary for the purpose in question. ',
  title5: 'Your rights',
  content23: 'You are the one in control of your Personal Data and we always strive to ensure that you can exercise your rights as efficiently and smoothly as possible. ',
  content24: 'Access - You always have the right to receive information about the Processing of data that concerns you.  We only provide information if we have been able to verify that it is you that are requesting the information. ',
  content25: 'Rectification - If you find that the Personal Data we process about you is incorrect, let us know and we will fix it!',
  content26: 'Erasure - Do you want us to completely forget about you? You have the right to be forgotten and request deletion of your Personal Data when the Processing is no longer necessary for the purpose for which it was collected.  If we are required to retain your information under applicable law or a contract that we have entered with you, we will ensure that it is processed only for the specific purpose set forth in such applicable law or contract.  We will thereafter erase the information as soon as possible. ',
  content27: "Objections - Do you disagree with our assessment that a legitimate interest for Processing your Personal Data overrides your interest in protecting your privacy? Don't worry - in such case, we will review our legitimate interest assessment.  Of course, we add your objection to the balance and make a new assessment to see if we can still justify our Processing of your Personal Data.  If you object to direct marketing, we will immediately delete your personal information without making an assessment. ",
  content28: 'Restriction - You can also ask us to restrict our Processing of your Personal Data',
  content29: 'Whilst we are Processing a request from you for any of your other rights;',
  content30: 'If, instead of requesting erasure, you want us to limit the Processing of Personal Data for a specific purpose.  For example, if you do not want us to send advertising to you in the future, we still need to save your name in order to know that we should not contact you; or',
  content31: 'In cases where we no longer need the information in relation to the purpose for which it was collected, provided that you do not have an interest in retaining it to make a legal claim. ',
  content32: 'Data portability - We may provide you with the data that you have submitted to us or that we have received from you in connection with a contract that we have entered with you.  You will receive your information in a commonly used and machine-readable format that you can transfer to another personal data manager. ',
  content33: 'Withdraw consent - If you have given consent to one or several specific Processing(s) of your Personal Data, you have the right to withdraw your consent at any time and thus ask us to terminate the Processing immediately.  Please note that you can only withdraw your consent for future Processing of Personal Data and not for Processing that has already taken place. ',
  definition7: 'How you use your rights',
  content34: 'Reach out to business@yaazhtech. com business@yaazhtech. com',
  title6: 'Transfer of personal data',
  content35: 'In order to run our business, we may need help from others who will process Personal Data on our behalf, so-called Processors. ',
  content36: 'In cases where our Processors transfer Personal Data outside the EU/EEA, we have ensured that the level of protection is adequate, and in compliance with Applicable Law, by controlling that either of the following requirements are fulfilled:',
  content37: 'the EU Commission has determined that the level of protection is adequate in the third country where the data is processed;',
  content38: "the Processor has signed up to the EU Commission's standard contract clauses (SCCs) for data transfer to non-EU/EEA countries; or",
  content39: 'the Processor has taken other appropriate safeguards prior to the transfer and that such safeguards comply with Applicable law. ',
  content40: 'We have entered into Data Processing Agreements (DPA) with all our Processors.  The DPA sets out, among other things, how the Processor may process the Personal Data and what security measures are required for the Processing. ',
  content41: 'We may also need to disclose your personal information to certain designated authorities in order to fulfill obligations under applicable law or legally binding judgements. ',
  definition8: 'Our processors',
  content42: 'YaazhTech does not sell your Personal Data to third parties and of course we do not share your Personal Data with just anyone.  However, in some cases we may need to share your Personal Data with selected third parties.  If so, we make sure that the transfer happens in a secure way that protects your privacy.  To follow are categories of recipients with whom we may share your data. ',
  content43: 'Advertising agencies and suppliers of print and advertising. ',
  content44: 'IT suppliers for e. g.  business systems and case management.  In order to be able to carry out our assignments and services, we store your Personal Data in our business systems (a system that administers our customers and contacts). ',
  content45: 'Statistics to contribute to industry statistics and to improve the customer experience. ',
  title7: 'Security measures',
  content46: 'YaazhTech has taken technical and organisational measures to ensure that your Personal Data is processed securely and protected from loss, abuse and unauthorised access. ',
  definition9: 'Our security measures',
  content47: 'Organisational security measures are measures that are implemented in work methods and routines within the organisation. ',
  content48: 'Internal governance documents (policys/instructions)',
  content49: 'Login and password management',
  content50: 'Physical security (premises etc. )',
  content51: 'Technical security measures are measures implemented through technical solutions. ',
  content52: 'Encryption',
  content53: 'Access log',
  content54: 'Secure network',
  content55: 'VPN',
  content56: 'Back-up',
  content57: 'Regular security inspection',
  content58: 'Two-step verification',
  title8: 'Cookies',
  content59: 'YaazhTech uses cookies and similar tracking techniques to analyse the use of the Functions so that we can give you the best user experience.  For more information on how we use cookies, see our Cookie Policy (in the footer of the main site). ',
  title9: "If we don't keep our promise",
  content60: 'If you think that we are not Processing your Personal Data correctly, even after you have notified us of this, you are always entitled to submit your complaint to the Swedish Authority for Privacy Protection. ',
  content61: 'More information about our obligations and your rights can be found at https://www. imy. se/',
  content62: 'You can contact the authority via e-mail at: imy@imy. se',
  title10: 'Changes to this policy',
  content63: 'We reserve the right to make changes to this Policy.  In the event that the change affects our obligations or your rights, we will inform you about the changes in advance so that you are given the opportunity to take a position on the updated policy. ',
  title11: 'Contact',
  content64: 'Please contact us if you have questions about your rights or if you have any other questions about how we process your personal information:',
  content65: 'business@yaazhtech. com',
  email_address: 'business@yaazhtech. com',
};

// Twitter Section

const twitterDetails = {
  userName: 'twitter', // Replace "twitter" with your twitter username without @
  display: true, // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreenContent,
  skillsSection,
  techStack,
  openSource,
  ourServices,
  blogSection,
  talkSection,
  contactInfo,
  twitterDetails,
  peppyHireInfo,
  peppyOrderInfo,
  peppyPayInfo,
  peppyDoctorInfo,
  privacyInfo,
  termsInfo,
  cookieInfo,
  clientContent,
  hirePricing,
  jobContent,
  jobRequirements,
  training,
  experienced,
  devPricing,
  education,
};