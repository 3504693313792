import React from 'react';
import technology_1 from "../../assets/images/Techstack/technology_1.jpg";
import technology_2 from "../../assets/images/Techstack/technology_2.jpg";
import technology_3 from "../../assets/images/Techstack/technology_3.jpg";
import technology_4 from "../../assets/images/Techstack/technology_4.jpg";
import technology_5 from "../../assets/images/Techstack/technology_5.jpg";
import technology_6 from "../../assets/images/Techstack/technology_6.jpg";
import technology_7 from "../../assets/images/Techstack/technology_7.jpg";
import technology_8 from "../../assets/images/Techstack/technology_8.jpg";
import technology_11 from "../../assets/images/Techstack/technology_11.png";
import technology_10 from "../../assets/images/Techstack/technology_10.png";
import technology_12 from "../../assets/images/Techstack/technology_12.jpg";
import technology_13 from "../../assets/images/Techstack/technology_13.jpg";
import technology_14 from "../../assets/images/Techstack/technology_14.png";
import { Container } from '../../components/Container';

const TechnologyMarquee = () => {
  const technologies = [
    technology_1, technology_2, technology_3, technology_4, technology_5,
    technology_6, technology_7, technology_8, technology_11, technology_12,
    technology_13, technology_14
  ];

  return (
    <div className="overflow-hidden py-8">
      <Container className="flex flex-wrap justify-center  max-w-full">
        <div className="text-center">
            <h2 className="text-3xl font-semibold">Technology Stack</h2>
        </div>
      <marquee behavior="alternate">
        <div className="flex items-center justify-center space-x-4 py-8">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-24 h-24 flex items-center justify-center 
                         transform hover:scale-110 transition-transform duration-300"
            >
              <img src={tech}  alt="technology" />
            </div>
          ))}
        </div>
      </marquee>
      </Container>
    </div>
  );
};

export default TechnologyMarquee;
