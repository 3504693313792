import React from 'react'
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { SeoConfigurationEn } from '../../../data/content/En/SeoConfigurationEn';
import { SeoConfigurationDe } from '../../../data/content/De/SeoConfigurationDe';
import { Language } from '../../../config/Config';
import { Link } from 'react-router-dom';
import Button from '../../../components/StaticButton/Button';
const SeoConfiguration = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
   
    <div className="main contact-margin-top" id="contact">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10 mt-4">
         <Button text={Language==='en'? SeoConfigurationEn.button:SeoConfigurationDe.button} navigation="/devSubscriptionPricingSeo" />
        
                <br></br>
                <br></br>
        
          <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? SeoConfigurationEn.header:SeoConfigurationDe.header}</h1>
         <br></br>
         <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? SeoConfigurationEn.whyfree:SeoConfigurationDe.whyfree}
                {' '}</b>
                 </p><br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.w1:SeoConfigurationDe.w1}
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? SeoConfigurationEn.whyStart:SeoConfigurationDe.whyStart}</b>
                  
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.s1:SeoConfigurationDe.s1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.s2:SeoConfigurationDe.s2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.s3:SeoConfigurationDe.s3}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? SeoConfigurationEn.features:SeoConfigurationDe.features}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.f1:SeoConfigurationDe.f1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.f2:SeoConfigurationDe.f2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.f3:SeoConfigurationDe.f3}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? SeoConfigurationEn.servicesWorks:SeoConfigurationDe.servicesWorks}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.a1:SeoConfigurationDe.a1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.a2:SeoConfigurationDe.a2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.a3:SeoConfigurationDe.a3}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? SeoConfigurationEn.readytoGo:SeoConfigurationDe.readytoGo}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? SeoConfigurationEn.r1:SeoConfigurationDe.r1}</li>
                  {' '}
                  </p><br></br>
                  <Button text={Language==='en'? SeoConfigurationEn.button:SeoConfigurationDe.button} navigation="/devSubscriptionPricingSeo" />
       
              
         </div>
         </div>
         </div>
         </div>
  )
}

export default SeoConfiguration