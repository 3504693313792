const DirectHireEn={

    header:"Welcome to YaazhTech ",
    transformation:"Your Gateway to Talent Transformation",
   w1:" At YaazhTech, we understand that building a successful team is the cornerstone of organizational growth. Whether you're seeking immediate expertise through direct hires or looking to nurture emerging talent with internship programs, YaazhTech is your strategic partner in talent acquisition. Explore our tailored solutions and discover how we can elevate your team and contribute to your company's success.",
    header1:"Direct Hire Solutions Crafting Your Dream Team:",
    dreamTeam:"Why Choose Direct Hire with YaazhTech?",
    talent:"1. Strategic Talent Acquisition:",
    s1:"We excel in identifying and recruiting professionals who align seamlessly with your organization's goals. Our comprehensive screening process ensures that every candidate presented to you possesses the skills and expertise required to make an immediate impact.",
timeProcess:"2. Efficiency in Time and Process",
   f1:"In the fast-paced business landscape, time is a precious resource. YaazhTech's streamlined hiring process is designed for efficiency, delivering top-tier candidates promptly so that you can focus on driving your business forward.",
 approch:"3.Customized Recruitment Approach:",
  a1:"Recognizing the uniqueness of every organization, we work closely with you to understand your company culture and specific needs. Our approach is tailored to ensure not just skill alignment but also a cultural fit with your team.",
  quality:"4. Commitment to Quality: ",
  r1:"Quality assurance is at the heart of our recruitment process. We guarantee that each candidate has undergone rigorous assessments, setting a high standard for the talent we bring to your organization.",
  futureLeader:"Internship Programs: Cultivating Future Leaders",
  internForm:"Why Choose Interns from YaazhTech?",
  innovation:"1.Fresh Perspectives and Innovation:",
  e1:"Interns at YaazhTech bring fresh perspectives and innovative ideas to your organization. Our internship programs attract motivated individuals eager to learn and contribute, infusing new energy and creativity into your team.",
  pipelineFuture:"2. Talent Pipeline for the Future:",
  q1:"Our internship programs are designed as a talent pipeline for future full-time hires. By providing interns with a positive and enriching experience, you not only gain immediate support but also cultivate a pool of potential long-term team members.",
  costSolution:"3. Cost-Effective Solution:",
  y1:"Internship programs offer a cost-effective way to meet temporary staffing needs while providing invaluable learning experiences for emerging professionals. It's a mutually beneficial arrangement that contributes to both your organization's success and the interns' professional development.",
  branding:"Enhanced Employer Branding",
  u1:"Being an advocate for intern development enhances your employer brand. Positive experiences shared by interns contribute to a favorable reputation, making YaazhTech an attractive destination for top talent.",
  u2:"Whether you're looking for seasoned professionals through our direct hire services or seeking to shape the future with interns, YaazhTech is committed to being your partner in talent transformation. Contact us today to discuss how our personalized solutions can contribute to the growth and success of your organization.",
  button:"Apply",
  
  };
  export {DirectHireEn};