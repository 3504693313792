import React from 'react';
import { Fade } from 'react-reveal';
import { policyHubEn } from '../../../../../../data/content/En/PolicyHubEn';
import { StateContext } from '../../../../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
 
 const TrainingPolicy= () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="main contact-margin-top" id="contact">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
        
   
      <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn.trainingPolicy}</strong></h1>
      <br></br>
      <h2 className="pages-content-text text-l ml-4 md:ml-10" ><strong>{policyHubEn.termsAndConditions}</strong> </h2>
      <br></br>
      <h4 className="pages-content-text text-l ml-4 md:ml-10" ><strong>{policyHubEn.performanceEvaluation1}</strong> </h4>
      <br></br>
   <ul className="list-disc ml-20" >
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.technicallyTrainedclient}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. periodicallyEvaluate}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.trainAndHireProgram}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. trainingCostOffer}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.trainingTiming}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.spendTraining}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.schedule}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.extraTrainingCost}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.successfulTraining}</li>
   </ul>
   <br></br>
         <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn. disclaimer }</strong></h1>
      <br></br>
      <ul className="list-disc ml-20" >
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.companyReservesTheRightAlter}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.yaazhtechTerminateYourEmploymentContract}</li>
      </ul>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />
       </div>
       </div> 
    </Fade>
  );
};
 
export default TrainingPolicy;