
import React, { useState } from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";



const CardComponent = () => {

    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
      );

      
  const [showReadMore, setShowReadMore] = useState(false);

  const toggleReadMore = () => {
    setShowReadMore(!showReadMore);
  };

  

  return (
    <div className="">
      <div className="">
        
        {!showReadMore && (
            <button
            onClick={toggleReadMore}
            className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-light="true"
            style={{ color: currentColor }}
          >
            Read More..
          </button>
        )}
        {showReadMore && (
          <div className="mt-4 p-4">
            <h2 className="font-bold text-2xl mb-2">WE HAVE EXPERTS IN FOLLOWING TECHNOLOGY AND SERVICES:</h2>
            <ul className="list-none text-xl leading-[2rem]">

              <li>✓ FlexPLM PTC products.</li>
              <li>✓ Java-Springboot micro services.</li>
              <li>✓ Javascript-ReactJs SPA reactive pages.</li>
              <li>✓ Mainframe to Spring-boot micro services modernization.</li>
              <li>✓ IAM, SSO (SailPoint, Ping federate, FIM, KeyCloak, OpenID).</li>
              <li>✓ Camunda BPMN with micro service orchestration.</li>
              <li>✓ AWS solution architects.</li>
              <li>✓ API test engineers.</li>
              
              

            </ul>    
             
           
            <button
            onClick={toggleReadMore}
            className="select-none rounded-lg py-3 px-6 -ml-5 mt-5 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-light="true"
            style={{ color: currentColor }}
          >
            Show Less
          </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
