import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { Toaster } from "react-hot-toast";
import {
  documentGeneration,
  certificateGeneration,
  employeeCertificateGeneration,
} from "../../../../../api/HrApi";
import Holiday from "./Holiday";

const LetterHead = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [letterRequest, setLetterRequest] = useState({
    header: "",
    subHeader: "",
    documentBody: "",
  });
  const [recipientName, setRecipientName] = useState("");
  const [certFor, setCertFor] = useState("Internship");
  const [techFor, setTechFor] = useState("");
  const [duration, setDuration] = useState("");
  const [completionDate, setCompletionDate] = useState("");
  const [pdfSize, setPdfSize] = useState("A4");
  const [rating, setRating] = useState("");
  const [effectiveYear, setEffectiveYear] = useState("");
  const [evaluationYear, setEvaluationYear] = useState("");     
  const [performanceRemarks, setPerformanceRemarks] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [hikePercentage, setHikePercentage] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [currentFixedCtc, setCurrentFixedCtc] = useState("");
  const [certificationFor, setCertificationFor] = useState("salaryCertificate");

  useEffect(() => {
    document.title = "LetterHead";
  }, []);

  const docGeneration = (event) => {
    event.preventDefault();
    setShowLoading(true);
    documentGeneration(letterRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = letterRequest.header + ".pdf";
        link.click();
        setShowLoading(false);
        setLetterRequest({ header: "", subHeader: "", documentBody: "" });
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  const handleGenerateEpicCertificate = (e) => {
    e.preventDefault();
    const certificateRequest = {
      recipientName,
      certFor,
      techFor,
      duration,
      completionDate,
      pdfSize,
      performanceRemarks,
    };
    certificateGeneration(certificateRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = certificateRequest.recipientName + ".pdf";
        link.click();
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  const handleEmployeeSalaryCertificate = (e) => {
    e.preventDefault();
    const employeeCertificateRequest = {
      recipientEmail,
      certificationFor,
      currentFixedCtc,
      hikePercentage,
      rating,        
      effectiveYear,
      evaluationYear
      
    };
    employeeCertificateGeneration(employeeCertificateRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = employeeCertificateRequest.recipientEmail + employeeCertificateRequest.certificationFor+".pdf";
        link.click();
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  return (
    <div className="container grid md:grid-cols-2 gap-4 p-2">
      <div className="p-4 rounded bg-[#E6F6FA]">
        {showLoading ? (
          <div className="flex justify-center items-center">
            <i className="material-icons w3-xxxlarge w3-spin">refresh</i>
          </div>
        ) : (
          <form onSubmit={docGeneration} className="space-y-2">
            <h1 className="text-lg font-semibold text-center">
              Generate Document
            </h1>
            <div>
              <label className="block text-sm font-semibold">Header</label>
              <input
                type="text"
                className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                placeholder="Header"
                value={letterRequest.header}
                onChange={(e) =>
                  setLetterRequest({ ...letterRequest, header: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold">SubHeader</label>
              <input
                type="text"
                className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                placeholder="SubHeader"
                value={letterRequest.subHeader}
                onChange={(e) =>
                  setLetterRequest({
                    ...letterRequest,
                    subHeader: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold">
                Document Body
              </label>
              <textarea
                className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                placeholder="Document Body"
                value={letterRequest.documentBody}
                onChange={(e) =>
                  setLetterRequest({
                    ...letterRequest,
                    documentBody: e.target.value,
                  })
                }
                required
              ></textarea>
            </div>
            <button
              className="w-full py-1 text-sm text-white rounded"
              style={{ backgroundColor: currentColor }}
            >
              SUBMIT
            </button>
          </form>
        )}
      </div>
      <div className="p-4 rounded bg-[#E6F6FA]">
        <h2 className="text-lg font-semibold text-center">
          Generate Certificate
        </h2>
        <form onSubmit={handleGenerateEpicCertificate} className="space-y-2">
          <div>
            <label className="block text-sm font-semibold">
              Recipient Name
            </label>
            <input
              type="text"
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">
              Certificate Type
            </label>
            <select
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={certFor}
              onChange={(e) => setCertFor(e.target.value)}
              required
            >
              <option value="Internship">Internship</option>
              <option value="Technical Training">Technical Training</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-semibold">Tech</label>
            <select
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={techFor}
              onChange={(e) => setTechFor(e.target.value)}
              required
            >
              <option value="Java SE 8 Programmer">Java SE 8 Programmer</option>
              <option value="Java SE 6 Programmer">Java SE 6 Programmer</option>
              <option value="Java SE 8 With Spring-boot">
                Java SE 8 With Spring-boot
              </option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-semibold">Duration</label>
            <input
              type="text"
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              placeholder="e.g., June 2024 - August 2024"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">
              Completion Date
            </label>
            <input
              type="date"
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">PDF Size</label>
            <select
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={pdfSize}
              onChange={(e) => setPdfSize(e.target.value)}
            >
              <option value="A4">A4</option>
              <option value="LANDSCAPE">Landscape</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-semibold">
              Performance Remarks
            </label>
            <textarea
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={performanceRemarks}
              onChange={(e) => setPerformanceRemarks(e.target.value)}
              placeholder="in Percentage (Primary Level)"
            ></textarea>
          </div>
          <button
            className="w-full py-1 text-sm text-white rounded"
            style={{ backgroundColor: currentColor }}
          >
            Generate Certificate
          </button>
        </form>
      </div>
      <div className="p-4 rounded bg-[#E6F6FA]">
        <h2 className="text-lg font-semibold text-center">
          Generate Employee Certificate
        </h2>
        <form onSubmit={handleEmployeeSalaryCertificate} className="space-y-2">
          <div>
            <label className="block text-sm font-semibold">
              Recipient Email
            </label>
            <input
              type="text"
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">
              Certificate Type
            </label>
            <select
              className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
              value={certificationFor}
              onChange={(e) => setCertificationFor(e.target.value)}
              required
            >
              <option value="salaryCertificate">Salary Certificate</option>
              <option value="hikeLetter">Hike Letter</option>
            </select>
          </div>

         

          {certificationFor === "hikeLetter" && (
            <div>
              {" "}
              <div>
                <label className="block text-sm font-semibold">
                  Current Fixed CTC
                </label>
                <textarea
                  className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                  value={currentFixedCtc}
                  onChange={(e) => setCurrentFixedCtc(e.target.value)}
                  placeholder="current ctc before hike"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-semibold">
                  Rating
                </label>
                <textarea
                  className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                  placeholder="hike percentage between 5 to 20 max"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-semibold">
                  Hike Percentage
                </label>
                <textarea
                  className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                  value={hikePercentage}
                  onChange={(e) => setHikePercentage(e.target.value)}
                  placeholder="hike percentage between 5 to 20 max"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-semibold">
                  effective year
                </label>
                <textarea
                  className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                  value={effectiveYear}
                  onChange={(e) => setEffectiveYear(e.target.value)}
                  placeholder="ex: 2024"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-semibold">
                  Evaluation year
                </label>
                <textarea
                  className="form-control w-full text-sm py-1 px-2 bg-transparent border-b border-gray-500 outline-none"
                  value={evaluationYear}
                  onChange={(e) => setEvaluationYear(e.target.value)}
                  placeholder="ex: 2023"
                ></textarea>
              </div>
            </div>
          )}

          <button
            className="w-full py-1 text-sm text-white rounded"
            style={{ backgroundColor: currentColor }}
          >
            Generate Certificate
          </button>
        </form>
      </div>
      <div className="p-4 rounded bg-[#E6F6FA]">
        <Holiday />
      </div>
      <Toaster />{" "}
    </div>
  );
};
export default LetterHead;
