import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { hireCoderEn } from '../../../data/content/En/HireCoderEn';
import { hireCoderDe } from '../../../data/content/De/HireCoderDe';
import { Link } from 'react-router-dom';
import { Language } from '../../../config/Config';

const HireCoder = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      <div className="container">
      <div className="main contact-margin-top" id="contact">
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
          <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? hireCoderEn.button:hireCoderDe.button}</span></Link>
          </button>
            <br></br>
            <br></br>
            <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? hireCoderEn.title:hireCoderDe.title}</h1>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.codingExpertise:hireCoderDe.codingExpertise}</p><br></br>
             
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.question:hireCoderDe.question}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.yourVision:hireCoderDe.yourVision}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.seize:hireCoderDe.seize}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.whyChooseOurCoders:hireCoderDe.whyChooseOurCoders}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.expertise:hireCoderDe.expertise}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.experience:hireCoderDe.experience}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.reliability:hireCoderDe.reliability}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.howItWorks:hireCoderDe.howItWorks}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.postYourProject:hireCoderDe.postYourProject}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.getCoding:hireCoderDe.getCoding}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.whyPartnerWithUs:hireCoderDe.whyPartnerWithUs}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.diverseTalent:hireCoderDe.diverseTalent}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.flexibility:hireCoderDe.flexibility}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.support:hireCoderDe.support}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.readyToHireACoder:hireCoderDe.readyToHireACoder}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.startJourney:hireCoderDe.startJourney}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? hireCoderEn.hireExceptionalRemoteDevelopers:hireCoderDe.hireExceptionalRemoteDevelopers}</li>
             {' '}
             </p><br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? hireCoderEn.expertInTheTech:hireCoderDe.expertInTheTech}</b>
              <ul
                  className="pages-content-text text-lg  ml-4 md:ml-10"
                  style={{ fontSize: 16, listStyle: 'none', fontWeight: 200 }}
                >
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    FlexPLM PTC
                    products.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Java-Springboot micro services.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Javascript-ReactJs SPA reactive pages.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Mainframe to
                    Spring-boot micro services modernization.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    IAM, SSO
                    (SailPoint, Ping federate, FIM, KeyCloak, OpenID).
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    Camunda BPMN
                    with micro service orchestration.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    AWS solution
                    architects.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>✓</span>
                    {' '}
                    API test
                    engineers.
                  </li>
                </ul>
                {' '}
             </p><br></br>

             <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? hireCoderEn.button:hireCoderDe.button}</span></Link>
              </button>
              </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default HireCoder