import axios from 'axios';
import {
  ACCESS_TOKEN,
  config,
} from '../config/Config';

const sendRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      'Authorization',
      `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    );
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  return fetch(options.url, options).then((response) => response.json().then((json) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  }));
};

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

export function shortlist(shortlistedCandidate) {
  return instance.post('/secure/client/shortlist', shortlistedCandidate, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function retrieveProfileImageByEmail(email) {
  // alert("email"+email)
  const url = `/secure/client/image/${email}`;
  return instance.get(url, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}

export function onboardTest(formData) {
  return instance.post('/secure/assessment/testOnboard', formData, {
    headers: {
      'Content-type': undefined,
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function submitAnswers(formData) {
  return instance.post('/secure/assessment/submitAssessmentAnswers', formData, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function submitResult(formData) {
  return instance.post('/secure/assessment/submitResult', formData, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function assessmentEvaluationMCQ(formData) {
  return instance.post('/secure/assessment/assessmentEvaluationMCQ', formData, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function getPageAssessments(pageNumber, category, searchText) {
  if (!searchText) {
    if (pageNumber !== undefined && pageNumber !== null && !isNaN(+pageNumber)) {
      return instance.get(`/secure/assessment/dashboard/assessments/${category}?page=${pageNumber}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
        },
      });
    }
    return instance.get(`/secure/assessment/dashboard/assessments/${category}?page=0`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    });
  }
  return instance.get(`/secure/assessment/dashboard/assessments/${category}?page=0&text=${searchText}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function getPageTrackAssessments(pageNumber, category, searchText) {
  if (!searchText) {
    if (pageNumber !== undefined && pageNumber !== null && !isNaN(+pageNumber)) {
      return instance.get(`/secure/assessment/dashboard/trackAssessments/${category}?page=${pageNumber}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
        },
      });
    }
    return instance.get(`/secure/assessment/dashboard/trackAssessments/${category}?page=0`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    });
  }
  return instance.get(`/secure/assessment/dashboard/trackAssessments/${category}?page=0&text=${searchText}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function deleteAssessment( testId) {
  return instance.delete(`/secure/assessment/deleteAssessment/${testId}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}