const DirectInternDe={

  title:"Starten Sie Ihre Tech-Karriere mit dem Direct Intern Program von Yaazhtech!",
  titleExplain:"Sind Sie ein Programmiergenie mit einer Leidenschaft für die Entwicklung innovativer Web-Erlebnisse? Träumen Sie davon, Codezeilen in dynamische Websites und Anwendungen umzuwandeln? Dann ist das Direct Intern Program von Yaazhtech der perfekte Startpunkt für Ihre Tech-Karriere!",
  heading:"Ihr Tor zur Tech-Welt:",
 headingExplain:"Unser innovatives Programm bietet eine Vielzahl spannender Praktikumsmöglichkeiten, die auf unterschiedliche Interessen und Fähigkeiten zugeschnitten sind:",
 l1:"Tauchen Sie ein in die Welt der Webentwicklung und beherrschen Sie die Grundlagen der Front-End- und Back-End-Technologien.",
 l2:"Lassen Sie Ihrer Kreativität als Front-End-Praktikant (ReactJS) freien Lauf und erstellen Sie Benutzeroberflächen, die sowohl schön als auch funktional sind.",

 l3:"Erhalten Sie einen Einblick in die Front-End- (ReactJS) und Back-End-Entwicklung (JAVA) und gewinnen Sie ein ganzheitliches Verständnis des Webentwicklungsprozesses.",
 l4:"Verbessern Sie Ihre Fähigkeiten in der Java-Programmierung und erstellen Sie robuste und skalierbare Anwendungen.",

 l5:"Bei der Entwicklung mobiler Anwendungen werden Ihre Ideen als funktionierende Software auf Smartphones und Tablets zum Leben erweckt.",
 l6:"Lernen Sie die Kunst von DevOps kennen und sorgen Sie für eine nahtlose Zusammenarbeit zwischen Entwicklungs- und Betriebsteams.",

 l7:"Ein Python-Praktikant sammelt praktische Erfahrungen mit Python, um in einem professionellen Umfeld Probleme zu erstellen und zu lösen",
 l8:"Als Go-Programmierpraktikant tauchen Sie in die Entwicklung effizienter und skalierbarer Software unter Verwendung der beliebten Sprache von Google ein.",

beyond:"Über das Codieren hinaus: Verschiedene Wege erkunden:",
beyondExp:"Während sich unser Programm auf programmierungsorientierte Praktika konzentriert, sind wir uns der riesigen Landschaft der Technologiebranche bewusst! Wir bieten auch Möglichkeiten in:",
l9:"Tauchen Sie ein in die Welt des digitalen Marketings und erkunden Sie Wege wie Social-Media-Marketing, Suchmaschinenoptimierung (SEO) und Content-Marketing.",
l10:"Lernen Sie die Feinheiten von Camunda, einer führenden Open-Source-Workflow-Engine, kennen und erwerben Sie wertvolle Kenntnisse in der Prozessautomatisierung.",
l11:"Unterstützen Sie die Personalabteilung bei administrativen Aufgaben, bei der Personalbeschaffung und machen Sie sich mit HR-Prozessen vertraut.",
l12:"Stellen Sie sicher, dass Softwareanwendungen reibungslos funktionieren, indem Sie Kommunikationskanäle (APIs) testen.",
l13:"Erfahren Sie, wie Sie Daten mithilfe der Structured Query Language (SQL) verwalten und analysieren.",

ready:"Warten Sie nicht auf ein traditionelles Praktikumsprogramm, um Ihre technische Karriere zu starten.",

passion:"Lassen Sie uns Ihre Leidenschaft in einen Sinn verwandeln! Treten Sie der Yaazhtech-Familie bei und begeben Sie sich noch heute auf Ihr aufregendes Tech-Abenteuer!",
applyButton:"Hier bewerben",
  
  };
  export {DirectInternDe};