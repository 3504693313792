
import React, { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../contexts/StateContext';
import { ACCESS_TOKEN, COOKIE_PREF } from "../../../config/Config";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import toast, { Toaster } from 'react-hot-toast';
import {
  login,
  requestResetPassword,
  saveCookie,
  disable2FA,
} from "../../../api/Api";

function ForgotPassword() {

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();


  const updateForgotPasswordEmail = (event) => {
    setForgotPasswordEmail(event.target.value);
  };

  const showForgotPasswordModalFunction = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  const closeAlert = () => {
    toast(false);

  };


  const forgotPasswordSend = (event) => {
    event.preventDefault();

    if (forgotPasswordEmail) {
      setShowLoading(true);
      requestResetPassword(forgotPasswordEmail)
        .then((res) => {
          toast.success("Your request has been accepted! If this email address is correct, you will soon receive an email with a link to reset your password.");
          toast.success("success");
          setShowLoading(false);
          showForgotPasswordModalFunction();
          navigate("/");
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error("Your username or password is incorrect. Please try again!");
            toast.error("error");
          } else {
            toast.error("Sorry! Something went wrong. Please try again or check with email registered or not !");
            toast.error("error");
          }
          setShowLoading(false);
        });
    } else {
      toast.error("Please enter a valid email address.");
      toast.error("error");
    }


  };


  const token = localStorage.getItem(ACCESS_TOKEN);
  if (
    token !== "undefined" &&
    token !== undefined &&
    token !== null &&
    token !== "null" &&
    token !== ""
  ) {
    //<ShowAlert message="You are already logged in !" type="info" />


    return <Navigate to='/login' replace />
  }

  else {


    return (


      <div>

        <section className="container mx-auto p-4">
          <div className="max-w-md mx-auto p-8 rounded bg-[#E6F6FA]">
            <div className="card card-signin my-5 -mt-10">
              <div className="card-body ml-5 mr-5 mt-5">
                <h2 className="text-xl uppercase flex justify-center font-semibold mb-2 mt-4">
                  Forgot Password
                </h2>
                <br />

                <span style={{ color: currentColor }}>
                  <p className='flex justify-center items-center'>To change your password please enter your email address</p>
                </span>
                <form onSubmit={(e) => forgotPasswordSend(e)}>
                  <input
                    type="email"
                    autoComplete="on"
                    className="form-control w-full text-base py-2 my-4 bg-transparent
                        text-black border-b border-black outline-none focus:outline-none"
                    id="enable2FAPasswordConfirm"
                    required
                    placeholder="Enter your email address"
                    onChange={updateForgotPasswordEmail}
                  />
                  <button onClick={closeAlert} className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                    style={{ backgroundColor: currentColor }} >
                    <span>
                      Continue
                    </span>
                  </button>

                </form>
                <br />
              </div>



            </div>
          </div>
        </section>

        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: '#FF4136',
                color: '#fff',
              },
            },

            success: {
              duration: 6000,
              style: {
                background: '#008000',
                color: '#fff',
              },
            },
          }}
        />

      </div>


    )
  }
}
export default ForgotPassword




