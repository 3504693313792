import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import {
  CircularProgress,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { salaryGeneration } from "../../../../../api/AdminApi";

const SalaryStatement = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [salaryStatementRequest, setSalaryStatementRequest] = useState({
    userName: "",
    month: "",
    UAN: "",
    PF: "",
    panYesOrNo: "Yes",
    modeOfPayment:"",
    taxDeductedSoFar:"",
    taxDeductedSoFarThisMonth:""
  });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Salary Statement Generation";
  }, []);

  const updateUserName = (event) => {
    setSalaryStatementRequest({
      ...salaryStatementRequest,
      userName: event.target.value,
    });
  };
  const updatePanYesOrNo = (event) => {
   // alert("in"+event.target.value)
    setSalaryStatementRequest({
      ...salaryStatementRequest,
      panYesOrNo: event.target.value,
    });
  };
  const updateTaxDeductedSoFarThisMonth = (event) => {
    // alert("in"+event.target.value)
     setSalaryStatementRequest({
       ...salaryStatementRequest,
       taxDeductedSoFarThisMonth: event.target.value,
     });
   };
  
  const updateTaxDeductedSoFar = (event) => {
    // alert("in"+event.target.value)
     setSalaryStatementRequest({
       ...salaryStatementRequest,
       taxDeductedSoFar: event.target.value,
     });
   };
   const updateModeOfPayment = (event) => {
    // alert("in"+event.target.value)
     setSalaryStatementRequest({
       ...salaryStatementRequest,
       modeOfPayment: event.target.value,
     });
   };
  

  const updateMonth = (event) => {
    setSalaryStatementRequest({
      ...salaryStatementRequest,
      month: event.target.value,
    });
  };

  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };

  const SalaryStatementGeneration = (event) => {
    event.preventDefault();
    setShowLoading(true);

    salaryGeneration(salaryStatementRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = salaryStatementRequest.userName + ".pdf";

        link.click();
        toast.success("Salary Statement generated successfully!");
        setShowLoading(false);
        setSalaryStatementRequest({ userName: "", month: "", panYesOrNo: "" });
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist!");
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <div className="container mx-auto p-4">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              {showLoading ? (
                <div className="text-center">
                  <Typography variant="h5">
                    Salary Statement Generation
                  </Typography>
                  <CircularProgress />
                </div>
              ) : (
                <form onSubmit={SalaryStatementGeneration}>
                  <h3 className="text-3xl font-bold mb-4">Salary Statement</h3>

                  <TextField
                    fullWidth
                    label="UserName"
                    variant="outlined"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.userName}
                    onChange={updateUserName}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    fullWidth
                    label="panYesOrNo"
                    variant="outlined"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.panYesOrNo}
                    onChange={updatePanYesOrNo}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    fullWidth
                    label="Cash/Bank Transfer"
                    variant="outlined"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.modeOfPayment}
                    onChange={updateModeOfPayment}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    fullWidth
                    label="taxDeductedSoFar"
                    variant="outlined"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.taxDeductedSoFar}
                    onChange={updateTaxDeductedSoFar}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    fullWidth
                    label="taxDeductedSoFarThisMonth"
                    variant="outlined"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.taxDeductedSoFarThisMonth}
                    onChange={updateTaxDeductedSoFarThisMonth}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    fullWidth
                    label="Month"
                    variant="outlined"
                    type="month"
                    className="mt-3"
                    required
                    value={salaryStatementRequest.month}
                    onChange={updateMonth}
                  />
                  <br></br>
                  <br></br>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-5"
                    style={{ backgroundColor: currentColor }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="mt-14"
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default SalaryStatement;
