import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { devPricing } from "../../../data/content/PageContent";
import { Link } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import Button from "../../../components/StaticButton/Button";
const DevSubscriptionPricing = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  return (
    <div className="mt-2 p-2 md:ml-6 md:mr-6 rounded-3xl">
      <div
        className={`rounded-3xl ${
          currentMode === "Dark" ? "bg-white" : "bg-white"
        }`}
      >
        <div
          className={`pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center rounded-3xl ${
            currentMode === "Dark"
              ? "bg-white"
              : "bg-white"
          }`}
        >
          <h1 className="text-3xl font-bold " style={{ color: currentColor }}>
            Pricing
          </h1>
       
          
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="overflow-hidden p-0.5 border rounded-lg dark:border-black-700">
              <div className="sm:flex sm:-mx-0.5"></div>
            </div>
          </div>
          <div className=" flex gap-3 justify-center">
          <Button text="Online"/>
          <Button text="Offline" navigation="/devSubscriptionPricingYear"/>
          </div>
          {/* <button className="focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white-800 dark:text-white-200 dark:hover:bg-blue-700 bg-transparent rounded-lg hover:bg-blue-500">
            Online
          </button>
          <Link to="/devSubscriptionPricingYear">
            <button className="focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white-800 dark:text-white-200 dark:hover:bg-blue-700 bg-transparent rounded-lg hover:bg-blue-500">
             Offline
            </button>
          </Link> */}
        </div>
        <div className="center-container text-center">
        <span className="contact-info text-xl font-bold uppercase mb-2">
        <p>
        <a className="whatsapp-link" href="https://wa.me/916382289060" target="_blank" rel="noopener noreferrer">
        Whatsapp us for quick apply 
            <FaWhatsapp className='w-8 h-8 inline-block -mt-1 ml-2' style={{ background: 'green', color: 'white' }} />
            +91 6382289060
        </a>
    </p>
   
    <p>
        <a className="whatsapp-link" href="https://wa.me/916381699905" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className='w-8 h-8 inline-block -mt-1 mr-2' style={{ background: 'green', color: 'white' }} />
             +91 6381699905
        </a>
    </p>
</span>

            </div>
            <br></br>
        <section className="px-6 py-8 mx-auto">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
           
            <card
              className={
                currentMode === "Dark"
                  ? "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
                  : "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
              }
            >
              <p
                class="text-lg font-medium text-black-800 dark:text-black-100"
                style={{ color: currentColor, textAlign: "center" }}
              >
                <Link
                  to={{
                    pathname: "/register",
                  }}
                  state={{
                    subscriptionFor: devPricing.premium,
                    subscriptionCost: devPricing.premiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  }}
                  exact
                >
                  <Button text={devPricing.premium}/>
                </Link>
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-black-800 dark:text-black-100">
                {" "}
                ₹ {devPricing.premiumCost}
                <span class="text-base font-normal text-black-600 dark:text-black-400">
                  / Year + GST
                </span>
              </h4>

              <div class="mt-3 space-y-2">
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumDevAccess}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 "> {devPricing.premiumDevPages}</span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 "> {devPricing.premiumDevUsers}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.premiumDevUsersAdditional}
                  </span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.premiumDevSecureOptions}
                  </span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumDevUsersDelete}</span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">{devPricing.premiumDevSecureEmail}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumDevSEO}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">
                    {devPricing.premiumDevSEOAdditional}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumDevAdditional}</span>
                </div>
              </div>
            </card>
            <card
              className={
                currentMode === "Dark"
                  ? "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
                  : "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
              }
            >
              <p
                class="text-lg font-medium text-black-800 dark:text-black-100"
                style={{ color: currentColor, textAlign: "center" }}
              >
                <Link
                  to={{
                    pathname: "/register",
                  }}
                  state={{
                    subscriptionFor: devPricing.superPremium,
                    subscriptionCost: devPricing.superPremiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  }}
                  exact
                >
                  <Button text={devPricing.superPremium}/>
                </Link>
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-black-800 dark:text-black-100">
                {" "}
                ₹ {devPricing.superPremiumCost}
                <span class="text-base font-normal text-black-600 dark:text-black-400">
                  / Year + GST
                </span>
              </h4>

              <div class="mt-3 space-y-2">
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 "> {devPricing.superPremiumDevAccess}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">{devPricing.superPremiumDevPages}</span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">{devPricing.superPremiumDevUsers}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">
                    {devPricing.superPremiumDevUsersAdditional}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">
                    {devPricing.superPremiumDevSecureOptions}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">
                    {devPricing.superPremiumDevUsersDelete}
                  </span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">
                    {devPricing.superPremiumDevSecureEmail}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 "> {devPricing.superPremiumDevSEO}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.superPremiumDevSEOAdditional}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.superPremiumDevAdditional}
                  </span>
                </div>
              </div>
            </card>
            <card
              className={
                currentMode === "Dark"
                  ? "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
                  : "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
              }
            >
              <p
                class="text-lg font-medium text-black-800 dark:text-black-100"
                style={{ color: currentColor, textAlign: "center" }}
              >
                <Link
                  to={{
                    pathname: "/register",
                  }}
                  state={{
                    subscriptionFor: devPricing.premiumSoftware,
                    subscriptionCost: devPricing.premiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  }}
                  exact
                >
                  <Button text={devPricing.premiumSoftware}/>
                </Link>
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-black-800 dark:text-black-100">
                {" "}
                ₹ {devPricing.premiumSoftwareCost}
                <span class="text-base font-normal text-black-600 dark:text-black-400">
                  / Year + GST
                </span>
              </h4>

              <div class="mt-3 space-y-2">
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumSoftwareDevAccess}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 "> {devPricing.premiumSoftwareDevPages}</span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 "> {devPricing.premiumSoftwareDevUsers}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.premiumSoftwareDevUsersAdditional}
                  </span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">
                    {" "}
                    {devPricing.premiumSoftwareDevSecureOptions}
                  </span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumSoftwareDevUsersDelete}</span>
                </div>

                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>{" "}
                  <span class="mx-4 ">{devPricing.premiumSoftwareDevSecureEmail}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumSoftwareDevSEO}</span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">
                    {devPricing.premiumSoftwareDevSEOAdditional}
                  </span>
                </div>
                <div class="flex items-center">
                  <icon>
                    {" "}
                    <AiOutlineArrowRight />
                  </icon>

                  <span class="mx-4 ">{devPricing.premiumSoftwareDevAdditional}</span>
                </div>
              </div>
            </card>
            
          </div>
        </section>
      </div>
      <p className="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.header}
      </p>
      <small className="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.taskEstimation}
      </small>
      <small className="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.noCards}
      </small>
    </div>
  );
};
export default DevSubscriptionPricing;
