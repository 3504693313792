import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { useContextSelector } from 'use-context-selector';
import { Button } from '.';
import { chatData } from '../data/staticData';
import { StateContext } from '../contexts/StateContext';

function Notification() {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode,
  );
  return (
  
    <div
      className={
        currentMode === "Dark"
          ? "nav-item absolute right-5 md:right-40 top-16 p-8 rounded-lg w-96 bg-dark-gradient-r"
          : "nav-item absolute right-5 md:right-40 top-16 p-8 rounded-lg w-96 bg-light-gradient-r"
      }
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-black-200">Notifications</p>
          <button type="button" className="text-black text-xs rounded p-1 px-2 bg-orange-theme "> 5 New</button>
        </div>
        <Button icon={<MdOutlineCancel />} color="rgb(153, 171, 180)" bgHoverColor="light-black" size="2xl" borderRadius="50%" />
      </div>
      <div className="mt-5 ">
        {chatData?.map((item, index) => (
          <div key={index} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
            <img className="rounded-full h-10 w-10" src={item.image} alt={item.message} />
            <div>
              <p className="font-semibold dark:text-black-200">{item.message}</p>
              <p className="text-black-500 text-sm dark:text-black-400">
                {' '}
                {item.desc}
                {' '}
              </p>
            </div>
          </div>
        ))}
        <div className="mt-5">
          <Button color="white" bgColor={currentColor} text="See all notifications" borderRadius="10px" width="full" />
        </div>
      </div>
    </div>
  );
}

export default Notification;
