const TrainingAndHiringMainEn={
  title:"Accelerate Your IT Career with Yaazhtech's Training and Hiring Services ",
  explain:"Transform into a highly sought-after IT professional with Yaazhtech's comprehensive training and hiring solutions. Our expert-led programs equip you with in-demand skills, and our tailored hiring services connect you to the best opportunities in the industry. Start your journey to IT success with Yaazhtech today.",
  
 // course:"View All Courses and Enroll Now",
  
//course: (
   //   <span>
     //   <a href="/trainHire" style={{ color: 'blue', fontWeight: 'bold'}}>Click Here To View All Courses and Enroll Now!!!</a>
      //</span>
   // ),

  applyButton: "Apply Here",
  uniqueApproach:"Unique Approaches of Yaazhtech Training and Hiring:",
  k1:"Skill-Focused Curriculum: Our training programs are meticulously designed to bridge the gap between theoretical knowledge and practical applications, providing you with the skills employers value most.",
  k2:"Real-World Project Experience: Gain hands-on experience through real-world projects, building a portfolio that showcases your expertise.",
  k3:"Mentorship and Networking: Benefit from guidance by experienced IT professionals and connect with a network of like-minded individuals.",
  k4:"Placement Assistance: Our hiring services offer personalized support, resume building, interview preparation, and direct connections to leading companies.", 
  k5:"Flexible Learning Formats: Choose from online, in-person, or hybrid training options to fit your learning style and schedule.", 
  
  offerTrainingPrograms:"We offer unique training programs in:",
  p1:"Master the skills to build entire applications from scratch, including front-end (ReactJS) and back-end (Java Spring Boot) development.",
  p2:"Gain a strong foundation in Java programming fundamentals, preparing you for advanced studies and professional opportunities.",
  p3:"Learn how to create visually appealing and user-friendly interfaces using popular frameworks like ReactJS.",
  p4:"Dive into the versatile world of Python programming. Develop web applications, analyze data, automate tasks, and more!",
  
  p5:"Build engaging mobile experiences across platforms.",
  p6:"Yaazhtech's comprehensive training and hiring solutions empower you to master API testing, ensuring software quality and driving career advancement in this in-demand field.",
  p7:"Master the efficient and scalable world of Go (Golang) development.",
  p8:"Embark on your DevOps journey with our comprehensive introductory program.",

  p9:"Master the art of online advertising and marketing strategies.",
  p10:"Equip yourself with the skills to build and manage a thriving workforce.",
  p11:"Gain the fundamentals of SQL database management.",
  p12:"Embrace the power of Camunda, a leading process automation platform, and unlock exciting career opportunities with Yaazhtech's comprehensive online training.",

  //newServices:"Discover Our Hiring Services",

  //newServices: (
    //  <span>
      //  <a href="/trainHireDoc" style={{ color: 'blue', fontWeight: 'bold'}}>Discover Our Hiring Services:</a>
      //</span>
    //),

  top: "Top Technology Training and Hiring Services",
  c1:"Java with ReactJS Full Stack Developer",
  c2:"Java Developer (Spring Boot)",
  c3:"UI Developer (React JS)",
  c4:"Mobile App Development",
  c5:"Camunda Development",
  c6:"Python Development",
  c7:"Golang Development",
  c8:"API Test Engineer",
  c9:"SQL Developer",
  c10:"DevOps Engineering",
  c11:"Digital Marketing",
  c12:"HR Manager",
  
  //partner:"Ready to transform your IT career? Get in touch with our experts today!",

  partner: (
      <span>
        <a href="/contact" style={{ color: 'blue', fontWeight: 'bold'}}>Ready to transform your IT career? Get in touch with our experts today!</a>
      </span>
    ),

  }

  

  export {TrainingAndHiringMainEn};