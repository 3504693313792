import React, { useState,useEffect } from "react";
import toast, { Toaster } from "react-hot-toast"; 
import { documentData } from "../../../../../../api/commonApi";
import { CustomInput, FormGroup } from 'reactstrap';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../contexts/StateContext";
 
const DocumentDataSettings = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  const [state,setState] =useState( {
    profilePic: null,
    resumeFile: null,
    profileFileName:'',
    invalidProfileFile:false,
    resumeFileName:'',
    invalidProfileFile:false,
    showLoading: false,
});
 
const [showLoading, setShowLoading] = useState(false);
 
  useEffect(() => {
    document.title = "My account document data settings";
  },[]);
  
  const handleProfileFileChange = ({target: {files}})=>{
 
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000;
    if (size < maxSize) {
      setState({ profileFileName: name, invalidProfileFile: false });
      setState({ profilePic: files[0]});
    } else {
      setState({ profileFileName: '', invalidProfileFile: true });
      toast.error("image size should not exceed 100kb");
      toast.error("error");
      setShowLoading(false);
     // history.push("/me/settings/profilepic");
    }
  }
  const handleResumeFileChange=({target: {files}})=> {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000;
    if (size < maxSize) {
      setState({ resumeFileName: name, invalidResumeFile: false });
      setState({ resumeFile: files[0]});
    } else {
      setState({ resumeFileName: '', invalidResumeFile: true });
      toast.error("Resume size should not exceed 100kb");
      toast.error("error");
      setShowLoading(false);
      //history.push("/");
      //history.push("me/settings/profilepic");
    }
  }
 
  const handleDocumentSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
   
    const formData = new FormData();
    formData.append("profile", state.profilePic);
    formData.append("resume", state.resumeFile);
    documentData(formData)
      .then((response) => {
        const data = response.data;
        toast.success(data.message, "success");
        setShowLoading(false);
 
        setState({
          profilePic: null,
          resumeFile: null,
          resumeFileName:'',
          profileFileName:''
        });
       // history.push("/");
        //history.push("me/settings/profilepic");
      })
      .catch((error) => {
        toast.error(error.message);
        toast.error("error");
        setShowLoading(false);
      });
  };
 
 
    const { profileFileName, invalidProfileFile,resumeFileName,invalidResumeFile } = state;
    return (
      <div className="text-center">
        <h2 className='text-2xl ml-10 font-semibold mt-5 flex items-center justify-center'>ProfilePic and Resume Upload</h2>
      <br></br>
        <div>
          {showLoading ? (
            <div className="align-content-center text-center">
              <h3>Updating your profile pic and resume</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={handleDocumentSubmit}>
              <div className="w-full md:w-1/2 mx-auto p-3">
              <div className="grid md:grid-cols-2">
                <label className=" text-gray-500" htmlFor="profilePic">Profile Picture:</label>
               
                <FormGroup>
                  <CustomInput
                    type="file"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={profileFileName || "choose an image file size less than 100kb"}
                    onChange={handleProfileFileChange}
                    invalid={invalidProfileFile}
                  />
                </FormGroup>
              </div>
              <br></br>
              <div className="grid md:grid-cols-2">
                <label className=" text-gray-500" htmlFor="resume">Latest Resume:</label>
                
                <FormGroup>
                  <CustomInput
                    type="file"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500 "
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={resumeFileName || "choose an pdf or doc file size less than 100kb"}
                    onChange={handleResumeFileChange}
                    invalid={invalidResumeFile}
                  />
                </FormGroup>
              </div>
              <br></br>
              <button
             className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
             type="submit"
             data-ripple-light="true"
             style={{ color: currentColor }}
              >
              <span>Submit</span>
              </button>
              </div>
            </form>
          )}
        </div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
            />
      </div>
    );
 
}
 
export default DocumentDataSettings;