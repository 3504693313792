import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { getRejected } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";


function Rejected() {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = [
    "Search",
    'PdfExport'
  ];
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState("");
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let rejectedList = [];
  useEffect(() => {
    document.title = "Rejected";
    loadData();
  }, []);
  const loadData = () => {
    getRejected(searchText)
      .then((res) => {
        const responseData = res.data.data;
        for (let i = 0; i < responseData.length; i++) {
        const rejected = {
        name : responseData[i].name,
         email : responseData[i].email,
         mobile: responseData[i].mobile,
          graduationYear: responseData[i].graduationYear,
          subscriptionFor :responseData[i].subscriptionFor,
          subscriptionType :responseData[i].subscriptionType,
          appliedOn : splitByT(responseData[i].appliedOn),
                
        };
        rejectedList.push(rejected); 
          
        }
        setData(rejectedList);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl">Rejected Candidate</h2>
      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        allowFiltering={true}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      > 
        <ColumnsDirective>
          <ColumnDirective field='name' headerText='Name' width='100'  textAlign="Center" />
          <ColumnDirective field='email' headerText='Email' width='150'  textAlign="Center" />
          <ColumnDirective field='mobile' headerText='Mobile' width='100'  textAlign="Center" />
          <ColumnDirective field='subscriptionType' headerText='Subscription Type' width='120'  textAlign="Center" />
          <ColumnDirective field='subscriptionFor' headerText='Subscription For' width='120'  textAlign="Center" />
          <ColumnDirective field='appliedOn' headerText='Applied On' width='120'  textAlign="Center" />
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Group, PdfExport]} />
      </GridComponent>
    </div>
  );
}

export default Rejected;
