import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { mobileFreeWebAppImg, pocMvcImg, seoConfigurationImg, staffAugmentationImg } from '../../assets/images';

const ImageSwitcher = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();

  const tabs = [
    { id: 1, label: 'Staff Augmentation', content: 'Content for Tab 1', img: staffAugmentationImg, path: '/staffAugmentation' },
    { id: 2, label: 'Get your free web-app', content: 'Content for Tab 2', img: mobileFreeWebAppImg, path: '/mobileFriendly' },
    { id: 3, label: 'SEO Configuration', content: 'Content for Tab 3', img: seoConfigurationImg, path: '/seoConfiguration' },
    { id: 4, label: 'Free POC/MVC', content: 'Content for Tab 4', img: pocMvcImg, path: '/transformIdeas' },
  ];

  const handleTabClick = (tabId, path) => {
    setActiveTab(tabId);
    navigate(path);
  };
  const handleTabImageClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const activeTabData = tabs.find((tab) => tab.path === activeTab);

  return (
    <div className="flex flex-col items-center">
      <div className="md:flex pb-3 gap-3  grid md:grid-cols-4 space-x-4">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => handleTabClick(tab.id, tab.path)}
            onMouseEnter={() => setActiveTab(tab.path)}
            className={`cursor-pointer ${
              activeTab === tab.id ? 'bg-[#ed008c] text-white' : 'bg-white text-pink-600'
            } hover:bg-[#ed008c] hover:text-white relative z-10 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase transition-all focus:outline-none text-pink-600 border-2 border-pink-600 transition duration-300`}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="mt-8  ">
        {activeTab && activeTabData && (
          <div onClick={() => handleTabImageClick(activeTab)}>
            <img className="object-cover  bg-white h-auto md:h-[400px] rounded-lg shadow-lg flex justify-center w-auto text-pink-600 border-2 border-pink-600 " src={activeTabData.img} alt={activeTabData.label} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSwitcher;
