import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { useLocation, useNavigate } from "react-router-dom";
import { upiIDCompany } from "../../../../config/Config";
import { updatePaymentStatusBackend } from "../../../../api/FinanceApi";
import toast, { Toaster } from "react-hot-toast";

function QRCodeGenerator() {
  const location = useLocation();
  const navigate = useNavigate();
  const { subscription } = location.state;
  console.log(subscription);
  const [upiID, setUpiID] = useState(upiIDCompany);
  const [amount, setAmount] = useState(subscription.costIncludingGstPay);
  const [qrCodeUrl, setQRCodeUrl] = useState("");
  const [timeout, setTimeoutState] = useState(900); // 60 seconds for countdown
  const [isQRDisabled, setQRDisabled] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  // Generate QR code and reset timer
  const generateQRCode = async () => {
    const qrData = `upi://pay?pa=${upiID}&am=${amount}`;
    const qrCodeImageUrl = await QRCode.toDataURL(qrData);
    setQRCodeUrl(qrCodeImageUrl);
    setQRDisabled(false); // Enable QR code initially
    setTimeoutState(900); // Set timeout to 60 seconds (or desired duration)
  };

  // Timer effect for countdown
  useEffect(() => {
    if (timeout > 0 && qrCodeUrl) {
      const timer = setInterval(() => {
        setTimeoutState((prevTimeout) => prevTimeout - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear timer on unmount
    } else if (timeout === 0) {
      setQRDisabled(true); // Disable QR code when timer reaches 0
    }
  }, [timeout, qrCodeUrl]);

  const updatePaymentStatus = (event) => {};
  // Handle payment completion (simulated)
  const handlePaymentCompletion = () => {
    event.preventDefault();
    setShowLoading(true);
    const currentDate = new Date();

const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // Months are 0-indexed
const day = currentDate.getDate();
    const invoiceStatusRequest = {
      userName: subscription.userName,
      subscriptionId: subscription.subscriptionId,
      paymentId: subscription.paymentId,
      invoiceStatus: "credited",
      comments: "correction",
      transactionId: transactionId,
      amountCredited: amount,
      paymentDate: `${year}-${month}-${day}`,
      totalBillingDay: "",
      invoiceDate: "",
      invoiceDateChange: "no",
      subscriptionCost: "",
      invoiceType: "",
      subscriptionType: "",
    };
    updatePaymentStatusBackend(invoiceStatusRequest)
      .then((res) => {
        //alert("suucesss"+JSON.stringify(res.data.message));
        toast.success(res.data.message);
        navigate("/payment-success", {
          state: {
            amount: amount,
            transactionId: transactionId,
          },
          replace: true,
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2 lg:w-1/3 mx-auto mt-8">
      <div className="text-center mb-6">
        <h1 className="text-3xl font-bold text-blue-700">
          Yaazhtech Software Solutions Pvt Ltd
        </h1>
        <p className="text-gray-600 mt-1">Secure Payment QR Code Generator</p>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-center">
        Generate Payment QR Code
      </h2>

      <label className="text-gray-700 font-medium mb-2">Enter the Amount</label>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter Amount"
        className="border rounded-lg p-2 w-full mb-4"
      />

      <button
        onClick={generateQRCode}
        className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full"
      >
        Generate QR Code
      </button>

      {/* QR Code Display with Timer */}
      {qrCodeUrl && !isQRDisabled && (
        <div className="mt-6 text-center">
          <h3 className="text-xl font-medium mb-2">
            Scan this QR Code (Expires in {timeout}s)
          </h3>
          <img
            src={qrCodeUrl}
            alt="QR Code"
            className="border p-2 mx-auto w-1/2 md:w-1/3"
          />
          <label className="text-gray-700 font-medium mb-2">
            Enter the Transaction ID
          </label>
          <input
            type="text"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            placeholder="Enter the Tranaction Id"
            className="border rounded-lg p-2 w-full mb-4"
          />

          <p className="text-black">
            Please scan the QR code and enter the transaction ID to complete the
            transaction.
          </p>
        </div>
      )}

      {isQRDisabled && (
        <div className="mt-6 text-center text-red-600">
          <p>The QR code has expired. Please generate a new one.</p>
        </div>
      )}

      <button
        onClick={handlePaymentCompletion}
        disabled={isQRDisabled}
        className={`mt-4 ${
          isQRDisabled ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
        } text-white py-2 px-4 rounded-lg w-full`}
      >
        Complete Payment
      </button>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}

export default QRCodeGenerator;
