import React from 'react';
import Button from '../../../components/StaticButton/Button';

const AboutSection = () => {
  return (
    <div className="bg-gray-100 py-16 text-center">
      <h2 className="text-4xl font-bold mb-8">See What We're All About</h2>
      <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-12">
        Freshworks provides innovative customer engagement software for businesses of all sizes, making it easy for teams to build strong relationships with their customers.
      </p>
      <Button text="learn more" navigation="/about" />
    </div>
  );
};

export default AboutSection;
