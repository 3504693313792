import React from 'react';

const Values = () => {
  return (
    <section id="values" className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-gray-800">Our Core Values</h2>
        <p className="mt-4 text-lg text-gray-600">These are the principles that guide our work every day.</p>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Add values cards */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Integrity</h3>
            <p className="mt-4 text-gray-600">We believe in doing the right thing, always.</p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Innovation</h3>
            <p className="mt-4 text-gray-600">We encourage creativity and new ideas.</p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Teamwork</h3>
            <p className="mt-4 text-gray-600">Collaboration is at the heart of our success.</p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Customer Focus</h3>
            <p className="mt-4 text-gray-600">Our customers are our top priority.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
