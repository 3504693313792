
import React, { useState, useEffect } from 'react';


import { accountData } from "../../../../../../api/commonApi";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../contexts/StateContext';
import toast, { Toaster } from "react-hot-toast";

const AccountDataSettings = (props) => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    const [accountDetails, setAccountDetails] = useState({
        accountNo: "",
        ifscCode: "",
        bankName: "",
        upiId: ""

    });

    const [user, setUser] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        document.title = "My account data settings";
    }, []);





    const accountNo = (event) => {
        let req = { ...accountDetails };
        req.accountNo = event.target.value;
        setAccountDetails(req);

    };
    const ifscCode = (event) => {

        let req = { ...accountDetails };
        req.ifscCode = event.target.value;
        setAccountDetails(req);

    };
    const bankName = (event) => {
        let req = { ...accountDetails };
        req.bankName = event.target.value;
        setAccountDetails(req);

    };
    const upiId = (event) => {
        let req = { ...accountDetails };
        req.upiId = event.target.value;
        setAccountDetails(req);

    }
    const accountDataOnboard = (event) => {
        event.preventDefault();
        setShowLoading(true);
        const account = { ...accountDetails };
        //alert("account details" + JSON.stringify(account));
        accountData(account)
            .then(response => {
                const data = response.data;
                toast.success(data.message, "success");
                setShowLoading(true);

                setAccountDetails({
                    accountNo: '', ifscCode: '', bankName: '',
                    upiId: ''
                })

            })
            .catch(error => {
                toast.error(error.message);
                toast.error("error");
                setShowLoading(false);

            })

    };



    return (
        <div className="text-center">

            <h2 className='text-2xl ml-10 font-semibold mt-5 flex items-center justify-center'>Account Data Form</h2>

            <br></br>
            <div>
                {
                    showLoading
                        ?
                        <div className="align-content-center text-center">
                            <h3>Updating your account data</h3>
                            <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                        </div>
                        :
                        <form onSubmit={accountDataOnboard}>
                            <div className="w-full md:w-1/2 mx-auto p-3">
                                <div className="grid md:grid-cols-2">
                                    <label className="text-xl text-gray-500" htmlFor="accountNo">Bank Account No:</label>

                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="accountNo" name='accountNo'
                                        placeholder="Enter your Account No" required autoComplete="on" onChange={accountNo}

                                    />
                                </div>
<br></br>
                                <div className="grid md:grid-cols-2">
                                    <label className="text-xl text-gray-500 " htmlFor="ifscCode">IFSC code:</label>

                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="ifscCode" name='ifscCode'
                                        placeholder="Enter your IFSC code" required autoComplete="on" onChange={ifscCode}

                                    />
                                </div>

<br></br>
                                <div className="grid md:grid-cols-2">
                                    <label className="text-xl text-gray-500" htmlFor="bankName">Bank Name:</label>

                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="bankName" name='bankName'
                                        placeholder="Enter your Bank Name" required autoComplete="on" onChange={bankName}

                                    />
                                </div>
                                <br></br>
                                <div className="grid md:grid-cols-2">
                                    <label className="text-xl text-gray-500 " htmlFor="upiId">UPI Id:</label>

                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="upiId" name='upiId'
                                        placeholder="Enter your UPI id" required autoComplete="on" onChange={upiId}

                                    />
                                </div>
                            </div>
                            <br></br>

                            <button
                                className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="submit"
                                data-ripple-light="true"
                                style={{ color: currentColor }}
                            >
                                <span>Submit</span>
                            </button>
                        </form>
                }

            </div>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        duration: 6000,
                        style: {
                            background: "#FF4136",
                            color: "#fff",
                        },
                    },

                    success: {
                        duration: 6000,
                        style: {
                            background: "#008000",
                            color: "#fff",
                        },
                    },
                }}
            />

        </div>
    );

}

export default AccountDataSettings;