import { getHttpTraces } from '../../../../../api/AdminApi';
import React, { useState, useEffect } from "react";
import { StateContext } from "../../../../../contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import { ToastContainer, toast } from 'react-toastify';
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Selection,
    Inject,
    Edit,
    Toolbar,
    Sort,
    Filter,
    Group,
    PdfExport,
   
  } from "@syncfusion/ej2-react-grids";
  import { useNavigate } from 'react-router-dom';

const Httptrace = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const navigate = useNavigate();
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const toolbarOptions = [
    "Search",
    'PdfExport'
  ];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];   
  const [pageNumber,setPageNumber]=useState("");
  const [category,setCategory]=useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true); 
  const [traceContent, setTraceContent] = useState([]);
    const [Httptrace, setHttptrace] = useState({
        showLoading: false,
        traces: [],
        
      });
      
      useEffect(() => {
        document.title = "API HTTP Traces";
        httpTraces();
      },[]);
        const httpTraces =  (event) => {
          let content = [];
        setHttptrace({ ...Httptrace, showLoading: true });
         getHttpTraces(pageNumber,category,searchText).then((res) => {
           // alert("data+"+JSON.stringify(res.data.traces))
           
            for( let i=0; i< res.data.traces.length; i++){
              let traces = res.data.traces;
              const data ={
                method: "",
                uri: "",
               status: "",
                timeTaken:"",
              };
              data.method = traces[i].request.method;
              data.uri = traces[i].request.uri;
              data.status = traces[i].response.status;
              data.timeTaken = traces[i].timeTaken;
             content.push(data);
            }
            setTraceContent(content);

            toast.success(res.data.message);
            setHttptrace({ ...Httptrace, showLoading: false });
        })
       
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Unable to fetch API to retrieve http request log!'"
          );
        } else  {
          toast.error(error.message);
        }
        setHttptrace({ ...Httptrace, showLoading: false });
      });
      
  };
  
  const traces =(event) =>{
    setHttptrace({...Httptrace.traces.length});
  }
 return (
    <div
    className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl"> The last 100 requests
      <span className="text-muted" style={{ fontSize: 11 }}>made to the API</span>
      </h2>
      <GridComponent
        dataSource={traceContent}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
       
      > 
        <ColumnsDirective>
     
          <ColumnDirective field='method' headerText='Method' width='100'  textAlign="Center" />
          <ColumnDirective field='uri' headerText='URi' width='80'  textAlign="Center" />
          <ColumnDirective field='status' headerText='ResponseCode' width='80'  textAlign="Center" />
         <ColumnDirective field='timeTaken' headerText='TimeTaken(in s)' width='100'  textAlign="Center" />
           </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit,  Filter,  Group, PdfExport]} />
      </GridComponent>
   
  <hr />
  </div>
  )
  };


export default Httptrace;