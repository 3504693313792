import { config, ACCESS_TOKEN } from '../config/Config';

const sendRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  return fetch(options.url, options)
    .then(
      (response) => response.json()
        .then(
          (json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          },
        ),
    );
};

export function login(loginRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/authenticate`,
    method: 'POST',
    body: JSON.stringify(loginRequest),
  });
}

export function register(registerRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/signup`,
    method: 'POST',
    body: JSON.stringify(registerRequest),
  });
}

export function updatePassword(updatePasswordRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/account/changePassword`,
    method: 'POST',
    body: JSON.stringify(updatePasswordRequest),
  });
}

export function check2FAUsage() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/account/settings/2fa/status`,
    method: 'GET',
  });
}

export function enable2FAStepOne() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/account/settings/2fa/enable?step=1`,
    method: 'POST',
  });
}

export function enable2FAStepTwo(enableTwoFARequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/account/settings/2fa/enable?step=2`,
    method: 'POST',
    body: JSON.stringify(enableTwoFARequest),
  });
}

export function disable2FA() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/account/settings/2fa/disable`,
    method: 'GET',
  });
}

export function requestResetPassword(email) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/forgot-password/${email}`,
    method: 'GET',
  });
}

export function resetPasswordApi(resetPasswordRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/forgot-password`,
    method: 'POST',
    body: JSON.stringify(resetPasswordRequest),
  });
}
export function subscribe(subscriptionRequest) {
 // alert("inside api call"+JSON.stringify(subscriptionRequest))
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/subscribe`,
    method: 'POST',
    body: JSON.stringify(subscriptionRequest),
  });
}
export function retrieveProfileData() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/common/profileData`,
    method: 'GET',
  });
}



export function retrieveOrgData() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/common/orgData`,
    method: 'GET',
  });
}
export function retrieveProfileDataByEmail(email) {
 // alert(email)
  return sendRequest({
    url: `${config.url.API_BASE_URL}/secure/common/profileData/${email}`,
    method: 'GET',
  });
}
export function retrieveProjectByFeatureName(featureName) {
  // alert(email)
   return sendRequest({
     url: `${config.url.API_BASE_URL}/secure/kanban/getTasksByFeatureName/${featureName}`,
     method: 'GET',
   });
 }


export function retrieveProjectByOrgName(orgName) {
  // alert(email)
   return sendRequest({
     url: `${config.url.API_BASE_URL}/secure/kanban/getTasksByOrgName/${orgName}`,
     method: 'GET',
   });
 }
export function unsubscribe(unSubscriptionRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/unsubscribe`,
    method: 'POST',
    body: JSON.stringify(unSubscriptionRequest),
  });
}
export function saveCookie(cookieRequest, userNameOrEmail) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/saveCookie/${userNameOrEmail}`,
    method: 'POST',
    body: JSON.stringify(cookieRequest),
  });
}
export function getEndpoints() {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/getEndpoints`,
    method: 'GET',
  });
}
export function getContent(endpoint, category) {
 // alert("data inside backedn fu"+JSON.stringify(endpoint));
  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/getContent/${endpoint}/${category}`,
    method: 'GET',
  });
}
export function contactData(contactUs) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/public/contactMail`,
    method: 'POST',
    body: JSON.stringify(contactUs),
  });
}
export function updateTransactionStatus(transactionTrackingId) {
 // alert("tracking is called")
      return sendRequest({
          url: config.url.API_BASE_URL + "/public/saveTransactionStatus/"+transactionTrackingId,
          method: 'GET'
      });
      
    }
