import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faExternalLinkAlt,
  faRecycle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getEndpoints } from "../../../../../api/Api";
import toast, { Toaster } from "react-hot-toast";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DeleteContent from "./DeleteContent";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Group,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
const EditContent = (props) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
 
  const navigate = useNavigate();
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  //const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = ["Search", "SortAscending", "PdfExport"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState("");
  const [content, setContent] = useState("");
  const [publishContent, setPublishContent] = useState([]);
  const [state, setState] = useState('');
const [showDeleteContent,setShowDeleteContent] = useState(false);
const [deleteContentProps, setDeleteContentProps] = useState(null);


  useEffect(() => {
    document.title = "Content management";
    endPointData();
  }, []);

  const endPointData = () => {
    let endpointList = [];
    setLoading(true);
  
    getEndpoints()
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          let responseData = res.data;
  
          for (let j = 0; j < responseData[i].contentStateList.length; j++) {
            const user = {
              category: responseData[i].category,
              publishedBy: responseData[i].contentStateList[j].publishedBy,
              endpoint: responseData[i].contentStateList[j].endpoint,
            };
  
            endpointList.push(user);
          }
        }
        setPublishContent(endpointList);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        setLoading(false);
        console.log(error);
      });
  };
  const updateSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const loadData = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Add your loadData logic here if needed
  };
  
  const handleUpdate = (props) => {
   // alert("inside handleupdate print endpoint :"+JSON.stringify(props.endpoint));
   // alert("inside handleupdate print category :"+JSON.stringify(props.category));
  

   // navigate("/updatecontent");
   
   const state = {  contentEndPoint: props.endpoint,      
    category: props.category, };     
     navigate('/updatecontent', { state });
       
  };
  
  const handledelete = (props) => {
     //alert("inside handleupdate print endpoint :"+JSON.stringify(props.endpoint));
       //alert("inside handleupdate print category :"+JSON.stringify(props.category));
    const state = {  contentEndPoint: props.endpoint,      
      category: props.category,
     };     
     navigate('/deletecontent', { state });
       
    setShowDeleteContent(true);
    setDeleteContentProps(state);

  };
  const actionTemplate = ((props) => {
    
    const [category, setCategory] = useState('');
    const [endpoint, setEndPoint ] = useState('');
  
   useEffect(()=> {
   // alert("inside handleupdate print endpoint :"+JSON.stringify(props.endpoint));
   // alert("inside handleupdate print category :"+JSON.stringify(props.category));

    showDetail();
   }, []);
    const showDetail = () => {
     setCategory(props.category);
     setEndPoint(props.endpoint);  
    };
   

    return (
      
      <DeleteContent category = {props.category} endpoint ={props.endpoint}/>
      
    )
  })

  if (loading) {
    return (
      <div className="align-content-center text-center">
        <h4 className="text-muted">Loading. Please Wait...</h4>
        <i className="material-icons w3-xxxlarge w3-spin align-content-center">
          refresh
        </i>
      </div>
    );
  }
 
  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl">Edit Content</h2>
      <GridComponent
        dataSource={publishContent}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
       
          <ColumnDirective
            field="category"
            headerText="Category"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="publishedBy"
            headerText="PublishedBy"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="endpoint"
            headerText="Endpoint"
            width="80"
            textAlign="Center"
    
          />
        
          <ColumnDirective
            headerText="Update"
            width="100"
            textAlign="Center"
            template={(props) => (
              <span>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ cursor: "pointer", marginRight: "5px" }}
                  onClick={() => handleUpdate(props)}
                />
              </span>
            )}
          />
          <ColumnDirective
            headerText="Delete"
            width="100"
            textAlign="Center"
            template={actionTemplate }
        
          />

       {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
     
    </div>
  );
        
};

export default EditContent;