import React from 'react'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { EducationEn } from '../../../data/content/En/EducationEn';
import { EducationDe } from '../../../data/content/De/EducationDe';

const EducationDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    <div className="container">
   
    <div className="main contact-margin-top" id="contact">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
            className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
            style={{ backgroundColor: currentColor }}>
            <Link
              to={{
                  pathname: "/educationRegister",
                 }}
              state={{
                  subscriptionCost:subscriptionCost ,
                  subscriptionFor:subscriptionFor ,
                  subscriptionType: subscriptionType,
                }}
                > <span>{Language==='en'? EducationEn.button:EducationDe.button}</span>
             </Link>
          </button>
          <br></br>
          <br></br>
          
         <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'?EducationEn.welcome:EducationDe.welcome}</h1>
         <br></br>
         <p className="pages-content-text text-lg ml-4 md:ml-10">
          {Language==='en'? EducationEn.summary:EducationDe.summary}
          {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
        {Language==='en'?EducationEn.whyChoose:EducationDe.whyChoose}
        {' '}</b>
        </p>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.subjectMatterExperts:EducationDe.subjectMatterExperts}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.subjectSummary:EducationDe.subjectSummary}</li>
        {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.personalizedLearningPlans:EducationDe.personalizedLearningPlans}</b>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.personalSummary:EducationDe.personalSummary}</li>
        {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.comprehensiveCourseCoverage:EducationDe.comprehensiveCourseCoverage}</b>      
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.comprehensiveSummary:EducationDe.comprehensiveSummary}</li>
        {' '}
        </p><br></br>
        
        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.interactiveAndEngagingSessions:EducationDe.interactiveAndEngagingSessions}</b>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.interactiveSummary:EducationDe.interactiveSummary}</li>
        {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.flexibleScheduling:EducationDe.flexibleScheduling}</b>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.flexibleSummary:EducationDe.flexibleSummary}</li>
        {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?EducationEn.benefits:EducationDe.benefits}</b>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.confidenceBuilding:EducationDe.confidenceBuilding}</li>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.focusedLearning:EducationDe.focusedLearning}</li>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.practicalApplication:EducationDe.practicalApplication}</li>
        <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? EducationEn.continuousSupport:EducationDe.continuousSupport}</li>
        {' '}
        </p><br></br>

        <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
        {Language==='en'?EducationEn.conclusion:EducationDe.conclusion}
        {' '}</b>
        </p>
              
        <button
        className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
        style={{ backgroundColor: currentColor }}>
        <Link
          to={{
              pathname: "/educationRegister",
              }}
              state={{
                subscriptionCost:subscriptionCost ,
                subscriptionFor:subscriptionFor ,
                subscriptionType: subscriptionType,
              }} > <span>{Language==='en'? EducationEn.button:EducationDe.button}</span>
        </Link>
        </button>
        </div>
        </div>
        </div>
        </div>
                
  )
}

export default EducationDoc