import React from 'react'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';

import { BackendDeveloperEn } from '../../../data/content/En/BackendDeveloperEn';
import { BackendDeveloperDe } from '../../../data/content/De/BackendDeveloperDe';
import { FullStackDeveloperEn } from '../../../data/content/En/FullStackDeveloperEn';
import { FullStackDeveloperDe } from '../../../data/content/De/FullStackDeveloperDe';
const FullStackDeveloperDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
   
    <div className="main contact-margin-top" id="directIntern">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? FullStackDeveloperEn.button:FullStackDeveloperDe.button}</span>
               
              </Link>
              </button>
        
         <p> <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? FullStackDeveloperEn.header:FullStackDeveloperDe.header}</h1>
         
                
                 <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.w1:FullStackDeveloperDe.w1}</li>
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? FullStackDeveloperEn.eligibility:FullStackDeveloperDe.eligibility}</b>

                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.o1:FullStackDeveloperDe.o1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? FullStackDeveloperEn.stipendOffered:FullStackDeveloperDe.stipendOffered}</b>
              
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.s1:FullStackDeveloperDe.s1}</li>
        
                  {' '}
                 </p> <br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? FullStackDeveloperEn.learn:FullStackDeveloperDe.learn}</ b>
                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? FullStackDeveloperEn.infoLevel:FullStackDeveloperDe.infoLevel}</ b>
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.p1:FullStackDeveloperDe.p1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className=""><b>{Language==='en'? FullStackDeveloperEn.environmentSetup:FullStackDeveloperDe.environmentSetup}</b>
                  
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r1:FullStackDeveloperDe.r1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r1:FullStackDeveloperDe.r1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r2:FullStackDeveloperDe.r2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r3:FullStackDeveloperDe.r3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r4:FullStackDeveloperDe.r4}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r5:FullStackDeveloperDe.r5}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r5:FullStackDeveloperDe.r5}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r6:FullStackDeveloperDe.r6}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? FullStackDeveloperEn.r7:FullStackDeveloperDe.r7}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.install:BackendDeveloperDe.install}</ b>
    j1:"https://www.yaazhtech.com/java-basic-concepts",
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.j1:BackendDeveloperDe.j1">https://www.yaazhtech.com/java-basic-concepts</Link></li>

                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.linkForJava:BackendDeveloperDe.linkForJava}</ b>
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.z1:BackendDeveloperDe.z1">https://www.yaazhtech.com/evaluation-Java-SpringBoot%20Interview%20Questions</Link></li>
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.z:BackendDeveloperDe.z2">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li>
                 </p>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.level:BackendDeveloperDe.level}</ b>
        
                {Language==='en'? BackendDeveloperEn.primaryLevel:BackendDeveloperDe.primaryLevel}
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.g1:BackendDeveloperDe.g1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.reactJs:BackendDeveloperDe.reactJs}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.h2:BackendDeveloperDe.h2">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'?https://w BackendDeveloperEn.h2:BackendDeveloperDe.h2">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li> 
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.flexPlm:BackendDeveloperDe.flexPlm}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'?https://w BackendDeveloperEn.d1:BackendDeveloperDe.d1">https://www.yaazhtech.com/flexPLM-flexPLM-FlexPLM-Self-Intro%202</Link></li>
                  {' '}
{''}</p><br></br>
<p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.dark:BackendDeveloperDe.dark}</ b>
                  
        
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.l1:BackendDeveloperDe.l1}</li>
                  
                  {' '}
                  </p><br></br>
                  <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? BackendDeveloperEn.button:BackendDeveloperDe.button}</span>
               
              </Link>
              </button>
         </div>
         </div>
         </div>
         </div>
  )
}

export default FullStackDeveloperDoc