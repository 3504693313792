const BuildYourVisionEn={
  header:"Build Your Vision with Our End-to-End Development Teams!",
  w1:" At YaazhTech, we're dedicated to being your partner in crafting complete solutions, MVPs, or POCs from inception to implementation.",
  whyChoose:"Why Choose Our End-to-End Teams?",
  s1:"Comprehensive Support: Our teams cover every stage, from ideation to deployment, ensuring a holistic approach to your project.",
  s2:"Expertise Across Domains: Expect specialists in various domains, offering insights and proficiency tailored to your project's needs.",
  s3:"Agile Methodologies: We adapt agile practices to accelerate development and swiftly respond to evolving requirements.",
  teamsWork:"How Our Teams Work",
  f1:"Strategic Planning: Collaborate with our experts to shape your concept into a viable plan.",
  f2:"Development Phase: Our teams diligently work on coding, design, and testing, ensuring a robust final product.",
  f3:"Deployment & Support: From launch to ongoing support, we're with you every step of the way.",
  partnerWithUs:"Why Partner With Us",
  a1:"Full-Scale Solutions: We provide comprehensive solutions, not just code, ensuring a complete product or concept.",
  a2:"Time & Cost Efficiency: Our streamlined process ensures optimized timeframes and budgets.",
  a3:"Dedicated Support: Our commitment doesn't end at deployment; we offer continuous support for your solution's success.",   
  readytoBring:"Ready to Bring Your Idea to Life? ",
  r1:"Team up with our end-to-end development experts today! Let's transform your vision into a tangible and successful product.",
  button:"Get Started",
};
export {BuildYourVisionEn};