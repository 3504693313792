import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { getApplicants } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";

import EmploymentStatus from "./EmploymentStatus";
import OnboardEmployee from "./OnboardEmployee";


function Applicants() {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = [
    "Search",
    'PdfExport'
  ];
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [data, setData] = useState([]);//no need
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
 
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
   //same
  let applicantsList = [];
  useEffect(() => {
    document.title = "Applicants";
    loadData();
  }, []);
  const loadData = () => {
    getApplicants(searchText)
      .then((res) => {
        const responseData = res.data.data;
        for (let i = 0; i < responseData.length; i++) {
          const applicants = {
            name: "",
            email: "",
            mobile: "",
            graduationYear: "",
            subscriptionFor: "",
            subscriptionType: "",
            appliedOn: "",         
        };
          applicants.name = responseData[i].name;
          applicants.email = responseData[i].email;
          applicants.mobile = responseData[i].mobile;
          applicants.graduationYear = responseData[i].graduationYear;
          applicants.subscriptionFor = responseData[i].subscriptionFor;
          applicants.subscriptionType = responseData[i].subscriptionType;
          applicants.appliedOn = splitByT(responseData[i].appliedOn);
          
          applicantsList.push(applicants);
        }
        setData(applicantsList);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        setLoading(false);
        console.log(error);
      });
  };
  
  const dialogTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);   
    };
    return (
     <EmploymentStatus name={userName} email={userEmail}/>   
    )
  })
  const onboardTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);   
    };
    return (  
     <OnboardEmployee name={userName} email={userEmail}/>    
    )
  })
 

  return (
    <div
      // className={
      //   currentMode === "Dark"
      //     ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
      //     : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      // }
    >
      <h2 className="p-1 text-2xl">Applicants Page</h2>
      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        allowFiltering={true}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      > 
        <ColumnsDirective>
        <ColumnDirective field='action' headerText='Action' textAlign="Center" isPrimaryKey={true}  width='100' template={onboardTemplate} />
          {/*<ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />*/}
          <ColumnDirective field='name' headerText='Name' width='100'  textAlign="Center" />
          <ColumnDirective field='email' headerText='Email' width='150'  textAlign="Center" />
          <ColumnDirective field='mobile' headerText='Mobile' width='100'  textAlign="Center" />
          <ColumnDirective field='subscriptionType' headerText='Subscription Type' width='120'  textAlign="Center" />
          <ColumnDirective field='subscriptionFor' headerText='Subscription For' width='120'  textAlign="Center" />
          <ColumnDirective field='appliedOn' headerText='Applied On' width='120'  textAlign="Center" />

          
         {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Group, PdfExport]} />
      </GridComponent>
    </div>
  );
}

export default Applicants;
