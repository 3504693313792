import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { StaffAugDatabaseEngineerEn } from '../../../data/content/En/StaffAugDatabaseEngineerEn';
import { StaffAugDatabaseEngineerDe } from '../../../data/content/De/StaffAugDatabaseEngineerDe';
import { Link } from 'react-router-dom';
import { Language } from '../../../config/Config';

const StaffAugDatabaseEngineer = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      <div className="container">
      <div className="main contact-margin-top" id="contact">
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
          <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? StaffAugDatabaseEngineerEn.button:StaffAugDatabaseEngineerDe.button}</span></Link>
          </button>
            <br></br>
            <br></br>
            <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? StaffAugDatabaseEngineerEn.title:StaffAugDatabaseEngineerDe.title}</h1>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugDatabaseEngineerEn.headingContent1:StaffAugDatabaseEngineerDe.headingContent1}<br></br></p>
             
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugDatabaseEngineerEn.mastersOfDataManipulation:StaffAugDatabaseEngineerDe.mastersOfDataManipulation}</b></p>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugDatabaseEngineerEn.ourSqlDevelopers:StaffAugDatabaseEngineerDe.ourSqlDevelopers}</p>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugDatabaseEngineerEn.leverage:StaffAugDatabaseEngineerDe.leverage}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugDatabaseEngineerEn.craftRobustAndEfficientDatabaseStructures:StaffAugDatabaseEngineerDe.craftRobustAndEfficientDatabaseStructures}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugDatabaseEngineerEn.extractValuable:StaffAugDatabaseEngineerDe.extractValuable}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugDatabaseEngineerEn.maintain:StaffAugDatabaseEngineerDe.maintain}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugDatabaseEngineerEn.partnerWith:StaffAugDatabaseEngineerDe.partnerWith}</b></p><br></br>

    <hr className="my-5" />
    <div className="d-flex justify-content-between"> 
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }}><Link to ="/hireCoderRegister">
        <span><div className="text-center"/>{Language==='en'? StaffAugDatabaseEngineerEn.button:StaffAugDatabaseEngineerDe.button}</span></Link>
        </button>
      {/* TODO */}
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" style={{ backgroundColor: currentColor }} ><Link to ="/staffAugmentation">
      <span><div className="text-center"/>{Language==='en'? StaffAugDatabaseEngineerEn.cancelButton:StaffAugDatabaseEngineerDe.cancelButton}</span></Link>
      </button>
      </div>
              </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default StaffAugDatabaseEngineer