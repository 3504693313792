import React, { useState, useEffect } from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../../src/contexts/StateContext";
import { Navigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { timeSheetUpdateRequest } from "../../../../../../api/HrApi";

//import { ACCESS_TOKEN } from "../../../../../config/Config";
const ClockTime = (props) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [state, setState] = useState({
    timeSheetRequest: {
      date: "",
      hoursWorked: "",
    },
    user: props.user,
    showLoading: false,
  });




  // useEffect(() => {
  //   document.title = "Apply Leaves";
  //   if (localStorage.getItem(ACCESS_TOKEN)) {
  //     const token = localStorage.getItem(ACCESS_TOKEN);
  //     let base64Url = token.split(".")[1];
  //     let base64 = base64Url.replace("-", "+").replace("_", "/");
  //     let decodedToken = JSON.parse(window.atob(base64));
  //     setState(prevState => ({ ...prevState, user: decodedToken }));
  //   }
  // }, []);

  const updateDateWorked = (event) => {
    setState(prevState => ({
      ...prevState,
      timeSheetRequest: {
        ...prevState.timeSheetRequest,
        date: event.target.value,
      },
    }));
  };

  const updateHoursWorked = (event) => {
    setState(prevState => ({
      ...prevState,
      timeSheetRequest: {
        ...prevState.timeSheetRequest,
        hoursWorked: event.target.value,
      },
    }));
  };

  const timeUpdate = (event) => {
    event.preventDefault();
    const req = {
      ...state.timeSheetRequest,
    };
    setState(prevState => ({ ...prevState, timeSheetRequest: req, showLoading: true }));

    timeSheetUpdateRequest(req)
      .then((res) => {
        const data = res.data;         
        toast.success("Your time is logged successfully", data.message);
      //  toast.success(res.data.message);   
        setState(prevState => ({ ...prevState, showLoading: false }));
        return <Navigate to="/timesheet" replace />;
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
        } else {
          toast.error(error.message);
        }
        setState(prevState => ({ ...prevState, showLoading: false }));
      });
  };

  return (
    
    <div  className="text-center">
      <br></br>
      <h1 className='text-2xl ml-10 font-semibold mt-5 flex items-center justify-center' > Clock your time </h1>
      <br></br>
      <div>
        {state.showLoading ? (
          <div className="align-content-center text-center ml-48">
            <h3>Time sheet update...</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              refresh
            </i>
          </div>
        ) : (
          <form onSubmit={timeUpdate}>
            
            <div className="w-full md:w-1/2 mx-auto p-3">
           
              <div className="grid md:grid-cols-2">
                <label className="text-xl text-gray-500 " htmlFor="inputWorkedDate">Date :</label>
                <input 
                class="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                id="inputWorkedDate" 
                type="Date" 
                placeholder="Date worked"
                required
                  onChange={updateDateWorked}
                  autoComplete="on"
                />
               
              </div>
              <br></br>

              <div className="grid md:grid-cols-2">
                <label className="text-xl text-gray-500 " htmlFor="hoursWorked">Hours:</label>
               
                <input
                  type="number"
                  id="hoursWorked"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="hours worked (Ex: 8 or 9)"
                  required
                  onChange={updateHoursWorked}
                  autoComplete="on"
                />
              </div>

              <br></br>

              <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="submit"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                <span >Action</span>
              </button>
            </div>
          </form>

        )}

      </div>
      <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
        crossOrigin="anonymous"
      ></script>
    </div>
   
  );
};

export default ClockTime;