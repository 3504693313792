import React from 'react';
import { Link } from "react-router-dom";
import { FaInstagramSquare, FaMapMarkerAlt, FaWhatsappSquare } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { BsLinkedin } from 'react-icons/bs';
import { GrFacebookOption } from 'react-icons/gr';
import { FaTwitterSquare } from 'react-icons/fa';
import { StateContext } from '../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';


const Footer = () => {
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode,
  );
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  };
  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <div className={currentMode === 'Dark' ? 'bg-[#f5f5f5]' : 'bg-[#f5f5f5]'}>
        <div className="text-black rounded-t-3xl mt-8 md:mt-10 bg-[f5f5f5]">
          <div className="flex flex-col  md:flex-row justify-between p-8 md:px-32 px-5 space-y-8 md:space-y-0">
            <div className="w-full md:w-1/4">
              <h1 className="font-semibold text-xl pb-4">YAAZH TECH Software Solution pvt ltd </h1>
              <p>
                Our company originated with young minds with prematured baby steps into this software
                development world with the motive of providing world class software service from small
                scale to large scale industries with enhanced trainees.
              </p>
            </div>
            <div>
              <h1 className="font-semibold text-xl pb-4 ">Links</h1>
              <nav className="flex flex-col gap-2">
                <Link to='/home' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">Home</Link>
                <Link to='/about' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">About Us</Link>
                <Link to='/services' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">Services</Link>
                <Link to='/trainHire' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">Training</Link>
                <Link to='/education' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">Education</Link>
                <Link to='/directHire' onClick={scrollToTop} className="hover:text-backgroundColor transition-all cursor-pointer">DirectHire</Link>
              </nav>
            </div>

            <div>
              <h4 className='font-semibold text-xl pb-4'>Find Us</h4>
              <div className="space-y-2">
                <p>33/A-9 ST1 Omaluran street, sridevi,</p>
                <p>Complex 1st floor, Near vijaya Mahal,</p>
                <p>Malikundam main road, Mecheri,</p>
                <p>Tamilnadu, India. 636453.</p>
              </div>

              <h4 className='font-semibold text-xl pt-6 pb-4'>Email</h4>
              <div className="space-y-2">
                <p>business@yaazhtech.com</p>
                <p>hr@yaazhtech.com</p>
              </div>
            </div>

            <div>
              <h1 className="font-semibold text-xl pb-4">Contact Us</h1>
              <div className="space-y-2">
                <p>+91 6382289060</p>
                <p>+04298278160</p>
              </div>

              <div className="flex flex-row space-x-3 mt-6">
                <a href="https://www.linkedin.com/company/yaazhtech/mycompany/verification/" className="text-2xl">
                  <BsLinkedin className='w-8 h-8' />
                </a>
                <a href="https://www.facebook.com/yaazhtech" className="text-2xl">
                  <GrFacebookOption className='w-8 h-8' />
                </a>
                <a href="https://twitter.com/yaazhtech" className="text-2xl">
                  <FaTwitterSquare className='w-8 h-8' />
                </a>
                <a href="https://www.instagram.com/yaazhtech/" className="text-2xl">
                  <FaInstagramSquare className='w-8 h-8' />
                </a>
              </div>
            </div>
          </div>

          <div className="text-center py-4">
            <p>© 2012-2024 All rights reserved by YaazhTech.com</p>
            <div className='space-x-2'>
              <Link to="/privacy" onClick={scrollToTop}>Privacy Policy</Link>
              <Link to="/terms" onClick={scrollToTop}>Terms of Use</Link>
              <Link to="/cancellationPolicy" onClick={scrollToTop}>Cancellation Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;