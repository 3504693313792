import React, { useState, useEffect } from "react";
import { StateContext } from "../../../../../contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import {
  getApiUptime, getCpuCount, getCpuUsage, getJvmMemMax, getJvmMemUsed,
} from '../../../../../api/AdminApi';
import {  Navigate } from "react-router-dom";

const Metrics = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [showLoading, setShowLoading] = useState(false);
  const [uptime, setUptime] = useState(0);
  const [cpuUsage, setCpuUsage] = useState(0);
  const [cpuCount, setCpuCount] = useState(0);
  const [memoryUsed, setMemoryUsed] = useState(0);
  const [memoryMax, setMemoryMax] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      getMetrics();
    }, 6000000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getMetrics();
  }, []);
  const getMetrics =  async () => {
    setShowLoading(true);
  try {
      const uptimeResponse =  await getApiUptime();
      setUptime(`${uptimeResponse.data.measurements[0].value}`);

      const memoryUsedResponse = await getJvmMemUsed();
      setMemoryUsed(memoryUsedResponse.data.measurements[0].value);

      const memoryMaxResponse = await getJvmMemMax();
      setMemoryMax(memoryMaxResponse.data.measurements[0].value);

      const cpuUsageResponse = await getCpuUsage();
      setCpuUsage(cpuUsageResponse.data.measurements[0].value);

      const cpuCountResponse = await getCpuCount();
      setCpuCount(cpuCountResponse.data.measurements[0].value);

      setShowLoading(false);
      return <Navigate to="/dashboard" replace />;
    } catch (error) {
      setShowLoading(false);
    }
  };
  const getUptime = () => {
    const sec_num = parseInt(uptime, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = `0${hours}`; }
    if (minutes < 10) { minutes = `0${minutes}`; }
    if (seconds < 10) { seconds = `0${seconds}`; }
    return <p>{`${hours}:${minutes}:${seconds}`}</p>;
  };

  const memUsage = (memoryUsed / memoryMax) * 100;
  const formattedMemUsage = Number(memUsage.toFixed(2));
  const cpuUsagePercentage = (cpuUsage / cpuCount) * 100;
  const formattedCpuUsage = Number(cpuUsagePercentage.toFixed(2));

  return (
    <div className="flex flex-col items-center justify-center h-screen">
    <div>
      <h4 className="text-center font-bold">API Metrics</h4>
      <hr />
      {showLoading ? (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading metrics</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
        </div>
      ) : (
        <div>
          <h6>API uptime</h6>
          {getUptime()}
          <h6>
            JVM Memory usage (
            {formattedMemUsage}
            %)
          </h6>
          <div className="w-full bg-gray-200">
            <div
              className={`h-5 bg-info text-dark ${formattedMemUsage === 100 ?'bg-blue-500 rounded-r-lg' : 'bg-blue-500 rounded-full'}`}
              style={{ width: `${formattedMemUsage}%` }}
              role="progressbar"
              aria-valuenow={formattedMemUsage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
           
          </div>
          <h6>
            JVM CPU usage (
              {formattedCpuUsage}%
            )
          </h6>
          <div className="w-full bg-gray-200">
            <div
              className={`h-5 bg-info text-dark ${formattedCpuUsage === 100 ? 'bg-blue-500 rounded-r-lg' : 'bg-blue-500 rounded-full'}`}
              style={{ width: `${formattedCpuUsage}%` }}
              role="progressbar"
              aria-valuenow={formattedCpuUsage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
           
          </div>
        </div>
      )}
    </div>
     </div>

  );
};

export default Metrics;