const TrainAndHireEn={
    welcome:"Welcome to Yaazhtech: Transforming Careers, Building Futures ",
    w1:"At Yaazhtech, we're not just a training and hiring platform – we're architects of success, dedicated to empowering individuals with the skills they need and connecting them with exciting career opportunities. Explore the seamless journey of getting trained and hired with Yaazhtech. ",
    
    training:"Training Programs: Elevate Your Skills, Ignite Your Potential ",
    t1:"Embark on a journey of continuous learning and growth with our cutting-edge training programs. Whether you're a recent graduate or a seasoned professional, Yaazhtech offers industry-focused courses crafted to equip you with the expertise demanded by today's fast-paced job market. ",
    
    key:"Key Features: ",
    k1:"Industry-Aligned Curriculum:  Stay ahead with up-to-date, industry-relevant content designed by seasoned professionals. ",
    k2:"Hands-On Learning:  Immerse yourself in practical, real-world scenarios to enhance your problem-solving skills. ",
    k3:"Mentorship:  Benefit from personalized guidance and insights from experienced mentors dedicated to your success. ",
    k4:"Certification:  Earn a recognized certification upon successful completion, validating your skills for employers.", 
    
    why:"Why Choose Yaazhtech Training? ",
    f1:"Future-Ready Skills:  Our courses are tailored to emerging trends, ensuring you're equipped with skills that are in high demand. ",
    f2:"Interactive Learning:  Engage in a dynamic learning environment that encourages collaboration and knowledge sharing. ",
    f3:"Career Path Guidance:  Beyond training, we offer valuable insights to help you navigate your career path effectively. ",
    
    yaazh:"Yaazhtech Hiring Hub:  Where Talent Meets Opportunity ",
    y1:"Our commitment goes beyond training; we're passionate about connecting talent with the right opportunities. Whether you're an employer seeking top-tier professionals or a candidate looking for your dream job, Yaazhtech's Hiring Hub is the bridge to your success. ",
    
    our:"Our Hiring Process: ",
    o1:"Needs Assessment:  Understand your organization's goals and workforce requirements. ",
    o2:"Talent Identification:  Source and evaluate candidates based on skills, experience, and cultural fit. ",
    o3:"Comprehensive Screening:  Rigorous assessment to ensure candidates match your specific needs. ",
    o4:"Client-Candidate Synergy:  Facilitate seamless interactions between clients and shortlisted candidates. ",
    o5:"Onboarding Support:  Assist with the onboarding process for a smooth integration into your organization. ",
    
    partner:"Why Partner with Yaazhtech? ",
    p1:"Tailored Solutions:  We customize our services to align with your unique business needs. ",
    p2:"Quality Talent:  Our stringent screening processes ensure you receive candidates of the highest caliber. ",
    p3:"Timely Delivery:  Recognizing the importance of time, we strive for efficient and timely placements. ",
    p4:"Building Futures:  We foster long-term relationships, contributing not just to your immediate needs but to the sustained success of your organization. ",
    
    ready:"Ready to elevate your career or build a dynamic team?  Choose Yaazhtech - where innovation meets talent.",
    contact:"  Contact us today to embark on a transformative journey of growth and success. ",
    
     button:"Apply",
     cancelButton:"Cancel",
    }
        export {TrainAndHireEn};