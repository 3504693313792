const BackendDeveloperEn={

    header:"Direct Intern Opportunities : Backend DEVELOPER ",
   w1:"We are providing Direct Intern for the candidates who are interested in learning Backend(Java with Spring Boot). The candidates take their own time to complete the three levels successfully. They can join as intern with 5K stipend per month. The levels are mentioned below. ",
   eligibility:"Eligibility",
   o1:"BE/BTech or BCA/MCA/Msc",
   stipendOffered:"Stipend Offered",
    s1:"5000 Per Month",
    learn:"Learn More",
    infoLevel:"1.Info Level",
    p1:"Info level contains basic environment setup for Backend and Basic 14 questions in Java ,",
    environmentSetup:"Environment Setup",
    r1:"1.Download jdk 17 2. ",
    r2:"2.Install nosql workbench",
    r3:"3.Download postman  ",
    r4:"4.Install intellij",
    r5:"5.Download github desktop",
   
    install:"Link for installation",
    j1:"https://www.yaazhtech.com/java-basic-concepts",
    linkForJava:"Link for Java 14 Questions:",
    z1:"https://www.yaazhtech.com/evaluation-Java-SpringBoot%20Interview%20Questions" ,
level:"2.Primary level:",
primaryLevel :"Primary level contains ", 
g1:"Profile summary with one sample project explanation, React JS contains 30 questions and FlexPLM has 20 questions",
reactJs:"Java 40 Questions:",
h1:"https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction",
flexPlm:"FlexPLM 20 Questions:",
d1:"https://www.yaazhtech.com/flexPLM-flexPLM-FlexPLM-Self-Intro%202",
dark:"3. Dark Level:",
l1:"This level contains Profile summary, Java 40 questions and Problem solving with real time coding.",


  button:"Apply",
  
  };
  export {BackendDeveloperEn};