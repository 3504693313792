import React, { Component, Suspense } from 'react';
import Lottie from 'react-lottie';
import Loading from '../loading/Loading';

export default class DisplayLottie extends Component {
  render() {
    const { animationData } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
    };

    return (
      <Suspense fallback={<Loading />}>
        <div className="w-full max-w-xs sm:max-w-sm md:w-[780px] mx-auto">
          <Lottie 
            options={defaultOptions} 
            isClickToPauseDisabled 
          />
        </div>
      </Suspense>
    );
  }
}
