import React from 'react';
 
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
const Card = ({ imageUrl, title, title1, description }) => {
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode,
  );
  return (
 
      <div className={currentMode === 'Dark' ? 'max-w-md mx-auto rounded-xl overflow-hidden shadow-md md:flex bg-dark-gradient-r' : 'max-w-md mx-auto rounded-xl overflow-hidden shadow-md md:flex bg-light-gradient-r'}>
      <div className="md:flex-shrink-0">
        <img className=" mt-10 mb-2 mr-5 ml-3 h-48 w-48 object-cover rounded-full md:w-48" src={imageUrl} alt="Card Image" />
      </div>
 
      
      <div className="p-2">
        <h2 className="text-base font-semibold mr-2 mb-2">{title}</h2>
        <h2 className="text-base font-semibold mb-2 mr-2">{title1}</h2>
        <p className="text-black">{description}</p>
      </div>
    </div>
  );
};
 
export default Card;