import React, { useState, useEffect } from "react";
import { onboardTest } from "../../../../api/AssessmentApi";
import { StateContext } from "../../../../contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import { Toaster,toast } from "react-hot-toast";
const Onboard = (props) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [assessmentOnboard, setAssessmentOnboard] = useState({
    orgName: "",
    assessmentName: "",
    assessmentTotalDuration: "",
    assessmentTotalScore: "",
    assessmentMinimumScoreToPass: "",
    testCategory: "",
    testType: "",
    fileType: "",
  });
  const [qaFile, setQaFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Assessment Onboard";
    // onboardTest();
  }, []);

  const updateOrgName = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      orgName: event.target.value,
    });
  };

  const updateAssessmentName = (event) => {
  
    setAssessmentOnboard({
      ...assessmentOnboard,
      assessmentName: event.target.value,
    });
  };

  const updateAssessmentTotalDuration = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      assessmentTotalDuration: event.target.value,
    });
  };

  const updateAssessmentTotalScore = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      assessmentTotalScore: event.target.value,
    });
  };

  const updateAssessmentMinimumScoreToPass = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      assessmentMinimumScoreToPass: event.target.value,
    });
  };

  const updateTestCategory = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      testCategory: event.target.value,
    });
  };

  const updateTestType = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      testType: event.target.value,
    });
  };
  const updateFileType = (event) => {
    setAssessmentOnboard({
      ...assessmentOnboard,
      fileType: event.target.value,
    });
  };
  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    const [{ size, name }] = files;
    const maxSize = 100000000;

    if (size < maxSize) {
      setFileName(name);
      setInvalidFile(false);
      setQaFile(files[0]);
    } else {
      setFileName("");
      setInvalidFile(true);
    }
  };

  const onboardAssessment = (event) => {
   // alert("assessmentOnboard :"+JSON.stringify(assessmentOnboard));

    event.preventDefault();
    setShowLoading(true);
   if( assessmentOnboard.fileType ==="txt"){
    assessmentOnboard.testCategory = assessmentOnboard.testCategory+"mcq";
   }
    const formData = new FormData();
    formData.append("file", qaFile);
    formData.append(
      "assessmentOnboardRequest",
      new Blob([JSON.stringify(assessmentOnboard)], {
        type: "application/json",
      })
    );
    onboardTest(formData)
      .then((res) => {
        // Assuming showAlert is a function defined within this component
        showAlert(res.data.message, "success");
        setShowLoading(false);

        setAssessmentOnboard({
          orgName: "",
          assessmentName: "",
          assessmentTotalDuration: "",
          assessmentTotalScore: "",
          assessmentMinimumScoreToPass: "",
          testCategory: "",
          testType: "",
          fileType: "",
        });
        setQaFile(null);
        // Assuming navigate is a function provided by a routing library
        // navigate("/onlineAssessment",   {
        //   state: {
        //     assessment: res.data.assessment, // Pass the assessment data received from the API
        //     fileType: assessmentOnboard.fileType,
        //   },
        // });
      })
      .catch((error) => {
        if (error.status === 401) {
          showAlert("Your username or email does not exist !", "error");
        } else {
          showAlert(error.message, "error");
        }
        setShowLoading(false);
      });
  };

  const showAlert = (message, type) => {
    if (type === "success") {
      toast.success("You have susscessfully Onboarded the Assessment");
    } else {
      toast.error("unable to Onboard");
    }
  };
  return (
    <div className="w-full md:10 mx-auto p-3">
      <h1 className="md:text-xl sm:text-lg p-3 font-semibold text-center uppercase mb-3">
        Create Assessment
      </h1>
      <br></br>
      <div>
        {showLoading ? (
          <div className="align-content-center text-center">
            <h3>Onboard Assessment initiated</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              in progress
            </i>
          </div>
        ) : (
          <form onSubmit={(e) => onboardAssessment(e)}>
            <button
              className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="submit"
              data-ripple-light="true"
              style={{ color: currentColor }}
            >
              Onboard Assessment
            </button>

            <div className="grid md:grid-cols-2">
              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="orgName">
                  {" "}
                  Org Name{" "}
                </label>
                <input
                  type="text"
                  id="orgName"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Org who initiates online evaluation."
                  required
                  onChange={updateOrgName}
                  autoComplete="on"
                />
              </div>

              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="testName">
                  {" "}
                  Assessment name{" "}
                </label>
                <input
                  type="text"
                  id="testName"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Name of the assessment"
                  required
                  onChange={updateAssessmentName}
                  autoComplete="on"
                />
              </div>

              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="totalDuration">
                  {" "}
                  Duration for Per Question{" "}
                </label>
                <input
                  type="text"
                  id="totalDuration"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Duration in mins"
                  required
                  onChange={updateAssessmentTotalDuration}
                  autoComplete="on"
                />
              </div>
              <div className="mt-2 mx-3">
                <select
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  name="option"
                  value={assessmentOnboard.testCategory}
                  onChange={(event) => updateTestCategory(event)}
                >
                  <option
                    className="w-full py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    value=""
                  >
                    Assessment Category
                  </option>
                  <option value="ds-programming">ds-programming</option>
                  <option value="backend">backend</option>
                  <option value="frontend">frontend</option>
                  <option value="fullstack">fullstack</option>
                  <option value="devops">devops</option>
                  <option value="testing">testing</option>
                  <option value="flex-plm">flex-plm</option>
                  <option value="soft-skills">soft-skills</option>
                  <option value="others">others</option>
                  <option value="policy">policy</option>
                  <option value="internal">internal</option>
                </select>
              </div>

              <div className="mt-2 mx-3">
                <select
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  name="option"
                  value={assessmentOnboard.testType}
                  onChange={(event) => updateTestType(event)}
                >
                  <option
                    className="w-full py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    value=""
                  >
                    Assessment Type
                  </option>
                  <option value="technical">technical</option>
                  <option value="non-technical">non-technical</option>
                  <option value="communication">communication</option>
                  <option value="others">others</option>
                </select>
              </div>
              <div className="mt-2 mx-3">
                <select
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  name="option"
                  value={assessmentOnboard.fileType}
                  onChange={(event) => updateFileType(event)}
                >
                  <option
                    className="w-full py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    value=""
                  >
                    File Type
                  </option>
                  <option value="txt">MCQ</option>
                  <option value="excel">Text</option>
                </select>
              </div>

              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="totalScore">
                  {" "}
                  Assessment total score{" "}
                </label>
                <input
                  type="text"
                  id="totalScore"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Total score divisible by no of question."
                  required
                  onChange={updateAssessmentTotalScore}
                  autoComplete="on"
                />
              </div>

              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="minimumPass">
                  {" "}
                  Minimum pass score{" "}
                </label>
                <input
                  type="text"
                  id="minimumPass"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Minimum pass criteria score."
                  required
                  onChange={updateAssessmentMinimumScoreToPass}
                  autoComplete="on"
                />
              </div>

              <div className="mt-2 mx-3">
                <label className="text-gray-500" for="qaFile">
                  {" "}
                  Assessment QA file{" "}
                </label>
                <div className="w-full py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500">
                  <input
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    className="text-gray-900"
                    label={fileName || "choose an image file"}
                    onChange={handleFileChange}
                    invalid={invalidFile}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>  
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: '#FF4136',
              color: '#fff',
            },
          },

          success: {
            duration: 6000,
            style: {
              background: '#008000',
              color: '#fff',
            },
          },
        }}
      />
    </div>
  );
};

export default Onboard;
