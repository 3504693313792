import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import TrendingVideos from "./TrendingVideos";
import { NavLink } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import {
  adminLinks,
  clientLinks,
  financierLinks,
  hrLinks,
  userLinks,
} from "../data/staticData";
import { Dropdown } from "reactstrap";
import { AiFillCaretDown } from "react-icons/ai";
import UserLinksComponent from "./UserLinksComponent";
import { FINANCIER, ADMIN, HR, CLIENT } from "../config/Config";
import MenuItems from "./MenuItems";

const NavLinks = ({ actionClick }) => {
  const activeMenu = useContextSelector(
    StateContext,
    (state) => state.activeMenu
  );
  const setActiveMenu = useContextSelector(
    StateContext,
    (state) => state.setActiveMenu
  );
  const contentEndpoints = useContextSelector(
    StateContext,
    (state) => state.contentEndpoints
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const role = useContextSelector(StateContext, (state) => state.role);

  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [learningData, setLearningData] = useState(null); // State to store learning data
  const [myProfileOpen, setMyProfileOpen] = useState(false);
  const handleCloseSideBar = () => {
    // if (activeMenu !== undefined && screenSize <= 900) {
    setActiveMenu(false);
    window.scrollTo({ top: 0, behavior: "instant" });
    //}
  };
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2";

  const handleActiveMenu = () => {
    //setActiveMenu(!activeMenu);
    //actionClick();
    setOpen(false);
  };

  return (
    <>
      <div className="md:flex">
        {links?.map((link) => (
          <div key={link.name}>
            <div className="px-3 text-left md:cursor-pointer group">
              <h1
                className="py-7 flex justify-between md:text-dark text-lg items-center md:pr-0 pr-5 group"
                onClick={() => {
                  heading !== link.name
                    ? setHeading(link.name)
                    : setHeading("");
                  setSubHeading("");
                  setOpen(!open);
                }}
              >
                {link.name}
                <span className="text-xl md:hidden inline">
                  <ion-icon
                    name={`${
                      heading === link.name ? "chevron-up" : "chevron-down"
                    }`}
                  ></ion-icon>
                </span>
                <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                  <ion-icon name="chevron-down"></ion-icon>
                </span>
              </h1>

              {link.name === "Joinus" && open ? (
                <div className="absolute top-25 right-0 px-10 py-5 overflow-y-auto w-full h-[545px] bg-white hidden group-hover:md:block hover:md:block">
                  <div className="flex">
                    {/* Left Side: Tech Pack */}
                    <div className="w-1/4">
                      <h1 className="sm:text-2xl text-xl font-semibold">
                        Tech Stacks
                      </h1>
                      <div className="flex gap-1">
                        {/* Split contentEndpoints into two columns */}
                        <div className="w-1/2">
                          {contentEndpoints
                            ?.slice(0, Math.ceil(contentEndpoints.length / 2))
                            .map((item) => (
                              <div
                                key={item.category}
                                className="flex items-center gap-5 pl-4 pt-3 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              >
                                <Link
                                  to={`/${`${item.category}-${item.contentStateList[0].endpoint}`}`}
                                  onClick={handleActiveMenu}
                                >
                                  <h4 className="capitalize  sm:text-lg font-semibold">
                                    {item.category}
                                  </h4>
                                </Link>
                              </div>
                            ))}
                        </div>

                        <div className="w-1/2">
                          {contentEndpoints
                            ?.slice(Math.ceil(contentEndpoints.length / 2))
                            .map((item) => (
                              <div
                                key={item.category}
                                className="flex items-center gap-5 pl-4 pt-3 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              >
                                <Link
                                  to={`/${`${item.category}-${item.contentStateList[0].endpoint}`}`}
                                  onClick={handleActiveMenu}
                                >
                                  <h4 className="capitalize sm:text-lg text-md font-semibold">
                                    {item.category}
                                  </h4>
                                </Link>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    {/* Right Side: Profile Details */}
                    {/* Right Side: Profile Details */}
                    <div className="w-3/4 border-l border-gray-300 pl-6">
                      {isLoggedIn ? (
                        <UserLinksComponent
                          userLinks={userLinks}
                          currentColor={currentColor}
                          activeLink="your-active-link-class"
                          normalLink="your-normal-link-class"
                          handleActive={handleActiveMenu}
                        />
                      ) : (
                        <div className="flex gap-5">
                          <div className="absolute top-25 right-0 px-10 py-5 bg-white hidden group-hover:md:block hover:md:block">
                            <div className="flex gap-10">
                              <div className="w-1/4">
                                <h1 className="sm:text-2xl text-xl font-semibold">
                                  Tech Stacks
                                </h1>
                                <div className="flex gap-1">
                                  {/* Split contentEndpoints into two columns */}
                                  <div className="w-1/2">
                                    {contentEndpoints
                                      ?.slice(
                                        0,
                                        Math.ceil(contentEndpoints.length / 2)
                                      )
                                      .map((item) => (
                                        <div
                                          key={item.category}
                                          className="flex items-center gap-5 pl-4 pt-3 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                        >
                                          <Link
                                            to={`/${`${item.category}-${item.contentStateList[0].endpoint}`}`}
                                            onClick={handleActiveMenu}
                                          >
                                            <h4 className="capitalize  sm:text-lg font-semibold">
                                              {item.category}
                                            </h4>
                                          </Link>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="w-1/2">
                                    {contentEndpoints
                                      ?.slice(
                                        Math.ceil(contentEndpoints.length / 2)
                                      )
                                      .map((item) => (
                                        <div
                                          key={item.category}
                                          className="flex items-center gap-5 pl-4 pt-3 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                        >
                                          <Link
                                            to={`/${`${item.category}-${item.contentStateList[0].endpoint}`}`}
                                            onClick={handleActiveMenu}
                                          >
                                            <h4 className="capitalize sm:text-lg text-md font-semibold">
                                              {item.category}
                                            </h4>
                                          </Link>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              {link.sublinks?.map((mysublinks) => (
                                <div
                                  className="flex relative"
                                  key={mysublinks.Head}
                                >
                                  <div className="flex flex-col gap-10 after:border-r-4 after:border-gray-500 after:absolute after:w-1 after:bottom-0 after:right-0 after:content-['']">
                                    <h1 className="text-2xl font-semibold">
                                      {mysublinks.Head}
                                      <span className="float-right mr-44">
                                        {mysublinks?.Head1}
                                      </span>
                                    </h1>
                                    <ul
                                      className="list-none flex gap-6 items-start pl-0"
                                      style={{ flexFlow: "wrap" }}
                                    >
                                      {mysublinks.sublink?.map((slink) => (
                                        <li className="h-auto" key={slink.name}>
                                          {slink.image && (
                                            <img
                                              src={slink.image}
                                              alt="image"
                                              className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md"
                                            />
                                          )}
                                          {slink.video && (
                                            <img
                                              src={slink.video}
                                              alt="image"
                                              className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md"
                                            />
                                          )}
                                          <Link
                                            to={slink.link}
                                            target={slink?.blank}
                                            rel={slink?.rel}
                                            className="hover:text-primary font-bold no-underline"
                                            onClick={() => {
                                              setOpen(false);
                                            }}
                                          >
                                            <div className="h-full flex-col flex justify-between">
                                              <div className="w-[284px] flex gap-1 flex-col">
                                                <span className="font-semibold text-lg text-[rgb(29, 32, 38)] mb-2">
                                                  {slink.name}
                                                </span>
                                                <p className="text-md text-[rgb(93, 100, 118)] font-normal mb-2">
                                                  {slink?.content}
                                                </p>
                                              </div>
                                            </div>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Conditional Rendering Based on Role */}
                      {role === "ADMIN" && (
                        <UserLinksComponent
                          userLinks={adminLinks}
                          currentColor={currentColor}
                          activeLink="your-active-link-class"
                          normalLink="your-normal-link-class"
                          handleActive={handleActiveMenu}
                        />
                      )}
                      {role === "HR" && (
                        <UserLinksComponent
                          userLinks={hrLinks}
                          currentColor={currentColor}
                          activeLink="your-active-link-class"
                          normalLink="your-normal-link-class"
                          handleActive={handleActiveMenu}
                        />
                      )}
                      {role === "FINANCIER" && (
                        <UserLinksComponent
                          userLinks={financierLinks}
                          currentColor={currentColor}
                          activeLink="your-active-link-class"
                          normalLink="your-normal-link-class"
                          handleActive={handleActiveMenu}
                        />
                      )}
                      {role === "CLIENT" && (
                        <UserLinksComponent
                          userLinks={clientLinks}
                          currentColor={currentColor}
                          activeLink="your-active-link-class"
                          normalLink="your-normal-link-class"
                          handleActive={handleActiveMenu}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                link.submenu &&
                open && (
                  <div>
                    <div className="absolute top-25 right-0 px-10 py-5 w-full h-[545px] overflow-y-auto bg-white hidden group-hover:md:block hover:md:block">
                      <div className="flex gap-10">
                        {link.sublinks?.map((mysublinks) => (
                          <div className="flex relative" key={mysublinks.Head}>
                            <div className="w-[600px] flex flex-col gap-10 after:border-r-4 after:border-gray-500 after:absolute after:w-1 after:h-[104%] after:bottom-0 after:right-0 after:content-['']">
                              <h1 className="text-2xl font-semibold">
                                {mysublinks.Head}{" "}
                                <span className="float-right mr-44">
                                  {mysublinks?.Head1}
                                </span>
                              </h1>
                              <ul
                                className="list-none flex gap-6 items-start pl-0"
                                style={{ flexFlow: "wrap" }}
                              >
                                {mysublinks.sublink?.map((slink) => (
                                  <li className="h-auto" key={slink.name}>
                                    {slink.image && (
                                      <img
                                        src={slink.image}
                                        alt="image"
                                        className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md"
                                      />
                                    )}
                                    {slink.video && (
                                      <img
                                        src={slink.video}
                                        alt="image"
                                        className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md"
                                      />
                                    )}
                                    <Link
                                      to={slink.link}
                                      target={slink?.blank}
                                      rel={slink?.rel}
                                      className="hover:text-primary font-bold no-underline"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <div className="h-full flex-col flex justify-between">
                                        <div className="w-[284px] flex gap-1 flex-col">
                                          <span className="font-semibold text-lg text-[rgb(29, 32, 38)] mb-2">
                                            {slink.name}
                                          </span>
                                          <p className="text-md text-[rgb(93, 100, 118)] font-normal mb-2">
                                            {slink?.content}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {/* Mobile menus */}
            <div
              className={`${heading === link.name ? "md:hidden" : "hidden"}`}
            >
              {isLoggedIn ? (
                <>
                  {link.name === "Joinus" ? (
                    <div className="md:hidden">
                      <button
                        className="py-4 pl-7 bg-[#f5f5f5] font-semibold flex justify-between items-center pr-5 w-full"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        Tech Stack
                        <span className="text-xl">
                          <ion-icon
                            name={`${isOpen ? "chevron-up" : "chevron-down"}`}
                          ></ion-icon>
                        </span>
                      </button>

                      {isOpen && (
                        <div className="pl-7">
                          {contentEndpoints.map((item) => (
                            <li className="py-3 pl-5" key={item.name}>
                              <Link
                                to={`/${item.category}-${item.contentStateList[0].endpoint}`}
                                onClick={actionClick}
                              >
                                {item.category}
                              </Link>
                              {/* <Link to={slink.link} target={slink?.blank} rel={slink?.rel} onClick={actionClick}>
                     {slink.name}
                   </Link> */}
                            </li>
                          ))}
                        </div>
                      )}
                      <MenuItems actionClick={actionClick} />
                    </div>
                  ) : (
                    <>
                      {link.sublinks?.map((slinks) => (
                        <div key={slinks.Head}>
                          <h1
                            onClick={() =>
                              subHeading !== slinks.Head
                                ? setSubHeading(slinks.Head)
                                : setSubHeading("")
                            }
                            className="py-4 pl-7 bg-[#f5f5f5] font-semibold md:pr-0 flex justify-between items-center pr-5"
                          >
                            {slinks.Head}
                            <span className="text-xl md:mt-1 md:ml-2 inline">
                              <ion-icon
                                name={`${
                                  subHeading === slinks.Head
                                    ? "chevron-up"
                                    : "chevron-down"
                                }`}
                              ></ion-icon>
                            </span>
                          </h1>
                          <div
                            className={`${
                              subHeading === slinks.Head
                                ? "md:hidden"
                                : "hidden"
                            }`}
                          >
                            {slinks.sublink?.map((slink) => (
                              <li className="py-3 pl-14" key={slink.name}>
                                <Link
                                  to={slink.link}
                                  target={slink?.blank}
                                  rel={slink?.rel}
                                  onClick={actionClick}
                                >
                                  {slink.name}
                                </Link>
                              </li>
                            ))}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {link.name === "Joinus" ? (
                    <div className="md:hidden">
                      <button
                        className="py-4 pl-7 bg-[#f5f5f5] font-semibold flex justify-between items-center pr-5 w-full"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        Tech Stack
                        <span className="text-xl">
                          <ion-icon
                            name={`${isOpen ? "chevron-up" : "chevron-down"}`}
                          ></ion-icon>
                        </span>
                      </button>

                      {isOpen && (
                        <div className="pl-7">
                          {contentEndpoints.map((item) => (
                            <li className="py-3 pl-5" key={item.name}>
                              <Link
                                to={`/${item.category}-${item.contentStateList[0].endpoint}`}
                                onClick={actionClick}
                              >
                                {item.category}
                              </Link>
                              {/* <Link to={slink.link} target={slink?.blank} rel={slink?.rel} onClick={actionClick}>
                     {slink.name}
                   </Link> */}
                            </li>
                          ))}
                        </div>
                      )}
                      {link.sublinks?.map((slinks) => (
                        <div key={slinks.Head}>
                          <h1
                            onClick={() =>
                              subHeading !== slinks.Head
                                ? setSubHeading(slinks.Head)
                                : setSubHeading("")
                            }
                            className="py-4 pl-7 bg-[#f5f5f5] font-semibold md:pr-0 flex justify-between items-center pr-5"
                          >
                            {slinks.Head}
                            <span className="text-xl md:mt-1 md:ml-2 inline">
                              <ion-icon
                                name={`${
                                  subHeading === slinks.Head
                                    ? "chevron-up"
                                    : "chevron-down"
                                }`}
                              ></ion-icon>
                            </span>
                          </h1>
                          <div
                            className={`${
                              subHeading === slinks.Head
                                ? "md:hidden"
                                : "hidden"
                            }`}
                          >
                            {slinks.sublink?.map((slink) => (
                              <li className="py-3 pl-14" key={slink.name}>
                                <Link
                                  to={slink.link}
                                  target={slink?.blank}
                                  rel={slink?.rel}
                                  onClick={actionClick}
                                >
                                  {slink.name}
                                </Link>
                              </li>
                            ))}
                          </div>
                        </div>
                      ))}
                      {/* <MenuItems /> */}
                    </div>
                  ) : (
                    <>
                      {link.sublinks?.map((slinks) => (
                        <div key={slinks.Head}>
                          <h1
                            onClick={() =>
                              subHeading !== slinks.Head
                                ? setSubHeading(slinks.Head)
                                : setSubHeading("")
                            }
                            className="py-4 pl-7 bg-[#f5f5f5] font-semibold md:pr-0 flex justify-between items-center pr-5"
                          >
                            {slinks.Head}
                            <span className="text-xl md:mt-1 md:ml-2 inline">
                              <ion-icon
                                name={`${
                                  subHeading === slinks.Head
                                    ? "chevron-up"
                                    : "chevron-down"
                                }`}
                              ></ion-icon>
                            </span>
                          </h1>
                          <div
                            className={`${
                              subHeading === slinks.Head
                                ? "md:hidden"
                                : "hidden"
                            }`}
                          >
                            {slinks.sublink?.map((slink) => (
                              <li className="py-3 pl-14" key={slink.name}>
                                <Link
                                  to={slink.link}
                                  target={slink?.blank}
                                  rel={slink?.rel}
                                  onClick={actionClick}
                                >
                                  {slink.name}
                                </Link>
                              </li>
                            ))}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}

              {/* Mobile Menu - Visible on small screens */}
              {/* <div className="md:hidden">
        <button
          className="py-4 pl-7 bg-[#f5f5f5] font-semibold flex justify-between items-center pr-5 w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          Tech Stack
          <span className="text-xl">
            <ion-icon name={`${isOpen ? "chevron-up" : "chevron-down"}`}></ion-icon>
          </span>
        </button>

        {isOpen && (
          <div className="pl-7">
            {contentEndpoints.map((item) => (
              <li className="py-3 pl-5" key={item.name}>
                <Link to={`/${item.category}-${item.contentStateList[0].endpoint}`} onClick={actionClick}>
                         {item.category}
                        </Link>
               
              </li>
            ))}
          </div>
        )}
      </div> */}
              {/* sublinks */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NavLinks;
