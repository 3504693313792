import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { createManualSubscription } from "../../../../../api/FinanceApi";
import toast, { Toaster } from "react-hot-toast";

const ManualSubscription = () => {
  const [subscriptionRequest, setSubscriptionRequest] = useState({
    userName: "",
    subscriptionFor: "",
    subscriptionType: "",
    subscriptionCost: "",
    totalBillingHours: "",
    totalBillingDay: "",
    paymentBillingFrom: "",
    paymentBillingTo: "",
    paymentId: "",
    techStack: "",
    subscriptionId: "NEW",
    employeeEmailIdList: [],
    
  });
  const [emailIdListForm, setEmailIdListForm] = useState({
    emailIdList: [{
      employeeId: '',
    } 
    ],  
  });
  const [showLoading, setShowLoading] = useState(false);

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  useEffect(() => {
    document.title = "Manual Payment for existing subscription";
  }, []);
  
  const handleInfoAddClick = () => {
    setEmailIdListForm({
      ...emailIdListForm,
      emailIdList: [
        ...emailIdListForm.emailIdList,
        {
         employeeId: "",
        },
      ],
    });
  };
  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailIdListForm.emailIdList];
    list[index][name] = value;
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };
  const handleInfoRemoveClick = (index) => {
    const list = [...emailIdListForm.emailIdList];
    list.splice(index, 1);
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };

  const updateUserName = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      userName: event.target.value,
    });
  };

  

  const updateSubscriptionId = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionId: event.target.value,
    });
  };
  const updatePaymentId = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentId: event.target.value,
    });
  };
  const updateTechStack = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      techStack: event.target.value,
    });
  };
  const updateSubscriptionFor = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionFor: event.target.value,
    });
  };
  const updateSubscriptionType = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionType: event.target.value,
    });
  };
  const updateSubscriptionCost = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionCost: event.target.value,
    });
  };
  const updatePaymentBillingFrom = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentBillingFrom: event.target.value,
    });
  };
  const updatePaymentBillingTo = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentBillingTo: event.target.value,
    });
  };
  const updatetotalBillingDay = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      totalBillingDay: event.target.value,
    });
  };
  const updatetotalBillingHours = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      totalBillingHours: event.target.value,
    });
  };
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
    
  const handleManualPayment = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const employeeEmailIdList = [];
    for (let i=0; i<emailIdListForm.emailIdList.length; i++) {
     // alert("inside for"+JSON.stringify(emailIdListForm.emailIdList[i].employeeId));
      employeeEmailIdList.push(emailIdListForm.emailIdList[i].employeeId)
    }
    //alert("EmailID"+JSON.stringify(employeeEmailIdList))
    let req = subscriptionRequest;
    req.employeeEmailIdList = employeeEmailIdList;
    setSubscriptionRequest(req);
    createManualSubscription(subscriptionRequest)
      .then((res) => {
        //alert("suucesss"+JSON.stringify(res.data.message));
        toast.success(res.data.message);

        setSubscriptionRequest({ ...subscriptionRequest, userName: "" });
        setSubscriptionRequest({ ...subscriptionRequest, subscriberName: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionId: "NEW",
        });
        setSubscriptionRequest({ ...subscriptionRequest, paymentId: "" });
        setSubscriptionRequest({ ...subscriptionRequest, techStack: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionFor: null,
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionType: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionCost: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          paymentBillingFrom: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          paymentBillingTo: "",
        });
        setSubscriptionRequest({ ...subscriptionRequest, totalBillingDay: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          totalBillingHours: "",
        });
        setShowLoading(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };
  const { emailIdList } = emailIdListForm;
  
  return (
    <>
      <br></br>

      <div className="w-full md:w-1/2 mx-auto p-3">
        <div>
          <h2 className="md:text-xl sm:text-lg p-3 font-semibold uppercase mb-3">
            Add / Edit invoice for existing subscription{" "}
          </h2>
          <hr />
        </div>

        <div>
          {showLoading ? (
            <div className="align-content-center text-center">
              <h3>additional payment creation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={handleManualPayment}>
              <div className="grid md:grid-cols-2">
                <div className="mt-3 mx-3">
                  <label className="text-gray-500" for="userName">
                    User Name/Email
                  </label>
                  <input
                    type="text"
                    id="userName"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="User Name"
                    required
                    value={subscriptionRequest.userName}
                    onChange={updateUserName}
                    autoComplete="on"
                  />
                </div>

               
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="subscriptionId">
                    Subscription Id
                  </label>
                  <input
                    type="text"
                    id="subscriptionId"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="keep blank if new subscription"
                    required
                    value={subscriptionRequest.subscriptionId}
                    onChange={updateSubscriptionId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="paymentId">
                    Payment Id
                  </label>
                  <input
                    type="text"
                    id="paymentId"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscriptionRequest.paymentId}
                    onChange={updatePaymentId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="techStack">
                    Project
                  </label>
                  <input
                    type="text"
                    id="techStack"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscriptionRequest.techStack}
                    onChange={updateTechStack}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="subscriptionFor">
                    Subscription For
                  </label>
                  <input
                    type="text"
                    id="subscriptionFor"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscriptionRequest.subscriptionFor}
                    onChange={updateSubscriptionFor}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="subscriptionType">
                    Subscription Type
                  </label>
                  <select
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    value={subscriptionRequest.subscriptionType}
                    onChange={(event) => updateSubscriptionType(event)}
                  >
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">One Time</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="subscriptionCost">
                    Subscription Cost
                  </label>
                  <input
                    type="text"
                    id="subscriptionCost"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Subscription Cost"
                    required
                    value={subscriptionRequest.subscriptionCost}
                    onChange={updateSubscriptionCost}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="billingFrom">
                    Payment Billing From
                  </label>
                  <input
                    type="date"
                    id="billingFrom"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing From"
                    required
                    value={subscriptionRequest.paymentBillingFrom}
                    onChange={updatePaymentBillingFrom}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="billingTo">
                    Payment Billing To
                  </label>
                  <input
                    type="date"
                    id="billingTo"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing To"
                    required
                    value={subscriptionRequest.paymentBillingTo}
                    onChange={updatePaymentBillingTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="totalBillingDay">
                    Total no of billable days
                  </label>
                  <input
                    type="text"
                    id="totalBillingDay"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscriptionRequest.totalBillingDay}
                    onChange={updatetotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="text-gray-500" for="totalBillingHours">
                    Total no of billing hours
                  </label>
                  <input
                    type="text"
                    id="totalBillingHours"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Total Billing Hours"
                    required
                    value={subscriptionRequest.totalBillingHours}
                    onChange={updatetotalBillingHours}
                    autoComplete="on"
                  />
                </div>
                {emailIdList.map((x, i) => {
                  return(
                    <div className="mt-2 mx-3">
                    <label htmlFor="employeeID" className="mt-2">
                      Employee Id
                    </label>
                    <input
                      type="text"
                      className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      id="employeeId"
                      name="employeeId"
                      placeholder="Employee ID"
                      required
                      autoComplete="on"
                      value={x.employeeId}
                      onChange={(e) => handleContentInfo(e, i)}
                    />
                    {emailIdList.length !== 1 && (
                      <button
                        className="mt-5 mb-5 w-50 rounded px-7 pb-2.5 pt-3 mr-10 text-sm text-white"
                        style={{ backgroundColor: currentColor }}
                        onClick={handleInfoRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                    {emailIdList.length - 1 === i && (
                      <button
                        className="mt-5 w-50 rounded px-7 pb-2.5 pt-3 text-sm text-white"
                        style={{ backgroundColor: currentColor }}
                        onClick={handleInfoAddClick}
                      >
                        Add
                      </button>
                    )}
                  </div>

                  )}
                )}
                 
                
               
                <br />
                
              </div>
              <button
                  className="w-50 md:float-right sm:mx-auto rounded-lg px-5 mx-3 pb-2.5 pt-3 text-sm text-white uppercase"
                  style={{ backgroundColor: currentColor }}
                >
                  <span>Send</span>
                </button>
            </form>
          )}
        </div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: "#FF4136",
                color: "#fff",
              },
            },

            success: {
              duration: 6000,
              style: {
                background: "#008000",
                color: "#fff",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default ManualSubscription;
