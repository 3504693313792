import React, { useState, useEffect } from "react";
import { StateContext } from "../../../../../contexts/StateContext";
import { documentGeneration } from "../../../../../api/AdminApi";
import SalaryStatement from "./SalaryStatement";
import { useContextSelector } from "use-context-selector";
import ExperienceLetter from "./ExperienceLetter";
import {
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

const OfferLetter = () => {
  // State variables
  const [offerLetterRequest, setOfferLetterRequest] = useState({
    employeeFirstName: "",
    employeeLastName: "",
    designation: "",
    offeredCTC: "",
    annualIncentive: "",
    experience: "",
    joiningDate: "",
    emailId: ""
  });
  const [showLoading, setShowLoading] = useState(false);
  const [offerType, setOfferType] = useState("download");
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  // UseEffect to update document title
  useEffect(() => {
    document.title = "OfferLetter Generation";
  }, []);

  // Event handlers to update form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOfferLetterRequest({ ...offerLetterRequest, [name]: value });
  };
  const updateOfferType = (event) => {
    setOfferType(event.target.value);
    
    
  };
  // Submit form
  const docGeneration = (event) => {
    event.preventDefault();
    //alert("data" + JSON.stringify(offerLetterRequest));
    setShowLoading(true);
    documentGeneration(offerLetterRequest,offerType)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = offerLetterRequest.employeeFirstName + ".pdf";

        link.click(); /*

        alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        alert("url"+url);
        const link = document.createElement('a');
        alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
*/
        toast.success("OfferLetter generated successfully !");
        //toast.success("success")

        setShowLoading(false);
        setOfferLetterRequest("");
        //  this.props.history.push("/hr/dashboard/letterHead");
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
          //toast.error("error")
        } else {
          toast.error(error.message);
          //toast.error("error")
        }
        setShowLoading(false);
      });
  };
  

  return (
    <div className="container mx-auto p-4">
      <div className="grid md:grid-cols-3 gap-4">
        <div className="p-6 rounded bg-[#E6F6FA]">
          {showLoading ? (
            <div className="flex items-center justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Select offer type {" "}
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateOfferType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value="empty"
                    ></option>
                    <option value="newOffer">newOffer</option>
                    <option value="download">download</option>
                    
                  </select>
                  {offerType==="download" ? (
            <form onSubmit={docGeneration}>
              <div className="card card-signin my-5">
                <div className="card-body">
                  <h3 className="text-3xl font-bold mb-4">
                    {" "}
                    Download Offer Letter
                  </h3>
                </div>
                <CardContent>
              
                  <div className="form-group">
                    <TextField
                      fullWidth
                      type="email"
                      label="Email ID"
                      variant="outlined"
                      name="emailId"
                      value={offerLetterRequest.emailId}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <br></br>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: currentColor, color: "#fff" }}
                  >
                    SUBMIT
                  </Button>
                </CardContent>
              </div>
            </form>):(<form onSubmit={docGeneration}>
              <div className="card card-signin my-5">
                <div className="card-body">
                  <h3 className="text-3xl font-bold mb-4">
                    {" "}
                    Generate Offer Letter
                  </h3>
                </div>
                <CardContent>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Employee First Name"
                      variant="outlined"
                      name="employeeFirstName"
                      value={offerLetterRequest.employeeFirstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Employee Last Name"
                      variant="outlined"
                      name="employeeLastName"
                      value={offerLetterRequest.employeeLastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Designation"
                      variant="outlined"
                      name="designation"
                      value={offerLetterRequest.designation}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="fixed CTC"
                      variant="outlined"
                      name="offeredCTC"
                      value={offerLetterRequest.offeredCTC}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Annual Incentive"
                      variant="outlined"
                      name="annualIncentive"
                      value={offerLetterRequest.annualIncentive}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Experience when joining"
                      variant="outlined"
                      name="experience"
                      value={offerLetterRequest.experience}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      type="date"
                      label="Joining Date"
                      variant="outlined"
                      name="joiningDate"
                      value={offerLetterRequest.joiningDate}
                      onChange={handleInputChange}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="YYYY-MM-DD"
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      fullWidth
                      type="email"
                      label="Email ID"
                      variant="outlined"
                      name="emailId"
                      value={offerLetterRequest.emailId}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <br></br>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: currentColor, color: "#fff" }}
                  >
                    SUBMIT
                  </Button>
                </CardContent>
              </div>
            </form>)}
                </div>
                
             
          )}
        </div>
        <div className="p-6 rounded bg-[#E6F6FA]">
          <SalaryStatement />
        </div>
        <div className="p-6 rounded bg-[#E6F6FA]">
          <ExperienceLetter />
        </div>
      </div>

      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="mt-14"
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default OfferLetter;
