const TrainingForExperiencedMainEn={
    heading:"Empower Your Career with Yaazhtech's Training for Experienced Professionals",
    headingExplain1:"Are you a seasoned professional looking to expand your skill set, explore new career paths, or simply stay ahead of the curve? Yaazhtech's innovative Training for Experienced Professionals program is designed to help you achieve just that!",
    headingExplain2:"In today's dynamic job market, cross-functional skills and employee upskilling are more crucial than ever. Our program equips you with the knowledge and tools to excel in a cross-skilling environment, allowing you to:",
    
    broaden:"Learn new technologies, methodologies, and industry best practices relevant to your field and beyond.",
    increase:"Enhance your resume and stand out from the competition with in-demand skills.",
    drive:"Explore new career opportunities within your current domain or transition smoothly into a different field.",

    applyButton:"Apply Here",
    
    program:"Our Program Structure: ",
    programExplain:"The Yaazhtech Training for Experienced Professionals program offers a structured yet flexible learning approach:",
    self:"Access a comprehensive library of online courses, tutorials, and resources that you can explore at your own convenience.",
    mentor:"Receive support and personalized advice from our experienced instructors throughout your learning journey.",
    real:"Apply your newfound knowledge through practical exercises and projects designed to simulate real-world scenarios.",
    
    benefits:"Benefits of Our Program",
    upskilling:"Master the latest industry trends and technologies to stay relevant and competitive.",
    cross:"Explore new areas of expertise and broaden your professional horizons.",
    career:"Gain the skills and confidence needed to pursue leadership roles or transition into different fields.",
    
    who:"Who Should Apply?",
    explain:"This program is ideal for experienced professionals who are:",
    o1:"Looking to upskill in their current domain",
    o2:"Interested in cross-skilling to explore new career paths.",
    o3:"Seeking to enhance their employability and marketability.", 
    
    take:"Take Charge of Your Future Today!",
    takeExplain:"Ready to unlock your full potential and propel your career forward? Enroll in Yaazhtech's Training for Experienced Professionals program now!",
    
    haveQuestion:"Have Questions?",
    contact:"We'll help you identify the right learning path to take your skills and career to the next level.",
    
    }
        export {TrainingForExperiencedMainEn};