import React from 'react';
import Button from '../../../../components/StaticButton/Button';

const Opportunities = () => {
  return (
    <section id="opportunities" className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-gray-800">Explore Career Opportunities</h2>
        <p className="mt-4 text-lg text-gray-600">Discover the roles where you can make a difference.</p>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Add job opportunity cards here */}
          <div className="bg-gray-100 shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Training</h3>
            <p className="mt-4 text-gray-600 mb-2">Location: Remote</p>
            <Button text="Apply Now" navigation="/trainingAndHiring"/>
      </div>
          <div className="bg-gray-100 shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Direct Hire</h3>
            <p className="mt-4 text-gray-600 mb-2">Location: Remote</p>
            <Button text="Apply Now" navigation="/directHiringMain"/>
            </div>
          <div className="bg-gray-100 shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Direct Intern</h3>
            <p className="mt-4 text-gray-600 mb-2">Location: Remote</p>
            <Button text="Apply Now" navigation="/directInternMain"/>
          </div>
          <div className="bg-gray-100 shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Training for Experienced</h3>
            <p className="mt-4 text-gray-600 mb-2 ">Location: Remote</p>
            <Button text="Apply Now" navigation="/trainingForExperiencedMain"/>
             </div>
          {/* More cards */}
        </div>
      </div>
    </section>
  );
};

export default Opportunities;
