
import React, { useEffect, useState } from "react";
import { profileData } from "../../../../../../api/commonApi";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../contexts/StateContext';
import toast, { Toaster } from "react-hot-toast"; 

const ProfileDataSettings = ({ history }) => {

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const [profileState, setProfileState] = useState({
    profileOverallSummary: "",
    experience: "",
    experienceData: [
      {
        companyName: "",
        startDate: "",
        endDate: "",
        project: "",
        rolesAndResponsibility: "",
        techStack: "",
        designation: "",
        teamSize: "",
        isCurrentExperience: "",
        uan:"",
        pfNo:"",
      },
    ],
    preferredLocation: "anywhere-india",
    additionalComments: "",
    educationData: [
      {
        graduation: "",
        institutionName: "",
        placeOfInstitution: "",
        graduationStartDate: "",
        graduationEndDate: "",
        specialization: "",
        percentage: "",
      },
    ],
    achievementList: [{}],
    certificationList: [{}],
    showLoading: false,
  });

  const [showLoading, setShowLoading] = useState(false);
  
  const {
    
    achievementList,
    certificationList

    
  } = profileState;

  useEffect(() => {
    document.title = "My account profile data settings";
  }, []);

  const updateProfileSummary = (event) => {
    setProfileState((profileState) => ({
      ...profileState,
      profileOverallSummary: event.target.value,
    }));
  };

  const updateExperience = (event) => {
    setProfileState((profileState) => ({
      ...profileState,
      experience: event.target.value,
    }));
  };

  const updateAdditionalComments = (event) => {
    setProfileState((profileState) => ({
      ...profileState,
      additionalComments: event.target.value,
    }));
  };

  const updatePreferred = (event) => {
    setProfileState((profileState) => ({
      ...profileState,
      preferredLocation: event.target.value,
    }));
  };


  const handleOnboardProfile = (event) => {
    event.preventDefault();
    setProfileState((profileState) => ({
      ...profileState,
      showLoading: true,
    }));

    const profile = profileState;
    
    profileData(profile)
      .then((response) => {
        const data = response.data;
        toast.success(data.message);
        toast.success("success");

        setProfileState({ showLoading: false });

        
        setProfileState((profileState) => ({
          ...profileState,
          profileOverallSummary: "",
          experience: "",
          experienceData: [
            {
              companyName: "",
              startDate: "",
              endDate: "",
              project: "",
              rolesAndResponsibility: "",
              techStack: "",
              designation: "",
              teamSize: "",
              isCurrentExperience: "",
              uan:"",
              pfNo:"",
            },
          ],
          educationPersuing: "",
          educationData: [
            {
              graduation: "",
              institutionName: "",
              placeOfInstitution: "",
              graduationStartDate: "",
              graduationEndDate: "",
              specialization: "",
              percentage: "",
            },
          ],
          achievementList: [{}],
          certificationList: [{}],
        }));
        //history.push("/me/settings/profile");
      })
      .catch((error) => {
        
        toast.error(error);
        setProfileState({ showLoading: false });
      });
  }

  const handleExperienceAddClick = () => {
    setProfileState((profileState) => ({
      ...profileState,
      experienceData: [
        ...profileState.experienceData,
        {
          companyName: "",
          startDate: "",
          endDate: "",
          project: "",
          rolesAndResponsibility: "",
          techStack: "",
          designation: "",
          teamSize: "",
          isCurrentExperience: "",
          uan:"",
        pfNo:"",
          
        },
      ],
    }));
  }

  const handleExperienceRemoveClick = (index) => {

    const removeData = [...profileState.experienceData];
    removeData.splice(index, 1);
    setProfileState((profileState) => ({
      ...profileState,
      experienceData: removeData,
    }));
  }

  const handleEducationAddClick = () => {
    setProfileState((profileState) => ({
      ...profileState,
      educationData: [
        ...profileState.educationData,
        {
        graduation: "",
        institutionName: "",
        placeOfInstitution: "",
        graduationStartDate: "",
        graduationEndDate: "",
        specialization: "",
        percentage: "",
         
        },
      ],
    }));
  }

  const handleEducationRemoveClick = (index) => {

    const removeData = [...profileState.educationData];
    removeData.splice(index, 1);
    setProfileState((profileState) => ({
      ...profileState,
      educationData: removeData,
    }));
  }
  

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...profileState.experienceData];
    list[index][name] = value;
    setProfileState((prevState) => ({
      ...prevState,
      experienceData: list,
    }));
  };

  const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...profileState.educationData];
    list[index][name] = value;
    setProfileState((prevState) => ({
      ...prevState,
      educationData: list,
    }));
  };

  

  const handleAchievementList = (e, index) => {
    const { name, value } = e.target;
    const list = [...profileState.achievementList];
    list[index][name] = value;
    setProfileState((prevState) => ({
      ...prevState,
      achievementList: list,
    }));
  };

  
  
  const handleCertificationList = (e, index) => {
    const { name, value } = e.target;
    const list = [...profileState.certificationList];
    list[index][name] = value;
    setProfileState((prevState) => ({
      ...prevState,
      certificationList: list,
    }));
  };


  const handleAchievementAddClick = () => {
    setProfileState({ ...profileState, achievementList: [...achievementList, {}] });
  };
  
  const handleAchievementRemoveClick = (index) => {
    const list = [...achievementList];
    list.splice(index, 1);
    setProfileState({ ...profileState, achievementList: list });
  };

  const handleCertificationAddClick = () => {
    setProfileState({ ...profileState, certificationList: [...certificationList, {}] });
  };
  
  const handleCertificationRemoveClick = (index) => {
    const list = [...certificationList];
    list.splice(index, 1);
    setProfileState({ ...profileState, certificationList: list });
  };

  return (
    <div className=' w-full md:w-3/9 mx-auto p-3'>
      <h2 className="md:text-xl sm:text-lg p-3 font-semibold text-center uppercase mb-3">Profile Data Onboard</h2>
      
      <div>
        {profileState.showLoading ? (
          <div className="align-content-center text-center">
            <h3>Updating your profile data</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              refresh
            </i>
          </div>
        ) : (
          <form onSubmit={handleOnboardProfile}  >
             <div className="mt-2 mx-3">
                          
            <button className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="submit"
                      data-ripple-light="true"
                      style={{ color: currentColor }}>Submit</button>

            </div>
           <div className="grid md:grid-cols-3">
            <div className="grid md:grid-row">  
            <div className="mt-2 mx-3">
              <label htmlFor="profileOverallSummary" className="text-gray-500 ">Profile Summary</label>
              <textarea
                type="text"
                className="w-full py-2 px-6 clear_btn text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                name="profileOverallSummary"
                placeholder="Enter your Profile  Summary"
                required
                autoComplete="on"
                value={profileState.profileOverallSummary}
                onChange={updateProfileSummary}
              />
            </div>
             <div className="mt-2 mx-3">
              <label htmlFor="preferredLocation" className="text-gray-500 "> Preferred Location</label>
              <select
                className="w-full py-2 px-6 clear_btn text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                name="option"
                value={profileState.preferredLocation}
                onChange={(event) => updatePreferred(event)}
              >
                <option value="anywhere-india">anywhere-india</option>
                <option value="anywhere-south-india">anywhere-south-india</option>
                <option value="anywhere-north-india">anywhere-north-india</option>
                <option value="coimbatore-chennai-bangalore">coimbatore-chennai-bangalore</option>
                <option value="hybrid-anywhere-india">hybrid-anywhere-india</option>
                <option value="anywhere-worldwide">anywhere-worldwide</option>
                <option value="wfh-only">wfh-only</option>
              </select>
            </div>
            <div className="mt-2 mx-3">
              <label htmlFor="additionalComments" className="text-gray-500">Additional Comments</label>
              <input
                type="text"
                className="w-full py-2 px-6 clear_btn text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                name="additionalComments"
                placeholder="Please provide if any additional info share regarding job location."
                required
                autoComplete="on"
                onChange={updateAdditionalComments}
              />

            </div>
            <div className="mt-2 mx-3 ">
              <label htmlFor="achievements" className="text-gray-500 ">Achievements</label>
              {profileState.achievementList && profileState.achievementList.map((achievementList, index) => (

                <div>
                  <input
                    type="text"
                    className="w-full py-2 px-6 clear_btn text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="achievement"
                    placeholder="Achievement"
                    required
                    autoComplete="on"
                    value={achievementList.achievement}
                    onChange={(e) => handleAchievementList(e, index)}
                  />
                  {profileState.achievementList.length !== 1 && (
                    <button
                    className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                      onClick={handleAchievementRemoveClick}
                    >
                      Remove
                    </button>
                  )}
                  {profileState.achievementList.length - 1 === index && (
                    <button
                    className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                       onClick={handleAchievementAddClick}
                    >
                      Add
                    </button>
                  )}
                </div>

              ))}
            </div>
            <div className="mt-2 mx-3 ">
              <label htmlFor="certifications" className="text-gray-500 ">Certifications</label>
              {profileState.certificationList && profileState.certificationList.map((certificationList, index) => (           
                  <div>
                    <input
                      type="text"
                      className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      id="certification"
                      name="certification"
                      placeholder="Certification"
                      required
                      autoComplete="on"
                      value={certificationList.certification}                    
                      onChange={(e) => handleCertificationList(e, index)}
                    />
                    {profileState.certificationList.length !== 1 && (
                      <button
                      className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                      style={{ color: currentColor }}
                        onClick={handleCertificationRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                    {profileState.certificationList.length - 1 === index && (
                      <button
                      className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                      style={{ color: currentColor }}
                        onClick={handleCertificationAddClick}
                      >
                        Add
                      </button>
                    )}
                  </div>
              ))}
            </div>
              <div className="mt-2 mx-3">
              <label htmlFor="experience" className="text-gray-500 ">Total Experience</label>
              <input
                type="number"
                className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                name="experience"
                placeholder="Enter Your Month Experience"
                required
                autoComplete="on"
                onChange={updateExperience}
              />
            </div>
          </div>
          
          
<div className="grid md:grid-row"> 

            <div className="mt-2 mx-3">
              <label className="text-gray-500 ">Experience in Details</label>
              {profileState.experienceData && profileState.experienceData.map((experience, index) => (
                <div key={index}>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="companyName"
                    value={experience.companyName}
                    placeholder="Organization Name"
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  <label htmlFor="inputDate" className="text-gray-500 ">Start Date</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="startDate"
                    placeholder="startDate"
                    value={experience.startDate}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  <label htmlFor="inputDate" className="text-gray-500">End Date</label>
                  <input
                    type="text"
                    name="endDate"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="mm/dd/yyy or till date"
                    value={experience.endDate}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />

                  <label htmlFor="inputDate" className="text-gray-500 ">Project</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="project"
                    placeholder="Project"
                    value={experience.project}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />

                  <label htmlFor="inputDate" className="text-gray-500 ">Roles and Responsbility</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="rolesAndResponsibility"
                    placeholder="Roles and Responsbility"
                    value={experience.rolesAndResponsibility}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  <label htmlFor="inputDate" className="text-gray-500 ">Tech Stack</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="techStack"
                    placeholder="Tech Stack"
                    value={experience.techStack}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  <label htmlFor="inputDate" className="text-gray-500 ">Designation</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="designation"
                    placeholder="Designation"
                    value={experience.designation}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  <label htmlFor="inputDate" className="text-gray-500 ">Team Size</label>

                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Team Size"
                    name="teamSize"
                    value={experience.teamSize}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />
                  
                  <label htmlFor="inputDate" className="text-gray-500 ">Is Current Experience</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="isCurrentExperience"
                    placeholder="Is Current Experience"
                    value={experience.isCurrentExperience}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />

                   <label htmlFor="inputDate" className="text-gray-500 ">PF No</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="pfNo"
                    placeholder="Enter your PF Account NO"
                    value={experience.pfNo}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />

                  <label htmlFor="inputDate" className="text-gray-500 ">UAN</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="uan"
                    placeholder="Enter your UAN"
                    value={experience.uan}
                    onChange={(e) => handleExperienceChange(e, index)}
                  />

                 



                  {profileState.experienceData.length !== 1 && (
                    <button
                      type="button"
                      className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                      onClick={handleExperienceRemoveClick}

                    >
                      Remove
                    </button>
                   
                  )}
                  {profileState.experienceData.length - 1 === index && (
                    <button
                      type="button"
                      className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                      onClick={handleExperienceAddClick}
                    >
                      Add
                    </button>
                   
                  )}
                </div>
              ))}
            </div>
</div>
       <div className="grid md:grid-row">  
            <div className="mt-2 mx-3 ">
              <label htmlFor="educationData" className="text-gray-500 ">Education Details</label>
              {profileState.educationData && profileState.educationData.map((education, index) => (
                <div>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="graduation"
                    name="graduation"
                    placeholder="Graduation"
                    required
                    autoComplete="on"
                    value={education.graduation}
                    onChange={(e) => handleEducationChange(e, index)}
                  />
                  <label htmlFor="educationData" className="text-gray-500 ">Institution Name</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="institutionName"
                    name="institutionName"
                    placeholder="Institution Name"
                    required
                    autoComplete="on"
                    value={education.institutionName}
                    onChange={(e) => handleEducationChange(e, index)}
                  />

                <label htmlFor="educationData" className="text-gray-500">Place Of Institution</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="placeOfInstitution"
                    name="placeOfInstitution"
                    placeholder="Place Of Institution"
                    required
                    autoComplete="on"
                    value={education.placeOfInstitution}
                    onChange={(e) => handleEducationChange(e, index)}
                  />

                  <label htmlFor="educationData" className="text-gray-500 ">Specialization</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="specialization"
                    name="specialization"
                    placeholder="specialization"
                    required
                    autoComplete="on"
                    value={education.specialization}
                    onChange={(e) => handleEducationChange(e, index)}
                  />

                  <label htmlFor="educationData" className="text-gray-500 ">Percentage</label>
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="percentage"
                    name="percentage"
                    placeholder="Percentage"
                    required
                    autoComplete="on"
                    value={education.percentage}
                    onChange={(e) => handleEducationChange(e, index)}
                  />
                  <label htmlFor="graduationStartDate" className="text-gray-500 ">
                    Graduation Start date
                  </label>
                  <input
                    type="date"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="graduationStartDate"
                    name="graduationStartDate"
                    placeholder="Graduation Start date"
                    required
                    autoComplete="on"
                    value={education.graduationStartDate}
                    onChange={(e) => handleEducationChange(e, index)}
                  />{" "}
                  <label htmlFor="graduationStartDate" className="text-gray-500 ">
                    Graduation End date
                  </label>
                  <input
                    type="date"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="graduationEndDate"
                    name="graduationEndDate"
                    placeholder="Graduation End date"
                    required
                    autoComplete="on"
                    value={education.graduationEndDate}
                    onChange={(e) => handleEducationChange(e, index)}
                  />
                  {profileState.educationData.length !== 1 && (
                    <button
                      
                    className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                      onClick={handleEducationRemoveClick}
                    >
                      Remove
                    </button>
                  )}
                  {profileState.educationData.length - 1 === index && (
                    <button
                    className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    
                    style={{ color: currentColor }}
                      onClick={handleEducationAddClick}
                    >
                      Add
                    </button>
                  )}
                </div>

              ))}
            </div>
            </div>
           </div>
           
           
          </form>
        )}
      </div>

      <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />

    </div>
  );
};

export default ProfileDataSettings;
