import React from 'react';
import Card from './Card';
import img1 from '../../../assets/images/about/one.jpg'
import img2 from '../../../assets/images/about/two.jpg'
import img3 from '../../../assets/images/about/three.jpg'
import img4 from '../../../assets/images/about/four.jpg'
import img5 from '../../../assets/images/about/five.jpg'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector'
 
const About = () => {

    const currentColor = useContextSelector(StateContext, (state) => state.currentColor);

  const cardsData = [
    {
      imageUrl:img1,
      title: "2016-2022",
      title1: "Our Humble Beginning",
      description: "our company originated with young minds with premature baby steps into this software development world with the motive of providing world class software service from small scale to large scale industries with enhanced trainees.",
    },
    {
      imageUrl: img2,
      title: "JANUARY 2022",
      title1: "An Agency is Born",
      description: "Our company has changed to research and development industry with enough development tools to handle development of an application.",
    },
    {
      imageUrl: img3,
      title: "AUGUST 2022",
      title1: "Transition to Full Service",
      description: "Our development services started formally with internal application and grown our employee count to multiple of tens with successful implementations handled during the training sessions.",
    },
    {
      imageUrl: img4,
      title: "JUNE 2023",
      title1: "Phase Two Expansion",
      description: "We launched mobile friendly webapp to the market with the help of our enhanced development team and expanded our development to multiple categories namely, backend, frontend, fullstack and business process automation.",
    },
    {
      imageUrl: img5,
      title: "",
      title1: "",
      description: "",
    },
  ];
 
  return (
    

      <div className="min-h-screen  flex flex-col justify-center items-center">
    <h1 className="text-2xl flex justify-center font-bold mb-4"style={{ color: currentColor }}>ABOUT</h1>
      <h1 className="text-base text-center mb-12 font-semibold">All Roads Leads to Experience</h1>
      {cardsData.map((card, index) => (
          <div key={index} className="relative flex-auto">
            {index > 0 && <div className="line-path w-px h-32 mx-auto bg-black"></div>}
            <Card {...card} />
           </div>
        ))}
        
      </div>
    
  );
};
 
export default About;
