import React from 'react';
import { carrersHomeImg } from '../../assets/images';
import { Container } from '../../components/Container';
import Button from '../../components/StaticButton/Button';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';

const LifeAtYaazhtech = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
      );
  return (
    <Container>
      <div className="flex flex-col md:flex-row items-center justify-center p-8 bg-white">
        {/* Left Side - Image with Dual Border Effect */}
        <div className="md:w-1/2 w-full p-4 flex justify-center relative">
          {/* Colorful animated dual border */}
        
          <img
            src={carrersHomeImg}
            alt="Life at Yaazhtech"
            className="relative rounded-tr-[50px] rounded-bl-[50px] w-full max-w-md object-cover transform hover:scale-105 transition-transform duration-500"
          />
        </div>

        {/* Right Side - Content */}
        <div className="md:w-1/2 w-full p-4 flex flex-col justify-center items-start">
          <h3 className="text-2xl font-extrabold mb-4" style={{ color: currentColor }}>
            LIFE AT Yaazhtech
          </h3>
          <h1 className="text-5xl font-semibold text-gray-800 mb-4">
            Build your career with us
          </h1>
          <p className="text-gray-600 text-2xl leading-relaxed mb-8">
            Yaazhtech is 5,000+ employees across 13 global locations focused on one goal:
            to help more people find success. Come do your best work with us.
          </p>
          <Button text="Careers at Yaazhtech" navigation="/careers" />
        </div>
      </div>
    </Container>
  );
};

export default LifeAtYaazhtech;
