import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Group,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faExternalLinkAlt,
  faRecycle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { getInterns, getTrainee } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import ActionTrainee from "./ActionTrainee";
import { Link } from "react-router-dom";
import ActionViewClient from "../../../financier/dashboard/payment/ActionViewClient";
import { Payment, Subscriptions } from "@mui/icons-material";

function TraineeData() {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  //const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = ["Search", "SortAscending", "PdfExport"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [content, setContent] = useState([]);
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  
  useEffect(() => {
    document.title = "Employee Data";
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getTrainee(searchText)
      .then((res) => {
        const content = [];
        let user = {
          leaveStatus: "",
          name: "",
          totalLeaveTaken: "",
          totalEligibleLeaves: "",
          paidSoFar:"",
          pendingAmount: "",
          totalCourseFee:"",
          subscriptionList:{}
        };

        for (let i = 0; i < res.data.data.length; i++) {
          user = res.data.data[i];
          const sickLeavesTaken = user.sickLeavesTaken
            ? user.sickLeavesTaken
            : 0;
          const vacationLeavesTaken = user.vacationLeavesTaken
            ? user.vacationLeavesTaken
            : 0;
          const totalLeaveTaken = sickLeavesTaken + vacationLeavesTaken;
          const eligibleSickLeaves = user.eligibleSickLeaves
            ? user.eligibleSickLeaves
            : 0;
          const eligibleVacationLeaves = user.eligibleVacationLeaves
            ? user.eligibleVacationLeaves
            : 0;
          const totalEligibleLeaves =
            eligibleSickLeaves + eligibleVacationLeaves;
          const leaveStatus = totalEligibleLeaves - totalLeaveTaken;
          let totalCourseFee = 0;
          let paidSoFar = 0;
          if (user.subscriptionList && Array.isArray(user.subscriptionList)) {
            user.subscriptionList.forEach((subscription) => {
              if (subscription.paymentList && Array.isArray(subscription.paymentList)) {
                subscription.paymentList.forEach((payment) => {
                  if (payment.costIncludingGstPay) {
                    totalCourseFee += payment.costIncludingGstPay;
                  } 
                  if(payment.paidSoFar){
                    paidSoFar += payment.paidSoFar
                  }
                });
              }
            });
            
          }user.leaveStatus = leaveStatus;
          user.totalEligibleLeaves = totalEligibleLeaves;
          user.totalLeaveTaken = totalLeaveTaken;
          user.paidSoFar= paidSoFar;
             user.totalCourseFee = totalCourseFee;
             user.pendingAmount = totalCourseFee - paidSoFar;
          user.subscriptionList=user.subscriptionList;
          content.push(user);
        }
        content.sort((a, b) =>
          a.leaveStatus > b.leaveStatus
            ? 1
            : b.leaveStatus > a.leaveStatus
            ? -1
            : 0
        );
        setContent(content);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          toast.error("error");
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
          toast.error("error");
        }
        setLoading(false);
        console.log(error);
      });
  };

  const actionTemplate = (props) => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [role, setRole] = useState("");
    const [totalLeaves, setTotalLeaves] = useState("");
    const [timeSheet, setTimeSheet] = useState([]);
    const [leaveList, setLeaveList] = useState([]);

    useEffect(() => {
      showDetail();
    }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);
      setRole(props.role);
      setTotalLeaves(props.totalLeaves);
      setTimeSheet(props.timeSheetList);
      setLeaveList(props.leaveDataList);
    };
    return (
      <ActionTrainee
        name={userName}
        email={userEmail}
        role={role}
        totalLeaves={totalLeaves}
        timeSheet={timeSheet}
        leaveListData={leaveList}
      />
    );
  };
  const handleViewProfileClick = (props) => {
    return (
      <NavLink
        to={{
          pathname: "/viewProfileViaHr",
          profileProps: {
            email: props.email,
          },
        }}
        exact
      >
        <button type="button" className="btn btn-light">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
      </NavLink>
    );
  };
  const leaveStatusTemplate = (props) => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [leaveStatus, setLeaveStatus] = useState([]);
    useEffect(() => {
      showDetail();
    }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);
      setLeaveStatus(props.leaveStatus);
    };
    return (
      <>
        {leaveStatus < 0 ? (
          <span className="badge bg-deep-orange-800 p-1 text-white rounded-lg">
            Irregular
          </span>
        ) : (
          <span className="badge bg-green-700 p-1  text-white rounded-lg">
            Regular
          </span>
        )}
      </>
    );
  };
  const differnceTemplate = (props) => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [leaveStatus, setLeaveStatus] = useState([]);
    useEffect(() => {
      showDetail();
    }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);
      setLeaveStatus(props.leaveStatus);
    };

    return (
      <div>
        <span className="p-1">{leaveStatus}</span>
        {leaveStatus < 0 ? (
          <small className="text-muted">(₹ {leaveStatus * -500})</small>
        ) : (
          <small className="text-muted">(₹ 0)</small>
        )}
      </div>
    );
  };

  const actionTemplateForPaymentUpdate = (props) => {

        const subscriptionList = props.subscriptionList;
       //alert("sjfklsfjlsf"+JSON.stringify(props.subscriptionList));
        const constructPaymentDataList = [];
      
        const userName = props.email;
        if (
          subscriptionList !== undefined &&
          subscriptionList !== null &&
          subscriptionList.length > 0
        ) {
          for (let i = 0; i < subscriptionList.length; i++) {
            const payment = subscriptionList[i].paymentList; 
            if (
              payment !== undefined &&
              payment !== null &&
              payment.length > 0
            ) {
              for (let j = 0; j < payment.length; j++) {
                const paymentData = {
                  projectRequirement: "",
                  dueDate: "",
                  paymentBillingFrom: "",
                  paymentBillingTo: "",
                  totalBillingDay: "",
                  totalBillingHours: "",
                  costPerDay: "",
                  subscriptionType: "",
                  subscriptionFor: "",
                  costExcludingGstPay: "",
                  costIncludingGstPay: "",
                  costIncludingGstPayMinusTDS: "",
                  paymentStatus: "",
                  subscriptionId: "",
                  paymentId: "",
                  userName: "",
                  subscriptionId: "",
                  subscriptionName: "",
                  subscriptionCost: "",
                  invoiceDate: "",
                  gst: "",
                  costExGstExTds: "",
                  paidSoFar: "",
                  pendingAmount: "",
                  employeeEmailIdList: [],
                };
                paymentData.subscriptionId = subscriptionList[i].subscriptionId;
                paymentData.subscriptionCost =
                  subscriptionList[i].subscriptionCost;
                paymentData.employeeEmailIdList =
                  subscriptionList[i].employeeEmailIdList;
                paymentData.subscriptionFor =
                  subscriptionList[i].subscriptionFor;
                paymentData.subscriptionType =
                  subscriptionList[i].subscriptionType;
                paymentData.subscriptionName =
                  subscriptionList[i].subscriptionName;
                paymentData.subscriptionId = subscriptionList[i].subscriptionId;
                paymentData.projectRequirement =
                  subscriptionList[i].projectRequirement;
                paymentData.costPerDay = subscriptionList[i].subscriptionCost;
                const originalDueDate = new Date(payment[j].invoiceDate);
                const newDueDate = new Date(
                  originalDueDate.getTime() + 35 * 24 * 60 * 60 * 1000
                );
                paymentData.dueDate = newDueDate.toISOString();
                paymentData.paidSoFar = payment[j].paidSoFar;
                paymentData.pendingAmount = payment[j].pendingAmount;
                paymentData.invoiceDate = payment[j].invoiceDate;
                paymentData.paymentBillingFrom = payment[j].paymentBillingFrom;
                paymentData.paymentBillingTo = payment[j].paymentBillingTo;
                paymentData.costExcludingGstPay =
                  payment[j].costExcludingGstPay;
                paymentData.costIncludingGstPay =
                  payment[j].costIncludingGstPay;
                paymentData.gst = Math.round(
                  payment[j].costIncludingGstPay -
                    payment[j].costExcludingGstPay
                );
                paymentData.costIncludingGstPayMinusTDS = Math.round(
                  payment[j].costIncludingGstPay -
                    payment[j].costExcludingGstPay / 10
                );
                paymentData.costExGstExTds = Math.round(
                  paymentData.costIncludingGstPayMinusTDS - paymentData.gst
                );
                paymentData.paymentId = payment[j].paymentId;
                paymentData.totalBillingDay = payment[j].totalBillingDay;
                paymentData.totalBillingHours = payment[j].totalBillingHours;
                paymentData.paymentStatus = payment[j].paymentStatus;
                paymentData.userName = userName;
                constructPaymentDataList.push(paymentData);
              }
              constructPaymentDataList.sort((a, b) =>
                a.paymentStatus > b.paymentStatus
                  ? 1
                  : b.paymentStatus > a.paymentStatus
                  ? -1
                  : 0
              );
            } else {
              setLoading(false);
            }
          }
    
        } else {
         console.log("subscriptionList is undefined or null")
        }
      

    const paymentData = {
      projectRequirement: constructPaymentDataList[0].projectRequirement,
      dueDate: constructPaymentDataList[0].dueDate,
      paymentBillingFrom: constructPaymentDataList[0].paymentBillingFrom,
      paymentBillingTo: constructPaymentDataList[0].paymentBillingTo,
      totalBillingDay: constructPaymentDataList[0].totalBillingDay,
      totalBillingHours: constructPaymentDataList[0].totalBillingHours,
      costPerDay: constructPaymentDataList[0].subscriptionCost,
      subscriptionType: constructPaymentDataList[0].subscriptionType,
      subscriptionFor: constructPaymentDataList[0].subscriptionFor,
      costExcludingGstPay: constructPaymentDataList[0].costExcludingGstPay,
      costIncludingGstPay: constructPaymentDataList[0].costIncludingGstPay,
      costIncludingGstPayMinusTDS:
      constructPaymentDataList[0].costIncludingGstPay - constructPaymentDataList[0].costIncludingGstPay / 10,
      paymentStatus: constructPaymentDataList[0].paymentStatus,
      subscriptionId: constructPaymentDataList[0].subscriptionId,
      paymentId: constructPaymentDataList[0].paymentId,
      userName: constructPaymentDataList[0].userName,
      subscriptionId: constructPaymentDataList[0].subscriptionId,
      subscriptionName: constructPaymentDataList[0].subscriptionName,
      subscriptionCost: constructPaymentDataList[0].subscriptionCost,
      employeeEmailIdList: constructPaymentDataList[0].employeeEmailIdList,
    };

    return <ActionViewClient subscription={paymentData} />;
  };
  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl">Trainee Page</h2>
      <GridComponent
        dataSource={content}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
          {/*  <ColumnDirective field='action' headerText='Action' textAlign="Center" isPrimaryKey={true}  width='100' template={onboardTemplate} />
          <ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />
         */}
          <ColumnDirective
            field="name"
            headerText="Name"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="totalLeaveTaken"
            headerText="LeavesTaken"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="totalEligibleLeaves"
            headerText="EligibleLeaves"
            width="80"
            textAlign="Center"
          />
           <ColumnDirective
            field="totalCourseFee"
            headerText="CourseFees"
            width="80"
            textAlign="Center"
          />
           <ColumnDirective
            field="paidSoFar"
            headerText="paidSoFar"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="pendingAmount"
            headerText="pendingAmount"
            width="80"
            textAlign="Center"
          />
          <ColumnDirective
            field="action"
            headerText="Action"
            width="100"
            textAlign="Center"
            template={actionTemplate}
          />
           <ColumnDirective
            field="action"
            headerText="PaymentUpdate"
            width="100"
            textAlign="Center"
            template={actionTemplateForPaymentUpdate}
          />
          <ColumnDirective
            headerText="View Profile"
            width="100"
            textAlign="Center"
            template={(props) => (
              <Link
                to={{
                  pathname: "/viewProfileData",
                }}
                state={{
                  email: props.email,
                }}
                exact
              >
                <button
                  className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
                  type="button"
                >
                  {" "}
                  <span style={{ color: currentColor }}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </span>{" "}
                </button>
              </Link>
            )}
          />
          <ColumnDirective
            field="leaveStatus"
            headerText="Difference"
            width="100"
            textAlign="Center"
            template={differnceTemplate}
          />
          <ColumnDirective
            field="leaveStatus"
            headerText="Status"
            width="100"
            textAlign="Center"
            template={leaveStatusTemplate}
          />

          {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}

export default TraineeData;
