import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { Link, Navigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { ACCESS_TOKEN, CLIENT, CLIENT_TOKEN, COOKIE_PREF, EMPLOYEE, EMPLOYEE_TOKEN, FINANCIER, FINANCIER_TOKEN, HR, HR_TOKEN, USER } from "../../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import {
  login,
  requestResetPassword,
  saveCookie,
  disable2FA,
} from "../../../api/Api";


import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../components/StaticButton/Button";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Login = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const role = useContextSelector(StateContext, (state) => state.role);
 
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [loginRequest, setLoginRequest] = useState({
    usernameOrEmail: "",
    password: "",
    twoFACode: 0,
    rememberMe: false,
  });
  const [type, setType] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  
  
  useEffect(() => {
    document.title = "Sign in";
  }, []);

  const updateUsernameOrEmail = (event) => {
    setLoginRequest((prevRequest) => ({
      ...prevRequest,
      usernameOrEmail: event.target.value,
    }));
  };

  const updatePassword = (event) => {
    setLoginRequest((prevRequest) => ({
      ...prevRequest,
      password: event.target.value,
    }));
  };

  const updateRememberMe = () => {
    setLoginRequest((prevRequest) => ({
      ...prevRequest,
      rememberMe: !prevRequest.rememberMe,
    }));
  };

  const updateTwoFACode = (event) => {
    setLoginRequest((prevRequest) => ({
      ...prevRequest,
      twoFACode: parseInt(event.target.value, 10),
    }));
  };

  const cancelEnable2FA = () => {
    disable2FA();
  };

  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };

  const saveCookieData = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));

      saveCookie(localStorage.getItem(COOKIE_PREF), JSON.stringify(token.email))
        .then((res) => {
          toast.success(res.data.message);
          toast.success("success");
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error(
              "Your username or password is incorrect. Please try again !"
            );
            toast.error("error");
          } else {
            toast.error(error.message);
            toast.error("error");
          }
          setShowLoading(false);
        });
    }
  };

  const requestLogin = (event) => {
    event.preventDefault();
    setShowLoading(true);
    login(loginRequest)
      .then((res) => {
        localStorage.setItem(
          ACCESS_TOKEN,
          res.accessToken ? res.accessToken : null
        );
        if (
          res.message ===
          "Two-Factor authentication is enable for your account ! Please enter the code!!"
        ) {
          toast.success(
            "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application."
          );
          toast.success("success");
          setTwoFAEnabled(true);
          setShowLoading(false);
        } else {
          setIsLoggedIn(true);
          let token = res.accessToken;
          //alert("outside"+JSON.stringify(token));
          const base64Url = token.split(".")[1];
          //alert("baseData"+JSON.stringify(base64Url))
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          // alert("base"+JSON.stringify(base64));
          token = JSON.parse(window.atob(base64));
          //alert("token genra"+JSON.stringify(token));
          // console.log(exp, Math.floor(Date.now()/1000));
          if (token.exp <= Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ADMIN_TOKEN);
            localStorage.removeItem(HR_TOKEN);
            localStorage.removeItem(EMPLOYEE_TOKEN);
            localStorage.removeItem(CLIENT_TOKEN);
            localStorage.removeItem(FINANCIER_TOKEN);
            // showAlert('Your session has expired !', 'info');
            // this.props.history.push("/");
          
            isLoggedIn(false);
          }
          if (token.rol.length >= 1) {
           // alert("token role from login.js" + token.rol[0]);
            setRole(token.rol[0]);
          }
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or password is incorrect. Please try again !"
          );
          toast.error("error");
        } else if (
          toast.error ===
          "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application."
        ) {
          toast.error(
            "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application."
          );
          toast.error("error");
          setTwoFAEnabled(true);
        } else if (
          toast.error ===
          "Invalid 2FA authentication code ! Please try again later..."
        ) {
          toast.error(
            "Invalid 2FA authentication code ! Please try again later..."
          );
          toast.error("error");
        } else {
          toast.error(error.message);
          toast.error("error");
        }
        setShowLoading(false);
      });
  };

  const showForgotPasswordModalFunction = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  const forgotPasswordSend = (event) => {
    event.preventDefault();

    if (forgotPasswordEmail) {
      setShowLoading(true);
      requestResetPassword(forgotPasswordEmail)
        .then((res) => {
          toast.success(
            "Your request has been accepted! If this email address is correct, you will soon receive an email with a link to reset your password."
          );
          toast.success("success");
          setShowLoading(false);
          showForgotPasswordModalFunction();
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error(
              "Your username or password is incorrect. Please try again!"
            );
            toast.error("error");
          } else {
            toast.error("Sorry! Something went wrong. Please try again!");
            toast.error("error");
          }
          setShowLoading(false);
        });
    } else {
      toast.error("Please enter a valid email address.");
      toast.error("error");
    }
  };
  const showPassword = () => setType(!type);

  const token = localStorage.getItem(ACCESS_TOKEN);
  if (
    token !== "undefined" &&
    token !== undefined &&
    token !== null &&
    token !== "null" &&
    token !== "" &&
    isLoggedIn &&
    token !== "undefined" &&
    token !== undefined &&
    token !== null &&
    token !== "null" &&
    token !== ""
  ) {
    //toast.success("You are already logged in !");
    //alert("role==="+role)
   {/** if(token && role===HR){
      return <Navigate to="/interns" replace />;
    }   if(token && role===EMPLOYEE){
      return <Navigate to="/assessment" replace />;
    }   if(token && role===CLIENT){
      return <Navigate to="/invoiceAndPayments" replace />;
    }if(token  && role===FINANCIER){
      return <Navigate to="/clients" replace />;
    }else{
      return <Navigate to="/trainingInvoiceDetails" replace />;
    }*/} 
    return <Navigate to="/" replace />;
  } else {
    return (
      <>
        <div className="container mx-auto p-4">
          <div
            className={
              currentMode === "Dark"
                ? "max-w-md mx-auto p-8 rounded bg-[#f5f5f5]"
                : "max-w-md mx-auto p-8 rounded bg-[#f5f5f5]"
            }
          >
            {showLoading ? (
              // <div className="card card-signin my-5">
              //   <div className="card-body">
              //     <div className="align-content-center text-center">
              //       <h5>Signing in</h5>
              //       <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              //         refresh
              //       </i>
              //     </div>
              //   </div>
              // </div>
              <div className="flex justify-center items-center my-5">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-200 rounded-full animate-spin" style={{ borderTopColor: currentColor }}></div>
  </div>
            ) : (
              <div>
                {!twoFAEnabled ? (
                  <div>
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                      <form onSubmit={(e) => requestLogin(e)}>
                        <div className="card card-signin my-5 -mt-10">
                          <div className="card-body ml-5 mr-5 mt-5">
                            <h1 className="text-xl uppercase flex justify-center font-semibold mb-2">
                              Sign In
                            </h1>

                            <p className="text-base flex justify-center mb-2">
                              Welcome Back! Please Enter your details.
                            </p>

                            <br />

                            <div>
                              <label
                                htmlFor="inputEmail"
                                className="font-semibold"
                              >
                                Email address or username
                              </label>

                              <input
                                type="text"
                                id="inputEmail"
                                className="form-control w-full text-base py-2 my-4 bg-transparent
                                  text-black border-b border-black outline-none focus:outline-none"
                                placeholder="Email address or username"
                                required
                                onChange={updateUsernameOrEmail}
                                autoComplete="on"
                              />
                            </div>

                            <label
                              htmlFor="inputPassword"
                              className="font-semibold"
                            >
                              Password
                            </label>

                            <div className="flex items-center relative">
                              <input
                                type={type ? "text" : "password"}
                                id="inputPassword"
                                className="form-control text-base w-full py-2 my-4 bg-transparent
                              text-black border-b border-black outline-none focus:outline-none"
                                placeholder="Password"
                                required
                                autoComplete="on"
                                onChange={updatePassword}
                              />
                              <span
                                className="showpass absolute right-2 text-2xl cursor-pointer"
                                onClick={showPassword}
                              >
                                {type ? <AiFillEye /> : <AiFillEyeInvisible />}
                              </span>
                            </div>
                            <div className="w-full flex items-center justify-between">
                              <div className="w-full flex items-center">
                                {/* <input
                              type="checkbox"
                              className="custom-control-input"
                              id="rememberMe"
                              onChange={updateRememberMe}
                            /> */}
                                <div
                                  onClick={showForgotPasswordModalFunction}
                                  className="cursor-pointer"
                                >
                                  <Link to="/forgot">
                                    <p className="text-base font-semibold whitespace-nowrap cursor-pointer mb-2">
                                      Forgot password ?
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <Button text="Sign in" />
                           
                            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-[#ccd5ae] after:mt-0.5 after:flex-1 after:border-t after:border-[#ccd5ae]">
                              <p className="mx-4 mb-0 text-center font-semibold">
                                OR
                              </p>
                            </div>
                            
                           <div className="flex justify-around">
                            <Link to="/trainHire">
                            <div className="px-2">
                            <Button text="sign up with carrers"  />
                            </div>
                            </Link>

                            <Link to="/services">
                            <div className="px-2">
                            <Button text="sign up with services" />
                            </div>
                            </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                      <form onSubmit={(e) => requestLogin(e)}>
                        <div className="card card-signin my-5 -mt-10">
                          <div className="card-body ml-5 mr-5 mt-5">
                            <h1 className="text-xl uppercase flex justify-center font-semibold mb-2">
                              Sign In
                            </h1>

                            <span style={{ color: currentColor }}>
                              <p className="text-base flex justify-center mb-2">
                                with Two-Factor authentication
                              </p>
                            </span>
                            <br />

                            <div>
                              <label
                                htmlFor="inputEmail"
                                className="font-semibold"
                              >
                                Two-Factor Authentication code
                              </label>

                              <input
                                type="number"
                                id="input2FACode"
                                className="form-control w-full text-base py-2 my-4 bg-transparent
                              text-black border-b border-black outline-none focus:outline-none"
                                placeholder="123456"
                                required
                                autoComplete="on"
                                onChange={updateTwoFACode}
                              />
                            </div>

                            <Button text="Sign in" />

                            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-[#ccd5ae] after:mt-0.5 after:flex-1 after:border-t after:border-[#ccd5ae]">
                              <p className="mx-4 mb-0 text-center font-semibold">
                                OR
                              </p>
                            </div>

                            <Link to="/trainHire">
                            <div className="mb-4">
                            <Button text="sign up with carrers"  />
                            </div>
                            </Link>

                            <Link to="/services">
                            <div className="mb-4">
                            <Button text="sign up with services" />
                            </div>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },

              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />
        </div>
      </>
    );
  }
};

export default Login;
