// ProjectList.js
import React, { useState, useEffect } from "react";
import {
  retrieveOrgData,
  retrieveProfileData,
  retrieveProjectByFeatureName,
} from "../../../api/Api";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from "@mui/material";

import KanbanBoard from "./KanbanBoard";
import AddProjectOrTaskSubscription from "./AddProjectOrTaskSubscription";
import { ORGDATALIST, PUBLICHOLIDAYLIST } from "../../../config/Config";

const tabs = ["Project List", "AddTask", "Kanban Board"];

const ProjectListForClient = () => {
  const [selectedFeature, setSelectedFeature] = useState("");
  const [featureIdList, setFeatureIdList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedView, setSelectedView] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const orgDetailList = JSON.parse(localStorage.getItem(ORGDATALIST));
  //alert("ORGDataLIST"+JSON.stringify(orgDetailList));
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedProject(null);
    setSelectedFeature(null); // Reset selected project when changing tabs
    setSelectedView(0); // Reset selected view when changing tabs
  };
  const handleProjectClick = (myKanban) => {
    setSelectedTab(1); // Switch to Kanban Board tab
    setOpenDialog(true);
    setSelectedProject(myKanban);
  };
  
  const handleDialogClose = () => {
    // Close the dialog
    setOpenDialog(false);
    
  };
  const handleOptionSelect = (tabValue, viewValue) => {
    // Set the selected tab and view based on user's choice
    setSelectedTab(tabValue);
    setSelectedView(viewValue);
    setOpenDialog(false);
  };

  const handleChangeProject = (event) => {
    const newValue = event.target.value;
    setSelectedFeature(newValue);
    retrieveBoardList(newValue);
  };

  const retrieveBoardList = (selectedFeature) => {
    retrieveProjectByFeatureName(selectedFeature)
      .then((res) => {
        const projectList = res.data;
        handleProjectClick(projectList.taskList);
      })
      .catch((error) => {
        console.error("Error retrieving board list:", error);
      });
  };

  useEffect(() => {
    retrieveProfileData()
      .then((res) => {
        setFeatureIdList(res.featureList);
      })
      .catch((error) => {
        console.error("Error retrieving feature list:", error);
      });
  }, []);
 
  return (
    <div>
      <AppBar position="static">
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </AppBar>
      <div className="p-4">
        {selectedView === 0 && (
          <>
            <Container>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                height="100vh"
              >
                <Typography variant="h10" gutterBottom sx={{ fontSize: "1.5rem" }}>
                  Project List for {selectedFeature}
                </Typography>

                {/* Select Box for Projects */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  minWidth="200px"
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    sx={{ fontSize: "0.8rem", marginBottom: "8px" }}
                  >
                    <InputLabel id="project-label">Select Project</InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      value={selectedFeature}
                      onChange={handleChangeProject}
                      label="Select Project"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {featureIdList.map((featureId) => (
                        <MenuItem key={featureId} value={featureId}>
                          {featureId}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Container>
          </>
        )}
        


        {selectedView === 1 &&
          selectedProject !== null &&
          selectedTab === 1 && (
            <Paper elevation={3} className="p-3">
              <AddProjectOrTaskSubscription
                selectedOrg={selectedFeature}
                selectedProject={selectedProject}
                featureName={selectedFeature}
                setOpenDialog={setOpenDialog}
              />
            </Paper>
          )}

        {selectedView === 2 &&
          selectedProject !== null &&
          selectedTab === 2 && (
            <Paper elevation={3} className="p-3">
              <KanbanBoard myKanban={selectedProject} orgName={selectedFeature} />
            </Paper>
          )}
        {selectedView === 0 && (
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="xs"
            classes={{ paper: "m-4" }}
          >
            <DialogTitle className="text-center">Choose an Option</DialogTitle>
            <DialogActions className="flex justify-center space-x-2 p-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOptionSelect(1, 1)}
                className="flex-1"
              >
                Add Task
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOptionSelect(2, 2)}
                className="flex-1"
              >
                View Kanban
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default ProjectListForClient;
