import React from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { BuildYourVisionEn} from "../../data/content/En/BuildYourVisionEn";
import { BuildYourVisionDe } from '../../data/content/De/BuildYourVisionDe';
import { Language } from '../../config/Config';
import { Link } from 'react-router-dom';

const buildYourVision = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor,
      ); 
      return (
        
        <div className="container">
     
        <div className="main contact-margin-top" id="contact">
          <div className="contact-div-main">
            <div className="contact-header ml-4 md:ml-8">
            
              <button
                      className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                      style={{ backgroundColor: currentColor }}
                    ><Link to ="/hireCoderRegister">
                      <span>{Language==='en'? BuildYourVisionEn.button:BuildYourVisionDe.button}</span></Link>
                    </button>
                    <br></br>
                    <br></br>
            
              <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? BuildYourVisionEn.header:BuildYourVisionDe.header}</h1>
             <br></br>
                     <p className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.w1:BuildYourVisionDe.w1}
                     {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-8"><b>{Language==='en'? BuildYourVisionEn.whyChoose:BuildYourVisionDe.whyChoose}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.s1:BuildYourVisionDe.s1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.s2:BuildYourVisionDe.s2}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.s3:BuildYourVisionDe.s3}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-8"><b>{Language==='en'? BuildYourVisionEn.teamsWork:BuildYourVisionDe.teamsWork}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.f1:BuildYourVisionDe.f1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.f2:BuildYourVisionDe.f2}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.f3:BuildYourVisionDe.f3}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-8"><b>{Language==='en'? BuildYourVisionEn.partnerWithUs:BuildYourVisionDe.partnerWithUs}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.a1:BuildYourVisionDe.a1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.a2:BuildYourVisionDe.a2}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.a3:BuildYourVisionDe.a3}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-8"><b>{Language==='en'? BuildYourVisionEn.readytoBring:BuildYourVisionDe.readytoBring}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-8">{Language==='en'? BuildYourVisionEn.r1:BuildYourVisionDe.r1}</li>
                      {' '}
                      </p><br></br>
                      <button
                      className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                      style={{ backgroundColor: currentColor }}
                    ><Link to ="/hireCodeRegister">
                      <span>{Language==='en'? BuildYourVisionEn.button:BuildYourVisionDe.button}</span></Link>
                    </button>
                  
             </div>
             </div>
             </div>
             </div>
      )
    }

export default buildYourVision