import React from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";

const Project_Process = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode,
  );
  return (
    <div className="container">
      <h2
        className="text-3xl text-center font-bold uppercase"
        style={{ color: currentColor }}
      >
        Our Project Development process
      </h2>
      <p className="mt-2 text-xl text-center font-semibold">
        Customer deliverables are always our target.
      </p>

      <div
        className={
          currentMode === "Dark"
            ? "container px-6 py-8 mx-auto bg-dark-gradient-r"
            : "container px-6 py-8 mx-auto bg-light-gradient-r"
        }
      >
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-4 mt-10">
          <div className="flex items-center mb-5 ">
            <div>
              <div className="relative flex items-center justify-center w-14 h-14 rounded-full">
                {" "}
                1{" "}
              </div>
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Business Requirements
              </h3>
              <p className="text-base font-normal">
                We collaborate with customer to deep dive into business
                requirements and solutions.
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-1 rounded-full">
              {" "}
              2{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Processing
              </h3>
              <p className="text-base font-normal">
                Our architects engage and provide various solutions for the
                client's problem statements.{" "}
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14 rounded-fu">
              {" "}
              3{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Connecting dots
              </h3>
              <p className="text-base font-normal">
                Deadline for the deliverables connecting dots are identified and
                defined.
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14 rounded-full">
              {" "}
              4{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Presentation
              </h3>
              <p className="text-base font-normal">
                We present our periodic output as part of sprint review with
                client stakeholders.{" "}
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14  rounded-full">
              {" "}
              5{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Continuous Improvement
              </h3>
              <p className="text-base font-normal">
                {" "}
                prioritizing the task and improve iteratively at each sprint.
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14 rounded-full">
              {" "}
              6{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Milestones
              </h3>
              <p className="text-base font-normal">
                Address the key deliverables and define it well ahead to meet
                the deadlines.
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14rounded-full">
              7
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Intermediate Review
              </h3>
              <p className="text-base font-normal">
                At the each sprint, development progress is presented to the
                customer.{" "}
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14 rounded-full">
              8{" "}
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Deployment and Bug fix
              </h3>
              <p className="text-base font-normal">
                Once approved, deploy the solutions from lower environments to
                UAT,Pre-prod and prod with proper testing tools.{" "}
              </p>
            </div>
          </div>

          <div className="flex items-center mb-5">
            <div className="relative  flex items-center justify-center w-14 h-14rounded-full">
              9
            </div>
            <div className="mt-2 sm:pe-8">
              <h3
                className="text-lg text-center font-bold uppercase"
                style={{ color: currentColor }}
              >
                Warranty support
              </h3>
              <p className="text-base font-normal">
                End of deliverables handover with proper warranty support to
                stable production environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project_Process;
