import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { createManualSubscription } from "../../../../../api/FinanceApi";

const EditInvoiceDetail = ({ subscription }) => {
    console.log("subscribe"+JSON.stringify(subscription));
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [subscriptionRequest, setSubscriptionRequest] = useState({
    userName: "",
    subscriberName: "",
    subscriptionFor: "",
    subscriptionType: "",
    subscriptionCost: "",
    totalBillingHours: "",
    totalBillingDay: "",
    paymentBillingFrom: "",
    paymentBillingTo: "",
    paymentId: "",
    techStack: "",
    subscriptionId: "NEW",
    employeeEmailIdList: [],
  });
  const [emailIdListForm, setEmailIdListForm] = useState({
    emailIdList: [{
      employeeId: '',
    } 
    ],
    
  });
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  useEffect(() => {
    document.title = "Edit Invoice for existing subscription";
   // updateEmployeeIdSubscription();
  }, []);
  const updateEmployeeIdSubscription = () => {
    let req = subscription.employeeEmailIdList;
    let employeeId;
    const list = [...emailIdListForm.emailIdList];

    for(let i = 0; i < req.length; i++) {

      employeeId = req[i];
    }
  }
  const handleInfoAddClick = () => {
    setEmailIdListForm({
      ...emailIdListForm,
      emailIdList: [
        ...emailIdListForm.emailIdList,
        {
         employeeId: "",
        },
      ],
    });
  };
  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailIdListForm.emailIdList];
    list[index][name] = value;
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };
  const handleInfoRemoveClick = (index) => {
    const list = [...emailIdListForm.emailIdList];
    list.splice(index, 1);
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };
  const updateUserName = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      userName: event.target.value,
    });
  };

  const updateSubscriberName = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriberName: event.target.value,
    });
  };

  const updateSubscriptionId = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionId: event.target.value,
    });
  };
  const updatePaymentId = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentId: event.target.value,
    });
  };
  const updateTechStack = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      techStack: event.target.value,
    });
  };
  const updateSubscriptionFor = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionFor: event.target.value,
    });
  };
  const updateSubscriptionType = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionType: event.target.value,
    });
  };
  const updateSubscriptionCost = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      subscriptionCost: event.target.value,
    });
  };
  const updatePaymentBillingFrom = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentBillingFrom: event.target.value,
    });
  };
  const updatePaymentBillingTo = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      paymentBillingTo: event.target.value,
    });
  };
  const updatetotalBillingDay = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      totalBillingDay: event.target.value,
    });
  };
  const updatetotalBillingHours = (event) => {
    setSubscriptionRequest({
      ...subscriptionRequest,
      totalBillingHours: event.target.value,
    });
  };
  const handleManualPayment = (event) => {
    event.preventDefault();
    setShowLoading(true);
    let employeeEmailIdList = [];
    for (let i=0; i<emailIdListForm.emailIdList.length; i++) {
      const employeeIdTemp = {};
      //alert("inside for"+JSON.stringify(emailIdListForm.emailIdList[i].employeeId));
      employeeIdTemp=emailIdListForm.emailIdList[i].employeeId;
      employeeEmailIdList.push(employeeIdTemp)
    }
    //alert("EmailID"+JSON.stringify(employeeEmailIdList))
    let req = subscriptionRequest;
    req.employeeEmailIdList = employeeEmailIdList;
    setSubscriptionRequest(req);
    //alert("EmailID"+JSON.stringify(subscriptionRequest))
    createManualSubscription(subscriptionRequest)
      .then((res) => {
        //alert("suucesss"+JSON.stringify(res.data.message));
        toast.success(res.data.message);

        setSubscriptionRequest({ ...subscriptionRequest, userName: "" });
        setSubscriptionRequest({ ...subscriptionRequest, subscriberName: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionId: "NEW",
        });
        setSubscriptionRequest({ ...subscriptionRequest, paymentId: "" });
        setSubscriptionRequest({ ...subscriptionRequest, techStack: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionFor: null,
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionType: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          subscriptionCost: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          paymentBillingFrom: "",
        });
        setSubscriptionRequest({
          ...subscriptionRequest,
          paymentBillingTo: "",
        });
        setSubscriptionRequest({ ...subscriptionRequest, totalBillingDay: "" });
        setSubscriptionRequest({
          ...subscriptionRequest,
          totalBillingHours: "",
        });
        setShowLoading(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };
  const { emailIdList } = emailIdListForm;

  return (
    <div>
      {/*<button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
  */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-100 bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Edit Invoice
                </h2>
                <div className="relative p-0 px-3 flex-none">
                <form onSubmit={handleManualPayment}>
              <div className="grid md:grid-cols-2">
                <div className="mt-3 mx-3">
                  <label className="float-left items-center flex mt-2" for="userName">
                    User Name/Email
                  </label>
                  <input
                    type="text"
                    id="userName"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="User Name"
                    required
                    value={subscription.userName}
                    onChange={updateUserName}
                    autoComplete="on"
                  />
                </div>

            

                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionId">
                    Subscription Id
                  </label>
                  <input
                    type="text"
                    id="subscriptionId"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="keep blank if new subscription"
                    required
                    value={subscription.subscriptionId}
                    onChange={updateSubscriptionId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="paymentId">
                    Payment Id
                  </label>
                  <input
                    type="text"
                    id="paymentId"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscription.paymentId}
                    onChange={updatePaymentId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="techStack">
                    Project
                  </label>
                  <input
                    type="text"
                    id="techStack"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscription.projectRequirement}
                    onChange={updateTechStack}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionFor">
                    Subscription For
                  </label>
                  <input
                    type="text"
                    id="subscriptionFor"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscription.subscriptionFor}
                    onChange={updateSubscriptionFor}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Subscription Type
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    value={subscription.subscriptionType}
                    onChange={(event) => updateSubscriptionType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value={subscription.subscriptionType}
                    ></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">One Time</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionCost">
                    Subscription Cost
                  </label>
                  <input
                    type="text"
                    id="subscriptionCost"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Subscription Cost"
                    required
                    value={subscription.subscriptionCost}
                    onChange={updateSubscriptionCost}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingFrom">
                    Payment Billing From
                  </label>
                  <input
                    type="date"
                    id="billingFrom"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing From"
                    required
                    value={subscription.paymentBillingFrom}
                    onChange={updatePaymentBillingFrom}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingTo">
                    Payment Billing To
                  </label>
                  <input
                    type="date"
                    id="billingTo"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing To"
                    required
                    value={subscription.paymentBillingTo}
                    onChange={updatePaymentBillingTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="totalBillingDay">
                    Total no of billable days
                  </label>
                  <input
                    type="text"
                    id="totalBillingDay"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    value={subscription.totalBillingDay}
                    onChange={updatetotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="totalBillingHours">
                    Total no of billable hours
                  </label>
                  <input
                    type="text"
                    id="totalBillingHours"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Total Billing Hours"
                    required
                    value={subscription.totalBillingHours}
                    onChange={updatetotalBillingHours}
                    autoComplete="on"
                  />
                </div>
                {/* emailIdList.map((x, i) => {
                  return(
                    <div className="mt-2 mx-3">
                    <label htmlFor="employeeID" className="mt-2">
                      Employee Id
                    </label>
                    <input
                      type="text"
                      className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      id="employeeId"
                      name="employeeId"
                      placeholder="Employee ID"
                      required
                      autoComplete="on"
                      value={x.employeeId}
                      onChange={(e) => handleContentInfo(e, i)}
                    />
                    {emailIdList.length !== 1 && (
                      <button
                        className="mt-5 mb-5 w-50 rounded px-7 pb-2.5 pt-3 mr-10 text-sm text-white"
                        style={{ backgroundColor: currentColor }}
                        onClick={handleInfoRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                    {emailIdList.length - 1 === i && (
                      <button
                        className="mt-5 w-50 rounded px-7 pb-2.5 pt-3 text-sm text-white"
                        style={{ backgroundColor: currentColor }}
                        onClick={handleInfoAddClick}
                      >
                        Add
                      </button>
                    )}
                  </div>

                  )}
                )*/}
                 
                 
                <br />
                
              </div>
              <button
                  className="w-50 md:float-right sm:mx-auto rounded-lg px-5 mx-3 pb-2.5 pt-3 text-sm text-white uppercase"
                  style={{ backgroundColor: currentColor }}
                >
                  <span>Send</span>
                </button>
            </form>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (    
       null
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default EditInvoiceDetail;
