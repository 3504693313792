const TransformingEn={

    header:"Transforming Ideas into Solutions: Try Before You Commit!",
    t1:"At YaazhTech, we believe in proving our solutions before committing to contracts.",
    t2:" We offer POCs/MVPs, ensuring you witness the power of our products firsthand.",
    why:"Why Opt for Our POC/MVP Services?",
     w1:"Risk-Free Validation: Test our solutions at no cost, ensuring they align with your needs and expectations before any commitments.",
     w2:"Quality Assurance: Experience the quality and functionality of our products to make informed decisions about moving forward.",
     w3:"Tailored Solutions: We design POCs/MVPs specifically addressing your business problems, offering tangible solutions.",
   more:"More Than Just Staffing: Product-as-a-Service",
   m1:"Holistic Approach: Beyond staffing, we provide end-to-end product development, solving diverse business challenges.",
   m2:"Flexible Engagement: Embrace a service model where we deliver products as a service, tailored to your unique needs.",
  how:"How Our POC/MVP Services Work",
  o1:"Ideation & Analysis: Collaborate with our experts to conceptualize a solution that addresses your business challenges.",
  o2:"Development & Testing: Witness our team in action as they build and rigorously test the POC/MVP.",
  o3:"Evaluation & Decision: Review the delivered solution, ensuring it meets your requirements before proceeding.",   
  ready:"Ready to Explore Our Product-as-a-Service Approach?",
   r1:"Experience the future of solutions! Engage with us for a POC/MVP and witness how we transform ideas into actionable, problem-solving products.",

  button:"Request Free POC/MVP"
  
};
export {TransformingEn};