import React, { useState, useEffect } from 'react';
import { FiCopy } from 'react-icons/fi';
import { getContent, getEndpoints } from '../../../api/Api';
import ReactPlayer from 'react-player';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useHistory, useNavigate } from 'react-router-dom';

const ContentHeader = ({ primaryHeader, createdAt }) => (
  <div className="mb-6">
    <h1 className="text-3xl md:text-4xl font-bold text-blue-700">{primaryHeader}</h1>
    <h4 className="mt-2 text-gray-500 text-lg">Last Updated: {createdAt}</h4>
  </div>
);

const ContentBody = ({ contentList }) => (
  <div className="mt-6 md:mt-8">
    {contentList.map((item, i) => (
      <div key={i} className="mb-12">
        <h4 className="text-xl md:text-2xl font-semibold mb-3">{item.contentHeader}</h4>
        <div className="text-base  leading-relaxed mb-4">
          {item.contentBody.split('- ').map((bodyItem, j) => (
            <p key={j} className="mb-2">{bodyItem}</p>
          ))}
        </div>
        {item.codeSnippet && <CodeSnippet code={item.codeSnippet} />}
        {item.youTubeLink && <YouTubeEmbed link={item.youTubeLink} />}
        {item.contentImageBody && (
          <img src={item.contentImageBody} alt="Content" className="w-full h-auto mt-4" />
        )}
      </div>
    ))}
  </div>
);

const CodeSnippet = ({ code }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code)
      .then(() => toast.success('Copied to clipboard!'))
      .catch(() => toast.error('Failed to copy!'));
  };

  return (
    <div className="bg-black text-white p-4 rounded-md mt-4 relative border border-gray-300">
      <FiCopy
        className="absolute top-2 right-2 text-white-600 cursor-pointer hover:text-white-800"
        onClick={copyToClipboard}
      />
      <pre className="overflow-x-auto">
        <code className="whitespace-pre-wrap">{code}</code>
      </pre>
    </div>
  );
};

const YouTubeEmbed = ({ link }) => (
  <div className="relative mb-6 w-full h-0 overflow-hidden" style={{ paddingTop: '56.25%' }}>
    <ReactPlayer
      url={link}
      className="absolute top-0 left-0 w-full h-full"
      controls={true}
    />
  </div>
);

// Sidebar for Relevant Topics (Endpoints)
const RelevantTopics = ({ stateList, category }) => (
  <div className="p-4 bg-white rounded-md shadow-lg">
    <h4 className="font-bold text-lg mb-4">Relevant Topics</h4>
    <ul className="list-disc pl-6 space-y-2">
      {stateList.map((item, index) => (
        <li key={index}>
          <Link
            to={`/${category}-${item.endpoint}`}
            className="text-blue-600 hover:underline focus:outline-none"
          >
            {item?.endpoint}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const ContentManagement = (props) => {
  const [content, setContent] = useState({});
  const [endpoints, setEndpoints] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState(props.endpoint || null);
  const [stateList, setStateList] = useState(props.stateList || []);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    fetchEndPointData();
  }, [selectedEndpoint, props.category]);

  const fetchEndPointData = async () => {
    setLoading(true);
    try {
      const endpointRes = await getEndpoints();
      if (endpointRes.data) {
        setEndpoints(endpointRes.data);

        const categoryEndpoints = endpointRes.data.find(
          (endpoint) => endpoint.category === props.category
        );
        if (categoryEndpoints) {
          const firstContent = categoryEndpoints.contentStateList[0];
          if (selectedEndpoint) {
            const contentRes = await getContent(selectedEndpoint, props.category);
            if (contentRes.data) {
              setContent(contentRes.data);
              setContentList(contentRes.data.contentList);
            }
          }
        }
      }
    } catch (error) {
      toast.error(error.message || 'Failed to fetch content');
    }
    setLoading(false);
  };

  // Get the index of the current endpoint
  const currentIndex = stateList.findIndex((item) => item.endpoint === selectedEndpoint);

  // Function to navigate to the next endpoint
  const handleNext = () => {
    if (currentIndex < stateList.length - 1) {
      const nextEndpoint = stateList[currentIndex + 1].endpoint;
      setSelectedEndpoint(nextEndpoint);
      navigate(`/${props.category}-${nextEndpoint}`);
    }
  };

  // Function to navigate to the previous endpoint
  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevEndpoint = stateList[currentIndex - 1].endpoint;
      setSelectedEndpoint(prevEndpoint);
      navigate(`/${props.category}-${prevEndpoint}`);
    }
  };

  return (
    <div className="container mx-auto p-4 md:p-8">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Main Content Area */}
        <div className="md:col-span-9">
          {loading ? (
            <p className="text-lg font-semibold">Loading content...</p>
          ) : (
            <>
              <ContentHeader primaryHeader={content.primaryHeader} createdAt={content.createdAt} />
              <ContentBody contentList={contentList} />

              {/* Navigation Buttons */}
              <div className="flex justify-between mt-8">
                <button
                  onClick={handlePrevious}
                  className=" hover:bg-[#ed008c] hover:text-white bg-white text-pink-600 border-2 border-pink-600 px-4 py-2 rounded disabled:bg-gray-400"
                  disabled={currentIndex === 0}
                >
                  Previous
                </button>
                <button
                  onClick={handleNext}
                  className="hover:bg-[#ed008c] hover:text-white bg-white text-pink-600 border-2 border-pink-600 px-4 py-2 rounded disabled:bg-gray-400"
                  disabled={currentIndex === stateList.length - 1}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>

        {/* Sidebar for Relevant Topics */}
        <div className="md:col-span-3">
          <div className="sticky top-0">
            <RelevantTopics stateList={stateList} category={props.category} />
          </div>
        </div>
      </div>

      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            duration: 4000,
            style: { background: '#008000', color: '#fff' },
          },
          error: {
            duration: 4000,
            style: { background: '#FF4136', color: '#fff' },
          },
        }}
      />
    </div>
  );
};

export default ContentManagement;
