import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { createProjectOrTaskWithSubscription } from "../../../../../api/FinanceApi";

const AddProjectOrTaskWithSubscription = ({ subscription }) => {
  const navigate = useNavigate();
    console.log("subscribe"+JSON.stringify(subscription));
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );

  const [subscriptionWithProjectOrTask, setSubscriptionWithProjectOrTask] = useState({
    userName: "",
    paymentId: "",
    subscriptionId: "",
    projectOrTask: "",
    acceptanceCriteria:"",
    taskDetails:"",
    subscriptionFor: "",
    subscriptionType: "",
    subscriptionCost: "",
    totalBillingDay: "",
    paymentBillingFrom: "",
    paymentBillingTo: "",
    invoiceType:"",
    invoiceOption:"",
    
  });
  
  const [emailIdListForm, setEmailIdListForm] = useState({
    emailIdList: [{
      employeeId: '',
    } 
    ],
    
  });
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  useEffect(() => {
    document.title = "Add Project or Task";
    updateDataFromSubscription();
  }, []);
const updateDataFromSubscription=()=>{
  let req=subscriptionWithProjectOrTask;
  
  req.userName=subscription.userName;
 // req.techStack=subscription.techStack;
  setSubscriptionWithProjectOrTask(req);
  //alert(JSON.stringify(subscriptionWithProjectOrTask));
}

const updateProjectOrTask = (event) => {
  setSubscriptionWithProjectOrTask({
    ...subscriptionWithProjectOrTask,
    projectOrTask: event.target.value,
  });
};
const updateTechStack = (event) => {
  setSubscriptionWithProjectOrTask({
    ...subscriptionWithProjectOrTask,
    techStack: event.target.value,
  });
};
const updateTaskDetails = (event) => {
  setSubscriptionWithProjectOrTask({
    ...subscriptionWithProjectOrTask,
    taskDetails: event.target.value,
  });
};const updateAcceptanceCriteria = (event) => {
  setSubscriptionWithProjectOrTask({
    ...subscriptionWithProjectOrTask,
    acceptanceCriteria: event.target.value,
  });
};



  const handleInfoAddClick = () => {
    setEmailIdListForm({
      ...emailIdListForm,
      emailIdList: [
        ...emailIdListForm.emailIdList,
        {
         employeeId: "",
        },
      ],
    });
  };
  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailIdListForm.emailIdList];
    list[index][name] = value;
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };
  const handleInfoRemoveClick = (index) => {
    const list = [...emailIdListForm.emailIdList];
    list.splice(index, 1);
    setEmailIdListForm({ ...emailIdListForm, emailIdList: list });
  };
  const updateUserName = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      userName: event.target.value,
    });
  };
 
  const updateTaskType = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      invoiceType: event.target.value,
    });
  };

  const updateSubscriptionId = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      subscriptionId: event.target.value,
    });
  };
  const updatePaymentId = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      paymentId: event.target.value,
    });
  };
  
  const updateSubscriptionFor = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      subscriptionFor: event.target.value,
    });
  };
  const updateSubscriptionType = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      subscriptionType: event.target.value,
    });
  };
  const updateInvoiceOption = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      invoiceOption: event.target.value,
    });
  };
  const updatePaymentBillingFrom = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      paymentBillingFrom: event.target.value,
    });
  };
  const updatePaymentBillingTo = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      paymentBillingTo: event.target.value,
    });
  };
  const updateTotalBillingDay = (event) => {
    setSubscriptionWithProjectOrTask({
      ...subscriptionWithProjectOrTask,
      totalBillingDay: event.target.value,
    });
  };

  const handleAddProjectOrTask = (event) => {
    event.preventDefault();
    setShowLoading(true);

    
    let req = subscriptionWithProjectOrTask;
    if(req.projectOrTask==="createTask" && req.invoiceOption==="no"){
    req.paymentId=subscription.paymentId;
    req.subscriptionId=subscription.subscriptionId;
    }
    if(req.projectOrTask==="createTask" && req.invoiceOption==="yes"){
      req.subscriptionId=subscription.subscriptionId;
      }
    setSubscriptionWithProjectOrTask(req);
    //alert("subscriptionWithProjectOrTask"+JSON.stringify(subscriptionWithProjectOrTask))
    createProjectOrTaskWithSubscription(subscriptionWithProjectOrTask)
      .then((res) => {
        //alert("suucesss"+JSON.stringify(res.data.message));
        toast.success(res.data.message);

        setSubscriptionWithProjectOrTask({ ...subscriptionWithProjectOrTask, userName: "" });
        setSubscriptionWithProjectOrTask({ ...subscriptionWithProjectOrTask, subscriberName: "" });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          subscriptionId: "NEW",
        });
        setSubscriptionWithProjectOrTask({ ...subscriptionWithProjectOrTask, paymentId: "" });
        setSubscriptionWithProjectOrTask({ ...subscriptionWithProjectOrTask, techStack: "" });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          subscriptionFor: null,
        });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          subscriptionType: "",
        });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          subscriptionCost: "",
        });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          paymentBillingFrom: "",
        });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          paymentBillingTo: "",
        });
        setSubscriptionWithProjectOrTask({ ...subscriptionWithProjectOrTask, totalBillingDay: "" });
        setSubscriptionWithProjectOrTask({
          ...subscriptionWithProjectOrTask,
          totalBillingHours: "",
        });
        setShowLoading(false);
       setShowModal(false);
        // navigate('/viewClient',  state={
        //   clientUserName: subscription.userName,
        // })
       
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };
  const { emailIdList } = emailIdListForm;

  return (
    <div>
      {/*<button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
  */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-100 bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                {(subscriptionWithProjectOrTask.projectOrTask==="createProject")?(
                  <>
                 
                  <h2 className="card-title text-center text-lg font-semibold">
                  Add developer/project with Task for the client :      {subscription.userName}
                </h2>
                        </>
                ):(<h2 className="card-title text-center text-lg font-semibold">
                  Add Task for the existing Project :      {subscription.userName}
                </h2>)} 
                
                <div className="relative p-0 px-3 flex-none">
                <form onSubmit={handleAddProjectOrTask}>
              <div className="grid md:grid-cols-2">
              <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Select
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateProjectOrTask(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value="empty"
                    ></option>
                    <option value="createProject">Create new Project/add developer</option>
                    <option value="createTask">Add Tasks for existing Project</option>
                  </select>
                </div>
                {(subscriptionWithProjectOrTask.projectOrTask==="createProject")?(
                  <>
                  <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Task Type
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateTaskType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                     
                    ></option>
                    <option value="nonBillable">nonBillable</option>
                    <option value="internal">internal</option>
                    <option value="billable">billable</option>
                    <option value="others">Others</option>
                  </select>
                </div> 
           
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="techDetails">
                    Task Details
                  </label>
                  <input
                    type="text"
                    id="techDetails"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateTaskDetails}  
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="acceptCriteria">
                    Acceptance criteria
                  </label>
                  <input
                    type="text"
                    id="acceptCriteria"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateAcceptanceCriteria}  
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionFor">
                    Subscription For ({subscription.subscriptionFor})
                  </label>
                  <input
                    type="text"
                    id="subscriptionFor"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateSubscriptionFor}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Subscription Type
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateSubscriptionType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value={subscription.subscriptionType}
                    ></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">One Time</option>
                    <option value="others">Others</option>
                  </select>
                </div>
             
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingFrom">
                    Planned Start Date
                  </label>
                  <input
                    type="date"
                    id="billingFrom"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing From"
                    required
                    onChange={updatePaymentBillingFrom}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingTo">
                    Expected End Date
                  </label>
                  <input
                    type="date"
                    id="billingTo"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing To"
                    required
                    onChange={updatePaymentBillingTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="totalBillingDay">
                  No of days to complete
                  </label>
                  <input
                    type="text"
                    id="totalBillingDay"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateTotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                </>
                ):(null)}
                {(subscriptionWithProjectOrTask.projectOrTask==="createTask")?(
                  <>
                  <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Task with Invoice?
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateInvoiceOption(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value="empty"
                    ></option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                        {(subscriptionWithProjectOrTask.invoiceOption==="yes")?(
                  <>
                  <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Task Type
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateTaskType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                     
                    ></option>
                    <option value="nonBillable">nonBillable</option>
                    <option value="internal">internal</option>
                    <option value="billable">billable</option>
                    <option value="others">Others</option>
                  </select>
                </div> 
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionFor">
                    Subscription For ({subscription.subscriptionFor})
                  </label>
                  <input
                    type="text"
                    id="subscriptionFor"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateSubscriptionFor}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Subscription Type
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateSubscriptionType(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value={subscription.subscriptionType}
                    ></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">One Time</option>
                    <option value="others">Others</option>
                  </select>
                </div>
             
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingFrom">
                    Planned Start Date
                  </label>
                  <input
                    type="date"
                    id="billingFrom"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing From"
                    required
                    onChange={updatePaymentBillingFrom}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="billingTo">
                    Expected End Date
                  </label>
                  <input
                    type="date"
                    id="billingTo"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Billing To"
                    required
                    onChange={updatePaymentBillingTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="totalBillingDay">
                  No of days to complete
                  </label>
                  <input
                    type="text"
                    id="totalBillingDay"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateTotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                        </>
                ):(null)}     
           
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="techDetails">
                    Task Details
                  </label>
                  <input
                    type="text"
                    id="techDetails"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateTaskDetails}  
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="acceptCriteria">
                    Acceptance criteria
                  </label>
                  <input
                    type="text"
                    id="acceptCriteria"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateAcceptanceCriteria}  
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="totalBillingDay">
                  No of days to complete
                  </label>
                  <input
                    type="text"
                    id="totalBillingDay"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="New or Modify"
                    required
                    onChange={updateTotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                  </>
                ):(null)}

              <button
                  className="w-50 md:float-right sm:mx-auto rounded-lg px-5 mx-3 pb-2.5 pt-3 text-sm text-white uppercase"
                  style={{ backgroundColor: currentColor }}
                >
                  <span>Send</span>
                </button>

                 
                <br />
                
              </div>
              
            </form>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (    
       null
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default AddProjectOrTaskWithSubscription;
