import axios from 'axios';
import {
  ACCESS_TOKEN,
  config,
} from '../config/Config';

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

export function retrieveProfileImage() {
  return instance.get('/secure/employee/image', {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}
export function retrieveProfileImageByEmail(email) {
  return instance.get(`/secure/employee/image/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
}

export function profilePersonalData(employee) {
  return instance.get('/secure/employee/image', {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  });
  // alert("Datas" + JSON.stringify.employee)

  return sendRequest({
    url: `${config.url.API_BASE_URL}/auth/updatepersonaldata`,
    method: 'POST',
    body: JSON.stringify(employee),
  });
}
export function publicHolidaysUpdate(publicHolidayYear) {
  return instance.get(`/secure/employee/holidays/${publicHolidayYear}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
