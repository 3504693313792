import React from 'react'
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { DirectHireEn } from '../../../data/content/En/DirectHireEn';
import { DirectHireDe } from '../../../data/content/De/DirectHireDe';
const DirectHire = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
<div className="container">
  <div className="main contact-margin-top" id="directHire">
    <div className="contact-div-main">
      <div className="contact-header ml-4 md:ml-10">
        <button
          className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
          style={{ backgroundColor: currentColor }}>
       <Link
        to={{
          pathname: "/careersRegister",
        }}
        state={{
          subscriptionType: subscriptionType,
          subscriptionCost: subscriptionCost,
          subscriptionFor: subscriptionFor,
        }}
          >
           <span>{Language==='en'? DirectHireEn.button:DirectHireDe.button}</span>
               
               </Link>
               </button>

        <h1 className="section-heading text-3xl font-bold uppercase" style={{ color: currentColor }}>
          {Language === 'en' ? DirectHireEn.header : DirectHireDe.header}
        </h1>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.transformation : DirectHireDe.transformation}</b>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          {Language === 'en' ? DirectHireEn.w1 : DirectHireDe.w1}
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.header1 : DirectHireDe.header1}</b>
          {Language === 'en' ? DirectHireEn.dreamTeam : DirectHireDe.dreamTeam}
        </p>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.talent : DirectHireDe.talent}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.s1 : DirectHireDe.s1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.timeProcess : DirectHireDe.timeProcess}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.f1 : DirectHireDe.f1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.approch : DirectHireDe.approch}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.a1 : DirectHireDe.a1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.quality : DirectHireDe.quality}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.r1 : DirectHireDe.r1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.futureLeader : DirectHireDe.futureLeader}</b>
        </p>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.internForm : DirectHireDe.internForm}</b>
        </p>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.innovation : DirectHireDe.innovation}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.e1 : DirectHireDe.e1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.pipelineFuture : DirectHireDe.pipelineFuture}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.q1 : DirectHireDe.q1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.costSolution : DirectHireDe.costSolution}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.y1 : DirectHireDe.y1}</li>
        </p>
        <br></br>
        <p className="pages-content-text text-lg ml-4 md:ml-10">
          <b>{Language === 'en' ? DirectHireEn.branding : DirectHireDe.branding}</b>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.u1 : DirectHireDe.u1}</li>
          <li className="pages-content-text text-lg ml-4 md:ml-10">{Language === 'en' ? DirectHireEn.u2 : DirectHireDe.u2}</li>
        </p>
        <br></br>
        <button
          className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
          style={{ backgroundColor: currentColor }}>
       <Link
        to={{
          pathname: "/careersRegister",
        }}
        state={{
          subscriptionType: subscriptionType,
          subscriptionCost: subscriptionCost,
          subscriptionFor: subscriptionFor,
        }}
          >
            <span>{Language==='en'? DirectHireEn.button:DirectHireDe.button}</span>
               
               </Link>
               </button>
      </div>
    </div>
  </div>
</div>

 )
}

export default DirectHire