import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { Button } from ".";
import { userProfileData, adminProfileData,clientProfileData, financeProfileData,hrProfileData,employeeProfileData } from "../data/staticData";
import { StateContext } from "../contexts/StateContext";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  EMPLOYEE_TOKEN,
  CLIENT_TOKEN,
  FINANCIER_TOKEN,
  CLIENT,
  FINANCIER,
  EMPLOYEE,
  USER,
  HR,
  ADMIN,
  ORGDATALIST,
  PUBLICHOLIDAYLIST,
  DEVELOPERLIST
} from "../config/Config";
import { retrieveProfileImage } from "../api/EmployeeApi";
import ButtonForProfile from "./ButtonForProfile";
import { retrieveOrgData } from "../api/Api";
import { avatar } from "../assets/images";

function UserProfile() {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const setActiveMenu = useContextSelector(
    StateContext,
    (state) => state.setActiveMenu
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const handleCloseProfileBar = () => {
    // if (activeMenu !== undefined && screenSize <= 900) {
    setActiveMenu(false);
    //}
  };
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const role = useContextSelector(StateContext, (state) => state.role);
  const [showLoading, setShowLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  let menuItemsLeft;
  let decodedToken;
  let subHeader = [];
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (
    token !== "undefined" &&
    token !== undefined &&
    token !== null &&
    token !== "null" &&
    token !== ""
  ) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace("-", "+").replace("_", "/");
    decodedToken = JSON.parse(window.atob(base64));
    console.log("data" + JSON.stringify(decodedToken));
    menuItemsLeft = [
      <div>
        <p className="font-semibold text-xl dark:text-black-200">
          {" "}
          {decodedToken.name}{" "}
        </p>
        <p className="text-black-500 text-sm dark:text-black-400">
          {" "}
          {decodedToken.rol}{" "}
        </p>
        <p className="text-black-500 md:text-sm text-xs font-semibold dark:text-black-400">
          {" "}
          {decodedToken.email}{" "}
        </p>
      </div>,
    ];
  }
  useEffect(() => {
    document.title = "user Profile";
    retrieveProfilePic();
    retrieveOrgDataList();
  }, []);
  const retrieveOrgDataList = () => {
    retrieveOrgData()
      .then((res) => {
        localStorage.setItem(ORGDATALIST, JSON.stringify(res.orgDetailList));
        localStorage.setItem(PUBLICHOLIDAYLIST, JSON.stringify(res.holidayMap));
        localStorage.setItem(DEVELOPERLIST, JSON.stringify(res.developerDetailsList))
      })
      .catch((error) => {
        console.error("Error retrieving feature list:", error);
      });
  };
  const retrieveProfilePic = () => {
    setShowLoading(true);
    retrieveProfileImage()
      .then((res) => {
        // alert("profile"+JSON.stringify(res.data));
        //console.log("data" + JSON.stringify(res));
        setImagePreviewUrl(res.data);
        //showAlert(
        // "Profile image retrieved successfully ",
        // "success"
        // );
        setShowLoading(true);
      })
      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image does't exist !", "error");
        } else {
          //showAlert(error.message, "error");
        }
        setShowLoading(true);
      });
  };

  return (
    <div
      className={
        currentMode === "Dark"
          ? "nav-item absolute right-1 top-16 p-2 rounded-lg w-72 bg-[#f6f6f6]"
          : "nav-item absolute right-1 top-16 p-2 rounded-lg w-72 bg-[#f6f6f6]"
      }
    >
      <div className="flex justify-between items-center">
        <p className="font-semibold md:text-lg text-md dark:text-black-200">
          User Profile
        </p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-black"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div
        className={
          currentMode === "Dark"
            ? "flex gap-5 items-center mt-3 border-b-1 pb-3 bg-[#f6f6f6]"
            : "flex gap-5 items-center mt-3 border-b-1 pb-3 bg-[#f6f6f6]"
        }
      >
        {imagePreviewUrl ? (
          <img
            src={`data:image/jpeg;base64,${imagePreviewUrl}`}
            alt="avatar"
            className={
              currentMode === "Dark"
                ? "rounded-full md:h-24 md:w-24 bg-[#f6f6f6]"
                : "rounded-full md:h-24 md:w-24 h-16 w-16 bg-[#f6f6f6]"
            }
          />
        ) : (
          <img
            src={avatar}
            alt="avatar"
            className={
              currentMode === "Dark"
                ? "rounded-full h-24 w-24 bg-[#f6f6f6]"
                : "rounded-full h-24 w-24 bg-[#f6f6f6]"
            }
          />
        )}

        {menuItemsLeft}
      </div>
      <div>
        {role === CLIENT
          ? clientProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
              
                <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
                
              </div>
            ))
          : null}
            {role === FINANCIER
          ? financeProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
              
                <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
                
              </div>
            ))
          : null}
            {role === EMPLOYEE
          ? employeeProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
                <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
              </div>
            ))
          : null}
          {role === HR
          ? hrProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
               <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
              </div>
            ))
          : null}
           {role === ADMIN
          ? adminProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
               <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
              </div>
            ))
          : null}
          {role === USER
          ? userProfileData.map((item, index) => (
              <div
                key={index}
                className="flex gap-5 border-b-1 border-color p-2 hover:bg-light-black cursor-pointer  dark:hover:bg-[#42464D]"
              >
               <NavLink to={`/${item.url}`} className="ml-2">
                  <ButtonForProfile
                    icon={item.icon}
                    color={item.iconColor}
                    bgColor={item.iconBg}
                    text={item.title}
                  >
                
                  </ButtonForProfile>
                </NavLink>
               
               
              </div>
            ))
          : null}
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
          buttonClickFor="logout"
        />
      </div>
    </div>
  );
}

export default UserProfile;
