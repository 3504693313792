import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import { ACCESS_TOKEN, config, merchantId } from '../config/Config';

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});
const gateWayInstance = axios.create({
  baseURL: '/hermes',
});

const gateWayInstanceApi = axios.create({
  baseURL: config.url.API_GATE_URL,
});

const sendRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  return fetch(options.url, options)
    .then(
      (response) => response.json()
        .then(
          (json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          },
        ),
    );
};

export function profilePersonalData(personalData) {
  return instance.post('/secure/common/updatePersonalData', personalData, {
   headers: {
   'Content-type': 'application/json',
  Authorization: localStorage.getItem(ACCESS_TOKEN)
      ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
      : null,
  },
 });
 }
export function profileData(profile) {
  // alert("Profile data" + JSON.stringify(profile));
  return instance.post('/secure/common/updateProfileData', profile, {
    headers: {
      'Content-type': 'application/json',

      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function retrieveUser() {
  return instance.get('/secure/common/profileData', {
    headers: {
      'Content-type': 'application/json',

      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function accountData(account) {
  return instance.post('/secure/common/accountData', account, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function documentData(document) {
  return instance.post('/secure/common/documentData', document, {
    headers: {
      'Content-type': undefined,
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function skillDetails(skill) {
  return instance.post('/secure/common/skillData', skill, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function bankingData(banking) {
  return instance.post('/secure/common/bankingData', banking, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
// Generate SHA-256 hash
/* async function sha256Hash(input) {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  } */

export function payViaGateWay(encoded, xVerify) {
  // const xHash=encoded+"/pg/v1/pay"+"695d0547-3728-4b1c-825d-996479133615";
  // let reHashed=sha256Hash(xHash);
  // alert("sha256 async function"+JSON.stringify(reHashed));

  // const crypto = require('crypto');
  // Generate SHA-256 hash
  // const hash = crypto.createHash('sha256').update(xHash).digest('hex');
  const hashHex = '';
  // hash(xHash).then((hex) => alert("hex:::::"+hex));
  // alert("sha256 via direcat requir"+hashHex);
  const payRequest = {
    request: '',
  };
  payRequest.request = encoded;
  return gateWayInstance.post('/pg/v1/pay', payRequest, {
    headers: {
      'Content-type': 'application/json',
      'X-VERIFY': `${xVerify}###${1}`,
    },
  });
}
export function payViaGateWayBackend(transactionRequest){
 // alert("sfdsf inside trans"+JSON.stringify(transactionRequest))
  return instance.post("/secure/common/pay", transactionRequest, {
   headers: {
     "Content-type": "application/json",
     Authorization: localStorage.getItem(ACCESS_TOKEN)
       ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
       : null,
   },
 });
}
export function checktransactionStatus(encoded, xVerify) {
  // alert("inisde")
  const xHash = `${encoded}/pg/v1/pay` + 'cb6843ed-09ab-4454-8088-d5a46bf5ef9c';
  // let reHashed=sha256Hash(xHash);
  // alert("sha256 async function"+JSON.stringify(reHashed));

  // const crypto = require('crypto');
  // Generate SHA-256 hash
  // const hash = crypto.createHash('sha256').update(xHash).digest('hex');
  const hashHex = '';
  // hash(xHash).then((hex) => alert("hex:::::"+hex));
  // alert("sha256 via direcat requir"+hashHex);
  const payRequest = {
    request: '',
  };
  // alert("xVerify"+xVerify)
  payRequest.request = encoded;
  return gateWayInstance.post('/pg/v1/pay', payRequest, {
    headers: {
      'Content-type': 'application/json',
      'X-VERIFY': `${xVerify}###${1}`,
    },
  });
}

export function sendBulkEmail(formData) {
  return instance.post('/secure/common/sendBulkEmail', formData, {
    headers: {
      'Content-type': undefined,
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function saveTransactionId(banking) {
  return instance.post('/secure/common/saveTransactionId', banking, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function getTransactionId() {
  return instance.get('/secure/common/getTransactionId', {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}

export function contentData(content) {
  return instance.post('/secure/common/contentManagement', content, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function contentPutData(contentUpdate) {
  return instance.put('/secure/common/contentManagementUpdate', contentUpdate, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function generateInvoice(paymentDetails) {
 // alert(`paymentDetails!!!...${JSON.stringify(paymentDetails)}`);
  return instance.post('/secure/common/generateInvoice', paymentDetails, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function getUsers() {
  return instance.get('/secure/admin/dashboard/users', {
    headers: {
      'Content-type': 'application/json',

      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function retrieveClientUser(clientUserName) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/secure/common/userData/${clientUserName}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function createManualSubscription(subscriptionRequest) {
  return instance.get('/secure/common/createSubscription', subscriptionRequest, {
    headers: {
      'Content-type': 'application/json',

      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
