
import React, { useState } from "react";
import { Hero } from "./Hero";
import TechnologyMarquee from "./TechnologyMarquee";
import Services from "./Services";
import Service from "./Service";
import ProductShowcase from "./ProductShowCase";
import Developer from "./Developer";
import LifeAtYaazhtech from "./LifeAtYaazhtech";
import TestimonialSlider from "./TestimonialSlider";


function Home() {
  return (
    <div /*className={currentMode === "Dark" ? "dark" : ""} */>
      <Hero/>
      <TechnologyMarquee />
      <Service/>
      <ProductShowcase/>
      <LifeAtYaazhtech/>
      <TestimonialSlider/>
      {/* <Developer /> */}
    
    </div>
  );
}

{/* Employee Skills and Role , If add another employee you can add below 'const data=[]' */ }



export default Home;
