import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { CustomInput, FormGroup } from 'reactstrap';
import { Navigate } from "react-router-dom";
import { sendBulkEmail }from "../../../../../../api/commonApi"


const BulkEmailing = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [BulkEmailing, setBulkEmailing] = useState({
    recipientsList: "",
    service: "",
    mailBody: "",
    subject: "",
    qaFile: null,
    fileName: '',
    invalidFile: false,
    showLoading: false,
    });
    useEffect(()=>{
      document.title = "Bulk Mailing";
    },[]);
    
    const handleFileChange = ({target: {files}}) => {
      const cancel  = !files.length;
      //alert("files"+JSON.stringify(files.length));
      if (cancel) return;
      const [{ size, name }] = files;
      const maxSize = 100000;
        if (files && files.length) {        
          const [{ size, name }] = files;
          const maxSize = 100000;
          if (size < maxSize) {
            let req = BulkEmailing;
            req.fileName = name;
            req.invalidFile = false;
            req.qaFile = files[0];
            setBulkEmailing(req);
          } else {
            let req = BulkEmailing;
            req.fileName = name;
            req.invalidFile = false;
            setBulkEmailing(req);
            toast.error(
              "Your image size should not exceed 100kb . Please try again !"
            );
            toast.error("error");
            setBulkEmailing({ ...BulkEmailing, showLoading: false });
          }
        }
      };
const recipientsList =(event) =>{
  setBulkEmailing({...BulkEmailing, recipientsList: event.target.value});
}
const mailBody =(event) =>{
  setBulkEmailing({...BulkEmailing, mailBody: event.target.value});
}
const subject =(event) =>{
  setBulkEmailing({...BulkEmailing, subject: event.target.value});
}
const updateService =(event) =>{
  setBulkEmailing({...BulkEmailing,  service: event.target.value});
}
const clearAlert = () => {
  toast.success("");
  toast.error("");
};
const sendEmailFeature = (event) => {
  event.preventDefault();
  //alert("data" + JSON.stringify(BulkEmailing));
  setBulkEmailing({ ...BulkEmailing, showLoading: true });
  const formData = new FormData();
    formData.append("file", BulkEmailing.qaFile);
    formData.append('emailRequest', new Blob([JSON.stringify(
     BulkEmailing
    )], {
      type: "application/json"
    }));
  sendBulkEmail(formData)
  .then((res) => {
    //alert("suucesss"+JSON.stringify(res.data.message));
    toast.success(res.data.message);
   
      setBulkEmailing({ ...BulkEmailing, showLoading: false });
      setBulkEmailing({ ...BulkEmailing, recipientsList: "" });
      setBulkEmailing({ ...BulkEmailing, service: "" });
      setBulkEmailing({ ...BulkEmailing, mailBody: "" });
      setBulkEmailing({ ...BulkEmailing, subject: "" });
      setBulkEmailing({ ...BulkEmailing, qaFile: null });
      setBulkEmailing({ ...BulkEmailing, fileName: "" });
    })
    .catch((error) => {
      if (error.status === 401) {
        toast.error(
          "Your username or email does not exist. Please try again !"
        );
      } else  {
        toast.error(error.message);
      }
      setBulkEmailing({ ...BulkEmailing, showLoading: false });
    });
};

  
return (
  <div>

 <div className="container mx-auto p-4">
        <div className="max-w-md mx-auto p-8 rounded bg-[#E6F6FA]">
          {BulkEmailing.showLoading ? (
            <div className="align-content-center text-center">
              <h5>BulkEmailing</h5>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (

            <div className='card card-signin my-5 -mt-10'>
         <form onSubmit={(e) => sendEmailFeature(e)}>

              <div className="card-body ml-5 mr-5 mt-5">
                <h1 className="text-xl uppercase flex justify-center font-semibold mb-2">
                Bulk Emailing
                </h1>
              </div>

               <br />


              <div>
                <h2 className="font-semibold">Reciptents </h2>
                <input
                  type="text"
                  className="form-control w-full text-base py-2 my-4 bg-transparent
           text-black border-b border-black outline-none focus:outline-none"
                  placeholder="Reciptents"
                  onChange={recipientsList}
                  autoComplete="on"
                />


                <h2 className="font-semibold"> Email Subject  </h2>
                <input
                  type="text"
                  className="form-control w-full text-base py-2 my-4 bg-transparent
           text-black border-b border-black outline-none focus:outline-none"
                  placeholder="Email Subject"
                  onChange={subject}
                  autoComplete="on"
                  required
                />


                <h2 className="font-semibold"> Body Of The Mail </h2>
                <input
                  type="text"
                  className="form-control w-full text-base py-2 my-4 bg-transparent
           text-black border-b border-black outline-none focus:outline-none"
                  placeholder="Body Of The Mail"
                   onChange={mailBody}
                  autoComplete="on"
                  required
                />
                <div className="mt-0">
                  <label htmlFor="orgName" className="font-semibold">Image for promotion</label>
                  <select
                     className="form-control w-full text-base py-2 my-4 bg-transparent
                     text-black border-b border-black outline-none focus:outline-none"
                    name="option"
                    value={BulkEmailing.service}
                    onChange={(event) => updateService(event)}
                  >
                    <option className="form-control" value="">Select purpose of the email.</option>
                    <option value="business">business</option>
                    <option value="hr">hr</option>
                    <option value="info">info</option>

                  </select>
                </div>
                <div className="mt-0">
                  <label htmlFor="qaFile" className="font-semibold">Service Image</label>
                  <FormGroup>
                    <CustomInput
                      type="file"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
                      text-black border-b border-black outline-none focus:outline-none"
                      id="exampleCustomFileBrowser"
                      name="customFile"
                     //label={BulkEmailing.fileName || "choose an image file"} 
                      onChange={handleFileChange}
                      invalid={BulkEmailing.invalidFile}
                     

                    />
                  </FormGroup>
                </div>
                <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                >
                  <span>SUBMIT</span>
                </button>



              </div>

              </form>
            </div>
          )}
        </div>

      </div>
      <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        error: {
          duration: 6000,
          style: {
            background: '#FF4136',
            color: '#fff',
          },
        },

        success: {
          duration: 6000,
          style: {
            background: '#008000',
            color: '#fff',
          },
        },
      }}
    />
 
      </div>
)
}
   



   

export default BulkEmailing;




