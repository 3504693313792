// ProfileTabs.js
import React, {useState,useEffect}from 'react';
import { AppBar, Card, CardContent, Tab, Tabs, Typography, Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
//import { retrieveProfileData } from '../../../../../api/Api';
//import { retrieveProfileImage } from '../../../../../api/EmployeeApi';
import { getUsersByCategory } from '../../../../../api/FinanceApi';
import avatar from '../../../../../assets/images/logos/logoFinal30x30.png';
import AlertComponent from '../../../../../AlertComponent';

const SubscriptionTab = ({subscriptionList}) => (
   

  <Container>
    <Card>
      <CardContent>
      <Typography variant="h5" color="primary">Subscription Data</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subscription For</TableCell>
                <TableCell>Subscription Id</TableCell>
                <TableCell>Subscription Status</TableCell>
                <TableCell>Subscription Cost</TableCell>
                <TableCell>Subscription Type</TableCell>
              </TableRow>
            </TableHead>
           {/* {subscriptionList && subscriptionList.length >0 ?( <TableBody>
            {subscriptionList.map((data) => (
            <TableRow key={data.subscriptionFor}>
                <TableCell>{data.subscriptionFor}</TableCell> 
               <TableCell>{data.subscriptionId}</TableCell> 
              <TableCell>{data.subscriptionStatus}</TableCell>
              <TableCell>{data.subscriptionCost}</TableCell>
              <TableCell>{data.subscriptionType}</TableCell> 
            </TableRow>
          ))}
            </TableBody>):null}*/}
           
          </Table>  
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const PaymentTab = ({paymentList}) => (
   

  <Container>
    <Card>
      <CardContent>
      <Typography variant="h5" color="primary">Payment List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment Status</TableCell>
                <TableCell>Billing From</TableCell>
                <TableCell>Billing To</TableCell>
                <TableCell>Paid So Far</TableCell>
                <TableCell>Payment Id</TableCell>
              </TableRow>
            </TableHead>
          {/*{paymentList && paymentList.length>0?( <TableBody>
           {paymentList.map((data) => (
            <TableRow key={data.paymentStatus}>
              <TableCell>{data.paymentStatus}</TableCell>
              <TableCell>{data.paymentBillingFrom}</TableCell> 
              <TableCell>{data.paymentBillingTo}</TableCell> 
              <TableCell>{data.paidSoFar}</TableCell> 
              <TableCell>{data.paymentId}</TableCell> 
            </TableRow>
          ))}
            </TableBody>):null}*/}
           
          </Table> 
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);
const TransactionTab = ({transactionList}) => (
   

  <Container>
    <Card>
      <CardContent>
      <Typography variant="h5" color="primary">Transaction List</Typography>
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Status</TableCell>
                <TableCell>Transaction Amount</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Mode Of Transaction</TableCell>
              </TableRow>
            </TableHead>
          {/* {transactionList && transactionList.length>0?(<TableBody>
            {transactionList.map((data) => (
            <TableRow key={data.transactionStatus}>
              <TableCell>{data.transactionStatus}</TableCell>
              <TableCell>{data.transactionAmount}</TableCell> 
              <TableCell>{data.transactionId}</TableCell> 
              <TableCell>{data.transactionDate}</TableCell>
              <TableCell>{data.modeOfTransaction}</TableCell> 
            </TableRow>
          ))}
            </TableBody>):null}*/}
          
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Container>
);


const SubscriptionTabs = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showLoading, setShowLoading] = useState(false);
  const [category, setCategory] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [subscriptionList, setSubscriptionList] = useState({
  });
  const [paymentList,setPaymentList]=useState({});
  const [transactionList,setTransactionList]=useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  useEffect(() => {
    document.title = 'Overall Subscription';
  
    loadData();
  }, []);
  

  const loadData = () => {
    setShowLoading(true);
    getUsersByCategory(category,searchText)
      .then((res) => {
        //alert("sdsdf"+JSON.stringify(res.employment))
        setCategory(res.category);

        
        const subscriptionDataList=res.subscription?res.subscription:null;
      
        setSubscriptionList(subscriptionDataList);

        const paymentList = [];
        for(let i=0;i<subscriptionDataList.length;i++){
          //alert("insideforloop"+JSON.stringify(subscriptionDataList[i]));
          paymentList.push(...subscriptionDataList[i].paymentList);
        }
       // alert("newpaymentlist"+JSON.stringify(newPaymentList));
        setPaymentList(paymentList);
        
        const transactionList = [];
        for(let i=0;i<paymentList.length;i++){
          if(paymentList[i].transactionDetailsList){
            transactionList.push(...paymentList[i].transactionDetailsList);
          }
        }
        //alert("newtransactionList"+JSON.stringify(newTransactionList));
        setTransactionList(transactionList);

       // setAlertMessage("Profile Data retrieved successfully");
        setAlertSeverity('success');
        
       
      })

      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image doesn't exist!", 'error');
        } else {
          //showAlert(error.message, 'error');
        }
        setShowLoading(false);
      });
  };
  return (
    <><Box sx={{ flexGrow: 1 ,display: 'block', width: '100%' }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} centered 
    >     
          <Tab label="Subscription Card" />
          <Tab label="Payment Card" />
          <Tab label="Transaction Card" />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SubscriptionTab subscriptionList={subscriptionList}  />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PaymentTab paymentList={paymentList}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TransactionTab transactionList={transactionList}/>
      </TabPanel>
     
    </Box><AlertComponent
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertMessage('')} /></>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default SubscriptionTabs;
