const DirectInternEn={

    title:"Launch Your Tech Career with Yaazhtech's Direct Intern Program!",
    titleExplain:"Are you a coding whiz with a passion for building innovative web experiences? Do you dream of turning lines of code into dynamic websites and applications? Then Yaazhtech's Direct Intern Program is the perfect launchpad for your tech career!",

    heading:"Your Gateway to the Tech World:",
   headingExplain:"Our innovative program offers a variety of exciting internship opportunities, catering to diverse interests and skill sets:",
   l1:"Dive into the world of web development, mastering the fundamentals of front-end and back-end technologies.",
   l2:"Unleash your creativity as a front-end intern(ReactJS), crafting user interfaces that are both beautiful and functional.",

   l3:"Get a taste of both front-end(ReactJS) and back-end development(JAVA), gaining a holistic understanding of the web development process.",
   l4:"Sharpen your skills in Java programming, building robust and scalable applications.",

   l5:"Mobile application development is bringing your ideas to life as functioning software on smartphones and tablets.",
   l6:"Learn the art of DevOps, ensuring seamless collaboration between development and operations teams.",

   l7:"A Python intern gains hands-on experience using Python to build and solve problems in a professional setting.",
   l8:"As a Go programming intern, you'll dive into building efficient and scalable software using Google's popular language.",

 beyond:"Beyond Coding: Exploring Diverse Paths:",
  beyondExp:"While our program focuses on coding-centric internships, we recognize the vast landscape of the tech industry! We also provide opportunities in:",
  l9:"Dive into the world of digital marketing, exploring avenues such as social media marketing, search engine optimization (SEO), and content marketing.  ",
  l10:"Learn the intricacies of Camunda, a leading open-source workflow engine, and gain valuable skills in process automation.",
  l11:"Assist HR with administrative tasks, recruitment, and gaining exposure to HR processes.",
  l12:"Ensure software applications function smoothly by testing communication channels (APIs).",
  l13:"Learn to manage and analyze data using Structured Query Language (SQL).",

  ready:"Don't wait for a traditional internship program to launch your tech career. Take charge of your future with Yaazhtech's Direct Intern Program.",

  passion:"Let's turn your passion into purpose!  Join the Yaazhtech family and embark on your exciting tech adventure today!",
  applyButton:"Apply Here",
  
  };
  export {DirectInternEn};