const StaffAugQaAndTestingEn = {
    title: "Yaazhtech: Your Trusted Partner for Quality Assurance and Testing Solutions",
    headingContent1:
      "In today's fast-paced digital world, ensuring the quality and functionality of software applications is crucial for success.",
    headingContent2:
      "Yaazhtech stands out as a leading provider of comprehensive QA & Testing solutions, empowering businesses to deliver exceptional software experiences. We specialize in API Testing and Automation Testing, offering a robust suite of services to streamline your development process and guarantee a seamless end-user experience.",
    
    unveiling: "Unveiling the Power of API Testing:",
    api: "APIs (Application Programming Interfaces) act as the backbone of modern software, facilitating communication between different applications and components.",
    apiTesting: "API Testing ensures that these crucial interfaces function as intended, delivering accurate and reliable data exchange.",
    yaazhtechExpert: "Yaazhtech's expert team leverages cutting-edge tools and methodologies to meticulously test your APIs, identifying and resolving any potential issues before they impact your application's performance.",

    unlocking: "Unlocking Efficiency with Automation Testing:",
    automationTesting1:"Automation Testing revolutionizes the testing process by automating repetitive tasks, significantly reducing testing time and effort.",
    automationTesting2:"Yaazhtech's skilled professionals create robust and customized automated test scripts, encompassing various functionalities and user scenarios.",
    comprehensive:"This comprehensive approach empowers you to:",
    enhanceTestingCoverage: "Enhance testing coverage: Thoroughly test various functionalities across different platforms and devices.",
    expediteTestingCycles: "Expedite testing cycles: Execute tests rapidly, enabling faster feedback loops and quicker development iterations.",
    reduceManualEffort: "Reduce manual effort: Free up your QA team to focus on more strategic testing initiatives.",
  
    YaazhtechYourPath: "Yaazhtech: Your Path to Quality Assurance Excellence:",
    byPartnering: "By partnering with Yaazhtech, you gain access to a team of QA & Testing specialists with extensive experience and expertise in various methodologies and tools. We cater to diverse project requirements, delivering tailored solutions that align seamlessly with your specific needs and objectives.",
    contact: (
      <span>
        <a href="/contact" style={{ color: 'blue', fontWeight: 'bold'}}>Contact Yaazhtech</a>
        {' '}
       today to discuss your unique testing challenges and explore how our comprehensive QA & Testing services can propel your software development endeavors to new heights.
      </span>
    ),
    button:"Hire a Coder",
    cancelButton:"Cancel",

  };

  export {StaffAugQaAndTestingEn};