import React, { useState, useEffect } from "react";
import { StateContext } from "../../../../../contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import { checkHealth } from '../../../../../api/AdminApi';
import {  Navigate } from "react-router-dom";

const Health = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [isNavBarCollapseOpen, setIsNavBarCollapseOpen] = useState(false);
  const [healthStatus, setHealthStatus] = useState({
    status: '',
    details: {},
  });
  const [showHealthLoading, setShowHealthLoading] = useState(false);


  useEffect(() => {
    const intervalId = setInterval(() => {
      checkApiHealth();
    }, 3000000);
  })

  useEffect(() => {
    checkApiHealth();
  }, []);

  const checkApiHealth = () => {
    setShowHealthLoading(true);

    checkHealth()
      .then((res) => {
        setHealthStatus(res.data);
        setShowHealthLoading(false);
        return <Navigate to="/dashboard" replace />;
      })
      .catch((error) => {
        props.showAlert(error.message, 'error');
        setShowHealthLoading(false);
      });
  };
  const status = [];
  let health; let diskSpace; let db; let mail; let dsUsage;

  if (healthStatus.status) {
    health = healthStatus.status;
    diskSpace = healthStatus.components.diskSpace.status;
    db = healthStatus.components.ping.status;
    mail = healthStatus.components.ping.status;
    dsUsage =
      ((healthStatus.components.diskSpace.details.total -
        healthStatus.components.diskSpace.details.free) /
        healthStatus.components.diskSpace.details.total) *
      100;
    dsUsage = Number(dsUsage.toFixed(2));

    if (health === 'UP') {
      status.push(<span key={1} className="badge badge-pill badge-success">API</span>);
    } else if (health === 'DOWN') {
      status.push(<span key={1} className="badge badge-pill badge-warning">API</span>);
    } else {
      status.push(<span key={1} className="badge badge-pill badge-danger">API</span>);
    }

    if (diskSpace === 'UP') {
      status.push(<span key={2} className="badge badge-pill badge-success">Disk Space</span>);
    } else if (diskSpace === 'DOWN') {
      status.push(<span key={2} className="badge badge-pill badge-warning">Disk Space</span>);
    } else {
      status.push(<span key={2} className="badge badge-pill badge-danger">Disk Space</span>);
    }

    if (db === 'UP') {
      status.push(<span key={3} className="badge badge-pill badge-success">Database</span>);
    } else if (db === 'DOWN') {
      status.push(<span key={3} className="badge badge-pill badge-warning">Database</span>);
    } else {
      status.push(<span key={3} className="badge badge-pill badge-danger">Database</span>);
    }

    if (mail === 'UP') {
      status.push(<span key={4} className="badge badge-pill badge-success">Emails</span>);
    } else if (mail === 'DOWN') {
      status.push(<span key={4} className="badge badge-pill badge-warning">Emails</span>);
    } else {
      status.push(<span key={4} className="badge badge-pill badge-danger">Emails</span>);
    }

    status.push(
      <div key={5} className="mt-2">
        <h6>
          Disk usage (
          {dsUsage}
          {' '}
          %)
        </h6>
        <div className="w-full bg-gray-200">
          <div
            className={`h-5 bg-info text-dark ${dsUsage === 100 ? 'bg-blue-500 rounded-r-lg' : 'bg-blue-500 rounded-full'}`}
            style={{ width: `${dsUsage}%` }}
            role="progressbar"
            aria-valuenow={dsUsage}
            aria-valuemin="0"
            aria-valuemax="100"
          />
         
        </div>
      </div>,
    );
  } else {
    status.push(<span key={1} className="badge badge-pill badge-danger">API</span>);
    status.push(<span key={2} className="badge badge-pill badge-danger">Disk Space</span>);
    status.push(<span key={3} className="badge badge-pill badge-danger">Database</span>);
    status.push(<span key={4} className="badge badge-pill badge-danger">Emails</span>);
  }


  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <h4 className="text-center font-bold">Api Health</h4>
        <hr />
        {showHealthLoading ? (
          <div className="align-content-center text-center">
            <h4 className="text-muted">Checking API Health</h4>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
          </div>
        ) : (
          <div className="mt-3">{status}</div>
        )}
      </div>
    </div>
  );
};

export default Health