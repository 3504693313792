import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { performanceUpdate, sprintPerformanceUpdate } from "../../../../../api/HrApi";
import PerformanceUpdateModal from "./PerformanceUpdateModal";

const LevelUpdate = ({ name, email }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);
  const currentMode = useContextSelector(StateContext, (state) => state.currentMode);

  const [performanceInput, setPerformanceInput] = useState({
    email: "",
    performanceLevel: "",
    comments: "",
    performanceType: ""
  });

  const [performanceRequest, setPerformanceRequest] = useState({
    email: "",
    evaluationYear: "",
    evaluationDateFrom: "",
    evaluationDateTo: "",
    technicalManagerComments: "Init",
    sprintFromDate: "",
    sprintToDate: "",
    rating: "",
    managementFeedback: "",
    technicalFeedback: "",
    employeeInput: "",
    performanceCategory: ""
  });

  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };

  const updateStateField = (field, value, stateUpdater) => {
    stateUpdater((prevState) => ({ ...prevState, [field]: value }));
  };

  const updatePerformanceType = (event) => {
    updateStateField("performanceType", event.target.value, setPerformanceInput);
  };
  
  const updatePerformanceCategory = (event) => {
    updateStateField("performanceCategory", event.target.value, setPerformanceRequest);
  };
  const updatePerformanceLevel = (event) => {
    updateStateField("performanceLevel", event.target.value, setPerformanceInput);
  };

  const updateComment = (event) => {
    updateStateField("comments", event.target.value, setPerformanceInput);
  };

  const updateSprintFromDate = (event) => {
    updateStateField("sprintFromDate", event.target.value, setPerformanceRequest);
  };

  const updateSprintToDate = (event) => {
    updateStateField("sprintToDate", event.target.value, setPerformanceRequest);
  };

  const updateManagementFeedback = (event) => {
    updateStateField("managementFeedback", event.target.value, setPerformanceRequest);
  };

  const updateTechnicalFeedback = (event) => {
    updateStateField("technicalFeedback", event.target.value, setPerformanceRequest);
  };

  const updateRating = (event) => {
    updateStateField("rating", event.target.value, setPerformanceRequest);
  };

  const updateEmployeeInput = (event) => {
    updateStateField("employeeInput", event.target.value, setPerformanceRequest);
  };

  const updateEvaluationYear= (event) => {
    updateStateField("evaluationYear", event.target.value, setPerformanceRequest);
  };

  const updateEvaluationDateFrom = (event) => {
    updateStateField("evaluationDateFrom", event.target.value, setPerformanceRequest);
  };
  const updateEvaluationDateTo = (event) => {
    updateStateField("evaluationDateTo", event.target.value, setPerformanceRequest);
  };

  const handleClickBack = () => {
    navigate('/');
  };

  const performanceUpdateSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);

    const req = { ...performanceInput, email };
    setPerformanceInput(req);

    const request = { ...performanceRequest, email };
    setPerformanceRequest(request);

    const apiCall = performanceInput.performanceType === "trainee"
      ? performanceUpdate(req)
      : sprintPerformanceUpdate(request);

    apiCall
      .then((res) => {
        toast.success(`Employee performance update request has been accepted! ${email}`);
        setShowLoading(false);
        setShowModal(false);
        setShowLoading(false);
        navigate('/interns');
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist!");
        } else {
          toast.error(error.message);
        }
        setShowModal(false);
        setShowLoading(false);
      });
  };

  return (
    <div>
      {showModal && (
        <>
        <PerformanceUpdateModal
            showModal={showModal}
            setShowModal={setShowModal}
            name={name}
            email={email}
            updatePerformanceType={updatePerformanceType}
            performanceInput={performanceInput}
            performanceRequest={performanceRequest}
            updatePerformanceLevel={updatePerformanceLevel}
            updateComment={updateComment}
            updateSprintFromDate={updateSprintFromDate}
            updateSprintToDate={updateSprintToDate}
            updateEvaluationFromDate={updateEvaluationDateFrom}
            updateEvaluationDateTo={updateEvaluationDateTo}
            updateEvaluationYear={updateEvaluationYear}
            updateRating={updateRating}
            updateManagementFeedback={updateManagementFeedback}
            updateTechnicalFeedback={updateTechnicalFeedback}
            updateEmployeeInput={updateEmployeeInput}
            currentColor={currentColor}
            performanceUpdateSubmit={performanceUpdateSubmit}
            updatePerformanceCategory={updatePerformanceCategory}
            // ... pass any other necessary props
          />
        </>
      )}

      {/* Toast Notification */}
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default LevelUpdate;
