import React from 'react';
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { ContractEn } from '../../../data/content/En/ContractEn';
import { ContractDe } from '../../../data/content/De/ContractDe';
import { Language } from '../../../config/Config';
import { Link } from 'react-router-dom';


const Contract = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );


 return (
    <div className="container mx-auto p-4">

      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header mx-4 md:mx-10">
           
          <button
                      className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                      style={{ backgroundColor: currentColor }}
                    ><Link to ="/directHire">
                      <span>{Language==='en'? ContractEn.button:ContractDe.button}</span></Link>
                    </button>
                    <br></br>
                    <br></br>

                    <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? ContractEn.header:ContractDe.header}</h1>
          
            <br></br>
            <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.bulidDream : ContractDe.bulidDream}</b>
            </p>
            <br></br>
            <p className="text-lg ml-4 md:ml-10">{Language === 'en' ? ContractEn.w1 : ContractDe.w1}</p>
            <br></br>
            <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.chooseYaazhtech : ContractDe.chooseYaazhtech}</b>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.s1 : ContractDe.s1}</li>
                <li>{Language === 'en' ? ContractEn.s2 : ContractDe.s2}</li>
                <li>{Language === 'en' ? ContractEn.s3 : ContractDe.s3}</li>
                <li>{Language === 'en' ? ContractEn.s4 : ContractDe.s4}</li>
                <li>{Language === 'en' ? ContractEn.s5 : ContractDe.s5}</li>
                <li>{Language === 'en' ? ContractEn.s6 : ContractDe.s6}</li>
                <br></br> 
                </ul>
                <p className="text-lg ml-4 md:ml-10">{Language === 'en' ? ContractEn.whatWe : ContractDe.whatWe}</p>
                <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.a1 : ContractDe.a1}</li>
                <li>{Language === 'en' ? ContractEn.a2 : ContractDe.a2}</li>
                <li>{Language === 'en' ? ContractEn.a3 : ContractDe.a3}</li>
                </ul>
            <br></br>
            <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.staffAugmentation : ContractDe.staffAugmentation}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.r1 : ContractDe.r1}</li>
                <li>{Language === 'en' ? ContractEn.r2 : ContractDe.r2}</li>
                <li>{Language === 'en' ? ContractEn.r3 : ContractDe.r3}</li>
                
                {/* ... (repeat for other list items) */}
              </ul>
              <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.immediateJoiners : ContractDe.immediateJoiners}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.t1 : ContractDe.t1}</li>
                <li>{Language === 'en' ? ContractEn.t2 : ContractDe.t2}</li>
                </ul>
                <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.flexibleContractOptions : ContractDe.flexibleContractOptions}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.e1 : ContractDe.e1}</li>
                <li>{Language === 'en' ? ContractEn.e2 : ContractDe.e2}</li>
                </ul>
                <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.projectDuration : ContractDe.projectDuration}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.y1 : ContractDe.y1}</li>
                <li>{Language === 'en' ? ContractEn.y2 : ContractDe.y2}</li>
                <li>{Language === 'en' ? ContractEn.y3 : ContractDe.y3}</li>
                
                </ul>
                <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.readyBuild : ContractDe.readyBuild}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.u1 : ContractDe.u1}</li>

                </ul>
                <p className="text-lg ml-4 md:ml-10">
              <b>{Language === 'en' ? ContractEn.buildSuccess : ContractDe.buildSuccess}</b></p>
              <ul className="list-disc ml-4 md:ml-10">
                <li>{Language === 'en' ? ContractEn.z1 : ContractDe.z1}</li>    
                </ul>
            </p>
            <br></br>
            {/* Repeat the pattern for other sections */}
          </div>
        </div>
      </div>
    </div>
  );
};


export default Contract;
