import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { devPricing } from "../../../data/content/PageContent";
import { Link } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from "../../../components/StaticButton/Button";

const DevSubscriptionPricingYear = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  return (
    <div className="mt-2 p-2 md:ml-6 md:mr-6 rounded-3xl">
      <div
        className={
          currentMode === "Dark"
            ? "rounded-3xl bg-white"
            : "rounded-3xl bg-white"
        }
      >
        <div
          className={
            currentMode === "Dark"
              ? "pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center rounded-3xl bg-[#ffffff]"
              : "pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center rounded-3xl bg-[#ffffff]"
          }
        >
          <h1 class="text-3xl font-bold " style={{ color: currentColor }}>
            Pricing
          </h1>
          <div class="sm:flex sm:items-center sm:justify-between">
            <div></div>

            <div class="overflow-hidden p-0.5 border rounded-lg dark:border-black-700">
              <div class="sm:-mx-0.5 flex"></div>
            </div>
          </div>
          <div className=" flex gap-3 justify-center">
          <Button text="Online" navigation="/devSubscriptionPricing"/>
          <Button text="Offline" />
          </div>
        </div>
        <section>
          <div class=" px-6 py-8 mx-auto">
            <div class="grid  mt-3  grid-cols-4 gap-4">
              <card
                className={
                  currentMode === "Dark"
                    ? "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
                    : "w-72 h-90 rounded-lg border shadow-md flex flex-col p-5 bg-[#f5f5f5]"
                }
              >
                 <p
                class="text-lg font-medium text-black-800 dark:text-black-100"
                style={{ color: currentColor, textAlign: "center" }}
              >
                <Link
                  to={{
                    pathname: "/register",
                  }}
                  state={{
                    subscriptionFor: devPricing.annumPremium,
                    subscriptionCost: devPricing.annumPremiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  }}
                  exact
                >
                   <Button text={devPricing.annumPremium}/>
                 
                </Link>
              </p>
                <h4 class="mt-2 text-4xl font-semibold text-black-800 dark:text-black-100">
                  ₹ {devPricing.annumPremiumCost}
                  <span class="text-base font-normal text-black-600 dark:text-black-400">
                    / Onetime + GST
                  </span>
                </h4>

                <div class="mt-3 space-y-2">
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>
                    <span class="mx-4 ">
                      {devPricing.annumPremiumDevAccess}
                    </span>
                  </div>
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>
                    <span class="mx-4 ">{devPricing.annumPremiumDevPages}</span>
                  </div>

                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>

                    <span class="mx-4 ">{devPricing.annumPremiumDevUsers}</span>
                  </div>
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>

                    <span class="mx-4 ">
                      {devPricing.annumPremiumDevUsersAdditional}
                    </span>
                  </div>

                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>
                    <span class="mx-4 ">
                      {devPricing.annumPremiumDevSecureOptions}
                    </span>
                  </div>

                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>

                    <span class="mx-4 ">
                      {devPricing.annumPremiumDevUsersDelete}
                    </span>
                  </div>
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>

                    <span class="mx-4 ">
                      {devPricing.annumPremiumDevSecureEmail}
                    </span>
                  </div>
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>

                    <span class="mx-4 ">{devPricing.annumPremiumDevSEO}</span>
                  </div>
                  <div class="flex items-center">
                    <icon>
                      {" "}
                      <AiOutlineArrowRight />
                    </icon>
                    <span class="mx-4 ">
                      {" "}
                      {devPricing.annumPremiumDevAdditional}
                    </span>
                  </div>
                </div>
                
              </card>
            </div>
          </div>
        </section>
      </div>
      <p class="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.header}
      </p>
      <small className="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.taskEstimation}
      </small>
      <small className="mt-4 text-black-500 dark:text-black-400 font-bold">
        {devPricing.noCards}
      </small>
    </div>
  );
};
export default DevSubscriptionPricingYear;
