import React from 'react'
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { MobileFriendlyEn } from '../../../data/content/En/MobileFriendlyEn';
import { MobileFriendlyDe } from '../../../data/content/De/MobileFriendlyDe';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { TrainAndHireEn } from '../../../data/content/En/TrainAndHireEn';
import { TrainAndHireDe } from '../../../data/content/De/TrainAndHireDe';

const TrainHireDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    <div className="container">
   
    <div className="main contact-margin-top" id="contact">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/trainingRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor ,
                  subscriptionType:subscriptionType ,
                }}
                
              > <span>{Language==='en'? TrainAndHireEn.button:TrainAndHireDe.button}</span>
               </Link>
              </button>
                <br></br>
                <br></br>
                <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'?TrainAndHireEn.welcome:TrainAndHireDe.welcome}</h1>
         <br></br>
         <p className="pages-content-text text-lg ml-4 md:ml-10">
                {Language==='en'? TrainAndHireEn.w1:TrainAndHireDe.w1}
                {' '}
                 </p><br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'?TrainAndHireEn.training:TrainAndHireDe.training}
                {' '}</b>
                 </p>
                 <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.t1:TrainAndHireDe.t1}
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?TrainAndHireEn.key:TrainAndHireDe.key}</b>
                  
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.k1:TrainAndHireDe.k1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'?TrainAndHireEn.k2:TrainAndHireDe.k2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.k3:TrainAndHireDe.k3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.k4:TrainAndHireDe.k4}</li>

                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?TrainAndHireEn.why:TrainAndHireDe.why}</b>
                  
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.f1:TrainAndHireDe.f1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'?TrainAndHireEn.f2:TrainAndHireDe.f2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.f3:TrainAndHireDe.f3}</li>

                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'?TrainAndHireEn.yaazh:TrainAndHireDe.yaazh}
                {' '}</b>
                 </p>
                 <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.y1:TrainAndHireDe.y1}
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?TrainAndHireEn.our:TrainAndHireDe.our}</b>
                  
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.o1:TrainAndHireDe.o1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'?TrainAndHireEn.o2:TrainAndHireDe.o2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.o3:TrainAndHireDe.o3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.o4:TrainAndHireDe.o4}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.o5:TrainAndHireDe.o5}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'?TrainAndHireEn.partner:TrainAndHireDe.partner}</b>
                  
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.p1:TrainAndHireDe.p1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'?TrainAndHireEn.p2:TrainAndHireDe.p2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.p3:TrainAndHireDe.p3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? TrainAndHireEn.p4:TrainAndHireDe.p4}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'?TrainAndHireEn.ready:TrainAndHireDe.ready}
                {' '}</b>
                </p>
                <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'?TrainAndHireEn.contact:TrainAndHireDe.contact}
                {' '}</b>
                </p><br></br>
                <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/trainingRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor ,
                  subscriptionType:subscriptionType ,
                }}
                
              > <span>{Language==='en'? TrainAndHireEn.button:TrainAndHireDe.button}</span>
               
              </Link>
              </button>
              <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/trainingAndHiring",
                }}
                
              > <span>{Language==='en'? TrainAndHireEn.cancelButton:TrainAndHireDe.cancelButton}</span>
               
              </Link>
              </button>
                 
              </div>
              </div>
                </div>
                </div>
                
                
  );
}

export default TrainHireDoc