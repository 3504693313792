import React, { useState } from "react";
import { candidateOnboardRequest } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";

const OnboardEmployee = ({ name, email }) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [onboardRequest, setOnboardRequest] = useState({
    email: "",
    officialEmail: "",
    employmentStatus: "Employment Status",
    joiningDate: "",
    designation: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const updateEmploymentStatus = (event) => {
    setOnboardRequest({ ...onboardRequest, employmentStatus: event.target.value });
  };
  const updateJoiningDate = (event) => {
    setOnboardRequest({ ...onboardRequest, joiningDate: event.target.value });
  };
  const updateDesignation = (event) => {
    setOnboardRequest({ ...onboardRequest, designation: event.target.value });
  };
  const candidateOnboard = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const usernameOrEmail = email;
    let req = onboardRequest;
    req.email = usernameOrEmail;
    candidateOnboardRequest(onboardRequest)
    .then((res) => {
        toast.success(res.data.message);
        toast.success( "Employee onboard request has been accepted !" + onboardRequest.email);
        setShowLoading(false);
        setShowModal(false);
    })
    .catch((error) => {
      if (error.status === 401) {
        toast.error("Your username or email does not exist !");
      } else {
        toast.error(error.message);
      }
      setShowLoading(false);
      setShowModal(false);
    });
  };

  return (
    <div>
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                Applicants Status change
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + " { " + email + " }"}
                  </h3>
                  <form onSubmit={candidateOnboard}>
                    <div className="mt-2">
                      <div className="form-group">
                        <label htmlFor="employmentStatus" className="font-bold"> Employment Status</label>
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          //focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"

                          // className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name="option"
                          value={onboardRequest.employmentStatus}
                          onChange={(event) => updateEmploymentStatus(event)}
                        >
                          <option value="Employment Status">Employment Status</option>
                    <option value="intern">INTERN</option>
                    <option value="trainee">TRAINEE</option>
                    <option value="employee">EMPLOYEE</option>
                    <option value="rejected">REJECTED</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputDate" className="font-bold">Joining Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="joiningDate"
                          name="joiningDate"
                          placeholder="actionDate"
                          required
                          autoComplete="on"
                          onChange={updateJoiningDate}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputDesignation" className="font-bold">Designation</label>
                        <br />
                        <input
                          type="text"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="Designation"
                          name="Designation"
                          placeholder="Designation"
                          required
                          autoComplete="on"
                          onChange={updateDesignation}
                        />
                      </div>
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Onboard
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default OnboardEmployee;
