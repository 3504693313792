import React, { useState, useEffect } from "react";
import { ACCESS_TOKEN } from "../../../../../config/Config";
import { submitResult } from "../../../../../api/AssessmentApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress, Dialog, Typography } from '@mui/material';

const StudentAnswer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {assessmentShortAnswers, student } = location.state||{};
  
  const [assessmentResultRequest, setAssessmentResultRequest] = useState({
    email: "",
    score: "",
    result: "",
    testId: "",
    assessmentShortAnswers: {},
  });
  const [showModalUserInfo, setShowModalUserInfo] = useState(false);
  const [alertIsVisible, setAlertIsVisible] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [answerList, setAnswerList] = useState([{}]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(""); 
  
  
  
  useEffect(() => {
    console.log("DATA 2:", assessmentShortAnswers);
    console.log("Student Data:", student);
    if (assessmentShortAnswers) {
      setAssessmentResultRequest((prev) => ({
        ...prev,
        assessmentShortAnswers,
        email: student.studentMailId,
        testId: student.testId // Ensure this is set
      }));
    }
    onShowAlert();
  }, [assessmentShortAnswers]);

  const onShowAlert = () => {
    setAlertIsVisible(true);
    setTimeout(() => {
      setAlertIsVisible(false);
    }, 90000);
  };

  const updateRetrieveAssessmentData = () => {
   alert("data1 :" + JSON.stringify(assessment));
   
   
    if (!assessment || !assessment.students) {
      console.error("Assessment or questionPaper is undefined");
      return;
    }
  const students = assessment.students;
    const req = {
      ...assessmentResultRequest,
      assessmentShortAnswers: students.assessmentShortAnswers ,
    };

    setAssessmentResultRequest(req);
    setShowLoading(false);
    };

  

  const backToHome = () => {
   navigate("/trackAssessment");
  };

  const submitResultRequest = (event) => {
    event.preventDefault();
    console.log("Submitting result request:", assessmentResultRequest);
    const req = { ...assessmentResultRequest, 
      email: student.studentMailId, 
      testId: student.testId };
      if (!req.testId) {
        console.error("testId is missing");
        return; // Optionally show an error message to the user
      }
    setShowLoading(true);
    submitResult(req)
      .then((res) => {
        setAlertMessage("Candidate result updated!");
        setAlertType("success");
        setShowLoading(false);
        setShowModalUserInfo(false);
        backToHome();
      })
      .catch((error) => {
        setAlertMessage(error.message || "An error occurred.");
        setAlertType("error");
        setShowLoading(false);
      });
  };

  const toggle = () => {
    setShowModalUserInfo(!showModalUserInfo);
  };

  const updateScore = (event) => {
    setAssessmentResultRequest({ ...assessmentResultRequest, score: event.target.value });
  };

  const updateResult = (event) => {
    setAssessmentResultRequest({ ...assessmentResultRequest, result: event.target.value });
  };

  const renderQuestionsAndAnswers = () => {
    const {  assessmentShortAnswers } = assessmentResultRequest;

  
    return (
      <div>
        {assessmentShortAnswers && Object.keys(assessmentShortAnswers).length ? (
          Object.entries(assessmentShortAnswers).map(([key, value], index) => {
            const [question, answer] = value.split(":::::");
            return (
              <div key={index} className="question-container" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                <Typography variant="body1"><strong>Q:</strong> {question}</Typography>
                <Typography variant="body2"><strong>A:</strong> {answer}</Typography>
              </div>
            );
          })
        ) : (
          <Typography variant="body2">No short answer questions available.</Typography>
        )}
      </div>
    );
  };


  return (
    <>
       
 <div className="container mx-auto">
 <div className="flex items-center justify-between mb-4">
        <Typography variant="h6">STUDENT Answers</Typography>
        <Button variant="contained" color="primary" onClick={toggle}>
         UPDATE THE RESULT
        </Button>
        <Button variant="outlined" onClick={backToHome}>Back</Button>

        </div>
      {assessmentResultRequest.showLoading ? (
        <CircularProgress />
      ) : (
        <>
          {renderQuestionsAndAnswers()}
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
            crossorigin="anonymous"
          ></script>
        </>
      )}
    </div>
        <Dialog open={showModalUserInfo} onClose={toggle}>
            <div className="align-content-center align-items-center align-self-center text-center">
                {student && (
                    <>
                        <h2>{student.studentName} ({student.studentMailId})</h2>
                        <form onSubmit={ submitResultRequest}>
                            <div>
                                <p className="card-title text-center">Candidate Score Update</p>
                                <div className="mt-2">
                                    <TextField
                                        id="inputScore"
                                        label="Score"
                                        type="text"
                                        variant="outlined"
                                        placeholder="Score"
                                        required
                                        onChange={updateScore}
                                        autoComplete="on"
                                    />
                                </div>
                                <div className="mt-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="result-label">Overall Result</InputLabel>
                                        <Select
                                            labelId="result-label"
                                            id="result"
                                            value={assessmentResultRequest.result}
                                            onChange={updateResult}
                                        >
                                            <MenuItem value="pass">pass</MenuItem>
                                            <MenuItem value="failed">failed</MenuItem>
                                            <MenuItem value="re-take">re-take</MenuItem>
                                            <MenuItem value="in-progress">in-progress</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <br />
                                <Button variant="outlined" color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    Update Result
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </Dialog>
    </>
);
};

export default StudentAnswer;
 