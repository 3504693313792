import { Link } from "react-router-dom";

// Component to render individual links and sub-links in a responsive grid layout
const LinkComponent = ({
  link,
  currentColor,
  activeLink,
  normalLink,
  handleActive,
}) => (
  <li className="w-full">
    <Link
      to={`/${link.url}`}
      onClick={handleActive}
      className="block capitalize text-lg md:text-xl lg:text-2xl font-semibold text-black hover:text-gray-900 transition duration-200"
    >
      {link.name}
    </Link>

    {/* Render sub-links if available */}
    {link.subMenuLinks && (
      <ul className="grid grid-cols-1 gap-2 mt-2 ml-4">
        {link.subMenuLinks.map((subLink) => (
          <li key={subLink.name} className="w-full">
            <Link
              to={`/${subLink.url}`}
              onClick={handleActive}
              className="block capitalize text-md md:text-lg  font-semibold hover:text-gray-900 transition duration-200"
            >
              {subLink.name}
            </Link>
          </li>
        ))}
      </ul>
    )}
  </li>
);

// Main component to map and display user links in a responsive grid layout
const UserLinksComponent = ({
  userLinks,
  currentColor,
  activeLink,
  normalLink,
  handleActive,
}) => {
  return (
    <div className="w-full p-4 rounded-lg">
      {userLinks.map((section) => (
        <div key={section.title} className="mb-8">
          {/* <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            {section.title}
          </h2> */}

          {/* Responsive grid for links */}
          <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {section.links.map((link) => (
              <LinkComponent
                key={link.name}
                link={link}
                handleActive={handleActive}
                currentColor={currentColor}
                activeLink={activeLink}
                normalLink={normalLink}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default UserLinksComponent;
