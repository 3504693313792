import React from 'react';

const JobCategories = () => {
  return (
    <section id="job-categories" className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-gray-800">Job Categories</h2>
        <p className="mt-4 text-lg text-gray-600">Find the perfect role in your area of expertise.</p>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Add job category cards */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Engineering</h3>
            <p className="mt-4 text-gray-600">Join our innovative engineering team.</p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Sales</h3>
            <p className="mt-4 text-gray-600">Help us reach more customers.</p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Marketing</h3>
            <p className="mt-4 text-gray-600">Drive our brand's success.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobCategories;
