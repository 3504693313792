import { platformAI, platformWhat } from "../assets/images";

export const links = [
  {
    name: "Products",
    submenu: true,
    sublinks: [
      {
        Head: "Software",
        sublink: [
          {
            name: "Ponmugil Game Application",
            link: "/",
            content: "Download now and dive into a world of endless fun and excitement!",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "FoodFly App",
            link: "https://foodfly.yaazhtech.com/",
            content: "Order your favorite dishes and enjoy quick, reliable delivery right to your doorstep!",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "Finance App",
            link: "https://finance.yaazhtech.com/",
            content: "Track expenses, plan budgets, and achieve your financial goals with confidence.",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "Education App",
            link: "https://edu.yaazhtech.com/",
            content: "Access quality courses, interactive lessons, and achieve your academic goals seamlessly.",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "VisualGlance App",
            link: "/",
            content: "Capture, edit, and share stunning visuals that bring your creativity to life.",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "Jewellery App",
            link: "https://www.gvggroupofcompanies.com/",
            content: "Find exquisite pieces that blend tradition with modern elegance, perfect for every occasion.",
            blank: "_blank",
            rel: "noopener noreferrer",
          },
        ],
      },
      {
        Head: "Solutions",
        sublink: [
          { name: "Staff Augmentation", link: "/staffAugmentation" },
          { name: "Get Your free Web App", link: "/mobileFriendly" },
          { name: "SEO Configuration", link: "/seoConfiguration" },
          { name: "Free poc/mvc", link: "/transformIdeas" },
        ],
      },
    ],
  },
  {
    name: "Platform",
    submenu: true,
    sublinks: [
      {
        Head: "Software Platform",
        sublink: [
          { name: "Overview", link: "/" },
          { name: "App Marketplace", link: "/" },
          { name: "Integrations", link: "/" },
          { name: "Developers", link: "/" },
          { name: "AI Capabilities", link: "/" },
          { name: "Technical Alliances", link: "/" },
          { name: "Latest Innovations", link: "/" },
        ],
      },
      {
        Head: "What's New",
        sublink: [
          {
            name: "Experienced AI Power Customer Support",
            link: "/",
            content: "Omnichannel, AI-powered support that's easy to implement, use, and scale.",
            image: platformAI,
          },
          {
            name: "We Have Opened a New Data Center in Chennai",
            link: "/",
            content: "Yaazhtech work data center in South India.",
            image: platformWhat,
          },
        ],
      },
    ],
  },
  {
    name: "Resources",
    submenu: true,
    sublinks: [
      {
        Head: "Customers",
        Head1: "Company",
        sublink: [
          { name: "Customer Stories", link: "/" },
          { name: "About", link: "/about" },
          { name: "Community", link: "/" },
          { name: "Leadership", link: "/leadership" },
          { name: "Careers", link: "/careers" },
          { name: "Services", link: "/" },
          { name: "Partners", link: "/" },
          { name: "Support", link: "/" },
          { name: "Insight Trends", link: "/" },
          { name: "Teams", link: "/team" },
        ],
      },
      {
        Head: "Trending",
        sublink: [
          {
            name: "Fresh Insights for Leaders",
            link: "/",
            content: "Original research and analysis from leading industry experts.",
            video: platformAI,
          },
          {
            name: "More Impact, Less Stress",
            link: "/",
            content: "See how IT, Customer Service succeed with Yaazhtechworks.",
            video: platformWhat,
          },
        ],
      },
    ],
  },
  {
    name: "Joinus",
    submenu: true,
    sublinks: [
      {
        Head: "Service Platform",
        sublink: [
          { name: "Staff Augmentation", link: "/staffAugmentation" },
          { name: "Get Your free Web App", link: "/mobileFriendly" },
          { name: "SEO Configuration", link: "/seoConfiguration" },
          { name: "Free poc/mvc", link: "/transformIdeas" },
        ],
      },
      {
        Head: "Careers Platform",
        sublink: [
          { name: "Train Hire", link: "/trainingAndHiring" },
          { name: "Direct Hire", link: "/directHiringMain" },
          { name: "Direct Intern", link: "/directInternMain" },
          { name: "Training For Experienced ", link: "/trainingForExperiencedMain" },
        ],
      },
     
    ],
  },
  
];
