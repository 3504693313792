import { useState, useEffect, useRef } from "react";
import {
  Button,
  TextareaAutosize,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box,
} from "@mui/material";
import { submitAnswers } from "../../../../../api/AssessmentApi";
import { useNavigate } from "react-router-dom";

const TimeCounter = (props) => {
  const Ref = useRef(null);
  const navigate = useNavigate();
  const [assessmentRequest, setAssessmentRequest] = useState({
    alertIsVisible: true,
    imagePreviewUrl: "",
    user: "",
    showLoading: false,
    questions: [],
    mcqQuestions: [],
    userName: "",
    counter: 0,
    textIdx: 0,
    minutesPerQuestion: 0,
    answerList: [],
    questionAnswerList: [],
    testId: "",
    choices: [],
    fileType: "",
  });

  const [timer, setTimer] = useState("00:00:00");
  const [counter, setCounter] = useState(0);
  const [timeCounter, setTimeCounter] = useState(
    Math.ceil(props.minutesPerQuestion) * 60
  );
  const [testId, setTestId] = useState("");
  const [answer, setAnswer] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [fileType, setFileType] = useState("excel");
  const fileInputRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );

      if (counter === props.noOfQuestions) {
        clearInterval(Ref.current);
      } else {
        setTimeCounter((prevTimeCounter) => prevTimeCounter + 1);
      }
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:00");
    setTimeCounter(0);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + props.minutesPerQuestion * 60);
    // console.log("Deadline:",deadline)
    return deadline;
  };

  useEffect(() => {
    setTestId(props.testId);
    setAnswer(props.questionAnswer);
    setAssessmentRequest((prevState) => ({
      ...prevState,
      testId: props.testId,
      answerList: props.answerList,
    }));
  }, [props.testId, props.answerList]);

  const onClickReset = () => {
    const deadTime = getDeadTime();
    clearTimer(deadTime);

    setCounter((prevCounter) => prevCounter + 1);
    props.handleAnswerAddClick();
  };

  const onClickSubmitAnswers = () => {
    // Get the latest assessment request data
    // console.log("Submitting with questionAnswerList:", assessmentRequest.questionAnswerList);
    const latestAssessmentRequest = {
      ...assessmentRequest,

      testId: testId, // Use the latest testId state
    };

    alert("data :" + JSON.stringify(latestAssessmentRequest));
    setAssessmentRequest({ ...latestAssessmentRequest, showLoading: true });

    const assessmentSubmitAnswerRequest = {
      answers: latestAssessmentRequest.questionAnswerList.filter(
        (el) => Object.keys(el).length
      ),
      testId: latestAssessmentRequest.testId,
    };

    submitAnswers(assessmentSubmitAnswerRequest)
      .then((res) => {
        showAlert(
          "Your answers saved, You can check your score in assessment dashboard.",
          "success"
        );
        setAssessmentRequest({
          ...latestAssessmentRequest,
          showLoading: false,
        });
        navigate("/assessments");
      })
      .catch((error) => {
        if (error.status === 401) {
          showAlert("Your username or email does not exist !", "error");
        } else {
          showAlert(error.message, "error");
        }
        setAssessmentRequest({
          ...latestAssessmentRequest,
          showLoading: false,
        });
      });
  };

  const showAlert = (message, type) => {};

  const backToHome = () => {
    navigate("/assessments");
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
    };
    reader.readAsText(file);
  };

  return (
    <>
      <br />
      <br />
      <div className="container mx-auto">
        <Typography variant="h4">Welcome to online assessment.</Typography>
        <Typography variant="body2">
          Total duration of the test:{" "}
          <span className="text-gray-500">
            {Math.ceil(props.minutesPerQuestion) * props.noOfQuestions} mins
          </span>{" "}
          <br /> Total no of Questions:{" "}
          <span className="text-gray-500">{props.noOfQuestions}</span>
        </Typography>
        <div className="bg-gray-200 p-4 rounded-lg">
          <Typography variant="h6" className="text-primary font-bold mb-3">
            Timer: {timer}
          </Typography>
          <div>
            {counter > 0 && counter <= props.noOfQuestions ? (
              <form>
                <div className="form-group">
                  <Typography variant="body1" className="mb-2">
                    {props.questions[counter - 1]}
                  </Typography>
                  {/* {props.fileType === "excel" ? (  */}

                  <TextareaAutosize
                    style={{ width: "100%", minHeight: "200px" }}
                    className="w-full h-40 p-2 resize-none border rounded-lg"
                    placeholder="Please type your answer here."
                    required
                    autoComplete="on"
                    value={props.answerList[counter]}
                    onChange={(e) =>
                      props.handleAnswerList(
                        e,
                        counter,
                        props.questions[counter - 1]
                      )
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      props.showAlert(
                        "Paste is restricted, if you do this you may fail in this assessment.",
                        "error"
                      );
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      props.showAlert(
                        "Copy is restricted, if you do this you may fail in this assessment.",
                        "error"
                      );
                      return false;
                    }}
                  />

                  <Typography variant="body2" className="text-gray-500 mt-2">
                    *Your answers will be auto saved and next question will
                    appear immediately when minutes per question time expires.
                    <br />
                    *Please start typing your answer and wait until time to
                    expire to see the next question.
                    <br />
                    *Your moves are tracked, if you change the screen you may
                    get disqualified and it may stop working.
                  </Typography>
                  {timeCounter === Math.ceil(props.minutesPerQuestion) * 60
                    ? onClickReset()
                    : null}
                </div>
              </form>
            ) : counter === 0 ? (
              <>
                <Typography variant="body2" className="text-gray-500">
                  Click start to begin the online test.
                </Typography>
                <Box mt={2} mb={2} display="flex" gap={2}>
                  <Button
                    variant="contained"
                    onClick={onClickReset}
                    className=""
                  >
                    Start
                  </Button>
                  <Button variant="outlined" onClick={backToHome}>
                    Back
                  </Button>
                </Box>
                <Typography variant="body2" className="text-gray-500 mt-2">
                  <strong>Tips:</strong>
                  <br />
                  The online coding/theory/communication test itself is{" "}
                  {props.noOfQuestions *
                    Math.ceil(props.minutesPerQuestion)}{" "}
                  minutes.
                  <br />
                  *You cannot stop a section once you’ve begun, so I encourage
                  you to be in a quiet spot ahead of time where you can focus
                  without distraction.
                  <br />
                  *If you're not able to complete the assessment by the due
                  date, just let our HR know and we will extend the link for
                  you.
                </Typography>
                <Typography variant="body2" className="text-gray-500 mt-2">
                  <strong>Note:</strong>
                  <br />
                  You can use any of the following languages you’re comfortable
                  with: C#, C, C++, Java, Python, Ruby, or JavaScript.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" className="text-gray-500">
                  Time's up! To save your answers, please click submit.
                </Typography>
                <Button
                  variant="contained"
                  onClick={props.onClickSubmitAnswers}
                  className="mt-2"
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeCounter;

