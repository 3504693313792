const StaffAugSoftwareDevelopementEn = {
    title: "Yaazhtech: Your one-stop shop for cutting-edge software development",
    headingContent:
      "Yaazhtech is a leading software development company specializing in crafting innovative solutions that empower businesses to thrive in today's dynamic digital landscape. Our team of passionate and skilled developers possesses extensive expertise in various domains, enabling us to deliver projects tailored to your specific needs and goals.",
    
    unLeashing: "Unleashing the Power of Technology Through Diverse Expertise:",
    frontEndDevelopers: "Front End Developers - ReactJS:",
    f1: "Leveraging the versatility and performance of ReactJS, our dedicated ReactJS developers create interactive and visually stunning user interfaces that bring your ideas to life. Their expertise in building single-page applications (SPAs) ensures a seamless and engaging user experience for your customers.",
    fullStackDevelopers: "Full-Stack Developers with Java and ReactJS:",
    full1: "Our full-stack developers, proficient in both Java and ReactJS, possess the exceptional ability to seamlessly integrate the front-end and back-end functionalities of your application.",
    javaBackendDevelopers: "Java Backend Developers:", 
    back: "Our Java backend developers possess in-depth knowledge of building scalable and secure back-end systems. They leverage their expertise in Java frameworks and libraries to develop robust APIs, efficient data management solutions, and reliable server-side logic, forming the solid foundation of your application.",
    camundaDevelopers: "Camunda Developers: ",
    cam: "Our adept developers leverage the power of Camunda, a leading process automation platform, to streamline your workflows and optimize operational efficiency.",
    mobileAppDevelopers: "Mobile App Developers: ",
    mob1: "We bring your ideas to life by creating intuitive and engaging mobile applications that cater to diverse platforms and user needs.",
    devOpsEngineers: "DevOps Engineers:",
    dev1: "We bridge the gap between development and operations, ensuring seamless deployments, continuous integration, and reliable infrastructure management.",
    digitalMarketingExperts: "Digital Marketing Experts: ",
    digital: "We help you navigate the ever-evolving digital marketing landscape by crafting effective strategies that enhance brand awareness, drive customer engagement, and achieve measurable results.",
    pythonDevelopers: "Python Developers: ",
    python: "Our Python developers harness the versatility and power of this widely-used programming language to build robust, scalable, and maintainable software solutions.",
    goLangDevelopers: "GoLang Developers: ",
    golang: "We leverage the speed, efficiency, and concurrency features of GoLang to create high-performance applications that meet the demands of modern web development.",

    conclusion:"At Yaazhtech, we are committed to fostering a collaborative and results-oriented environment where we work closely with our clients to understand their unique challenges and aspirations. We translate your vision into reality by delivering exceptional software solutions that empower you to achieve your strategic objectives.",
    
    
    button:"Hire a Coder",
    cancelButton:"Cancel",

  };

  export {StaffAugSoftwareDevelopementEn};