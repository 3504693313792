import React, { useState } from "react";
import { valueDesign, valueDesign1, valueDesign2, valueDesign3, valueDesign4 } from "../../../../assets/images";

const TabsComponent = () => {
  const tabs = [
    { 
      name: "We Drive Results", 
      content: "Success doesn’t just happen. Our steady, strong growth comes from a set of principles that guide us - all centered around continuously finding better, more human ways to work. We take pride in our ability to adapt and evolve, ensuring that our strategies are always aligned with the latest trends and best practices.",
      image: valueDesign,
      buttonLabel: "Learn More"
    },
    { 
      name: "We Collaborate", 
      content: "Collaboration is at the heart of everything we do. We work together across teams and departments to drive success. Our open-door policy fosters communication and teamwork, allowing us to overcome challenges and achieve shared goals.",
      image: valueDesign1,
      buttonLabel: "Join Us"
    },
    { 
      name: "We Innovate", 
      content: "Innovation is a core principle for us. We constantly seek new ways to solve problems and improve our products and services. Our commitment to innovation drives us to explore new ideas, embrace change, and deliver cutting-edge solutions.",
      image: valueDesign2,
      buttonLabel: "Discover More"
    },
    { 
      name: "We Focus on Our Customers", 
      content: "Our customers are the center of our universe. We listen to them and tailor our solutions to meet their needs. By understanding their challenges, we create products and services that truly make a difference.",
      image: valueDesign3,
      buttonLabel: "Customer Stories"
    },
    { 
      name: "We Value Differences", 
      content: "Diversity drives innovation and success. We value and celebrate the differences that each team member brings to the table. Our inclusive culture ensures that every voice is heard, and every perspective is considered.",
      image: valueDesign4,
      buttonLabel: "Our Values"
    }
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="max-w-6xl mx-auto my-8 px-4 sm:px-6 lg:px-8">
      {/* Tab Headings */}
      <div className="flex flex-col md:flex-row border-b border-gray-300">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`text-left md:text-center px-2 py-3 mx-3 text-base md:text-lg font-medium focus:outline-none transition-colors duration-200 ${
              activeTab.name === tab.name
                ? "text-blue-600 border-blue-600 border-b-2 md:border-b-0 md:border-l-2 md:border-r-2"
                : "text-gray-600 hover:text-blue-500"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {/* Tab Content and Image */}
      <div className="mt-6 flex flex-col-reverse md:flex-row md:space-x-8 transition-all duration-500 ease-in-out transform">
        <div className="md:w-1/2 flex flex-col justify-center md:pr-8">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">{activeTab.name}</h2>
          <p className="text-gray-600 mb-4">{activeTab.content}</p>
          <button
            className="mt-2 px-4 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none transition-colors duration-200"
            onClick={() => alert(`${activeTab.buttonLabel} clicked`)}
          >
            {activeTab.buttonLabel}
          </button>
        </div>
        <div className="md:w-1/2 flex items-center justify-center md:justify-end">
          <img 
            src={activeTab.image} 
            alt={activeTab.name} 
            className="w-full h-full max-h-64 md:max-h-80 object-cover rounded-lg transition-transform duration-500 ease-in-out transform hover:scale-105" 
          />
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
