import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createTaskOrSubscription } from "../../../api/FinanceApi";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { DEVELOPERLIST } from "../../../config/Config";

const AddProjectOrTaskSubscription = ({
  selectedOrg,
  selectedProject,
  featureName,
  setOpenDialog,
}) => {
  const navigate = useNavigate();
  //console.log("featureName" + featureName);
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );

  const [subscriptionOrTask, setSubscriptionOrTask] = useState({
    featureName: featureName,
    orgName: selectedOrg,
    projectOrTask: "",
    taskDetails: "",
    subscriptionType: "",
    invoiceType: "",
    isNewSubscription: "Yes",
    totalBillingDay: "",
    startDate: "",
    endDate: "",
    assignedTo: "",
    priority: "",
  });

  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const developerDetailList = JSON.parse(localStorage.getItem(DEVELOPERLIST));

  useEffect(() => {
    document.title = "Add Project or Task";
    updateDataFromSubscription();
  }, []);

  const updateDataFromSubscription = () => {
    let req = subscriptionOrTask;
    setSubscriptionOrTask(req);
  };
  const updateIsNewSubscription = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      isNewSubscription: event.target.value,
    });
  };
  const updatePaymentBillingTo = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      paymentBillingTo: event.target.value,
    });
  };

  const updateStartDate = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      startDate: event.target.value,
    });
  };
  const updateEndDate = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      endDate: event.target.value,
    });
  };
  const updateTaskType = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      invoiceType: event.target.value,
    });
  };
  const updateSubscriptionType = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      subscriptionType: event.target.value,
    });
  };
  const updateProjectOrTask = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      projectOrTask: event.target.value,
    });
  };

  const updateTaskDetails = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      taskDetails: event.target.value,
    });
  };
  const handleAssignChange = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      assignedTo: event.target.value,
    });
  };
  const handlePriorityChange = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      priority: event.target.value,
    });
  };

  const updateAcceptanceCriteria = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      acceptanceCriteria: event.target.value,
    });
  };

  const updateTotalBillingDay = (event) => {
    setSubscriptionOrTask({
      ...subscriptionOrTask,
      totalBillingDay: event.target.value,
    });
  };

  const handleAddProjectOrTask = (event) => {
    event.preventDefault();
    setShowLoading(true);

    let req = subscriptionOrTask;
    setSubscriptionOrTask(req);

    createTaskOrSubscription(subscriptionOrTask)
      .then((res) => {
        toast.success(res.data.message);
        resetSubscriptionForm();
        setShowLoading(false);
        //setShowModal(false);
      })
      .catch((error) => {
        handleSubscriptionError(error);
      });
  };

  const resetSubscriptionForm = () => {
    setSubscriptionOrTask({
      project: selectedProject,
      paymentId: "",
      featureName: featureName,
      orgName: selectedOrg,
      projectOrTask: "",
      taskDetails: "",
      subscriptionType: "",
      invoiceType: "",
    });
  };

  const handleSubscriptionError = (error) => {
    if (error.status === 401) {
      toast.error("Your username or email does not exist. Please try again!");
    } else {
      toast.error(error.message);
    }
    setShowLoading(false);
  };
  const handleCancel = () => {
    setOpenDialog(false);
    setShowModal(false);
    // navigate("/"); // Replace with your actual project list route
    //navigate("/kanban");
  };

  return (
    <>
      {showModal && (
        <Dialog open={true} fullWidth maxWidth="xs">
          <DialogTitle>
            {subscriptionOrTask.projectOrTask === "createProject" ? (
              <>
                <span>Add Subscription (Developer):</span>
                <DialogContent>
                  <form onSubmit={handleAddProjectOrTask}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="projectOrTask">
                            Select
                          </InputLabel>
                          <Select
                            id="projectOrTask"
                            value={subscriptionOrTask.projectOrTask}
                            onChange={(event) => updateProjectOrTask(event)}
                          >
                            <MenuItem value="Select"></MenuItem>
                            <MenuItem value="createProject">
                              Add Subscription (Developer)
                            </MenuItem>
                            <MenuItem value="createTask">Add Tasks </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {subscriptionOrTask.projectOrTask === "createProject" && (
                        <>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="subscriptionType">
                                New Subscription
                              </InputLabel>
                              <Select
                                id="subscriptionType"
                                value={subscriptionOrTask.subscriptionType}
                                onChange={(event) =>
                                  updateSubscriptionType(event)
                                }
                              >
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="taskType">
                                Invoice Type
                              </InputLabel>
                              <Select
                                id="taskType"
                                value={subscriptionOrTask.invoiceType}
                                onChange={(event) => updateTaskType(event)}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="billable">
                                  Billable (GST)
                                </MenuItem>
                                <MenuItem value="nonBillable">
                                  nonBillable
                                </MenuItem>
                                <MenuItem value="internal">internal</MenuItem>

                                <MenuItem value="others">Others</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="subscriptionType">
                                Subscription Type
                              </InputLabel>
                              <Select
                                id="subscriptionType"
                                value={subscriptionOrTask.subscriptionType}
                                onChange={(event) =>
                                  updateSubscriptionType(event)
                                }
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="yearly">Yearly</MenuItem>
                                <MenuItem value="oneTime">One Time</MenuItem>
                                <MenuItem value="others">Others</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="taskDetails"
                              label="Task Details"
                              variant="outlined"
                              multiline
                              rows={4} // Set the number of rows you want
                              required
                              onChange={updateTaskDetails}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" size="small">
                              <InputLabel id="assigned-to-label">
                                Assigned To
                              </InputLabel>
                              <Select
                                labelId="assigned-to-label"
                                id="assigned-to"
                                onChange={handleAssignChange}
                              >
                                {developerDetailList.map((developer) => (
                                  <MenuItem value={developer.email}>
                                    {developer.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
                                {/* Replace the following options with your list of email ids */}
                                {/*<MenuItem value="kanakarasu20@gmail.com">
                                  kanakarasu
                                </MenuItem>
                                <MenuItem value="rajappansellamuthu@gmail.com">
                                  Rajappan
                                </MenuItem>
                                <MenuItem value="varshasriudhayakumar@gmail.com">
                                  Varshasri
                                </MenuItem>
                                <MenuItem value="rupavarshni98@gmail.com">
                                  Rupavarshini
                                </MenuItem>
                                <MenuItem value="annalakshmi.ramasamyy@gmail.com">
                                  Annalakshmi
                                </MenuItem>
                                <MenuItem value="ezhilarasi0898@gmail.com">
                                  Ezhil
                                </MenuItem>
                                <MenuItem value="snpjeni@gmail.com">
                                  Jenita
                                </MenuItem>
                                <MenuItem value="simburc03@gmail.com">
                                  Silabarasi
                                </MenuItem>
                                <MenuItem value="gitanjali.moorthy@gmail.com">
                                  Gita
                                </MenuItem>
                                <MenuItem value="jaya.saran78@gmail.com">
                                  PSN
                                </MenuItem>
                                <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
                              */}
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* Include other fields specific to creating a project */}
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" size="small">
                              <InputLabel id="priority-to-label">
                                Priority
                              </InputLabel>
                              <Select
                                labelId="assigned-to-label"
                                id="assigned-to"
                                onChange={handlePriorityChange}
                              >
                                {/* Replace the following options with your list of email ids */}
                                <MenuItem value="LOW">LOW</MenuItem>
                                <MenuItem value="HIGH">HIGH</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="totalBillingDay"
                              label="Approx. No of days to complete"
                              variant="outlined"
                              required
                              onChange={updateTotalBillingDay}
                            />
                          </Grid>
                          <TextField
                            label="Start Date"
                            type="date"
                            name="startDate"
                            value={subscriptionOrTask.startDate || ""}
                            onChange={updateStartDate}
                            fullWidth
                            margin="normal"
                            size="small"
                          />
                          <TextField
                            label="End Date"
                            type="date"
                            name="endDate"
                            value={subscriptionOrTask.endDate || ""}
                            onChange={updateEndDate}
                            fullWidth
                            margin="normal"
                            size="small"
                          />
                        </>
                      )}

                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setShowModal(false)}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={showLoading}
                          fullWidth
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </>
            ) : (
              <>
                <span>Add Task:</span>
                <DialogContent>
                  <form onSubmit={handleAddProjectOrTask}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="projectOrTask">
                            Select
                          </InputLabel>
                          <Select
                            id="projectOrTask"
                            value={subscriptionOrTask.projectOrTask}
                            onChange={(event) => updateProjectOrTask(event)}
                          >
                            <MenuItem value="Select"></MenuItem>
                            <MenuItem value="createTask">Add Tasks </MenuItem>
                            <MenuItem value="createProject">
                              Add Subscription/Developer
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {subscriptionOrTask.projectOrTask === "createTask" && (
                        <>
                          {" "}
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="taskDetails"
                              label="Task Details"
                              variant="outlined"
                              multiline
                              rows={4} // Set the number of rows you want
                              required
                              onChange={updateTaskDetails}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" size="small">
                              <InputLabel id="assigned-to-label">
                                Assigned To
                              </InputLabel>
                              <Select
                                labelId="assigned-to-label"
                                id="assigned-to"
                                onChange={handleAssignChange}
                              >
                                {developerDetailList.map((developer) => (
                                  <MenuItem value={developer.email}>
                                    {developer.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
                                {/* Replace the following options with your list of email ids */}
                                {/*<MenuItem value="kanakarasu20@gmail.com">
                                  kanakarasu
                                </MenuItem>
                                <MenuItem value="rajappansellamuthu@gmail.com">
                                  Rajappan
                                </MenuItem>
                                <MenuItem value="varshasriudhayakumar@gmail.com">
                                  Varshasri
                                </MenuItem>
                                <MenuItem value="rupavarshni98@gmail.com">
                                  Rupavarshini
                                </MenuItem>
                                <MenuItem value="annalakshmi.ramasamyy@gmail.com">
                                  Annalakshmi
                                </MenuItem>
                                <MenuItem value="ezhilarasi0898@gmail.com">
                                  Ezhil
                                </MenuItem>
                                <MenuItem value="snpjeni@gmail.com">
                                  Jenita
                                </MenuItem>
                                <MenuItem value="simburc03@gmail.com">
                                  Silabarasi
                                </MenuItem>
                                <MenuItem value="gitanjali.moorthy@gmail.com">
                                  Gita
                                </MenuItem>
                                <MenuItem value="jaya.saran78@gmail.com">
                                  PSN
                                </MenuItem>
                                <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
                      */}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" size="small">
                              <InputLabel id="priority-to-label">
                                Priority
                              </InputLabel>
                              <Select
                                labelId="assigned-to-label"
                                id="assigned-to"
                                onChange={handlePriorityChange}
                              >
                                {/* Replace the following options with your list of email ids */}
                                <MenuItem value="LOW">LOW</MenuItem>
                                <MenuItem value="HIGH">HIGH</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* Include other fields specific to creating a project */}
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="totalBillingDay"
                              label="Approx. No of days to complete"
                              variant="outlined"
                              required
                              onChange={updateTotalBillingDay}
                            />
                          </Grid>
                          <label className="text-sm">Start Date</label>
                          <TextField
                            type="date"
                            name="startDate"
                            onChange={updateStartDate}
                            fullWidth
                            margin="normal"
                            size="small"
                          />
                           <label className="text-sm">End Date</label>
                          <TextField
                            type="date"
                            name="endDate"
                            onChange={updateEndDate}
                            fullWidth
                            margin="normal"
                            size="small"
                          />
                        </>
                      )}

                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleCancel}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={showLoading}
                          fullWidth
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </>
            )}
          </DialogTitle>
        </Dialog>
      )}
    </>
  );
};

export default AddProjectOrTaskSubscription;
