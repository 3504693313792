import React, { useEffect, useState } from "react";
import { contentData } from "../../../../../api/commonApi";
import { CustomInput, FormGroup } from "reactstrap";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../src/contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { holidayDetails } from "../../../../../api/HrApi";
const Holiday = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const [holidayState, setHolidayState] = useState({
    year: "",
    holidayData: [
      {
        holidayDate: "",
        holidayName: "",
      },
    ],
    showLoading: false,
  });
  useEffect(() => {
    document.write = "Holiday settings";
  }, []);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };

  const year = (event) => {
    setHolidayState({ ...holidayState, year: event.target.value });
  };
  const handleInfoAddClick = () => {
    setHolidayState({
      ...holidayState,
      holidayData: [
        ...holidayState.holidayData,
        {
          holidayDate: "",
          holidayName: "",
        },
      ],
    });
  };

  // handle click event of the onchange method
  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...holidayState.holidayData];
    list[index][name] = value;
    setHolidayState({ ...holidayState, holidayData: list });
  };

  // handle click event of the Remove button

  const handleInfoRemoveClick = (index) => {
    const list = [...holidayState.holidayData];
    list.splice(index, 1);
    setHolidayState({ ...holidayState, holidayData: list });
  };

  const holidayList = (event) => {
    event.preventDefault();
    setHolidayState({ ...holidayState, showLoading: true });
    const skill = holidayState;
    // alert("Datas" + JSON.stringify(skill));
    holidayDetails(skill)
      .then((response) => {
        const data = response.data;
        toast.success(data.message);
        setHolidayState({ ...holidayState, showLoading: true });

        setHolidayState({
          year: "",
          holidayData: [
            {
              holidayDate: "",
              holidayName: "",
            },
          ],
        });
        return <Navigate to="/letterHead" replace />;
      })
      .catch((error) => {
        toast.error(error);
        setHolidayState({ ...holidayState, showLoading: false });
      });
  };

  const { holidayData } = holidayState;
  return (
    <div>
      
        <div>
          {holidayState.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Updating your holiday data</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={holidayList}>
              <div className='card card-signin my-5 -mt-10'>
              <div className="card-body ml-5 mr-5 mt-5">
                  <h1 className="text-xl uppercase flex justify-center text-center font-semibold mb-2">
                    Holiday Document
                  </h1>
                </div>

                <p className='text-base flex justify-center mb-2'>Holiday Generation</p>

                <br />
              <div className="form-group">
                <label htmlFor="category"> Year</label>
                <select
                  className="block w-full mt-2
                       bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                  //focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"

                  // className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="option"
                  value={holidayState.year}
                  onChange={year}
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023"> 2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                </select>
              </div>
              <div className="form-group">
                <h4
                  htmlFor="holidayData"
                  className="text-base mb-10 mt-10 "
                >
                  Holiday Details
                </h4>
                {holidayData.map((x, i) => {
                  return (
                    <div>
                      <label htmlFor="holidayDate" className="mt-2">
                        Holiday Date
                      </label>
                      <input
                        type="date"
                        className="form-control mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="holidayDate"
                        name="holidayDate"
                        placeholder="holidayDate"
                        required
                        autoComplete="on"
                        value={x.holidayDate}
                        onChange={(e) => handleContentInfo(e, i)}
                      />
                      <label htmlFor="holidayName" className="mt-2">
                        Holiday Name
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="holidayName"
                        name="holidayName"
                        required
                        autoComplete="on"
                        placeholder="Holiday Name"
                        value={x.holidayName}
                        onChange={(e) => handleContentInfo(e, i)}
                      />

                      {holidayData.length !== 1 && (
                        <button
                          className="mt-5 mb-5 w-50 rounded px-7 pb-2.5 pt-3 mr-10 text-sm text-white"
                          style={{ backgroundColor: currentColor }}
                          onClick={handleInfoRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {holidayData.length - 1 === i && (
                        <button
                          className="mt-5 w-50 rounded px-7 pb-2.5 pt-3 text-sm text-white"
                          style={{ backgroundColor: currentColor }}
                          onClick={handleInfoAddClick}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>

              <button
                type="submit"
                className="mt-5 w-50 rounded px-7 pb-2.5 pt-3 text-sm text-white"
                style={{ backgroundColor: currentColor }}
              >
                Submit
              </button>



</div>
           
            </form>
          )}
        </div>
      
      <Toaster
        position="top-right"
        containerClassName="mt-14"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default Holiday;
