import React, { useState, useEffect } from "react";
import { retrieveProjectByOrgName, retrieveOrgData } from "../../../api/Api";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  AppBar,
  Tabs,
  Tab,
  Paper,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import AddProjectOrTaskSubscription from "./AddProjectOrTaskSubscription";
import KanbanBoard from "./KanbanBoard";
import toast from "react-hot-toast";
import { DEVELOPERLIST, ORGDATALIST, PUBLICHOLIDAYLIST } from "../../../config/Config";
import { retrieveProfileData } from "../../../api/TaskServiceApi";

const tabs = ["Project List", "AddTask", "Kanban Board"];

const ProjectList = () => {
  const [selectedOrg, setSelectedOrg] = useState("");
  const currentColor = "#2196f3"; // Replace with your color or use context
  const [projectListContent, setProjectListContent] = useState([]);
  const [featureIdList, setFeatureIdList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [featureId, setFeatureId] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedView, setSelectedView] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const orgDetailList = JSON.parse(localStorage.getItem(ORGDATALIST));
  const developerDetailList = JSON.parse(localStorage.getItem(DEVELOPERLIST));

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedProject(null);
    setSelectedView(0); // Reset selected view when changing tabs
  };

  const handleProjectClick = (project) => {
    // Open the dialog to let the user choose the option
    setOpenDialog(true);
    setSelectedProject(project.taskList);
    setSelectedOrg(selectedOrg);
    setFeatureId(project.featureName);
  };

  const handleDialogClose = () => {
    // Close the dialog
    setOpenDialog(false);
  };

  const handleOptionSelect = (tabValue, viewValue) => {
    // Set the selected tab and view based on user's choice
    setSelectedTab(tabValue);
    setSelectedView(viewValue);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (selectedOrg) {
      retrieveBoardList(selectedOrg);
    }
  }, [selectedOrg]);

  const retrieveBoardList = (selectedOrg) => {
    retrieveProjectByOrgName(selectedOrg)
      .then((res) => {
        const projectList = res.data;
        projectList.sort((a, b) =>
          a.orgName > b.orgName ? 1 : b.orgName > a.orgName ? -1 : 0
        );
        projectList.sort((a, b) =>
          a.featureName > b.featureName
            ? 1
            : b.featureName > a.featureName
            ? -1
            : 0
        );
        setProjectListContent(projectList);
        setSelectedOrg(selectedOrg);
        toast.success("Retrieved Successfully");
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
        }
      });
  };
 

  const handleChangeProject = (event) => {
    const newValue = event.target.value;
    setSelectedOrg(newValue);
    retrieveBoardList(newValue);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </AppBar>

      <div className="p-4">
        {selectedView === 0 && (
          <Container>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              height="100vh"
            >
              <Typography
                variant="h10"
                gutterBottom
                sx={{ fontSize: "1.5rem" }}
              >
                Project List for {selectedOrg}
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minWidth="200px"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                  sx={{ fontSize: "0.8rem", marginBottom: "8px" }}
                >
                  <InputLabel id="project-label">Select Project</InputLabel>
                  <Select
                    labelId="project-label"
                    id="project"
                    value={selectedOrg}
                    onChange={handleChangeProject}
                    label="Select Project"
                    sx={{ fontSize: "0.8rem" }}
                  >
                   {orgDetailList.map((option) => (
                      <MenuItem value={option.orgName}>
                        {option.orgEmail}
                      </MenuItem>
                    ))}
                    {/*<MenuItem value="yaazhtech">YaazhTech</MenuItem>
                    <MenuItem value="Durai_org">Durai_org</MenuItem>
                    <MenuItem value="gvg">gvg</MenuItem>
                    <MenuItem value="Darwintnpsc">Darwintnpsc</MenuItem>
                    <MenuItem value="sias">sias</MenuItem>
                    <MenuItem value="Yaazhtech_Training">
                      TrainingAtYaazhtech
                    </MenuItem>
                     */}
                  </Select>
                </FormControl>
              </Box>

              <List>
                {projectListContent.map((project) => (
                  <ListItem
                    key={project.featureName}
                    onClick={() => handleProjectClick(project)}
                    button
                  >
                    <Paper elevation={3} className="p-3" sx={{ width: "100%" }}>
                      <Typography variant="body1">
                        {project.featureName}
                      </Typography>
                    </Paper>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Container>
        )}

        {selectedView === 1 &&
          selectedProject !== null &&
          selectedTab === 1 && (
            <Paper elevation={3} className="p-3">
              <AddProjectOrTaskSubscription
                selectedOrg={selectedOrg}
                selectedProject={selectedProject}
                featureName={featureId}
                setOpenDialog={setOpenDialog}
              />
            </Paper>
          )}

        {selectedView === 2 &&
          selectedProject !== null &&
          selectedTab === 2 && (
            <Paper elevation={3} className="p-3">
              <KanbanBoard myKanban={selectedProject} orgName={selectedOrg} />
            </Paper>
          )}
        {selectedView === 0 && (
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="xs"
            classes={{ paper: "m-4" }}
          >
            <DialogTitle className="text-center">Choose an Option</DialogTitle>
            <DialogActions className="flex justify-center space-x-2 p-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOptionSelect(1, 1)}
                className="flex-1"
              >
                Add Task
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOptionSelect(2, 2)}
                className="flex-1"
              >
                View Kanban
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
