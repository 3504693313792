import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { getStatistics } from '../../../../../api/AdminApi';
import toast, { Toaster } from "react-hot-toast";
import Metrics from "../util/Metrics";
import Health from "../util/Health";

const Dashboard = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [statistics, setStatistics] = useState({
    newUsersToday: 0,
    totalNumberOfUsers: 0,
    totalNumberOfUsersUsingTwoFA: 0,
    //loading: true,
  });

  const [loading, setLoading] = useState(true);

  const [newUsersToday, setNewUsersToday] = useState(0);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
  const [totalNumberOfUsersUsingTwoFA, setTotalNumberOfUsersUsingTwoFA] = useState(0);

  useEffect(() => {
    document.title = 'Admin Dashboard';
    loadStatistics();
  }, []);

  const loadStatistics = () => {
    setStatistics({ ...Dashboard, loading: true })
    getStatistics()
      .then((res) => {
        setNewUsersToday(res.data.newUsersToday);
        setTotalNumberOfUsers(res.data.totalNumberOfUsers);
        setTotalNumberOfUsersUsingTwoFA(res.data.totalNumberOfUsersUsingTwoFA);
        setLoading(false);
      }).catch(error => {
        if (error.message && error.success === false) {
          toast(error.message);
          // this.props.showAlert(error.message, "error");
        } else {
          toast(error.message);
          // this.props.showAlert(error.message, "error");
        }
        setStatistics({ ...Dashboard, loading: false })
        console.log(error);
      });
  }
  return (
    <div className="container mx-auto">
      <div className="container grid md:grid-cols-3 p-4">
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="p-6  rounded bg-[#E6F6FA] m-3">
          <h2 className="text-center mt-5 font-bold">Statistics Data Of Users</h2>
          <br />
          {loading ? (
            <div className="text-center">
              <h4 className="text-muted">Loading statistics. Please Wait...</h4>
              <i className="material-icons w3-xxxlarge w3-spin">refresh</i>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card text-black bg-dark">
                  <div className="card-body">
                    <h5 className="card-title text-center">Registered users today</h5>
                    <hr />
                    <h6 className="card-title text-center">{newUsersToday}</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card text-black bg-dark">
                  <div className="card-body">
                    <h5 className="card-title text-center">Total number of users</h5>
                    <hr />
                    <h6 className="card-title text-center">{totalNumberOfUsers}</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card text-black bg-dark">
                  <div className="card-body">
                    <h5 className="card-title text-center">Total number of users using 2FA</h5>
                    <hr />
                    <h6 className="card-title text-center">{totalNumberOfUsersUsingTwoFA}</h6>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
        <div className="p-6  rounded bg-[#E6F6FA] m-3">
          <Health />

        </div>
        <div className="p-6  rounded bg-[#E6F6FA] m-3">
          <Metrics />
        </div>
      </div>

    </div>
  )
}

export default Dashboard
