const SeoConfigurationEn = {
    header: "Mobile-Friendly Web App Development with SEO Configuration",
    whyfree: "Get Your Mobile-Friendly Web App Optimized for Search Engines!",
    w1: "At YaazhTech, we're dedicated to empowering your digital presence with our mobile-friendly web app development service, now including comprehensive SEO configuration.",
    whyStart: "Why Opt for Our SEO Configured Mobile-Friendly Web App?",
    s1: "Optimized for Search Engines: Ensure your web app ranks higher on search engine results pages, attracting more organic traffic.",
    s2: "Enhanced User Experience: Provide users with a seamless browsing experience across all devices, improving engagement and retention.",
    s3: "Quick Setup: Expedite the launch of your web app with our efficient development process, saving you valuable time.",
    features: "Features of Your SEO Configured Mobile-Friendly Web App",
    f1: "Responsive Design: Enjoy a web app that adapts flawlessly to various screen sizes and devices, enhancing usability and accessibility.",
    f2: "Intuitive Interface: Engage your audience with an intuitive user interface, designed to maximize user satisfaction and interaction.",
    f3: "Integrated SEO: Leverage built-in SEO configuration to optimize your web app's visibility and performance on search engines.",
    servicesWorks: "How Our SEO Configuration Service Works",
    a1: "Consultation & Analysis: Collaborate with our experts to understand your SEO needs and goals for your web app.",
    a2: "Development & Optimization: Watch our team develop and fine-tune your web app with SEO best practices in mind.",
    a3: "Launch & Monitoring: Seamlessly launch your SEO-configured web app and monitor its performance with our ongoing support.",
    readytoGo: "Ready to Boost Your Online Presence? Start Your SEO Configured Web App Today!",
    r1: "Experience the benefits of a mobile-friendly web app with integrated SEO configuration and unlock new opportunities for online success.",
    button: "Get Your SEO Configured Web App",
  };
  export { SeoConfigurationEn };
  