import React, { useState, useEffect } from "react";
import { ACCESS_TOKEN } from "../../../../../config/Config";
import { submitAnswers } from "../../../../../api/AssessmentApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from '../../../../../contexts/StateContext';
import { Button, Typography, CircularProgress } from '@mui/material';

const ViewAnswers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assessment } = location.state;

  const [state, setState] = useState({
    alertIsVisible: true,
    imagePreviewUrl: "",
    user: "",
    showLoading: false,
    assessmentQuestionShortAnswersMap: {},
    assessmentQuestionsMultipleChoiceMap: "",
    userName: "",
    counter: 0,
    textIdx: 0,
    minutesPerQuestion: 0,
    answerList: [],
    testId: "",
  });

  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);

  const onDismissAlert = () => {
    setState(prevState => ({ ...prevState, alertIsVisible: false }));
  };

  const onShowAlert = () => {
    setState(prevState => ({ ...prevState, alertIsVisible: true }), () => {
      window.setTimeout(() => {
        setState(prevState => ({ ...prevState, alertIsVisible: false }));
      }, 90000);
    });
  };

  useEffect(() => {
    updateRetrieveAssessmentData(assessment);
   
  }, []);

  const updateRetrieveAssessmentData = () => {
   // alert("data :" + JSON.stringify(assessment));

    console.log("Retrieved Assessment Data: ", assessment);

    if (!assessment || !assessment.questionPaper) {
      console.error("Assessment or questionPaper is undefined");
      return;
    }

    const questionPaper = assessment.questionPaper;

    const req = {
      ...state,
      assessmentQuestionShortAnswersMap: questionPaper.assessmentQuestionShortAnswersMap || null,
      assessmentQuestionsMultipleChoiceMap: questionPaper.assessmentQuestionsMultipleChoiceMap || null,
      testId: assessment.testId || 0,
      students: assessment.students || null,
    };

    setState(prevState => ({ ...prevState, ...req, showLoading: false }));
  };

 
  const backToHome = () => {
    navigate("/trackAssessment");
  };

  

 
  const renderQuestionsAndAnswers = () => {
    const { assessmentQuestionShortAnswersMap, assessmentQuestionsMultipleChoiceMap } = state;

  
    return (
      <div>
 <div className="flex items-center justify-between mb-4">
          <Typography variant="h6">Question and Answers</Typography>
          <Button variant="outlined" onClick={backToHome}>Back</Button>
        </div>
                {assessmentQuestionShortAnswersMap && Object.keys(assessmentQuestionShortAnswersMap).length ? (
          Object.entries(assessmentQuestionShortAnswersMap).map(([question, answer], index) => (
            <div key={index} className="question-container" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
              <Typography variant="body1"><strong>Q:</strong> {question}</Typography>
              <Typography variant="body2"><strong>A:</strong> {answer}</Typography>
            </div>
          ))
        ) : (
          <Typography variant="body2">No short answer questions available.</Typography>
        )}

    
      </div>
    );
  };

  return (
    <div className="container mx-auto">
      {state.showLoading ? (
        <CircularProgress />
      ) : (
        <>
          {renderQuestionsAndAnswers()}
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
            crossorigin="anonymous"
          ></script>
        </>
      )}
    </div>
  );
};

export default ViewAnswers;
