import React, { useState,useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { generateInvoice } from "../../../../../api/commonApi";
import EditInvoiceDetail from "./EditInvoiceDetail";
import UpdatePaymentStatusDetail from "./UpdatePaymentStatusDetail";
import { useNavigate, Link } from "react-router-dom";

const PaymentPopup = ({ subscription }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
 
  const handleConfirmClick = async () => {
    try {
      setShowModal(false);
      await new Promise(resolve => setTimeout(resolve, 100));
      navigate("/paymentGateway", {
        state: {
          subscription: subscription,
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  };
  return (
    <div>
      
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  You will be redirected to Payment gateway. Do you want to proceed?
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  
                    <div>
                      <Link
                        to={{
                          pathname: "/paymentGateway",
                        }}
                        state={{
                          subscription: subscription,
                        }}
                        exact
                      >
                        <button
                          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
                          type="button"
                          onClick={handleConfirmClick}
                        >
                          {" "}
                          <span style={{ color: currentColor }}>
                            Confirm
                          </span>{" "}
                        </button>
                      </Link>

                    </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (
        null
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default PaymentPopup;
