/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Layout from "./Layout";
import MainContent from "./MainContent";

function Root() {
  return (
    <Layout>
      <MainContent />
    </Layout>
  );
}

export default Root;
