import React, { useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { experienceLetterGeneration } from "../../../../../api/AdminApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
const ExperienceLetter = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [exitEmployeeDetails, setExitEmployeeDetails] = useState({
    exitEmployeeUsername: "",
    exitLetterType: "",
    employeeExitDate: "",
  });
  const [showLoading, setShowLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExitEmployeeDetails({ ...exitEmployeeDetails, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);

    experienceLetterGeneration(exitEmployeeDetails)
      .then((res) => {
        let binaryString = window.atob(res.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "ExperienceLetter.pdf";
        link.click();

        setShowLoading(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          // Handle error appropriately
        } else {
          // Handle error appropriately
        }
        setShowLoading(false);
      });
  };

  return (
    <div className="container mx-auto py-8">
      <div className="max-w-md mx-auto">
        <h3 className="text-3xl font-bold mb-4">Experience/Relieving Letter</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <TextField
              label="Exit Employee Username"
              fullWidth
              variant="outlined"
              name="exitEmployeeUsername"
              value={exitEmployeeDetails.exitEmployeeUsername}
              onChange={handleInputChange}
              required
            />
          </div>
          
          <div className="mb-4">
            <TextField
              label="Experience/Relieving"
              fullWidth
              variant="outlined"
              name="exitLetterType"
              value={exitEmployeeDetails.exitLetterType}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Employee Exit Date"
              fullWidth
              variant="outlined"
              type="date"
              name="employeeExitDate"
              value={exitEmployeeDetails.employeeExitDate}
              onChange={handleInputChange}
              required
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="YYYY-MM-DD"
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="mb-4"
            style={{ backgroundColor: currentColor, color: "#fff" }}
          >
            {showLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Download"
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ExperienceLetter;
