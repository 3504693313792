import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function PaymentSuccess() {

  const location = useLocation();
  const { amount, transactionId } = location.state;
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-green-100">
      {/* Success Message */}
      <div className="bg-white shadow-lg rounded-lg p-8 w-11/12 md:w-1/2 lg:w-1/3 text-center">
        <h2 className="text-4xl font-bold text-green-600 mb-4">Payment Successful!</h2>
        <p className="text-gray-700 text-lg mb-6">
          Thank you for your payment. Your transaction was successful!
        </p>
        
        {/* Transaction Details */}
        <p className="text-gray-500 mb-2">
          <span className="font-semibold">Amount Paid:</span> ₹{amount}
        </p>
        <p className="text-gray-500 mb-6">
          <span className="font-semibold">Transaction ID:</span> {transactionId}
        </p>
        
        {/* Button to go back or navigate */}
        <Link to="/" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
          Back to Home
        </Link>
      </div>
    </div>
  );
}

export default PaymentSuccess;
