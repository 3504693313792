import React from 'react';
import { useNavigate } from 'react-router-dom';

const Button = ({ text, navigation, clickMethod }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // If clickMethod is passed, call it
    if (clickMethod && typeof clickMethod === 'function') {
      clickMethod();
    }
    // If navigation is passed, navigate to the desired path
    if (navigation) {
      navigate(navigation);
    }
  }

  return (
    <button
      className="relative z-10 rounded-lg py-3 px-6 hover:bg-[#ed008c] hover:text-white text-center align-middle font-sans text-xs font-bold uppercase transition-all focus:outline-none bg-white text-pink-600 border-2 border-pink-600"
      onClick={handleClick}
    >
      {text}
      <span className="absolute inset-0 rounded-lg"></span>
    </button>
  );
};

export default Button;
