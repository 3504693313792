import React from 'react';
import { Grid, Typography, LinearProgress, Box } from '@material-ui/core';

const CompactTrainingPerformance = ({ trainingPerformanceEvaluation }) => {
  const {
    probationStartDate,
    probationEndDate,
    remainingProbationDays,
    performanceRating,
    performanceLevel,
    performancePercent,
    nextEvaluationDate,
    performanceComments,
  } = trainingPerformanceEvaluation;

  return (
    <><Typography variant="h6">Performance in Training</Typography><Grid container spacing={2} className="mt-4">

      {[
        { label: 'Probation Start Date', value: probationStartDate },
        { label: 'Probation End Date', value: probationEndDate },
        { label: 'Remaining Probation Days', value: remainingProbationDays },
        { label: 'Performance Rating', value: performanceRating },
        { label: 'Performance Level', value: performanceLevel },
        {
          label: 'Performance Percent',
          value: `${performancePercent}%`,
          progressValue: parseInt(performancePercent),
        },
        { label: 'Next Evaluation Date', value: nextEvaluationDate },
        { label: 'Performance Comments', value: performanceComments },
      ].map(({ label, value, progressValue }) => (
        <Grid key={label} item xs={12} sm={6} md={4}>
          <div>
            <Typography className="font-bold">{label}:</Typography>
            <Typography className="mb-1">{value}</Typography>
            {progressValue !== undefined && (
              <Box mt={1}>
                <LinearProgress variant="determinate" value={progressValue} />
              </Box>
            )}
          </div>
        </Grid>
      ))}
    </Grid></>
  );
};

export default CompactTrainingPerformance;
