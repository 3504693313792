import { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TimeCounterMcq = (props) => {
  const navigate = useNavigate();
  const [assessmentRequest, setAssessmentRequest] = useState({
    alertIsVisible: true,
    imagePreviewUrl: "",
    user: "",
    mcqQuestions: [],
    userName: "",
    counter: 0,
    textIdx: 0,
    minutesPerQuestion: 0,
    answerList: [],
    questionAnswerList: [],
    testId: "",
    choices: [],
    mcqOptions: [],
  });

  const [timer, setTimer] = useState("00:00:00");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1); // Initialize to -1 for the initial screen
  const [selectedOptions, setSelectedOptions] = useState({});
 
  const [timeCounter, setTimeCounter] = useState(0); 
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    setAssessmentRequest((prev) => ({
      ...prev,
      mcqQuestions: props.mcqQuestions,
      minutesPerQuestion: props.minutesPerQuestion,
      testId: props.testId,
      answerList: props.answerList,
      mcqOptions: props.mcqOptions,
    }));
  }, [
    props.mcqQuestions,
    props.minutesPerQuestion,
    props.testId,
    props.answerList,
    props.mcqOptions,
  ]);

  const getTimeRemaining = () => {
    const total = timeCounter * 1000;
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return { total, hours, minutes, seconds };
  };

  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setTimeCounter((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerRef.current);
          handleTimeUp();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleTimeUp = () => {
    if (currentQuestionIndex < props.noOfQuestions - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setTimeCounter(Math.ceil(props.minutesPerQuestion) * 60); // Reset timer for the next question
      startTimer(); // Start timer for the next question
    } else {
      setIsNextEnabled(true);
    }
  };

  useEffect(() => {
    if (currentQuestionIndex >= 0) {
      startTimer();
      return () => clearInterval(timerRef.current);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const { hours, minutes, seconds } = getTimeRemaining();
    setTimer(
      `${hours > 9 ? hours : "0" + hours}:${
        minutes > 9 ? minutes : "0" + minutes
      }:${seconds > 9 ? seconds : "0" + seconds}`
    );
  }, [timeCounter]);

  const onClickNextQuestion = () => {
    if (currentQuestionIndex < props.noOfQuestions - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setTimeCounter(Math.ceil(props.minutesPerQuestion) * 60);
      setIsNextEnabled(false);
      startTimer();
    } else {
      setIsNextEnabled(true);
    }
  };

  const handleMCQChange = (e, index) => {
    const value = e.target.value;
    props.handleMCQSelection(e, index, props.mcqQuestions[index].question);
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [index]: value,
    }));
    setIsNextEnabled(true);
  };

  const onClickSubmit = () => {
    clearInterval(timerRef.current);
    props.onClickSubmitMCQAnswers();
  };

  const backToHome = () => {
    navigate("/assessmentMcqs");
  };

 
  const startAssessment = () => {
    setTimeCounter(Math.ceil(props.minutesPerQuestion) * 60); 
    setCurrentQuestionIndex(0);
  };

  return (
    <>
      <div className="container mx-auto">
        <Typography variant="h4">Welcome to online assessment.</Typography>
        <Typography variant="body2">
          Total duration of the test:{" "}
          <span className="text-gray-500">
            {props.noOfQuestions * props.minutesPerQuestion} mins
          </span>{" "}
          <br />
          Total number of Questions:{" "}
          <span className="text-gray-500">{props.noOfQuestions}</span>
        </Typography>
        <div className="bg-gray-200 p-4 rounded-lg">
          <Typography variant="h6" className="text-primary font-bold mb-3">
            Timer: {timer}
          </Typography>
          <div>
            {currentQuestionIndex === -1 ? (
              <div>
                <Typography variant="body2" className="text-gray-500">
                  Click start to begin the online test.
                </Typography>
                <Box mt={2} mb={2} display="flex" gap={2}>
                <Button
                  variant="contained"
                  onClick={startAssessment}
                  className=""
                >
                  Start
                </Button>
                <Button variant="outlined"
                 onClick={backToHome}
                 className="">
                  Back
                </Button>
                </Box>
                <Typography variant="body2" className="text-gray-500 mt-2">
                  <strong>Tips:</strong>
                  <br />
                  The online test itself is{" "}
                  {props.noOfQuestions * Math.ceil(props.minutesPerQuestion)}{" "}
                  minutes.
                  <br />
                  *You cannot stop a section once you’ve begun, so I encourage
                  you to be in a quiet spot ahead of time where you can focus
                  without distraction.
                  <br />
                  *If you're not able to complete the assessment by the due
                  date, just let our HR know and we will extend the link for
                  you.
                </Typography>
                <Typography variant="body2" className="text-gray-500 mt-2">
                  <strong>Note:</strong>
                  <br />
                  You can use any of the following languages you’re comfortable
                  with: C#, C, C++, Java, Python, Ruby, or JavaScript.
                </Typography>
              </div>
            ) : currentQuestionIndex < props.noOfQuestions ? (
              <form>
                <div className="form-group">
                  <Typography variant="body1" className="mb-2">
                    {currentQuestionIndex + 1}.{" "}
                    {
                      assessmentRequest.mcqQuestions[currentQuestionIndex]
                        ?.question
                    }
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label={`question-${currentQuestionIndex}`}
                      name={`question-${currentQuestionIndex}`}
                      value={selectedOptions[currentQuestionIndex] || ""}
                      onChange={(e) =>
                        handleMCQChange(e, currentQuestionIndex)
                      }
                    >
                      {assessmentRequest.mcqQuestions[
                        currentQuestionIndex
                      ]?.choices.map((choice, choiceIndex) => (
                        <FormControlLabel
                          key={choiceIndex}
                          value={choice}
                          control={<Radio />}
                          label={choice}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                  <div>
                    {currentQuestionIndex < props.noOfQuestions - 1 ? (
                      <Button
                        onClick={onClickNextQuestion}
                        disabled={!isNextEnabled}
                        sx={{
                          backgroundColor: isNextEnabled ? 'primary.main' : 'grey.500',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: isNextEnabled ? 'primary.dark' : 'grey.600',
                          },
                        }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={onClickSubmit}
                        variant="contained"
                        className="mt-2"
                        disabled={!isNextEnabled}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
                <Typography variant="body2" className="text-gray-500 mt-2 py-2">
                  *Your answers will be auto saved and next question will appear
                  immediately when minutes per question time expires.
                  <br />
                  Please select the correct option from the choices above and
                  wait for the time to expire to see the next question.
                  <br />
                  *Your moves are tracked, if you change the screen you may get
                  disqualified and it may stop working.
                </Typography>
              </form>
            ) : (
              <div>
                <Typography variant="body2" className="text-gray-500">
                  Time's up! To save your answers, please click submit.
                </Typography>
                <Button
                  variant="contained"
                  onClick={onClickSubmit}
                  className="mt-2"
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeCounterMcq;

