const DirectHireMainDe={
    title:"YaazhTech: Ihr One-Stop-Shop für die Direkteinstellung von Top-Tech-Talenten",
    explain:"YaazhTech optimiert Ihren Einstellungsprozess, indem es Sie direkt mit außergewöhnlichen Entwicklern, Designern und Ingenieuren verbindet. Wir sind darauf spezialisiert, die perfekte Lösung für Ihr Team zu finden, Zwischenhändler auszuschalten und Ihnen wertvolle Zeit und Ressourcen zu sparen.",
  
    lookingTo:"Hinüberschauen zu:",
    l1:"Beauftragen Sie einen Programmierer um Ihre Traum-App zu erstellen?",
    l2:"Direktvermietung Ein talentierter Softwareentwickler, der Ihr nächstes Projekt vorantreibt?",
    l3:"Finden Der perfekte Webentwickler, um Ihre Online-Präsenz zu verbessern?",
    noFurthur:"Suchen Sie nicht weiter! YaazhTech ist Ihre Komplettlösung für die Gewinnung außergewöhnlicher Tech-Talente durch Direkteinstellung.",

    applyButton: "Hier bewerben",
    
    offer:"Das bieten wir an:",
    explain:"Direkteinstellung für Top-Tech-Positionen: Wir sind darauf spezialisiert, Sie mit vorab geprüften, hochqualifizierten Fachleuten in verschiedenen Bereichen zusammenzubringen, darunter:",
    p1:"React-Entwickler",
    p2:"Java-Entwickler",
    p3:"Full-Stack-Entwickler",
    p4:"Mobile Entwickler",
    p5:"Camunda-Entwickler",
    p6:"DevOps-Ingenieure",
    p7:"SEO-Spezialisten",
    p8:"Python-Entwickler",
    p9:"Personalmanager",
    p10:"Experten für API-Tests",
    p11:"SQL-Entwickler",
    p12:"Golang-Entwickler",
  
    ready: "Sind Sie bereit, Ihr Traum-Entwicklungsteam aufzubauen?",
    contact:"Kontaktieren Sie YaazhTech noch heute für eine kostenlose Beratung. Wir helfen Ihnen dabei, den perfekten direkt angestellten Programmierer für Ihre spezifischen Anforderungen zu finden und Ihre technologische Innovation voranzutreiben.",
  
    }
  
    
  
    export {DirectHireMainDe};