import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';
import { TrainingForExperiencedMainEn } from '../../data/content/En/TrainingForExperiencedMainEn';
import { TrainingForExperiencedMainDe } from '../../data/content/De/TrainingForExperiencedMainDe';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../../components/StaticButton/Button';

const TrainingForExperiencedMain = () => {
  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);
  const language = useContextSelector(StateContext, (state) => state.language);

  const content = language === 'EN' ? TrainingForExperiencedMainEn : TrainingForExperiencedMainDe;

  return (
    <div className="container mx-auto px-4">
      <Helmet>
        <title>
          Upskill & Advance: Yaazhtech Training for Experienced Professionals (Cross-Functional Skills & Career Growth)
        </title>
        <meta
          name="description"
          content="Elevate your career with Yaazhtech's training! Master in-demand skills, explore new domains, and gain a competitive edge. (Experienced Professionals, Upskilling, Cross-Functional Skills)"
        />
      </Helmet>

      <div className="py-10">
        <div className="inspect-visible visually-hidden absolute w-px h-px m-px p-0 overflow-hidden clip-rect">
          <h1>
            Upskill & Advance: Yaazhtech Training for Experienced Professionals (Cross-Functional Skills & Career Growth)
          </h1>
        </div>

        <div className="max-w-5xl mx-auto">
          <h2 className="text-3xl font-bold uppercase mb-6" style={{ color: currentColor }}>
            {content.heading}
          </h2>

          <p className="text-lg mb-4">{content.headingExplain1}</p>
          <p className="text-lg mb-4">
            {content.headingExplain2}
            <ul className="list-disc list-inside ml-5">
              <li>
                <span className="font-bold">Broaden your Skillset: </span>
                {content.broaden}
              </li>
              <li>
                <span className="font-bold">Increase Employability: </span>
                {content.increase}
              </li>
              <li>
                <span className="font-bold">Drive Career Growth: </span>
                {content.drive}
              </li>
            </ul>
          </p>

          <div className="flex justify-center mb-6">
            <Button text={content.applyButton} navigation="/trainingExperienced" />
            
          </div>

          <p className="text-lg mb-4">
            <b>{content.program}</b>
          </p>
          <p className="text-lg mb-4">
            {content.programExplain}
            <ul className="list-disc list-inside ml-5">
              <li>
                <span className="font-bold">Self-Paced Learning: </span>
                {content.self}
              </li>
              <li>
                <span className="font-bold">Mentorship & Guidance: </span>
                {content.mentor}
              </li>
              <li>
                <span className="font-bold">Real-World Application: </span>
                {content.real}
              </li>
            </ul>
          </p>

          <p className="text-lg mb-4">
            <b>{content.benefits}</b>
          </p>
          <p className="text-lg mb-4">
            <ul className="list-disc list-inside ml-5">
              <li>
                <span className="font-bold">Upskilling & Reskilling Opportunities: </span>
                {content.upskilling}
              </li>
              <li>
                <span className="font-bold">Cross-Domain Learning: </span>
                {content.cross}
              </li>
              <li>
                <span className="font-bold">Career Advancement: </span>
                {content.career}
              </li>
            </ul>
          </p>

          <p className="text-lg mb-4">
            <b>{content.who}</b>
          </p>
          <p className="text-lg mb-4">
            {content.explain}
            <ul className="list-disc list-inside ml-5">
              <li>{content.o1}</li>
              <li>{content.o2}</li>
              <li>{content.o3}</li>
            </ul>
          </p>

          <p className="text-lg mb-4">
            <b>{content.take}</b>
          </p>
          <p className="text-lg mb-4">{content.takeExplain}</p>

          <p className="text-lg mb-4">
            <b>{content.haveQuestion}</b>
          </p>

          <p className="text-lg mb-4">
            <Link to="/contact" className="text-blue-500 font-bold">
              Contact our team for a free consultation
            </Link>
            {' to discuss your career goals and how our program can help you achieve them.'}
            {content.contact}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrainingForExperiencedMain;
