const StaffAugTalentAcquisitionSpecialistEn = {
    title: "Yaazhtech: Fueling Innovation Through Exceptional Talent",
    headingContent1:
      "At Yaazhtech, we recognize the immense value of exceptional talent in driving innovation and achieving sustainable growth.",
    headingContent2:
      "Our dedicated Talent Acquisition Specialists are committed to attracting, engaging, and onboarding the brightest minds who share our passion for creating groundbreaking software solutions.",
    
    moreThanJustRecruitment: "More Than Just Recruitment: Building the Yaazhtech Dream Team:",
    our: "Our Talent Acquisition Specialists are not just recruiters; they are talent architects who meticulously craft the Yaazhtech dream team.",
    theyGo: "They go beyond traditional recruitment methods, actively seeking out passionate individuals who align with our company culture and possess the skills and drive to excel.",

    unCovering: "Uncovering Hidden Gems: Proactive Sourcing and Talent Pool Building:",
    throughInnovative:"Through innovative sourcing strategies and a commitment to building a diverse talent pool, our Talent Acquisition Specialists uncover hidden gems in the vast landscape of skilled professionals.",
    theyLeverage: "They leverage their expertise in online platforms, professional networks, and targeted outreach to identify the perfect fit for each role, ensuring a seamless integration into our dynamic team.",
   
    craftingAPositive: "Crafting a Positive Candidate Experience: From Initial Contact to Onboarding:",
    initial: "From the initial point of contact, our Talent Acquisition Specialists prioritize creating a positive and engaging candidate experience.",
    transparent: "They provide transparent communication, maintain open dialogue throughout the recruitment process, and foster a welcoming environment that allows candidates to truly envision themselves thriving at Yaazhtech.",

    joinJourney: "Join the Yaazhtech Journey: Building a Career You'll Love",
    initial: "If you are a passionate and talented individual seeking to make a real impact in the software development industry, we encourage you to explore career opportunities at Yaazhtech.",
    talent: "Our Talent Acquisition Specialists are eager to connect with you and discuss how your unique skills and experience can contribute to our collective success.",
    
    button:"Hire a Coder",
    cancelButton:"Cancel",

  };

  export {StaffAugTalentAcquisitionSpecialistEn};