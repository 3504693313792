const TrainingAndHiringMainDe={
  title:"Beschleunigen Sie Ihre IT-Karriere mit den Schulungs- und Einstellungsdiensten von Yaazhtech",
  explain:"Verwandeln Sie sich mit den umfassenden Schulungs- und Einstellungslösungen von Yaazhtech in einen äußerst gefragten IT-Experten. Unsere von Experten geleiteten Programme vermitteln Ihnen gefragte Fähigkeiten und unsere maßgeschneiderten Einstellungsdienste vernetzen Sie mit den besten Möglichkeiten in der Branche. Beginnen Sie noch heute Ihre Reise zum IT-Erfolg mit Yaazhtech.",
  
 // course:"View All Courses and Enroll Now",
  
  //course: (
     // <span>
     //   <a href="/trainHire" style={{ color: 'blue', fontWeight: 'bold'}}>Klicken Sie hier, um alle Kurse anzuzeigen und sich jetzt anzumelden!!!</a>
     // </span>
  //  ),

  applyButton: "Hier bewerben",
  uniqueApproach:"Einzigartige Ansätze für die Ausbildung und Einstellung von Yaazhtech:",
  k1:"Kompetenzorientierter Lehrplan: Unsere Schulungsprogramme sind sorgfältig darauf ausgelegt, die Lücke zwischen theoretischem Wissen und praktischen Anwendungen zu schließen und Ihnen die Fähigkeiten zu vermitteln, die Arbeitgeber am meisten schätzen.",
  k2:"Praxisnahe Projekterfahrung: Sammeln Sie praktische Erfahrungen durch reale Projekte und erstellen Sie ein Portfolio, das Ihr Fachwissen unter Beweis stellt.",
  k3:"Mentoring und Networking: Profitieren Sie von der Beratung durch erfahrene IT-Experten und vernetzen Sie sich mit einem Netzwerk von Gleichgesinnten.",
  k4:"Vermittlungsunterstützung: Unsere Einstellungsdienste bieten persönliche Unterstützung, Erstellung von Lebensläufen, Vorbereitung auf Vorstellungsgespräche und direkte Verbindungen zu führenden Unternehmen.", 
  k5:"Flexible Lernformate: Wählen Sie je nach Lernstil und Zeitplan zwischen Online-, Präsenz- oder Hybrid-Schulungsoptionen.", 
  
  offerTrainingPrograms:"Wir bieten einzigartige Schulungsprogramme in:",
  p1:"Erlernen Sie die Fähigkeiten, komplette Anwendungen von Grund auf zu erstellen, einschließlich Front-End- (ReactJS) und Back-End-Entwicklung (Java Spring Boot).",
  p2:"Erwerben Sie solide Grundlagen in den Grundlagen der Java-Programmierung und bereiten Sie sich auf fortgeschrittene Studien und berufliche Möglichkeiten vor.",
  p3:"Erfahren Sie, wie Sie mit beliebten Frameworks wie ReactJS optisch ansprechende und benutzerfreundliche Schnittstellen erstellen.",
  p4:"Tauchen Sie ein in die vielseitige Welt der Python-Programmierung. Entwickeln Sie Webanwendungen, analysieren Sie Daten, automatisieren Sie Aufgaben und mehr!",
  
  p5:"Erstellen Sie ansprechende mobile Erlebnisse auf allen Plattformen.",
  p6:"Die umfassenden Schulungs- und Einstellungslösungen von Yaazhtech befähigen Sie, API-Tests zu meistern, die Softwarequalität sicherzustellen und den beruflichen Aufstieg in diesem gefragten Bereich voranzutreiben.",
  p7:"Beherrschen Sie die effiziente und skalierbare Welt der Go-Entwicklung (Golang).",
  p8:"Beginnen Sie Ihre DevOps-Reise mit unserem umfassenden Einführungsprogramm.",

  p9:"Beherrschen Sie die Kunst der Online-Werbung und Marketingstrategien.",
  p10:"Rüsten Sie sich mit den Fähigkeiten aus, um eine erfolgreiche Belegschaft aufzubauen und zu verwalten.",
  p11:"Erlernen Sie die Grundlagen der SQL-Datenbankverwaltung.",
  p12:"Nutzen Sie die Leistungsfähigkeit von Camunda, einer führenden Plattform für Prozessautomatisierung, und erschließen Sie sich spannende Karrieremöglichkeiten mit der umfassenden Online-Schulung von Yaazhtech.",

  //newServices:"Discover Our Hiring Services",

  //newServices: (
   //   <span>
    //    <a href="/trainHireDoc" style={{ color: 'blue', fontWeight: 'bold'}}>Entdecken Sie unsere Einstellungsdienstleistungen:</a>
    //  </span>
   // ),
  top: "Top-Technologieschulungs- und Einstellungsdienste",
  c1:"Java mit ReactJS Full Stack Developer",
  c2:"Java-Entwickler (Spring Boot)",
  c3:"UI-Entwickler (React JS)",
  c4:"Entwicklung mobiler Apps",
  c5:"Camunda-Entwicklung",
  c6:"Python-Entwicklung",
  c7:"Golang-Entwicklung",
  c8:"API-Testingenieur",
  c9:"SQL-Entwickler",
  c10:"DevOps-Engineering",
  c11:"Digitales Marketing",
  c12:"HR Manager",
  
  //partner:"Ready to transform your IT career? Get in touch with our experts today!",

  partner: (
      <span>
        <a href="/contact" style={{ color: 'blue', fontWeight: 'bold'}}>Sind Sie bereit, Ihre IT-Karriere zu verändern? Nehmen Sie noch heute Kontakt mit unseren Experten auf!</a>
      </span>
    ),
  }

  export {TrainingAndHiringMainDe};