
import React ,{useState,useEffect} from 'react';
//import { Link } from 'react-router-dom';
import { profilePersonalData } from '../../../../../../../src/api/commonApi';
import {
 AADHAR_EXACT_LENGTH,
 PAN_EXACT_LENGTH,
   MOBILE_EXACT_LENGTH,
  NAME_MIN_LENGTH
} from "../../../../../../../src/config/Config";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../../src/contexts/StateContext';
import toast, { Toaster } from "react-hot-toast"; 


const PersonalDataSettings = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
    const [personalDataRequest, setPersonalDataRequest] = useState({
      firstName: '',
      lastName: '',
      fullName: '',
      panNo: '',
      aadharNo: '',
      dateOfBirth: '',
      presentAddress: '',
      permanentAddress: '',
      bloodGroup: '',
      phoneNo: '',
      fatherName: '',
      emergencyContactNo: '',
      motherName: '',
      fatherDob: '',
      motherDob: '',
      genderStatus: ''
    });
    const [showLoading, setShowLoading] = useState(false);
   
    useEffect(() => {
      document.title = 'My account Personal data settings';
    }, []);
   
    const updateField = (field, value) => {
      setPersonalDataRequest(prevData => ({
        ...prevData,
        [field]: value
      }));
    };
   
    const handleOnboardPersonalProfile = (event) => {
      event.preventDefault();
      setShowLoading(true);
      const personalData = personalDataRequest;
     
      if (personalData.phoneNo.length !== MOBILE_EXACT_LENGTH) {
        toast.error("Your mobile number must contain exactly and no special characters.");
        setShowLoading(false);
        return null;
      }
    if (personalData.aadharNo.length !== AADHAR_EXACT_LENGTH) {
      
        toast.error("Your aadhar number must contain exactly and no special characters.");
        setShowLoading(false);
        return null;
      }

      if (personalData.panNo.length !== PAN_EXACT_LENGTH) {
      
        toast.error("Your pan number must contain exactly and no special characters.");   
        setShowLoading(false);
        return null;
      }
          
   
      profilePersonalData(personalData)
        .then((response) => {
          const data = response.data;         
          toast.success(data.message);
          setShowLoading(false);

          setPersonalDataRequest({
            firstName: '', lastName: '', fullName: '',
            panNo: '', aadharNo: '', dateOfBirth: '', presentAddress: '', permanentAddress: '',
            phoneNo: '', emergencyContactNo: '', fatherDob: '', motherDob: '',
            fatherName: '', motherName: '', bloodGroup: '', genderStatus: ''
          });
        })
        .catch((error) => {
          
          toast.error(error.message);
          setShowLoading(false);
        });
    };
   
    const updateFirstName = (event) => {
        let req = personalDataRequest;
        req.firstName = event.target.value;
        setPersonalDataRequest(req);
       
      };

      const updateLastName = (event) => {
        let req = personalDataRequest;
        req.lastName = event.target.value;
        setPersonalDataRequest(req);
       
      };

      const updateFullName = (event) => {
        let req = personalDataRequest;
        req.fullName = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updatePanNo = (event) => {
        let req = personalDataRequest;
        req.panNo = event.target.value;
        setPersonalDataRequest(req);
       
      };

      const updateAadharNo = (event) => {
        let req = personalDataRequest;
        req.aadharNo = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateDob = (event) => {
        let req = personalDataRequest;
        req.dateOfBirth = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateGenderStatus = (event) => {
        let req = personalDataRequest;
        req.genderStatus = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updatePresentAddress = (event) => {
        let req = personalDataRequest;
        req.presentAddress = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updatePermanentAddress = (event) => {
        let req = personalDataRequest;
        req.permanentAddress = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateBloodGroup = (event) => {
        let req = personalDataRequest;
        req.bloodGroup = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updatePhoneNo = (event) => {
        let req = personalDataRequest;
        req.phoneNo = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateFatherName = (event) => {
        let req = personalDataRequest;
        req.fatherName = event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateEmergencyContactNo = (event) => {
        let req = personalDataRequest;
        req.emergencyContactNo= event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateMotherName = (event) => {
        let req = personalDataRequest;
        req.motherName= event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateFatherDob = (event) => {
        let req = personalDataRequest;
        req.fatherDob= event.target.value;
        setPersonalDataRequest(req);
       
      };
      const updateMotherDob = (event) => {
        let req = personalDataRequest;
        req.motherDob= event.target.value;
        setPersonalDataRequest(req);
       
      };
  return (
  

    <div className=' w-full md:10 mx-auto p-3'>
                
                <h2 className="md:text-xl sm:text-lg p-3 font-semibold text-center uppercase mb-3">Personal Onboard</h2>
               
                <div>
                    {
                        showLoading
                            ?
                            <div className="align-content-center text-center">
                                <h3>Updating your personal data</h3>
                                <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                            </div>
                            :
                            <form onSubmit={handleOnboardPersonalProfile}>
                              
                              <button
                      className="select-none  rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="submit"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                          Submit
                        </button>
 <div className="grid md:grid-cols-4">
                                <div className="mt-3 mx-3">
                                    <label htmlFor="firstName" className='text-gray-500'>First Name:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"  id="firstName" name='firstName'
                                        placeholder="Enter your First Name" required autoComplete="on"
                                        onChange={updateFirstName}

                                    />
                                </div>

                                <div className="mt-2 mx-3">
                                    <label htmlFor="LastName" className='text-gray-500'>Last Name:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"  id="firstName" name='firstName'
                                    
                                    placeholder="Enter your Last Name" required autoComplete="on" 
                                        onChange={updateLastName}
                                    />
                                </div>

                                <div className="mt-2 mx-3">
                                    <label htmlFor="fullName" className='text-gray-500'>Full Name:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"  id="firstName" name='firstName'
                              
                                        placeholder="Enter your Full Name" required autoComplete="on"
                                        onChange={updateFullName}
                                    />
                                </div>


                                <div className="mt-2 mx-3">
                                        <label htmlFor="panNo" className='text-gray-500'>Pancard No:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="panNo" name='panNo'
                                        placeholder="Enter your Pancard No" required autoComplete="on"
                                         onChange={updatePanNo}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="aadharNo" className='text-gray-500'>Aadhar No:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="aadharNo" name='aadharNo'
                                        placeholder="Enter your Aadharcard No" required autoComplete="on"
                                         onChange={updateAadharNo}
                                    />
                                </div>


                                <div className="mt-2 mx-3">
                                    <label htmlFor="dateOfBirth" className='text-gray-500'>Date Of Birth :</label>
                                    <input type="date" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="dateOfBirth" name='dateOfBirth'
                                        required autoComplete="on"
                                         onChange={updateDob}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="genderStatus" className='text-gray-500'>Gender Status:</label>

                                    <select
                                        className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                                        id="GenderStatus"
                                        placeholder="Select gender status"
                                     onChange={updateGenderStatus}
                                    ><option value="Gender Status">Gender Status</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Transgender">Transgender</option>

                                    </select>
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="presentAddress" className='text-gray-500'>Present Address:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="presentAddress" name='presentAddress'
                                        placeholder="Enter your present address" required autoComplete="on"
                                         onChange={updatePresentAddress}
                                    />
                                </div>


                                <div className="mt-2 mx-3">
                                    <label htmlFor="permanentAddress" className='text-gray-500 '>Permanent Address:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" 
                                    id="permanentAddress" 
                                    name='permanentAddress'
                                        placeholder="Enter your permanent address" required 
                                        autoComplete="on"
                                         onChange={updatePermanentAddress}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="bloodGroup" className='text-gray-500'>BloodGroup:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="bloodGroup" name='bloodGroup'
                                        placeholder="Enter your Blood Group" required autoComplete="on"
                                         onChange={updateBloodGroup}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="phoneNo"className='text-gray-500'>Phone No :</label>
                                    <input type="phone" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="phoneNo" name='phoneNo'
                                        placeholder="Enter your Phone No" required autoComplete="on"
                                         onChange={updatePhoneNo}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="fatherName" className='text-gray-500'>Father Name:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="fatherName" name='fatherName'
                                        placeholder="Enter your Father Name" required autoComplete="on"
                                         onChange={updateFatherName}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="emergencyContactNo" className='text-gray-500'>Emergency Contact No:</label>
                                    <input type="phone" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="emergencyContactNo" name='emergencyContactNo'
                                        placeholder="Enter your Emergency Contact No" required autoComplete="on"
                                         onChange={updateEmergencyContactNo}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="motherName"className='text-gray-500'>Mother Name:</label>
                                    <input type="text" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="motherName" name='motherName'
                                        placeholder="Enter your Mother Name" required autoComplete="on"
                                         onChange={updateMotherName}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="fatherDob" className='text-gray-500'>Father DOB:</label>
                                    <input type="date" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="fatherDob" name='fatherDob'
                                        required autoComplete="on"
                                         onChange={updateFatherDob}
                                    />
                                </div>
                                <div className="mt-2 mx-3">
                                    <label htmlFor="motherDob" className='text-gray-500'>Mother DOB:</label>
                                    <input type="date" className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" id="motherDob" name='motherDob'
                                        required autoComplete="on"
                                         onChange={updateMotherDob}
                                    />
                                </div>
                                
                                </div>
                                <br></br>
                               
                       
                        </form> 
                    }

                </div>

                <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />
            </div>
      
      
       );

    
};


export default PersonalDataSettings;




    
       