import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';
import { contactData } from "../../api/Api";
 
const Contact = (props) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
 
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [showLoading,setShowLoading] = useState(false);
  useEffect(() => {
    document.title = "Contact Us";
  }, []);
  const nameChange =(event) =>{
    setContact({...contact, name: event.target.value});
  }
  const mailChange =(event) =>{
    setContact({...contact, email: event.target.value});
  }
  const phoneChange =(event) =>{
    setContact({...contact, phone: event.target.value});
  }
  const subjectChange =(event) =>{
    setContact({...contact, subject: event.target.value});
  }
  const messageChange =(event) =>{
    setContact({...contact, message: event.target.value});
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);
    const contactUs = contact;
    //alert("success"+JSON.stringify(contactUs));
    contactData(contactUs)
        .then(response => {
         
           toast.success("Your Message has been submitted.Thank you for contacting us!","success");
          setShowLoading(true);
 
           setContact({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: ""
           })
 
       })
        .catch(error => {
           toast.error(error.message);
           toast.error("error");
           setShowLoading(false);
           
       })
    //alert(contact)
  };
 
 
  useEffect(() => {
    if (props.notFound === true) {
      props.showAlert("Page not found! Back to the home page.", "error");
    }
  }, [props.notFound]);
  ////style={{ backgroundImage: "url(" + img + ")" }}
 
  return (
    <>
      <br></br>
 
      <div>
        <div className="w-full md:w-1/4 mx-auto align-baseline">
          <div className="text-center">
            <h2
              className="text-2xl font-bold uppercase text-gray-300 mb-2"
              style={{ color: currentColor }}
            >
              {" "}
              Contact Us{" "}
            </h2>
            <h3 className="text-sm text-black-500 italic mb-3">
              To reach us, email at{" "}
              <a
                className="text-black-500"
                href="mailto:business@yaazhtech.com"
              >
               business@yaazhtech.com{" "}
              </a>
              .{" "}
            </h3>
            <h3 className="text-sm text-gray-300 mb-6"> or </h3>
          </div>
 
          <form onSubmit={handleSubmit}>
            <div className="px-4 mb-4">
              <input
                className="w-full py-3 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-black-500"
                id="name"
                type="text"
                onChange={nameChange}
                value={contact.name}
                placeholder="Your Name *"
                required
              />
            </div>
 
            <div className="px-4 mb-4">
              <input
                className="w-full py-3 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-black-500"
                id="email"
                type="email"
                onChange={mailChange}
                value={contact.email}
                placeholder="Your Email *"
                required
              />
            </div>
 
            <div className="px-4 mb-4">
              <input
                className="w-full py-3 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white  border border-gray-300 focus:border-black-500"
                id="phone"
                type="tel"
                onChange={phoneChange}
                value={contact.phone}
                placeholder="Your Phone *"
                required
              />
            </div>
 
            <div className="px-4 mb-4">
              <input
                className="w-full py-3 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white  border border-gray-300 focus:border-black-500"
                id="subject"
                type="text"
                onChange={subjectChange}
                value={contact.subject}
                placeholder="Your Subject *"
                required
              />
            </div>
 
            <div className="px-4 mb-4">
              <textarea
                rows="5"
                className="w-full py-3 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-black-500"
                id="message"
                placeholder="Your Message *"
                required
                onChange={messageChange}
                value={contact.message}
              />
            </div>
             
              <button
              className="mt-5 ml-20 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
              style={{ backgroundColor: currentColor }}
              >
              <span>Send Message</span>
              </button>
          </form>
        </div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
            />
      </div>
    </>
  );
};
 
export default Contact;