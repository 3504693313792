import React, { useState, useEffect } from 'react';
import { Link, json } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../contexts/StateContext';
import toast, { Toaster } from "react-hot-toast"; 
import { useNavigate } from 'react-router-dom';

import {
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    USERNAME_MAX_LENGTH,
    USERNAME_MIN_LENGTH
} from "../../../../../../config/Config";
import { updatePassword } from "../../../../../../api/Api";

const AccountSettings = () => {
  const navigate = useNavigate();
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor,
      );

    const [updatePasswordRequest, setUpdatePasswordRequest] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });
    const [showLoading, setShowLoading] = useState(false);
    const [passwordPattern, setPasswordPattern] = useState({
        number: false,
        lowerCase: false,
        upperCase: false,
        charsLength: false
    });

    useEffect(() => {
        document.title = "My account settings";
    }, []);

    const updateOldPassword = event => {
        setUpdatePasswordRequest({
            ...updatePasswordRequest,
            oldPassword: event.target.value
        });
    };

    let updateNewPassword = (event) => {
        let req = { ...updatePasswordRequest };
        req.newPassword = event.target.value;
        setUpdatePasswordRequest(req);
    
        let passwordPattern = setPasswordPattern;
    
        const lowerCase = /[a-z]/g;
        if (req.newPassword.match(lowerCase)) {
          document.getElementById("lowerCase").classList.remove("text-danger");
          document.getElementById("lowerCase").classList.add("text-success");
          passwordPattern.lowerCase = true;
        } else {
          document.getElementById("lowerCase").classList.remove("text-success");
          document.getElementById("lowerCase").classList.add("text-danger");
          passwordPattern.lowerCase = false;
        }
    
        const upperCase = /[A-Z]/g;
        if (req.newPassword.match(upperCase)) {
          document.getElementById("upperCase").classList.remove("text-danger");
          document.getElementById("upperCase").classList.add("text-success");
          passwordPattern.upperCase = true;
        } else {
          document.getElementById("upperCase").classList.remove("text-success");
          document.getElementById("upperCase").classList.add("text-danger");
          passwordPattern.lowerCase = false;
        }
    
        const number = /[0-9]/g;
        if (req.newPassword.match(number)) {
          document.getElementById("number").classList.remove("text-danger");
          document.getElementById("number").classList.add("text-success");
          passwordPattern.number = true;
        } else {
          document.getElementById("number").classList.remove("text-success");
          document.getElementById("number").classList.add("text-danger");
          passwordPattern.number = false;
        }
    
        if (
          req.newPassword.length > PASSWORD_MIN_LENGTH &&
          req.newPassword.length < PASSWORD_MAX_LENGTH
        ) {
          document.getElementById("chars").classList.remove("text-danger");
          document.getElementById("chars").classList.add("text-success");
          passwordPattern.charsLength = true;
        } else {
          document.getElementById("chars").classList.remove("text-success");
          document.getElementById("chars").classList.add("text-danger");
          passwordPattern.charsLength = false;
        }
        setPasswordPattern(passwordPattern);
      };

    
      const updateConfirmNewPassword = event => {
        let req = {...updatePasswordRequest};
        req.confirmNewPassword = event.target.value;
        setUpdatePasswordRequest(req);

        let passwordPattern = setPasswordPattern;

        const lowerCase = /[a-z]/g;
        if (req.confirmNewPassword.match(lowerCase)) {
            document.getElementById("lowerCase").classList.remove("text-danger");
            document.getElementById("lowerCase").classList.add("text-success");
            passwordPattern.lowerCase = true;
        } else {
            document.getElementById("lowerCase").classList.remove("text-success");
            document.getElementById("lowerCase").classList.add("text-danger");
            passwordPattern.lowerCase = false;
        }

        const upperCase = /[A-Z]/g;
        if (req.confirmNewPassword.match(upperCase)) {
            document.getElementById("upperCase").classList.remove("text-danger");
            document.getElementById("upperCase").classList.add("text-success");
            passwordPattern.upperCase = true;
        } else {
            document.getElementById("upperCase").classList.remove("text-success");
            document.getElementById("upperCase").classList.add("text-danger");
            passwordPattern.lowerCase = false;
        }

        const number = /[0-9]/g;
        if (req.confirmNewPassword.match(number)) {
            document.getElementById("number").classList.remove("text-danger");
            document.getElementById("number").classList.add("text-success");
            passwordPattern.number = true;
        } else {
            document.getElementById("number").classList.remove("text-success");
            document.getElementById("number").classList.add("text-danger");
            passwordPattern.number = false;
        }

        if (req.confirmNewPassword.length > PASSWORD_MIN_LENGTH && req.confirmNewPassword.length < PASSWORD_MAX_LENGTH) {
            document.getElementById("chars").classList.remove("text-danger");
            document.getElementById("chars").classList.add("text-success");
            passwordPattern.charsLength = true;
        } else {
            document.getElementById("chars").classList.remove("text-success");
            document.getElementById("chars").classList.add("text-danger");
            passwordPattern.charsLength = false;
        }
          setPasswordPattern(passwordPattern);

        if (req.newPassword === req.confirmNewPassword) {
            document.getElementById("inputNewPassword").classList.add("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-valid");
            document.getElementById("inputNewPassword").classList.remove("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-invalid");
        } else {
            document.getElementById("inputNewPassword").classList.add("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-invalid");
            document.getElementById("inputNewPassword").classList.remove("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-valid");
        }
    };


    const sendUpdatePasswordRequest = (event) => {
        event.preventDefault();
        setShowLoading(true);
        const req = updatePasswordRequest;
        //alert(JSON.stringify(req)+"success");
        if (req.confirmNewPassword && req.confirmNewPassword.length < USERNAME_MIN_LENGTH
            || req.confirmNewPassword && req.confirmNewPassword.length > USERNAME_MAX_LENGTH) {
            toast.error("Your username must contain between " + USERNAME_MIN_LENGTH + " and " + USERNAME_MAX_LENGTH + " characters !");
            toast.error("error");
            return null;
        }

        if (req.confirmNewPassword && req.confirmNewPassword.length < PASSWORD_MIN_LENGTH
            || req.confirmNewPassword && req.confirmNewPassword.length > PASSWORD_MAX_LENGTH) {
            toast.error("Your password must contain between " + PASSWORD_MIN_LENGTH + " and " + PASSWORD_MAX_LENGTH + " characters !");
            toast.error("error");
            return null;
        }

        if (req.newPassword === req.confirmNewPassword) {
            const passwordPattern = setPasswordPattern;
            if (passwordPattern.upperCase && passwordPattern.lowerCase && passwordPattern.charsLength && passwordPattern.number) {
                updatePassword(updatePasswordRequest)
                    .then(res => {
                        //alert("after backend call");
                        // localStorage.setItem(
                        //     ACCESS_TOKEN,
                        //     res.accessToken ? res.accessToken : null
                        //   );
                          //alert("Your passowed changed")
                        toast.success("Your password has been changed !");
                        toast.success("success");
                        //history.push("/me/settings/account");
                        setShowLoading(false);
                        navigate('/');
                    }).catch(error => {
                    if (error.message) {
                        toast.error(error.message);
                        toast.error("error");
                    } else {
                       toast.error("Sorry! Something went wrong. Please try again!");
                       toast.error("error");
                    }
                    setShowLoading(false);
                    //console.log(error);
                    
                });
            } else {
                toast.error("Invalid password : please take care of password constraints !");
                toast.error("error");
            }
        } else {
            toast.error("Passwords does not match !");
            toast.error("error");
        }

    };
    
    

    return (
        <div className="text-center p-8 ">
           
            <h2 className='text-2xl ml-10 font-semibold mb-6'>Change password</h2>
           
            <br></br>
            <div  className="w-full md:w-2/3 lg:w-1/2 mx-auto">
                    {
                        updatePasswordRequest.showLoading
                            ?
                            <div className="text-center mb-6">
                                <h3 className="text-lg font-medium">Updating your password</h3>
                                <i className="material-icons w3-xxxlarge w3-spin ">refresh</i>
                            </div>
                            :
                            <form onSubmit={sendUpdatePasswordRequest}  className="space-y-6">

                                <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4 ">
                                    <label htmlFor="inputOldPassword" className=" text-gray-500 text-start">Your password:</label>
                                    <input type="password" 
className="col-span-2 w-full py-2 px-4 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:bg-white focus:border-blue-500"                                    placeholder="Enter your password" required autoComplete="on"
                                    onChange={updateOldPassword}
                                    />
                                </div>
                                <br></br>
                                <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                                    <label htmlFor="inputNewPassword" className=' text-gray-500   text-start'>New password:</label>
                                    <input type="password"
className="col-span-2 w-full py-2 px-4 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:bg-white focus:border-blue-500"                                           placeholder="Enter your new password" required autoComplete="on"
                                           onChange={updateNewPassword}
                                    />
                                </div>
                                <br></br>
                                <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4">    
                                                                  <label htmlFor="inputConfirmNewPassword" className=' text-gray-500 text-start'>Confirm new password:</label>
                                    <input type="password"
 className="col-span-2 w-full py-2 px-4 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:bg-white focus:border-blue-500"                                           placeholder="Enter your new password again" required autoComplete="on"
                                           onChange={updateConfirmNewPassword}
                                    />
                                </div>
                                
                                <br></br>
                                
                                <div className=' text-gray-500 text-sm  '>
                                <p className="text-muted">Your password must contain :</p>
                                <p className="text-danger" id="number">* One number</p>
                                <p className="text-danger" id="lowerCase">* One lower case letter</p>
                                <p className="text-danger" id="upperCase">* One upper case letter</p>
                                <p className="text-danger" id="chars">* Between 6 and 20 characters</p>

                                </div>
                                <br></br>
                                <div className="text-center mt-6">
                                <button
                      className="inline-block py-3 px-8 text-center align-middle font-sans text-xs font-bold uppercase rounded-lg  shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="submit"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                                Change Password</button>

                             </div>
                             

                            </form>
                    }

                </div>
                <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
          />

        </div>
    );
};

export default AccountSettings;
