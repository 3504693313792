import React, { useState } from "react";
import { probationActionRequest } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";


const EmploymentStatus = ({ name, email }) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [action, setAction] = useState({
    probationActionRequest: "Download",
  });
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const probationAction = (event) => {
    setAction({ ...action, probationActionRequest: event.target.value });
  };
  const probationLetterAction = (event) => {
    setShowLoading(false);
    const usernameOrEmail = email;
    event.preventDefault();
    const probationActionData = {
      probationRequestAction: action.probationActionRequest,
      username: usernameOrEmail,
    };
    probationActionRequest(probationActionData)
      .then((res) => {
        if (action.probationActionRequest === "Download") {
          let binaryString = window.atob(res.data);

          let binaryLen = binaryString.length;

          let bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: "application/pdf" });

          let link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = probationActionData.username + ".pdf";

          link.click(); 
          toast.success("Employee probation letter downloaded successfully");
          setShowModal(false);
        } else {
          toast.success("Employee probation letter mail sent successfully");
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <div>
    
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Probation Action
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + " { " + email + " }"}
                  </h3>
                  <form onSubmit={probationLetterAction}>
                    <div>
                      <div className="form-group">
                        <label htmlFor="category"> Action</label>
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          //focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"

                          // className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name="option"
                          value={action.probationActionRequest}
                          onChange={(event) => probationAction(event)}
                        >
                          <option value="Download">Download</option>
                          <option value="sendMail">Send Mail</option>
                        </select>
                      </div>
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <Toaster
        position="bottom-right" 
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default EmploymentStatus;
