import React, { useEffect, useState } from "react";
import {useNavigate,useParams} from 'react-router-dom';
import {subscribe} from "../../../api/Api";
import toast, { Toaster } from "react-hot-toast";


const ConfirmSubscription = (props) => {
const navigate=useNavigate();
    let { id } = useParams(); 
    let { token } = useParams(); 

    const [subscriptionRequest, setSubscriptionRequest] = useState({
      id: 0,
      token: "",
      status: "",
    });
    const [showLoading, setShowLoading] = useState(true);
    useEffect(() => {
        //console.log(`/something/${id}`);
       // console.log(`/something/${token}`);

        setShowLoading(true);
        document.title = "Confirm subscription";
    if (id && token) {
        let req = subscriptionRequest;
        req.id = id;
        req.token = token;
        req.status='subscribed';
        setSubscriptionRequest(req);
    } else {
        //this.props.showAlert("Invalid subscription link ! may be you can re create your account.", "error");
        toast("Invalid subscription link ! may be you can re create your account.")
        navigate("/signup")
        //this.props.history.push("/signup");
    }
  subscribeUser();
    },[]);
    const subscribeUser = () => {
      subscribe(subscriptionRequest)
    .then(res => {
        //this.props.showAlert("Your account subscription has been completed ! Please login now.", "success");
        //this.props.history.push("/login");
        toast("Your account subscription has been completed ! Please login now.")
        navigate("/login")
        setSubscriptionRequest('');
        setShowLoading(false);
    }).catch(error => {
    if (error.message && error.success === false) {
      toast(error.message);
       // this.props.showAlert(error.message, "error");
    } else {
      toast(error.message);
       // this.props.showAlert(error.message, "error");
    }
    
    console.log(error);
  });
    }
    return (
      <div className="container">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin my-5">
                  <div className="card-body">


                      {
                          showLoading
                              ?
                              <div>
                                  <h1 className="card-title text-center">Loading. Please wait...</h1>
                                  <i className="material-icons w3-xxxlarge w3-spin d-flex justify-content-center">refresh</i>
                              </div>
                              : navigate("/login")
                               
                      }
                  </div>
              </div>
          </div>
          <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#fff",
              color: "#FF4136",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#fff",
              color: "#008000",
            },
          },
        }}
      />
        
      </div>
  );
  }
export default ConfirmSubscription; 