import React, { useEffect, useState } from "react";
import { contentData, contentPutData } from "../../../../../api/commonApi";
import { CustomInput, FormGroup } from 'reactstrap';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../src/contexts/StateContext';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from "react-router-dom";
import { getContent } from "../../../../../api/Api";
import { Link } from "react-router-dom";

const UpdateContent = (props) => {
  console.log("props printing from console" + JSON.stringify(props));
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const location = useLocation();
  // Check if state is available before using it

  if (!location.state) {
    // Handle the case where state is not available
    return <div>No state found</div>;
  }
  const { contentEndPoint } = location.state;
  const { category } = location.state;
  console.log(contentEndPoint + category);




  const [endpoint, setEndpoint] = useState('');
  // const[contentEndPoint,setContentEndPoint] = useState('');
  const [primaryHeader, setPrimaryHeader] = useState('');
  const [categoryState, setCategoryState] = useState('');
  //const [category, setCategory] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [publishedBy, setPublishedBy] = useState('');
  const [viewCount, setViewCount] = useState('');
  const [state, setState] = useState();
  const [previousYouTubeLink, setPreviousYouTubeLink] = useState('');


  const [contentInfoData, setContentInfoData] = useState([
    {
      contentHeader: '',
      contentBody: '',
      codeSnippet: '',
      contentImageBody: null,
      contentImageName: '',
      invalidContentFile: false,
      youTubeLink:'',
    },
  ]);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // updateViewContentRequest();
    setEndpoint(contentEndPoint);
    setCategoryState(category);
    setPrimaryHeader('');
    // alert(endpoint+"from useEffect")
    updateViewContentResponse();
    if (contentInfoData.length > 1) {
      setPreviousYouTubeLink(contentInfoData[contentInfoData.length - 2].youTubeLink);
    }
  }, [endpoint, category]);



  const updateViewContentResponse = () => {
    //const data = endpoint;
    getContent(endpoint, category)
      .then((res) => {
      
        if (res.data === null) {
          toast(res.message);
        } else {
          setPrimaryHeader(res.data.primaryHeader);
          setContentInfoData(res.data.contentList);
          setCategoryState(res.data.category);
          setEndpoint(res.data.endpoint);
          setCreatedAt(res.data.createdAt);
          setPublishedBy(res.data.publishedBy);
          setViewCount(res.data.viewCount);
          setPreviousYouTubeLink(res.data.youTubeLink); // Set previous YouTube link separately
          setContentInfoData(res.data.contentList.map((item, i) => ({
            ...item,
            youTubeLink: res.data.contentList[i].youTubeLink, // Set youTubeLink individually for each item
          })));         // setYoutubeLink(res.data.youTubeLink);
        }

        setShowLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);

        if (error.response && error.response.status === 401) {
          toast.error('You are not eligible to access this endpoint');
        }

        setShowLoading(false);
      });

  };

  const handleInfoAddClick = () => {
    setContentInfoData(prevData => [
      ...prevData,
      {
        contentHeader: '',
        contentBody: '',
        codeSnippet: '',
        contentImageBody: null,
        contentImageName: '',
        invalidContentFile: false,
        youTubeLink:'',   
         }
        ]);
    
  };

  const fileDataConverter = async (fileData) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
  
    try {
      const base64Data = await toBase64(fileData);
      return base64Data;
    } catch (error) {
      console.error("Error converting file data to base64:", error);
      return null;
    }
  };
  

  const handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    if (name === 'category') {
      setCategoryState(value);

      if (name === 'youTubeLink') {
        const updatedList = contentInfoData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              youTubeLink: value,
            };
          }
          return item;
        });
        setContentInfoData(updatedList);

      if (e.target.name === 'contentImageBody') {
        const { files } = e.target;
        

        if (files && files.length) {
          const [{ size, name }] = files;
          const maxSize = 100000;

          if (size < maxSize) {
            const list = [...contentInfoData];

            list[index].contentImageName = name;
            list[index].invalidContentFile = false;

            fileDataConverter(files[0]).then((response) => {
              list[index].contentImageBody = response;
              setContentInfoData(list);
            });
          } else {
            const list = [...contentInfoData];
            list[index].contentImageName = '';
            list[index].invalidContentFile = true;
            setContentInfoData(list);
            // showAlert('Image size should not exceed 100kb', 'error');
            toast.message('Image size should not exceed 100kb');
            setShowLoading(false);
            // props.history.push('/publish/editcontent');
          }
        }
      }
    }
  }
    const list = [...contentInfoData];
    list[index][name] = value;
    setContentInfoData(list);
  };

  const handleInfoRemoveClick = (index) => {
    const list = [...contentInfoData];
    list.splice(index, 1);
    setContentInfoData(list);
  };
  


  const publishContentEditUpdate = (event) => {
    event.preventDefault();
    setShowLoading(true);

    const contentUpdate = {
      endpoint,
      primaryHeader,
      category,
      createdAt,
      publishedBy,
      viewCount,
      contentInfoData,
    };

    contentPutData(contentUpdate)
      .then((response) => {
        const { data } = response;
        toast.success("Content is updated successfully", data.message);
        setShowLoading(false);

        setPrimaryHeader('');
        setCategoryState('');
        setPublishedBy('');
        setCreatedAt('');
        setViewCount('');
        setEndpoint('');
        setContentInfoData([
          {
            contentHeader: '',
            contentBody: '',
            codeSnippet: '',
            contentImageBody: null,
            contentImageName: '',
            invalidContentFile: false,
            youTubeLink:'',
          },
        ]);

        setShowLoading(false);
        // props.history.push('/publish/editContent');
      })
      .catch((error) => {
        // showAlert(error, 'error');
        toast(error);
        setShowLoading(false);
      });
  };

  const backToHome = () => {
    navigate("/editcontent");
  };

  return (
    <div className="w-full md:10 mx-auto p-3">
      <h2 className="md:text-xl sm:text-lg p-3 font-semibold text-center uppercase mb-3">{endpoint} </h2>
      <div className="flex justify-between items-center mb-3">
        <button className="bg-blue-500 text-white font-bold px-4 py-2 rounded" onClick={backToHome}>
          <div className="d-inline-block bi bi-download me-2" />
          Back to edit content
        </button>
      </div>
      <br></br>
      <div>
        {showLoading ? (
          <div className="align-content-center text-center">
            <h3>Updating your content data</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              refresh
            </i>
          </div>
        ) : (
          <form onSubmit={publishContentEditUpdate}>

            <div className="grid md:grid-cols-3">

              <div className="grid md:grid-row">
                <div className="mt-2 mx-3">

                  <label htmlFor="primaryHeader">Primary Header</label>
                  <br />
                  <input
                    type="text"
                    className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    id="primaryHeader"
                    name="primaryHeader"
                    placeholder="primary header"
                    required
                    autoComplete="on"
                    value={primaryHeader}
                    onChange={(e) => setPrimaryHeader(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <div className="mt-2 mx-3">
                    <h4 htmlFor="contentInfoData" style={{ fontWeight: 'bold' }}>Content Info</h4>

                    {contentInfoData.map((x, i) => {
                      return (
                        <div>
                          <label htmlFor="contentHeader" className="">
                            Content Header
                          </label>
                          <input
                            type="text"
                            className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            id="contentHeader"
                            name="contentHeader"
                            placeholder="content header"
                            required
                            autoComplete="on"
                            value={x.contentHeader}
                            onChange={(e) => handleContentInfo(e, i)}
                          />
                          <label htmlFor="contentBody" className="">
                            Content Body
                          </label>
                          <textarea
                            type="text"
                            className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            id="contentBody"
                            name="contentBody"
                            required
                            autoComplete="on"
                            placeholder="content body"
                            value={x.contentBody}
                            onChange={(e) => handleContentInfo(e, i)}
                          />
                          <label htmlFor="codeSnippet" className="">
                            Code Snippet
                          </label>
                          <textarea
                            type="text"
                            className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            id="codeSnippet"
                            name="codeSnippet"
                            autoComplete="on"
                            placeholder="code snippet"
                            value={x.codeSnippet}
                            onChange={(e) => handleContentInfo(e, i)}
                          />
                          <label htmlFor="youTubeLink"  className="">
                            youTubeLink
                          </label>
                          <input
                            type="text"
                            className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            id="youTubeLink"                           
                             name="youTubeLink"
                            autoComplete="on"
                            placeholder="youTubeLink"
                            value={x.youTubeLink}
                            onChange={(e) => handleContentInfo(e, i)}
                          />

                          <label htmlFor="profilePic">Content Image Body</label>

                          <FormGroup>
                            <CustomInput
                              type="file"
                              id="exampleCustomFileBrowser"
                              name="contentImageBody"
                              className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                              label={
                                x.contentImageName ||
                                "choose an image file size less than 100kb"
                              }
                              onChange={(e) => handleContentInfo(e, i)}
                              invalid={x.invalidContentFile}
                            />
                          </FormGroup>

                          {contentInfoData.length !== 1 && (
                            <button
                              className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              onClick={() => handleInfoRemoveClick(i)}
                              style={{ color: currentColor }}
                            >
                              Remove
                            </button>
                          )}
                          {contentInfoData.length - 1 === i && (
                            <button
                              className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              onClick={handleInfoAddClick}
                              style={{ color: currentColor }}
                            >
                              Add
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"

              style={{ color: currentColor }}
            >
              Submit
            </button>
          </form>
        )}
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: '#FF4136',
              color: '#fff',
            },
          },

          success: {
            duration: 6000,
            style: {
              background: '#008000',
              color: '#fff',
            },
          },
        }}
      />
    </div>
  );
}


export default UpdateContent;