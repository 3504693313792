import React from 'react';

const CallToAction = () => {
  return (
    <section id="call-to-action" className="py-20 bg-blue-600 text-white text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold">Ready to Make an Impact?</h2>
        <p className="mt-4 text-lg">Join us and be part of something extraordinary.</p>
        <a href="#apply" className="mt-8 inline-block bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold">
          Apply Now
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
