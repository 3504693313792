import React, { useEffect, useState } from "react";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Group,
  PdfExport
} from "@syncfusion/ej2-react-grids";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faExternalLinkAlt,
  faRecycle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { applicantsGrid } from "../../../../../data/staticData";
import { Button, Header } from "../../../../../components";
import { getApplicants, getApplicantsPage, getEmployee, getEmployeeData } from "../../../../../api/HrApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import ApplyLeave from "./ApplyLeave";
import LevelUpdate from "./LevelUpdate";
import TimeSheet from "./TimeSheet";
import LeaveData from "./LeaveData";
import CategoryUpdate from "./CategoryUpdate";
import ActionEmployee from "./ActionEmployee";
import { useNavigate } from 'react-router-dom';


function EmployeeData() {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const navigate = useNavigate();
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  //const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);

  const toolbarOptions = [
    "Search",
    'SortAscending',
    'PdfExport'
  ];
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [content, setContent] = useState([]);
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let applicantsList = [];
  useEffect(() => {
    document.title = "Employee Data";
    loadData();
  }, []);
  const loadData = () => {
    setLoading(true)
    getEmployee(searchText)
      .then((res) => {
       
        const content = [];
        let user = {
          leaveStatus: '',
          name: '',
          totalLeaveTaken: '',
          totalEligibleLeaves: '',
        };

        for (let i = 0; i < res.data.data.length; i++) {
          user = res.data.data[i];
          const sickLeavesTaken = user.sickLeavesTaken ? user.sickLeavesTaken : 0;
          const vacationLeavesTaken = user.vacationLeavesTaken ? user.vacationLeavesTaken : 0;
          const totalLeaveTaken = sickLeavesTaken + vacationLeavesTaken;
          const eligibleSickLeaves = user.eligibleSickLeaves ? user.eligibleSickLeaves : 0;
          const eligibleVacationLeaves = user.eligibleVacationLeaves ? user.eligibleVacationLeaves : 0;
          const totalEligibleLeaves = eligibleSickLeaves + eligibleVacationLeaves;
          const leaveStatus = totalEligibleLeaves - totalLeaveTaken;
          user.leaveStatus = leaveStatus;
          user.totalEligibleLeaves = totalEligibleLeaves;
          user.totalLeaveTaken = totalLeaveTaken;
          content.push(user);
        }
        content.sort((a, b) => ((a.leaveStatus > b.leaveStatus) ? 1 : ((b.leaveStatus > a.leaveStatus) ? -1 : 0)));
        setContent(content);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
            toast.error(error.message);
            toast.error("error");
        } else {
            toast.error('Sorry! Something went wrong. Please try again!');
            toast.error("error");
        }
        setLoading(false)
        console.log(error);
      });
      setLoading(false);
      
  
  }
  
  const handleViewProfileClick = (props) => {
    //alert(props.email)
    const state = {
      email: props.email,
    };

    navigate('/viewProfileViaHr', { state });
  };
  
  const actionTemplate = ((props) => {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
    const [role, setRole] = useState('');
    const [totalLeaves, setTotalLeaves] = useState('');
    const [timeSheet, setTimeSheet] = useState([]);
    const [leaveList, setLeaveList] = useState([]);
     

   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email);
      setRole(props.role);
      setTotalLeaves(props.totalLeaves);
      setTimeSheet(props.timeSheetList);
      setLeaveList(props.leaveDataList);      
    };
    return (
      <ActionEmployee name={userName} email={userEmail} role={role} totalLeaves={totalLeaves} timeSheet={timeSheet} leaveListData={leaveList}/>
    )
  })
  
  const leaveStatusTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
    const [leaveStatus, setLeaveStatus] = useState([]);
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email); 
      setLeaveStatus(props.leaveStatus);  
    };
    return (
       
      <>
      {leaveStatus<0 ? (
                <span className="badge bg-deep-orange-800 p-1 text-white rounded-lg">Irregular</span>
              ) : (
                <span className="badge bg-green-700 p-1  text-white rounded-lg">Regular</span>
              )}
    </>
       
    )
  })
  const differnceTemplate = ((props) => {
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail ] = useState('');
    const [leaveStatus, setLeaveStatus] = useState([]);
   useEffect(()=> {
    showDetail();
   }, []);
    const showDetail = () => {
      setUserName(props.name);
      setUserEmail(props.email); 
      setLeaveStatus(props.leaveStatus);  
    };
    
    return (     
      <div>
     <span className="p-1">{leaveStatus}</span> 
      {leaveStatus<0 ? (
          <small className="text-muted">(₹ {leaveStatus*-500})</small>
              ) : (
                <small className="text-muted">(₹ 0)</small>
              )}
              </div>
      
    )
  })
  
 

  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl">Employees Page</h2>
      <GridComponent
        dataSource={content}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      > 
        <ColumnsDirective>
      {/*  <ColumnDirective field='action' headerText='Action' textAlign="Center" isPrimaryKey={true}  width='100' template={onboardTemplate} />
          <ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />
         */} 
          <ColumnDirective field='name' headerText='Name' width='100'  textAlign="Center" />
          <ColumnDirective field='totalLeaveTaken' headerText='totalLeaveTaken' width='80'  textAlign="Center" />
          <ColumnDirective field='totalEligibleLeaves' headerText='totalEligibleLeaves' width='80'  textAlign="Center" />
          <ColumnDirective field='action' headerText='Action' width='100'  textAlign="Center" template={actionTemplate} />
          <ColumnDirective
            headerText="View Profile"
            width="100"
            textAlign="Center"
            template={(props) => (
              <Link
                to={{
                  pathname: "/viewProfileData",
                }}
                state={{
                  email: props.email,
                }}
                exact
              >
                <button
                  className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
                  type="button"
                >
                  {" "}
                  <span style={{ color: currentColor }}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </span>{" "}
                </button>
              </Link>
            )}
          />
          <ColumnDirective field='leaveStatus' headerText='Difference' width='100'  textAlign="Center" template={differnceTemplate} />
          <ColumnDirective field='leaveStatus' headerText='Status' width='100'  textAlign="Center" template={leaveStatusTemplate} />
          
          
         {/* {applicantsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
         ))}*/}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter,  Group, PdfExport]} />
      </GridComponent>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}

export default EmployeeData;
