const MobileFriendlyEn={

  title:"Get Your Free Mobile-Friendly Web App with Yaazhtech!",
  titleExplain:"In today's mobile-first world, having a website that looks great and functions flawlessly on all devices is no longer a luxury, it's a necessity. At Yaazhtech, we understand the importance of a mobile responsive website design. That's why we're offering a FREE mobile-friendly web app to help you take your online presence to the next level!",
 
  whyChoose:"Why Choose Yaazhtech's Free Mobile-Friendly Web App?",
  seamless:"Ensure your website flawlessly adapts to any screen size, from desktops to tablets and smartphones. No more pinching and zooming for your visitors! View your site on mobile with ease, just like it was designed for them.",
  enhanced:"Keep your audience engaged with a mobile responsive website that's intuitive and easy to navigate on the go. This translates to a more enjoyable user experience and potentially, more conversions.",
  boost:"Search engines prioritize mobile-friendly websites. Our mobile responsive web design helps your website rank higher in search results, leading to increased organic traffic.",
  free:"Get started with our mobile-friendly webapp at no cost. We'll guide you through the simple setup process, ensuring your website is mobile-optimized in no time.",
  
 who:"Who Can Benefit from This Free Offer?",
 small:"Establish a strong online presence without breaking the bank. Our free web app is perfect for showcasing your brand and services to a mobile audience.",
 entrepreneur:"Make a lasting impression with a professional-looking website that adapts to any device. Our mobile responsive web solution is ideal for building your online credibility.",
 nonProfit:"Reach a wider audience and spread your message effectively with a website that's accessible to everyone, regardless of their device.",

ready:"Ready to Get Started?",
dontMiss:"Don't miss out on this incredible opportunity! Sign up today and claim your FREE mobile-friendly web app from Yaazhtech. Take control of your online presence and unlock the power of a mobile-optimized website!",

question:"Still Have Questions?",
quesExplain:" We'll discuss your specific needs and ensure you have all the information you need to make an informed decision.",

button:"Get Your Free Web App",
go:"Go mobile with Yaazhtech and experience the difference!",

};
export {MobileFriendlyEn};