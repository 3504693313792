import React from 'react';

const Testimonial = () => {
  return (
    <section id="testimonial" className="py-20 bg-blue-50 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-gray-800">What Our Team Says</h2>
        <p className="mt-4 text-lg text-gray-600">Hear from the people who are building something great with us.</p>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Add testimonial cards */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <p className="italic text-gray-600">"Working here has been an incredible experience. The team is supportive, and the projects are challenging yet rewarding."</p>
            <h4 className="mt-4 text-xl font-semibold text-gray-800">VarshaSri, Team Lead</h4>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <p className="italic text-gray-600">"Being a part of this team has been an amazing journey. The collaborative environment and the challenging projects."</p>
            <h4 className="mt-4 text-xl font-semibold text-gray-800">Rupavarshini, Team Lead</h4>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <p className="italic text-gray-600">"The combination of a supportive team and engaging projects has allowed me to push my boundaries and achieve new milestones in my career."

</p>
            <h4 className="mt-4 text-xl font-semibold text-gray-800">Rajappan, Software Engineer</h4>
          </div>
          {/* More testimonials */}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
