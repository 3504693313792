import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { SiOracle, SiReact } from "react-icons/si";
import SiderealDate from "./SiderialTime";
import { FiHome } from "react-icons/fi";
import { useContextSelector } from "use-context-selector";
import { Dropdown } from "reactstrap";
import { AiFillCaretDown } from "react-icons/ai";
import {
  links,
  carrerlist,
  userLinks,
  employeeLinks,
  adminLinks,
  financierLinks,
  clientLinks,
  hrLinks,
} from "../data/staticData";
import logo from "../assets/images/logos/logoFinal30x30.png";
import { StateContext } from "../contexts/StateContext";
import {
  ACCESS_TOKEN,
  ADMIN,
  HR,
  EMPLOYEE,
  CLIENT,
  CONTENT_ENDPOINTS,
  FINANCIER,
  USER,
  EMPLOYER,
} from "../config/Config";
import { useStateContext } from "../contexts/ContextProvider";
import { greeting } from "../data/content/PageContent";

function Sidebar() {
  const activeMenu = useContextSelector(
    StateContext,
    (state) => state.activeMenu
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const setActiveMenu = useContextSelector(
    StateContext,
    (state) => state.setActiveMenu
  );
  const contentEndpoints = useContextSelector(
    StateContext,
    (state) => state.contentEndpoints
  );
  const screenSize = useContextSelector(
    StateContext,
    (state) => state.screenSize
  );
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setRole = useContextSelector(StateContext, (state) => state.setRole);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    return time.toLocaleTimeString();
  };

  const formatDate = (date) => {
    return date.toLocaleDateString();
  };
  const role = useContextSelector(StateContext, (state) => state.role);
  const handleCloseSideBar = () => {
    // if (activeMenu !== undefined && screenSize <= 900) {
    setActiveMenu(false);
    window.scrollTo({ top: 0, behavior: "instant" });
    //}
  };
  const [open, setOpen] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [evaluation, setEvaluation] = useState(true);
  const [subMenuEvaluation, setSubMenuEvaluation] = useState(false);
  const [myProfileOpen, setMyProfileOpen] = useState(false);
  const [profilesPool, setProfilesPool] = useState(false);
  const [updateMyProfileOpen, setUpdateMyProfileOpen] = useState(false);
  const [skillsOpen, setSkillsOpen] = useState(false);
  const [subMenuProfile, setSubMenuProfile] = useState(false);
  const [defaultProfile, setDefaultProfile] = useState(false);
  const [subMenuInvoiceClient, setSubMenuInvoiceClient] = useState(true);
  const [subMenuDefaultClient, setSubMenuDefaultClient] = useState(false);

  const [employee, setEmployee] = useState(true);
  const [subMenuEmployee, setSubMenuEmployee] = useState(false);
  const [hr, setHr] = useState(true);
  const [subMenuHr, setSubMenuHr] = useState(false);
  const [admin, setAdmin] = useState(true);
  const [subMenuAdmin, setSubMenuAdmin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2";
  let menuItemsLeft;
  let decodedToken;
  const subHeader = [];
  const [javaScriptOpen, setJavaScriptOpen] = useState(true);
  const [subMenuJavaScriptOpen, setSubMenuJavaScriptOpen] = useState(false);

  const [subMenuEvaluationOpen, setSubMenuEvaluationOpen] = useState(false);
  const [javaOpen, setJavaOpen] = useState(true);
  const [subMenuJavaOpen, setSubMenuJavaOpen] = useState(false);
  const [golangOpen, setGolangOpen] = useState(true);
  const [subMenuGolangOpen, setSubMenuGolangOpen] = useState(false);
  const [environmentOpen, setEnvironmentOpen] = useState(true);
  const [subMenuEnvironmentOpen, setSubMenuEnvironmentOpen] = useState(false);
  const [flexPLMOpen, setFlexPLMOpen] = useState(true);
  const [subMenuFlexPLMOpen, setSubMenuFlexPLMOpen] = useState(false);
  const [awsOpen, setAwsOpen] = useState(true);
  const [subMenuAwsOpen, setSubMenuAwsOpen] = useState(false);
  const [identityOpen, setIdentityOpen] = useState(true);
  const [subMenuIdentityOpen, setSubMenuIdentityOpen] = useState(false);
  const [reactJsOpen, setReactJsOpen] = useState(true);
  const [subMenuReactJsOpen, setSubMenuReactJsOpen] = useState(false);
  const [camundaOpen, setCamundaOpen] = useState(true);
  const [subMenuCamundaOpen, setSubMenuCamundaOpen] = useState(false);
  const [testingOpen, setTestingOpen] = useState(true);
  const [subMenuTestingOpen, setSubMenuTestingOpen] = useState(false);
  const [mainframeOpen, setMainframeOpen] = useState(true);
  const [subMenuMainframeOpen, setSubMenuMainframeOpen] = useState(false);
  const [softSkillsOpen, setSoftSkillsOpen] = useState(true);
  const [subMenuSoftSkillsOpen, setSubMenuSoftSkillsOpen] = useState(false);
  const [dataStructureOpen, setDataStructureOpen] = useState(true);
  const [subMenuDataStructureOpen, setSubMenuDataStructureOpen] =
    useState(false);
  const [springbootOpen, setSpringbootOpen] = useState(true);
  const [subMenuSpringbootOpen, setSubMenuSpringbootOpen] = useState(false);
  const [databaseOpen, setDatabaseOpen] = useState(true);
  const [subMenuDatabaseOpen, setSubMenuDatabaseOpen] = useState(false);
  const [othersOpen, setOthersOpen] = useState(true);
  const [subMenuOthersOpen, setSubMenuOthersOpen] = useState(false);
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (isLoggedIn) {
    menuItemsLeft = [
      <>
        {userLinks.map((item) => (
          <div key={item.title}>
            <p className="font-semibold dark:text-black-400 ml-3 -mt-8 mb-5 uppercase">
              {item.title}
            </p>

            <p className="font-semibold dark:text-black-400 ml-3 mt-5 mb-5 uppercase">
              {item.dashboard}
            </p>

            {item.links.map((link) => (
              <ul>
                <li>
                  {link.name === "my profile" ? (
                    <Dropdown className="gap-1">
                      {link.subMenuLinks && (
                        <div
                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                          onClick={() => setMyProfileOpen(!myProfileOpen)}
                        >
                          <span className="data">{link.icon}</span>
                          <span className="capitalize">{link.name}</span>

                          <AiFillCaretDown
                            className={`${
                              myProfileOpen && "rotate-180"
                            } ml-auto`}
                          />
                        </div>
                      )}
                      {link.subMenuLinks && myProfileOpen && (
                        <ul>
                          {link.subMenuLinks.map((subMenuItem, idx) => (
                            <li>
                              <NavLink
                                to={`/${subMenuItem.url}`}
                                key={subMenuItem.url}
                                onClick={handleCloseSideBar}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? currentColor : "",
                                })}
                                className={({ isActive }) =>
                                  isActive ? activeLink : normalLink
                                }
                              >
                                {subMenuItem.icon}
                                <span className="capitalize ">
                                  {subMenuItem.name}
                                </span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Dropdown>
                  ) : (
                    <>
                      {link.name === "update Myprofile" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() =>
                                setUpdateMyProfileOpen(!updateMyProfileOpen)
                              }
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  updateMyProfileOpen && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && updateMyProfileOpen && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : (
                        <>
                          {link.name === "skills" ? (
                            <Dropdown className="gap-1">
                              {link.subMenuLinks && (
                                <div
                                  className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                  onClick={() => setSkillsOpen(!skillsOpen)}
                                >
                                  <span className="data">{link.icon}</span>
                                  <span className="capitalize">
                                    {link.name}
                                  </span>

                                  <AiFillCaretDown
                                    className={`${
                                      skillsOpen && "rotate-180"
                                    } ml-auto`}
                                  />
                                </div>
                              )}
                              {link.subMenuLinks && skillsOpen && (
                                <ul>
                                  {link.subMenuLinks.map((subMenuItem, idx) => (
                                    <li>
                                      <NavLink
                                        to={`/${subMenuItem.url}`}
                                        key={subMenuItem.url}
                                        onClick={handleCloseSideBar}
                                        style={({ isActive }) => ({
                                          backgroundColor: isActive
                                            ? currentColor
                                            : "",
                                        })}
                                        className={({ isActive }) =>
                                          isActive ? activeLink : normalLink
                                        }
                                      >
                                        {subMenuItem.icon}
                                        <span className="capitalize ">
                                          {subMenuItem.name}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Dropdown>
                          ) : (
                            <NavLink
                              to={`/${link.url}`}
                              key={link.url}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                              className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                              }
                            >
                              {link.icon}
                              <span className="capitalize ">{link.name}</span>
                            </NavLink>
                          )}
                        </>
                      )}
                    </>
                  )}
                </li>
              </ul>
            ))}
          </div>
        ))}
        {role === EMPLOYEE ? (
          <>
            {/**  {employeeLinks.map((item) => (
              <div>
                {item.links.map((link) => (
                  <ul>
                    <li>
                      {link.name === "employee dashboard" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() =>
                                setSubMenuEmployee(!subMenuEmployee)
                              }
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${subMenuEmployee && "rotate-180"
                                  } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuEmployee && employee && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  {subMenuItem.name === "leave policy" ? (
                                    <Dropdown className="gap-1">
                                      {subMenuItem.subMenuLinks && (
                                        <div
                                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                          onClick={() =>
                                            setSubMenuProfile(!subMenuProfile)
                                          }
                                        >
                                          <span className="data">
                                            {subMenuItem.icon}
                                          </span>
                                          <span className="capitalize">
                                            {subMenuItem.name}
                                          </span>

                                          <AiFillCaretDown
                                            className={`${subMenuProfile && "rotate-180"
                                              } ml-auto`}
                                          />
                                        </div>
                                      )}
                                      {subMenuItem.subMenuLinks &&
                                        subMenuProfile &&
                                        profile && (
                                          <ul>
                                            {subMenuItem.subMenuLinks.map(
                                              (subDropMenuItem, idx) => (
                                                <li>
                                                  <NavLink
                                                    to={`/${subDropMenuItem.url}`}
                                                    key={subDropMenuItem.url}
                                                    onClick={handleCloseSideBar}
                                                    style={({ isActive }) => ({
                                                      backgroundColor: isActive
                                                        ? currentColor
                                                        : "",
                                                    })}
                                                    className={({ isActive }) =>
                                                      isActive
                                                        ? activeLink
                                                        : normalLink
                                                    }
                                                  >
                                                    {subDropMenuItem.icon}
                                                    <span className="capitalize ">
                                                      {subDropMenuItem.name}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                    </Dropdown>
                                  ) : (
                                    <NavLink
                                      to={`/${subMenuItem.url}`}
                                      key={subMenuItem.url}
                                      onClick={handleCloseSideBar}
                                      style={({ isActive }) => ({
                                        backgroundColor: isActive
                                          ? currentColor
                                          : "",
                                      })}
                                      className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                      }
                                    >
                                      {subMenuItem.icon}
                                      <span className="capitalize ">
                                        {subMenuItem.name}
                                      </span>
                                    </NavLink>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : null}
                    </li>
                  </ul>
                ))}
              </div>
            ))} */}
          </>
        ) : null}
        {role === ADMIN ? (
          <>
            {" "}
            {adminLinks.map((item) => (
              <div>
                {item.links.map((link) => (
                  <ul>
                    <li>
                      {link.name === "admin dashboard" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() => setSubMenuAdmin(!subMenuAdmin)}
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuAdmin && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuAdmin && admin && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : null}
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </>
        ) : null}
        {role === FINANCIER ? (
          <>
            {" "}
            {financierLinks.map((item) => (
              <div>
                {item.links.map((link) => (
                  <ul>
                    <li>
                      {link.name === "dashboard" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() => setSubMenuAdmin(!subMenuAdmin)}
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuAdmin && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuAdmin && admin && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : (
                        <NavLink
                          to={`/${link.url}`}
                          key={link.url}
                          onClick={handleCloseSideBar}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : "",
                          })}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          {link.icon}
                          <span className="capitalize ">{link.name}</span>
                        </NavLink>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </>
        ) : null}
        {role === CLIENT ? (
          <>
            {" "}
            {clientLinks.map((item) => (
              <div>
                {item.links.map((link) => (
                  <ul>
                    <li>
                      {link.name === "Payment & Invoices" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() =>
                                setSubMenuInvoiceClient(!subMenuInvoiceClient)
                              }
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuInvoiceClient && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuInvoiceClient && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() =>
                                setSubMenuDefaultClient(!subMenuDefaultClient)
                              }
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuDefaultClient && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuDefaultClient && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </>
        ) : null}
        {role === HR ? (
          <>
            {" "}
            {hrLinks.map((item) => (
              <div>
                {item.links.map((link) => (
                  <ul>
                    <li>
                      {link.name === "hr dashboard" ? (
                        <Dropdown className="gap-1">
                          {link.subMenuLinks && (
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() => setSubMenuHr(!subMenuHr)}
                            >
                              <span className="data">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuHr && "rotate-180"
                                } ml-auto`}
                              />
                            </div>
                          )}
                          {link.subMenuLinks && subMenuHr && hr && (
                            <ul>
                              {link.subMenuLinks.map((subMenuItem, idx) => (
                                <li>
                                  <NavLink
                                    to={`/${subMenuItem.url}`}
                                    key={subMenuItem.url}
                                    onClick={handleCloseSideBar}
                                    style={({ isActive }) => ({
                                      backgroundColor: isActive
                                        ? currentColor
                                        : "",
                                    })}
                                    className={({ isActive }) =>
                                      isActive ? activeLink : normalLink
                                    }
                                  >
                                    {subMenuItem.icon}
                                    <span className="capitalize ">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Dropdown>
                      ) : (
                        <NavLink
                          to={`/${link.url}`}
                          key={link.url}
                          onClick={handleCloseSideBar}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : "",
                          })}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          {link.icon}
                          <span className="capitalize ">{link.name}</span>
                        </NavLink>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </>
        ) : null}
      </>,
    ];
  } else {
    menuItemsLeft = [
      <>
        {/*
      {links.map((item) => (
        <div key={item.title}>
        <p className="font-semibold dark:text-black-400 ml-3 -mt-8 -mb-5 uppercase">
          {item.title}
        </p>
        </div>
        ))}
         {links.map((item) => (
          <div key={item.title}>
            <p className="text-black-400 dark:text-black-400 m-3 mt-4 uppercase">
              {item.title}
            </p>
            {item.links.map((link) => (
              <ul>
                <li>
                  {link.name === 'careers' ? (
                    <Dropdown className="gap-1">
                      {link.subMenuLinks && (
                        <div
                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                          onClick={() => setSubMenuOpen(!subMenuOpen)}
                        >
                          <span className="data">{link.icon}</span>
                          <span className="capitalize">{link.name}</span>

                          <AiFillCaretDown
                            className={`${subMenuOpen && 'rotate-180'
                              } ml-auto`}
                          />
                        </div>
                      )}
                      {link.subMenuLinks && subMenuOpen && open && (
                        <ul>
                          {link.subMenuLinks.map((subMenuItem, idx) => (
                            <li>
                              <NavLink
                                to={`/${subMenuItem.url}`}
                                key={subMenuItem.url}
                                onClick={handleCloseSideBar}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive
                                    ? currentColor
                                    : '',
                                })}
                                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                              >
                                {subMenuItem.icon}
                                <span className="capitalize ">
                                  {subMenuItem.name}
                                </span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Dropdown>
                  ) : (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.url}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : '',
                      })}
                      className={({ isActive }) => (isActive ? activeLink : normalLink)}
                    >
                      {link.icon}
                      <span className="capitalize ">{link.name}</span>
                    </NavLink>
                  )}
                </li>
              </ul>
            ))}
          </div>
        ))} */}
      </>,
    ];
  }

  return (
    <div
      className={
        currentMode === "Dark"
          ? "bg-[#f5f5f5] h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
          : "bg-[#f5f5f5] h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
      }
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-3xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} alt="yaazhtech logo" />
              <span style={{ color: currentColor }}>YaazhTech</span>
            </Link>
            
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-black mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>

          <div  className=" p-1 text-gradient-from-blue-to-pink text-slate-900 text-center text-sm">Teamwork Makes Dream </div>
          
          
          <br></br>
          <div>
            <h4 className="flex ml-3 space-x-4">
              <strong>G :</strong>
              {formatDate(currentTime)} <strong> T : </strong>
              {formatTime(currentTime)}
            </h4>
            <div className="flex space-x-4">{/**<SiderealDate /> */}</div>
          </div>
          <br></br>
          <div className="current-location"></div>
          <div className="mt-10 ">{menuItemsLeft}</div>
          <br></br>
          <div className="mt-8">
            {" "}
            <p className="font-semibold dark:text-black-400 ml-3 -mt-8 -mb-5 uppercase">
              tech stack
            </p>
          </div>
          <div className="mt-10">
            {contentEndpoints.map((item) => (
              <div>
                <ul>
                  <li>
                    {item.category === "javascript" ? (
                      <Dropdown className="gap-1">
                        <div
                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                          onClick={() =>
                            setSubMenuJavaScriptOpen(!subMenuJavaScriptOpen)
                          }
                        >
                          <span className="capitalize font-semibold">
                            {item.category}
                          </span>

                          <AiFillCaretDown
                            className={`${
                              subMenuJavaScriptOpen && "rotate-180"
                            } ml-auto`}
                          />
                        </div>

                        {item.contentStateList.map((link) => (
                          <>
                            {link.endpoint &&
                              subMenuJavaScriptOpen &&
                              javaScriptOpen && (
                                <ul>
                                  <li>
                                    <NavLink
                                      to={`/${`${item.category}-${link.endpoint}`}`}
                                      key={link.endpoint + item.category}
                                      onClick={handleCloseSideBar}
                                      style={({ isActive }) => ({
                                        backgroundColor: isActive
                                          ? currentColor
                                          : "",
                                      })}
                                      className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                      }
                                    >
                                      {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                      <span className="capitalize ">
                                        {link.endpoint}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              )}
                          </>
                        ))}
                      </Dropdown>
                    ) : (
                      <>
                        {item.category === "evaluation" ? (
                          <Dropdown className="gap-1">
                            <div
                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                              onClick={() =>
                                setSubMenuEvaluationOpen(!subMenuEvaluationOpen)
                              }
                            >
                              <span className="capitalize font-semibold">
                                {item.category}
                              </span>

                              <AiFillCaretDown
                                className={`${
                                  subMenuEvaluationOpen && "rotate-180"
                                } ml-auto`}
                              />
                            </div>

                            {item.contentStateList.map((link) => (
                              <>
                                {link.endpoint && subMenuEvaluationOpen && (
                                  <ul>
                                    <li>
                                      <NavLink
                                        to={`/${`${item.category}-${link.endpoint}`}`}
                                        key={link.endpoint + item.category}
                                        onClick={handleCloseSideBar}
                                        style={({ isActive }) => ({
                                          backgroundColor: isActive
                                            ? currentColor
                                            : "",
                                        })}
                                        className={({ isActive }) =>
                                          isActive ? activeLink : normalLink
                                        }
                                      >
                                        {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                        <span className="capitalize ">
                                          {link.endpoint}
                                        </span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                )}
                              </>
                            ))}
                          </Dropdown>
                        ) : (
                          <>
                            {item.category === "java" ? (
                              <Dropdown className="gap-1">
                                <div
                                  className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                  onClick={() =>
                                    setSubMenuJavaOpen(!subMenuJavaOpen)
                                  }
                                >
                                  <span className="capitalize font-semibold ">
                                    {item.category}
                                  </span>

                                  <AiFillCaretDown
                                    className={`${
                                      subMenuJavaOpen && "rotate-180"
                                    } ml-auto`}
                                  />
                                </div>

                                {item.contentStateList.map((link) => (
                                  <>
                                    {link.endpoint &&
                                      subMenuJavaOpen &&
                                      javaOpen && (
                                        <ul>
                                          <li>
                                            <NavLink
                                              to={`/${`${item.category}-${link.endpoint}`}`}
                                              key={
                                                link.endpoint + item.category
                                              }
                                              onClick={handleCloseSideBar}
                                              style={({ isActive }) => ({
                                                backgroundColor: isActive
                                                  ? currentColor
                                                  : "",
                                              })}
                                              className={({ isActive }) =>
                                                isActive
                                                  ? activeLink
                                                  : normalLink
                                              }
                                            >
                                              {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                              <span className="capitalize ">
                                                {link.endpoint}
                                              </span>
                                            </NavLink>
                                          </li>
                                        </ul>
                                      )}
                                  </>
                                ))}
                              </Dropdown>
                            ) : (
                              <>
                                {item.category === "golang" ? (
                                  <Dropdown className="gap-1">
                                    <div
                                      className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                      onClick={() =>
                                        setSubMenuGolangOpen(!subMenuGolangOpen)
                                      }
                                    >
                                      <span className="capitalize font-semibold ">
                                        {item.category}
                                      </span>

                                      <AiFillCaretDown
                                        className={`${
                                          subMenuGolangOpen && "rotate-180"
                                        } ml-auto`}
                                      />
                                    </div>

                                    {item.contentStateList.map((link) => (
                                      <>
                                        {link.endpoint &&
                                          subMenuGolangOpen &&
                                          golangOpen && (
                                            <ul>
                                              <li>
                                                <NavLink
                                                  to={`/${`${item.category}-${link.endpoint}`}`}
                                                  key={
                                                    link.endpoint +
                                                    item.category
                                                  }
                                                  onClick={handleCloseSideBar}
                                                  style={({ isActive }) => ({
                                                    backgroundColor: isActive
                                                      ? currentColor
                                                      : "",
                                                  })}
                                                  className={({ isActive }) =>
                                                    isActive
                                                      ? activeLink
                                                      : normalLink
                                                  }
                                                >
                                                  {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                  <span className="capitalize ">
                                                    {link.endpoint}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </ul>
                                          )}
                                      </>
                                    ))}
                                  </Dropdown>
                                ) : (
                                  <>
                                    {item.category === "environmental-setup" ? (
                                      <Dropdown className="gap-1">
                                        <div
                                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                          onClick={() =>
                                            setSubMenuEnvironmentOpen(
                                              !subMenuEnvironmentOpen
                                            )
                                          }
                                        >
                                          <span className="capitalize font-semibold ">
                                            {item.category}
                                          </span>

                                          <AiFillCaretDown
                                            className={`${
                                              subMenuEnvironmentOpen &&
                                              "rotate-180"
                                            } ml-auto`}
                                          />
                                        </div>

                                        {item.contentStateList.map((link) => (
                                          <>
                                            {link.endpoint &&
                                              subMenuEnvironmentOpen &&
                                              environmentOpen && (
                                                <ul>
                                                  <li>
                                                    <NavLink
                                                      to={`/${`${item.category}-${link.endpoint}`}`}
                                                      key={
                                                        link.endpoint +
                                                        item.category
                                                      }
                                                      onClick={
                                                        handleCloseSideBar
                                                      }
                                                      style={({
                                                        isActive,
                                                      }) => ({
                                                        backgroundColor:
                                                          isActive
                                                            ? currentColor
                                                            : "",
                                                      })}
                                                      className={({
                                                        isActive,
                                                      }) =>
                                                        isActive
                                                          ? activeLink
                                                          : normalLink
                                                      }
                                                    >
                                                      {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                      <span className="capitalize ">
                                                        {link.endpoint}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                </ul>
                                              )}
                                          </>
                                        ))}
                                      </Dropdown>
                                    ) : (
                                      <>
                                        {item.category === "flexPLM" ? (
                                          <Dropdown className="gap-1">
                                            <div
                                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                              onClick={() =>
                                                setSubMenuFlexPLMOpen(
                                                  !subMenuFlexPLMOpen
                                                )
                                              }
                                            >
                                              <span className="capitalize font-semibold ">
                                                {item.category}
                                              </span>

                                              <AiFillCaretDown
                                                className={`${
                                                  subMenuFlexPLMOpen &&
                                                  "rotate-180"
                                                } ml-auto`}
                                              />
                                            </div>

                                            {item.contentStateList.map(
                                              (link) => (
                                                <>
                                                  {link.endpoint &&
                                                    subMenuFlexPLMOpen &&
                                                    flexPLMOpen && (
                                                      <ul>
                                                        <li>
                                                          <NavLink
                                                            to={`/${`${item.category}-${link.endpoint}`}`}
                                                            key={
                                                              link.endpoint +
                                                              item.category
                                                            }
                                                            onClick={
                                                              handleCloseSideBar
                                                            }
                                                            style={({
                                                              isActive,
                                                            }) => ({
                                                              backgroundColor:
                                                                isActive
                                                                  ? currentColor
                                                                  : "",
                                                            })}
                                                            className={({
                                                              isActive,
                                                            }) =>
                                                              isActive
                                                                ? activeLink
                                                                : normalLink
                                                            }
                                                          >
                                                            {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                            <span className="capitalize ">
                                                              {link.endpoint}
                                                            </span>
                                                          </NavLink>
                                                        </li>
                                                      </ul>
                                                    )}
                                                </>
                                              )
                                            )}
                                          </Dropdown>
                                        ) : (
                                          <>
                                            {item.category === "aws" ? (
                                              <Dropdown className="gap-1">
                                                <div
                                                  className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                  onClick={() =>
                                                    setSubMenuAwsOpen(
                                                      !subMenuAwsOpen
                                                    )
                                                  }
                                                >
                                                  <span className="capitalize font-semibold ">
                                                    {item.category}
                                                  </span>

                                                  <AiFillCaretDown
                                                    className={`${
                                                      subMenuAwsOpen &&
                                                      "rotate-180"
                                                    } ml-auto`}
                                                  />
                                                </div>

                                                {item.contentStateList.map(
                                                  (link) => (
                                                    <>
                                                      {link.endpoint &&
                                                        subMenuAwsOpen &&
                                                        awsOpen && (
                                                          <ul>
                                                            <li>
                                                              <NavLink
                                                                to={`/${`${item.category}-${link.endpoint}`}`}
                                                                key={
                                                                  link.endpoint +
                                                                  item.category
                                                                }
                                                                onClick={
                                                                  handleCloseSideBar
                                                                }
                                                                style={({
                                                                  isActive,
                                                                }) => ({
                                                                  backgroundColor:
                                                                    isActive
                                                                      ? currentColor
                                                                      : "",
                                                                })}
                                                                className={({
                                                                  isActive,
                                                                }) =>
                                                                  isActive
                                                                    ? activeLink
                                                                    : normalLink
                                                                }
                                                              >
                                                                {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                <span className="capitalize ">
                                                                  {
                                                                    link.endpoint
                                                                  }
                                                                </span>
                                                              </NavLink>
                                                            </li>
                                                          </ul>
                                                        )}
                                                    </>
                                                  )
                                                )}
                                              </Dropdown>
                                            ) : (
                                              <>
                                                {item.category ===
                                                "identity-access-management" ? (
                                                  <Dropdown className="gap-1">
                                                    <div
                                                      className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                      onClick={() =>
                                                        setSubMenuTestingOpen(
                                                          !subMenuIdentityOpen
                                                        )
                                                      }
                                                    >
                                                      <span className="capitalize font-semibold ">
                                                        {item.category}
                                                      </span>

                                                      <AiFillCaretDown
                                                        className={`${
                                                          subMenuIdentityOpen &&
                                                          "rotate-180"
                                                        } ml-auto`}
                                                      />
                                                    </div>

                                                    {item.contentStateList.map(
                                                      (link) => (
                                                        <>
                                                          {link.endpoint &&
                                                            subMenuIdentityOpen &&
                                                            identityOpen && (
                                                              <ul>
                                                                <li>
                                                                  <NavLink
                                                                    to={`/${`${item.category}-${link.endpoint}`}`}
                                                                    key={
                                                                      link.endpoint +
                                                                      item.category
                                                                    }
                                                                    onClick={
                                                                      handleCloseSideBar
                                                                    }
                                                                    style={({
                                                                      isActive,
                                                                    }) => ({
                                                                      backgroundColor:
                                                                        isActive
                                                                          ? currentColor
                                                                          : "",
                                                                    })}
                                                                    className={({
                                                                      isActive,
                                                                    }) =>
                                                                      isActive
                                                                        ? activeLink
                                                                        : normalLink
                                                                    }
                                                                  >
                                                                    {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                    <span className="capitalize ">
                                                                      {
                                                                        link.endpoint
                                                                      }
                                                                    </span>
                                                                  </NavLink>
                                                                </li>
                                                              </ul>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                  </Dropdown>
                                                ) : (
                                                  <>
                                                    {item.category ===
                                                    "reactJs" ? (
                                                      <Dropdown className="gap-1">
                                                        <div
                                                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                          onClick={() =>
                                                            setSubMenuReactJsOpen(
                                                              !subMenuReactJsOpen
                                                            )
                                                          }
                                                        >
                                                          <span className="capitalize font-semibold ">
                                                            {item.category}
                                                          </span>

                                                          <AiFillCaretDown
                                                            className={`${
                                                              subMenuReactJsOpen &&
                                                              "rotate-180"
                                                            } ml-auto`}
                                                          />
                                                        </div>

                                                        {item.contentStateList.map(
                                                          (link) => (
                                                            <>
                                                              {link.endpoint &&
                                                                subMenuReactJsOpen &&
                                                                reactJsOpen && (
                                                                  <ul>
                                                                    <li>
                                                                      <NavLink
                                                                        to={`/${`${item.category}-${link.endpoint}`}`}
                                                                        key={
                                                                          link.endpoint +
                                                                          item.category
                                                                        }
                                                                        onClick={
                                                                          handleCloseSideBar
                                                                        }
                                                                        style={({
                                                                          isActive,
                                                                        }) => ({
                                                                          backgroundColor:
                                                                            isActive
                                                                              ? currentColor
                                                                              : "",
                                                                        })}
                                                                        className={({
                                                                          isActive,
                                                                        }) =>
                                                                          isActive
                                                                            ? activeLink
                                                                            : normalLink
                                                                        }
                                                                      >
                                                                        {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                        <span className="capitalize ">
                                                                          {
                                                                            link.endpoint
                                                                          }
                                                                        </span>
                                                                      </NavLink>
                                                                    </li>
                                                                  </ul>
                                                                )}
                                                            </>
                                                          )
                                                        )}
                                                      </Dropdown>
                                                    ) : (
                                                      <>
                                                        {item.category ===
                                                        "camunda" ? (
                                                          <Dropdown className="gap-1">
                                                            <div
                                                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                              onClick={() =>
                                                                setSubMenuCamundaOpen(
                                                                  !subMenuCamundaOpen
                                                                )
                                                              }
                                                            >
                                                              <span className="capitalize font-semibold ">
                                                                {item.category}
                                                              </span>

                                                              <AiFillCaretDown
                                                                className={`${
                                                                  subMenuCamundaOpen &&
                                                                  "rotate-180"
                                                                } ml-auto`}
                                                              />
                                                            </div>

                                                            {item.contentStateList.map(
                                                              (link) => (
                                                                <>
                                                                  {link.endpoint &&
                                                                    subMenuCamundaOpen &&
                                                                    camundaOpen && (
                                                                      <ul>
                                                                        <li>
                                                                          <NavLink
                                                                            to={`/${`${item.category}-${link.endpoint}`}`}
                                                                            key={
                                                                              link.endpoint +
                                                                              item.category
                                                                            }
                                                                            onClick={
                                                                              handleCloseSideBar
                                                                            }
                                                                            style={({
                                                                              isActive,
                                                                            }) => ({
                                                                              backgroundColor:
                                                                                isActive
                                                                                  ? currentColor
                                                                                  : "",
                                                                            })}
                                                                            className={({
                                                                              isActive,
                                                                            }) =>
                                                                              isActive
                                                                                ? activeLink
                                                                                : normalLink
                                                                            }
                                                                          >
                                                                            {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                            <span className="capitalize ">
                                                                              {
                                                                                link.endpoint
                                                                              }
                                                                            </span>
                                                                          </NavLink>
                                                                        </li>
                                                                      </ul>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </Dropdown>
                                                        ) : (
                                                          <>
                                                            {item.category ===
                                                            "testing" ? (
                                                              <Dropdown className="gap-1">
                                                                <div
                                                                  className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                  onClick={() =>
                                                                    setSubMenuTestingOpen(
                                                                      !subMenuTestingOpen
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="capitalize font-semibold ">
                                                                    {
                                                                      item.category
                                                                    }
                                                                  </span>

                                                                  <AiFillCaretDown
                                                                    className={`${
                                                                      subMenuTestingOpen &&
                                                                      "rotate-180"
                                                                    } ml-auto`}
                                                                  />
                                                                </div>

                                                                {item.contentStateList.map(
                                                                  (link) => (
                                                                    <>
                                                                      {link.endpoint &&
                                                                        subMenuTestingOpen &&
                                                                        testingOpen && (
                                                                          <ul>
                                                                            <li>
                                                                              <NavLink
                                                                                to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                key={
                                                                                  link.endpoint +
                                                                                  item.category
                                                                                }
                                                                                onClick={
                                                                                  handleCloseSideBar
                                                                                }
                                                                                style={({
                                                                                  isActive,
                                                                                }) => ({
                                                                                  backgroundColor:
                                                                                    isActive
                                                                                      ? currentColor
                                                                                      : "",
                                                                                })}
                                                                                className={({
                                                                                  isActive,
                                                                                }) =>
                                                                                  isActive
                                                                                    ? activeLink
                                                                                    : normalLink
                                                                                }
                                                                              >
                                                                                {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                <span className="capitalize ">
                                                                                  {
                                                                                    link.endpoint
                                                                                  }
                                                                                </span>
                                                                              </NavLink>
                                                                            </li>
                                                                          </ul>
                                                                        )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </Dropdown>
                                                            ) : (
                                                              <>
                                                                {item.category ===
                                                                "mainframe-modernization" ? (
                                                                  <Dropdown className="gap-1">
                                                                    <div
                                                                      className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                      onClick={() =>
                                                                        setSubMenuMainframeOpen(
                                                                          !subMenuMainframeOpen
                                                                        )
                                                                      }
                                                                    >
                                                                      <span className="capitalize font-semibold ">
                                                                        {
                                                                          item.category
                                                                        }
                                                                      </span>

                                                                      <AiFillCaretDown
                                                                        className={`${
                                                                          subMenuMainframeOpen &&
                                                                          "rotate-180"
                                                                        } ml-auto`}
                                                                      />
                                                                    </div>

                                                                    {item.contentStateList.map(
                                                                      (
                                                                        link
                                                                      ) => (
                                                                        <>
                                                                          {link.endpoint &&
                                                                            subMenuMainframeOpen &&
                                                                            mainframeOpen && (
                                                                              <ul>
                                                                                <li>
                                                                                  <NavLink
                                                                                    to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                    key={
                                                                                      link.endpoint +
                                                                                      item.category
                                                                                    }
                                                                                    onClick={
                                                                                      handleCloseSideBar
                                                                                    }
                                                                                    style={({
                                                                                      isActive,
                                                                                    }) => ({
                                                                                      backgroundColor:
                                                                                        isActive
                                                                                          ? currentColor
                                                                                          : "",
                                                                                    })}
                                                                                    className={({
                                                                                      isActive,
                                                                                    }) =>
                                                                                      isActive
                                                                                        ? activeLink
                                                                                        : normalLink
                                                                                    }
                                                                                  >
                                                                                    {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                    <span className="capitalize ">
                                                                                      {
                                                                                        link.endpoint
                                                                                      }
                                                                                    </span>
                                                                                  </NavLink>
                                                                                </li>
                                                                              </ul>
                                                                            )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </Dropdown>
                                                                ) : (
                                                                  <>
                                                                    {item.category ===
                                                                    "soft-skills" ? (
                                                                      <Dropdown className="gap-1">
                                                                        <div
                                                                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                          onClick={() =>
                                                                            setSubMenuSoftSkillsOpen(
                                                                              !subMenuSoftSkillsOpen
                                                                            )
                                                                          }
                                                                        >
                                                                          <span className="capitalize font-semibold ">
                                                                            {
                                                                              item.category
                                                                            }
                                                                          </span>

                                                                          <AiFillCaretDown
                                                                            className={`${
                                                                              subMenuSoftSkillsOpen &&
                                                                              "rotate-180"
                                                                            } ml-auto`}
                                                                          />
                                                                        </div>

                                                                        {item.contentStateList.map(
                                                                          (
                                                                            link
                                                                          ) => (
                                                                            <>
                                                                              {link.endpoint &&
                                                                                subMenuSoftSkillsOpen &&
                                                                                softSkillsOpen && (
                                                                                  <ul>
                                                                                    <li>
                                                                                      <NavLink
                                                                                        to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                        key={
                                                                                          link.endpoint +
                                                                                          item.category
                                                                                        }
                                                                                        onClick={
                                                                                          handleCloseSideBar
                                                                                        }
                                                                                        style={({
                                                                                          isActive,
                                                                                        }) => ({
                                                                                          backgroundColor:
                                                                                            isActive
                                                                                              ? currentColor
                                                                                              : "",
                                                                                        })}
                                                                                        className={({
                                                                                          isActive,
                                                                                        }) =>
                                                                                          isActive
                                                                                            ? activeLink
                                                                                            : normalLink
                                                                                        }
                                                                                      >
                                                                                        {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                        <span className="capitalize ">
                                                                                          {
                                                                                            link.endpoint
                                                                                          }
                                                                                        </span>
                                                                                      </NavLink>
                                                                                    </li>
                                                                                  </ul>
                                                                                )}
                                                                            </>
                                                                          )
                                                                        )}
                                                                      </Dropdown>
                                                                    ) : (
                                                                      <>
                                                                        {item.category ===
                                                                        "data-structure-algorithms" ? (
                                                                          <Dropdown className="gap-1">
                                                                            <div
                                                                              className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                              onClick={() =>
                                                                                setSubMenuDataStructureOpen(
                                                                                  !subMenuDataStructureOpen
                                                                                )
                                                                              }
                                                                            >
                                                                              <span className="capitalize font-semibold ">
                                                                                {
                                                                                  item.category
                                                                                }
                                                                              </span>

                                                                              <AiFillCaretDown
                                                                                className={`${
                                                                                  subMenuDataStructureOpen &&
                                                                                  "rotate-180"
                                                                                } ml-auto`}
                                                                              />
                                                                            </div>

                                                                            {item.contentStateList.map(
                                                                              (
                                                                                link
                                                                              ) => (
                                                                                <>
                                                                                  {link.endpoint &&
                                                                                    subMenuDataStructureOpen &&
                                                                                    dataStructureOpen && (
                                                                                      <ul>
                                                                                        <li>
                                                                                          <NavLink
                                                                                            to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                            key={
                                                                                              link.endpoint +
                                                                                              item.category
                                                                                            }
                                                                                            onClick={
                                                                                              handleCloseSideBar
                                                                                            }
                                                                                            style={({
                                                                                              isActive,
                                                                                            }) => ({
                                                                                              backgroundColor:
                                                                                                isActive
                                                                                                  ? currentColor
                                                                                                  : "",
                                                                                            })}
                                                                                            className={({
                                                                                              isActive,
                                                                                            }) =>
                                                                                              isActive
                                                                                                ? activeLink
                                                                                                : normalLink
                                                                                            }
                                                                                          >
                                                                                            {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                            <span className="capitalize ">
                                                                                              {
                                                                                                link.endpoint
                                                                                              }
                                                                                            </span>
                                                                                          </NavLink>
                                                                                        </li>
                                                                                      </ul>
                                                                                    )}
                                                                                </>
                                                                              )
                                                                            )}
                                                                          </Dropdown>
                                                                        ) : (
                                                                          <>
                                                                            {item.category ===
                                                                            "springboot" ? (
                                                                              <Dropdown className="gap-1">
                                                                                <div
                                                                                  className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                                  onClick={() =>
                                                                                    setSubMenuSpringbootOpen(
                                                                                      !subMenuSpringbootOpen
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <span className="capitalize font-semibold ">
                                                                                    {
                                                                                      item.category
                                                                                    }
                                                                                  </span>

                                                                                  <AiFillCaretDown
                                                                                    className={`${
                                                                                      subMenuSpringbootOpen &&
                                                                                      "rotate-180"
                                                                                    } ml-auto`}
                                                                                  />
                                                                                </div>

                                                                                {item.contentStateList.map(
                                                                                  (
                                                                                    link
                                                                                  ) => (
                                                                                    <>
                                                                                      {link.endpoint &&
                                                                                        subMenuSpringbootOpen &&
                                                                                        springbootOpen && (
                                                                                          <ul>
                                                                                            <li>
                                                                                              <NavLink
                                                                                                to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                                key={
                                                                                                  link.endpoint +
                                                                                                  item.category
                                                                                                }
                                                                                                onClick={
                                                                                                  handleCloseSideBar
                                                                                                }
                                                                                                style={({
                                                                                                  isActive,
                                                                                                }) => ({
                                                                                                  backgroundColor:
                                                                                                    isActive
                                                                                                      ? currentColor
                                                                                                      : "",
                                                                                                })}
                                                                                                className={({
                                                                                                  isActive,
                                                                                                }) =>
                                                                                                  isActive
                                                                                                    ? activeLink
                                                                                                    : normalLink
                                                                                                }
                                                                                              >
                                                                                                {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                                <span className="capitalize ">
                                                                                                  {
                                                                                                    link.endpoint
                                                                                                  }
                                                                                                </span>
                                                                                              </NavLink>
                                                                                            </li>
                                                                                          </ul>
                                                                                        )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </Dropdown>
                                                                            ) : (
                                                                              <>
                                                                                {item.category ===
                                                                                "database" ? (
                                                                                  <Dropdown className="gap-1">
                                                                                    <div
                                                                                      className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                                      onClick={() =>
                                                                                        setSubMenuDatabaseOpen(
                                                                                          !subMenuDatabaseOpen
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      <span className="capitalize font-semibold ">
                                                                                        {
                                                                                          item.category
                                                                                        }
                                                                                      </span>

                                                                                      <AiFillCaretDown
                                                                                        className={`${
                                                                                          subMenuDatabaseOpen &&
                                                                                          "rotate-180"
                                                                                        } ml-auto`}
                                                                                      />
                                                                                    </div>

                                                                                    {item.contentStateList.map(
                                                                                      (
                                                                                        link
                                                                                      ) => (
                                                                                        <>
                                                                                          {link.endpoint &&
                                                                                            subMenuDatabaseOpen &&
                                                                                            databaseOpen && (
                                                                                              <ul>
                                                                                                <li>
                                                                                                  <NavLink
                                                                                                    to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                                    key={
                                                                                                      link.endpoint +
                                                                                                      item.category
                                                                                                    }
                                                                                                    onClick={
                                                                                                      handleCloseSideBar
                                                                                                    }
                                                                                                    style={({
                                                                                                      isActive,
                                                                                                    }) => ({
                                                                                                      backgroundColor:
                                                                                                        isActive
                                                                                                          ? currentColor
                                                                                                          : "",
                                                                                                    })}
                                                                                                    className={({
                                                                                                      isActive,
                                                                                                    }) =>
                                                                                                      isActive
                                                                                                        ? activeLink
                                                                                                        : normalLink
                                                                                                    }
                                                                                                  >
                                                                                                    {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                                    <span className="capitalize ">
                                                                                                      {
                                                                                                        link.endpoint
                                                                                                      }
                                                                                                    </span>
                                                                                                  </NavLink>
                                                                                                </li>
                                                                                              </ul>
                                                                                            )}
                                                                                        </>
                                                                                      )
                                                                                    )}
                                                                                  </Dropdown>
                                                                                ) : (
                                                                                  <>
                                                                                    {item.category ===
                                                                                    "others" ? (
                                                                                      <Dropdown className="gap-1">
                                                                                        <div
                                                                                          className="flex items-center gap-5 pl-4 pt-3  rounded-lg text-md text-black-700 dark:text-black-200 dark:hover:text-black hover:bg-light-black m-2"
                                                                                          onClick={() =>
                                                                                            setSubMenuOthersOpen(
                                                                                              !subMenuOthersOpen
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          <span className="capitalize font-semibold ">
                                                                                            {
                                                                                              item.category
                                                                                            }
                                                                                          </span>

                                                                                          <AiFillCaretDown
                                                                                            className={`${
                                                                                              subMenuOthersOpen &&
                                                                                              "rotate-180"
                                                                                            } ml-auto`}
                                                                                          />
                                                                                        </div>

                                                                                        {item.contentStateList.map(
                                                                                          (
                                                                                            link
                                                                                          ) => (
                                                                                            <>
                                                                                              {link.endpoint &&
                                                                                                subMenuOthersOpen &&
                                                                                                othersOpen && (
                                                                                                  <ul>
                                                                                                    <li>
                                                                                                      <NavLink
                                                                                                        to={`/${`${item.category}-${link.endpoint}`}`}
                                                                                                        key={
                                                                                                          link.endpoint +
                                                                                                          item.category
                                                                                                        }
                                                                                                        onClick={
                                                                                                          handleCloseSideBar
                                                                                                        }
                                                                                                        style={({
                                                                                                          isActive,
                                                                                                        }) => ({
                                                                                                          backgroundColor:
                                                                                                            isActive
                                                                                                              ? currentColor
                                                                                                              : "",
                                                                                                        })}
                                                                                                        className={({
                                                                                                          isActive,
                                                                                                        }) =>
                                                                                                          isActive
                                                                                                            ? activeLink
                                                                                                            : normalLink
                                                                                                        }
                                                                                                      >
                                                                                                        {/* {item.category === 'Java' ? <SiOracle /> : <FiHome />} */}
                                                                                                        <span className="capitalize ">
                                                                                                          {
                                                                                                            link.endpoint
                                                                                                          }
                                                                                                        </span>
                                                                                                      </NavLink>
                                                                                                    </li>
                                                                                                  </ul>
                                                                                                )}
                                                                                            </>
                                                                                          )
                                                                                        )}
                                                                                      </Dropdown>
                                                                                    ) : null}
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
