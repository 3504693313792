import React, { Component } from 'react';
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import{termsEn} from '../../../data/content/En/TermsEn';
import{termsDe} from '../../../data/content/De/TermsDe';
import { Language } from '../../../config/Config';
const Term = () => {
  const currentColor = useContextSelector(
      StateContext,
      (state) => state.currentColor,
    );
    return (
        <div className="container">
          <div className="main contact-margin-top" id="contact">
            <div className="contact-div-main">
              <div className="contact-header ml-4 md:ml-10 ">
                <label />
               <br></br>
               <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}} >{Language==='en'? termsEn.title:termsDe.title}</h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.yaazhTechSite:termsDe.yaazhTechSite}</p>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.rules:termsDe.rules}</p>
<br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10" >
                <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4  ">
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.commericalUse:termsDe.commericalUse}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.accessingSite:termsDe.accessingSite}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.lawRules:termsDe.lawRules}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.prohibitedLaw:termsDe.prohibitedLaw}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.copyright:termsDe.copyright}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.internetAbuse:termsDe.internetAbuse}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.dataTransfer:termsDe.dataTransfer}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.downloadManagement:termsDe.downloadManagement}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.websiteAccess:termsDe.websiteAccess}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.notHarmFul:termsDe.notHarmFul}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.siteDamage:termsDe.siteDamage}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.services:termsDe.services}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.errorDesign:termsDe.errorDesign}</li>
                  </ul>
                </p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.disclaimersAndLimitations:termsDe.disclaimersAndLimitations}</strong></h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.risk:termsDe.risk}</p>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.permittedLaw:termsDe.permittedLaw}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.liabilityLimitation:termsDe.liabilityLimitation}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.additionalChanges:termsDe.additionalChanges}</p>
               <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.indemnification:termsDe.indemnification}</strong></h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.termsOfServices:termsDe.termsOfServices}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.userResponsibilities:termsDe.userResponsibilities}</strong></h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.subscribers:termsDe.subscribers}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.payments:termsDe.payments}</strong></h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.paymentInformation:termsDe.paymentInformation}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.serviceEmails:termsDe.serviceEmails}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.subscribEmails:termsDe.subscribEmails}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.termination:termsDe.termination}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.terminateRights:termsDe.terminateRights}</p>
               <br></br> 
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.forceMajeure:termsDe.forceMajeure}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.majeure:termsDe.majeure}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.governingLaw:termsDe.governingLaw}</strong></h1>
               <br></br> 
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.rightsAndObilgations:termsDe.rightsAndObilgations}</p>
               <br></br> 
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn. construction:termsDe. construction}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.interpretation:termsDe.interpretation}</p>
               <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.severability:termsDe.severability}</strong> </h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.forceAndEffect:termsDe.forceAndEffect}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.waiver:termsDe.waiver}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.failureEnforce:termsDe.failureEnforce}</p>
               <br></br> 
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.statuteLimitations:termsDe.statuteLimitations}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.relatedServices:termsDe.relatedServices}</p>
               <br></br> 
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.titles:termsDe.titles}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.sectionTitles:termsDe.sectionTitles}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.assignment:termsDe.assignment}</strong></h1>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.operationOfLaw:termsDe.operationOfLaw}</p>
                <br></br>
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.modification:termsDe.modification}</strong></h1>
               <br></br> 
               <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.reviewChange:termsDe.reviewChange}</p>
               <br></br> 
                <h1 className="section-heading text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.copyrightAndTrademarkNotices:termsDe.copyrightAndTrademarkNotices}</strong></h1>
               <br></br> 
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.copyright:termsDe.copyright}</p>
                <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.tradeMark:termsDe.tradeMark}</p>
               <br></br> 
                <h1 className="section-heading  text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.tampering:termsDe.tampering}</strong></h1>
              <br></br>  
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.tamperUser:termsDe.tamperUser}</p>
               <br></br>
                <h1 className="section-heading  text-2xl"style={{color:currentColor}} ><strong>{Language==='en'? termsEn.legalInquires:termsDe.legalInquires}</strong></h1>
               <br></br>
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.investigation:termsDe.investigation}</p>
               <br></br> 
                <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? termsEn.understandTerms:termsDe.understandTerms}</p>
  <br></br>
              </div>
            </div>
          </div>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
            crossOrigin="anonymous"
          />
        </div>
    );
  }

export default Term;
