import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { StaffAugTalentAcquisitionSpecialistEn } from '../../../data/content/En/StaffAugTalentAcquisitionSpecialistEn';
import { StaffAugTalentAcquisitionSpecialistDe } from '../../../data/content/De/StaffAugTalentAcquisitionSpecialistDe';
import { Link } from 'react-router-dom';
import { Language } from '../../../config/Config';

const StaffAugTalentAcquisitionSpecialist = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      <div className="container">
      <div className="main contact-margin-top" id="contact">
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
          <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.button:StaffAugTalentAcquisitionSpecialistDe.button}</span></Link>
          </button>
            <br></br>
            <br></br>
            <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.title:StaffAugTalentAcquisitionSpecialistDe.title}</h1>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.headingContent1:StaffAugTalentAcquisitionSpecialistDe.headingContent1}</b>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.headingContent2:StaffAugTalentAcquisitionSpecialistDe.headingContent2}</p><br></br></p>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.moreThanJustRecruitment:StaffAugTalentAcquisitionSpecialistDe.moreThanJustRecruitment}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.our:StaffAugTalentAcquisitionSpecialistDe.our}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.theyGo:StaffAugTalentAcquisitionSpecialistDe.theyGo}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.unCovering:StaffAugTalentAcquisitionSpecialistDe.unCovering}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.throughInnovative:StaffAugTalentAcquisitionSpecialistDe.throughInnovative}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.theyLeverage:StaffAugTalentAcquisitionSpecialistDe.theyLeverage}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.craftingAPositive:StaffAugTalentAcquisitionSpecialistDe.craftingAPositive}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.initial:StaffAugTalentAcquisitionSpecialistDe.initial}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.transparent:StaffAugTalentAcquisitionSpecialistDe.transparent}</li>
             {' '}
             </p><br></br>

             <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.joinJourney:StaffAugTalentAcquisitionSpecialistDe.joinJourney}</b>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.initial:StaffAugTalentAcquisitionSpecialistDe.initial}</li>
             <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.talent:StaffAugTalentAcquisitionSpecialistDe.talent}</li>
             {' '}
             </p><br></br>

             <hr className="my-5" />
    <div className="d-flex justify-content-between"> 
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }}><Link to ="/hireCoderRegister">
        <span><div className="text-center"/>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.button:StaffAugTalentAcquisitionSpecialistDe.button}</span></Link>
        </button>
      {/* TODO */}
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" style={{ backgroundColor: currentColor }} ><Link to ="/staffAugmentation">
      <span><div className="text-center"/>{Language==='en'? StaffAugTalentAcquisitionSpecialistEn.cancelButton:StaffAugTalentAcquisitionSpecialistDe.cancelButton}</span></Link>
      </button>
      </div>
              </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default StaffAugTalentAcquisitionSpecialist