import React from 'react';

const LeadershipBanner = () => {
  return (
    <div className="bg-gradient-to-r from-pink-400 to-blue-500  py-16 flex flex-col items-center justify-center">
      <h1 className="text-4xl md:text-5xl font-bold mb-4 p-2">Our Leadership</h1>
      <p className="text-lg md:text-xl p-2">Meet the team driving Yaazhtech vision</p>
    </div>
  );
};

export default LeadershipBanner;
