
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../contexts/StateContext';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import toast, { Toaster } from 'react-hot-toast';


import Education from '../../static/Education';
import {
  ACCESS_TOKEN,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  MOBILE_EXACT_LENGTH,
  NAME_MIN_LENGTH
} from "../../../config/Config";
import { register } from '../../../api/Api';
import { ShowAlert } from '../../../components';
import { useNavigate } from 'react-router-dom';
const EducationRegister = (props) => {

  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  //alert(subscriptionType+subscriptionFor);

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const navigate = useNavigate();
  const [registerRequest, setRegisterRequest] = useState({

    mobile: "",
    email: "",
    password: "",
    name: "",
    subscriptionFor: "",
    department: "",
    arrears: "",
    subscriptionType: "",
    subscriptionCost: "",


  });

  const [type, setType] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');
  // const [alertType, setAlertType] = useState('');

  let [passwordPattern, setPasswordPattern] = useState({
    number: false,
    lowerCase: false,
    upperCase: false,
    charsLength: false,
  });

  const updateName = (event) => {
    let req = registerRequest;
    req.name = event.target.value;
    setRegisterRequest(req);
  };

  const updateEmail = (event) => {
    let req = registerRequest;
    req.email = event.target.value;
    setRegisterRequest(req);
  };

  const updateMobile = (event) => {
    let req = registerRequest;
    req.mobile = event.target.value;
    setRegisterRequest(req);
  };

  const updateDepartment = (event) => {
    let req = registerRequest;
    req.department = event.target.value;
    setRegisterRequest(req);
  };

  const updateArrears = (event) => {
    let req = registerRequest;
    req.arrears = event.target.value;
    setRegisterRequest(req);
  };

  const updateSubscription = () => {
    //alert("register"+JSON.stringify(props.jobApplicationProps));
    const req = registerRequest;
    if (location.state) {
      req.subscriptionType = subscriptionType ? subscriptionType : '';
      req.subscriptionCost = subscriptionCost ? subscriptionCost : '';
      req.subscriptionFor = subscriptionFor ? subscriptionFor : '';
    }
    setRegisterRequest(req);
    //alert("register"+JSON.stringify(this.state.registerRequest));
  };

  useEffect(() => {
    document.title = "Subscribe";
    updateSubscription();
    //onShowAlert();
  }, []);


  const requestRegister = (event) => {

    event.preventDefault();
    updateSubscription();

    const req = registerRequest;
    //alert("success " + JSON.stringify(req.registerRequest));
    if (req.mobile.length !== MOBILE_EXACT_LENGTH) {
      //alert("success "+JSON.stringify(req.mobile));

      toast.error("Your mobile number must contain exactly " +
        MOBILE_EXACT_LENGTH +
        " and " +
        "no special characters. (+)");

      toast.error("error");


      return null;
    }
    if (req.name.length < NAME_MIN_LENGTH) {

      toast.error("Name length should be greater than " + NAME_MIN_LENGTH + ".");
      toast.error("error")

      return null;
    }

    if (
      req.password.length < PASSWORD_MIN_LENGTH ||
      req.password.length > PASSWORD_MAX_LENGTH
    ) {

      toast.error("Your password must contain between " +
        PASSWORD_MIN_LENGTH +
        " and " +
        PASSWORD_MAX_LENGTH +
        " characters !");

      toast.error("error");

      return null;
    }

    const passwordPattern = setPasswordPattern;
    if (
      passwordPattern.upperCase &&
      passwordPattern.lowerCase &&
      passwordPattern.charsLength &&
      passwordPattern.number
    ) {
      setShowLoading(true);
      register(registerRequest)
        .then((res) => {

          toast.success(res.message)
          toast.success("success")
          navigate("/")
          // history.push("/login");
        })

        .catch((error) => {
          if (error.message) {

            toast.error(error.message)
            toast.error("error")

          }
          else {

            toast.error("Sorry! Something went wrong. Please try again!")
            toast.error("error")

          }
          setShowLoading(false);
          //console.log(error);
        });
    } else {

      toast.error("Invalid password : please take care of password constraints !")
      toast.error("error")



    }
  };

  const showPassword = () => setType(!type);

  const nextStep = () => {
    setStep(step + 1);
    setEmail('');
  }

  const prevStep = () => {
    setStep(step - 1);
    setError('');
  };

  let updatePassword = (event) => {
    let req = { ...registerRequest };
    req.password = event.target.value;
    setRegisterRequest(req);

    let passwordPattern = setPasswordPattern;

    const lowerCase = /[a-z]/g;
    if (req.password.match(lowerCase)) {
      document.getElementById("lowerCase").classList.remove("text-danger");
      document.getElementById("lowerCase").classList.add("text-success");
      passwordPattern.lowerCase = true;
    } else {
      document.getElementById("lowerCase").classList.remove("text-success");
      document.getElementById("lowerCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const upperCase = /[A-Z]/g;
    if (req.password.match(upperCase)) {
      document.getElementById("upperCase").classList.remove("text-danger");
      document.getElementById("upperCase").classList.add("text-success");
      passwordPattern.upperCase = true;
    } else {
      document.getElementById("upperCase").classList.remove("text-success");
      document.getElementById("upperCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const number = /[0-9]/g;
    if (req.password.match(number)) {
      document.getElementById("number").classList.remove("text-danger");
      document.getElementById("number").classList.add("text-success");
      passwordPattern.number = true;
    } else {
      document.getElementById("number").classList.remove("text-success");
      document.getElementById("number").classList.add("text-danger");
      passwordPattern.number = false;
    }

    if (
      req.password.length > PASSWORD_MIN_LENGTH &&
      req.password.length < PASSWORD_MAX_LENGTH
    ) {
      document.getElementById("chars").classList.remove("text-danger");
      document.getElementById("chars").classList.add("text-success");
      passwordPattern.charsLength = true;
    } else {
      document.getElementById("chars").classList.remove("text-success");
      document.getElementById("chars").classList.add("text-danger");
      passwordPattern.charsLength = false;
    }
    setPasswordPattern(passwordPattern);
  };


  const [step, setStep] = useState(1);
  // const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  // const [number, setNumber] = useState('');
  // const [department, setDepartment] = useState('');
  // const [arrears, setArrears] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');


  // const maxPasswordLength = 12;
  // const specialCharacters = /[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]/;



  // const handleSubmit = () => {
  //   if (password !== confirmPassword) {
  //     setError('Passwords do not match.');
  //   } else if (password.length > maxPasswordLength) {
  //     setError(`Password length must be at most ${maxPasswordLength} characters.`);
  //   } else if (!specialCharacters.test(password)) {
  //     setError('Password must contain at least one special character.');
  //   } 
  //    else {
  //     setError('');
  //   }
  // };

  if (localStorage.getItem(ACCESS_TOKEN)) {

    toast.success("You are already logged in !");

    //history.push("/");
    return <p>Oops ! Your are already logged in !</p>;
  } else {


    return (
      <div className="container mx-auto p-4">

        <div
          className={
            currentMode === "Dark"
              ? "max-w-md mx-auto p-8 rounded bg-dark-gradient-r"
              : "max-w-md mx-auto p-8 rounded bg-light-gradient-r"
          }
        >

          <form onSubmit={(e) => requestRegister(e)}>

            {showLoading ? (
              // <div className="align-content-center text-center">
              //   <h5>Signing up</h5>
              //   <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              //     refresh
              //   </i>
              // </div>
              <div className="flex justify-center items-center my-5">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-200 rounded-full animate-spin" style={{ borderTopColor: currentColor }}></div>
  </div>
            ) : (

              <div className='card card-signin my-5 -mt-10'>



                <div className="card-body ml-5 mr-5 mt-5">
                  <h1 className="text-xl uppercase flex justify-center font-semibold mb-2">
                    Sign Up
                  </h1>
                </div>

                <p className='text-base flex justify-center mb-2'>Welcome Back! Please Enter your details.</p>

                <br />
                {step === 1 && (

                  <div>
                    <h2 className="font-semibold">Name </h2>
                    <input
                      type="text"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
            text-black border-b border-black outline-none focus:outline-none"
                      placeholder="Enter your name"

                      onChange={updateName}
                      autoComplete="on"
                      required
                    />

                    <h2 className="font-semibold">Email address </h2>
                    <input
                      type="email"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                      placeholder="Enter your email address"
                      onChange={updateEmail}
                      autoComplete="on"
                      required
                    />


                    <h2 className="font-semibold">Mobile </h2>
                    <input
                      type="number"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                      placeholder="Enter your phone number"

                      onChange={updateMobile}
                      autoComplete="on"
                      required
                    />


                    <button onClick={nextStep} className=" w-20 text-white p-2 rounded" style={{ backgroundColor: currentColor }} >
                      Next
                    </button>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <h2 className="font-semibold">Department Name</h2>
                    <input
                      type="text"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                      placeholder="Enter your department name"

                      onChange={updateDepartment}
                      autoComplete="on"
                      required

                    />

                    <h2 className="font-semibold">No.of.arrears</h2>
                    <input
                      type="number"
                      className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                      placeholder="Enter your arrears"

                      onChange={updateArrears}
                      autoComplete="on"
                      required

                    />

                    <h2 className="font-semibold">Password</h2>
                    <div className="flex items-center relative">
                      <input
                        type={type ? "text" : "password"}
                        className="form-control w-full text-base py-2 my-4 bg-transparent
                 text-black border-b border-black outline-none focus:outline-none"
                        placeholder="Password"
                        onChange={updatePassword}
                        autoComplete="on"
                        required
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                      />
                      <span
                        className="showpass absolute right-2 text-2xl cursor-pointer"
                        onClick={showPassword} >
                        {type ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </span>

                    </div>
                    <small className="text-muted">
                      Your password must contain : <a className="text-danger" id="number">
                        * One number
                      </a>
                      <a className="text-danger" id="lowerCase">
                        * One lower case
                      </a>
                      <a className="text-danger" id="upperCase">
                        * One upper case
                      </a>
                      <a className="text-danger" id="chars">
                        * Between 6 and 20 chars
                      </a>
                    </small>

                    {/* <h2 className="text-base">Confirm Password</h2>          
               <input
                 type="password"
                 className="form-control w-full text-base py-2 my-4 bg-transparent
                 text-black border-b border-black outline-none focus:outline-none"
                 placeholder="Confirm Password"
                 value={confirmPassword}
                 onChange={(e) => setConfirmPassword(e.target.value)}                
                 maxLength={maxPasswordLength}
                 autoComplete="on"             
                 required
   
               /> */}

                    <div className="w-full flex justify-between" style={{ color: currentColor }}>
                      <p>Already Registered ?

                      </p>
                      <div>
                        <Link to="/login">
                          <p className="text-black text-xl">
                            Login now !
                          </p>

                        </Link>
                      </div>
                    </div>

                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <div className='flex justify-between'>
                      <button onClick={prevStep} className=" text-white w-20 mt-3 p-2 rounde" style={{ backgroundColor: currentColor }}>
                        Back
                      </button>

                      <button className=" text-white mt-3 p-2 rounded" style={{ backgroundColor: currentColor }}>
                        Create Account
                      </button>

                    </div>
                    <p className="py-2 mt-5">
                      <span style={{ color: currentColor }}>By clicking "Create account" or "Continue with Google", you agree to the</span> <span>YaazhTech Software Development Lab</span>
                      <span style={{ color: currentColor }}> and</span> <span>Privacy Policy. </span>
                      <span style={{ color: currentColor }}>We'll never share your information with anyone else.</span>
                    </p>


                  </div>

                )}



              </div>
            )}




          </form>
        </div>
        {/* {alertMessage && alertType && (
          <ShowAlert message={alertMessage} type={alertType} />
        )} */}


        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: '#FF4136',
                color: '#fff',
              },
            },

            success: {
              duration: 6000,
              style: {
                background: '#008000',
                color: '#fff',
              },
            },
          }}
        />
      </div>
    )
  }
}
export default EducationRegister;
