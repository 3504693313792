import React, { useState,useEffect } from "react";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../src/contexts/StateContext';
import { deleteEndPoint } from "../../../../../api/AdminApi";
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate,useLocation } from "react-router-dom";

const DeleteContent= ({category,endpoint}) => {
  
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
      );
  const [showModalUserInfo, setShowModalUserInfo] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
 const [endPoint, setEndPoint] = useState();
 const [showCard, setShowCard] = useState(false);
  // Check if state is available before using it

  
  const navigate = useNavigate();
 
 console.log("contentEndPoint:", endPoint);
  console.log("Show Modal:", showModalUserInfo);
  
  

  const toggle = () => {
    setShowModalUserInfo(!showModalUserInfo);
  };

  useEffect(() => {
    // Update the endPoint state when the endpoint prop changes
    setEndPoint(endpoint);
   // setCategory(category);
  }, [endpoint]);


  const deleteEndpoints = async(event) => {
    event.preventDefault();
    setShowLoading(true);
    
    //const removeEndPoint = endPoint;

    deleteEndPoint(endpoint, category)
      .then((response) => {
        const data = response.data;
        // props.showAlert(data.message, "success");
        toast.success("Content is deleted successfully", data.message);
        setShowLoading(false);
        toggle();
        setEndPoint('');
        setShowCard(false);
        // props.history.push("/");
        // props.history.push("/publish/editContent");
       
      })
      .catch((error) => {
        toast.error(error.message);
        setShowLoading(false);
      });
  };

  return (
    <div>
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowCard(true)}
      >
       
        <span style={{ color: currentColor }}>
          <FontAwesomeIcon 
           icon={faTrash}
           style={{ color: 'black' }}/>
        </span>
      </button>
      {showCard && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                 Delete  
                </h2>
                <div className="relative p-0 px-3 flex-auto max-h-96 overflow-y-auto">
                
                  <form onSubmit={deleteEndpoints}>
 <p>
 Are you sure you want to delete content in the category:{" "}
                    {category} and endpoint: {endpoint}?    </p>
    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
      <button
        style={{ backgroundColor: currentColor }}
        className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="submit"
      >
        Delete
      </button>
    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
         
        </>
     
     )}
   

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default DeleteContent;
