const policyHubEn={
    trainAndHire:"Train and Hire Policy ",
    termsAndConditions:"Terms and conditions:",
    performanceEvaluation:"1. Performance Evaluation:",
    technicallyTrained:"Candidates will be technically trained on our internal and/or client project with our technology experts in agile methodology with scrum",
    periodicallyEvaluate:"Periodically we will evaluate your candidature with the levels of Info, Dark, Primary and Client Interview", 
    trainingPeriod:"There is no duration of training period, according to your ability anytime you can clear the levels and become employee, client-intern or intern",
    trainingCost:"Training cost is applied for each training please check our web app for the time to time offered cost",
    trainingOpted:"Training can be opted as only as full time according to your current situation",  
    performanceEvaluated:"Your performance will be evaluated as per the Key Performance Indicators (KPIs) mentioned below",
    codingSkill:"1)	Technical Coding skills on Project tasks",
    technicalConcepts:"2)	Technical concepts and level of understanding metrics for the same", 
    softSkills:"3)	Soft skills (Communication, Behavioral, Professional skills, Email Communication)",
    understanding:"4)	Skills on understanding the business requirements, design, development and deployment",
    knowledgeSharing:"5)	Knowledge sharing habits and internal support",
    benefitsAndIndenture:"2. Benefits and Indenture",
    basedPerformance:"Based on your performance (Refer para 1) on KPIs and levels you will be rated at Yaazhtech between 1 to 5.", 
    performance:"Every 3 months performance (Intern, Client-Intern and Employee) will be evaluated and appraiser comments will be updated.",
    rating1:"Rating 1 and 2 - Eligible for year end bonus and increment.",
    rating3:"Rating 3 - Not Eligible for year end bonus and increment.",
    rating4:"Rating 4 - Not Eligible for year end bonus and increment and PIP on 3 months if not proved the performance will be terminated.",
    rating5:"Rating 5 - Irregular or low performer will be terminated.",
    contractCommence:"Contract will commence from the day one when you become Intern, Client-Intern or Employee for a minimum duration of 2 years. On any requirement for contract break  within two years duration you are required to pay 25% of your yearly pay or 2 lakhs whichever is higher (Ex: 10 LPA -> 25% is 2.5L >2L). Appropriate notice period to be served as per the designation ",
    noticePeriod:" Notice Period ", 
    appropriateDetails:"The notice period will commence from the date of application via Yaazhtech exit portal (subject to terms and conditions of contract break if applicable) with appropriate details and below are the notice serving period",
    intern:"Intern  - 1 Month (Intern certificate, intern pay statement will be provided) ",  
    clientIntern:"Client-Intern  - 2 Months (Intern certificate, intern pay statement will be provided) ",
    employee:"Employee - 3 Months (Experience certificate, Salary statement, PF statement, Relieving letter,Offer letter, Hike Letter will be provided) ",
    termsAndConditionsIntern:"Eligibility terms and conditions in INTERN: ",
    successfulCompletion:"Successful 100% completion of Dark level and Primary Level ",  
    stipendProvided:"Provided with stipend Rs 5,000/- per month (For 20 working days per month).",
    internWorks:"If Intern works more than 20 days per month will get an extra day payout accordingly at the year end",
    leavesWhichAffects:"If Intern opts for leaves which affects more than 20 working days per month the payout will be reduced in the same month accordingly ",  
    evaluatedPeriodically:"Interns performance will be evaluated periodically and will be eligible for bonus increments based on performance rating at the end of the year ", 
    caseInternCategory:"In case Intern remains the same in the Intern category, will get opportunities to participate in client projects ",
    chancesToAppear:"Intern will keep on getting  chances to appear for the client interview from time to time based on interview availability. There is no promise as such from YaazhTech for periodic client interviews, we request you to keep waiting and monitor with your technical/manager whom you report",
    becomeAnEmployee:"Also Intern may become an employee without success in Client Interview when  performing well in Internal Local/Client project development and candidate training for minimum 18 months. After 18 months of sustaining as an Intern will be eligible to get a salary package ranging from 1.5LPA to 3LPA based on the  years of experience",
    modeOfIntern:"Mode of intern is work from home, no mandatory travel required",
    homeBenefits:" Work from home benefits as modem one time cost and monthly internet recharge will be made by YaazhTech. (Jio: Rs 667/90 days, Vodafone:) ",  
    payoutMade:"Payout will be made as per Sidereal Solar Calendar month end working day but salary and tax calculation as per Gregorian Calendar",
    termsAndConditionsClient:"Eligibility terms and conditions in CLIENT-INTERN: ",
    successfulCompletionDark:"Successful 100% completion of Dark level and Primary Level and Client Screening Interview",
    providedStipend:"Provided with stipend Rs 10,000/- per month (For 20 working days per month).", 
    clientInternWorks:"Client-Intern who works more than 20 days per month will get an extra day payout accordingly at the year end.",
    internLeavesAffect:"Client-Intern opts for leaves which affect more than 20 working days per month the payout will be reduced in the same month accordingly", 
    evaluatedPeriodicallyClient:"Client-Interns performance will be evaluated periodically and will be eligible for bonus increments based on performance rating at the end of the year", 
    chancesAappear:"Client-Intern will keep on getting chances to appear for the client interview from time to time based on interview availability. There is no promise as such from YaazhTech for periodic client interviews, we request you to keep waiting and monitor with your technical/manager whom you report.", 
    becomeAnEmployeeSuccess:"Also Client-Intern may become an employee without success in Client Interview when performing well in Client project development and candidate training for minimum 18 months. After 18 months of sustaining as a Client-Intern will be eligible to get a salary package ranging from 1.5LPA to 10 LPA based on the years of experience.", 
    modeOftravel:"Mode of Client-Intern is work from home but if required will be asked to travel YaazhTech office at Salem or other locations as per the demand.", 
    homeBenefitsModem:"Work from home benefits as modem one time cost and monthly internet recharge will be made by YaazhTech. (Jio: Rs 667/90 days, Vodafone:)", 
    workingDaySalary:"Payout will be made as per Sidereal Solar Calendar month end working day but salary and tax calculation as per Gregorian Calendar.",
    termsAndConditionsEmployee:"Eligibility terms and conditions in EMPLOYEE", 
    successfulCompletionPrimary:"Successful 100% completion of Dark level and Primary Level and Client Complete Interview process", 
    salaryOffered:"Salary will be offered from 1.5LPA to 30 LPA which includes benefits and deductions (PF, Medical, Gratuity, Insurance and if any refer your offer letter for more details). This salary starts from 1.5LPA and it will increase based on various factors like performance evaluation KPIs, number of years of experience, individuality on development tasks", 
    workExtraDay:"Employees who work more than 20 days per month will get an extra day payout accordingly at the year end.", 
    payoutReduced:"Employees opt for leaves which affect more than 20 working days per month the payout will be reduced in the same month accordingly.", 
    eligibleBonusIncrements:"Employee performance will be evaluated periodically and will be eligible for bonus increments based on performance rating at the end of the year.", 
    nonBillableBenchPolicy:"Non billable bench policy: In case the Employee current client project gets expired/lost due to any reason like performance, client availability the Employee will again be offered a client interview immediately before the last day of the current project and so on. We request the Employee to appear for the same and clear the other client round. If they fail for the multiple interviews / no client shortlisted their profile for any interviews in 60 days of bench period then the Employee will be moved to 1.5LPA bucket.  (called as no billable bench package). Employees will have the option to choose either to continue as a 1.5LPA bucket or termination will be initiated by Yaazhtech because of client interview performance.",
    employmentPaymentSalary:"As part of employment, we may ask Employees to travel to client locations or work from home according to the project allocation. There is no additional payment for travel to the office apart from salary but the salary range will start from 3LPA to 30 LPA.",
    homeBenefits:"Work from home benefits as modem one time cost and monthly internet recharge will be made by YaazhTech. (Jio: Rs 667/90 days, Vodafone:)", 
    salaryAndTaxCalculation:"Payout will be made as per Sidereal Solar Calendar month end working day but salary and tax calculation as per Gregorian Calendar.", 
    trainingPolicy:" Training Policy",
    termsAndConditions:"Terms and conditions:",
    performanceEvaluation1:"Performance Evaluation:",
    technicallyTrainedclient:"Trainees will be technically trained on our internal and/or client project with our technology experts in agile methodology with scrum model for 3 months.", 
    periodicallyEvaluate:"Periodically we will evaluate their candidature with the levels of Info, Dark, Primary and Client Interview in this 3 months of training. ", 
    trainAndHireProgram:"There is no duration when you opt for the train and hire program, according to your ability anytime you can clear the levels and option to become an employee, client-intern or intern or avail training certificate.",
    trainingCostOffer:"Training cost is applied for each training please check our web app for the time to time offered cost.", 
    trainingTiming:"Training can be opted as part time or evening time according to the applicant's current situation.",
    spendTraining:"Maximum 4 hours per week is required to spend on training to fulfill the syllabus.", 
    schedule:"Flexible schedule as per candidate availability.",
    extraTrainingCost:"If in case trainees opt for more than 3 months due to any reason, extra cost will be applicable for each additional month of training. Please check with our HR for the latest offer on the extra training cost. ",
    successfulTraining:"End of successful training Trainees will be provided a certificate as per rating and time spent with us.", 
    leavePolicy:" Leave policy (Train-hire,Intern,Client-Intern,Employee)", 
    effectOn:"(Effect on 1st of Jan 2024.)",
    holidays:" Public Holidays List ",
    
    
    sickLeave:"Sick Leave",
    paidSickLeave:"Employees are entitled to 5 days/year of paid sick leave for self.", 
    interveningHolidaysWeekends:"Intervening holidays like weekends and public holidays falling within the leave period will be taken into account.", 
    normalSalaryDuring:"Employee will be entitled for normal salary during sick leave for full salary 5 days, post which employee payout will be deducted", 
    conditionCertificateissued:"A condition is that the employee would be issued a certificate of temporary incapacity for work by a doctor for consecutive sick leaves.", 
    certificateIssuedSickLeave:"The certificate is issued upon the end of the sick leave for more than 3 days of sick leave.",  
    vacationLeave:"Vacation Leave",
    provideAnOpportunity:" To provide an opportunity to Employees for time off, family vacation, rest and recuperation.", 
    eligibleWorkingDays:"An Employee is eligible for 10 calendar working days of leaves as a pro rata basis.",
    interveningHolidays:"Intervening holidays like weekends and public holidays falling within the leave period will not be taken into account",  
    fullyPaidCompany:"This leave will be fully paid by the Company",
    plannedAndReportedPriorly:"These leaves should be planned and reported priorly to the manager in advance of at least one month",
    employeeAccount:"Leaves will be fully credited into the Employee account at the start of the Accounting year.",  
    unusedLeaves:"Unused leaves are lapsed at the end of financial year December.", 
    carryforwardLeave:"There is no carryforward or encashment for the unused leaves.",
    maternityLeaves:" Maternity Leaves (Employee)",
    provideLeavesPregnancy:" To provide leaves to Female Employees at the time of pregnancy and after child birth.", 
    paternityLeavePolicy:"The company's Paternity leave policy for women as per norms of MATERNITY BENEFIT ACT, 1961 enacted by Central Govt of India.", 
    availabilityPaternityLeave:"Availability of paternity leave should be discussed with the manager in advance",
    paternityLeaves:" Paternity Leaves (Employee)",
    provideMaleEmployees:" To provide male Employees opportunities to bond with and take care of his new born child and enjoy fatherhood",   
    maleEmployeeLeave:"A male employee is entitled to 5 working days of paternity leave in case of child birth.", 
    unfortunateEvents:"Paternity leave is also allowed in case of unfortunate events including still born child or, miscarriage or miscarriage of a child.", 
    companyPaidLeave:"These leaves are Company paid leave",
    interveningHolidays:"Intervening holidays like weekends and public holidays falling within the leave period, will not be taken into account", 
    leaveWithoutPay:" Leave Without Pay",
    policyCompanyEncouragePractice:"It is not the policy of the company to encourage this practice. Under exceptional circumstances like serious illness, the employee can avail of such a leave after approval from the approval authority. This may be sanctioned only when all other forms of leave are exhausted.",
    extraLeavesTakenBeyondLimits:" All other extra leaves taken beyond your limits will be deducted from your salary/stipend and on trainees, you have to pay 500 INR per day for taking extra leaves.", 
    disclaimer:" Disclaimer:",
    companyReservesTheRightAlter:" The company reserves the right to alter, append or withdraw this policy either in part or in full based on managements discretions . All procedures as per the law of land to be adhered to prior to policy launch.",
    yaazhtechTerminateYourEmploymentContract:" Yaazhtech has full rights to reject or terminate your employment contract due to performance issues or any misconduct behavior of the candidate found in the work environment and charge you for the same. Yaazhtech has full rights to amend or cancel this contract at any time without any prior notice.", 
    
    
    };
    export {policyHubEn};