import React, { useState,useEffect } from "react";
import toast, { Toaster } from "react-hot-toast"; 

import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "../../../config/Config";
import { resetPasswordApi} from "../../../api/Api";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { useNavigate, useParams } from 'react-router-dom';

 const ResetPassword = (props) => {
    const { id, token } = useParams();
    const navigate = useNavigate();
        const currentColor = useContextSelector(
          StateContext,
          (state) => state.currentColor,
        );  

 const [resetPasswordRequest, setResetPasswordRequest] = useState({        
            id: 0,
            token: "",
            password: "",
            confirmPassword: ""
         });
  const [passwordPattern,setPasswordPattern]  =useState({
           number: false,
           lowerCase: false,
           upperCase: false,
          charsLength: false
  }) ;  
  const [showLoading, setShowLoading] = useState(false);
   

   /* constructor(props) {
        super(props);

        this.updateNewPassword = this.updateNewPassword.bind(this);
        this.updateConfirmNewPassword = this.updateConfirmNewPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }*/
     useEffect(() => {
        document.title = "Reset my password";
       // alert("sdfd"+JSON.stringify(props))
        if (id && token) {
            let req = {...resetPasswordRequest};
            req.id = id;
            req.token = token;
            setResetPasswordRequest(req);
        } else {
            toast.error("Invalid reset password link !");
            toast.error("error");
            this.props.history.push("/");
        }
      },[]);
   
    const updateNewPassword = (event) => {
        let req = {...resetPasswordRequest};
        req.password = event.target.value;
        setResetPasswordRequest(req);
        let pattern = passwordPattern;

        const lowerCase = /[a-z]/g;
        if (req.password.match(lowerCase)) {
            document.getElementById("lowerCase").classList.remove("text-danger");
            document.getElementById("lowerCase").classList.add("text-success");
            pattern.lowerCase = true;
        } else {
            document.getElementById("lowerCase").classList.remove("text-success");
            document.getElementById("lowerCase").classList.add("text-danger");
            pattern.lowerCase = false;
        }

        const upperCase = /[A-Z]/g;
        if (req.password.match(upperCase)) {
            document.getElementById("upperCase").classList.remove("text-danger");
            document.getElementById("upperCase").classList.add("text-success");
            pattern.upperCase = true;
        } else {
            document.getElementById("upperCase").classList.remove("text-success");
            document.getElementById("upperCase").classList.add("text-danger");
            pattern.lowerCase = false;
        }

        const number = /[0-9]/g;
        if (req.password.match(number)) {
            document.getElementById("number").classList.remove("text-danger");
            document.getElementById("number").classList.add("text-success");
            pattern.number = true;
        } else {
            document.getElementById("number").classList.remove("text-success");
            document.getElementById("number").classList.add("text-danger");
            pattern.number = false;
        }

        if (req.password.length > PASSWORD_MIN_LENGTH && req.password.length < PASSWORD_MAX_LENGTH) {
            document.getElementById("chars").classList.remove("text-danger");
            document.getElementById("chars").classList.add("text-success");
            pattern.charsLength = true;
        } else {
            document.getElementById("chars").classList.remove("text-success");
            document.getElementById("chars").classList.add("text-danger");
            pattern.charsLength = false;
        }
        setPasswordPattern(pattern);

        if (req.password === req.confirmPassword) {
            document.getElementById("inputNewPassword").classList.add("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-valid");
            document.getElementById("inputNewPassword").classList.remove("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-invalid");
        } else {
            document.getElementById("inputNewPassword").classList.add("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-invalid");
            document.getElementById("inputNewPassword").classList.remove("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-valid");
        }
    };

    const updateConfirmNewPassword = (event) => {
        let req = {...resetPasswordRequest};
        req.confirmPassword = event.target.value;
        setResetPasswordRequest(req);
        let pattern = passwordPattern;

        const lowerCase = /[a-z]/g;
        if (req.confirmPassword.match(lowerCase)) {
            document.getElementById("lowerCase").classList.remove("text-danger");
            document.getElementById("lowerCase").classList.add("text-success");
            pattern.lowerCase = true;
        } else {
            document.getElementById("lowerCase").classList.remove("text-success");
            document.getElementById("lowerCase").classList.add("text-danger");
            pattern.lowerCase = false;
        }

        const upperCase = /[A-Z]/g;
        if (req.confirmPassword.match(upperCase)) {
            document.getElementById("upperCase").classList.remove("text-danger");
            document.getElementById("upperCase").classList.add("text-success");
            pattern.upperCase = true;
        } else {
            document.getElementById("upperCase").classList.remove("text-success");
            document.getElementById("upperCase").classList.add("text-danger");
            pattern.lowerCase = false;
        }

        const number = /[0-9]/g;
        if (req.confirmPassword.match(number)) {
            document.getElementById("number").classList.remove("text-danger");
            document.getElementById("number").classList.add("text-success");
            pattern.number = true;
        } else {
            document.getElementById("number").classList.remove("text-success");
            document.getElementById("number").classList.add("text-danger");
            pattern.number = false;
        }

        if (req.confirmPassword.length > PASSWORD_MIN_LENGTH && req.confirmPassword.length < PASSWORD_MAX_LENGTH) {
            document.getElementById("chars").classList.remove("text-danger");
            document.getElementById("chars").classList.add("text-success");
            pattern.charsLength = true;
        } else {
            document.getElementById("chars").classList.remove("text-success");
            document.getElementById("chars").classList.add("text-danger");
            pattern.charsLength = false;
        }
        setPasswordPattern(pattern);
        

        if (req.password === req.confirmPassword) {
            document.getElementById("inputNewPassword").classList.add("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-valid");
            document.getElementById("inputNewPassword").classList.remove("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-invalid");
        } else {
            document.getElementById("inputNewPassword").classList.add("is-invalid");
            document.getElementById("inputConfirmNewPassword").classList.add("is-invalid");
            document.getElementById("inputNewPassword").classList.remove("is-valid");
            document.getElementById("inputConfirmNewPassword").classList.remove("is-valid");
        }
    };

    const resetPassword = (event) => {
       // event.preventDefault();

        const req = {...resetPasswordRequest};

        if (req.password.length < PASSWORD_MIN_LENGTH
            || req.password.length > PASSWORD_MAX_LENGTH) {
            toast.error("Your password must contain between " + PASSWORD_MIN_LENGTH + " and " + PASSWORD_MAX_LENGTH + " characters !");
            toast.error("error");
            return null;
        }

        if (req.confirmPassword.length < PASSWORD_MIN_LENGTH
            || req.confirmPassword.length > PASSWORD_MAX_LENGTH) {
            toast.error("Your password must contain between " + PASSWORD_MIN_LENGTH + " and " + PASSWORD_MAX_LENGTH + " characters !");
            toast.error("error");
            return null;
        }


        if (req.password === req.confirmPassword) {
            const pattern = passwordPattern;
            if (pattern.upperCase && pattern.lowerCase && pattern.charsLength && pattern.number) {
               setShowLoading(true);
                resetPasswordApi(req)
                    .then(res => {
                        toast.success("Your password has been changed !", "success");
                        navigate("/");
                        setShowLoading(false);
                    }).catch(error => {
                    if (error.message) {
                        toast.error(error.message);
                        toast.error("error");
                    } else {
                         toast.error(error.message);
                         toast.error("error");
                    }
                    setShowLoading(false);
                    console.log(error);
                });
            } else {
                toast.error("Invalid password : please take care of password constraints !");
                toast.error("error");
            }

        } else {
            toast.error("Passwords does not match !");
            toast.error("error");
            
        }
    };

        return (
            <div className="container mx-auto p-4 ">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto max-w-md p-8 rounded bg-[#E6F6FA]">
                    <div className="card card-signin my-5">
                        <div className="card-body">


                            {
                                showLoading
                                    ?
                                    <div>
                                        <h1 className="card-title text-center">Loading. Please wait...</h1>
                                        <i className="material-icons w3-xxxlarge w3-spin d-flex justify-content-center">refresh</i>
                                    </div>
                                    :
                                    <div className="text-center" >
                                        <h1 className="card-title text-center">Reset your password</h1>
                                        <form onSubmit={e => resetPassword(e)}>
                                            <div className="form-group mr-20 mt-4">
                                                <label  className="text-xl " htmlFor="inputNewPassword">New password</label>
                                                
                                                <input type="password" className="ml-10 form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="inputNewPassword"
                                                       placeholder="Enter your new password" required autoComplete="off"
                                                       onChange={e => updateNewPassword(e)}
                                                />
                                            </div>
                                            <div className="form-group mr-20 mt-4">
                                                <label className="text-xl" htmlFor="inputConfirmNewPassword">Confirm new password</label>
                                               
                                                <input type="password" className="ml-10 form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                       id="inputConfirmNewPassword"
                                                       placeholder="Enter your new password again" required
                                                       autoComplete="off"
                                                       onChange={e => updateConfirmNewPassword(e)}
                                                />
                                            </div>

                                            <p className="text-muted">Your password must contain :</p>
                                            <p className="text-danger" id="number">* One number</p>
                                            <p className="text-danger" id="lowerCase">* One lower case letter</p>
                                            <p className="text-danger" id="upperCase">* One upper case letter</p>
                                            <p className="text-danger" id="chars">* Between 6 and 20 characters</p>
                                            <button
                                            className="mt-5 ml-20 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-center text-white"
                                            style={{ backgroundColor: currentColor }}
                                             >
                                           <span>Reset password</span>
                                           </button>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
             <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              error: {
                duration: 6000,
                style: {
                  background: "#FF4136",
                  color: "#fff",
                },
              },
 
              success: {
                duration: 6000,
                style: {
                  background: "#008000",
                  color: "#fff",
                },
              },
            }}
            />
            </div>
        );
    
}

export default ResetPassword;