import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
const years = Array.from(
  { length: 10 },
  (_, index) => new Date().getFullYear()-1 + index
);

const PerformanceUpdateModal = ({
  showModal,
  setShowModal,
  name,
  email,
  updatePerformanceType,
  updatePerformanceCategory,
  performanceInput,
  updatePerformanceLevel,
  updateComment,
  updateSprintFromDate,
  updateSprintToDate,
  updateRating,
  updateManagementFeedback,
  updateTechnicalFeedback,
  updateEvaluationFromDate,
  updateEvaluationDateTo,
  updateEvaluationYear,
  currentColor,
  performanceRequest,
  performanceUpdateSubmit,
}) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={showModal}>
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-2xl">
            <div className="border-0 px-3 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
              <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                <Button
                  onClick={() => setShowModal(false)}
                  className="p-1 ml-auto bg-white border-0 text-red float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                >
                  <span className="bg-white text-red h-6 w-6 text-2xl">
                    <IoClose />
                  </span>
                </Button>
              </div>
              <h2 className="card-title text-center text-lg font-semibold">
                Performance Update
              </h2>
              <div className="relative p-0 px-3 flex-auto">
                <h3 className="font-bold text-md">{` ${name} { ${email} }`}</h3>
                <form onSubmit={performanceUpdateSubmit}>
                  <div className="mt-2 mx-3">
                    <label
                      className="float-left items-center flex mt-2"
                      htmlFor="performanceType"
                    >
                      Who?
                    </label>
                    <Select
                      className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      name="option"
                      value={performanceInput.performanceType}
                      onChange={(event) => updatePerformanceType(event)}
                    >
                      <MenuItem value="trainee">Trainee</MenuItem>
                      <MenuItem value="internOrEmployee">
                        Intern Or Employee
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="mt-2 mx-3">
                    <label
                      className="float-left items-center flex mt-2"
                      htmlFor="performanceType"
                    >
                      Evaluation Year
                    </label>

                    <Select
                      label="Year"
                      onChange={(event) => updateEvaluationYear(event)}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  {performanceInput.performanceType === "trainee" && (
                    <>
                      <div className="form-group">
                        <label htmlFor="inputPerformanceLevel">
                          Probation Level
                        </label>
                        <Select
                          className="block w-full mt-2 bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          name="option"
                          value={performanceInput.performanceLevel}
                          onChange={(event) => updatePerformanceLevel(event)}
                        >
                          <MenuItem value="Employment Status">
                            Probation Level
                          </MenuItem>
                          <MenuItem value="danger">danger</MenuItem>
                          <MenuItem value="secondary">secondary</MenuItem>
                          <MenuItem value="info">info</MenuItem>
                          <MenuItem value="dark">dark</MenuItem>
                          <MenuItem value="primary">primary</MenuItem>
                          <MenuItem value="success">success</MenuItem>
                        </Select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputComments" className="font-bold">
                          Current Level comments
                        </label>
                        <TextField
                          type="text"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="inputComments"
                          name="inputComments"
                          placeholder="Performance Comments"
                          required
                          autoComplete="on"
                          onChange={updateComment}
                        />
                      </div>
                    </>
                  )}
                  {performanceInput.performanceType === "internOrEmployee" && (
                    <>
                      <div className="mt-2 mx-3">
                        <label
                          className="float-left items-center flex mt-2"
                          htmlFor="performanceType"
                        >
                          What?
                        </label>
                        <Select
                          className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                          name="option"
                          value={performanceRequest.performanceCategory}
                          onChange={(event) => updatePerformanceCategory(event)}
                        >
                          <MenuItem value="yearEnd">Year End</MenuItem>
                          <MenuItem value="sprintWise">
                            Sprint wise Rating
                          </MenuItem>
                        </Select>
                      </div>

                      {performanceRequest.performanceCategory ===
                      "sprintWise" ? (
                        <>
                          <div className="form-group">
                            <div className="flex flex-wrap -mx-3">
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="sprintFromDate"
                                  className="font-bold"
                                >
                                  Sprint From
                                </label>
                                <br />
                                <TextField
                                  type="date"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="inputSprintFrom"
                                  name="inputSprintFrom"
                                  placeholder="Sprint From"
                                  required
                                  autoComplete="on"
                                  onChange={updateSprintFromDate}
                                />
                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="inputSprintTo"
                                  className="font-bold"
                                >
                                  Sprint To
                                </label>
                                <br />
                                <TextField
                                  type="date"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="inputSprintTo"
                                  name="inputSprintTo"
                                  placeholder="Sprint To"
                                  required
                                  autoComplete="on"
                                  onChange={updateSprintToDate}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label
                              className="font-bold"
                              htmlFor="performanceType"
                            >
                              Rating
                            </label>
                            <br />
                            <Select
                              className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                              name="option"
                              onChange={(event) => updateRating(event)}
                            >
                              <MenuItem value="empty"></MenuItem>
                              <MenuItem value="1">Excellent</MenuItem>
                              <MenuItem value="2">Good</MenuItem>
                              <MenuItem value="3">Not Bad</MenuItem>
                              <MenuItem value="4">Need improvement</MenuItem>
                              <MenuItem value="5">Bad</MenuItem>
                            </Select>
                          </div>

                          <div className="form-group">
                            <div className="flex flex-wrap -mx-3">
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="appraiserComments"
                                  className="font-bold"
                                >
                                  Management Feedback
                                </label>
                                <br />
                                <TextField
                                  type="text"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="appraiserComments"
                                  name="appraiserComments"
                                  placeholder="Managerial Comments"
                                  required
                                  autoComplete="on"
                                  fullWidth
                                  multiline // Enable multiline input
                                  rows={2} // Specify the number of rows
                                  margin="normal"
                                  variant="outlined"
                                  onChange={updateManagementFeedback}
                                />
                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="technicalManagerComments"
                                  className="font-bold"
                                >
                                  Technical Feedback
                                </label>
                                <br />
                                <TextField
                                  type="text"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="technicalManagerComments"
                                  name="technicalManagerComments"
                                  placeholder="Technical Feedback"
                                  required
                                  autoComplete="on"
                                  fullWidth
                                  multiline // Enable multiline input
                                  rows={2} // Specify the number of rows
                                  margin="normal"
                                  variant="outlined"
                                  onChange={updateTechnicalFeedback}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="form-group">
                            <div className="flex flex-wrap -mx-3">
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="sprintFromDate"
                                  className="font-bold"
                                >
                                  Evaluation Date From
                                </label>
                                <br />
                                <TextField
                                  type="date"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="inputSprintFrom"
                                  name="inputSprintFrom"
                                  placeholder="Sprint From"
                                  required
                                  autoComplete="on"
                                  onChange={updateEvaluationFromDate}
                                />
                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="inputSprintTo"
                                  className="font-bold"
                                >
                                  Evaluation Date To
                                </label>
                                <br />
                                <TextField
                                  type="date"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="inputSprintTo"
                                  name="inputSprintTo"
                                  placeholder="Sprint To"
                                  required
                                  autoComplete="on"
                                  onChange={updateEvaluationDateTo}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 mx-3">
                            <label
                              className="font-bold"
                              htmlFor="performanceType"
                            >
                              Rating
                            </label>
                            <Select
                              className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                              name="option"
                              onChange={(event) => updateRating(event)}
                            >
                              <MenuItem value="empty"></MenuItem>
                              <MenuItem value="1">Excellent</MenuItem>
                              <MenuItem value="2">Good</MenuItem>
                              <MenuItem value="3">Not Bad</MenuItem>
                              <MenuItem value="4">Need improvement</MenuItem>
                              <MenuItem value="5">Bad</MenuItem>
                            </Select>
                          </div>
                          <div className="form-group">
                            <div className="flex flex-wrap -mx-3">
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="appraiserComments"
                                  className="font-bold"
                                >
                                  Management Feedback
                                </label>
                                <br />
                                <TextField
                                  type="text"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="appraiserComments"
                                  name="appraiserComments"
                                  placeholder="Managerial Comments"
                                  required
                                  autoComplete="on"
                                  fullWidth
                                  multiline // Enable multiline input
                                  rows={2} // Specify the number of rows
                                  margin="normal"
                                  variant="outlined"
                                  onChange={updateManagementFeedback}
                                />
                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                <label
                                  htmlFor="technicalManagerComments"
                                  className="font-bold"
                                >
                                  Technical Feedback
                                </label>
                                <br />
                                <TextField
                                  type="text"
                                  className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="technicalManagerComments"
                                  name="technicalManagerComments"
                                  placeholder="Technical Feedback"
                                  required
                                  autoComplete="on"
                                  fullWidth
                                  multiline // Enable multiline input
                                  rows={2} // Specify the number of rows
                                  margin="normal"
                                  variant="outlined"
                                  onChange={updateTechnicalFeedback}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <Button
                      style={{ backgroundColor: currentColor }}
                      className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PerformanceUpdateModal;
