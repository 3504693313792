import React from "react";
import { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { getOverallTransactionData } from "../../../../api/FinanceApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../contexts/StateContext";

const OverallTransaction = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  
  const selectionSettings = { persistSelection: true };
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const toolbarOptions = ["PdfExport"];
  const [data, setData] = useState([]);

  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };



  useEffect(() => {
    document.title = "Overall Transaction";
    loadData(category);
  }, [category]);

  const loadData = () => {
    getOverallTransactionData(category)
      .then((res) => {
        console.log("API Response:", res.data);
       // alert(" List :"+JSON.stringify( res.data.data));
         const responseData = res.data.data;
        let overallTransactionList = [];
        for (let i = 0; i < responseData.length; i++) {
          const overallTransaction = { 
          name : "",
           transactionId: "", 
            transactionStatus: "",
            transactionAmount: "",
            dueDate: "",
            subscriptionType: "",
            subscriptionFor: "",
            createdAt: "",
          };
          overallTransaction.name =responseData[i].name;
       overallTransaction.transactionId =responseData[i].transactionId;
          overallTransaction.transactionStatus =responseData[i].transactionStatus;
          overallTransaction.transactionAmount = responseData[i].transactionAmount;
          overallTransaction.dueDate = responseData[i].dueDate;
          overallTransaction.subscriptionType =responseData[i].subscriptionType;
          overallTransaction.subscriptionFor = responseData[i].subscriptionFor;
          overallTransaction.createdAt = responseData[i].createdAt;

          overallTransactionList.push(overallTransaction);
        }
        setData(overallTransactionList);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setAlertMessage(error.message);
          setAlertType("error");
        } else {
          setAlertMessage("Sorry! Something went wrong. Please try again!");
          setAlertType("error");
        }
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  
  const handleCategoryChange = (category) => {
    console.log("Selected category:", category); // Check the value of category
    setCategory(category); // Update selected category
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="p-1 text-2xl">Overall Transaction</h2>
        <div className="p-1 ">
         
            <select
            value={category}
            onChange={(e) => handleCategoryChange(e.target.value)}
            className="border border-gray-300 rounded px-3 py-2 outline-none border-none text-xs relative"
          >
            <option value="">All</option>
            <option value="client">Client</option>
            <option value="trainee">Trainee</option>
          </select>
        </div>
      </div>
    

      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        allowFiltering={true}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='action' headerText='Action' textAlign="Center" isPrimaryKey={true}  width='100' template={onboardTemplate} /> */}
         
         
           <ColumnDirective
            field='name'
            headerText='Name'
            width="100"
            textAlign="Center"
          />
           <ColumnDirective
            field='transactionId'
            headerText='TranactionID'
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="transactionStatus"
            headerText="Status"
            width="150"
            textAlign="Center"
          />
          <ColumnDirective
            field="transactionAmount"
            headerText="Amount"
            width="150"
            textAlign="Center"
          />

          <ColumnDirective
            field='dueDate'
            headerText='end Date'
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="subscriptionType"
            headerText="Subscription Type"
            width="120"
            textAlign="Center"
          />
          <ColumnDirective
            field="subscriptionFor"
            headerText="Subscription For"
            width="120"
            textAlign="Center"
          />
          <ColumnDirective
            field="createdAt"
            headerText="Created At"
            width="120"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default OverallTransaction;
