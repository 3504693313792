import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  IoClose,
  IoCloudDownload,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import {
  updatePaymentStatusBackend,
  fetchSubscriptionDetails,
} from "../../../../../api/FinanceApi";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
} from "@mui/material";
import { HR_TOKEN } from "../../../../../config/Config";

const UpdatePaymentStatus = ({ subscription }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const role = useContextSelector(StateContext, (state) => state.role);
  const [invoiceStatusRequest, setInvoiceStatusRequest] = useState({
    userName: "",
    subscriptionId: "",
    paymentId: "",
    invoiceStatus: "",
    comments: "",
    transactionId: "",
    amountCredited: "",
    paymentDate: "",
    totalBillingDay: "",
    invoiceDate: "",
    invoiceDateChange: "no",
    subscriptionCost: "",
    invoiceType: "",
    subscriptionType: "",
  });

  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Update Payment Existing Subscription for Status";
    updateDataFromSubscription();
  }, []);

  const updateDataFromSubscription = () => {
    let req = { ...invoiceStatusRequest };
    req.paymentId = subscription.paymentId;
    req.subscriptionId = subscription.subscriptionId;
    req.userName = subscription.userName;
    setInvoiceStatusRequest(req);
  };

  const updateField = (field, value) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      [field]: value,
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleClickBack = () => {
    navigate("/");
  };

  const updatePaymentStatus = (event) => {
    event.preventDefault();
    setShowLoading(true);

    updatePaymentStatusBackend(invoiceStatusRequest)
      .then((res) => {
        setShowModal(false);
        toast.success(res.data.message);
        setShowLoading(false);
        if(role==="HR"){
          navigate("/trainees");
        }else{  navigate("/clients");}
      
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again!"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <div>
      {showModal && (
        <>
          <div className="fixed inset-0 z-50 overflow-hidden">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              &#8203;
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                        Update Invoice Or Payment Status
                      </h3>
                      <div className="mt-2">
                        <form onSubmit={updatePaymentStatus}>
                          <div className="grid grid-cols-2 gap-4">
                            <FormControl fullWidth>
                              <InputLabel htmlFor="invoiceStatus">
                                Invoice Status
                              </InputLabel>
                              <Select
                                id="invoiceStatus"
                                value={invoiceStatusRequest.invoiceStatus}
                                onChange={(e) =>
                                  updateField("invoiceStatus", e.target.value)
                                }
                                label="Invoice Status"
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="credited">Credited</MenuItem>
                                <MenuItem value="pending">Modify</MenuItem>
                                <MenuItem value="approved">Approved</MenuItem>
                                <MenuItem value="accepted">Accepted</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                                <MenuItem value="returnWithComments">
                                  Return With Comments
                                </MenuItem>
                              </Select>
                            </FormControl>

                            <TextField
                              fullWidth
                              id="comments"
                              label="Comments"
                              type="text"
                              value={invoiceStatusRequest.comments}
                              onChange={(e) =>
                                updateField("comments", e.target.value)
                              }
                              required
                            />

                            {invoiceStatusRequest.invoiceStatus ===
                              "credited" && (
                              <>
                                <TextField
                                  fullWidth
                                  id="transactionId"
                                  label="Transaction ID"
                                  type="text"
                                  value={invoiceStatusRequest.transactionId}
                                  onChange={(e) =>
                                    updateField("transactionId", e.target.value)
                                  }
                                  required
                                />

                                <TextField
                                  fullWidth
                                  id="amountCredited"
                                  label="Amount Credited"
                                  type="text"
                                  value={invoiceStatusRequest.amountCredited}
                                  onChange={(e) =>
                                    updateField(
                                      "amountCredited",
                                      e.target.value
                                    )
                                  }
                                  required
                                />

                                <TextField
                                  fullWidth
                                  id="paymentDate"
                                  label="Payment Date"
                                  type="date"
                                  value={invoiceStatusRequest.paymentDate}
                                  onChange={(e) =>
                                    updateField("paymentDate", e.target.value)
                                  }
                                  required
                                />
                              </>
                            )}

                            {invoiceStatusRequest.invoiceStatus ===
                              "pending" && (
                              <>
                                <TextField
                                  fullWidth
                                  id="totalBillingDay"
                                  label={`Total Billing Day (${subscription.totalBillingDay})`}
                                  type="text"
                                  value={invoiceStatusRequest.totalBillingDay}
                                  onChange={(e) =>
                                    updateField(
                                      "totalBillingDay",
                                      e.target.value
                                    )
                                  }
                                  required
                                />

                                <TextField
                                  fullWidth
                                  id="subscriptionCost"
                                  label={`Subscription Cost (${subscription.subscriptionCost})`}
                                  type="text"
                                  value={invoiceStatusRequest.subscriptionCost}
                                  onChange={(e) =>
                                    updateField(
                                      "subscriptionCost",
                                      e.target.value
                                    )
                                  }
                                  required
                                />

                                <FormControl fullWidth>
                                  <InputLabel htmlFor="taskType">
                                    Task Type
                                  </InputLabel>
                                  <Select
                                    id="taskType"
                                    value={subscription.taskType}
                                    onChange={(e) =>
                                      updateField("invoiceType", e.target.value)
                                    }
                                    label="Task Type"
                                  >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="nonBillable">
                                      Non-Billable
                                    </MenuItem>
                                    <MenuItem value="internal">
                                      Internal
                                    </MenuItem>
                                    <MenuItem value="billable">
                                      Billable
                                    </MenuItem>
                                    <MenuItem value="others">Others</MenuItem>
                                  </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                  <InputLabel htmlFor="invoiceDateOption">
                                    Change Date and Type?
                                  </InputLabel>
                                  <Select
                                    id="invoiceDateOption"
                                    value={subscription.invoiceDateOption}
                                    onChange={(e) =>
                                      updateField(
                                        "invoiceDateChange",
                                        e.target.value
                                      )
                                    }
                                    label="Change Invoice Date?"
                                  >
                                    <MenuItem value="empty"></MenuItem>
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                  </Select>
                                </FormControl>

                                {invoiceStatusRequest.invoiceDateChange ===
                                  "yes" && (
                                  <>
                                    <TextField
                                      id="invoiceDate"
                                      label="invoice Approved Date"
                                      type="date"
                                      value={invoiceStatusRequest.invoiceDate}
                                      onChange={(e) =>
                                        updateField(
                                          "invoiceDate",
                                          e.target.value
                                        )
                                      }
                                      InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                      fullWidth
                                      id="paymentDate"
                                      label="Payment Date"
                                      type="date"
                                      value={invoiceStatusRequest.paymentDate}
                                      onChange={(e) =>
                                        updateField(
                                          "paymentDate",
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="subscriptionType">
                                        Subscription Type (
                                        {subscription.subscriptionType ||
                                          "Select an option"}
                                        )
                                      </InputLabel>
                                      <Select
                                        id="subscriptionType"
                                        onChange={(e) =>
                                          updateField(
                                            "subscriptionType",
                                            e.target.value
                                          )
                                        }
                                        label="Subscription Type"
                                      >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="monthly">
                                          Monthly
                                        </MenuItem>
                                        <MenuItem value="yearly">
                                          Yearly
                                        </MenuItem>
                                        <MenuItem value="oneTime">
                                          One Time
                                        </MenuItem>
                                        <MenuItem value="cancelled">
                                          Cancelled
                                        </MenuItem>
                                        <MenuItem value="others">
                                          Others
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </>
                                )}
                              </>
                            )}
                            {invoiceStatusRequest.invoiceStatus ===
                              "cancelled" && (
                              <>   <FormControl fullWidth>
                                      <InputLabel htmlFor="subscriptionType">
                                        Subscription Type (
                                        {subscription.subscriptionType ||
                                          "Select an option"}
                                        )
                                      </InputLabel>
                                      <Select
                                        id="subscriptionType"
                                        onChange={(e) =>
                                          updateField(
                                            "subscriptionType",
                                            e.target.value
                                          )
                                        }
                                        label="Subscription Type"
                                      >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="monthly">
                                          Monthly
                                        </MenuItem>
                                        <MenuItem value="yearly">
                                          Yearly
                                        </MenuItem>
                                        <MenuItem value="oneTime">
                                          One Time
                                        </MenuItem>
                                        <MenuItem value="cancelled">
                                          Cancelled
                                        </MenuItem>
                                        <MenuItem value="others">
                                          Others
                                        </MenuItem>
                                      </Select>
                                    </FormControl>

                              </>
                            )}
                          </div>
                          <div className="flex justify-between mt-4">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{ flex: 1, marginRight: "8px" }}
                            >
                              Send
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              style={{
                                flex: 1,
                                backgroundColor: "white",
                                color: "red",
                              }}
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default UpdatePaymentStatus;
