import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CompactTrainingPerformance from "./CompactTrainingPerformance";

const PerformanceTab = ({
  yearEndPerformanceList,
  trainingPerformanceEvaluation,
}) => {
  const [selectedYear, setSelectedYear] = useState(null);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  return (
    <div>
      <CompactTrainingPerformance
        trainingPerformanceEvaluation={trainingPerformanceEvaluation}
      />

      {yearEndPerformanceList?yearEndPerformanceList.map((yearData) => (
        <Grid item xs={12} key={yearData.evaluationYear}>
          <Card
            style={{
              marginTop: "16px",
              cursor: "pointer",
              backgroundColor:
                selectedYear === yearData.evaluationYear
                  ? "#e6f7ff"
                  : "inherit",
            }}
            onClick={() => handleYearSelect(yearData.evaluationYear)}
          >
            <CardContent>
              <Typography variant="h6">{`Overall Performance in Year: ${yearData.evaluationYear}`}</Typography>
              {selectedYear === yearData.evaluationYear &&
                yearData.sprintWiseRatingList && (
                  <div>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography>
                        Evaluation Date From: {yearData.evaluationDateFrom}
                      </Typography>
                      <Typography>
                        Evaluation Date To: {yearData.evaluationDateTo}
                      </Typography>
                      <Typography>
                        Final Rating: {yearData.overallRating}
                      </Typography>
                      <Typography>
                        Overall Sprint Wise Average Rating:{" "}
                        {yearData.overallSprintWiseAverageRating}
                      </Typography>
                      <Typography>
                        Management Feedback: {yearData.managementFeedback}
                      </Typography>
                      <Typography>
                        Technical Feedback: {yearData.technicalFeedback}
                      </Typography>
                    </Grid>
                    <TableContainer component={Table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sprint From Date</TableCell>
                          <TableCell>Sprint To Date</TableCell>
                          <TableCell>Rating</TableCell>
                          <TableCell>Management Feedback</TableCell>
                          <TableCell>Technical Feedback</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {yearData.sprintWiseRatingList.map((sprint) => (
                          <TableRow
                            key={`${yearData.evaluationYear}-${sprint.sprintFromDate}`}
                          >
                            <TableCell>{sprint.sprintFromDate}</TableCell>
                            <TableCell>{sprint.sprintToDate}</TableCell>
                            <TableCell>{sprint.rating}</TableCell>
                            <TableCell>{sprint.managementFeedback}</TableCell>
                            <TableCell>{sprint.technicalFeedback}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </TableContainer>
                  </div>
                )}
            </CardContent>
          </Card>
        </Grid>
      )):null}
    </div>
  );
};

export default PerformanceTab;
