import React from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { TransformingEn} from "../../../data/content/En/TransformingEn";
import { TransformingDe } from '../../../data/content/De/TransformingDe';
import { Language } from '../../../config/Config';
import { Link } from 'react-router-dom';
import Button from '../../../components/StaticButton/Button';

const TransformIdeas = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor,
      ); 
      return (
        
        <div className="container">
     
        <div className="main contact-margin-top" id="contact">
          <div className="contact-div-main">
            <div className="contact-header ml-4 md:ml-10 mt-4">
            <Button  text={Language==='en'? TransformingEn.button:TransformingDe.button} navigation="/hireCoderRegister" />
                    <br></br>
                    <br></br>
            
              <h1 className="section-heading text-3xl font-bold uppercase "style={{color:currentColor}}>{Language==='en'? TransformingEn.header:TransformingDe.header}</h1>
             <br></br>
                     <p className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.t1:TransformingDe.t1}</p>
                      <p className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.t2:TransformingDe.t2}
                     {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-6"><b>{Language==='en'? TransformingEn.why:TransformingDe.warum}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.w1:TransformingDe.w1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.w2:TransformingDe.w2}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.w3:TransformingDe.w3}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-6"><b>{Language==='en'? TransformingEn.more:TransformingDe.mehr}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.m1:TransformingDe.m1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.m2:TransformingDe.m2}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-6"><b>{Language==='en'? TransformingEn.how:TransformingDe.wie}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.o1:TransformingDe.o1}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.o2:TransformingDe.o2}</li>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.o3:TransformingDe.o3}</li>
                      {' '}
                      </p><br></br>
                      <p className="pages-content-text text-lg ml-4 md:ml-6"><b>{Language==='en'? TransformingEn.ready:TransformingDe.ready}</b>
                      <li className="pages-content-text text-lg ml-4 md:ml-6">{Language==='en'? TransformingEn.r1:TransformingDe.r1}</li>
                      {' '}
                      </p><br></br>
                      <Button  text={Language==='en'? TransformingEn.button:TransformingDe.button} navigation="/hireCoderRegister" />
           
                  
             </div>
             </div>
             </div>
             </div>
      )
    }

export default TransformIdeas