// TaskTable.js
import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { DEVELOPERLIST } from "../../../config/Config";

const TaskTable = ({ tasks, onUpdateTask }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedTask, setEditedTask] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [assignedToFilter, setAssignedToFilter] = useState(null);
  const developerDetailList = JSON.parse(localStorage.getItem(DEVELOPERLIST));

  const handleEditClick = (task) => {
    setEditMode(true);
    setEditedTask({ ...task });
    setEditDialogOpen(true);
  };

  const handleSaveClick = () => {
    onUpdateTask(editedTask);
    setEditMode(false);
    setEditedTask({});
    setEditDialogOpen(false);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedTask({});
    setEditDialogOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };
  const handleProjectChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };
  const handleEstimationChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleReassignChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      assignedTo: value,
    }));
  };
  const handlePriorityChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      priority: value,
    }));
  };
  const handleStatusChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      status: value,
    }));
  };
  const handleCommentsChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      comments: value,
    }));
  };
  const handleStartDateChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      startDate: value,
    }));
  };

  const handleEndDateChange = (e) => {
    const { value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      endDate: value,
    }));
  };
  const handleAssignedToFilterChange = (e) => {
    setAssignedToFilter(e.target.value);
  };
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>
        <FormControl fullWidth margin="normal" size="small">
          <InputLabel id="assigned-to-filter-label">Assigned To</InputLabel>
          <Select
            labelId="assigned-to-filter-label"
            id="assigned-to-filter"
            value={assignedToFilter || ""}
            onChange={handleAssignedToFilterChange}
          >
             {developerDetailList.map((developer) => (
                      <MenuItem value={developer.email}>
                        {developer.name}
                      </MenuItem>
                    ))}
                    <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
            {/* 
            <MenuItem value="">All</MenuItem>
            <MenuItem value="kanakarasu20@gmail.com">kanakarasu</MenuItem>
            <MenuItem value="rajappansellamuthu@gmail.com">Rajappan</MenuItem>
            <MenuItem value="varshasriudhayakumar@gmail.com">
              Varshasri
            </MenuItem>
            <MenuItem value="rupavarshni98@gmail.com">Rupavarshini</MenuItem>
            <MenuItem value="annalakshmi.ramasamyy@gmail.com">
              Annalakshmi
            </MenuItem>
            <MenuItem value="ezhilarasi0898@gmail.com">Ezhil</MenuItem>
            <MenuItem value="snpjeni@gmail.com">Jenita</MenuItem>
            <MenuItem value="simburc03@gmail.com">Silabarasi</MenuItem>
            <MenuItem value="gitanjali.moorthy@gmail.com">Gita</MenuItem>
            <MenuItem value="jaya.saran78@gmail.com">PSN</MenuItem>
            <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
          */}
          </Select>
        </FormControl>
      </Grid>
      {tasks.length === 0 ? (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="body1">No tasks available</Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        tasks
        .filter((task) => !assignedToFilter || task.assignedTo === assignedToFilter)
        .map((task) => (
          <Grid item xs={12} key={task.id}>
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="h6" gutterBottom>
                      {task.taskId}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Task Details: </strong> {task.taskDetails}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Assigned To:</strong> {task.assignedTo}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Priority:</strong> {task.priority}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Start Date: </strong>
                      {task.startDate}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Expected Completion Date: </strong>
                      {task.endDate}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Time spent: </strong>
                      {task.estimation * 8} hrs
                    </Typography>
                    <Typography variant="body1">
                      <strong>Comments: </strong>
                      {task.comments}
                    </Typography>
                    <Typography variant="body1">
                      <strong>History: </strong>
                      {task.history}
                    </Typography>
                    {/* Other task details */}
                  </div>
                  <Button
                    variant="outlined"
                    onClick={() => handleEditClick(task)}
                  >
                    Edit
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))
    )}

      {/* Edit Task Dialog */}
      <Dialog open={editDialogOpen} onClose={handleCancelClick}>
        <DialogContent style={{ width: "300px" }}>
          <Typography variant="subtitle2" gutterBottom>
            Edit Task
          </Typography>
          <TextField
            label="taskDetails"
            name="taskDetails"
            value={editedTask.taskDetails || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={editedTask.status || ""}
              onChange={handleStatusChange}
            >
              {/* Replace the following options with your list of email ids */}
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="done">Done</MenuItem>
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="blocker">Blocker</MenuItem>
              <MenuItem value="pause">Pause</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="assigned-to-label">Assigned To</InputLabel>
            <Select
              labelId="assigned-to-label"
              id="assigned-to"
              value={editedTask.assignedTo || ""}
              onChange={handleReassignChange}
            >
               {developerDetailList.map((developer) => (
                      <MenuItem value={developer.email}>
                        {developer.name}
                      </MenuItem>
                    ))}
                    <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
              {/* Replace the following options with your list of email ids */}
              {/*<MenuItem value="kanakarasu20@gmail.com">kanakarasu</MenuItem>
              <MenuItem value="rajappansellamuthu@gmail.com">Rajappan</MenuItem>
              <MenuItem value="varshasriudhayakumar@gmail.com">
                Varshasri
              </MenuItem>
              <MenuItem value="rupavarshni98@gmail.com">Rupavarshini</MenuItem>
              <MenuItem value="annalakshmi.ramasamyy@gmail.com">
                Annalakshmi
              </MenuItem>
              <MenuItem value="ezhilarasi0898@gmail.com">Ezhil</MenuItem>
              <MenuItem value="snpjeni@gmail.com">Jenita</MenuItem>
              <MenuItem value="simburc03@gmail.com">Silabarasi</MenuItem>
              <MenuItem value="gitanjali.moorthy@gmail.com">Gita</MenuItem>
              <MenuItem value="jaya.saran78@gmail.com">PSN</MenuItem>
              <MenuItem value="hr@yaazhtech.com">HR</MenuItem>
                */}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="priority-to-label">Priority</InputLabel>
            <Select
              labelId="priority-to-label"
              id="priority-to"
              value={editedTask.priority || ""}
              onChange={handlePriorityChange}
            >
              {/* Replace the following options with your list of low and high */}
              <MenuItem value="LOW">LOW</MenuItem>
              <MenuItem value="HIGH">HIGH</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Comments"
            name="comments"
            value={editedTask.comments || ""}
            onChange={handleCommentsChange}
            fullWidth
            multiline // Enable multiline input
            rows={4} // Specify the number of rows
            margin="normal"
            variant="outlined" // You can use outlined variant for a more prominent appearance
          />
          <TextField
            label="Start Date"
            type="date"
            name="startDate"
            value={editedTask.startDate || ""}
            onChange={handleStartDateChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            label="End Date"
            type="date"
            name="endDate"
            value={editedTask.endDate || ""}
            onChange={handleEndDateChange}
            fullWidth
            margin="normal"
            size="small"
          />
          {/* Add other fields for completion date, comments, etc. */}
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default TaskTable;
