import React from 'react';
import { Fade } from 'react-reveal';
import { policyHubEn } from '../../../../../../data/content/En/PolicyHubEn';
import { StateContext } from '../../../../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
 
 const TrainAndHire = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="main contact-margin-top" id="contact">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
        
  {/* <h1 className="section-heading text-3xl font-bold uppercase " style={{color:currentColor}}> Policy  </h1>
   <br></br> */}
   <h2 className="pages-content-text text-xl ml-4 md:ml-10" ><strong> {policyHubEn.trainAndHire} </strong></h2>
   <br></br>
   <h3 className="pages-content-text text-l ml-4 md:ml-10"><strong>{policyHubEn.termsAndConditions}</strong></h3>
   <br></br>
   <h4 className='pages-content-text text-l ml-4 md:ml-10'><strong>{policyHubEn.performanceEvaluation}</strong></h4>
   <br></br>
   <p>
    <ul className="list-disc ml-20">
      <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.technicallyTrained}</li>
      <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.periodicallyEvaluate}</li>
      <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.trainingPeriod}</li>
      <li className="pages-content-text text-lg ml-4 md:ml-10 "> {policyHubEn.trainingCost}</li>
      <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.trainingOpted}</li>
      <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.performanceEvaluated}</li>
    </ul>
    </p>
    <br></br>
    <p>
    <ul className='ml-24'>
    <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.codingSkill}</li>
    <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.technicalConcepts}</li>
    <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.softSkills}</li>
    <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.understanding}</li>
    <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.knowledgeSharing}</li>
 
 
    </ul>
   </p>
   <br></br>
   <h4 className="pages-content-text text-l ml-4 md:ml-10"><strong>{policyHubEn.benefitsAndIndenture}</strong></h4>
   <br></br>
   <ul className="list-disc ml-20 ">
   <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.basedPerformance}</li>
   <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.performance}</li>
   </ul>
   <ul className="list-disc ml-28" >
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.rating1}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.rating3}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.rating4}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.rating5}</li>
    </ul>
    <ul className="list-disc ml-20 ">
   <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn. contractCommence}</li>
   <li className="pages-content-text text-lg ml-4 md:ml-10"> {policyHubEn.noticePeriod }</li>
   <p className='pages-content-text text-lg ml-4 md:ml-10'> {policyHubEn.appropriateDetails}</p>
   </ul>
   <br></br>
   <ul className="list-disc ml-24" >
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.intern}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.clientIntern}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.employee}</li>
   </ul>
   <br></br>
   <p className='pages-content-text text-lg ml-4 md:ml-10'><strong>{policyHubEn.termsAndConditionsIntern}</strong></p>
   <br></br>
   <ul className="list-disc ml-20" >
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.successfulCompletion}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.stipendProvided}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.internWorks}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.leavesWhichAffects}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.evaluatedPeriodically}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.caseInternCategory}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.chancesToAppear}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.becomeAnEmployee}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.modeOfIntern}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. homeBenefits}</li>
    <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.payoutMade}</li>
    </ul>
    <br></br>
    <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{policyHubEn. termsAndConditionsClient}</strong></p>
    <br></br>
    <ul className="list-disc ml-20">
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.successfulCompletionDark}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.providedStipend}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.clientInternWorks}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.internLeavesAffect}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.evaluatedPeriodicallyClient}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.chancesAappear}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.becomeAnEmployeeSuccess}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.modeOftravel}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.homeBenefitsModem}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.workingDaySalary}</li>
      </ul>
      <br></br>
      <p className='pages-content-text text-lg ml-4 md:ml-10'><strong>{policyHubEn.termsAndConditionsEmployee}</strong></p>
      <br></br>
      <ul className="list-disc ml-20">
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.successfulCompletionPrimary}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. salaryOffered}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. workExtraDay}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.payoutReduced}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. eligibleBonusIncrements}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.nonBillableBenchPolicy}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn. employmentPaymentSalary}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.homeBenefits}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.salaryAndTaxCalculation}</li>
     
      </ul>
      <br></br>
          <br></br>
      <h1 className="pages-content-text text-xl ml-4 md:ml-10"><strong>{policyHubEn. disclaimer }</strong></h1>
      <br></br>
      <ul className="list-disc ml-20" >
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.companyReservesTheRightAlter}</li>
      <li className='pages-content-text text-lg ml-4 md:ml-10'>{policyHubEn.yaazhtechTerminateYourEmploymentContract}</li>
      </ul>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />
       </div>
       </div> 
    </Fade>
  );
};
 
export default TrainAndHire;