/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Hero from "./Hero";
import Opportunities from "./Opportunities";
import JobCategories from "./JobCategories";
import Testimonial from "./Testimonial";
import Values from "./Values";
import BlogPosts from "./BlogPosts";
import CallToAction from "./CallToAction";
import TabsComponent from "./TabsComponent";

function MainContent() {
  return (
    <>
      <Hero />
      <Opportunities />
      <JobCategories />
      <Testimonial />
      <Values />
      <TabsComponent />
      {/* <BlogPosts /> */}
      <CallToAction />
    </>
  );
}

export default MainContent;
