/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import {
  kannaiyan,
  venkat,
  priya,
  divya,
  esther,
  pavithra,
  rajappan,
  suneel,
  varshasri,
  maheshkumar,
  venu,
  ezhil,
  hemalatha,
  rupavarshini,
  annalakshmi,
} from '../assets/images';
// const images = ['https://images.unsplash.com/photo-1506501139174-099022df5260?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80', 'https://images.unsplash.com/photo-1523438097201-512ae7d59c44?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80', 'https://images.unsplash.com/photo-1513026705753-bc3fffca8bf4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80']
// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']
// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']

const images = [kannaiyan, venkat,
  priya,
  divya,
  esther,
  pavithra,
  rajappan,
  suneel,
  varshasri,
  maheshkumar,
  venu,
  ezhil,
  hemalatha,
  rupavarshini,
  annalakshmi];
const items = [
  {
    header: <div className="row">

      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={venkat} alt="..." style={{ maxWidth: 256 }} />
          <h4>Venkatesan Vadivel</h4>
          <p className="text-muted">Technical Architect</p>
          <ul className="skills  mx-auto">
            <li>Javascript</li>
            <li>React Js</li>
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={ezhil} alt="..." style={{ maxWidth: 256 }} />
          <h4>EzhilArasi R</h4>
          <p className="text-muted">Senior consultant</p>
          <ul className="skills  mx-auto">
            <li>JavaScript</li>
            <li>React Js</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
    </div>,
    key: 1,
  },

  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={divya} alt="..." style={{ maxWidth: 256 }} />
          <h4>Divya Paiyannan</h4>
          <p className="text-muted"> Senior consultant</p>
          <ul className="skills  mx-auto">
            <li>JavaScript</li>
            <li>React Js</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={rajappan} alt="..." style={{ maxWidth: 256 }} />
          <h4>Rajappan S</h4>
          <p className="text-muted"> Senior consultant</p>
          <ul className="skills  mx-auto">
            <li>JavaScript</li>
            <li>React Js</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>

    </div>,
    key: 2,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={pavithra} alt="..." style={{ maxWidth: 256 }} />
          <h4>Pavithra</h4>
          <p className="text-muted">Consultant</p>
          <ul className="skills  mx-auto">
            <li>JavaScript</li>
            <li>React Js</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>

        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={hemalatha} alt="..." style={{ maxWidth: 256 }} />
          <h4>Hemalatha</h4>
          <p className="text-muted">Consultant</p>
          <ul className="skills  mx-auto">
            <li>JavaScript</li>
            <li>React Js</li>
            <li>FlexPLM</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
    </div>,

    key: 3,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={varshasri} alt="..." style={{ maxWidth: 256 }} />
          <h4>varshasri</h4>
          <p className="text-muted">Tech Lead L1</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={esther} alt="..." style={{ maxWidth: 256 }} />
          <h4>Esther Jenita</h4>
          <p className="text-muted">Tech Lead L1</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>

    </div>,
    key: 4,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={suneel} alt="..." style={{ maxWidth: 256 }} />
          <h4>Gokulan</h4>
          <p className="text-muted">Consultant</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>

    </div>,
    key: 5,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={suneel} alt="..." style={{ maxWidth: 256 }} />
          <h4>Suneel Munthala</h4>
          <p className="text-muted">  Senior consultant</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={venu} alt="..." style={{ maxWidth: 256 }} />
          <h4>Venu Regula</h4>
          <p className="text-muted">Consultant</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>

    </div>,
    key: 6,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={maheshkumar} alt="..." style={{ maxWidth: 256 }} />
          <h4>Maheshkumar</h4>
          <p className="text-muted">Senior consultant</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={rupavarshini} alt="..." style={{ maxWidth: 256 }} />
          <h4>Rupavarshini</h4>
          <p className="text-muted">Tech Lead L1</p>
          <ul className="skills  mx-auto">
            <li>Springboot-Java</li>
            <li>FlexPLM</li>
            <li>Postman</li>
            <li>DynamoDB</li>
            <li>AWS EC2</li>
          </ul>
        </div>
      </div>

    </div>,
    key: 7,
  },
  {
    header: <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={annalakshmi} alt="..." style={{ maxWidth: 256 }} />
          <h4>Annalakshmi</h4>
          <p className="text-muted">Tech Lead L1</p>
          <ul className="skills  mx-auto">
            <li>Postman</li>
            <li>AWS EC2</li>
            <li>FlexPLM</li>
            <li>Jira</li>
            <li>Jenkins</li>
            <li>Github</li>
          </ul>
        </div>

      </div>
      <div className="col-lg-6" />

    </div>,
    key: 8,
  },
];

export {
  items,
};
class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExited = this.onExited.bind(this);
    this.onExiting = this.onExiting.bind(this);
    // this.ProductList1 =this.ProductList1.bind(this);
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }
  // ProductList1() {
  // this.props.history.push(<ProductList/>);
  // }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={item.key}
      >
        <br />
        <br />
        <br />
        {item.header}

        <CarouselCaption />

      </CarouselItem>
    ));
    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}

        {...this.props}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    );
  }
}
export default Slider;
