import React, { useState } from "react";
import {
  educationImg,
  financeImg,
  foodFlyImg,
  gvgJewelleryImg,
  pocMvcImg,
  ponmugilImg,
  staffAugmentationImg,
} from "../../assets/images";
import { Container } from "../../components/Container";
import { Link, useNavigate } from "react-router-dom";

const ProductShowcase = () => {
  const [activeImage, setActiveImage] = useState(ponmugilImg);
  const navigate = useNavigate();
  const [activeUrl, setActiveUrl] = useState('/');
  const handleMouseOver = (link, image) => {
    setActiveImage(image);
    setActiveUrl(link);
  }
  const products = [
    {
      id: 1,
      title: "Ponmugil Game Application",
      description:
        "Ponmugil is a captivating mobile game that takes players on an adventurous journey through mystical lands.",
      img: ponmugilImg,
      link: "/",
    },
    {
      id: 2,
      title: "Foodfly App",
      description:
        "FoodFly is your ultimate food delivery companion, connecting you with the best restaurants and eateries in your area",
      img: foodFlyImg,
      link: "https://foodfly.yaazhtech.com/",
    },
    {
      id: 3,
      title: "Finance App",
      description:
        "Finance is your all-in-one personal finance management tool, designed to help you take control of your finances with ease.",
      img: financeImg,
      link: "https://finance.yaazhtech.com/",
    },
    {
      id: 4,
      title: "Education App",
      description:
        "Learning is an innovative education app designed to bring high-quality learning experiences to your fingertips.",
      img: educationImg,
      link: "https://edu.yaazhtech.com/",
    },
    {
      id: 5,
      title: "Visual Glance App",
      description:
        "VisualGlance is a powerful and intuitive app designed for creators, designers, and visual storytellers who want to bring their ideas to life.",
      img: pocMvcImg,
      link: "/",
    },
    {
      id: 6,
      title: "Jewellery App",
      description:
        "Jewellery is your ultimate destination for discovering and purchasing exquisite jewelry pieces, all from the comfort of your mobile device.",
      img: gvgJewelleryImg,
      link: "https://www.gvggroupofcompanies.com/",
    },
  ];

  return (
    <Container className="flex flex-wrap w-full justify-center ">
      <div className="text-center">
        <h2 className="text-3xl font-semibold mb-6">
          Discover Our Innovative Products
        </h2>
        <p className="font-semibold mb-6">
          Empowering businesses with cutting-edge solutions
        </p>
        <div className="flex flex-col md:flex-row max-w-screen-xl mx-auto justify-center">
          {/* Left-hand side for images */}
          <div className="w-full md:w-[40%] p-4 flex justify-center items-center">
          <Link
                  to={activeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
            <img
              src={activeImage}
              alt="Product"
              className="w-full  object-cover rounded-lg transition-transform   bg-white  h-auto  shadow-lg flex justify-center  text-pink-600  border-2 border-pink-600 duration-500"
            />
            </Link>
          </div>

          {/* Right-hand side for cards */}
          <div className="w-full md:w-[60%] p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            {products.map((product) => (
              <div>
                <Link
                  to={product?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    key={product.id}
                    className="p-4 hover:bg-[rgb(140,122,240)] hover:text-white relative z-10 rounded-lg  text-center align-middle font-sans   focus:outline-none text-dark border-2 border-pink-600 cursor-pointer transition-colors duration-300"
                    onMouseEnter={() => handleMouseOver(product.link, product.img)}
                  >
                    <h3 className="text-lg font-semibold">{product.title}</h3>
                    <p className="text-gray-600 hover:text-brown-50">
                      {product.description}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProductShowcase;
