import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { ThemeProvider } from '@material-tailwind/react';


ReactDOM.render(
  <React.StrictMode>
 
    <ThemeProvider>
      <ContextProvider>
      
        <App />
      
      </ContextProvider>
    </ThemeProvider>
 
  </React.StrictMode>,
  document.getElementById('root'),
);
registerServiceWorker();
