// KanbanBoard.js
import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, AppBar, Tabs, Tab } from "@mui/material";
import { useContextSelector } from "use-context-selector";
import TaskTable from "./TaskTable";
import toast from "react-hot-toast";
import { StateContext } from "../../../contexts/StateContext";
import { assignOrUpdateBoard } from "../../../api/HrApi";
const initialTasks = [
  { id: 1, title: "Task 1", assignedTo: "user1@example.com", status: "To Do" },
  {
    id: 2,
    title: "Task 2",
    assignedTo: "user2@example.com",
    status: "In Progress",
  },
  { id: 3, title: "Task 3", assignedTo: "user3@example.com", status: "Done" },
];

const KanbanBoard = ({ myKanban,orgName }) => {
  console.log(orgName);
  console.log(myKanban);
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [tasks, setTasks] = useState([]);
  const [tasksInProgress, setTasksInProgress] = useState([]);
  //const [orgName, setOrgNmae] = useState(orgName);

  const handleUpdateTask = (updatedTask) => {
    // implement backend logic of task reassign or status update of each task by userName,feature,projectName
    //alert("I am in for backend update" + JSON.stringify(updatedTask));
    
    assignOrUpdateBoard(updatedTask,orgName)
      .then((res) => {
       // alert("Response" + res.message);
       toast.success(res.message);
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          //setAlertMessage(error.message);
          //setAlertType("error");
        } else {
          //setAlertMessage("Sorry! Something went wrong. Please try again!");
          //setAlertType("error");
          toast.error("Sorry! Something went wrong. Please try again!");
        }
        //setLoading(false);
        console.log(error);
      });
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.taskId === updatedTask.taskId ? updatedTask : task
      )
    );
  };
  useEffect(() => {
    // alert("inside useEffect of kanban board"+JSON.stringify(myKanban));
    setTasks(myKanban);
   // setOrgNmae(selectedOrg);



  }, [myKanban,orgName]);


  const [tabValue, setTabValue] = useState(0);
  // Filter tasks based on the selected tab
  const filteredTasks =
    tabValue === 0
      ? tasks.filter((task) => task.status === "Open")
      : tabValue === 1
      ? tasks.filter((task) => task.status === "inProgress")
      :tabValue === 2
      ? tasks.filter((task) => task.status === "done")
      :tabValue === 3
      ? tasks.filter((task) => task.status === "blocker")
      : tasks.filter((task) => task.status === "closed");
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Kanban Board
      </Typography>

      {/* Tabs for "To Do," "In Progress," and "Done" */}
      <AppBar position="static" style={{ background: currentColor }}>
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="To Do" />
          <Tab label="In Progress" />
          <Tab label="Done" />
          <Tab label="Blocker" />
          <Tab label="Closed" />
        </Tabs>
      </AppBar>

      {/* TaskTables as TabPanels */}
      <Paper elevation={3} className="p-3">
        <TaskTable tasks={filteredTasks} onUpdateTask={handleUpdateTask} />
      </Paper>
    </Container>
  );
};

export default KanbanBoard;
