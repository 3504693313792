import React from 'react'
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { StaffAugQaAndTestingEn } from '../../../data/content/En/StaffAugQaAndTestingEn';
import { StaffAugQaAndTestingDe } from '../../../data/content/De/StaffAugQaAndTestingDe';
import { Link } from 'react-router-dom';
import { Language } from '../../../config/Config';

const StaffAugQaAndTesting = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    return (
      <div className="container">
      <div className="main contact-margin-top" id="contact">
      
       <div className="contact-div-main">
          <div className="contact-header ml-4 md:ml-10">
          <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}
                ><Link to ="/hireCoderRegister">
                  <span><div className="text-center"/>{Language==='en'? StaffAugQaAndTestingEn.button:StaffAugQaAndTestingDe.button}</span></Link>
          </button>
            <br></br>
            <br></br>
  <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? StaffAugQaAndTestingEn.title:StaffAugQaAndTestingDe.title}</h1>
  <br></br>
  <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.headingContent1:StaffAugQaAndTestingDe.headingContent1}</p><br></br>
  <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.headingContent2:StaffAugQaAndTestingDe.headingContent2}</p><br></br>

  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugQaAndTestingEn.unveiling:StaffAugQaAndTestingDe.unveiling}</b>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.api:StaffAugQaAndTestingDe.api}</li>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.apiTesting:StaffAugQaAndTestingDe.apiTesting}</li>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.yaazhtechExpert:StaffAugQaAndTestingDe.yaazhtechExpert}</li>
  {' '}
  </p><br></br>

  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugQaAndTestingEn.unlocking:StaffAugQaAndTestingDe.unlocking}</b></p>
  <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.automationTesting1:StaffAugQaAndTestingDe.automationTesting1}</p>
  <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.automationTesting2:StaffAugQaAndTestingDe.automationTesting2}</p><br></br>

  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugQaAndTestingEn.comprehensive:StaffAugQaAndTestingDe.comprehensive}</b>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.enhanceTestingCoverage:StaffAugQaAndTestingDe.enhanceTestingCoverage}</li>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.expediteTestingCycles:StaffAugQaAndTestingDe.expediteTestingCycles}</li>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.reduceManualEffort:StaffAugQaAndTestingDe.reduceManualEffort}</li>
  {' '}
  </p><br></br>
 
  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? StaffAugQaAndTestingEn.yaaztechYourPath:StaffAugQaAndTestingDe.yaaztechYourPath}</b>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.byPartnering:StaffAugQaAndTestingDe.byPartnering}</li>
  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? StaffAugQaAndTestingEn.contact:StaffAugQaAndTestingDe.contact}</li>
  {' '}
  </p>
    <hr className="my-5" />
    <div className="d-flex justify-content-between"> 
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white" style={{ backgroundColor: currentColor }}><Link to ="/hireCoderRegister">
        <span><div className="text-center"/>{Language==='en'? StaffAugQaAndTestingEn.button:StaffAugQaAndTestingDe.button}</span></Link>
        </button>
      {/* TODO */}
      <button className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white ml-3" style={{ backgroundColor: currentColor }} ><Link to ="/staffAugmentation">
      <span><div className="text-center"/>{Language==='en'? StaffAugQaAndTestingEn.cancelButton:StaffAugQaAndTestingDe.cancelButton}</span></Link>
      </button>
      </div>
      </div>
      </div>
      </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>
    )
}
export default StaffAugQaAndTesting