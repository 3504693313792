import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { updateTransactionStatus } from "../../../api/Api";
import {useParams} from 'react-router-dom';
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  CLIENT_TOKEN,
  FINANCIER_TOKEN,
  EMPLOYEE_TOKEN,
  transactionTrackingIdCons,
  delimiterUi,
} from "../../../config/Config";
import { NavLink } from "react-router-dom";
import { CircularProgress, Button, Container, Typography, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import { useAuth } from '../../../contexts/AuthContext';
const PaymentSuccessPage = (props) => {
  let { id } = useParams(); 
  let { token } = useParams(); 
  const { authData, setAuthData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );

  const [userName, SetuserName] = useState("");
  const [transactionTrackingIdFromUrl, setTransactionTrackingIdFromUrl] = useState("");
  const [accessTokenFromUrl, setAccessTokenFromUrl] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [xVerify, setXVerify] = useState("");
  const [transactionReference, setTransactionReference] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [defaultRender, setDefaultRender] = useState(false);
  const role = useContextSelector(StateContext, (state) => state.role);
  const setRole = useContextSelector(StateContext, (state) => state.setRole);


  useEffect(() => {
    document.title = "Payment status";
   // let { id } = useParams(); 
    //let { token } = useParams(); 
    // On return, retrieve and store the token from URL if available
    // const urlParams = new URLSearchParams(window.location.search);
    //const tokenFromUrl = urlParams.get('accessToken');
    // alert("Token from url"+urlParams.get('accessToken'));
    // const transactionTrackingIdFromUrl = urlParams.get('transactionTrackingId');
    //alert("transactionTrackingIdFromUrl"+transactionTrackingIdFromUrl)
   // handleRedirect();

  

    if (id && token) {
     // alert("id"+id+"token"+token);
      setAuthData(prevData => ({
        ...prevData,
        token: token,
        transactionTrackingId: id
      }));
      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem(transactionTrackingIdCons, id);

    } else {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        setAuthData(prevData => ({ ...prevData, token }));
      }
    }
    if (isAuthenticated()) { paymentGateWaySuccess(); }

  }, [setAuthData]);

  // Function to handle the URL and store parameters in localStorage
  function handleRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    const combinedParams = urlParams.get('combinedParams');

    if (id && token) {
      //const delimiter = '|'; // Same delimiter used during creation
      const paramsArray = combinedParams.split(delimiterUi);
//alert("combined param"+urlParams.combinedParams());
      // Assuming the order of parameters is known: [accessToken, transactionTrackingId]
      const [accessTokenFromUrl, transactionTrackingIdFromUrl] = paramsArray;
      setTransactionTrackingIdFromUrl(id);
      setAccessTokenFromUrl(token);
      localStorage.setItem(transactionTrackingIdCons, id);
    }
  }

  

  const isAuthenticated = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (
      token !== "undefined" &&
      token !== undefined &&
      token !== null &&
      token !== "null" &&
      token !== ""
    ) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));
      if (token.exp <= Math.floor(Date.now() / 1000)) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(HR_TOKEN);
        localStorage.removeItem(EMPLOYEE_TOKEN);
        localStorage.removeItem(CLIENT_TOKEN);
        localStorage.removeItem(FINANCIER_TOKEN);

        setIsLoggedIn(false);
        return false;
      }

      if (token.rol.length >= 1) {
        setRole(token.rol[0]);
        if (token.rol[0] && token.rol[0] === "ADMIN") {
          localStorage.setItem(ADMIN_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "HR") {
          localStorage.setItem(HR_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "EMPLOYEE") {
          localStorage.setItem(EMPLOYEE_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "CLIENT") {
          localStorage.setItem(CLIENT_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "FINANCIER") {
          localStorage.setItem(FINANCIER_TOKEN, token.rol[0]);
        }
      }
      setIsLoggedIn(true);
      return true;
    } else {
      return false;
    }
  };
  const paymentGateWaySuccess = () => {
    //alert("payment success is called" + localStorage.getItem(transactionTrackingIdCons));
   // alert("payment success is called from auth data" + authData.transactionTrackingId);
    if (localStorage.getItem(transactionTrackingIdCons)) {
      toast("transactionTrackingId - " + localStorage.getItem(transactionTrackingIdCons));
      console.log(localStorage.getItem(transactionTrackingIdCons));
     

      updateTransactionStatus(localStorage.getItem(transactionTrackingIdCons))
        .then((response) => {
          //localStorage.removeItem(transactionTrackingId);

          if (response.message === 'Payment got failed please retry or contact us.') {

            setDefaultRender(true);
            setShowLoading(false);
          } else {
            let res = response.data;
            setPaymentStatus(res.transactionCode);
            setTransactionReference(res.transactionId);
            setShowLoading(false);
            setDefaultRender(false);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          // localStorage.removeItem(transactionTrackingId);
          toast(error);
          setDefaultRender(true);
          setShowLoading(false);
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      // localStorage.removeItem(transactionTrackingId);
      console.log("else case if local storage is empty" + authData.transactionTrackingId)

      setDefaultRender(true);
      setPaymentStatus("failed");

      toast("There is no transactionTrackingId in storage");
      setShowLoading(false);
    }
  };


  if (showLoading) {
    return (
      <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  if (paymentStatus === "PAYMENT_SUCCESS") {
    return (
      <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" gutterBottom>
            Payment Done! {transactionReference}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Thank you for completing your secure online payment.
          </Typography>
          <Typography variant="body2">Have a great day!</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            component={NavLink}
            to={{
              pathname: "/",
            }}
            exact
          >
            GO BACK
          </Button>
        </Paper>

      </Container>
    );
  } if (defaultRender || paymentStatus !== "PAYMENT_SUCCESS") {
    return (
      <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" gutterBottom>
            Payment may failed!!
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Please contact our finance backend team to cross verify before retry.
          </Typography>
          <Typography variant="body2">
            Contact at +91 6382289060 or business@yaazhtech.com
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            component={NavLink}
            to={{
              pathname: "/",
            }}
            exact
          >
            GO BACK
          </Button>
        </Paper>

      </Container>
    );
  }

};

export default PaymentSuccessPage;

