import React from 'react'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { DirectHireEn } from '../../../data/content/En/DirectHireEn';
import { DirectHireDe } from '../../../data/content/De/DirectHireDe';
import { DirectInternEn } from '../../../data/content/En/DirectInternEn';
import { DirectInternDe } from '../../../data/content/De/DirectInternDe';
import DirectIntern from '../DirectIntern';
import { UiDeveloperEn } from '../../../data/content/En/UiDeveloperEn';
import { UiDeveloperDe } from '../../../data/content/De/UiDeveloperDe';
import { BackendDeveloperEn } from '../../../data/content/En/BackendDeveloperEn';
import { BackendDeveloperDe } from '../../../data/content/De/BackendDeveloperDe';
const BackendDeveloperDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
   
    <div className="main contact-margin-top" id="directIntern">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? BackendDeveloperEn.button:BackendDeveloperDe.button}</span>
               
              </Link>
              </button>
        
         <p> <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? BackendDeveloperEn.header:BackendDeveloperDe.header}</h1>
         
                
                 <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.w1:BackendDeveloperDe.w1}</li>
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.eligibility:BackendDeveloperDe.eligibility}</b>

                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.o1:BackendDeveloperDe.o1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.stipendOffered:BackendDeveloperDe.stipendOffered}</b>
              
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.s1:BackendDeveloperDe.s1}</li>
        
                  {' '}
                 </p> <br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.learn:BackendDeveloperDe.learn}</ b>
                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.infoLevel:BackendDeveloperDe.infoLevel}</ b>
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.p1:BackendDeveloperDe.p1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className=""><b>{Language==='en'? BackendDeveloperEn.environmentSetup:BackendDeveloperDe.environmentSetup}</b>
                  
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r1:BackendDeveloperDe.r1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r1:BackendDeveloperDe.r1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r2:BackendDeveloperDe.r2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r3:BackendDeveloperDe.r3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r4:BackendDeveloperDe.r4}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.r5:BackendDeveloperDe.r5}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.install:BackendDeveloperDe.install}</ b>
    j1:"https://www.yaazhtech.com/java-basic-concepts",
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.j1:BackendDeveloperDe.j1">https://www.yaazhtech.com/java-basic-concepts</Link></li>

                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.linkForJava:BackendDeveloperDe.linkForJava}</ b>
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.z1:BackendDeveloperDe.z1">https://www.yaazhtech.com/evaluation-Java-SpringBoot%20Interview%20Questions</Link></li>
                 </p>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.level:BackendDeveloperDe.level}</ b>
        
                {Language==='en'? BackendDeveloperEn.primaryLevel:BackendDeveloperDe.primaryLevel}
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.g1:BackendDeveloperDe.g1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.reactJs:BackendDeveloperDe.reactJs}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? BackendDeveloperEn.h1:BackendDeveloperDe.h1">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? BackendDeveloperEn.flexPlm:BackendDeveloperDe.flexPlm}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'?https://w BackendDeveloperEn.d1:BackendDeveloperDe.d1">https://www.yaazhtech.com/flexPLM-flexPLM-FlexPLM-Self-Intro%202</Link></li>
                  {' '}
{''}</p><br></br>
<p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? BackendDeveloperEn.dark:BackendDeveloperDe.dark}</ b>
                  
        
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? BackendDeveloperEn.l1:BackendDeveloperDe.l1}</li>
                  
                  {' '}
                  </p><br></br>
                  <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? BackendDeveloperEn.button:BackendDeveloperDe.button}</span>
               
              </Link>
              </button>
         </div>
         </div>
         </div>
         </div>
  )
}

export default BackendDeveloperDoc