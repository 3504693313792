import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../../../config/Config";
import {
  assessmentEvaluation,
  assessmentEvaluationMCQ,
  submitAnswers,
} from "../../../../../api/AssessmentApi";
import TimeCounter from "./TimeCounter";
import TimeCounterMcq from "./TimeCounterMcq";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";

const AssessmentAttempt = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { assessment } = location.state || {};

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const [assessmentRequest, setAssessmentRequest] = useState({
    alertIsVisible: true,
    imagePreviewUrl: "",
    user: "",
    showLoading: false,
    questions: [],
    mcqQuestions: [],
    userName: "",
    counter: 0,
    textIdx: 0,
    minutesPerQuestion: 0,
    answerList: [],
    questionAnswerList: [],
    testId: "",
    choices: [],
    fileType: null,
    // result:"",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  useEffect(() => {
    // console.log("Location state:", location.state); // Log to check the received location state
    console.log("Assessment data:", assessment); // Log the assessment data

    updateRetrieveAssessmentData(assessment);
  }, []);

  const updateRetrieveAssessmentData = () => {
    if (!assessment) {
      console.error("Assessment data is missing or empty.");
      return;
    }

    const req = {
      ...assessmentRequest,
      fileType: assessment.fileType,
      questions: assessment.questions || [],
      mcqQuestions: assessment.mcqQuestions || [],
      //  choices: assessment.mcqQuestions.map((q) => q.choices) || [],
      minutesPerQuestion: Math.ceil(
        (assessment.duration || 1) / (assessment.questions?.length || 1)
      ),
      questionsAnswerList: assessment.questionAnswerList || [],
      testId: assessment.testId || "",
      result: assessment.result,
    };
    console.log("Updating request with data:", req);
    setAssessmentRequest(req);
  };

  const onClickSubmitAnswers = () => {
    if (assessmentRequest) {
      setAssessmentRequest({ ...assessmentRequest, showLoading: true });

      const assessmentSubmitAnswerRequest = {
        answers: assessmentRequest.questionAnswerList.filter(
          (el) => el && Object.keys(el).length
        ),
        testId: assessmentRequest.testId,
      };

      submitAnswers(assessmentSubmitAnswerRequest)
        .then((res) => {
          setDialogMessage(
            "Your answers have been submitted successfully. Once HR verifies them, the results will be reflected."
          );
          setOpenDialog(true);
          setAssessmentRequest({ ...assessmentRequest, showLoading: false });
        })
        .catch((error) => {
          setDialogMessage("Oops! Something went wrong.");
          setOpenDialog(true);
          setAssessmentRequest({ ...assessmentRequest, showLoading: false });
        });
    } else {
      console.error("Error: assessmentRequest is null or undefined.");
    }
  };

  const onClickSubmitMCQAnswers = () => {
    if (assessmentRequest) {
      setAssessmentRequest({ ...assessmentRequest, showLoading: true });

      const assessmentSubmitMCQAnswerRequest = {
        answers: assessmentRequest.questionAnswerList
          .filter((el) => el && Object.keys(el).length)
          .map((el) => ({
            question: el.question,
            selectedOptions: el.selectedOptions,
          })),
        testId: assessmentRequest.testId,
      };

      assessmentEvaluationMCQ(assessmentSubmitMCQAnswerRequest)
        .then((res) => {
          const { result, score } = res.data;
          if (result === "pass") {
            setDialogMessage(`Congratulations! You have passed the assessment. 
              Score: ${score}. 
              Result: ${result}.`);
          } else {
            setDialogMessage(`Oops! You did not pass the assessment.
               Score: ${score}.
               Result: ${result}.`);
          }
          setOpenDialog(true);
          setAssessmentRequest({ ...assessmentRequest, showLoading: false });
        })
        .catch((error) => {
          setDialogMessage("Oops! Something went wrong.");
          setOpenDialog(true);
          setAssessmentRequest({ ...assessmentRequest, showLoading: false });
        });
    } else {
      console.error("Error: assessmentRequest is null or undefined.");
    }
  };

  const backToHome = () => {
    navigate("/assessments");
  };

  const handleAnswerList = (e, index, question) => {
    // const { name, value } = e.target;
    const value = e.target.value;
    const list = [...assessmentRequest.answerList];
    const qaList = [...assessmentRequest.questionAnswerList];
    list[index] = value;
    // console.log("value",value)
    qaList[index] = question + ":::::" + value;
    setAssessmentRequest({
      ...assessmentRequest,
      answerList: list,
      questionAnswerList: qaList,
    });
  };
  const handleMCQSelection = (e, index, question) => {
    const value = e.target.value;
    const list = [...assessmentRequest.choices];
    const qaList = [...assessmentRequest.questionAnswerList];
    list[index] = value;
    qaList[index] = { question, selectedOptions: value };
    console.log("handleMCQSelection -> qaList:", qaList); // Log the qaList
    setAssessmentRequest({
      ...assessmentRequest,
      choices: list,
      questionAnswerList: qaList,
    });
  };

  const handleAnswerRemoveClick = (index) => {
    const list = [...assessmentRequest.answerList];
    list.splice(index, 1);
    setAssessmentRequest({ ...assessmentRequest, answerList: list });
  };

  const handleAnswerAddClick = () => {
    setAssessmentRequest({
      ...assessmentRequest,
      answerList: [...assessmentRequest.answerList, ""],
    });
  };

  const showAlert = (message, type) => {
    // Implement your showAlert function here
  };
  const mcqOptions = assessmentRequest.mcqQuestions.map(
    (question) => question.choices
  );

  const handleCloseDialogText = () => {
    setOpenDialog(false);
    navigate("/assessments");
  };
  const handleCloseDialogMcq = () => {
    setOpenDialog(false);
    navigate("/assessmentMcqs");
  };

  return assessmentRequest.fileType === "excel" ? (
    <>
      <TimeCounter
        showAlert={showAlert}
        next={assessmentRequest.textIdx}
        minutesPerQuestion={assessmentRequest.minutesPerQuestion}
        noOfQuestions={assessmentRequest.questions.length}
        questions={assessmentRequest.questions}
        answerList={assessmentRequest.answerList}
        questionAnswerList={assessmentRequest.questionAnswerList}
        testId={assessmentRequest.testId}
        handleAnswerList={handleAnswerList}
        handleAnswerRemoveClick={handleAnswerRemoveClick}
        handleAnswerAddClick={handleAnswerAddClick}
        onClickSubmitAnswers={onClickSubmitAnswers}
        backToHome={backToHome}
        fileType={assessmentRequest.fileType}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialogText}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 2,
            maxWidth: "500px",
            margin: "auto",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            align="center"
          >
            Assessment Result
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            display="flex"
            justifyContent="center"
            align="center"
            minHeight="100px"
          >
            <DialogContentText id="alert-dialog-description" variant="h6">
              {dialogMessage}
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogText}
            variant="contained"
            color="primary"
            fullWidth
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : assessmentRequest.fileType === "txt" ? (
    <>
      <TimeCounterMcq
        showAlert={showAlert}
        next={assessmentRequest.textIdx}
        minutesPerQuestion={assessmentRequest.minutesPerQuestion}
        noOfQuestions={assessmentRequest.mcqQuestions.length}
        questions={assessmentRequest.questions}
        answerList={assessmentRequest.answerList}
        questionAnswerList={assessmentRequest.questionAnswerList}
        testId={assessmentRequest.testId}
        handleAnswerList={handleAnswerList}
        handleAnswerRemoveClick={handleAnswerRemoveClick}
        handleAnswerAddClick={handleAnswerAddClick}
        backToHome={backToHome}
        mcqQuestions={assessmentRequest.mcqQuestions}
        choices={assessmentRequest.choices}
        fileType={assessmentRequest.fileType}
        mcqOptions={mcqOptions}
        handleMCQSelection={handleMCQSelection}
        onClickSubmitMCQAnswers={onClickSubmitMCQAnswers}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialogMcq}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 2,
            maxWidth: "500px",
            margin: "auto",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            align="center"
          >
            Assessment Result
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            display="flex"
            justifyContent="center"
            align="center"
            minHeight="100px"
          >
            <DialogContentText id="alert-dialog-description" variant="h6">
              {dialogMessage}
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogMcq}
            variant="contained"
            color="primary"
            fullWidth
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <>
      <br />
      <br />
      <div className="container">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <small className="text-dark fw-bolder mb-4">
            You encountered a problem due to refresh or screen change.
          </small>
          <button className="btn btn-primary px-4 py-3" onClick={backToHome}>
            <div className="d-inline-block bi bi-download me-2"></div>
            Back to dashboard.
          </button>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        ></script>
      </div>
    </>
  );
};

export default AssessmentAttempt;
