import React, { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { leaveUpdateRequest } from "../../../../../api/HrApi";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import ApplyLeave from "./ApplyLeave";
import TimeSheet from "./TimeSheet";
import LevelUpdate from "../intern/LevelUpdate";
import CategoryUpdate from "./CategoryUpdate";
import LeaveData from "./LeaveData";

const ActionEmployee = ({ name, email, role, totalLeaves, timeSheet, leaveListData }) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const [actionType, setActionType] =useState("");
  
  const updateActionType = (event) => {
    setActionType(event.target.value);
  };
  
  const actionUpdate = (event) => {
    event.preventDefault();
    //alert("JSON"+JSON.stringify(actionType))
    if(actionType === "applyLeave" || actionType === "timeSheet" || actionType === "performanceUpdate" || actionType === "categoryUpdate" || actionType === "leaveData" || actionType=== "viewProfile") {
        setShowLoading(false);
        setShowModal(false);
    }
  };

  return (
    <div>
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Apply Action
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + " { " + email + " }"}
                  </h3>
                  <form onSubmit={actionUpdate}>
                    <div>
                      <div className="form-group">
                        <label htmlFor="category">Choose your action</label>
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          name="option"
                          value={actionType}
                          onChange={(event) => updateActionType(event)}
                        >
                          <option value="actionType">Action Type</option>
                          <option value="applyLeave">Apply Leave</option>
                          <option value="timeSheet">Time Sheet</option>
                          <option value="performanceUpdate">Performance Update</option>
                          <option value="categoryUpdate">Category Update</option>
                          <option value="leaveData">Leave Data</option>
                          <option value="viewProfile">View Profile</option>
                          
                        </select>
                      </div>
                     
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (
        <>
        {
            actionType === "applyLeave" ? (
                <ApplyLeave name={name}  email={email}/>
            ):(
                <>
                {
                    actionType === "timeSheet" ? (
                        <TimeSheet name={name}  email={email} timeSheet={timeSheet}/>
                    ):(
                        <>
                        {
                            actionType === "performanceUpdate" ? (
                                <LevelUpdate name={name}  email={email}/>
                            ):(
                                <>
                                {
                                    actionType === "categoryUpdate" ? (
                                        <CategoryUpdate name={name}  email={email} role={role} totalLeaves={totalLeaves}/>
                                    ):(
                                        <>
                                        {
                                            actionType === "leaveData" ? (
                                                <LeaveData name={name}  email={email} leaveListData={leaveListData}/>
                                            ):(
                                                <>
                                                {
                                                    actionType === "viewProfile" ? (
                                                        <LevelUpdate name={name}  email={email}/>
                                                    ):(
                                                        null
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                        </>
                                    )
                                }
                                </>
                            )
                        }
                        </>
                    )
                }
                </>
            )
        }
        </>
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default ActionEmployee;
