import React from 'react'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { DirectHireEn } from '../../../data/content/En/DirectHireEn';
import { DirectHireDe } from '../../../data/content/De/DirectHireDe';
import { DirectInternEn } from '../../../data/content/En/DirectInternEn';
import { DirectInternDe } from '../../../data/content/De/DirectInternDe';
import DirectIntern from '../DirectIntern';
const DirectInternDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
   
    <div className="main contact-margin-top" id="directHire">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? DirectInternEn.button:DirectInternDe.button}</span>
               
              </Link>
              </button>
        
          <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? DirectInternEn.header:DirectInternDe.header}</h1>
         <br></br>
         <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? DirectInternEn.directInternship:DirectInternDe.directInternship}
                {' '}</b>
                 </p><br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? DirectInternEn.talentCultivation:DirectInternDe.talentCultivation}</b>
                
                 <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectInternEn.w1:DirectInternDe.w1}</li>
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? DirectInternEn.time:DirectInternDe.time}</b>

                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectInternEn.s1:DirectInternDe.s1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? DirectInternEn.internApproach:DirectInternDe.internApproach}</b>
              
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectInternEn.f1:DirectInternDe.f1}</li>
        
                  {' '}
                 </p> <br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? DirectInternEn.qualityIntern:DirectInternDe.qualityIntern}</ b>
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectInternEn.a1:DirectInternDe.a1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className=""><b>{Language==='en'? DirectInternEn.header1:DirectInternDe.header1}</b></p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10">
                {Language==='en'? DirectInternEn.internForm:DirectInternDe.internForm}</p>
                <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? DirectInternEn.innovation:DirectInternDe.innovation}</b>
                
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectInternEn.e1:DirectInternDe.e1}</li>
                  
                  {' '}
                  </p><br></br>

                 
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? DirectHireEn.pipelineFuture:DirectHireDe.pipelineFuture}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectHireEn.q1:DirectHireDe.q1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? DirectHireEn.costSolution:DirectHireDe.costSolution}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectHireEn.y1:DirectHireDe.y1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? DirectHireEn.branding:DirectHireDe.branding}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectHireEn.u1:DirectHireDe.u1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? DirectHireEn.u2:DirectHireDe.u2}</li>
                  {' '}
                  </p><br></br>
                  <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? DirectHireEn.button:DirectHireDe.button}</span>
               
              </Link>
              </button>
         </div>
         </div>
         </div>
         </div>
  )
}

export default DirectInternDoc