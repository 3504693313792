import React, { useState, useEffect } from 'react';
import { StateContext } from './StateContext';
import { getEndpoints } from '../api/Api';
import { ShowAlert } from '../components';
import { ACCESS_TOKEN, ADMIN_TOKEN, HR_TOKEN, CLIENT_TOKEN, FINANCIER_TOKEN, EMPLOYEE_TOKEN } from '../config/Config';
import toast, { Toaster } from 'react-hot-toast';

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export function ContextProvider({ children }) {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#0b77c2');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(initialState);
  const [contentEndpoints, setContentEndpoints] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [role, setRole] = useState('');
  const [language, setLanguage] = useState('EN');
  
  useEffect(() => {
    getEndpoints()
      .then((res) => {
        if (res.data === null) {
           <ShowAlert message={res.message} type="error" />;
          toast(res.message)
        } else {
          toast(res.message)
          setContentEndpoints(res.data);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
        
           toast("Your are not eligible to access this endpoint")
        } else {
          toast(error.message)
         
        }
      });
  }, []);
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  
  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  return (

    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        contentEndpoints,
        setIsLoggedIn,
        isLoggedIn,
        role,
        setRole,
        language,
        setLanguage
      }}
    >
      {children}
      <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: '#FF4136',
                color: '#fff',
              },
            },

            success: {
              duration: 6000,
              style: {
                background: '#008000',
                color: '#fff',
              },
            },
          }}
        />
    </StateContext.Provider>
    
  );
}
