  
import AssessmentsGridLine from './AssessmentsGridLine';
import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Group,
  PdfExport,
 
} from "@syncfusion/ej2-react-grids";
import { useNavigate } from 'react-router-dom';
const AssessmentsGrid = (props) => {
  
  const navigate = useNavigate();
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const toolbarOptions = [
    "Search",
    'SortAscending',
    'PdfExport'
  ];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];   
   const [searchText, setSearchText] = useState("");
   const [traceContent, setTraceContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState(props.content);

  useEffect(() => {
    setAssessments(props.content);
  }, [props.content]);

  const assessmentsGrid =  (event) => {
    let content = [];
  setAssessmentGrid({ ...AssessmentsGrid, showLoading: true });
   getAssessmentGrid().then((res) => {
     // alert("data+"+JSON.stringify(res.data.traces))
      const data ={
        takenow: "",
        skill: "",
       duration: "",
        totalscore:"",
        minpass:"",
        status:"",
        result:"",
        yourscore:"",
      }
      for( let i=0; i< res.data.traces.length; i++){
        const traces = res.data.traces[i];
        data.takenow = traces.request.takenow;
        data.skill = traces.request.skill;
        data.duration = traces.response.duration;
        data.totalscore = traces.totalscore;
        data.minpass=traces.minpass;
        data.status=traces.status;
        data.result=traces.result;
        data.yourscore=traces.yourscore;
       content.push(data);
      }
      setTraceContent(content);

      toast.success(res.data.message);
      setAssessmentsGrid({ ...AssessmentsGrid, showLoading: false });
  })
 
.catch((error) => {
  if (error.status === 401) {
    toast.error(
      "Unable to AssessmentGrid to retrieve  log!'"
    );
  } else  {
    toast.error(error.message);
  }
  setAssessmentsGrid({ ...AssessmentsGrid, showLoading: false });
});

};

const traces =(event) =>{
setAssessmentGrid({...AssessmentsGrid.traces.length});
}



  return (
    <div
    className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl bg-light-gradient-r"
      }
    >
      <h2 className="p-1 text-2xl"> AssessmentGrid
      
      </h2>
      <GridComponent
        dataSource={traceContent}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      > 
        <ColumnsDirective>
     
          <ColumnDirective field='takenow' headerText='Takenow' width='100'  textAlign="Center" />
          <ColumnDirective field='skill' headerText='Skill' width='80'  textAlign="Center" />
          <ColumnDirective field='duration' headerText='Duration' width='80'  textAlign="Center" />
         <ColumnDirective field='totalscore' headerText='Totalscore' width='80'  textAlign="Center" />
         <ColumnDirective field='minpass' headerText='Minpass' width='80'  textAlign="Center" />
         <ColumnDirective field='status' headerText='Status' width='80'  textAlign="Center" />
         <ColumnDirective field='result' headerText='Result' width='80'  textAlign="Center" />
         <ColumnDirective field='yourScore' headerText='YourScore' width='80'  textAlign="Center" />
           </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter,  Group, PdfExport]} />
      </GridComponent>
   
  <hr />
 
                    </div>
        )};
      
    
 
 
export default AssessmentsGrid; 
