import React, { useState } from "react";
import { Fade } from 'react-reveal';
import { policyHubEn } from '../../../../../../data/content/En/PolicyHubEn';
import { StateContext } from '../../../../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import TrainAndHire from "./TrainAndHire";
import TrainingPolicy from "./TrainingPolicy";
import LeavePolicy from "./LeavePolicy";


const Tabs = ({ color }) => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal bg-blue " +
                  (openTab === 1
                    ? "text-black bg-" + color + "-600"
                    : "text-" + color + "-600 bg-blue")
                }
                onClick={e => {
                  e.preventDefault();

                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"

              >
                <i className="fas fa-space-shuttle text-base mr-1"></i> Train and Hire Policy
              </a>

            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal bg-blue " +
                  (openTab === 2
                    ? "text-black bg-" + color + "-600"
                    : "text-" + color + "-600 bg-blue")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="fas fa-cog text-base mr-1"></i> Training Policy
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal bg-blue " +
                  (openTab === 3
                    ? "text-black bg-" + color + "-600"
                    : "text-" + currentColor + "-600 bg-blue")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="fas fa-briefcase text-base mr-1"></i> Leave policy
              </a>
            </li>
           
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-blue w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1" >
                  <TrainAndHire />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"}  id="link2" >
                  <TrainingPolicy /> 
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3" >
                  <LeavePolicy />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} > 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default function AdminTab() {
  return (
    <>
      <Tabs color="black" />;
    </>
  );
}
