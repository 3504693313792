const privacyInfoEn = {
    main: "Last update: 27 May 2023",
    title: "Yaazhtech's privacy policy",
    question: "Why and who?",
    yaazhtechPrivacy:
      
      "Yaazhtech cares about privacy and protecting the Personal Data handled by us. This means that we care about your personal integrity and actively work to protect it.",
  
    policy: "In this Policy, we overall describe how and the purposes for which we use your Personal Data as well as what lawful basis we use and what measures we take to protect Personal Data. We also provide information on how you exercise the rights you have linked to our Processing of Personal data.",
  
    personalData: "Yaazhtech ('Yaazhtech', 'we', 'us', 'our') is the Controller of all Personal Data listed in this Privacy Policy (the 'Policy').",
  
    policyInformation: "This Policy provides information on how we handle Personal Data when you communicate with us, use the Services or visit our website yaazhtech.com (together the 'Functions').",
  
    recipient: "The intended recipient of the information provided in this Policy is:",
    userServices: "Users of the Services",
    potentialCustomers: "Potential customers",
    visitors: "Visitors of our website",
    definitions: "Definitions: ",
    applicableLaw: " Applicable Law refers to the legislation applicable to the Processing of Personal Data, including the GDPR, supplementary national legislation, as well as practices, guidelines and recommendations issued by a national or EU supervisory authority. Controller is the company/organisation that decides for what purposes and in what way personal data is to be processed and is responsible for the Processing of Personal Data in accordance with Applicable Law.Data Subject is the living, natural person whose Personal Data is being processed.Personal Data is all information relating, directly or indirectly, to an identifiable natural person.Processing means any operation or set of operations which is performed on Personal data, e.g. storage, modification, reading, handover and similar.Processor is the company/organisation that processes Personal Data on behalf of the Controller and can therefore only process the Personal Data according to the instructions of the Controller and the Applicable Law.The Services are to provide companies with highly skilled tech talents as well as providing opportunities for talents in search of a remote job.The definitions above shall apply in  the Policy regardless if they are capitalised or not.",
    role: "YaazhTech's role as a Controller",
    policyCovers: "The information in this Policy covers Personal Data Processing for which yaazhTech is the Controller. As a Controller we are responsible for the Processing for which we decide the purpose   and the means for the Processing (what methods, what Personal Data and for how long it is stored. The Policy does not describe how we Process Personal Data in the role of a Processor - i.e. when we process Personal Data on behalf of our customers.",
    platform: "We are providing a platform where we match tech talent with companies in need of adding people to their tech teams. We need to process information in order to be able to reach out to people actively showing interest in our services (to hire talent or to apply to become a member of our network), create user accounts, match clients with talents, handle time reporting, invoicing etc. In some cases, we use personal data for marketing purposes by sending out information or offers.",
    processingPersonalData: "YaazhTech's Processing of Personal Data",
    demonstrate: "We have a responsibility to describe and demonstrate how we fulfill the requirements that are imposed on us when we Process your Personal Data. This section aims to give you an understanding of what type of Personal Data we Process and on what reasons.",
    storage: "For how long do we store your Personal Data?",
    necessaryData: "We will keep your Personal Data as long as it is necessary for the purpose for which it was collected. Depending on the lawful basis on which we support the Processing, this may a) be regulated in a contract, b) be dependent on valid consent, c) be stated in legislation or d) follow by an internal assessment based on a legitimate interest assessment (LIA). In the list below, we indicate, where possible, the period during which the Personal Data will be stored and the criteria used to determine the storage period.",
    deleteData: "We never store your Personal Data longer than neccessary and delete Personal Data regularly. YaazhTech also takes reasonable actions to keep the Personal Data being Processed updated and to delete outdated and otherwise incorrect or redundant Personal Data.",
    processing: "Processing",
    dataProcessing: "The main purpose of the data processing undertaken by us is to provide, carry out and improve our services to you. There are several different reasons why we may need to collect, manage and save your data.",
    typesOfData: "We mainly Process the following types of Personal Data:",
    contractDetails: "Contact details to be able to confirm your identity, to verify your personal and contact details and to be able to communicate with you",
    improveService: "Information on your usage of the service or product to improve our services provided to you",
    customerAnalysis: "IP-adress to perform customer analysis and for content on our site to be presented effectively to you and the device you use",
    accessData: "How do we get access to your Personal Data?",
    collectData: "We collect your Personal Data in a number of different ways. We mainly get access to your Personal Data:",
    provideData: "By you providing your Personal Data to us",
    thirdParty: "Through third-party analysis technology ex. cookies",
    lawfulBasis: "Lawful basis",
    legalBasis: "In order for us to be able to process your Personal Data, it is required that we have so-called legal basis for each process. In our business, we process your personal data mainly on the following grounds:",
    performanceContract: "Performance of a contract - The Processing is necessary for the performance of a contract entered between us and the Data Subject, or to prepare for entering into an agreement with the Data Subject.",
    legitimateInterest: "Legitimate interest - YaazhTech may process Personal Data if we have assessed that a legitimate interest overrides the interest of fundamental rights and freedoms of the Data Subject, and if the processing is necessary for the purpose in question.",
    yourRight: "Your rights",
    exerciseRight: "You are the one in control of your Personal Data and we always strive to ensure that you can exercise your rights as efficiently and smoothly as possible.",
    access: "Access - You always have the right to receive information about the Processing of data that concerns you. We only provide information if we have been able to verify that it is you that are requesting the information.",
    rectificaton: "Rectification - If you find that the Personal Data we process about you is incorrect, let us know and we will fix it!",
    erasure: "Erasure - Do you want us to completely forget about you? You have the right to be forgotten and request deletion of your Personal Data when the Processing is no longer necessary for the purpose for which it was collected. If we are required to retain your information under applicable law or a contract that we have entered with you, we will ensure that it is processed only for the specific purpose set forth in such applicable law or contract. We will thereafter erase the information as soon as possible.",
    objections: "Objections - Do you disagree with our assessment that a legitimate interest for Processing your Personal Data overrides your interest in protecting your privacy? Don't worry - in such case, we will review our legitimate interest assessment. Of course, we add your objection to the balance and make a new assessment to see if we can still justify our Processing of your Personal Data. If you object to direct marketing, we will immediately delete your personal information without making an assessment.",
    restriction: "Restriction - You can also ask us to restrict our Processing of your Personal Data",
    processingRequest: "Whilst we are Processing a request from you for any of your other rights;",
    limit: "If, instead of requesting erasure, you want us to limit the Processing of Personal Data for a specific purpose. For example, if you do not want us to send advertising to you in the future, we still need to save your name in order to know that we should not contact you; or",
    legalClaim: "In cases where we no longer need the information in relation to the purpose for which it was collected, provided that you do not have an interest in retaining it to make a legal claim.",
    dataPortability: "Data portability - We may provide you with the data that you have submitted to us or that we have received from you in connection with a contract that we have entered with you. You will receive your information in a commonly used and machine-readable format that you can transfer to another personal data manager.",
    withdrawConsent: "Withdraw consent - If you have given consent to one or several specific Processing(s) of your Personal Data, you have the right to withdraw your consent at any time and thus ask us to terminate the Processing immediately. Please note that you can only withdraw your consent for future Processing of Personal Data and not for Processing that has already taken place.",
    howToUseRight: "How you use your rights",
    reachOut: "Reach out to business@yaazhtech.com",
    transferData: "Transfer of personal data",
    runBusiness: "In order to run our business, we may need help from others who will process Personal Data on our behalf, so-called Processors.",
    outsideData: "In cases where our Processors transfer Personal Data outside the EU/EEA, we have ensured that the level of protection is adequate, and in compliance with Applicable Law, by controlling that either of the following requirements are fulfilled:",
    euCommission: "the EU Commission has determined that the level of protection is adequate in the third country where the data is processed;",
    signedUp: "the Processor has signed up to the EU Commission's standard contract clauses (SCCs) for data transfer to non-EU/EEA countries; or",
    safegaurds: "the Processor has taken other appropriate safeguards prior to the transfer and that such safeguards comply with Applicable law.",
    dataAgreements: "We have entered into Data Processing Agreements (DPA) with all our Processors. The DPA sets out, among other things, how the Processor may process the Personal Data and what security measures are required for the Processing.",
    obligation: "We may also need to disclose your personal information to certain designated authorities in order to fulfill obligations under applicable law or legally binding judgements.",
    ourProcessors: "Our processors",
    thirdpartPersonalData: "YaazhTech does not sell your Personal Data to third parties and of course we do not share your Personal Data with just anyone. However, in some cases we may need to share your Personal Data with selected third parties. If so, we make sure that the transfer happens in a secure way that protects your privacy. To follow are categories of recipients with whom we may share your data.",
    advertising: "Advertising agencies and suppliers of print and advertising.",
    itSuppliers: "IT suppliers for e.g. business systems and case management. In order to be able to carry out our assignments and services, we store your Personal Data in our business systems (a system that administers our customers and contacts).",
    statistics: "Statistics to contribute to industry statistics and to improve the customer experience.",
    security: "Security measures",
    organistional: "YaazhTech has taken technical and organisational measures to ensure that your Personal Data is processed securely and protected from loss, abuse and unauthorised access.",
    securityMeasures: "Our security measures",
    workMethod: "Organisational security measures are measures that are implemented in work methods and routines within the organisation.",
    governance: "Internal governance documents (policys/instructions)",
    passwordManagement: "Login and password management",
    physicalSercutiy: "Physical security (premises etc.)",
    technicalSecurity: "Technical security measures are measures implemented through technical solutions.",
    encryption: "Encryption",
    accessLog: "Access log",
    secureNetwork: "Secure network",
    vpn: "VPN",
    backUp: "Back-up",
    regularSecurity: "Regular security inspection",
    twoStep: "Two-step verification",
    cookies: "Cookies",
    cookieUse: "YaazhTech uses cookies and similar tracking techniques to analyse the use of the Functions so that we can give you the best user experience. For more information on how we use cookies, see our Cookie Policy (in the footer of the main site).",
    promise: "If we don't keep our promise",
    notified: "If you think that we are not Processing your Personal Data correctly, even after you have notified us of this, you are always entitled to submit your complaint to the Swedish Authority for Privacy Protection.",
    rightFound: "More information about our obligations and your rights can be found at https://www.imy.se/",
    authority: "You can contact the authority via e-mail at: imy@imy.se",
    policyChange: "Changes to this policy",
    rightChange: "We reserve the right to make changes to this Policy. In the event that the change affects our obligations or your rights, we will inform you about the changes in advance so that you are given the opportunity to take a position on the updated policy.",
    contact: "Contact",
    contactQuestion: "Please contact us if you have questions about your rights or if you have any other questions about how we process your personal information:",
    email: "business@yaazhtech.com",
  };

  export {privacyInfoEn};