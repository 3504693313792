import React from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { TrainingAndHiringMainEn } from '../../data/content/En/TrainingAndHiringMainEn';
import { TrainingAndHiringMainDe } from '../../data/content/De/TrainingAndHiringMainDe';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../../components/StaticButton/Button';


const TrainingAndHiring = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );

    const language = useContextSelector(StateContext, (state) => state.language);
  const setLanguage = useContextSelector(
    StateContext,
    (state) => state.setLanguage
  );
    const content = language === 'EN' ? TrainingAndHiringMainEn : TrainingAndHiringMainDe;

    return (
        <div className="container mx-auto p-6">
            <Helmet>
                <title>Launch Your Tech Career with Yaazhtech's Training & Hiring Programs</title>
                <meta name="description" content="Master in-demand skills like Java, Python, DevOps, & more. Gain expert training, career guidance, & land your dream tech job with Yaazhtech. Start your future today!" />
            </Helmet>

            <h1 className="sr-only">Launch Your Tech Career with Yaazhtech's Training & Hiring Programs</h1>

            <div className="text-center mb-10">
                <h2 className="text-3xl font-bold uppercase" style={{ color: currentColor }}>
                    {content.title}
                </h2>
                <p className="text-lg mt-4">{content.explain}</p>
            </div>

            <div className="flex justify-center mb-10">
                <Button text={content.applyButton} navigation="/trainHire"/>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* First Card: Unique Approach */}
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h3 className="font-bold mb-4">{content.uniqueApproach}</h3>
                    <ul className="list-disc list-inside">
                        <li>{content.k1}</li>
                        <li>{content.k2}</li>
                        <li>{content.k3}</li>
                        <li>{content.k4}</li>
                        <li>{content.k5}</li>
                    </ul>
                </div>

                {/* Second Card: Offered Training Programs */}
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h3 className="font-bold mb-4">{content.offerTrainingPrograms}</h3>
                    <ul className="list-disc list-inside">
                        <li><span className="font-bold">Full-Stack Developer Courses:</span> {content.p1}</li>
                        <li><span className="font-bold">Java Programming & Development:</span> {content.p2}</li>
                        <li><span className="font-bold">UI Developer Training:</span> {content.p3}</li>
                        <li><span className="font-bold">Python Course:</span> {content.p4}</li>
                        <li><span className="font-bold">Mobile App Development Classes:</span> {content.p5}</li>
                        <li><span className="font-bold">API Testing:</span> {content.p6}</li>
                        <li><span className="font-bold">Learn Go Programming:</span> {content.p7}</li>
                        <li><span className="font-bold">DevOps Courses:</span> {content.p8}</li>
                        <li><span className="font-bold">Google Digital Marketing:</span> {content.p9}</li>
                        <li><span className="font-bold">Human Resources Training & Development:</span> {content.p10}</li>
                        <li><span className="font-bold">Learn SQL Program:</span> {content.p11}</li>
                        <li><span className="font-bold">Camunda Training:</span> {content.p12}</li>
                    </ul>
                </div>

                {/* Third Card: Top Reasons */}
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h3 className="font-bold mb-4">{content.top}</h3>
                    <ul className="list-disc list-inside">
                        <li>{content.c1}</li>
                        <li>{content.c2}</li>
                        <li>{content.c3}</li>
                        <li>{content.c4}</li>
                        <li>{content.c5}</li>
                        <li>{content.c6}</li>
                        <li>{content.c7}</li>
                        <li>{content.c8}</li>
                        <li>{content.c9}</li>
                        <li>{content.c10}</li>
                        <li>{content.c11}</li>
                        <li>{content.c12}</li>
                    </ul>
                </div>
            </div>

            <div className="text-center mt-10">
                <p className="font-bold text-lg">{content.partner}</p>
            </div>
        </div>
    );
};

export default TrainingAndHiring;
