
import React, { Component } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';

import kannaiyan from '../../assets/images/team/kannaiyanPic.jpg';
import venkat from '../../assets/images/team/venkat.jpg';
import priya from '../../assets/images/team/gokulapriya.jpg';
import divya from '../../assets/images/team/DivyaPaiyannan.jpg';
import esther from '../../assets/images/team/estherjentia.jpg';
import pavithra from '../../assets/images/team/Pavithra.jpg';
import rajappan from '../../assets/images/team/Rajappan.png';
import suneel from '../../assets/images/team/Suneelmunthala.jpg';
import varshasri from '../../assets/images/team/varshasri.jpg';
import maheshkumar from '../../assets/images/team/MaheshKumar.jpg';
import venu from '../../assets/images/team/venu.png';
import ezhil from '../../assets/images/team/EzhilArasi.R.jpeg';
import hemalatha from '../../assets/images/team/Hemalatha.jpeg';
import rupavarshini from '../../assets/images/team/Rupavarshini.jpg';
import annalakshmi from '../../assets/images/team/Annalakshmi.jpg';
import saravanan from '../../assets/images/team/Saravanan.png';
import brindhadevi from '../../assets/images/team/Brindhadevi.png';
import kanakarasu from '../../assets/images/team/kanakarasu.png';
import gitanjali from '../../assets/images/team/Gitanjali.png';



function Team() {

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  return (
    <>
      <br></br>
      <div className="container mx-auto">
        <div className="text-center">

          <h2 className="text-2xl font-bold uppercase text-blue-400 mb-2"
            style={{ color
            : currentColor }} > Our Technical Team </h2>

          <h3 className="text-sm text-gray-500 mb-10"
            style={{ color: currentColor }} > Many hands make light work. </h3>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2">

           <div className="flex items-center flex-col mb-5">
                <img className="rounded-full object-cover object-center object-top transition border border-gray-400" 
                src={ kannaiyan } alt="..." style={{ width: 150, height: 150}} />
                <h4 className="font-semibold"> Kannaiyan S </h4>
                <p className="text-muted"> CEO </p>
                <a href="http://www.linkedin.com/in/kannaiyan-s" aria-label="Parveen Anand LinkedIn Profile" >
                  <FaLinkedin className="text-blue-400 text-2xl" /></a>
              </div>           

              <div className="flex items-center flex-col mb-5">
                <img className="rounded-full object-cover object-center object-top transition border border-gray-400" 
                src={ priya } alt="..." style={{ width: 150, height: 150}} />
                <h4 className="font-semibold"> GokulaPriya Sivaraj </h4>
                <p className="text-muted"> Human Resource </p>
                <a href="http://www.linkedin.com/in/gokulapriya-hr" aria-label="Larry Parker LinkedIn Profile" >
                  <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>
              </div> 

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={venkat} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Venkatesan Vadivel </h4>
            <p className="text-muted"> Senior Technical Architect </p>
            <a href="https://www.linkedin.com/in/venkatesan-vadivel/" aria-label="Diana Petersen LinkedIn Profile" >
              <FaLinkedin className="text-blue-400 text-2xl w-8" /></a>
          </div>

         
          
          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={rajappan} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Rajappan </h4>
            <p className="text-muted"> Senior Consultant </p>
            <a href="http://www.linkedin.com/in/rajappan-sellamuthu-5742b2204" aria-label="Diana Petersen LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>
          </div>

          

          


          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={saravanan} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Saravanan </h4>
            <p className="text-muted"> Technical Architect </p>
            <a href="https://www.linkedin.com/in/saravanan-periyasamy-1008281a0" aria-label="Diana Petersen LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={varshasri} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Varshasri </h4>
            <p className="text-muted"> Technical Architect </p>
            <a href="http://www.linkedin.com/in/varsha-sri-bb4034146" aria-label="Parveen Anand LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>         
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={esther} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Esther Jenita </h4>
            <p className="text-muted"> Tech Lead L1 </p>
            <a href="http://www.linkedin.com/in/esther-jenita" aria-label="Diana Petersen LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>          
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={annalakshmi} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Annalakshmi </h4>
            <p className="text-muted"> Tech Lead 1 </p>
            <a href="https://www.linkedin.com/in/annalakshmir" aria-label="Parveen Anand LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={kanakarasu} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Kanakarasu </h4>
            <p className="text-muted"> Tech Lead 1 </p>
            <a href="https://www.linkedin.com/in/kanakarasu-manickam-6089b025/" aria-label="Larry Parker LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>           
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={rupavarshini} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Rupavarshini </h4>
            <p className="text-muted"> Tech Lead 1 </p>
            <a href="http://www.linkedin.com/in/rupa-varshni" aria-label="Larry Parker LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>         
          </div>

          <div className="flex items-center flex-col mb-5">
            <img className="rounded-full object-cover object-center object-top transition border border-gray-400"
              src={gitanjali} alt="..." style={{ width: 150, height: 150 }} />
            <h4 className="font-semibold"> Gitanjali </h4>
            <p className="text-muted"> Senior Consultant  </p>
            <a href="https://www.linkedin.com/in/gitanjali-panchamoorthy-5a6875103/" aria-label="Larry Parker LinkedIn Profile" >
              <FaLinkedin className="btn btn-dark text-blue-400 text-2xl" /></a>
          </div>

          

          

         

         

        </div>

      </div>
    </>
  );

}

export default Team;
