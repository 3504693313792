import React from 'react'
import { StateContext } from '../../../contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import { Language } from '../../../config/Config';
import { Link,useLocation } from 'react-router-dom';
import { DirectHireEn } from '../../../data/content/En/DirectHireEn';
import { DirectHireDe } from '../../../data/content/De/DirectHireDe';
import { DirectInternEn } from '../../../data/content/En/DirectInternEn';
import { DirectInternDe } from '../../../data/content/De/DirectInternDe';
import DirectIntern from '../DirectIntern';
import { UiDeveloperEn } from '../../../data/content/En/UiDeveloperEn';
import { UiDeveloperDe } from '../../../data/content/De/UiDeveloperDe';
import { BackendDeveloperEn } from '../../../data/content/En/BackendDeveloperEn';
import { BackendDeveloperDe } from '../../../data/content/De/BackendDeveloperDe';
const UiDeveloperDoc = () => {
  const location = useLocation();
  const { subscriptionType, subscriptionFor, subscriptionCost } = location.state;
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );
  return (
    
    <div className="container">
   
    <div className="main contact-margin-top" id="directIntern">
      <div className="contact-div-main">
        <div className="contact-header ml-4 md:ml-10">
         
        <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? UiDeveloperEn.button:UiDeveloperDe.button}</span>
               
              </Link>
              </button>
        
         <p> <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}}>{Language==='en'? BackendDeveloperEn.header:BackendDeveloperDe.header}</h1>
         
                
                 <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.w1:UiDeveloperDe.w1}</li>
                 {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? UiDeveloperEn.eligibility:UiDeveloperDe.eligibility}</b>

                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.o1:UiDeveloperDe.o1}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? UiDeveloperEn.stipendOffered:UiDeveloperDe.stipendOffered}</b>
              
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.s1:UiDeveloperDe.s1}</li>
        
                  {' '}
                 </p> <br></br>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.learn:UiDeveloperDe.learn}</ b>
                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.infoLevel:UiDeveloperDe.infoLevel}</ b>
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.p1:UiDeveloperDe.p1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className=""><b>{Language==='en'? UiDeveloperEn.environmentSetup:UiDeveloperDe.environmentSetup}</b>
                  
                
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r1:UiDeveloperDe.r1}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r2:UiDeveloperDe.r2}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r3:UiDeveloperDe.r3}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r4:UiDeveloperDe.r4}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r5:UiDeveloperDe.r5}</li>
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.r6:UiDeveloperDe.r6}</li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.install:UiDeveloperDe.install}</ b>
                 </p>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.linkForReact:UiDeveloperDe.linkForReact}</ b>
                <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? UiDeveloperEn.z1:UiDeveloperDe.z1">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li>
                 </p>
                 <p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.level:UiDeveloperDe.level}</ b>
        
                {Language==='en'? UiDeveloperEn.primaryLevel:UiDeveloperDe.primaryLevel}
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.g1:UiDeveloperDe.g1}</li>
                  
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? UiDeveloperEn.reactJs:UiDeveloperDe.reactJs}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'? UiDeveloperEn.h1:UiDeveloperDe.h1">https://www.yaazhtech.com/reactJs-reactJs-Basics%20-Introduction</Link></li>
                  {' '}
                  </p><br></br>
                  <p className="pages-content-text text-lg ml-4 md:ml-10"><b>{Language==='en'? UiDeveloperEn.flexPlm:UiDeveloperDe.flexPlm}</b>
                  <li className="pages-content-text text-lg ml-4 md:ml-10"><Link to="/Language==='en'?https://w UiDeveloperEn.d1:UiDeveloperDe.d1">https://www.yaazhtech.com/flexPLM-flexPLM-FlexPLM-Self-Intro%202</Link></li>
                  {' '}
{''}</p><br></br>
<p className="pages-content-text text-lg ml-4 md:ml-10"><b>
                {Language==='en'? UiDeveloperEn.dark:UiDeveloperDe.dark}</ b>
                  
        
                  <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? UiDeveloperEn.l1:UiDeveloperDe.l1}</li>
                  
                  {' '}
                  </p><br></br>
                  <button
                  className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                  style={{ backgroundColor: currentColor }}>
               <Link
                to={{
                  pathname: "/careersRegister",
                }}
                state={{
                  subscriptionCost:subscriptionCost,
                  subscriptionFor:subscriptionFor,
                  subscriptionType:subscriptionType,
                }}
               
              > <span>{Language==='en'? DirectHireEn.button:DirectHireDe.button}</span>
               
              </Link>
              </button>
         </div>
         </div>
         </div>
         </div>
  )
}

export default UiDeveloperDoc