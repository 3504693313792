
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { leaveUpdateRequest } from "../../../../../../../src/api/HrApi";
import {
  check2FAUsage,
  disable2FA,
  enable2FAStepOne,
  enable2FAStepTwo,
} from "../../../../../../../src/api/Api";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

//import TwoFA from "../../../../../assets/images/twoFA.png";
import { ACCESS_TOKEN } from "../../../../../../../src/config/Config";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../../../../contexts/StateContext';
import toast, { Toaster } from 'react-hot-toast';


const LeaveManagement = () => {

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );


  const [leaveRequest, setLeaveRequest] = useState({
    email: "",
    leaveType: "",
    leaveFrom: "",
    leaveTo: "",
    noOfDays: "",
    leaveSummary: "",
    flowFrom: "employeePage",
  });

  const [user, setUser] = useState();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Apply Leaves";
    if (localStorage.getItem(ACCESS_TOKEN)) {
      const token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      let decodedToken = JSON.parse(window.atob(base64));
      setUser(decodedToken);
    }
  }, []);

  const updateLeaveType = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveType: event.target.value });
  };

  const updateLeaveFrom = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveFrom: event.target.value });
  };

  const updateLeaveTo = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveTo: event.target.value });
  };

  const updateNoOfLeaveDay = (event) => {
    setLeaveRequest({ ...leaveRequest, noOfDays: event.target.value });
  };

  const updateLeaveSummary = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveSummary: event.target.value });
  };

  const leaveUpdate = (event) => {
    event.preventDefault(); 
    setShowLoading(true);
    leaveUpdateRequest(leaveRequest)
      .then((res) => {
        toast.success("Employee leave update request has been accepted!");
        setShowLoading(false);
        
      })
      .catch((error) => {
        if (error.status === 401) {
          // props.showAlert("Your username or email does not exist!", "error");
          toast.error(message);
        } else {
          // props.showAlert(error.message, "error");
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };

  return (
    <div  className="text-center" >
      <h1 class="text-2xl ml-10 font-semibold mt-5 flex items-center justify-center">Apply Leave</h1>
      <br></br>
      <div>
        {showLoading ? (
          <div className="align-content-center text-center">
            <h3>Updating your password</h3>
            <i className="material-icons w3-xxxlarge w3-spin align-content-center">
              refresh
            </i>
          </div>
        ) : (
          <form onSubmit={ leaveUpdate}>
            <div className="w-full md:w-1/2 mx-auto p-3">
             
              <div className="grid md:grid-cols-2">
                <label htmlFor="employmentStatus" className="text-xl text-gray-500">Leave Type :</label>
                <select
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"maxlength="1" size="1"
                  id="employmentStatus"
                  placeholder="Select employment status"
                  onChange={updateLeaveType}
                >
                  <option value="Leave Type">Leave Type</option>
                  <option value="sickLeave">Sick Leave</option>
                  <option value="vacationLeave">Vacation Leave</option>
                  <option value="lopLeave">Lop Leave</option>
                  <option value="paternityLeave">Paternity Leave</option>
                  <option value="maternityLeave">Maternity Leave</option>
                </select>
              </div>
              <br></br>
<div className="grid md:grid-cols-2">
              
                <label htmlFor="inputLeaveFrom" className="text-xl text-gray-500">Leave From:</label>
                <input
                  type="Date"
                  id="inputLeaveFrom"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                 
                  placeholder="Leave from"
                  required
                  onChange={updateLeaveFrom}
                  autoComplete="on"
                />
             
              </div>
<br></br>
              <div className="grid md:grid-cols-2">
                <label htmlFor="inputLeaveTo" className="text-xl text-gray-500">Leave To:</label>
                <input
                  type="Date"
                  id="inputLeaveTo"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Leave To"
                  required
                  onChange={updateLeaveTo}
                  autoComplete="on"
                />
              </div>

             <br></br>

              <div className="grid md:grid-cols-2">
                <label htmlFor="inputNoOfLeave" className="text-xl text-gray-500">No of Days:</label>
                <input
                  type="number"
                  id="inputNoOfDays"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500" 
                  placeholder="No of leaves"
                  required
                  onChange={updateNoOfLeaveDay}
                  autoComplete="on"
                />
              </div>
              <br></br>
              <div className="grid md:grid-cols-2">
                <label htmlFor="inputLeave" className="text-xl text-gray-500">Leave summary:</label>
                <input
                  type="text"
                  id="inputLeave"
                  className="w-full py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                  placeholder="Leave reason"
                  required
                  onChange={updateLeaveSummary}
                  autoComplete="on"
                />
              </div>
            
              <br />
             

              <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="submit"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
              Action
            </button>
              
            </div>
          
          </form>
        )}
      </div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
        crossOrigin="anonymous"
      ></script>
      <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: "#FF4136",
                color: "#fff",
              },
            },

            success: {
              duration: 6000,
              style: {
                background: "#008000",
                color: "#fff",
              },
            },
          }}
        />
    </div>
    
  );
};

export default LeaveManagement;

