import React from 'react'
import { Container } from '../../components/Container';
import ImageSwitcher from './ImageSwitcher';

const Service = () => {
  return (
    <div className="overflow-hidden md:py-8 md:px-8">
        <Container className="md:flex w-full ">
        <div className="text-center">
            <h2 className="md:text-3xl text-2xl font-semibold mb-6">Our Services</h2>
            <h1 className='md:text-4xl text-3xl font-semibold mb-6'>Happy customers. Energized employees. That’s impact.</h1>
            <p className='font-semibold mb-6 '>Choose one. Or all. You’ll see better customer relationships and happier employees—without the bloat, hassle, and expense of typical business software. Plus, you’ll get plenty of help from Freddy AI.</p>
            <ImageSwitcher/>
        </div>
        
        </Container>
     
    </div>
  )
}

export default Service;