const hireCoderEn = {
    title: "Welcome to YaazhTech - Our Coding Talent Hub! ",
    codingExpertise:
       
      "Are you in need of top-tier coding expertise to drive your projects to success? Look no further! YaazhTech provides you with skilled and experienced coders ready to tackle your development needs.",
    question: "What we do?",
    yourVision:
      "Unleash Your Vision with Our Tailored Developer Selection. Our curated pool comprises top-notch talent, allowing you to handpick the right individuals to sculpt your dream team. Immediate joiners equipped with diverse skills are at your disposal, ready to dive into projects on flexible contract terms.",
  
    seize: "Seize the opportunity to assemble a team that aligns precisely with your project's needs. With our cherry-picked developers, embark on your journey to success, knowing that you have a versatile and skilled team at your fingertips, primed to bring your vision to life.",
  
    whyChooseOurCoders: "Why Choose Our Coders?",
    expertise: "Expertise: Our coders are vetted for their proficiency in a range of programming languages and technologies.",
    experience: "Experience: From web development to app creation, our coders have a proven track record of delivering high-quality solutions.",
    reliability: "Reliability: Count on our coders to meet deadlines and exceed expectations, ensuring your projects stay on track.",

    howItWorks: "How It Works?",
    postYourProject: "Post Your Project: Browse through coder profiles and select the one that best fits your project.",
    getCoding: "Get Coding: Collaborate with your chosen coder to bring your vision to life.",

    whyPartnerWithUs: "Why Partner With Us?",
    diverseTalent: "Diverse Talent: Access a pool of talented coders with various specializations.",
    flexibility: "Flexibility: Scale resources based on your project demands.",
    support: "Support: Our team is here to assist you throughout the process, ensuring a smooth experience.",

    readyToHireACoder:"Ready to Hire a Coder?",

    startJourney: 
       "Start your journey towards coding excellence today! Post your project and find the perfect coder to elevate your development endeavors.",

    hireExceptionalRemoteDevelopers: "Effortlessly Hire Exceptional Remote Developers on Flexible Contracts, Rapidly.",

    expertInTheTech:"We have Experts in following Technology and Services:",
    button:"Hire a Coder",

  };

  export {hireCoderEn};