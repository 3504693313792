import React from 'react';
import { Link } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../contexts/StateContext';
import Root from './Careers/Root';

const Careers = () => {
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  return (
   <div>
    <Root/>
   </div>
    
  );
};

const CareerCard = ({ title, description, link, mode }) => {
  return (
    <Link to={link}  className={
      mode === "Dark"
        ? "bg-gradient-to-r from-[#fdfffc] to-[#e3bac6] block bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
        : "bg-gradient-to-r from-[#E6F6FA] to-[#90ddf0] block bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
    } >
      <div className="p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">{title}</h2>
        <p className="text-gray-600">{description}</p>
      </div>
    </Link>
  );
};

export default Careers;
