import React from 'react'
import { jobContent, training, jobRequirements, experienced, education } from '../../data/content/PageContent';
import { useContextSelector } from "use-context-selector";
import { Button } from "../../components";
import { StateContext } from "../../contexts/StateContext";
import { Link } from "react-router-dom";
import { FaWhatsapp } from 'react-icons/fa';

const Education = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor
    );
    const currentMode = useContextSelector(
        StateContext,
        (state) => state.currentMode,
      );
    const [showModal, setShowModal] = React.useState(false);


    return (
        <>
         <br></br>
         <div className='p-2 mt-5'>
         <div className="center-container text-center">
                <span className="contact-info text-xl font-bold uppercase mb-2">
                    <p>
                        <a className="whatsapp-link" href="https://wa.me/919042807990" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp className='w-8 h-8 inline-block -mt-1 mr-2' style={{ background: 'green', color: 'white' }} />
                            Whatsapp us for quick apply +91 9042807990
                        </a>
                    </p>
                </span>
            </div>
        </div>
        <br></br>
         <div className="lg:flex lg:flex-row sm:flex sm:flex-col sm:items-center mr-14">
         <div className={currentMode === 'Dark' ? 'w-full ml-10 lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r' : 'w-full ml-10 lg:w-1/4 p-3 rounded-lg bg-light-gradient-r'}>
                        <div className='p-2 mt-5'>
                            <div className='flex flex-row justify-between'>
                                <h3 className='font-semibold text-xl'><span style={{ color: currentColor }}>{education.header1}</span></h3>

                            </div>
                            <p className='px-3 text-sm  bg-backgroundColor' > Efficient Training for respective paper</p>
                            <li className='px-2 text-sm  bg-backgroundColor' > Engineering Mathematics</li>
                            <li className='px-2 text-sm  bg-backgroundColor' > Transform Partial  </li>
                            
                            <li className='px-2 text-sm  bg-backgroundColor' >Differiential equation</li>
                            <li className='px-2 text-sm  bg-backgroundColor' > Probability Theroy</li>
                            <li className='px-2 text-sm  bg-backgroundColor' > Numerical Analysis</li>
                            <br></br>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='px-3 text-sm  bg-backgroundColor' > <b><span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}> ₹ 25,000 {" "} </span> <span>/ </span>
                                        ₹ 12,500{" "}
                                        </b>
                                        </p></div>
                                </div>
                            </div>
                            <div>
                            <div className='px-2 text-sm border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase text-center' style={{ backgroundColor: currentColor }}>
                            <Link
                                to={{
                                        pathname: '/educationDoc',
                                    }}
                                    state= {{
                                        subscriptionCost: '12500',
                                        subscriptionFor: 'Maths',
                                        subscriptionType: 'TRAINING-education',
                                      }}     
                                >
                                    <Button
                                        color="white"
                                        text="Proceed"
                                        borderRadius="10px" />
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
        {/* Computer Science Engineering */}
        <div className={currentMode === 'Dark' ? 'w-full ml-10 lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r' : 'w-full ml-10 lg:w-1/4 p-3 rounded-lg bg-light-gradient-r'}>
                        <div className='p-2 mt-5'>
                            <div className='flex flex-row justify-between'>
                                <h3 className='font-semibold text-xl'><span style={{ color: currentColor }}>{education.header2}</span></h3>
                            </div>
                            <p className='px-3 text-sm  bg-backgroundColor' >Efficient Training for respective paper</p>
                            <li className='px-2 text-sm bg-backgroundColor' >Computer Networks </li>
                            <li className='px-2 text-sm bg-backgroundColor' >Data Structures and Algorithm  </li>
                            <li className='px-2 text-sm bg-backgroundColor' >Computer Archiecture and Organization</li>
                            <li className='px-2 text-sm bg-backgroundColor' >Operating System</li>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='px-3 text-sm  bg-backgroundColor' > <b><span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}> ₹ 25,000 {" "} </span> <span>/ </span>
                                            ₹ 12,500{" "}
                                            </b>
                                        </p></div>
                                </div>
                            </div>
                            <div>
                                <div className='px-2 text-sm border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase text-center' style={{ backgroundColor: currentColor }}>
                                   <Link
                                    to={{
                                        pathname: '/educationDoc',
                                    }}
                                    state= {{
                                        subscriptionCost: '12500',
                                        subscriptionFor: 'Computer Science',
                                        subscriptionType: 'TRAINING-education',
                                      }} 
                                >
                                    <Button
                                        color="white"
                                        text="Proceed"
                                        borderRadius="10px" />
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
             {/* Automobile Engineering*/}
            <div className={currentMode === 'Dark' ? 'w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r' : 'w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r'}>

                        <div className='p-2 mt-5'>
                            <div className='flex flex-row justify-between'>
                                <h3 className='font-semibold text-xl'><span style={{ color: currentColor }}>{education.header3}</span></h3>
                            </div>
                            <p className='px-3 text-sm  bg-backgroundColor'>Efficient Training for respective paper</p>
                            <li className='px-2 text-sm  bg-backgroundColor'>Mobile Design and Aesthetics </li>
                            <li className='px-2 text-sm  bg-backgroundColor'>Automotive materials  </li>
                            <li className='px-2 text-sm  bg-backgroundColor'>Electrical and Electronics</li>
                            <li className='px-2 text-sm  bg-backgroundColor'>Computer aided Vehicle Design </li>
                            <br></br>
                            <br></br>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='px-3 text-sm  bg-backgroundColor' > <b><span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}> ₹ 25,000 {" "} </span> <span>/ </span>
                                            ₹ 12,500{" "}
                                            </b>
                                        </p></div>
                                </div>
                            </div>
                            <div>
                                <div className='px-2 text-sm  border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase text-center ' style={{ backgroundColor: currentColor }}>
                                   <Link
                                    to={{
                                        pathname: '/educationDoc',
                                    }}
                                    state= {{
                                        subscriptionCost: '12500',
                                        subscriptionFor: 'Auto Mobile',
                                        subscriptionType: 'TRAINING-education',

                                      }}   
                                >
                                <Button
                                        color="white"
                                        text="Proceed"
                                        borderRadius="10px" />
                                </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                   
            {/* Mechanical Engineering */}
            <div className='p-2 mt-5'>
            <div className="lg:flex lg:flex-row sm:flex sm:flex-col sm:items-center mr-14">       
            <div className={currentMode === 'Dark' ? 'w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r' : 'w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r'}>
                        

                        <div className='p-2 mt-5'>
                            <div className='flex flex-row justify-between'>
                                <h3 className='font-semibold text-xl'><span style={{ color: currentColor }}>{education.header4}</span></h3>
                            </div>
                            <p className='px-3 text-sm bg-backgroundColor'>Efficient Training for respective paper</p>
                            <li className='px-2 text-sm bg-backgroundColor'>Fluid Mechanics </li>
                            <li className='px-2 text-sm bg-backgroundColor'> Materials Science </li>
                            <li className='px-2 text-sm  bg-backgroundColor'>Mechanics of Materials</li>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className='flex flex-row justify-between mt-3'>
                                <div className='flex gap-2'>
                                <div>
                                <p className='px-3 text-sm bg-backgroundColor'><b><span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}> ₹ 25,000 {" "}</span> <span>/</span> ₹ 12,500{" "} </b> </p></div>
                                </div>

                            </div>
                            <div>
                                <div className='px-2 text-sm  border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase text-center' style={{ backgroundColor: currentColor }}>
                                   <Link
                                    to={{
                                        pathname: '/educationDoc',
                                    }}
                                    state= {{
                                        subscriptionCost: '12500',
                                        subscriptionFor: 'Mechanical',
                                        subscriptionType: 'TRAINING-education',
                                      }}
                                    
                                >

                                    <Button
                                        color="white"
                                        text="Proceed"
                                        borderRadius="10px" />
                                </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
        </>
    )
}

export default Education
