import React from 'react';

const LeadershipPopup = ({ member, closePopup }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-[90%] max-w-4xl rounded-lg p-8 relative">
        {/* Close button */}
        <button
          onClick={closePopup}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Popup content */}
        <div className="flex flex-col md:flex-row gap-6">
          {/* Left side: Image with background color */}
          <div
            className="w-full md:w-1/2 h-[300px] relative"
            style={{ backgroundColor: member.color }}
          >
            <img src={member.imageUrl} alt={member.name} className="w-[250px] h-[300px] mx-auto object-cover" />
          </div>

          {/* Right side: Details */}
          <div className="w-full md:w-1/2 flex flex-col justify-center">
            <h2 className="text-2xl font-bold text-gray-900">{member.name}</h2>
            <p className="text-xl text-gray-600 mb-4">{member.position}</p>
            <p className="text-gray-700 mb-4">{member.about}</p>

            {/* LinkedIn link */}
            {member.linkedin && (
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-500 hover:text-pink-800"
              >
                View on LinkedIn
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadershipPopup;
