import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../../src/contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { deleteAssessment } from "../../../../../api/AssessmentApi";
import { Button } from "@mui/material";

const DeleteAssessment = ({  testId}) => {
  const currentColor = useContextSelector(StateContext, (state) => state.currentColor);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
//  const [category,setCategory] = useState();
  //const [createdAt,SetCreatedAt]= useState();
  const navigate = useNavigate();

  // useEffect(() => {
  //   setCategory(category);
  // }, [category]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const deleteAssessments = async (event) => {
    event.preventDefault();
    setShowLoading(true);

    console.log('Deleting assessment with:', { testId });
    if ( !testId) {
      toast.error("testId is missing");
      setShowLoading(false);
      return;
    }
    try {
      const response = await deleteAssessment( testId);
      toast.success("Assessment deleted successfully");
      setShowLoading(false);
      toggleModal();
    //  navigate(-1); // Redirect after deletion
    } catch (error) {
      toast.error(error.message);
      setShowLoading(false);
    }
  };
  const backToHome = (event) => {
    event.preventDefault();
    setShowModal(!showModal);
   };
  return (
    <div>
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={toggleModal}
      >
        <span style={{ color: currentColor }}>
          <FontAwesomeIcon icon={faTrash} style={{ color: 'black' }} />
        </span>
      </button>
      {showModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-2xl">
            <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
              <h2 className="card-title text-center text-lg font-semibold">
                Delete Assessment
              </h2>
              <div className="relative py-3 px-3 flex-auto max-h-96 overflow-y-auto">
                <form onSubmit={deleteAssessments}>
                  <p>
                    Are you sure that you want to delete this assessment.
                    
                  </p>
                  <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b gap-4">              
                        <button
                      style={{ backgroundColor: currentColor }}
                      className="text-white  uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none ease-linear transition-all duration-150 w-20 h-10 flex items-center justify-center"
                      type="submit"
                      // disabled={showLoading}
                      disabled={showLoading || !testId}
                    >
                      {showLoading ? 'Deleting...' :'Delete'}
                    </button>
                    <button class="bg-gray-200 border-2 border-gray-300 rounded-md text-gray-800 py-2 px-4 hover:bg-gray-300 hover:border-gray-400 w-20 h-10 " onClick={backToHome }>
  Back
</button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>

  );
};

export default DeleteAssessment;
