const DirectHireMainEn={
    title:"YaazhTech: Your One-Stop Shop for Direct Hiring Top Tech Talent",
    explain:"YaazhTech streamlines your hiring process by connecting you directly with exceptional developers, designers, and engineers. We specialize in finding the perfect fit for your team, removing the middleman and saving you valuable time and resources.",
  
    lookingTo:"Looking to:",
    l1:" to build your dream app?",
    l2:" a talented software developer to spearhead your next project?",
    l3:" the perfect web developer to elevate your online presence?",
    noFurthur:"Look no further! YaazhTech is your one-stop solution for acquiring exceptional tech talent through direct hiring.",

    applyButton: "Apply Here",
    
    offer:"Here's what we offer:",
    explain:"We specialize in connecting you with pre-vetted, highly skilled professionals in various areas, including:",
    p1:"React Developers",
    p2:"Java Developers",
    p3:"Full Stack Developers",
    p4:"Mobile Developers",
    p5:"Camunda Developers",
    p6:"DevOps Engineers",
    p7:"SEO Specialists",
    p8:"Python Developers",
    p9:"Human Resources Managers",
    p10:"API Testing Experts",
    p11:"SQL Developers",
    p12:"Golang Developers",
  
    ready: "Ready to build your dream development team?",
    contact:"Contact YaazhTech today for a free consultation. Let us help you find the perfect direct-hire coder for your specific needs and fuel your technological innovation.",
  
    }
  
    
  
    export {DirectHireMainEn};