const StaffAugDatabaseEngineerEn = {
    title: "Yaazhtech: Empowering Your Data with Expert SQL Developers",
    headingContent1:
      "At Yaazhtech, we understand the critical role data plays in driving informed decision-making and propelling business growth. That's why we offer a team of highly skilled SQL developers dedicated to unlocking the potential of your data through the power of SQL.",
    
    mastersOfDataManipulation: "Masters of Data Manipulation: SQL Developers at Yaazhtech:",
    ourSqlDevelopers: "Our SQL developers are not just masters of writing queries; they are data architects who understand the intricacies of database design and optimization.",
    leverage:"They leverage their expertise in SQL to:",
    craftRobustAndEfficientDatabaseStructures: "Craft robust and efficient database structures: Our developers meticulously design databases that cater to your specific data storage and retrieval needs, ensuring optimal performance and scalability.",
    extractValuable: "Extract valuable insights through powerful queries: They wield SQL as a powerful tool to write complex queries, allowing you to extract actionable insights from your data and gain a deeper understanding of your customers, operations, and market trends.",
    maintain:"Maintain data integrity and security: Our developers prioritize data security and adhere to best practices to safeguard your valuable information.",
    
    partnerWith: (
      <span>
        <a href="/contact" style={{ color: 'blue', fontWeight: 'bold'}}>Partner with Yaazhtech</a>
        {' '}
        and empower your organization with a comprehensive understanding of your data. Contact us today to connect with our skilled SQL developers and unlock the full potential of your data assets.
      </span>
    ),

    button:"Hire a Coder",
    cancelButton:"Cancel",

  };

  export {StaffAugDatabaseEngineerEn};