import React, { useState } from "react";
import { jobContent, internDirect } from "../../data/content/PageContent";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import Button from '../../components/StaticButton/Button';
const DirectIntern = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );

  const [showModal, setShowModal] = React.useState(false);
  const reactLink = internDirect.reactLink;
  const javaLink = internDirect.javaLink;
  const signUpLink = internDirect.signUpLink;
  const signInLink = internDirect.signInLink;
  const assessmentLink = internDirect.assessmentLink;
  const [showUiDeveloper, setUiDeveloper] = useState(false);
  const [showFullStackDeveloper, setFullStackDeveloper] = React.useState(false);
  const [showBackendDeveloper, setBackendDeveloper] = React.useState(false);

  const toggleUiDeveloper = () => {
    setUiDeveloper(!showUiDeveloper);
  };

  const toggleFullStackDeveloper = () => {
    setFullStackDeveloper(!showFullStackDeveloper);
  };

  const toggleBackendDeveloper = () => {
    setBackendDeveloper(!showBackendDeveloper);
  };

  return (
    <>
      <br></br>
      <div className="p-2 mt-5">
        <div className="center-container text-center">
          <span className="contact-info text-xl font-bold uppercase mb-2">
            <p>
              <a
                className="whatsapp-link"
                href="https://wa.me/918072488209"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp
                  className="w-8 h-8 inline-block -mt-1 mr-2"
                  style={{ background: "green", color: "white" }}
                />
                Whatsapp us for quick apply +91 8072488209
              </a>
            </p>
          </span>
        </div>
        <br></br>
      </div>
      <br></br>
      <div className=" lg:flex lg:flex-row justify-center sm:flex sm:flex-col sm:items-center mr-14">
        {/* <div
          className={
            currentMode === "Dark"
              ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
              : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
          }
        > */}
        <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
          <div className="p-2 mt-5">
            <h3 className="font-semibold text-lg">
              <span style={{ color: currentColor }}>
                {jobContent.uiDeveloper}
              </span>
            </h3>

            <p className="px-3 text-sm  bg-backgroundColor">
              {" "}
              Direct Intern for Candidates
            </p>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Complete three Levels
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Intern with Stipend per month
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Eligibility: BE/BTech or BCA/MCA/Msc
            </li>
            <div className="flex flex-row justify-between mt-3">
              <div className="flex gap-2">
                <div>
                  <p className="px-3 text-xs bg-backgroundColor">
                    {" "}
                    <span style={{ textDecorationStyle: "solid" }}>
                    <b>Application Fees: ₹236/- <span style={{fontSize:10}}>(incl. GST 18%)</span> </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row justify-between mt-3">
                <div className="flex gap-2">
                  <div>
                    <p className="px-3 text-sm bg-backgroundColor">
                      {" "}
                      <span style={{ textDecorationStyle: "solid" }}>
                        <b>Stipend: ₹5,000 </b>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <Button text="proceed" clickMethod={toggleUiDeveloper} />
              
            </div>

            {showUiDeveloper && (
              <Dialog open={showUiDeveloper} fullWidth maxWidth="sm">
                <DialogTitle>
                  <h2 className="card-title text-center text-lg font-semibold">
                    Terms and Conditions
                  </h2>
                </DialogTitle>
                <DialogContent>
                  <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <Link
                          to={{
                            pathname: "/careersRegister",
                          }}
                          state={{
                            subscriptionCost: "236",
                            subscriptionFor: "UI Developer",
                            subscriptionType: "Direct Intern",
                          }}
                          exact
                        >
                          <Button text="Apply" />
                          
                        </Link>
                      </div>
                      <div>
                        <Button text="Cancel" navigation="/directInternMain" />
                        
                      </div>
                    </div>
                  </div>
                  <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                    <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                      <b>{internDirect.works}</b>
                    </p>
                    <ul className="list-disc ml-2 md:ml-4">
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">Eligibility:</span>{" "}
                        {internDirect.eligible}
                      </li>
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Stipend Offered after finishing the below levels:
                        </span>{" "}
                        {internDirect.stipend}
                      </li>

                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Complete Online Assessment:
                        </span>{" "}
                        <ul className="list-disc ml-4">
                          <li>
                            {signUpLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>SignUp using this link:</span>{" "}
                                <a
                                  href={signUpLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signup
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                You will receive a confirmation link in your
                                email. Click Confirm to subscribe.
                              </span>
                            </span>
                          </li>
                          <li>
                            {signInLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Once click Confirm SignIn using this link with
                                  the registered email:
                                </span>{" "}
                                <a
                                  href={signInLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signin
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            {assessmentLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Go to this Assessment link and take the online
                                  test:
                                </span>{" "}
                                <a
                                  href={assessmentLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Assessment
                                </a>
                                <ul>
                                  <b>
                                    Note: Take DS Programming, Frontend and
                                    Softskills Test
                                  </b>
                                </ul>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                Once the test is completed, click Submit and you
                                can see the result after two days.
                              </span>
                            </span>
                          </li>
                        </ul>
                      </li>

                      <li>
                        {reactLink && (
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Basic ReactJS Questions Resource Link to complete
                              the levels:
                            </span>{" "}
                            <a
                              href={reactLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", fontWeight: "bold" }}
                            >
                              {/* Text you want to display instead of the link */}
                              ReactJS Questions Link
                            </a>
                          </li>
                        )}
                      </li>

                      <li>
                        <p className="pages-content-text text-sm mb-2">
                          <b>{internDirect.level}</b>
                        </p>
                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Info Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          {/* List of Info Level points */}
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.infoLevel}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Dark Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkLevel}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkReact1}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkReact2}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Primary Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.primaryLevel1}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Paper>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>
        <br></br>
        {/* <div
          className={
            currentMode === "Dark"
              ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
              : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
          }
        > */}
         <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
          <div className="p-2 mt-5">
            <h3 className="font-semibold text-lg">
              <span style={{ color: currentColor }}>
                {jobContent.fullStack}
              </span>
            </h3>

            <p className="px-3 text-sm  bg-backgroundColor">
              {" "}
              Direct Intern for Candidates
            </p>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Complete three Levels
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Intern with Stipend per month
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Eligibility: BE/BTech or BCA/MCA/Msc
            </li>
           
            <div className="flex flex-row justify-between mt-3">
              <div className="flex gap-2">
                <div>
                  <p className="px-3 text-xs bg-backgroundColor">
                    {" "}
                    <span style={{ textDecorationStyle: "solid" }}>
                    <b>Application Fees: ₹236/- <span style={{fontSize:10}}>(incl. GST 18%)</span> </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-3">
              <div className="flex gap-2">
                <div>
                  <p className="px-3 text-sm bg-backgroundColor">
                    {" "}
                    <span style={{ textDecorationStyle: "solid" }}>
                      <b>Stipend: ₹5,000 </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Button text="Proceed" clickMethod={toggleFullStackDeveloper} />
              
            </div>

            {showFullStackDeveloper && (
              <Dialog open={showFullStackDeveloper} fullWidth maxWidth="sm">
                <DialogTitle>
                  <h2 className="card-title text-center text-lg font-semibold">
                    Terms and Conditions
                  </h2>
                </DialogTitle>
                <DialogContent>
                  <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <Link
                          to={{
                            pathname: "/careersRegister",
                          }}
                          state={{
                            subscriptionCost: "236",
                            subscriptionFor: "Fullstack Developer",
                            subscriptionType: "Direct Intern",
                          }}
                          exact
                        >
                           <Button text="Apply" />
                       
                         
                        </Link>
                      </div>
                      <div>
                      <Button text="Cancel" navigation="/directInternMain" />
                       
                       
                      </div>
                    </div>
                  </div>
                  <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                    <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                      <b>{internDirect.works}</b>
                    </p>
                    <ul className="list-disc ml-2 md:ml-4">
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">Eligibility:</span>{" "}
                        {internDirect.eligible}
                      </li>
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Stipend Offered after finishing the below levels:
                        </span>{" "}
                        {internDirect.stipend}
                      </li>

                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Complete Online Assessment:
                        </span>{" "}
                        <ul className="list-disc ml-4">
                          <li>
                            {signUpLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>SignUp using this link:</span>{" "}
                                <a
                                  href={signUpLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signup
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                You will receive a confirmation link in your
                                email. Click Confirm to subscribe.
                              </span>
                            </span>
                          </li>
                          <li>
                            {signInLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Once click Confirm SignIn using this link with
                                  the registered email:
                                </span>{" "}
                                <a
                                  href={signInLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signin
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            {assessmentLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Go to this Assessment link and take the online
                                  test:
                                </span>{" "}
                                <a
                                  href={assessmentLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Assessment
                                </a>
                                <ul>
                                  <b>
                                    Note: Take DS Programming, Frontend, Backend
                                    and Softskills Test
                                  </b>
                                </ul>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                Once the test is completed, click Submit and you
                                can see the result after two days.
                              </span>
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        {javaLink && (
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Basic Java Questions Resource Link to complete the
                              levels:
                            </span>{" "}
                            <a
                              href={javaLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", fontWeight: "bold" }}
                            >
                              {/* Text you want to display instead of the link */}
                              Java Questions Link
                            </a>
                          </li>
                        )}
                      </li>
                      <li>
                        {reactLink && (
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Basic ReactJS Questions Resource Link to complete
                              the levels:
                            </span>{" "}
                            <a
                              href={reactLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", fontWeight: "bold" }}
                            >
                              {/* Text you want to display instead of the link */}
                              ReactJS Questions Link
                            </a>
                          </li>
                        )}
                      </li>
                      <li>
                        <p className="pages-content-text text-sm mb-2">
                          <b>{internDirect.level}</b>
                        </p>
                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Info Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          {/* List of Info Level points */}
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.infoLevel}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Dark Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkLevel}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkFull1}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkFull2}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Primary Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.primaryLevel3}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Paper>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>
        <br></br>

        {/* <div
          className={
            currentMode === "Dark"
              ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
              : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
          }
        > */}
         <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
          <div className="p-2 mt-5">
            <h3 className="font-semibold text-lg">
              <span style={{ color: currentColor }}>{jobContent.backend}</span>
            </h3>

            <p className="px-3 text-sm  bg-backgroundColor">
              {" "}
              Direct Intern for Candidates
            </p>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Complete three Levels
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Intern with Stipend per month
            </li>
            <li className="px-2 text-sm  bg-backgroundColor">
              {" "}
              Eligibility: BE/BTech or BCA/MCA/Msc
            </li>
            <div className="flex flex-row justify-between mt-3">
              <div className="flex gap-2">
                <div>
                  <p className="px-3 text-xs bg-backgroundColor">
                    {" "}
                    <span style={{ textDecorationStyle: "solid" }}>
                    <b>Application Fees: ₹236/- <span style={{fontSize:10}}>(incl. GST 18%)</span> </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-3">
              <div className="flex gap-2">
                <div>
                  <p className="px-3 text-sm bg-backgroundColor">
                    {" "}
                    <span style={{ textDecorationStyle: "solid" }}>
                      <b>Stipend: ₹5,000 </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Button text="Proceed" clickMethod={toggleBackendDeveloper} />
              
            </div>

            {showBackendDeveloper && (
              <Dialog open={showBackendDeveloper} fullWidth maxWidth="sm">
                <DialogTitle>
                  <h2 className="card-title text-center text-lg font-semibold">
                    Terms and Conditions
                  </h2>
                </DialogTitle>
                <DialogContent>
                  <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <Link
                          to={{
                            pathname: "/careersRegister",
                          }}
                          state={{
                            subscriptionCost: "236",
                            subscriptionFor: "Backend Developer",
                            subscriptionType: "Direct Intern",
                          }}
                          exact
                        >
                           <Button text="Apply" />
                       
                          
                        </Link>
                      </div>
                      <div>
                      <Button text="Cancel" navigation="/directInternMain" />
                       
                       
                      </div>
                    </div>
                  </div>
                  <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                    <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                      <b>{internDirect.works}</b>
                    </p>
                    <ul className="list-disc ml-2 md:ml-4">
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">Eligibility:</span>{" "}
                        {internDirect.eligible}
                      </li>
                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Stipend Offered after finishing the below levels:
                        </span>{" "}
                        {internDirect.stipend}
                      </li>

                      <li className="pages-content-text text-sm mb-2">
                        <span className="font-bold">
                          Complete Online Assessment:
                        </span>{" "}
                        <ul className="list-disc ml-4">
                          <li>
                            {signUpLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>SignUp using this link:</span>{" "}
                                <a
                                  href={signUpLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signup
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                You will receive a confirmation link in your
                                email. Click Confirm to subscribe.
                              </span>
                            </span>
                          </li>
                          <li>
                            {signInLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Once click Confirm SignIn using this link with
                                  the registered email:
                                </span>{" "}
                                <a
                                  href={signInLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Signin
                                </a>
                              </span>
                            )}
                          </li>
                          <li>
                            {assessmentLink && (
                              <span className="pages-content-text text-sm mb-2">
                                <span>
                                  Go to this Assessment link and take the online
                                  test:
                                </span>{" "}
                                <a
                                  href={assessmentLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {/* Text you want to display instead of the link */}
                                  Assessment
                                </a>
                                <ul>
                                  <b>
                                    Note: Take DS Programming, Backend and
                                    Softskills Test
                                  </b>
                                </ul>
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="pages-content-text text-sm mb-2">
                              <span>
                                Once the test is completed, click Submit and you
                                can see the result after two days.
                              </span>
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        {javaLink && (
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Basic Java Questions Resource Link to complete the
                              levels:
                            </span>{" "}
                            <a
                              href={javaLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", fontWeight: "bold" }}
                            >
                              {/* Text you want to display instead of the link */}
                              Java Questions
                            </a>
                          </li>
                        )}
                      </li>
                      <li>
                        <p className="pages-content-text text-sm mb-2">
                          <b>{internDirect.level}</b>
                        </p>
                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Info Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          {/* List of Info Level points */}
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.infoLevel}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Dark Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkLevel}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkJava1}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.darkJava2}
                          </li>
                        </ul>

                        <p className="pages-content-text text-sm mb-2">
                          <b>
                            <span className="font-bold">Primary Level:</span>{" "}
                          </b>
                        </p>
                        <ul className="list-disc ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            {internDirect.primaryLevel2}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Paper>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>
      </div>
      <div
        className="mt-5 md:ml-36 ml-4 "
        
      >
        <Button text="Cancel" navigation="/directInternMain"/>
       
      </div>
    </>
  );
};

export default DirectIntern;
