import React, { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { generateInvoice } from "../../../../../api/commonApi";
import EditInvoiceDetail from "./EditInvoiceDetail";
import UpdatePaymentStatusDetail from "./UpdatePaymentStatusDetail";
import { useNavigate, Link } from "react-router-dom";
import PaymentPopup from "./PaymentPopup";

const ActionTrainingDetail = ({ subscription }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [actionType, setActionType] = useState("");
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const updateActionType = (event) => {
    setActionType(event.target.value);
  };
  const actionUpdate = (event) => {
    event.preventDefault();

    if (actionType === "pay") {
      // alert("inside");
      setShowModal(false);
      setShowLoading(false);
    }
  };
  const handleConfirmClick = async () => {
    if(actionType === "pay") {
    try {
      setShowModal(false);
      await new Promise((resolve) => setTimeout(resolve, 100));
      navigate("/paymentGateway", {
        state: {
          subscription: subscription,
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  }
  else if(actionType === "qr") {
    try {
      setShowModal(false);
      await new Promise((resolve) => setTimeout(resolve, 100));
      navigate("/paymentQrCode", {
        state: {
          subscription: subscription,
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  }
  };
  return (
    <div>
      <button
        className="px-2 text-sm border-1 border-brightColor bg-backgroundColor hover:text-brightColor transition-all rounded-lg font-bold uppercase "
        type="button"
        onClick={() => setShowModal(true)}
      >
        {" "}
        <span style={{ color: currentColor }}>
          <IoCloudDownload />
        </span>{" "}
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto  mx-auto max-w-2xl">
              <button
                className="p-1 ml-auto bg-white border-0 text-red rounded-lg  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-white text-red  h-6 w-6 text-2xl ">
                  <IoClose />
                </span>
              </button>
              <div className="border-0 px-3 mt-10 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t"></div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Choose your payment action
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <form onSubmit={handleConfirmClick}>
                    <div>
                      <div className="form-group">
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          name="option"
                          value={actionType}
                          onChange={(event) => updateActionType(event)}
                        >
                          <option value="" disabled>
                            ---Select---
                          </option>
                          <option value="pay">Pay via Gateway</option>
                          <option value="qr">Pay via QR Code</option>
                        </select>
                      </div>

                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150 mr-auto"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase float-end px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (
        <>
          {/* {actionType === "pay" ? (
          <PaymentPopup subscription={subscription} />
        ) : null} */}
        </>
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default ActionTrainingDetail;
