import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { AiOutlineArrowRight, AiOutlineBell } from "react-icons/ai";
import { StateContext } from "../../contexts/StateContext";
import Mobile_friendly from "../../assets/images/services/Mobile_friendly.png";
import coder from "../../assets/images/services/coder.png";
import secure from "../../assets/images/services/secure.png";
import web from "../../assets/images/services/web.png";
import Contract from "./policy/Contract";
const Services = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode,
  );
  return (
    <div>
      <section className={currentMode === 'Dark' ? 'rounded-3xl  bg-dark-gradient-r' : 'rounded-3xl  bg-light-gradient-r'}>
        <h1
          className="text-2xl flex justify-center font-bold mb-4"
          style={{ color: currentColor }}
        >
          Our Services
        </h1>
        <h1 className="text-base text-center mb-12 font-semibold">
          We offer development services as solutions.
        </h1>
        <marquee
          width="100%"
          direction="left"
          height="40px"
          className="text-dark"
        >
          <h3 className="text-xl font-bold " style={{ color: currentColor }}>
            {" "}
            Tech Stack: FlexPLM, Java-Spring boot MCs, Javascript-ReactJs, Mainframe to Spring-boot, IAM, SailPoint, KeyCloak, Camunda BPMN and Testing.
          </h3>
        </marquee>

        <div className=" flex items-center justify-center">
          <div className="container flex sm:flex sm:-mx-5 p-3">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  gap-2">
              <div className={currentMode === 'Dark' ? 'p-1  bg-dark-gradient-r' : 'p-1 bg-light-gradient-r'}>
                <div className="px-1 py-1">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ color: currentColor }}
                  >
                    Mobile Friendly Webapp
                  </div>
                  <div className=" h-20 w-20 ">
                    <img
                      src={Mobile_friendly}
                      alt=""
                      className="flex justify-center items-center"
                    />
                  </div>
                  <p className=" mt-2 text-base">
                    Start your mobile friendly free web application for any kind
                    of business with secured user subscription management
                    dashboard..
                  </p>
                </div>
                <div className="px-1 py-1">
                  <Link to="/mobileFriendly">
                    <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle  text-xs font-bold uppercase  shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                      Get Your free Webapp
                    </button>
                  </Link>
                </div>
                <div className="px-1 py-1">
                  <Link to="/contract">
                    <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle  text-xs font-bold uppercase  shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                      <Contract></Contract>
                    </button>
                  </Link>
                </div>
              </div>
              <div className={currentMode === 'Dark' ? 'p-1  bg-dark-gradient-r' : 'p-1 bg-light-gradient-r'}>
                <div className="px-1 py-1">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ color: currentColor }}
                  >
                    Hire a coder
                  </div>
                  <div className=" h-20 w-20 ">
                    <img
                      src={coder}
                      alt=""
                      className="flex justify-center items-center"
                    />
                  </div>
                  <p className="mt-2 text-base">
                    Customize your dream team from our cherry picked developers
                    pool.Skilled immediate joiners are available in flexible
                    contract.
                  </p>
                </div>
                <div className="px-1 py-1">
                  <Link to="/hireCoderRegister">
                    <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                      Hire a coder
                    </button>
                  </Link>
                </div>
              </div>
              <div className={currentMode === 'Dark' ? 'p-1  bg-dark-gradient-r' : 'p-1 bg-light-gradient-r'}>
                <div className="px-1 py-3">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ color: currentColor }}
                  >
                    {" "}
                    Get your secured ODC
                  </div>
                  <div className=" h-20 w-20 ">
                    <img src={secure} alt="" className="flex " />
                  </div>

                  <p className=" text-base">
                    We have secured odc to solve your business problems.We also
                    undertake projects of flex plm PTC development, android, ios
                    mobile apps and BPMN process automation micro services.
                  </p>
                </div>
                <div className="px-1 py-1">
                  <Link to="/contact">
                    <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle text-xs font-bold uppercase  shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                      Get secured ODC
                    </button>
                  </Link>
                </div>
              </div>
              <div className={currentMode === 'Dark' ? 'p-1  bg-dark-gradient-r' : 'p-1 bg-light-gradient-r'}>
                <div className="px-1 py-1">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ color: currentColor }}
                  >
                    SOW based flexible contract
                  </div>
                  <div className=" h-20 w-20 ">
                    <img
                      src={web}
                      alt=""
                      className="flex justify-center items-center"
                    />
                  </div>
                  <p className="mt-2 text-base">
                    We do offer free poc/mvp to prove the solution before
                    undertake sow/tnm contracts. More than staffing we do
                    product as service for various business problems.
                  </p>
                </div>
                <div className="px-1 py-1">
                  <Link to="/contact">
                    <button
                      className="select-none rounded-lg py-3 px-6 text-center align-middle text-xs font-bold uppercase  shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      data-ripple-light="true"
                      style={{ color: currentColor }}
                    >
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Services;
