import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../contexts/StateContext';
import { useNavigate } from 'react-router-dom';
import {
  ACCESS_TOKEN, ADMIN_TOKEN, HR_TOKEN, EMPLOYEE_TOKEN, CLIENT_TOKEN, FINANCIER_TOKEN, ORGDATALIST, PUBLICHOLIDAYLIST, DEVELOPERLIST,
} from '../config/Config';

function Button({
  icon, bgColor, color, bgHoverColor, size, text, borderRadius, width, buttonClickFor,
}) {
  const navigate = useNavigate();
  const setIsClicked = useContextSelector(StateContext, (state) => state.setIsClicked);
  const initialState = useContextSelector(StateContext, (state) => state.initialState);
  const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
  const setIsLoggedIn = useContextSelector(StateContext, (state) => state.setIsLoggedIn);
  const role = useContextSelector(
    StateContext,
    (state) => state.role,
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole,
  );
  const setRoleLogin = useContextSelector(
    StateContext,
    (state) => state.setRoleLogin,
  );
  const [stateLog, setStateLog] = useState('');
  const handleClickBack = () => {
    navigate('/');
  };
  function logOut() {
    //alert('inside logout line 1');
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    localStorage.removeItem(HR_TOKEN);
    localStorage.removeItem(EMPLOYEE_TOKEN);
    localStorage.removeItem(CLIENT_TOKEN);
    localStorage.removeItem(FINANCIER_TOKEN);
    localStorage.removeItem(ORGDATALIST);
    localStorage.removeItem(PUBLICHOLIDAYLIST);
    localStorage.removeItem(DEVELOPERLIST);
    //setRole("desg");
    //alert('inside logout');
    setIsLoggedIn(false);
    navigate('/');
  
    // this.showAlert("Your are no longer logged in !", "success");
    // this.props.history.push("/");
  }
  return (
    <button
      type="button"
      onClick={() => {
        if (buttonClickFor !== undefined && buttonClickFor !== null && buttonClickFor === 'logout') {
          logOut();
          return(
            <Navigate to='/home' replace />
          );
        }
        setIsClicked(initialState);
      }}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon}
      {' '}
      {text}
    </button>
  );
}

export default Button;
