import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useContextSelector } from "use-context-selector";
import { Cart, Chat, Notification, UserProfile } from ".";
import { StateContext } from "../contexts/StateContext";
import { retrieveProfileImage } from "../api/EmployeeApi";
import { avatar } from "../assets/images";
import Navigation from "./Navigation";

function NavButton({ title, customFunc, icon, color, dotColor }) {
  return (
    <TooltipComponent content={title} position="BottomCenter" className="flex items-center">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span style={{ background: dotColor }} className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );
}

function Navbar() {
  const {
    activeMenu,
    currentMode,
    currentColor,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
    isLoggedIn,
  } = useContextSelector(StateContext, state => ({
    activeMenu: state.activeMenu,
    currentMode: state.currentMode,
    currentColor: state.currentColor,
    setActiveMenu: state.setActiveMenu,
    handleClick: state.handleClick,
    isClicked: state.isClicked,
    setScreenSize: state.setScreenSize,
    screenSize: state.screenSize,
    isLoggedIn: state.isLoggedIn,
  }));

  const [showLoading, setShowLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const retrieveProfilePic = async () => {
    setShowLoading(true);
    try {
      const res = await retrieveProfileImage();
      setImagePreviewUrl(res.data);
    } catch (error) {
      // Handle error (optional logging or alert)
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);
    if (isLoggedIn) {
      retrieveProfilePic();
    }
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isLoggedIn, setScreenSize]);

  useEffect(() => {
    setActiveMenu(screenSize > 900);
  }, [screenSize, setActiveMenu]);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className="bg-[#f5f5f5]">
        <div className="flex p-2 justify-between md:justify-normal relative">
          <div className="mt-6">
            {/* Optional NavButton for menu */}
          </div>
          {isLoggedIn ? (
            <div className="flex">
              <Navigation />
              <TooltipComponent content="Profile" position="BottomCenter" className="flex items-center">
                <div
                  className="items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                  onClick={() => handleClick("userProfile")}
                >
                  <img
                    src={imagePreviewUrl ? `data:image/jpeg;base64,${imagePreviewUrl}` : avatar}
                    alt="avatar"
                    className="rounded-full h-8 w-8"
                  />
                </div>
              </TooltipComponent>
              {isClicked.cart && <Cart />}
              {isClicked.chat && <Chat />}
              {isClicked.notification && <Notification />}
              {isClicked.userProfile && <UserProfile />}
            </div>
          ) : (
            <Navigation />
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
