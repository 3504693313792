import React, { Component } from 'react';
import { StateContext } from '../../../../src/contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import {privacyInfoEn} from '../../../data/content/En/PrivacyInfoEn';
import {privacyInfoDe} from '../../../data/content/De/PrivacyInfoDe';
import { Language } from '../../../config/Config';


  const Privacy = () => {
    const currentColor = useContextSelector(
        StateContext,
        (state) => state.currentColor,
      );
  
    return (
      <div className="container">
        <div className="main contact-margin-top" id="contact">
          <br></br>
         <div className="contact-div-main">
            <div className="contact-header ml-4 md:ml-10">
              
              {/* <p className="pages-start-text  text-2xl" >
                {Language==='en'? privacyInfoEn.main:privacyInfoDe.main}
                
              </p> */}
              <br></br>
              <h1 className="section-heading text-3xl font-bold uppercase"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.title:privacyInfoDe.title}</strong></h1>
             <br></br>
              <h1 className="pages-subhead-text text-2xl" >
                
                <strong>
                 
                  {Language==='en'? privacyInfoEn.question:privacyInfoDe.question}
                </strong>
              </h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
                {Language==='en'? privacyInfoEn.yaazhtechPrivacy:privacyInfoDe.yaazhtechPrivacy}
                
              </p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.policy:privacyInfoDe.policy}</p><br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10 ">{Language==='en'? privacyInfoEn.personalData:privacyInfoDe.personalData}</p><br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.policyInformation:privacyInfoDe.policyInformation}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.policy:privacyInfoDe.policy}</strong></p><br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.recipient:privacyInfoDe.recipient}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.userServices:privacyInfoDe.userServices}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.potentialCustomers:privacyInfoDe.potentialCustomers}</li>
              </p>
              <br></br>
              <h1 className="pages-subhead-text text-2xl font-bold uppercase"style={{color:currentColor}} >
                <strong>
                  {' '}
                  {Language==='en'? privacyInfoEn.visitors:privacyInfoDe.visitors}
                </strong>
              </h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10 "><strong>{Language==='en'? privacyInfoEn.definitions:privacyInfoDe.definitions}</strong></p>
             
             <br></br> 
             <h1 className="pages-subhead-text text-lg ml-4 md:ml-10 "style={{color:currentColor}}  >
                <strong>
                  {' '}
                  {Language==='en'? privacyInfoEn.applicableLaw:privacyInfoDe.applicableLaw}
                </strong>
                {' '}
              </h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>
                {' '}
                {Language==='en'? privacyInfoEn.role:privacyInfoDe.role}</strong>
              </p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10 ">
                {Language==='en'? privacyInfoEn.policyCovers:privacyInfoDe.policyCovers}
                {' '}
              </p>
              <br></br>
              
  <h1 className="pages-content-text text-lg ml-4 md:ml-10 "style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.platform:privacyInfoDe.platform}</strong></h1>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.processingPersonalData:privacyInfoDe.processingPersonalData}</strong></p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.demonstrate:privacyInfoDe.demonstrate}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.storage:privacyInfoDe.storage}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.necessaryData:privacyInfoDe.necessaryData}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.deleteData:privacyInfoDe.deleteData}</strong></p>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.processing:privacyInfoDe.processing}</strong></p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.dataProcessing:privacyInfoDe.dataProcessing}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
              <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4  ">

                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.typesOfData:privacyInfoDe.typesOfData}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.contractDetails:privacyInfoDe.contractDetails}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.improveService:privacyInfoDe.improveService}</li>
                </ul>
              </p>
              <br></br>
              
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.customerAnalysis:privacyInfoDe.customerAnalysis}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.accessData:privacyInfoDe.accessData}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
                <li className="pages-content-text text-lg ml-4 md:ml-10">
                  {' '}
                  {Language==='en'? privacyInfoEn.collectData:privacyInfoDe.collectData}
                </li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.provideData:privacyInfoDe.provideData}</li>
              </p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.thirdParty:privacyInfoDe.thirdParty}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn. lawfulBasis:privacyInfoDe. lawfulBasis}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.legalBasis:privacyInfoDe.legalBasis}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.performanceContract:privacyInfoDe.performanceContract}</p>
              <br></br>
              <h1 className="pages-subhead-text text-lg ml-4 md:ml-10"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.legitimateInterest:privacyInfoDe.legitimateInterest}</strong></h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.yourRight:privacyInfoDe.yourRight}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.exerciseRight:privacyInfoDe.exerciseRight}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.access:privacyInfoDe.access}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.rectificaton:privacyInfoDe.rectificaton}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.erasure:privacyInfoDe.erasure}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.objections:privacyInfoDe.objections}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
              <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4  ">

                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.restriction:privacyInfoDe.restriction}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.processingRequest:privacyInfoDe.processingRequest}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.limit:privacyInfoDe.limit}</li>
                </ul>
              </p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.legalClaim:privacyInfoDe.legalClaim}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.dataPortability:privacyInfoDe.dataPortability}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.withdrawConsent:privacyInfoDe.withdrawConsent}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.howToUseRight:privacyInfoDe.howToUseRight}</p>
              <br></br>
              <h1 className="pages-content-text text-lg ml-4 md:ml-10 "style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.reachOut:privacyInfoDe.reachOut}</strong></h1>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10"><strong>{Language==='en'? privacyInfoEn.transferData:privacyInfoDe.transferData}</strong></p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.runBusiness:privacyInfoDe.runBusiness}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">
              <ul className=" list-disc ml-8 md:ml-12 text-lg space-y-4 p-4  ">

                
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.outsideData:privacyInfoDe.outsideData}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.euCommission:privacyInfoDe.euCommission}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.signedUp:privacyInfoDe.signedUp}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn. safegaurds:privacyInfoDe. safegaurds}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.dataAgreements:privacyInfoDe.dataAgreements}</li>
                <li className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.obligation:privacyInfoDe.obligation}</li>
             </ul>
              </p>
              
              <br></br>
              <h1 className="pages-subhead-text text-2xl font-bold uppercase"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.ourProcessors:privacyInfoDe.ourProcessors}</strong></h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.thirdpartPersonalData:privacyInfoDe.thirdpartPersonalData}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.advertising:privacyInfoDe.advertising}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.itSuppliers:privacyInfoDe.itSuppliers}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.statistics:privacyInfoDe.statistics}</p>
              <br></br>
              <h1 className="pages-subhead-text text-2xl font-bold uppercase"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.security:privacyInfoDe.security}</strong></h1>
             <br></br>
             <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.organistional:privacyInfoDe.organistional}</p>
             <br></br>
             <h1 className="pages-content-text text-lg ml-4 md:ml-10"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.securityMeasures:privacyInfoDe.securityMeasures}</strong></h1>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.workMethod:privacyInfoDe.workMethod}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.governance:privacyInfoDe.governance}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.passwordManagement:privacyInfoDe.passwordManagement}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.physicalSercutiy:privacyInfoDe.physicalSercutiy}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.technicalSecurity:privacyInfoDe.technicalSecurity}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.encryption:privacyInfoDe.encryption}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.accessLog:privacyInfoDe.accessLog}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.secureNetwork:privacyInfoDe.secureNetwork}</p>
              <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.vpn:privacyInfoDe.vpn}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.backUp:privacyInfoDe.backUp}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.regularSecurity:privacyInfoDe.regularSecurity}</p>
             <br></br>
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.twoStep:privacyInfoDe.twoStep}</p>
             <br></br> 
              <h1 className="pages-content-text text-lg ml-4 md:ml-10"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.cookies:privacyInfoDe.cookies}</strong></h1>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.cookieUse:privacyInfoDe.cookieUse}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.promise:privacyInfoDe.promise}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.notified:privacyInfoDe.notified}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.rightFound:privacyInfoDe.rightFound}</p>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn. authority:privacyInfoDe. authority}</p>
             <br></br> 
              <h1 className="pages-content-text text-lg ml-4 md:ml-10"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.policyChange:privacyInfoDe.policyChange}</strong></h1>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.rightChange:privacyInfoDe.rightChange}</p>
             <br></br>
              <h1 className="pages-subhead-text text-2xl font-bold uppercase"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.contact:privacyInfoDe.contact}</strong></h1>
             <br></br> 
              <p className="pages-content-text text-lg ml-4 md:ml-10">{Language==='en'? privacyInfoEn.contactQuestion:privacyInfoDe.contactQuestion}</p>
             <br></br> 
              <h1 className="pages-content-text text-lg ml-4 md:ml-10"style={{color:currentColor}} ><strong>{Language==='en'? privacyInfoEn.email:privacyInfoDe.email}</strong></h1>
             <br></br> 
            </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossOrigin="anonymous"
        />

      </div>

    );
  }


export default Privacy;
