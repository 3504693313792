import React from 'react';
import './SplashScreen.css';
import DisplayLottie from './displayLottie/DisplayLottie';
import { greeting, splashScreenContent } from '../../data/content/PageContent';

export default function SplashScreen() {
  return (
    <div className="splash-container">
      <div className="splash-animation-container">
        <DisplayLottie animationData={splashScreenContent.animation} />
      </div>
      <div className="splash-title-container">
        {/*<span className="splash-title">{greeting.username}</span>*/}
        <span className="splash-title text-center">{greeting.username}</span>
        <h6 className="splash-title text-sm text-center">"{greeting.quotation}"</h6>
      </div>
    </div>
  );
}
