const TrainingForExperiencedMainDe={
    heading:"Stärken Sie Ihre Karriere mit Yaazhtechs Schulung für erfahrene Fachkräfte",
    headingExplain1:"Sind Sie ein erfahrener Profi und möchten Ihre Fähigkeiten erweitern, neue Karrierewege erkunden oder einfach der Konkurrenz einen Schritt voraus sein? Das innovative Programm „Schulung für erfahrene Fachkräfte“ von Yaazhtech soll Ihnen dabei helfen, genau das zu erreichen!",
    headingExplain2:"Auf dem dynamischen Arbeitsmarkt von heute sind funktionsübergreifende Fähigkeiten und die Weiterbildung der Mitarbeiter wichtiger denn je. Unser Programm stattet Sie mit dem Wissen und den Werkzeugen aus, die Sie benötigen, um sich in einer kompetenzübergreifenden Umgebung hervorzuheben, und ermöglicht Ihnen Folgendes:",
    
    broaden:"Lernen Sie neue Technologien, Methoden und Best Practices der Branche kennen, die für Ihren Bereich und darüber hinaus relevant sind.",
    increase:"Werten Sie Ihren Lebenslauf auf und heben Sie sich mit gefragten Fähigkeiten von der Konkurrenz ab.",
    drive:"Entdecken Sie neue Karrieremöglichkeiten in Ihrem aktuellen Bereich oder wechseln Sie reibungslos in einen anderen Bereich.",

    applyButton:"Hier bewerben",
    
    program:"Unsere Programmstruktur: ",
    programExplain:"Das Yaazhtech-Trainingsprogramm für erfahrene Fachkräfte bietet einen strukturierten und dennoch flexiblen Lernansatz:",
    self:"Greifen Sie auf eine umfassende Bibliothek mit Online-Kursen, Tutorials und Ressourcen zu, die Sie nach Belieben erkunden können.",
    mentor:"Erhalten Sie während Ihrer gesamten Lernreise Unterstützung und persönliche Beratung von unseren erfahrenen Dozenten.",
    real:"Wenden Sie Ihr neu gewonnenes Wissen in praktischen Übungen und Projekten an, die realitätsnahe Szenarien simulieren.",
    
    benefits:"Vorteile unseres Programms",
    upskilling:"Beherrschen Sie die neuesten Branchentrends und Technologien, um relevant und wettbewerbsfähig zu bleiben.",
    cross:"Entdecken Sie neue Fachgebiete und erweitern Sie Ihren beruflichen Horizont.",
    career:"Erwerben Sie die Fähigkeiten und das Selbstvertrauen, die Sie benötigen, um Führungsrollen zu übernehmen oder in andere Bereiche zu wechseln.",
    
    who:"Wer sollte sich bewerben??",
    explain:"Dieses Programm ist ideal für erfahrene Fachkräfte:",
    o1:"Sie möchten sich in ihrem aktuellen Bereich weiterbilden",
    o2:"Interessiert an Cross-Skilling, um neue Karrierewege zu erkunden.",
    o3:"Sie möchten ihre Beschäftigungs- und Marktfähigkeit verbessern.", 
    
    take:"Übernehmen Sie noch heute die Verantwortung für Ihre Zukunft!",
    takeExplain:"Sind Sie bereit, Ihr volles Potenzial auszuschöpfen und Ihre Karriere voranzutreiben? Melden Sie sich jetzt für das Training for Experienced Professionals-Programm von Yaazhtech an!",
    
    haveQuestion:"Habe Fragen?",
    contact:"Kontaktieren Sie unser Team für eine kostenlose Beratung, um Ihre Karriereziele zu besprechen und wie unser Programm Ihnen dabei helfen kann, diese zu erreichen. Wir helfen Ihnen dabei, den richtigen Lernweg zu finden, um Ihre Fähigkeiten und Ihre Karriere auf die nächste Stufe zu heben.",
    
    }
   export {TrainingForExperiencedMainDe};