import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { Link, useLocation, NavLink } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { getClients } from "../../../../../api/AdminApi";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { retrieveClientUser } from "../../../../../api/FinanceApi";
import toast, { Toaster } from "react-hot-toast";
import { generateInvoice } from "../../../../../api/commonApi";
import { Button } from "../../../../../components";
import ActionViewClient from "./ActionViewClient";
import { useNavigate } from "react-router-dom";

function ViewTransaction(props) {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  
  const location = useLocation();
  const { clientUserName } = location.state;
  const toolbarOptions = ["Search", "PdfExport"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [clientDetail, setClientDetail] = useState({
    clientName: "",
    clientEmailId: "",
    clientContactNo: "",
    clientStatus: "",
    clientRole: "",
  });
  const [data, setData] = useState([]);
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let applicantsList = [];
  useEffect(() => {
    document.title = "Invoice management";
    loadData();
  }, []);
  const loadData = () => {
    retrieveClientUser(clientUserName)
      .then((res) => {
        const subscriptionList = res.data.subscription;
        let req = clientDetail;
        req.clientName = res.data.name;
        req.clientEmailId = res.data.email;
        req.clientContactNo = res.data.mobile;
        req.clientStatus = res.data.status;
        req.clientRole = res.data.role;
        setClientDetail(req);
        const constructPaymentDataList = [];
        const { name } = res.data;
        const userName = res.data.email;
        if (
          subscriptionList !== undefined &&
          subscriptionList !== null &&
          subscriptionList.length > 0
        ) {
          for (let i = 0; i < res.data.subscription.length; i++) {
            const payment = subscriptionList[i].paymentList; 
            if (
              payment !== undefined &&
              payment !== null &&
              payment.length > 0
            ) {
              for (let j = 0; j < payment.length; j++) {
                
              const transaction = payment[j].transactionDetailsList;
             if (
              transaction !== undefined &&
              transaction !== null &&
              transaction.length > 0
            ) {
              for (let k = 0; k < transaction.length; k++) {
                  const transactionDetails={
                    transactionStatus:"",
                    transactionAmount:"",
                    transactionDate:"",
                    modeOfTransaction:"",
                  }
                  transactionDetails.transactionStatus=transaction[k].transactionStatus;
                  transactionDetails.transactionAmount=transaction[k].transactionAmount;
                  transactionDetails.transactionDate=transaction[k].transactionDate;
                  transactionDetails.modeOfTransaction=transaction[k].modeOfTransaction
                  constructPaymentDataList.push(transactionDetails);
              }}
              }
              constructPaymentDataList.sort((a, b) =>
                a.dueDate > b.dueDate ? 1 : b.dueDate > a.dueDate ? -1 : 0
              );
            } else {
              setLoading(false);
            }
          }
          setData(constructPaymentDataList);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
        }
        setLoading(false);
        console.log(error);
      });
  };
  const handleClickBack = () => {
    navigate("/clients");
  };
  const actionTemplate = (props) => {
    const paymentData = {
      projectRequirement: props.projectRequirement,
      dueDate: props.dueDate,
      paymentBillingFrom: props.paymentBillingFrom,
      paymentBillingTo: props.paymentBillingTo,
      totalBillingDay: props.totalBillingDay,
      totalBillingHours: props.totalBillingHours,
      costPerDay: props.subscriptionCost,
      subscriptionType: props.subscriptionType,
      subscriptionFor: props.subscriptionFor,
      costExcludingGstPay: props.costExcludingGstPay,
      costIncludingGstPay: props.costIncludingGstPay,
      costIncludingGstPayMinusTDS:
        props.costIncludingGstPay - props.costIncludingGstPay / 10,
      paymentStatus: props.paymentStatus,
      subscriptionId: props.subscriptionId,
      paymentId: props.paymentId,
      userName: props.userName,
      subscriptionId: props.subscriptionId,
      subscriptionName: props.subscriptionName,
      subscriptionCost: props.subscriptionCost,
      employeeEmailIdList: props.employeeEmailIdList,
    };

    return <ActionViewClient subscription={paymentData} />;
  };
  

  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      }
    >
      <div className="p-2 m-2">
        <h2 className="p-1 md:text-2xl float-left">View Clients Page</h2>
        <button
          className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          style={{ backgroundColor: currentColor }}
          onClick={() => handleClickBack()}
        >
          <span>back</span>
        </button>
      </div>

      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
       
        allowFiltering={false}
        allowGrouping={true}
       
        allowSorting
      >
        <ColumnsDirective>
          <ColumnDirective
            field="transactionStatus"
            headerText="Status"
            width="100"
            textAlign="Center"
          />
           <ColumnDirective
            field="transactionAmount"
            headerText="Transaction Amount"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="transactionDate"
            headerText="Transaction Date"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="modeOfTransaction"
            headerText="Mode Of Transaction"
            width="100"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>
      <div>
        <p>
          <h3>
            Client Info{" "}
            <span className="text-muted" style={{ fontSize: 11 }}>
              details
            </span>
          </h3>
          <div>
            <table className="table table-hover">
              <thead>
                <h1>
                  <span className="text-muted" style={{ fontSize: 17 }}>
                    {" "}
                    Data
                  </span>
                </h1>
              </thead>
              <tbody>
                <tr scope="row">
                  <th> Client Name</th>
                  <th> {clientDetail.clientName}</th>{" "}
                </tr>
                <tr scope="row">
                  <th> Client Email Id</th>
                  <th>{clientDetail.clientEmailId} </th>
                </tr>

                <tr scope="row">
                  <th> Client Contact No</th>
                  <th> {clientDetail.clientContactNo}</th>{" "}
                </tr>
                <tr scope="row">
                  <th> Status </th>
                  <th> {clientDetail.clientStatus}</th>{" "}
                </tr>
                <tr scope="row">
                  <th> Role</th>
                  <th> {clientDetail.clientRole}</th>{" "}
                </tr>
              </tbody>
            </table>
          </div>
        </p>
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}

export default ViewTransaction;
