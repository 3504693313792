import { ACCESS_TOKEN, config, merchantId } from "../config/Config";
import axios from "axios";

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});
const gateWayInstance = axios.create({
  baseURL: "/hermes",
});





export function retrieveClientUser(clientUserName) {
  return instance.get("/secure/finance/userData/"+clientUserName, {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}
export function createManualSubscription(subscriptionRequest) {
  return instance.post("/secure/finance/addManualPayment", subscriptionRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
  
}
export function createProjectOrTaskWithSubscription(subscriptionWithProjectOrTask) {
  return instance.post("/secure/finance/createProjectOrTaskWithSubscription", subscriptionWithProjectOrTask,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
  
}

export function createTaskOrSubscription(subscriptionOrTask) {
  return instance.post("/secure/finance/createTaskOrSubscription", subscriptionOrTask,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
  
}


export function updatePaymentStatusBackend(invoiceStatusRequest) {
  return instance.post("/secure/finance/invoiceStatusUpdate", invoiceStatusRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}
//add
// Assume instance is your axios instance
export function getOverallTransactionData(category) {
  const url = `/secure/finance/overallTransactionData/${category}?page=0`;
  
  return instance.get(url, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
    },
  })
}



export function getUsersByCategory(category,searchText) {
 
  return instance.get(
    `/secure/admin/dashboard/users/${category}?text=${searchText}`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}