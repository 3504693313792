import React from 'react';
import LeadershipBanner from './LeadershipBanner';
import ExecutiveTeam from './ExecutiveTeam';
import BoardOfDirectors from './BoardOfDirectors';
import AboutSection from './AboutSection';

const LeaderShip = () => {
  return (
    <div>
      <LeadershipBanner />
      <BoardOfDirectors />
      <ExecutiveTeam />
      
      <AboutSection />
    </div>
  );
};

export default LeaderShip;
