import React, { useState } from "react";
import {
  jobContent,
  experienced,
  jobRequirements,
} from "../../data/content/PageContent";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import Button from "../../components/StaticButton/Button";

const Experienced = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const navigate = useNavigate();

  const [showUiDeveloper, setUiDeveloper] = useState(false);
  const [showFullStackDeveloper, setFullStackDeveloper] = React.useState(false);
  const [showBackendDeveloper, setBackendDeveloper] = React.useState(false);
  const [showMobileDeveloper, setMobileDeveloper] = React.useState(false);
  const [showCamundaDeveloper, setCamundaDeveloper] = React.useState(false);
  const [showDevopsDeveloper, setDevopsDeveloper] = React.useState(false);
  const [showDigitalMarketing, setDigitalMarketing] = React.useState(false);
  const [showPythonDeveloper, setPythonDeveloper] = React.useState(false);
  const [showGoLangDeveloper, setGoLangDeveloper] = React.useState(false);
  const [showHr, setHr] = React.useState(false);
  const [showApi, setApi] = React.useState(false);
  const [showSqlDeveloper, setSqlDeveloper] = React.useState(false);
  const [showItSupported, setShowItSupported]  = React.useState(false);

  const toggleUiDeveloper = () => {
    setUiDeveloper(!showUiDeveloper);
  };

  const toggleFullStackDeveloper = () => {
    setFullStackDeveloper(!showFullStackDeveloper);
  };

  const toggleBackendDeveloper = () => {
    setBackendDeveloper(!showBackendDeveloper);
  };

  const toggleMobileDeveloper = () => {
    setMobileDeveloper(!showMobileDeveloper);
  };

  const toggleCamundaDeveloper = () => {
    setCamundaDeveloper(!showCamundaDeveloper);
  };

  const toggleDevopsDeveloper = () => {
    setDevopsDeveloper(!showDevopsDeveloper);
  };

  const toggleDigitalMarketing = () => {
    setDigitalMarketing(!showDigitalMarketing);
  };

  const togglePythonDeveloper = () => {
    setPythonDeveloper(!showPythonDeveloper);
  };

  const toggleGoLangDeveloper = () => {
    setGoLangDeveloper(!showGoLangDeveloper);
  };

  const toggleHr = () => {
    setHr(!showHr);
  };

  const toggleApi = () => {
    setApi(!showApi);
  };

  const toggleSqlDeveloper = () => {
    setSqlDeveloper(!showSqlDeveloper);
  };
  const toggleItSupportEngineer = () => {
    setShowItSupported(!showItSupported);
  };

  return (
    <>
      <div>
        <div className="text-center">
          <div class="contact-section mt-2">
            <span class="contact-info text-xl font-bold uppercase mb-2">
              <p>
                <a
                  class="whatsapp-link"
                  href="https://wa.me/918072488209"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp
                    class="w-8 h-8 inline-block -mt-1 mr-2 "
                    style={{ background: "green", color: "white" }}
                  />
                  Whatsapp us for quick apply +91 8072488209
                </a>
              </p>
            </span>
          </div>
        </div>
        <br></br>
        <div className="p-2 mt-5">
          <div className="lg:flex lg:flex-row  justify-center  sm:flex sm:flex-col sm:items-center mr-14">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.uiDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Skilled UI Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleUiDeveloper}/>
                 
                </div>

                {showUiDeveloper && (
                  <Dialog open={showUiDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "UI Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.fullStack}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience FullStack Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleFullStackDeveloper} />
                  
                </div>

                {showFullStackDeveloper && (
                  <Dialog open={showFullStackDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "300",
                                subscriptionFor: "Full Stack Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>

            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.backend}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience BackEnd Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>
                <li className="px-2 text-sm bg-backgroundColor">
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleBackendDeveloper}/>
                  
                </div>

                {showBackendDeveloper && (
                  <Dialog open={showBackendDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "Backend Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col sm:items-center mr-14  justify-center">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.mobile}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Qualified Mobile Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleMobileDeveloper}/>
                 
                </div>

                {showMobileDeveloper && (
                  <Dialog open={showMobileDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor:
                                  "Mobile App Developer (Android/IOs)",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li>{experienced.fee}</li>
                              <li>Note: {experienced.note}</li>
                            </ul>
                          </li>

                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.camunda}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience Camunda Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleCamundaDeveloper} />
                  
                </div>

                {showCamundaDeveloper && (
                  <Dialog open={showCamundaDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "CamundaDeveloper",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>

            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.devops}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience DevOps Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleDevopsDeveloper}/>
                 
                </div>

                {showDevopsDeveloper && (
                  <Dialog open={showDevopsDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "AWS Cloud Dev Ops Engineer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col sm:items-center mr-14  justify-center">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.digitalMarketing}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Managing Digital Marketing
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleDigitalMarketing} />
                 
                </div>

                {showDigitalMarketing && (
                  <Dialog open={showDigitalMarketing} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "Digital Marketing Expert",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.pythonDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience Python Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={togglePythonDeveloper} />
                  
                </div>

                {showPythonDeveloper && (
                  <Dialog open={showPythonDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "Python Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>

            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.golangDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experienced GoLang Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleGoLangDeveloper} />
                 
                </div>

                {showGoLangDeveloper && (
                  <Dialog open={showGoLangDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "GoLang Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col sm:items-center mr-14  justify-center">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.hrManager}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Qualified HR Manager
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleHr} />
                  
                </div>

                {showHr && (
                  <Dialog open={showHr} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "HR Manager",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.apiTesting}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Experience API tester
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleApi} />
                  
                </div>

                {showApi && (
                  <Dialog open={showApi} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "API Test Engineer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>

            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.sqlDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Qualified SQL Developers
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleSqlDeveloper} />
                 
                </div>

                {showSqlDeveloper && (
                  <Dialog open={showSqlDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "SQL Developer",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col sm:items-center mr-14  justify-center">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-[#f5f5f5]">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.itSupportEngineer}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Qualified IT & Support Engineer
                </p>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Online Coding
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Technical on Fundamentals
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Deep dive in Technical Skills
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Hr Manager Round
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Document and offer process
                </li>

                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  <b>Application Fees: ₹0</b>
                </li>
                <div>
                  <Button text="Proceed" clickMethod={toggleItSupportEngineer} />
                  
                </div>

                {showItSupported && (
                  <Dialog open={showItSupported} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                      <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "40px",
                          }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "0",
                                subscriptionFor: "HR Manager",
                                subscriptionType: "Direct-hire",
                              }}
                              exact
                            >
                              <Button text="Apply" />
                            </Link>
                          </div>
                          <div>
                            <Button
                              text="Cancel"
                              navigation="/directHiringMain"
                            />
                          </div>
                        </div>
                      </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{experienced.process}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Online Coding Assessment:
                            </span>{" "}
                            {experienced.assessments}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Technical Evaluation on Fundamentals:
                            </span>{" "}
                            {experienced.technicalEvaluation}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Deep Dive in Technical Skills:
                            </span>{" "}
                            {experienced.deepDive}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">HR Manager Round:</span>{" "}
                            {experienced.hrRound}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Interview Application Fees:
                            </span>{" "}
                            <ul>
                              <li> {experienced.fee}</li>
                              <li>
                                {" "}
                                <b>Note:</b>
                                {experienced.note}
                              </li>
                            </ul>{" "}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Document and Offer Process:
                            </span>{" "}
                            {experienced.document}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:ml-32 ml-4">
        <Button text="Cancel" navigation="/directHiringMain" />
      </div>
    </>
  );
};

export default Experienced;
