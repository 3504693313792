import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../contexts/StateContext';
import {
  ACCESS_TOKEN, ADMIN_TOKEN, HR_TOKEN, EMPLOYEE_TOKEN, CLIENT_TOKEN, FINANCIER_TOKEN,
} from '../config/Config';

function ButtonForProfile({
  icon, bgColor, color, bgHoverColor, size, text, borderRadius, width, buttonClickFor,
}) {
  const setIsClicked = useContextSelector(StateContext, (state) => state.setIsClicked);
  const initialState = useContextSelector(StateContext, (state) => state.initialState);
  const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
  const setIsLoggedIn = useContextSelector(StateContext, (state) => state.setIsLoggedIn);
  const role = useContextSelector(
    StateContext,
    (state) => state.role,
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole,
  );
  const setRoleLogin = useContextSelector(
    StateContext,
    (state) => state.setRoleLogin,
  );
  const [stateLog, setStateLog] = useState('');
  
  function logOut() {
    //alert('inside logout line 1');
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    localStorage.removeItem(HR_TOKEN);
    localStorage.removeItem(EMPLOYEE_TOKEN);
    localStorage.removeItem(CLIENT_TOKEN);
    localStorage.removeItem(FINANCIER_TOKEN);
    //setRole("desg");
    //alert('inside logout');
    setIsLoggedIn(false);
   
  
    // this.showAlert("Your are no longer logged in !", "success");
    // this.props.history.push("/");
  }
  return (
<div>
<button
      type="button"
      onClick={() => {
        if (buttonClickFor !== undefined && buttonClickFor !== null && buttonClickFor === 'logout') {
          logOut();
          return(
            <Navigate to='/home' replace />
          );
        }
        setIsClicked(initialState);
      }}
      style={{ backgroundColor: bgColor, color }}
      className={`text-xl rounded-lg p-3 hover:bg-light-black`}
    >
      {icon}
      {' '}
     
    </button>
    <span onClick={() => {
        if (buttonClickFor !== undefined && buttonClickFor !== null && buttonClickFor === 'logout') {
          logOut();
          return(
            <Navigate to='/home' replace />
          );
        }
        setIsClicked(initialState);
      }} className="font-semibold dark:text-black-200 pl-4">{text}</span>
</div>
  
    
  );
}

export default ButtonForProfile;
